export const UnidadesFederativas = [
  { uf: "AC" },
  { uf: "AL" },
  { uf: "AP" },
  { uf: "AM" },
  { uf: "BA" },
  { uf: "CE" },
  { uf: "DF" },
  { uf: "ES" },
  { uf: "GO" },
  { uf: "MA" },
  { uf: "MT" },
  { uf: "MS" },
  { uf: "MG" },
  { uf: "PA" },
  { uf: "PB" },
  { uf: "PR" },
  { uf: "PE" },
  { uf: "PI" },
  { uf: "RJ" },
  { uf: "RN" },
  { uf: "RS" },
  { uf: "RO" },
  { uf: "RR" },
  { uf: "SC" },
  { uf: "SP" },
  { uf: "SE" },
  { uf: "TO" },
];

export const formatDate = (e) => {
  if (!e) return null;
  const [year, month, day] = e.split("-");
  return `${day}/${month}/${year}`;
};
export const desformatDate = (e) => {
  if (!e) return null;
  const regex = /^(0[1-9]|[12]\d|3[01])\/(0[1-9]|1[0-2])\/\d{4}$/;
  const formatada = regex.test(e);
  if (formatada) {
    const [day, month, year] = e.split("/");
    return `${year}-${month}-${day}`;
  } else return e;
};

export const formatMoney = (valor = 0) => {
  return new Intl.NumberFormat("pt-br", {
    style: "currency",
    currency: "BRL",
  }).format(valor);
};
export const blob = (response) => {
  const fileNameHeader = "x-suggested-filename";
  const suggestedFileName = response.headers[fileNameHeader];

  const url = window.URL.createObjectURL(new Blob([response.data]));
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", suggestedFileName);
  document.body.appendChild(link);
  link.click();
  link.remove();
};

export const formatDateAutomatic = (data) => {
  if (data) {
    let valor = data.replace(/\D/g, ""); // Remove tudo que não é número

    if (valor.length > 2) {
      valor = valor.replace(/(\d{2})/, "$1/");
    }

    if (valor.length > 5) {
      valor = valor.replace(/(\d{2})(\d)/, "$1/$2");
    }

    // Limita o tamanho da string em 10 caracteres
    data = valor.slice(0, 10);
  }
  return data;
};

export const isPj = () => {
  const isPj = localStorage.getItem("isPJ");
  return isPj === "true" ? true : false;
};

export const isAdmin = () => {
  const isAdmin = localStorage.getItem("isAdmin");
  return isAdmin === "true" ? true : false;
};

export const isGpc = () => {
  const isGpc = localStorage.getItem("isGpc");
  return isGpc === "true" ? true : false;
};

export default {
  UnidadesFederativas,
  formatMoney,
  formatDate,
  blob,
  isPj,
  isAdmin,
  isGpc,
  desformatDate,
};
