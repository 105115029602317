<template>
    <div>

        <v-dialog v-model="termo" width="1800" @click:outside="fechaModal()">
            <v-card>
                <v-card-title>
                    <h5>Termos e Condições</h5>
                    <v-icon small @click="fechaModal()"> fa-times </v-icon>
                </v-card-title>


                <div v-if="termos.assinaturaGov">
                    <v-card-text>
                        <div class="espacamento-sem-cor">
                            <p class="espacamento-p-inicial">Baixe aqui o termo aditivo {{ termos.titulo }}, assine e
                                depois anexe (arquivo com
                                tamanho
                                máximo de 2MB) para declarar que você está de acordo com o termo. Segue abaixo um tutorial
                                de
                                como
                                assinar seu documento digitalmente.</p>
                        </div>
                        <div class="card-texto-termo">
                            <div class="texto-termo pt-5">
                                <h4>Como assinar digitalmente o documento?</h4>
                            </div>

                            <p class="espacamento-p">O Termo Aditivo não precisa ser impresso para assinatura, os mesmos
                                podem ser assinados
                                com sua conta do gov.br. Você acessa a plataforma através do aplicativo GOV.BR ( <a
                                    style="text-decoration: underline; color: #278B97"
                                    href="https://apps.apple.com/br/app/gov-br/id1506827551">link
                                    iOS</a> -- <a style="text-decoration: underline; color: #278B97"
                                    href="https://play.google.com/store/apps/details?id=br.gov.meugovbr&hl=pt_BR&gl=US">link
                                    Android</a> ) ou pelo <a style="text-decoration: underline; color: #278B97"
                                    href="https://sso.acesso.gov.br/login?client_id=assinador.iti.br&authorization_id=186b83313e5">site</a>.
                            </p>
                            <br>
                            <p class="espacamento-p">
                                Em caso de dúvidas, acesse o nosso artigo sobre <a
                                    style="text-decoration: underline; color: #278B97" @click="abrirArtigo()">Assinatura
                                    Digital</a>. Nesse link você vai
                                encontrar todas as informações necessárias para assinar o documento.
                            </p>
                        </div>


                    </v-card-text>

                    <v-col cols="12" class="espacamento-v-col-sem-cor">
                        <v-divider insert class="divider-tela"></v-divider>
                    </v-col>

                    <div style="visibility: hidden; display: table-column-group;">
                        <v-file-input id="file" v-model="documento" :state="validate.documento" accept="application/pdf"
                            :file-name-formatter="formatarNomeDocumento" @change="anexarArquivo($event)">
                        </v-file-input>
                    </div>



                    <v-card-actions class="justify-center pb-10">
                        <div class="download" @click="downloadDocumento(termos)">
                            <v-btn class="botao-baixar mr-2 "> <v-icon small color="#ffffff" class="mr-3">fas
                                    fa-download</v-icon> Baixar termo</v-btn>
                        </div>
                        <div class="download" @click="abrirSelectFile()">
                            <v-btn type="file" id="file" class="botao-salvar mr-3 "> <v-img class="icone-anexar"
                                    src="@/assets/images/icone/espaco-colaborador/icone_anexo_termo.svg"></v-img>Anexar
                                termo assinado </v-btn>
                        </div>
                    </v-card-actions>

                </div>

                <div v-else class="cor">
                    <v-card-text>
                        <div class="espacamento">
                            <iframe :src="url" type="application/pdf" height="500px" width="100%"
                                title="Termo Aditivo"></iframe>
                        </div>
                        <v-checkbox class="checkbox" v-on:change="setarDataLeitura(termos.idTermoColaborador)"
                            v-model="termos.dataLeitura" color="#278b97" label="Declaro que li e concordo."></v-checkbox>
                    </v-card-text>

                    <v-col cols="12" class="espacamento-v-col">
                        <v-divider insert class="divider-tela"></v-divider>
                    </v-col>

                    <v-card-actions class="justify-center pb-10">
                        <v-btn class="botao-cancelar mr-2 largura-btn" @click="fechaModal()">Sair</v-btn>
                        <v-btn v-if="termos.statusTermoAditivo === 'PENDENTE'" class="botao-salvar mr-2 largura-btn"
                            @click="aprovarTermo()"> Assinar Termo </v-btn>
                    </v-card-actions>
                </div>
            </v-card>
        </v-dialog>


    </div>
</template>
  
<script>
import api from "@/services/Api.js";
export default {
    name: "modal-termo",
    props: {
        item: {},
        value: {
            type: Boolean,
            default: false,
        },
    },
    data: () => ({
        termo: false,
        termos: {},
        url: "",
        documento: null,
        validate: {
            documento: null
        },
        envioArquivo: {
            idTermo: null,
        },
        nomeDocumento: null,
    }),
    watch: {
        value(e) {
            this.termo = e;
            this.termos = this.item;
            this.url = "";
            if (e) {
                this.linkDocumento(this.termos.id);
            }
        },
    },
    methods: {
        downloadDocumento(termo) {
            const vm = this;
            api
                .get("termo-aditivo/documento?idTermoAditivo=" + termo.id,
                    {
                        responseType: "arraybuffer",
                        headers: {}
                    })
                .then(function (res) {
                    const blob = new Blob([res.data], { type: "application/pdf" });
                    const url = window.URL.createObjectURL(blob);

                    const link = document.createElement("a");
                    link.href = url;
                    link.setAttribute(`download`, `${termo.titulo}`);
                    document.body.appendChild(link);
                    link.click();
                })
        },
        formatarNomeDocumento(names) {
            if (names[0].name.length > 30)
                return names[0].name.substring(0, 30) + '...';
            else return names[0].name;
        },
        aprovarTermo() {
            this.$emit("aprovar", this.termos.idTermoColaborador);
        },
        abrirSelectFile() {
            document.getElementById("file").click()
        },
        abrirArtigo() {
            let vm = this
            this.$router.push({
                path: "/detalhe-noticia?id=920",
            });
        },
        fechaModal: function () {
            this.$emit("fecha-modal");
            this.termos = {};
            this.checkDocumento = false;
            this.url = "";
            this.limparArquivo();
        },
        setarDataLeitura(idTermoColaborador) {
            const vm = this;
            if (vm.termos.dataLeitura) {
                api
                    .post("termo-aditivo/dataleitura/" + idTermoColaborador)
            }
        },
        async linkDocumento(idTermo) {
            const vm = this;
            await api
                .get(`termo-aditivo/documento?idTermoAditivo=${idTermo}`, {
                    responseType: "arraybuffer",
                    headers: {
                        Accept: "application/pdf",
                        Authorization: localStorage.token,
                    },
                })
                .then(function (response) {
                    const blob = new Blob([response.data], { type: "application/pdf" });
                    const url = window.URL.createObjectURL(blob);
                    vm.url = url
                })
                .catch((e) => {
                    let mensagem = "Não foi possível encontrar documento!";
                    this.$emit("mensagem", "Erro!", mensagem);
                })
        },
        anexarArquivo(arq) {
            const vm = this;
            if (arq.type != "application/pdf") {
                vm.limparArquivo();
                let mensagem = 'Tipo de arquivo não suportado, verifique se o anexo é um PDF!'
                this.$emit("mensagem", "Sucesso!", mensagem);
            }
            else if (arq) {
                vm.nomeDocumento = arq.name;
                this.$emit("enviarDocumento", vm.prepararDados());
                this.fechaModal();
                vm.limparArquivo();
            }
        },
        prepararDados() {
            const vm = this;
            const formData = new FormData();

            vm.envioArquivo.idTermo = vm.termos.idTermoColaborador;
            formData.append('form', JSON.stringify(this.envioArquivo));
            formData.append('anexo', this.documento);
            return formData;
        },
        limparArquivo() {
            const vm = this;
            vm.nomeDocumento = null;
            vm.documento = null;
            vm.envioArquivo = {}
            vm.verificaDocumento = false;
        },
    },
};
</script>
<style scoped>
p {
    text-align: center !important;
}


.icone-anexar {
    width: 22px !important;
    height: 22px !important;
}

.cor {
    background-color: #e9e9e9;
}

.espacamento-p {
    padding-left: 80px;
    padding-right: 80px;
    text-align: justify !important;
}

.espacamento-p-inicial {
    padding: 20px 45px 20px 45px;
    text-align: justify !important;
}

.texto-termo>h4 {
    color: #5D5D5D !important;
}

.divider-tela {
    margin: 30px;
    opacity: 1.0;
    justify-items: center;
}

.botao-baixar {
    background-color: #FF0C23 !important;
    color: #ffffff !important;
    border-radius: 15px !important;
    text-transform: initial !important;
    height: 40px !important;
    font-family: Montserrat;
    font-weight: 500 !important;
    box-shadow: none !important;
    width: 235px !important;
}

.download>.botao-salvar {
    width: 235px !important;
}


.borda {
    font-size: 16px;
    border-left: 3px solid;
    border-radius: 3px;
    padding-left: 10px;
    color: #008693;
    text-align: left;
}

.v-card__subtitle,
.v-card__text,
.v-card__title {
    padding: 0px !important;
}

.v-dialog>.v-card>.v-card__text {
    background-color: #e9e9e9;
    color: #2d2d2d;
    justify-content: center;
    padding: 20px 0px 0px 0px !important;
}

.espacamento {
    padding: 10px 30px 0px 30px;
    background-color: #e9e9e9;
}

.espacamento-sem-cor {
    padding: 10px 30px 0px 30px;
}


.espacamento-v-col {
    padding: 5px;
    background-color: #e9e9e9;
}

.espacamento-v-col-sem-cor {
    padding: 5px;
}

.checkbox {
    font-family: Montserrat;
    font-size: 0.9375rem;
    font-weight: 600;
    color: #616161 !important;
    margin-bottom: 0 !important;
    padding: 0px 0px 0px 35px;
}


.card-texto-termo {
    height: 210px;
    background: #edefee82 0% 0% no-repeat padding-box;
    text-align: center;
    margin-top: 15px;
}


.texto-termo {
    padding: 25px !important;
    font-weight: 600 !important;
    color: #616161 !important;
}


.v-card>.v-card__title>.v-icon {
    position: absolute;
    right: 24px;
    color: #ffffff;
}
</style>
  
  
  