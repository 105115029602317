<template>
  <div>
    <v-dialog v-model="modalAlterarBanner" width="1800" @click:outside="fechaModal()">
      <v-card>
        <v-card-title>
          <h3>{{ imagem.tituloLocal }}</h3>
          <v-icon @click="fechaModal()">fa-xmark</v-icon>
        </v-card-title>

        <v-card-text>
          <v-container class="px-8">
            <v-row v-if="alerta">
              <v-col cols="12">
                <div class="alerta">
                  <div>
                    <v-img class="icone-alerta"
                      src="@/assets/images/icone/espaco-colaborador/icone_sinal_de_aviso.svg"></v-img>
                  </div>

                  <v-divider inset vertical color="#278b97" justify="center" class="mb-2"
                    :class="$vuetify.breakpoint.xs ? 'd-none' : ''"></v-divider>

                  <div>
                    <h3> Erro ao anexar imagem</h3>
                    <p class="text-alerta">
                      A imagem anexada tem {{ width }} pixels de largura e {{ height }} pixels de altura.
                      <br />
                      Necessário imagem com {{ imagem.largura }} pixels de largura e {{ imagem.altura }} pixels de altura.
                    </p>
                  </div>
                </div>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" class="py-0">
                <p class="pre-visu">Pré-visualização</p>
                <v-card :class="imagem.altura > 400 ? 'dimensoes' : ''">
                  <v-img :src="'data:' + tipoArquivo + ';base64,' + arquivob64"></v-img>
                </v-card>
                <div v-if="imagem.altura && imagem.largura" class="tamanho-imagem">
                  <p>Proporção necessária</p>
                  <p>Largura: {{ imagem.largura }}px Altura: {{ imagem.altura }}px</p>
                </div>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12">
                <v-file-input v-model="arquivo" accept="image/*" @change="anexarArquivo(arquivo)" label="Imagem" outlined
                  dense hide-details="auto" :prepend-icon="null">
                  <template v-slot:prepend-inner>
                    <v-icon class="mr-2">fa-paperclip</v-icon>
                  </template>
                </v-file-input>
              </v-col>
              <v-col cols="12" v-if="comLink">
                <v-text-field v-model="imagem.linkEncaminhar" label="Link" outlined dense
                  hide-details="auto"></v-text-field>
              </v-col>
              <v-col cols="12" v-if="desativavel">
                <v-checkbox v-model="imagem.indAtivo" :label="`Imagem Ativa? ${imagem.indAtivo ? 'Sim' : 'Não'}`"
                  hide-details class="checkbox" />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions class="justify-center">
          <v-btn class="botao-cancelar" min-width="25%" @click="fechaModal()">Cancelar</v-btn>
          <v-btn class="botao-principal" min-width="25%" @click="editar()">Salvar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import api from "@/services/Api.js";

export default {
  name: "modal-banner",
  props: {
    modalAlterarBanner: Boolean,
    comLink: {
      type: Boolean,
      default: true,
    },
    tipo: {
      type: String,
      default: 'imagem',
    },
    imagem: Object,
    pagina: String,
    customMethod: {
      type: Function,
      required: true,
    },
    desativavel: Boolean,
  },
  data: () => ({
    arquivoImagem: null,
    arquivo: {},
    arquivob64: null,
    tipoArquivo: null,
    width: null,
    height: null,
    alerta: false,
  }),
  watch: {
    modalAlterarBanner() {
      const vm = this;
      vm.arquivob64 = vm.imagem.arquivo;
      vm.tipoArquivo = vm.imagem.tipoArquivo;
      vm.arquivo = new File([this.imagem.arquivo], this.imagem.nomeArquivo);
    }
  },
  methods: {
    preparaDadosEnvio(dadosEnvio, imagem) {
      let imagemFormData = new FormData();

      this.imagem.dataUltimaModificacao = null;
      this.imagem.idPessoalModificadora = null;

      if (imagem) {
        imagemFormData.append("fileImagem", imagem);
      }
      dadosEnvio.arquivo = "";
      imagemFormData.append("imagem", JSON.stringify(dadosEnvio));
      return imagemFormData;
    },
    editar() {
      const vm = this;
      vm.$emit('loader', true);
      const dadosEditados = vm.preparaDadosEnvio(vm.imagem, vm.arquivoImagem);
      api
        .post("imagem/editar", dadosEditados, {
          headers: { Authorization: localStorage.token },
          withCredentials: true
        })
        .then(() => {
          vm.chamarAtualizar();
          vm.fechaModal();
          vm.$emit('mensagem', 'Sucesso!', "Imagem editada com sucesso");
        })
        .catch((e) => { vm.$emit('mensagem', 'Erro!', e) })
        .finally(() => { vm.$emit('loader', false) });
    },
    anexarArquivo(arquivo) {
      console.log(this.imagem);
      const vm = this;

      if (arquivo.size) {
        vm.getImageDimensions(arquivo);

        setTimeout(() => {
          if ((vm.width == vm.imagem.largura) && (vm.height == vm.imagem.altura)) {
            vm.alerta = false;
            vm.arquivoImagem = arquivo;
            vm.imagem.nomeArquivo = vm.arquivoImagem.name;
            vm.imagem.tipoArquivo = vm.arquivoImagem.type;
            vm.tipoArquivo = vm.arquivoImagem.type;
            vm.getBase64(arquivo);
          } else {
            vm.alerta = true;
            vm.arquivo = {};
            vm.$emit('mensagem', 'Erro!', "Imagem anexada não corresponde a proporção solicitada!");
          }
          //Necessário para esperar o método getImageDimensions
        }, 60);
      }
    },
    chamarAtualizar() {
      const vm = this;
      if (vm.tipo == 'valores') {
        vm.$emit("atualizaValores", vm.pagina, vm.arquivob64, vm.tipoArquivo);
      } else {
        vm.$emit("atualizaImagem", vm.pagina, vm.arquivob64, vm.tipoArquivo);
      }
    },
    getImageDimensions(file) {
      const vm = this;

      if (!file) return null;

      const reader = new FileReader();
      reader.onload = (e) => {
        const img = new Image();
        img.onload = () => {
          vm.width = img.width;
          vm.height = img.height;
        };
        img.src = e.target.result;
      };
      reader.readAsDataURL(file);
    },
    getBase64(file) {
      const vm = this;
      var reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = function () {
        vm.arquivob64 = reader.result.replace(/^data:.+;base64,/, "");
      };
    },
    fechaModal() {
      this.alerta = false;
      this.arquivo = {};
      this.$emit("fecha-modal");
    },
  },
};
</script>

<style scoped>
.pre-visu {
  text-align: left !important;
  margin-bottom: .75rem !important;
  color: #00000099 !important;
}

.tamanho-imagem {
  margin: .5rem 0;
}

.tamanho-imagem p {
  color: #00000099 !important;
}

.alerta {
  width: auto;
  margin: .5rem 0;
  min-height: 5rem;
  padding: 1rem;
  background-color: #DDD;
  border-radius: 20px;
  gap: 4rem;
  align-items: center;
  display: flex;
}

.icone-alerta {
  width: 49px;
  height: 43px;
  margin-left: 50px;
}

.dimensoes {
  max-width: 400px;
  margin: auto;
}

.text-alerta {
  padding-top: 15px;
  text-align: center;
}

.checkbox {
  margin: 0;
  font-weight: 600;
}
</style>
