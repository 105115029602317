<template>
  <div>
    <Loader :loader-primario="loader" />

    <Alerta
      v-on:fecharModal="fecharModal"
      :exibir="modal.exibir"
      :titulo="modal.titulo"
      :mensagem="modal.mensagem"
      v-on:exclusao="excluir()"
    />

    <ModalBanner
      :modalAlterarBanner="modalAlterarBanner"
      :imagem="imagemModal"
      :pagina="pagina"
      :tipo="tipo"
      :comLink="link"
      @atualizaImagem="atualizaImagem"
      @atualizaValores="atualizaValores"
      v-on:fecha-modal="fechaModalImagem"
      :customMethod="getImagem"
      @mensagem="mensagem"
      @loader="isLoading"
    />

    <ModalPdf
      :modalAlterarPdf="modalAlterarPdf"
      :pdf="pdfModal"
      :pagina="pagina"
      v-on:fecha-modal="modalAlterarPdf = false"
      @atualizar="atualizarPdf"
      :customMethod="getPdf"
      @mensagem="mensagem"
    />

    <v-container fluid class="pa-0">
      <v-btn
        v-if="admin"
        @click="
          modalAlterarBanner = true;
          imagemModal = imagem.banner;
          pagina = 'banner';
        "
        class="banner-btn botao-edicao-adm"
      >
        <v-icon>fa fa-pencil</v-icon>
      </v-btn>
      <v-img
        :src="
          'data:' +
          imagem.banner.tipoArquivo +
          ';base64,' +
          imagem.banner.arquivo
        "
      />
      <v-row class="destaque">
        <v-col cols="12" md="3" class="linha">
          <a class="link" href="#valores">
            <v-img
              src="@/assets/images/icone/icone_valores_estrategicos.png"
              max-width="57px"
              class="ma-auto"
            ></v-img>
            <p class="ma-auto">Valores Estratégicos</p>
          </a>
        </v-col>

        <v-col cols="12" md="3" class="linha">
          <a class="link" href="#missao-visao">
            <v-img
              src="@/assets/images/icone/icone_missao_e_visao.png"
              max-width="57px"
              class="ma-auto"
            ></v-img>
            <p class="ma-auto">Missão & visão</p>
          </a>
        </v-col>

        <v-col cols="12" md="3" class="linha">
          <a class="link" href="#estrutura-corporativa">
            <v-img
              src="@/assets/images/icone/icone_estrutura_corporativa.png"
              max-width="57px"
              class="ma-auto"
            ></v-img>
            <p class="ma-auto">Estrutura Corporativa</p>
          </a>
        </v-col>

        <v-col cols="12" md="3" class="linha">
          <a class="link" href="#framework">
            <v-img
              src="@/assets/images/icone/icone_f7.png"
              max-width="57px"
              class="ma-auto"
            ></v-img>
            <p class="texto-destaque-f7 ma-auto">F7 - Framework Hepta</p>
          </a>
        </v-col>
      </v-row>
    </v-container>
    <v-container class="mt-4">
      <div class="numeracao">
        <v-row class="align-center linha-numeracao" no-gutters>
          <v-img
            src="@/assets/images/icone/icone_1.png"
            max-width="37px"
            class="mr-2"
            contain
          ></v-img>
          <div class="textonumeracao">
            <p>
              Forma particular, maneira, modo de fazer as coisas adotado pela
              empresa e todos os seus colaboradores.<br />
              Exemplo:
              <a class="textoJeitoHepta"
                >“O #JeitoHepta de prestar serviços de TI é uma paixão contínua
                de sempre atender melhor seus clientes.”</a
              >
            </p>
          </div>
        </v-row>
        <v-row class="align-center mt-15" no-gutters>
          <v-img
            src="../assets/images/icone/icone_2.png"
            max-width="37px"
            class="mr-2"
            contain
          ></v-img>
          <div class="textonumeracao">
            <p>
              Maneira de ser, de atuar, de apresentar-se: caráter, índole.<br />
              Exemplo:
              <a class="textoJeitoHepta"
                >"O #JeitoHepta é resultado dos valores estratégicos da empresa,
                que preza a qualidade e valoriza as pessoas.”</a
              >
            </p>
          </div>
        </v-row>
      </div>
      <div class="d-flex flex-column my-10">
        <v-icon color="#278B97" small>fas fa-chevron-down</v-icon>
        <v-icon color="#278B97" small class="icone-chevron"
          >fas fa-chevron-down</v-icon
        >
      </div>
    </v-container>

    <v-container fluid class="px-0">
      <v-btn
        v-if="admin"
        @click="
          modalAlterarBanner = true;
          imagemModal = imagem.bannerJeitoHepta;
          pagina = 'bannerJeitoHepta';
          link = true;
        "
        class="banner-jeito-hepta botao-edicao-adm"
      >
        <v-icon>fa fa-pencil</v-icon>
      </v-btn>
      <v-img
        :src="
          'data:' +
          imagem.bannerJeitoHepta.tipoArquivo +
          ';base64,' +
          imagem.bannerJeitoHepta.arquivo
        "
        @click="modalVideoPorDentro = true"
        max-width="100%"
        class="ma-auto pointer"
      />
      <v-dialog
        v-model="modalVideoPorDentro"
        width="1000"
        v-if="modalVideoPorDentro"
      >
        <v-card>
          <v-card-title class="py-5">
            <v-icon @click="modalVideoPorDentro = false">fas fa-times</v-icon>
          </v-card-title>
          <v-card-text>
            <iframe
              :src="imagem.bannerJeitoHepta.linkEncaminhar"
              allowfullscreen
              class="iframeBanner"
            ></iframe>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-container>
    <v-container id="valores">
      <div class="valores_estrategicos" id="valores-estrategicos">
        <v-img
          src="../assets/images/icone/icone_valores_estrategicos.png"
          max-width="57px"
          class="ma-auto"
        ></v-img>
        <h1 class="py-3">Valores <br />Estratégicos</h1>
      </div>
    </v-container>

    <v-row class="bg-valores">
      <v-col cols="12" sm="4">
        <v-tabs
          show-arrows
          :center-active="$vuetify.breakpoint.xsOnly"
          :vertical="$vuetify.breakpoint.smAndUp"
          v-model="tab"
          grow
          height="100%"
          background-color="transparent"
          class="tabs"
          next-icon="fas fa-chevron-right"
        >
          <v-tabs-slider color="transparent"></v-tabs-slider>
          <v-tab
            v-for="item in valoresEstrategicos"
            :key="item.id"
            class="tab-valores-estrategicos"
            active-class="tab-valores-estrategicos-ativo"
            @click="alterImage(item.id)"
          >
            <p>{{ item.titulo }}</p>
            <img class="iconeNavegacao" :src="item.imagem" width="70px" />
          </v-tab>
        </v-tabs>
      </v-col>
      <v-col cols="12" sm="8" class="pt-12">
        <v-tabs-items v-model="tab">
          <v-btn
            v-if="admin"
            @click="
              modalAlterarBanner = true;
              imagemModal = valores[categoriaSelecionada];
              pagina = categoriaSelecionada;
              tipo = 'valores';
            "
            class="banner-btn botao-edicao-adm"
          >
            <v-icon>fa fa-pencil</v-icon>
          </v-btn>
          <v-tab-item
            v-for="item in valoresEstrategicos"
            :key="item.id"
            class="bg-valores"
          >
            <img :src="urlImagemSelecionada" max-width="100%" width="100%" />
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>

    <!-- Missão e Visão -->
    <div>
      <v-divider class="divider-categoria" />
      <div id="missao-visao" class="titulo-categoria">
        <v-img
          src="../assets/images/icone/icone_missao_e_visao.png"
          max-width="57px"
          class="ma-auto"
        />
        <h1 class="pt-3">Missão & visão</h1>
      </div>
      <v-container>
        <v-row>
          <v-col cols="12" lg="6" offset-lg="2">
            <div class="card card-missao">
              <div class="card-missao-visao">
                <h1>Missão</h1>
                <p>
                  Potencializar as competências e investimentos de TI de nossos
                  clientes liberando seu tempo para que tenham foco em seu
                  negócio.
                </p>
              </div>
            </div>
          </v-col>

          <v-col cols="12" lg="6" offset-lg="5">
            <div class="card card-visao">
              <div class="card-missao-visao">
                <h1>Visão</h1>
                <p>
                  Expandir nacional e internacionalmente, nos mercados públicos
                  e privados, mantendo o clima organizacional e a saúde
                  financeira.
                </p>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>

    <!-- Estrutura Corporativa -->
    <div class="containerDividerEstrutura">
      <v-divider class="dividerEstrutura"></v-divider>
    </div>
    <div>
      <v-btn
        v-if="admin"
        @click="
          modalAlterarBanner = true;
          imagemModal = imagem.bannerEstruturaCorporativa;
          pagina = 'bannerEstruturaCorporativa';
          link = true;
        "
        class="banner-jeito-hepta botao-edicao-adm"
      >
        <v-icon>fa fa-pencil</v-icon>
      </v-btn>
      <v-img
        :src="
          'data:' +
          imagem.bannerEstruturaCorporativa.tipoArquivo +
          ';base64,' +
          imagem.bannerEstruturaCorporativa.arquivo
        "
        max-width="100%"
        class="ma-auto pointer"
        @click="modalEstruturaCorporativa = true"
      ></v-img>
    </div>
    <div id="estrutura-corporativa" class="titulo-categoria">
      <v-img
        src="../assets/images/icone/icone_missao_e_visao.png"
        max-width="57px"
        class="ma-auto"
      />
      <h1 class="pt-3">
        Estrutura <br />
        Corporativa
      </h1>
    </div>
    <div class="mapaAreas">
      <v-img src="../assets/images/banner/mapa_areas.png" class="mapaAreasImg">
        <div class="diretoria" @click="abrirModalMapaAreas(0)"></div>
        <div class="ascom" @click="abrirModalMapaAreas(1)"></div>
        <div class="asjur" @click="abrirModalMapaAreas(2)"></div>
        <div class="gpc" @click="abrirModalMapaAreas(3)"></div>
        <div class="gov" @click="abrirModalMapaAreas(4)"></div>
        <div class="geinfra" @click="abrirModalMapaAreas(5)"></div>
        <div class="gedev" @click="abrirModalMapaAreas(6)"></div>
        <div class="gecom" @click="abrirModalMapaAreas(7)"></div>
        <div class="gefin" @click="abrirModalMapaAreas(8)"></div>
      </v-img>
    </div>

    <!-- F7 - Framework Hepta -->
    <div class="containerF7">
      <v-divider class="dividerF7"></v-divider>
    </div>
    <div>
      <v-btn
        v-if="admin"
        @click="
          modalAlterarBanner = true;
          imagemModal = imagem.f7;
          pagina = 'f7';
          link = true;
        "
        class="banner-jeito-hepta botao-edicao-adm"
      >
        <v-icon>fa fa-pencil</v-icon>
      </v-btn>
      <v-img
        :src="'data:' + imagem.f7.tipoArquivo + ';base64,' + imagem.f7.arquivo"
        max-width="100%"
        class="ma-auto pointer"
        @click="modalF7 = true"
      ></v-img>
    </div>
    <div id="framework" class="titulo-categoria">
      <v-img
        src="../assets/images/icone/icone_f7.png"
        max-width="57px"
        class="ma-auto"
      />
      <h1 class="pt-3">
        F7 - Framework <br />
        Hepta
      </h1>
    </div>
    <div class="titulo-categoria-texto mt-12">
      <h2>
        O
        <a
          href="https://api-nossa-hepta.hepta.com.br/IntranetHepta/rs/anexo/visualizarPDF/7"
          target="_blank"
          >Framework Hepta – F7</a
        >
        é um conjunto de políticas, processos, procedimentos, modelos e
        ferramentas para padronizar, sistematizar e orientar nossa prestação de
        serviços. Nosso foco é garantir que as entregas sejam realizadas com
        qualidade, gerando a satisfação dos nossos clientes.
      </h2>
    </div>
    <Mandala class="mt-16"></Mandala>
    <v-dialog
      v-model="modalImgPlanejamentoEstrategico"
      width="1000"
      v-if="modalImgPlanejamentoEstrategico"
    >
      <v-card>
        <v-container>
          <v-row class="justify-end" no-gutters>
            <v-icon
              class="pb-2 pr-2"
              @click="modalImgPlanejamentoEstrategico = false"
              >fas fa-times</v-icon
            >
          </v-row>
          <v-img :src="srcImagemMapa" max-width="100%" class="ma-auto"></v-img>
        </v-container>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="modalPdfPlanejamentoEstrategico"
      width="1000"
      v-if="modalPdfPlanejamentoEstrategico"
    >
      <v-card>
        <v-card-title class="py-5">
          <v-icon @click="modalPdfPlanejamentoEstrategico = false"
            >fas fa-times</v-icon
          >
        </v-card-title>
        <v-cart-text>
          <iframe
            id="pdfViewer"
            width="100%"
            height="550px"
            :src="url"
          ></iframe>
        </v-cart-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="modalVideoPlanejamentoEstrategico"
      width="1000"
      v-if="modalVideoPlanejamentoEstrategico"
    >
      <v-card>
        <v-card-title class="py-5">
          <v-icon @click="modalVideoPlanejamentoEstrategico = false"
            >fas fa-times</v-icon
          >
        </v-card-title>
        <v-card-text>
          <iframe
            :src="pdf.visao.linkEncaminhar"
            allowfullscreen
            class="iframeBanner"
          ></iframe>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="modalEstruturaCorporativa"
      width="1000"
      v-if="modalEstruturaCorporativa"
    >
      <v-card>
        <v-card-title class="py-5">
          <v-icon @click="modalEstruturaCorporativa = false"
            >fas fa-times</v-icon
          >
        </v-card-title>
        <v-card-text>
          <iframe
            :src="imagem.bannerEstruturaCorporativa.linkEncaminhar"
            allowfullscreen
            class="iframeBanner"
          ></iframe>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="modalMapaAreas">
      <v-card class="pt-5">
        <v-card-title class="mapaTitle">
          <h1>{{ mapaSelecionado.titulo }}</h1>
          <v-icon @click="modalMapaAreas = false">fa-times</v-icon>
        </v-card-title>
        <v-container>
          <v-divider></v-divider>
          <div class="text-center pa-8">
            <p>{{ mapaSelecionado.texto }}</p>
          </div>
        </v-container>
      </v-card>
    </v-dialog>
    <v-dialog v-model="modalF7" width="1000" v-if="modalF7">
      <v-card>
        <v-card-title class="py-5">
          <v-icon @click="modalF7 = false">fas fa-times</v-icon>
        </v-card-title>
        <v-card-text>
          <iframe
            :src="imagem.f7.linkEncaminhar"
            allowfullscreen
            class="iframeBanner"
          ></iframe>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Mandala from "../components/JeitoHepta/MandaOficial.vue";
import ModalBanner from "@/components/Modal/ModalBanner.vue";
import ModalPdf from "@/components/Modal/ModalPDF.vue";
import Loader from "@/components/Loader.vue";
import api from "@/services/Api.js";
import modalMixin from "@/mixins/modalMixin";
import Alerta from "@/components/Alerta.vue";
import {
  idsImagemJeitoHepta,
  idsPdfJeitoHepta,
  valoresEstrategicos,
} from "@/utils/ImagemEnums.js";
import Utils from "@/utils/Utils";

export default {
  components: { Mandala, ModalBanner, Alerta, Loader, ModalPdf },
  mixins: [modalMixin],
  data() {
    return {
      categoriaSelecionada: "capital_humano",
      tab: null,
      tipo: "",
      valoresEstrategicos: [
        {
          titulo: "Capital Humano",
          imagem: require("@/assets/images/icone/icone_capital_humano.png"),
          id: "capital_humano",
        },
        {
          titulo: "Coerência",
          imagem: require("@/assets/images/icone/icone_coerencia.png"),
          id: "coerencia",
        },
        {
          titulo: "Colaboração",
          imagem: require("@/assets/images/icone/icone_colaboracao.png"),
          id: "colaboracao",
        },
        {
          titulo: "Equilíbrio",
          imagem: require("@/assets/images/icone/icone_equilibrio.png"),
          id: "equilibrio",
        },
        {
          titulo: "Felicidade",
          imagem: require("@/assets/images/icone/icone_felicidade.png"),
          id: "felicidade",
        },
        {
          titulo: "Respeito",
          imagem: require("@/assets/images/icone/icone_respeito.png"),
          id: "respeito",
        },
        {
          titulo: "Transformação",
          imagem: require("@/assets/images/icone/icone_transformacao.png"),
          id: "transformacao",
        },
      ],
      modalVideoPorDentro: false,
      modalVideoPlanejamentoEstrategico: false,
      modalImgPlanejamentoEstrategico: false,
      modalPdfPlanejamentoEstrategico: false,
      modalEstruturaCorporativa: false,
      modalMapaAreas: false,
      modalF7: false,
      modalAlterarBanner: false,
      modalAlterarPdf: false,
      imagemModal: {},
      pdfModal: {},
      pagina: "",
      admin: false,
      link: false,
      loader: false,
      urlImagemSelecionada: null,
      pdf: {
        visao: {
          linkEncaminhar: null,
        },
      },
      valores: {
        capital_humano: {
          tipoArquivo: "",
          arquivo: "",
        },
      },
      imagem: {
        banner: {
          tipoArquivo: "",
          arquivo: "",
        },
        mapaEstrategico: {
          tipoArquivo: "",
          arquivo: "",
        },
        bannerJeitoHepta: {
          tipoArquivo: "",
          arquivo: "",
          linkEncaminhar: null,
        },
        bannerEstruturaCorporativa: {
          tipoArquivo: "",
          arquivo: "",
          linkEncaminhar: null,
        },
        f7: {
          tipoArquivo: "",
          arquivo: "",
          linkEncaminhar: null,
        },
      },
      mapaAreas: [
        {
          id: 0,
          titulo: "Diretoria Executiva",
          texto: `Lidera a equipe gerencial a fim de conduzir o #TImeHepta no atingimento dos objetivos 
                  estratégicos, sempre mantendo as características da empresa: qualidade na entrega e ética em 
                  todas as relações.`,
        },
        {
          id: 1,
          titulo: "Assessoria de Comunicação Corporativa (ASCOM)",
          texto: `Responsável pela comunicação interna e externa da Hepta, incluindo tudo que se refere a 
                  identidade visual, presença online, eventos, campanhas e patrocínios. Nossa equipe de 
                  Comunicação atende todas as áreas da empresa, além de atuar também como suporte aos nossos 
                  clientes.`,
        },
        {
          id: 2,
          titulo: "Assessoria Jurídica (ASJUR)",
          texto: `Provê suporte jurídico a todas as áreas da empresa, tendo como missão a prevenção e mitigação 
                  de riscos relacionados. Seu papel é fundamental na elaboração e revisão de contratos e ofícios, 
                  adequação de documentos à legislação, além de defesa e acompanhamento de ações judiciais.`,
        },
        {
          id: 3,
          titulo: "Gerência de Gestão de Pessoas (GPC)",
          texto: `Responsável por oferecer ferramentas para gestão do capital humano da Hepta, a GPC não deve ser 
            confundida com o Departamento Pessoal (DP), que é ligado à área Administrativa e Financeira.`,
        },
        {
          id: 4,
          titulo: "Gerência de Governança Corporativa (GOV)",
          texto: `Responsável por manter o Framework Hepta, com todas as suas políticas, processos, procedimentos, 
                  modelos e ferramentas em pleno funcionamento para ser utilizado por todo o #TimeHepta. Além 
                  disso, é também o condutor do Programa de Compliance que busca a transparência, conformidade e 
                  integridade nas relações tanto internas à empresa como externas com o mercado e demais envolvidos.`,
        },
        {
          id: 5,
          titulo: "GEINFRA",
          texto: `Responsável pela gestão da operação de entrega de serviços de infraestrutura, incluindo equipes 
            alocadas em clientes e uma central de serviços compartilhados. Cada solução é construída de acordo 
            com a necessidade e contexto do cliente.`,
        },
        {
          id: 6,
          titulo: "GEDEV",
          texto: `Responsável pela gestão da operação de entrega de serviços de desenvolvimento, também com equipes 
                  alocadas e serviços compartilhados, de forma a oferecer soluções adequadas a demanda e contexto 
                  do cliente.`,
        },
        {
          id: 7,
          titulo: "Gerência Comercial e Marketing (GECOM)",
          texto: `Tem como principal atribuição apresentar os diferenciais da Hepta ao mercado, buscando por meio 
                  de uma venda humanizada identificar solução para as dificuldades de negócio e de TI dos clientes. 
                  Seu foco é na construção de relacionamentos éticos, duradouros e de confiança com os nossos 
                  atuais e futuros clientes a fim de que o #JeitoHepta seja percebido e a satisfação já se inicie 
                  na venda.`,
        },
        {
          id: 8,
          titulo: "Gerência Administrativa e Financeiro (GEFIN)",
          texto: `Responsável por gerir os recebimentos e pagamentos internos e externos, além de executar toda a 
                  parte administrativa de Departamento Pessoal. É também a área que viabiliza as viagens corporativas, 
                  além de cuidar do bem-estar do #TimeHepta, mantendo tudo em ordem com a recepção, manutenção predial e 
                  limpeza da sede.`,
        },
      ],
      mapaSelecionado: {},
      url: null,
    };
  },
  methods: {
    abrirMapaEstrategico() {
      this.modalImgPlanejamentoEstrategico = true;
      this.srcImagemMapa =
        "data:" +
        this.imagem.mapaEstrategico.tipoArquivo +
        ";base64," +
        this.imagem.mapaEstrategico.arquivo;
    },
    abrirModalPdf() {
      this.modalAlterarPdf = true;
      this.pagina = "visao";
      this.pdfModal = this.pdf.visao;
    },
    abrirPdf() {
      this.visualizarPdf(this.pdf.visao.id);
      this.modalPdfPlanejamentoEstrategico = true;
    },
    alterImage(categoria) {
      const vm = this;
      vm.categoriaSelecionada = categoria;
      vm.urlImagemSelecionada =
        "data:" +
        vm.valores[categoria].tipoArquivo +
        ";base64," +
        vm.valores[categoria].arquivo;
    },
    abrirModalMapaAreas(index) {
      this.modalMapaAreas = true;
      this.mapaSelecionado = this.mapaAreas[index];
    },
    visualizarPdf(id) {
      const vm = this;
      vm.loader = true;
      api
        .get("pdf/visualizar/" + id, {
          responseType: "arraybuffer",
          headers: {
            Accept: "application/pdf",
            Authorization: localStorage.token,
          },
        })
        .then((response) => {
          const blob = new Blob([response.data], { type: "application/pdf" });
          const url = window.URL.createObjectURL(blob);
          vm.url = url;
        })
        .catch((e) => {
          vm.mensagem("Erro!", "Não foi possivel baixar pdf.");
        })
        .finally((vm.loader = false));
    },
    getImagem(id, pagina, objeto) {
      const vm = this;
      vm.loader = true;
      api
        .get("imagem/" + id)
        .then((response) => {
          //ex: vm.valores.visao
          if (objeto == "valores") {
            vm.valores[pagina] = response.data;

            if (pagina == "capital_humano") {
              vm.urlImagemSelecionada =
                "data:" +
                vm.valores.capital_humano.tipoArquivo +
                ";base64," +
                vm.valores.capital_humano.arquivo;
            }
          }
          //ex: vm.imagem.banner
          else vm.imagem[pagina] = response.data;
        })
        .catch((e) => {
          vm.mensagem("Erro!", "Não foi possivel baixar imagem.");
        })
        .finally(() => {
          vm.loader = false;
        });
    },
    atualizarPdf(id, pagina) {
      this.getPdf(id, pagina);
    },
    getPdf(id, variavel) {
      const vm = this;
      api
        .get("pdf/" + id)
        .then((response) => {
          //nome da variavel é o pagina. ex: vm.banner
          vm.pdf[variavel] = response.data;
        })
        .catch((e) => {
          vm.mensagem("Erro!", "Não foi possivel baixar imagem.");
        });
    },
    isLoading(e) {
      this.loader = e;
    },
    atualizaImagem(pagina, imagem, tipoArquivo) {
      const vm = this;
      vm.imagem[pagina].arquivo = imagem;
      vm.imagem[pagina].tipoArquivo = tipoArquivo;
      vm.modalAlterarBanner = false;
    },
    atualizaValores(pagina, imagem, tipoArquivo) {
      const vm = this;
      vm.valores[pagina].arquivo = imagem;
      vm.valores[pagina].tipoArquivo = tipoArquivo;
      vm.urlImagemSelecionada =
        "data:" +
        vm.valores[pagina].tipoArquivo +
        ";base64," +
        vm.valores[pagina].arquivo;
      vm.modalAlterarBanner = false;
    },
    fechaModalImagem() {
      this.modalAlterarBanner = false;
      this.tipo = "";
      this.link = false;
    },
    isAdmin() {
      this.admin = Utils.isAdmin();
    },
  },
  created() {
    const vm = this;
    vm.isAdmin();

    for (let pagina in idsImagemJeitoHepta) {
      //ex: this.getImagem(103, 'banner');
      vm.getImagem(idsImagemJeitoHepta[pagina], pagina, "imagem");
    }

    for (let pagina in valoresEstrategicos) {
      vm.getImagem(valoresEstrategicos[pagina], pagina, "valores");
    }

    for (let pdf in idsPdfJeitoHepta) {
      vm.getPdf(idsPdfJeitoHepta[pdf], pdf);
    }
  },
};
</script>

<style scoped>
.destaque {
  position: relative;
  color: #278b97;
  background-color: #ffffff;
  border-radius: 32px 32px 0 0;
  height: 10rem;
  max-width: 1024px;
  margin: auto;
  margin-top: -4rem;
  align-items: center;
  box-shadow: 0px 6px 8px 2px #00000026;
}

.destaque .linha {
  border-right: 1px solid rgb(39 139 151 / 51%);
}

.destaque p {
  color: #278b97;
  text-transform: uppercase;
  text-align: center;
  font-weight: 700;
  padding-top: 21px;
  max-width: 180px;
}

.texto-destaque-f7 {
  width: 151px;
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-word;
  text-align: center;
  text-transform: uppercase;
}

.textoJeitoHepta {
  color: #278b97;
}

.numeracao {
  max-width: 1024px;
  margin: auto;
}

.linha-numeracao {
  position: relative;
}

.linha-numeracao::after {
  content: "";
  margin-left: 11px;
  min-height: 46px;
  border: 1px solid;
  border: 0.1px solid;
  border-color: #278b97;
  position: absolute;
  top: 46px;
}

.icone-chevron {
  line-height: 0.2;
}

.textonumeracao {
  line-height: 18px;
  margin-top: 10px;
}

.pointer {
  cursor: pointer;
}

.valores_estrategicos {
  background-color: #ffffff;
  text-align: -webkit-center;
  padding: 10px;
  position: relative;
}

.valores_estrategicos::after {
  content: "";
  position: absolute;
  right: calc(50% - 50px);
  top: 100%;
  border-left: 50px solid transparent;
  border-right: 50px solid transparent;
  border-top: 50px solid #ffffff;
  z-index: 1;
}

.tabs {
  height: 100%;
}

.tab-valores-estrategicos {
  background: #dcdcdc;
  border-radius: 0px 56px 56px 0px;
  padding-right: 40px;
  text-transform: uppercase;
  font-weight: bold;
  margin-top: 5px;
  display: flex;
  justify-content: space-between;
  width: 100% !important;
  height: 96px !important;
}

.tab-valores-estrategicos:hover {
  background-color: #ffeab1;
  transition: 0.3s;
}

.tab-valores-estrategicos-ativo,
.tab-valores-estrategicos-ativo:hover {
  background-color: #e9aa00;
  color: white;
}

.v-tab::before {
  border-radius: 0 38px 38px 0;
}

.tab-valores-estrategicos-ativo p {
  color: #ffffff;
}

.tab-valores-estrategicos-ativo > .iconeNavegacao {
  filter: brightness(0) invert(1);
  border-color: #ffffff;
}

.bg-valores {
  background-color: #f6f6f6;
}

.iconeNavegacao {
  margin-left: -5px;
  border-left: 1px solid #565656;
  padding-left: 15px;
}

.divider-categoria {
  max-width: 90%;
  border-width: 2px;
  border-radius: 4px;
}

.titulo-categoria {
  position: relative;
  padding: 1rem;
  margin: 0 0 2rem;
  background-color: #f5f5f5;
  text-align: center;
}

.titulo-categoria-texto {
  position: relative;
  padding: 1rem;
  margin: 0 0 2rem;
  text-align: center;
}

.titulo-categoria::after {
  content: "";
  position: absolute;
  right: calc(50% - 50px);
  top: 100%;
  border-left: 50px solid transparent;
  border-right: 50px solid transparent;
  border-top: 50px solid #f5f5f5;
  z-index: 1;
}

.iframeBanner {
  width: 80%;
  height: 452px;
  border: none;
  border-radius: 2rem;
}

/* Missao e Visão */
.card {
  position: relative;
  background: #fff;
  border: 0.5rem solid #00838f;
  border-radius: 2rem !important;
  box-shadow: rgb(100 100 111 / 20%) 0px 8px 28px 0px !important;
}

.card::before {
  content: "";
  position: absolute;
  left: -3.25rem;
  height: 6rem;
  width: 6rem;
  background: #00838f;
  border-radius: 100%;
  background-size: 4rem;
  background-repeat: no-repeat;
  background-position: center;
  z-index: 2;
}

.card-missao::before {
  background-image: url("../assets/images/icone/icone_missao.png");
}

.card-visao::before {
  background-image: url("../assets/images/icone/icone_visao.png");
}

.card-missao-visao {
  position: relative;
  margin-left: 1rem;
  padding: 1rem 2rem;
  background: #fff;
  z-index: 1;
}

.mapaAreas {
  margin-top: 25px;
  margin-bottom: 25px;
}

.mapaAreasImg {
  max-width: 70%;
  margin: auto;
}

.diretoria {
  position: absolute;
  left: 45%;
  top: 7%;
  width: 9%;
  height: 17%;
  cursor: pointer;
}

.ascom {
  position: absolute;
  left: 17%;
  top: 29%;
  width: 14%;
  height: 20%;
  cursor: pointer;
}

.asjur {
  position: absolute;
  left: 68%;
  top: 29%;
  width: 13%;
  height: 17%;
  cursor: pointer;
}

.gpc {
  position: absolute;
  left: 0%;
  top: 58%;
  width: 13%;
  height: 20%;
  cursor: pointer;
}

.gov {
  position: absolute;
  left: 17%;
  top: 58%;
  width: 17%;
  height: 20%;
  cursor: pointer;
}

.geinfra {
  position: absolute;
  left: 34%;
  top: 58%;
  width: 15%;
  height: 23%;
  cursor: pointer;
}

.gedev {
  position: absolute;
  left: 51%;
  top: 58%;
  width: 14%;
  height: 23%;
  cursor: pointer;
}

.gecom {
  position: absolute;
  left: 68%;
  top: 58%;
  width: 15%;
  height: 20%;
  cursor: pointer;
}

.gefin {
  position: absolute;
  left: 84%;
  top: 58%;
  width: 16%;
  height: 20%;
  cursor: pointer;
}

.mapaTitle {
  background-color: inherit !important;
}

.mapaTitle .v-icon {
  color: #278b97 !important;
  margin-bottom: 0.5rem;
}

.v-card > .v-card__title > .v-icon {
  position: absolute;
  right: 24px;
}

.containerF7 {
  display: flex;
  justify-content: end;
  background-color: #ffffff;
  padding: 4% 0% 1% 0%;
}

.containerDividerEstrutura {
  display: flex;
  justify-content: start;
  background-color: #ffffff;
  padding: 4% 0% 1% 0%;
}

.dividerEstrutura {
  background-color: #2ea1ac;
  color: #2ea1ac;
  max-width: 87%;
  height: 53px;
  max-height: 4px;
  border: solid;
  border-width: thin 5px 2px 3px;
  border-radius: 92px;
}

.dividerF7 {
  background-color: #b4b4b4;
  color: #b4b4b4;
  max-width: 87%;
  height: 53px;
  max-height: 4px;
  border: solid;
  border-width: thin 5px 2px 3px;
  border-radius: 92px;
}

.link {
  text-decoration: none;
}

.banner-btn {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
}

.banner-jeito-hepta {
  position: absolute;
  right: 0;
  z-index: 1;
}

@media screen and (max-width: 1280px) {
  .mapaAreasImg {
    max-width: 100%;
  }
}

@media screen and (max-width: 960px) {
  .destaque {
    height: 100%;
    width: 80%;
    top: 0;
  }

  .linha-numeracao::after {
    display: none;
  }

  .textonumeracao {
    line-height: inherit;
  }

  .card {
    margin: 4rem 0 0 !important;
  }

  .card-missao::before,
  .card-visao::before {
    top: -20%;
    left: calc(50% - 50px);
  }

  .card-missao-visao {
    padding: 1.5rem;
  }

  .tabs {
    padding-top: 50px;
  }

  .tab-valores-estrategicos {
    border-radius: 56px;
    width: 250px !important;
    padding: 0;
    justify-content: center;
    margin-right: 10px;
  }

  .iconeNavegacao {
    margin: 0;
    border: none;
    padding-left: 15px;
    max-width: 50px;
  }

  .mapaAreas {
    margin-top: 50px;
    margin-bottom: 50px;
  }
}

@media (min-width: 960px) and (max-width: 1264px) {
  .linha-numeracao::after {
    min-height: 35px;
    top: 110px;
  }
}
</style>
