var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('loader',{attrs:{"loader-primario":_vm.loader}}),_c('alerta',{attrs:{"exibir":_vm.modal.exibir,"titulo":_vm.modal.titulo,"mensagem":_vm.modal.mensagem},on:{"fecharModal":_vm.fecharModal,"exclusao":function($event){return _vm.excluir()}}}),_c('ModalSistemasEServicos',{attrs:{"item":_vm.sistemaEdicao,"tags":_vm.tags,"categorias":_vm.filtros},on:{"fecharModal":_vm.fecharModalSistemas,"fecha-modal":function($event){(_vm.modalSistema = false), _vm.limparCampos()},"salvar":_vm.salvou,"capturarMensagemErro":_vm.mensagemErro,"capturarMensagemSucesso":_vm.mensagemSucesso},model:{value:(_vm.modalSistema),callback:function ($$v) {_vm.modalSistema=$$v},expression:"modalSistema"}}),_c('v-container',[_c('div',{staticClass:"card"},[_c('div',{staticClass:"inicio-titulo"},[_c('v-img',{staticClass:"icone-titulo",attrs:{"src":require("@/assets/images/icone/espaco-colaborador/icone_sistemas.svg")}}),_c('h1',{staticClass:"titulo"},[_vm._v("Sistemas e Serviços")])],1),(_vm.admin)?_c('v-row',{staticClass:"justify-end my-0"},[_c('v-btn',{staticClass:"botao-principal",on:{"click":function($event){return _vm.abrirModal(null)}}},[_c('v-icon',{staticClass:"mr-2",attrs:{"small":""}},[_vm._v("fas fa-circle-plus")]),_vm._v(" Adicionar ")],1)],1):_vm._e(),_c('v-row',{staticClass:"areas"},[_c('v-col',{attrs:{"cols":"12"}},[_vm._v("Selecione a área ou pesquise o termo")]),_vm._l((_vm.filtros),function(filtro){return _c('v-col',{key:filtro.id,attrs:{"cols":"6","md":"3"}},[_c('v-card',{class:_vm.filtroPorCategoria == filtro.titulo
                ? 'card-arquivo card-arquivo-active'
                : 'card-arquivo',attrs:{"tabindex":"none"},on:{"click":function($event){return _vm.listarCategorias(filtro.id, filtro.titulo, 1)}}},[_c('v-img',{attrs:{"src":filtro.img}}),_c('p',[_vm._v(_vm._s(filtro.titulo))])],1)],1)})],2),_c('v-container',{staticClass:"mt-5"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{staticClass:"input-field",attrs:{"clearable":"","dense":"","outlined":"","hide-details":"auto"},model:{value:(_vm.textoPesquisa),callback:function ($$v) {_vm.textoPesquisa=$$v},expression:"textoPesquisa"}},[_c('template',{slot:"label"},[_c('span',{staticClass:"input-field-text"},[_vm._v("Pesquisar")])]),_c('template',{slot:"append"},[_c('v-icon',{staticClass:"input-field-text"},[_vm._v("fa-solid fa-magnifying-glass")])],1)],2)],1)],1)],1),_vm._l((_vm.arquivo),function(arq){return _c('v-col',{key:arq.id,attrs:{"cols":"12"}},[_c('FormatoArquivo',{attrs:{"arquivo":arq},on:{"update":_vm.update,"excluir":_vm.excluir}})],1)}),(_vm.arquivo.length === 0)?_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"alerta"},[_c('v-row',[_c('v-col',{staticClass:"alerta-icone",attrs:{"cols":"12","md":"2","xl":"1"}},[_c('v-img',{attrs:{"src":require("@/assets/images/icone/espaco-colaborador/icone_sinal_de_aviso.svg")}})],1),_c('v-col',{staticClass:"alerta-texto",attrs:{"cols":"12","md":"10","xl":"11"}},[_c('span',[_vm._v("Não há nenhum serviço ou sistema cadastrado para essa área.")])])],1)],1)]):_vm._e(),_c('div',{staticClass:"text-center"},[_c('v-container',[_c('v-row',{attrs:{"justify":"center"}},[_c('v-container',{staticClass:"max-width"},[_c('v-pagination',{attrs:{"prev-icon":" fa-light fa-chevron-left","next-icon":"fa-light fa-chevron-right","length":_vm.paginacao.maxPage,"circle":"","dense":"","solo":""},on:{"input":function($event){return _vm.listarCategorias(
                    _vm.idFiltroSelecionado,
                    _vm.filtroPorCategoria,
                    _vm.paginacao.page
                  )}},model:{value:(_vm.paginacao.page),callback:function ($$v) {_vm.$set(_vm.paginacao, "page", $$v)},expression:"paginacao.page"}})],1)],1)],1)],1)],2)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }