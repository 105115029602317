<template>
  <div class="relative">
    <ModalEdicaoDestaque v-model="modalEdicao" :idDestaque="novidade.id" @fecha-modal="modalEdicao = false" @sucesso-edicao="emitirSucesso()" />

    <v-btn v-if="admin" @click="abrirModalEdicao()" class="botao-edicao-adm posicao-botao">
      <v-icon>fa fa-pencil</v-icon>
    </v-btn>
    <v-card @click="abrirLink(novidade.link)" class="card-novidade" :style="{ background: novidade.codigoCor }">
      <v-row>
        <v-col cols="12" v-if="novidade.icone">
          <v-img :src="novidade.icone" />
        </v-col>

        <v-col cols="12" v-if="novidade.icone">
          <v-divider />
        </v-col>

        <v-col cols="12">
          <span class="titulo-custom">
            {{ novidade.titulo }}
          </span>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>

<script>
import ModalEdicaoDestaque from '@/components/Modal/ModalEdicaoDestaqueHome.vue';

export default {
  name: "CardNovidadeCustom",
  components: { ModalEdicaoDestaque },
  props: {
    novidade: { Type: Object, required: true },
    admin: { Type: Boolean, required: true },
  },
  data() {
    return {
      modalEdicao: false,
    };
  },
  methods: {
    abrirLink(link) {
      window.open(link, '_blank');
    },
    abrirModalEdicao() {
      this.modalEdicao = true;
    },
    emitirSucesso(){
      this.$emit("sucesso-edicao");
    }
  },
}
</script>

<style scoped>
.relative {
  position: relative;
}

.card-novidade {
  height: 100%;
  padding: 10% 15%;
  border-radius: 1rem !important;
  box-shadow: none !important;
  color: #FFF !important;
  font-size: 1rem;
}

.card-novidade .v-divider {
  border-color: #FFF !important;
}

.card-novidade .v-image {
  margin: 0 auto;
  width: 30%;
  filter: brightness(0) invert(1);
}

.posicao-botao {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
}

.titulo-custom {
  font-weight: bold;
}
</style>