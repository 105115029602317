<template>
  <v-dialog v-if="dialog" v-model="dialog" @click:outside="fecharModal" width="1800">
    <v-card>
      <v-card-title>
        <h3>Registrar Deslocamento</h3>
      </v-card-title>

      <v-card-text>
        <v-form ref="deslocamento-form" lazy-validation>
          <v-container>
            <v-row>
              <v-col cols="12" md="3">
                <v-text-field
                  :disabled="!!idDeslocamento"
                  v-model="ticket"
                  label="Ticket"
                  dense
                  outlined
                  hide-details="auto"
                  required
                  :rules="[v => !!v || 'Digite o ticket de atendimento']"
                />
              </v-col>
              <v-col cols="12" md="3">
                <v-text-field
                  v-model="origem"
                  label="Origem"
                  dense
                  outlined
                  hide-details="auto"
                  required
                  :rules="[v => !!v || 'Digite o endereço de origem']"
                />
              </v-col>
              <v-col cols="12" md="3">
                <v-text-field
                  v-model="destino"
                  label="Destino"
                  dense
                  outlined
                  hide-details="auto"
                  required
                  :rules="[v => !!v || 'Digite o endereço de destino']"
                />
              </v-col>
              <v-col cols="12" md="3">
                <v-menu
                  v-model="data"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                  id="editDataVerificacao"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-bind="attrs"
                      v-model="dataVisita"
                      class="select"
                      outlined
                      dense
                      label="Data"
                      placeholder="DD/MM/AAAA"
                      required
                      :rules="[v => !!v || 'Digite uma data válida']"
                      v-on="on"
                      hide-details="auto"
                      clearable
                    >
                      <template slot="append">
                        <v-icon color="primary">fas fa-calendar-days</v-icon>
                      </template>
                    </v-text-field>
                  </template>

                  <v-date-picker
                    v-model="datapicker"
                    required
                    @input="dataVisita = formatDate(datapicker)"
                    :rules="[v => !!v || 'Selecione uma data']"
                    color="primary"
                    locale="pt-br"
                    no-title
                    scrollable
                  ></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
          </v-container>
        </v-form>

        <v-divider class="ma-3" />

        <v-container>
          <v-row>
            <v-col cols="12">
              <h4>Registre os transportes:</h4>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="9">
              Adicione os trechos percorridos para deslocamento até o cliente. Preencha a linha ou rota na descrição,
              valor e tipo de transporte utilizado.
            </v-col>
            <v-col cols="12" md="3">
              <v-btn :disabled="novo" @click="novo = true" class="botao-cancelar w-100">
                <v-icon class="mr-2">fa-plus</v-icon>
                Cadastrar novo
              </v-btn>
            </v-col>
          </v-row>
        </v-container>

        <v-form ref="transporte-form" v-model="formTransporte" v-if="novo">
          <v-container>
            <v-row>
              <v-col cols="12" md="4">
                <v-text-field
                  v-model="transporteForm.descricao"
                  label="Descrição"
                  dense
                  outlined
                  hide-details="auto"
                  required
                  :rules="[v => !!v || 'Adicione uma descrição']"
                />
              </v-col>
              <v-col cols="12" md="4">
                <v-select
                  v-model="transporteForm.idModal"
                  label="Tipo de Transporte"
                  :items="modais"
                  item-text="modal"
                  item-value="id"
                  dense
                  outlined
                  hide-details="auto"
                  required
                  :rules="[v => !!v || 'Selecione uma opção']"
                />
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field
                  v-model="transporteForm.valorGasto"
                  label="Valor"
                  type="Number"
                  dense
                  outlined
                  hide-details="auto"
                  required
                  :rules="[v => !!v || 'Digite o valor', v => v > 0 || 'Digite um valor válido']"
                />
              </v-col>
            </v-row>

            <v-row class="justify-center">
              <v-col cols="12" md="3">
                <v-btn @click="pushTransporte" class="botao-principal w-100">Adicionar</v-btn>
              </v-col>
              <v-col cols="12" md="3">
                <v-btn @click="limparFormTransporte" class="botao-cancelar w-100">Cancelar</v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-form>

        <v-banner v-for="(transporte, index) in transportes" :key="index" botao-principal w-100>
          <v-row class="d-flex align-center">
            <v-col cols="6" md="3">
              <b>Descrição:</b>
              {{ transporte.descricao }}
            </v-col>
            <v-col cols="6" md="3">
              <b>Transporte:</b>
              {{ modais.find(m => m.id == transporte.idModal).modal }}
            </v-col>
            <v-col cols="6" md="3">
              <b>Valor:</b>
              {{ formatMoney(transporte.valorGasto) }}
            </v-col>

            <v-spacer></v-spacer>

            <v-col cols="auto">
              <v-btn @click="transportes.splice(index, 1)" color="botao-excluir">
                <v-icon class="mr-2">fa-trash</v-icon>
                Excluir
              </v-btn>
            </v-col>
          </v-row>
        </v-banner>

        <v-divider class="mx-3 my-6" />
      </v-card-text>

      <v-card-actions>
        <v-row class="justify-center">
          <v-col cols="12" md="4">
            <v-btn @click="fecharModal" class="botao-cancelar w-100">Cancelar</v-btn>
          </v-col>
          <v-col cols="12" md="4">
            <v-btn
              v-if="!idDeslocamento"
              :disabled="transportes.length == 0"
              @click="subimitDeslocamento"
              class="botao-principal w-100"
            >
              Concluir
            </v-btn>
            <v-btn
              v-else
              :disabled="!temMudancas || transportes.length == 0"
              @click="atualizarDeslocamento"
              class="botao-principal w-100"
            >
              Atualizar
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { formatDate, formatMoney } from '@/utils/Utils';
import ServiceVisitaTecnica from '@/services/ServiceVisitaTecnica';

export default {
  name: 'CadastroDeslocamento',
  props: ['idDeslocamento'],
  data() {
    return {
      dialog: false,
      novo: false,
      data: null,
      datapicker: '',
      enderecoOrigem: null,
      enderecoDestino: null,
      OdthVisita: null,
      transpostes: null,
      dataVisita: null,
      destino: null,
      origem: null,
      ticket: null,
      deslocamento: {},
      formTransporte: null,
      transporteForm: {},
      transportes: [],
      modais: [],
    };
  },
  methods: {
    formatMoney,
    formatDate,
    atualizarDeslocamento() {
      let vm = this;
      if (!vm.$refs['deslocamento-form'].validate() || !(vm.transportes.length > 0)) return;
      const toCreateTransportes = vm.transportes.filter(t => t.idTranporte == null);
      const toDeleteTransportes = vm.transpostes.filter(
        to => !vm.transportes.filter(t => !!t.idTranporte).some(t => t.idTranporte == to.idTranporte)
      );

      vm.$emit('loader', true);
      Promise.all([
        ...toDeleteTransportes.map(t => {
          return ServiceVisitaTecnica.deletarTransporte(t.idTranporte);
        }),
      ]).finally(() => vm.$emit('loader', false));
      const deslocamento = {
        id: vm.deslocamento.id,
        origem: vm.origem,
        destino: vm.destino,
        data: vm.dataVisita,
        transportes: toCreateTransportes,
      };
      deslocamento.data = deslocamento.data.split('/').reverse().join('-');
      ServiceVisitaTecnica.atualizarDeslocamento(deslocamento)
        .then(res => {
          vm.$emit('sucesso', res.data);
          vm.limparFormularios();
        })
        .catch(err => {
          vm.$emit('erro', err);
        })
        .finally(() => vm.$emit('loader', false));
      vm.fecharModal();
    },
    subimitDeslocamento() {
      let vm = this;
      if (vm.$refs['deslocamento-form'].validate() && vm.transportes.length > 0) {
        const deslocamento = {
          origem: vm.origem,
          destino: vm.destino,
          data: vm.dataVisita,
          ticket: vm.ticket,
        };
        deslocamento.data = deslocamento.data.split('/').reverse().join('-');
        vm.$emit('loader', true);
        ServiceVisitaTecnica.postDeslocamento({
          transportes: vm.transportes,
          ...deslocamento,
        })
          .then(res => {
            vm.$emit('sucesso', res.data);
            vm.limparFormularios();
          })
          .catch(err => {
            vm.$emit('erro', err);
          })
          .finally(() => vm.$emit('loader', false));
        vm.fecharModal();
      }
    },
    pushTransporte() {
      let vm = this;
      const form = vm.$refs['transporte-form'];
      if (form.validate()) {
        vm.transportes.push(vm.transporteForm);
        vm.transporteForm = {};
        form.reset();
        vm.novo = false;
      } else {
        vm.$emit('erro', 'Preencha corretamente os campos');
      }
    },
    limparFormTransporte() {
      let vm = this;
      vm.novo = false;
    },
    limparFormularios() {
      let vm = this;
      vm.deslocamento = {};
      vm.transportes = [];
    },
    fecharModal() {
      let vm = this;
      vm.$emit('close');
      vm.dialog = false;
      vm.transportes = [];
      vm.deslocamento = {};
      vm.origem = null;
      vm.destino = null;
      vm.dataVisita = null;
      vm.ticket = null;
    },
    buscarTiporTransportes() {
      let vm = this;
      ServiceVisitaTecnica.tiposModais()
        .then(res => (vm.modais = res.data))
        .catch(err => vm.$emit('erro', err));
    },
    buscarDeslocamento() {
      let vm = this;
      if (vm.idDeslocamento) {
        return ServiceVisitaTecnica.despesasDeslocamento(vm.idDeslocamento).then(res => {
          vm.enderecoOrigem = res.data.enderecoOrigem;
          vm.origem = res.data.enderecoOrigem;
          vm.enderecoDestino = res.data.enderecoDestino;
          vm.destino = res.data.enderecoDestino;
          vm.dataVisita = res.data.dthVisita.split('T')[0].split('-').reverse().join('/');
          vm.OdthVisita = res.data.dthVisita;
          vm.ticket = res.data.ticket;
          vm.transpostes = res.data.transpostes;
          vm.deslocamento = {};
          vm.deslocamento = {
            ...res.data,
          };
          vm.deslocamento.id = res.data.id;
          vm.deslocamento.ticket = res.data.ticket;
          vm.deslocamento.origem = res.data.enderecoOrigem;
          vm.deslocamento.destino = res.data.enderecoDestino;
          vm.deslocamento.data = res.data.dthVisita.split('T')[0].split('-').reverse().join('/');
          vm.transportes = res.data.transpostes.map(t => {
            const newT = {
              ...t,
            };
            newT.idModal = t.modal.id;
            return newT;
          });
        });
      }
    },
  },
  computed: {
    temMudancaDeslocamento() {
      let vm = this;
      return (
        vm.enderecoOrigem != vm.origem ||
        vm.enderecoDestino != vm.destino ||
        vm.OdthVisita?.split('T')[0].split('-').reverse().join('/') != vm.dataVisita
      );
    },
    temMudancas() {
      let vm = this;
      return (
        this.temMudancaDeslocamento ||
        vm.transportes.some(t => t.idTranporte == null) ||
        vm.transportes.length != (vm.transpostes?.length || 0)
      );
    },
  },
  watch: {
    idDeslocamento() {
      this.buscarDeslocamento();
    },
  },
  created() {
    let vm = this;
    vm.buscarTiporTransportes();
  },
};
</script>
<style scoped></style>
