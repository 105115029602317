<template>
  <v-row class="card-geral">
    <v-col cols="12" md="3" xl="2" class="hidden-sm-and-down">
      <v-card class="card-imagem">
        <v-img :src="'data:image/png;base64, ' + arquivo.imagem"></v-img>
      </v-card>
    </v-col>
    <v-col cols="12" md="9" xl="10">
      <v-card class="card-servico">
        <v-chip v-if="arquivo.rascunho && admin" class="rascunho"
          >Rascunho</v-chip
        >
        <v-card-text>
          <v-row class="my-0">
            <v-col cols="12" md="8" xl="10" class="col-dados-sistema">
              <v-row>
                <h1>{{ arquivo.titulo }}</h1>
              </v-row>
              <v-row>{{ arquivo.descricao }}</v-row>
              <v-chip
                v-for="(tags, index) in arquivo.tags"
                :key="index"
                class="chip-categoria"
                >{{ tags.nome }}</v-chip
              >
            </v-col>
            <v-col cols="12" md="4" xl="2" class="botoes">
              <v-btn :href="arquivo.url" class="botao-principal w-100">
                <v-img
                  src="@/assets/images/icone/espaco-colaborador/icone_acessar.svg"
                  class="icone-botao"
                ></v-img>
                Acessar Sistema
              </v-btn>
              <div v-if="admin" class="botoes-admin">
                <v-icon @click="$emit('update', arquivo)" color="#14AB51"
                  >fas fa-pen</v-icon
                >
                <v-icon @click="$emit('excluir', arquivo.id)" color="#D91727"
                  >fas fa-trash</v-icon
                >
              </div>
              <div v-else class="espacamento"></div>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import Utils from "@/utils/Utils";

export default {
  props: ["arquivo"],
  data() {
    return {
      dadosArquivo: [],
      admin: false,
    };
  },
  methods: {
    isAdmin() {
      this.admin = Utils.isAdmin();
    },
  },
  created() {
    this.isAdmin();
  },
};
</script>

<style scoped>
.card-geral {
  display: flex;
}

.card-geral .col {
  display: flex;
  height: 100%;
}

.card-imagem {
  height: 100%;
  border: 1px solid #278b97;
  border-radius: 20px;
  overflow: hidden;
  box-shadow: none !important;
}

.card-imagem .v-image {
  max-height: 10rem;
}

.card-servico {
  background-color: #eff9fa;
  border: 1px solid #278b97;
  border-radius: 20px;
  box-shadow: none !important;
  height: 100%;
}

.rascunho {
  position: absolute;
  top: calc(0% - 0.75rem);
  margin: 0 1rem;
  padding: 0 0.5rem;
  background-color: #f8ba00 !important;
  color: #fff;
}

.col-dados-sistema {
  padding: 0 0 0 1rem;
}

.col-dados-sistema .row {
  margin: 0;
  color: #616161;
}

.chip-categoria {
  position: absolute;
  top: calc(100% - 3rem);
  background-color: #278b97 !important;
  color: #fff;
  font-size: 0.75rem;
  margin-top: 0.5rem;
  padding: 0 0.5rem;
}

.botoes {
  height: 100%;
  padding: 0 0.5rem;
  text-align: right;
}

.icone-botao {
  max-width: 1rem;
  margin-right: 0.5rem;
}

.botoes-admin .v-icon {
  font-size: 1.25rem;
  margin: 1rem 0.5rem;
}

.espacamento {
  /* Para que haja espaço para os chips de categoria no mobile */
  padding: 1rem;
}
</style>
