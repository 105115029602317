<template>
  <div>
    <loader :loader-primario="loader" />

    <alerta
      v-on:fecharModal="fecharModal"
      :exibir="modal.exibir"
      :titulo="modal.titulo"
      :mensagem="modal.mensagem"
    />

    <modalAtestado
      v-model="modalAtestado"
      :atestado="atestado"
      v-on:fecha-modal="modalAtestado = false"
      @salvar="salvarAtestado"
    />

    <v-container>
      <div class="card">
        <div class="inicio-titulo">
          <v-img
            class="icone-titulo"
            src="@/assets/images/icone/espaco-colaborador/icone_atestado.svg"
          ></v-img>
          <h1 class="titulo">Meus Atestados</h1>
        </div>

        <!-- Aviso -->
        <v-row>
          <v-col cols="12">
            <Aviso
              message="Os atestados médicos devem ser homologados e enviados em até 48 horas úteis após a 
              emissão. Após esse prazo o sistema não irá mais aceitar o envio."
            />
            <v-col cols="12">
              <p class="font-weight-bold">
                Informações completas sobre o processo para envio e homologação
                de atestados médicos estão disponíveis em:
                <a
                  href="https://nossahepta.com.br/IntranetHepta/#/detalhe-academia?id=503&pesquisa=false&artigo=true"
                  target="_blank"
                >
                  Espaço do Colaborador > Saúde > Atestados Médicos
                </a>
              </p>
            </v-col>
          </v-col>
        </v-row>

        <!-- Adicionar Atestado -->
        <v-row class="justify-end">
          <v-col cols="12" md="4" xl="2">
            <v-btn @click="abrirModal(null)" class="botao-principal w-100">
              <v-icon class="mr-2">fas fa-circle-plus</v-icon>
              Adicionar Atestado
            </v-btn>
          </v-col>
        </v-row>

        <!-- Filtros -->
        <v-row>
          <v-col cols="12" md="6">
            <v-menu
              v-model="dataAtestado"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="auto"
              id="editDataVerificacao"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  maxlength="10"
                  v-model="filtroDataAtestado"
                  @input="pagina = [1]"
                  class="input-field"
                  outlined
                  dense
                  placeholder="DD/MM/AAAA"
                  v-on="on"
                  hide-details="auto"
                  v-bind="attrs"
                >
                  <template slot="label">
                    <span class="input-field-text">Data do Atestado</span>
                  </template>
                  <template v-slot:append>
                    <v-icon class="input-field-text"
                      >fas fa-calendar-days</v-icon
                    >
                  </template>
                </v-text-field>
              </template>
              <v-date-picker
                color="#278b97"
                v-model="filtroDataAtestadoFormatado"
                required
                @input="
                  filtroDataAtestado = formatDate(filtroDataAtestadoFormatado)
                "
                :rules="[(v) => !!v || 'O campo Data é obrigatório.']"
                locale="pt-br"
                no-title
                scrollable
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="12" md="6">
            <v-menu
              v-model="dataAtualizacao"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="auto"
              id="editDataVerificacaoAtualizacao"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  maxlength="10"
                  v-model="filtroDataAtualização"
                  class="input-field"
                  outlined
                  dense
                  placeholder="DD/MM/AAAA"
                  v-on="on"
                  hide-details="auto"
                  v-bind="attrs"
                >
                  <template slot="label">
                    <span class="input-field-text">Data da Atualização</span>
                  </template>
                  <template v-slot:append>
                    <v-icon class="input-field-text"
                      >fas fa-calendar-days</v-icon
                    >
                  </template>
                </v-text-field>
              </template>
              <v-date-picker
                color="#278b97"
                v-model="filtroDataAtualização"
                required
                @input="
                  filtroDataAtualização = formatDate(filtroDataAtualização)
                "
                :rules="[(v) => !!v || 'O campo Data é obrigatório.']"
                locale="pt-br"
                no-title
                scrollable
              ></v-date-picker>
            </v-menu>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="9">
            <v-autocomplete
              @input="pagina = [1]"
              class="input-field"
              clearable
              chips
              outlined
              dense
              hide-details="auto"
              height="40px"
              v-model="filtroDataStatus"
              :items="listaStatus"
              item-text="text"
              multiple
            >
              <template slot="label">
                <span class="input-field-text">Status:</span>
              </template>
              <template v-slot:append>
                <v-icon class="input-field-text">fas fa-chevron-down</v-icon>
              </template>
            </v-autocomplete>
          </v-col>
          <v-col cols="12" md="3">
            <v-btn @click="limparFiltro()" class="botao-limpar"
              >Limpar Buscas</v-btn
            >
          </v-col>
        </v-row>

        <!-- Tabela -->
        <v-row>
          <v-col cols="12">
            <v-data-table
              v-model:pagina="pagina"
              :headers="headers"
              :items="tabelaAtestados"
              :items-per-page="porPagina"
              hide-default-footer
              @update:options="options = $event"
              :no-data-text="tabelaVazia()"
            >
              <template v-slot:item.dataAtualizacao="{ item }">{{
                formatDateTime(item.dataAtualizacao)
              }}</template>
              <template v-slot:item.item-status="{ item }">
                <div :class="status(item.status.descricao)">
                  {{ item.status.descricao }}
                </div>
              </template>
              <template v-slot:item.acao="{ item }">
                <v-tooltip top>
                  <template
                    v-slot:activator="{ on, attrs }"
                    class="acoes-tabela"
                  >
                    <v-icon
                      @click="abrirModal(item)"
                      v-bind="attrs"
                      v-on="on"
                      class="icone-acao"
                      >fas fa-eye</v-icon
                    >
                  </template>
                  <span>Visualizar</span>
                </v-tooltip>
              </template>
            </v-data-table>
          </v-col>
          <v-col cols="12" class="text-center">
            <v-container>
              <v-row justify="center">
                <v-col cols="5">
                  <v-container class="max-width">
                    <v-pagination
                      prev-icon=" fa-light fa-chevron-left"
                      next-icon="fa-light fa-chevron-right"
                      v-model="pagina[0]"
                      :length="Math.ceil(atestados.length / porPagina)"
                      @input="mudarPagina()"
                      circle
                      dense
                      solo
                    ></v-pagination>
                  </v-container>
                </v-col>
              </v-row>
            </v-container>
          </v-col>
        </v-row>
      </div>
    </v-container>
  </div>
</template>

<script>
import loader from "@/components/Loader.vue";
import alerta from "@/components/Alerta.vue";
import api from "@/services/Api.js";
import modalMixin from "@/mixins/modalMixin";
import modalAtestado from "@/components/EspacoDoColaborador/Atestado/ModalAtestados.vue";
import { formatDateAutomatic } from "@/utils/Utils";
import Aviso from "@/components/Aviso.vue";

export default {
  components: { loader, alerta, modalAtestado, Aviso },
  mixins: [modalMixin],
  name: "atestados",
  data() {
    return {
      options: {
        pageCount: 1,
      },
      pagina: [1],
      porPagina: 5,
      listaStatus: [
        { text: "Em análise", value: 0 },
        // { text: "Pendente", value: 1 },
        { text: "Aceito", value: 2 },
        { text: "Não Aceito", value: 3 },
      ],
      headers: [
        { text: "Data do Atestado", value: "dataAtestado" },
        { text: "Data da Atualização", value: "dataAtualizacao" },
        { text: "Quantidade de dias", value: "qtdDias" },
        {
          text: "Status",
          value: "item-status",
          sortable: false,
          align: "center",
        },
        {
          text: "Ação",
          value: "acao",
          sortable: false,
          align: "center",
        },
      ],
      atestados: [],
      tabelaAtestados: [],
      ufs: [],
      loader: false,
      filtro: {
        dataAtestado: null,
        dataAtualizacao: null,
        status: [],
      },
      dataAtualizacao: false,
      dataAtestado: false,
      filtroDataAtestado: null,
      filtroDataAtualização: null,
      filtroDataAtestadoFormatado: null,
      filtroDataAtualizacaoFormatado: null,
      filtroDataAtestadoFormatado: null,
      filtroDataStatus: [],
      modalAtestado: false,
      atestado: {},
      salvarHomologacao: null,
      salvarAt: null,
    };
  },
  watch: {
    filtroDataAtestado(e) {
      if (e.length == 10) {
        this.buscarAtestados();
      }

      this.filtroDataAtestado = this.formatDateAutomatic(e);
    },
    filtroDataAtualização(e) {
      if (e.length == 10) {
        this.buscarAtestados();
      }
      this.filtroDataAtualização = this.formatDateAutomatic(e);
    },
    filtroDataStatus() {
      this.buscarAtestados();
    },
  },
  methods: {
    formatDateAutomatic: formatDateAutomatic,
    abrirModal(atestado) {
      if (atestado != null) {
        this.atestado = atestado;
      } else {
        this.atestado = {
          id: null,
          nomeMedico: "",
          dataAtestado: "",
          qtdDias: 0,
          crm: "",
          cid: "",
          comentarios: "",
          status: {
            descricao: "",
            value: 0,
          },
          observacao: null,
          dataRegistro: "",
          dataAtualizacao: null,
          pessoa: null,
          documentos: [],
          gestorCiente: null,
        };
      }

      this.modalAtestado = true;
    },
    tabelaVazia() {
      return "Nenhum atestado encontrado!";
    },
    formatDate(e) {
      if (!e) return null;
      const [year, month, day] = e.split("-");
      this.dataAtualizacao = false;
      this.dataAtestado = false;
      return `${day}/${month}/${year}`;
    },
    limparFiltro() {
      this.pagina = [1];
      this.filtroDataAtestado = null;
      this.filtroDataAtualização = null;
      this.filtroDataStatus = [];
    },
    status(e) {
      if (e == "APROVADO") return "status-tabela status-aprovado";
      else if (e == "Não aceito") return "status-tabela status-invalidado";
      else return "status-tabela status-pendente";
    },
    buscarUfs() {
      const vm = this;
      this.loader = true;
      api
        .get("v2/dominio/uf")
        .then((res) => {
          res.data.forEach((e) => {
            vm.ufs.push(e.nomeDominio);
          });
        })
        .catch((e) => {
          this.mensagemErro(e.message);
        })
        .finally(() => {
          this.loader = false;
        });
    },
    filtrar() {
      const vm = this;
      vm.buscarAtestados();
    },
    mudarPagina() {
      if (this.pagina[0] > 1) {
        this.tabelaAtestados = this.atestados.slice(
          (this.pagina[0] - 1) * this.porPagina
        );
      } else {
        this.tabelaAtestados = this.atestados;
      }
    },
    preparandoFiltro() {
      this.filtro = {
        dataAtestado: this.revertFormatDate(this.filtroDataAtestado),
        status: this.filtroDataStatus,
      };
    },
    buscarAtestados() {
      const vm = this;
      vm.loader = true;

      vm.preparandoFiltro();
      api
        .get(`atestados/pessoa`, {
          params: {
            ...this.filtro,
          },
          paramsSerializer: { indexes: null },
        })
        .then((res) => {
          vm.atestados = res.data;
          res.data.forEach((element) => {
            element.dataAtestado = vm.formatarData(element.dataAtestado);
          });
          vm.tabelaAtestados = res.data;
        })
        .catch((e) => {
          this.mensagemErro(e.message);
        })
        .finally(() => {
          this.loader = false;
        });
    },
    async salvarDocumento(documento, tipo) {
      const vm = this;
      if (documento) {
        await api.post(`atestados/documento`, documento).then((res) => {
          if (tipo == "atestado") {
            vm.salvarAt = res.data;
          } else {
            vm.salvarHomologacao = res.data;
          }
        });
      }
    },
    async salvarAtestado(atestado, documentoAtestado, documentoHomologacao) {
      const vm = this;
      vm.loader = true;

      await vm.salvarDocumento(documentoAtestado, "atestado");
      await vm.salvarDocumento(documentoHomologacao), "homologacao";

      if (vm.salvarAt) atestado.documentos.push(vm.salvarAt);
      if (vm.salvarHomologacao) atestado.documentos.push(vm.salvarHomologacao);

      await api
        .post(`atestados`, atestado)
        .then((res) => {
          vm.loader = false;
          vm.mensagemSucesso(res.data);
        })
        .catch((e) => {
          vm.mensagemErro(e.message);
        })
        .finally(() => {
          vm.loader = false;
          vm.pagina = [1];
          vm.buscarAtestados();
        });
    },
    formatarData(data) {
      if (!data) return null;
      if (data == "Sem data") return data;

      const [ano, mes, dia] = data.includes("-")
        ? data.split("-")
        : data.split("/");
      return `${dia}/${mes}/${ano}`;
    },
    revertFormatDate(date) {
      if (!date) {
        return null;
      }
      const [day, month, year] = date.split("/");
      return `${year}-${month}-${day}`;
    },
    formatDateTime(dateTimeString) {
      if (!dateTimeString) return "-";
      const dateTime = new Date(dateTimeString);
      const day = dateTime.getDate().toString().padStart(2, "0");
      const month = (dateTime.getMonth() + 1).toString().padStart(2, "0");
      const year = dateTime.getFullYear();
      const hour = dateTime.getHours().toString().padStart(2, "0");
      const minutes = dateTime.getMinutes().toString().padStart(2, "0");

      return `${day}/${month}/${year} ${hour}:${minutes}`;
    },
  },
  async mounted() {
    const vm = this;
    await vm.buscarUfs();
    await vm.buscarAtestados();
  },
};
</script>

<style scoped>
.status-aprovado {
  background-color: #32acb9;
}

.status-invalidado {
  background-color: #db1826;
}

.status-pendente {
  background-color: #f8ba00;
}
</style>
