<template>
  <v-container>
    <v-dialog v-model="show" max-width="1200">
      <v-card>
        <v-card-title>
          <h3>Solicitações de mudança de benefício</h3>
          <v-icon @click="show = false">fa-times</v-icon>
        </v-card-title>

        <v-card-text>
          <v-row>
            <v-col cols="12" md="4">
              <v-text-field dense outlined hide-details="auto" class="input-field">
                <template v-slot:label>
                  <span class="input-field-text">Pesquisar</span>
                </template>
                <template v-slot:append>
                  <v-icon class="input-field-text">fas fa-search</v-icon>
                </template>
              </v-text-field>
            </v-col>
            <v-col cols="12" md="5">
              <v-select outlined dense hide-details="auto" :menu-props="{ bottom: true, offsetY: true }"
                class="input-field">
                <template v-slot:label>
                  <span class="input-field-text">Selecione um tipo de mudança</span>
                </template>
                <template v-slot:append>
                  <v-icon class="input-field-text">fas fa-chevron-down</v-icon>
                </template>
              </v-select>
            </v-col>
            <v-col cols="12" md="3">
              <v-select outlined dense hide-details="auto" :menu-props="{ bottom: true, offsetY: true }"
                class="input-field">
                <template v-slot:label>
                  <span class="input-field-text">Selecione um Status</span>
                </template>
                <template v-slot:append>
                  <v-icon class="input-field-text">fas fa-chevron-down</v-icon>
                </template>
              </v-select>
            </v-col>
            <v-col cols="12" md="4">
              <v-select outlined dense hide-details="auto" :menu-props="{ bottom: true, offsetY: true }"
                class="input-field">
                <template v-slot:label>
                  <span class="input-field-text">Selecione uma data do pedido</span>
                </template>
                <template v-slot:append>
                  <v-icon class="input-field-text">fas fa-chevron-down</v-icon>
                </template>
              </v-select>
            </v-col>
            <v-col cols="12" md="5">
              <v-select outlined dense hide-details="auto" :menu-props="{ bottom: true, offsetY: true }"
                class="input-field">
                <template v-slot:label>
                  <span class="input-field-text">Selecione uma data de atualização</span>
                </template>
                <template v-slot:append>
                  <v-icon class="input-field-text">fas fa-chevron-down</v-icon>
                </template>
              </v-select>
            </v-col>
            <v-col cols="12" md="3">
              <v-btn class="botao-limpar">Limpar Busca</v-btn>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <h4 class="text-left my-2">Histórico de solicitações de mudança</h4>
              <v-data-table :headers="cabecalhoMudancaPlano" :items="solicitacoes" :single-expand="singleExpand"
                :items-per-page="5" show-expand>
                <template #item.data-table-expand="{ _, expand, isExpanded }">
                  <v-icon @click="expand(!isExpanded)" class="icone-acao">fas fa-eye</v-icon>
                </template>

                <template v-slot:expanded-item="{ headers, item }">
                  <td :colspan="headers.length" class="py-5">
                    <v-row>
                      <v-col cols="12" lg="6">
                        <h4 class="destaque_solicitacao">
                          <v-icon small class="mr-2">fas fa-caret-right</v-icon>
                          Dados do pedido - Nº {{ item.id }}
                        </h4>
                      </v-col>
                      <v-col cols="12" md="6" lg="3">
                        <v-btn @click="abrirModalAnexo(item)" class="botao-principal w-100">Anexar documento</v-btn>
                      </v-col>
                      <v-col cols="12" md="6" lg="3">
                        <v-btn @click="removerPedido(item.id)" class="botao-cancelar w-100">Remover pedido</v-btn>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" md="4">
                        <div class="borda_solicitacao">
                          <p>
                            Benefício Solicitado:<br />
                            <span>{{ item.beneficio ? item.beneficio.descricao : "-" }}</span>
                          </p>
                        </div>
                      </v-col>
                      <v-col cols="12" md="6" lg="4">
                        <div class="borda_solicitacao">
                          <p>
                            Data do registro:<br />
                            <span>
                              {{ item.dataRegistro ? formatarDataHoraPadraoBrasileiro(item.dataRegistro) : "-" }}
                            </span>
                          </p>
                        </div>
                      </v-col>
                      <v-col cols="12" md="6" lg="4">
                        <div class="borda_solicitacao">
                          <p>
                            Tipo de solicitação:<br />
                            <span>{{ item.tipoMudanca.nome ? item.tipoMudanca.nome : "-" }}</span>
                          </p>
                        </div>
                      </v-col>
                      <v-col cols="12" md="6" lg="4">
                        <div class="borda_solicitacao">
                          <p>
                            Benefício Anterior: <br />
                            <span>{{ item.beneficioAnterior ? item.beneficioAnterior.descricao : "-" }}</span>
                          </p>
                        </div>
                      </v-col>
                      <v-col cols="12" md="6" lg="4">
                        <div class="borda_solicitacao">
                          <p>
                            Status: <br />
                            <span>{{ item.status ? item.status.replace("_", " ") : "-" }}</span>
                          </p>
                        </div>
                      </v-col>
                    </v-row>

                    <v-divider class="mb-4"></v-divider>

                    <v-row v-if="item.tipoMudanca.nome == 'Adicionar Dependente'" no-gutters>
                      <h4 class="destaque_solicitacao">
                        <v-icon small class="mr-2">fas fa-caret-right</v-icon> Dependentes
                      </h4>
                    </v-row>
                    <v-row v-for="dependente in item.dependentes" :key="dependente.id" class="mt-2">
                      <v-col cols="12" md="6" lg="4">
                        <div class="borda_solicitacao">
                          <p>
                            Nome:<br />
                            <span>{{ dependente.nome ? dependente.nome : "-" }}</span>
                          </p>
                        </div>
                      </v-col>
                      <v-col cols="12" md="6" lg="4">
                        <div class="borda_solicitacao">
                          <p>
                            CPF: <br />
                            <span>{{ dependente.cpf ? dependente.cpf : "-" }}</span>
                          </p>
                        </div>
                      </v-col>
                      <v-col cols="12" md="6" lg="4">
                        <div class="borda_solicitacao">
                          <p>
                            Data de Nascimento:<br />
                            <span>
                              {{ dependente.dtNascimento
                                ? formatarDataHoraPadraoBrasileiro(dependente.dtNascimento)
                                : "-" }}
                            </span>
                          </p>
                        </div>
                      </v-col>
                      <v-col cols="12" md="6" lg="4">
                        <div class="borda_solicitacao">
                          <p>
                            Sexo:<br />
                            <span>{{ dependente.sexo ? dependente.sexo : "-" }}</span>
                          </p>
                        </div>
                      </v-col>
                      <v-col cols="12" md="6" lg="4">
                        <div class="borda_solicitacao">
                          <p>
                            Parentesco: <br />
                            <span>
                              {{ dependente.grauParentesco.nome
                                ? dependente.grauParentesco.nome
                                : "-"
                              }}
                            </span>
                          </p>
                        </div>
                      </v-col>
                      <v-col cols="12" md="6" lg="4">
                        <div class="borda_solicitacao">
                          <p>
                            Nome Mãe: <br />
                            <span>{{ dependente.nomeMae ? dependente.nomeMae : "-" }} </span>
                          </p>
                        </div>
                      </v-col>

                      <v-divider></v-divider>
                    </v-row>
                  </td>
                </template>

                <template v-for="header in cabecalhoMudancaPlano.filter((header) => header.hasOwnProperty('formatter'))"
                  v-slot:[`item.${header.value}`]="{ value }">
                  {{ header.formatter(value) }}
                </template>
              </v-data-table>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <h4 class="text-left my-2">Histórico de beneficios</h4>
              <v-data-table :headers="cabecalhoHistorico" :items="historico" hide-default-footer></v-data-table>
            </v-col>
          </v-row>

          <!-- Ações Modal -->
          <v-card-actions>
            <v-col cols="12" md="4" offset-md="4">
              <v-btn @click="show = false" class="botao-sair mt-4">Sair</v-btn>
            </v-col>
          </v-card-actions>
        </v-card-text>
      </v-card>
    </v-dialog>

    <loader :loader-primario="loader" />

    <ModalAnexarDocumentos v-model="modalAnexar.show" :pedido="modalAnexar.pedido" />
  </v-container>
</template>

<script>
import api from "@/services/Api.js";
import loader from "@/components/Loader.vue";
import modalMixin from "@/mixins/modalMixin";
import { formatarDataHoraPadraoBrasileiro } from "@/utils/DateUtils.js";
import ModalAnexarDocumentos from "@/components/EspacoDoColaborador/Beneficios/modais/ModalAnexarDocumentos.vue";

export default {
  props: {
    value: Boolean,
    colaborador: Object,
  },
  components: {
    loader,
    ModalAnexarDocumentos,
  },
  mixins: [modalMixin],
  data() {
    return {
      loader: false,
      modalAnexar: {
        show: false,
        pedido: null,
      },
      cabecalhoMudancaPlano: [
        {
          text: "ID",
          value: "id",
          sortable: false,
        },
        {
          text: "Benefício desejado",
          value: "beneficio.descricao",
          formatter: (x) => (x ? x.toUpperCase() : "-"),
          sortable: false,
        },
        {
          text: "Tipo de mudança",
          value: "tipoMudanca.nome",
          formatter: (x) => (x ? x.toUpperCase() : "-"),
          sortable: false,
        },
        {
          text: "Data do pedido",
          value: "dataRegistro",
          align: "center",
          formatter: (x) => (x ? formatarDataHoraPadraoBrasileiro(x) : "-"),
          sortable: true,
        },
        {
          text: "Data de atualização",
          value: "dataAtualizacao",
          align: "center",
          formatter: (x) => (x ? formatarDataHoraPadraoBrasileiro(x) : "-"),
          sortable: false,
        },
        {
          text: "Status",
          value: "status",
          align: "center",
          formatter: (x) => (x ? x.replace("_", " ").toUpperCase() : "-"),
          sortable: false,
        },
        {
          text: "Detalhes",
          value: "data-table-expand",
          align: "center",
          sortable: false,
        },
      ],
      expanded: [],
      singleExpand: true,
      solicitacoes: [],
      historico: [],
      cabecalhoHistorico: [
        {
          text: "ID",
          value: "idBeneficio",
          sortable: false,
        },
        {
          text: "Benefício",
          value: "nomeBeneficio",
          formatter: (x) => (x ? x.toUpperCase() : "-"),
          sortable: false,
        },
        {
          text: "Tipo",
          value: "nomeTipo",
          sortable: false,
        },
        {
          text: "Descrição",
          value: "descricaoBeneficio",
          sortable: false,
        },
      ],
    };
  },
  methods: {
    formatarDataHoraPadraoBrasileiro,
    buscarPedidos() {
      const vm = this;
      vm.loader = true;
      api
        .get(`beneficios/pedido`)
        .then(async (res) => await (vm.solicitacoes = res.data))
        .catch((e) => vm.mensagemErro(e.message))
        .finally(() => (vm.loader = false));
    },
    removerPedido(id) {
      const vm = this;
      vm.loader = true;
      api
        .delete(`beneficios/remover-pedido/${id}`)
        .then(() => vm.buscarPedidos())
        .catch((e) => vm.mensagemErro(e.message));
    },
    abrirModalAnexo(item) {
      const vm = this;
      console.log(item);
      vm.modalAnexar = {
        pedido: item,
        show: true,
      };
      vm.show = false;
    },
    buscarHistoricoBeneficiosPessoa() {
      const vm = this;
      api
        .get(`beneficios/pessoa-historico`)
        .then((res) => {
          vm.historico = res.data;
        })
        .catch((e) => vm.mensagemErro(e.message));
    },
  },
  computed: {
    show: {
      get() {
        if (this.value == true) this.buscarPedidos();
        return this.value;
      },
      set(value) {
        const vm = this;
        this.$emit("input", value);
      },
    },
  },
  mounted() {
    const vm = this;
    vm.buscarHistoricoBeneficiosPessoa();
  },
};
</script>

<style scoped>
.v-dialog>.v-card>.v-card__text {
  padding: 2rem !important;
}

.input-field::v-deep .v-input__slot {
  background-color: #DDD !important;
}

.botao-sair {
  background-color: transparent !important;
  color: #278b97 !important;
  border-radius: 15px !important;
  height: 40px !important;
  width: 100%;
  border: 1px solid #278b97;
}

.destaque_solicitacao,
.destaque_solicitacao i {
  display: flex;
  align-items: center;
  color: #e7b814;
}

.borda_solicitacao {
  margin-top: 0px;
  border-left: 5px solid #278b97;
  height: 40px;
  padding-left: 10px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  color: #278b97;
  margin-bottom: 15px;
}

.borda_solicitacao p {
  text-align: left !important;
  color: #5d5d5d !important;
}

.borda_solicitacao span {
  color: #278b97 !important;
}
</style>
