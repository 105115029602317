var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',[_c('div',{staticClass:"alerta"},[_c('v-row',[_c('v-col',{staticClass:"alerta-icone",attrs:{"cols":"12","md":"2","xl":"1"}},[_c('v-img',{attrs:{"src":require("@/assets/images/icone/espaco-colaborador/icone_sinal_de_aviso.svg")}})],1),_c('v-col',{staticClass:"alerta-texto",attrs:{"cols":"12","md":"10","xl":"11"}},[_c('span',[_vm._v(" Para saber os valores da mensalidade, percentuais de participação da Hepta e outras informações sobre coparticipação,reembolso e carência, acesse "),_c('a',{attrs:{"href":"https://nossahepta.com.br/IntranetHepta/#/detalhe-academia?id=1207&pesquisa=false&artigo=true","target":"_blank"}},[_vm._v("Nossa Hepta > Espaço do Colaborador > Benefícios > Plano de Saúde")])])])],1)],1),_c('v-row',[_c('v-col',{staticClass:"titulo-e-divider"},[_c('h1',[_vm._v("Titular")]),_c('v-divider')],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6","lg":"4","xl":"3","offset-lg":"4","offset-xl":"6"}},[(_vm.beneficio.length === 0)?_c('v-btn',{staticClass:"botao-principal w-100",on:{"click":function($event){return _vm.abrirModalPlanoDeSaude(false)}}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("fas fa-plus")]),_vm._v(" Solicitar Plano de Saúde ")],1):_vm._e()],1),_c('v-col',{attrs:{"cols":"12","md":"6","lg":"4","xl":"3"}},[_c('v-btn',{staticClass:"botao-principal w-100",on:{"click":function($event){_vm.showMinhasSolicitacoes = true}}},[_c('v-img',{staticClass:"icone-solicitacoes",attrs:{"src":require("@/assets/images/icone/espaco-colaborador/icone_document.svg")}}),_vm._v(" Minhas Solicitações ")],1)],1)],1),_c('v-row',[_c('v-col',[_c('v-data-table',{attrs:{"headers":_vm.headerTitular,"items":_vm.beneficio,"hide-default-footer":true,"no-data-text":"Você não possui plano de saúde cadastrado!"},scopedSlots:_vm._u([_vm._l((_vm.headerTitular.filter((header) =>
            header.hasOwnProperty('formatter')
          )),function(header){return {key:`item.${header.value}`,fn:function({ value }){return [_vm._v(" "+_vm._s(header.formatter(value))+" ")]}}}),{key:`item.requerimento`,fn:function({ item }){return [_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-btn',{staticClass:"botao-amarelo",on:{"click":function($event){return _vm.abrirModalPlanoDeSaude(true, item)}}},[_vm._v(" Trocar Plano de Saúde ")])],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-btn',{staticClass:"botao-vermelho",on:{"click":function($event){return _vm.cancelarPlano(item)}}},[_vm._v("Cancelar Benefícios")])],1)],1)]}}],null,true)})],1)],1),_c('v-row',[_c('v-col',{staticClass:"titulo-e-divider"},[_c('h1',[_vm._v("Dependentes")]),_c('v-divider')],1)],1),_c('v-row',{staticClass:"justify-end espacamento-btn-adicionar my-5"},[_c('v-col',{attrs:{"cols":"12","md":"6","lg":"4","xl":"3","offset-md":"6","offset-lg":"8","offset-xl":"9"}},[_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(_vm.beneficio.length > 0),expression:"beneficio.length > 0"}],staticClass:"botao-principal w-100",on:{"click":function($event){return _vm.adicionarDependete()}}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("fas fa-circle-plus")]),_vm._v(" Adicionar Dependentes ")],1)],1)],1),_c('v-row',[_c('v-col',[_c('v-data-table',{attrs:{"headers":_vm.headerDependente,"items":_vm.dependente,"no-data-text":"Você não possui dependentes com Planos de Saúde cadastrados!","hide-default-footer":true},scopedSlots:_vm._u([_vm._l((_vm.headerDependente.filter((header) =>
            header.hasOwnProperty('formatter')
          )),function(header){return {key:`item.${header.value}`,fn:function({ value }){return [_vm._v(" "+_vm._s(header.formatter(value))+" ")]}}}),{key:`item.requerimento`,fn:function({ item }){return [_c('v-btn',{staticClass:"botao-vermelho",on:{"click":function($event){return _vm.removerDependente(item)}}},[_vm._v("Remover Dependente")])]}}],null,true)})],1)],1),_c('alerta',{attrs:{"exibir":_vm.modal.exibir,"titulo":_vm.modal.titulo,"mensagem":_vm.modal.mensagem},on:{"fecharModal":_vm.fecharModal}}),_c('ModalMinhasSolicitacoes',{model:{value:(_vm.showMinhasSolicitacoes),callback:function ($$v) {_vm.showMinhasSolicitacoes=$$v},expression:"showMinhasSolicitacoes"}}),_c('ModalCancelarBeneficio',{attrs:{"beneficio":_vm.modalCancelarBeneficio.beneficio,"tipoMudanca":_vm.modalCancelarBeneficio.tipoMudanca,"tituloModal":_vm.modalCancelarBeneficio.tituloModal,"dependentes":_vm.modalCancelarBeneficio.dependente},on:{"sucesso":function($event){return _vm.mensagemSucesso(
        'Pedido realizado com sucesso. Aguarde o atendimento da solicitação.'
      )}},model:{value:(_vm.modalCancelarBeneficio.showCancelarPlano),callback:function ($$v) {_vm.$set(_vm.modalCancelarBeneficio, "showCancelarPlano", $$v)},expression:"modalCancelarBeneficio.showCancelarPlano"}}),_c('ModalAdicionarDependentes',{attrs:{"tituloModal":_vm.modalAdicionarDependente.titulo,"dependentes":_vm.modalAdicionarDependente.dependente,"beneficio":_vm.modalAdicionarDependente.beneficio},on:{"sucesso":function($event){return _vm.mensagemSucesso(
        'Pedido realizado com sucesso. Aguarde o atendimento da solicitação.'
      )}},model:{value:(_vm.modalAdicionarDependente.show),callback:function ($$v) {_vm.$set(_vm.modalAdicionarDependente, "show", $$v)},expression:"modalAdicionarDependente.show"}}),_c('ModalSolicitarPlano',{attrs:{"titulo":_vm.modalSolicitarPlanoSaude.titulo,"dependentes":_vm.modalSolicitarPlanoSaude.dependente,"tipo":1,"alteracaoPlano":_vm.modalSolicitarPlanoSaude.alteracaoPlano,"beneficioAnterior":_vm.modalSolicitarPlanoSaude.beneficioAnterior},on:{"create":_vm.abrirModalAnexarDocumento},model:{value:(_vm.modalSolicitarPlanoSaude.show),callback:function ($$v) {_vm.$set(_vm.modalSolicitarPlanoSaude, "show", $$v)},expression:"modalSolicitarPlanoSaude.show"}}),_c('ModalAnexarDocumentos',{attrs:{"pedido":_vm.modalAnexar.pedido,"alteracao":_vm.modalAnexar.alteracao},model:{value:(_vm.modalAnexar.show),callback:function ($$v) {_vm.$set(_vm.modalAnexar, "show", $$v)},expression:"modalAnexar.show"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }