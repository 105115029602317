<template>
  <div>
    <Alerta v-on:fecharModal="fecharModal" :exibir="modal.exibir" :titulo="modal.titulo" :mensagem="modal.mensagem">
    </Alerta>

    <ModalArtigo v-if="dialogEditar" :novidade="editarNovidade" :academiaHepta="academiaHepta"
      v-on:fecha-modal="fecharModalArtigo" @mensagemSucesso="mensagemSucesso" @capturarMensagemErro="mensagemErro"></ModalArtigo>

    <v-container>
      <v-card class="card">
        <div class="inicio-titulo">
          <v-img class="icone-titulo" src="@/assets/images/icone/espaco-colaborador/icone_academia_hepta.svg"></v-img>
          <h1 class="titulo">Academia Hepta</h1>
        </div>

        <!-- Tab Slider -->
        <v-row class="my-4">
          <v-tabs v-model="tab" grow hide-slider show-arrows>
            <v-tabs-slider></v-tabs-slider>
            <v-tab @click="buscarNovidades(categoria.nome), (page = 1)" v-for="categoria in categorias"
              :key="categoria.id" :href="'#' + categoria.nome" active-class="tab-active" class="tab">
              {{ categoria.nome }}
            </v-tab>
          </v-tabs>
        </v-row>
        <!-- Pesquisa -->
        <v-row v-if="!visualizarArtigo" class="row-pesquisa">
          <v-col cols="12" sm="10">
            <v-text-field @input="buscarNovidades()" dense width="40px" solo v-model="pesquisa" clearable
              hide-details="auto" class="input-field">
              <template slot="label">
                <span class="input-field-text">Pesquisar</span>
              </template>
              <template slot="append">
                <v-icon class="input-field-text">fa-solid fa-magnifying-glass</v-icon>
              </template>
            </v-text-field>
          </v-col>
          <v-col cols="12" sm="2">
            <v-btn @click="abrirModalAcademia" class="botao-principal w-100">
              <v-icon class="mr-2">fas fa-circle-plus</v-icon>Adicionar
            </v-btn>
          </v-col>
        </v-row>
        <!-- Cards -->
        <div v-if="!visualizarArtigo">
          <v-tabs-items v-model="tab">
            <v-tab-item v-for="categoria in categorias" :key="categoria.id" :value="categoria.nome">
              <v-container :class="$vuetify.breakpoint.mobile ? 'mt-8' : ''">
                <v-row>
                  <template v-for="novidade in novidades">
                    <v-col cols="12" md="12" lg="6" :key="novidade.id">
                      <CardNovidade :id="novidade.id" :titulo="novidade.titulo" :icone="novidade.icone"
                      :descricao="novidade.descricao" :rascunho="novidade.rascunho" :academiaHepta="academiaHepta"
                        v-on:excluirNovidade="excluirNovidade(novidade)"
                        v-on:abrirModalEditar="abrirModalEditarNovidade(novidade)"></CardNovidade>
                    </v-col>
                  </template>
                </v-row>
              </v-container>
            </v-tab-item>
          </v-tabs-items>
          <v-pagination prev-icon=" fa-light fa-chevron-left" next-icon="fa-light fa-chevron-right" v-model="page"
            :length="maxPages" @input="buscarNovidades()" circle dense solo color="#278b97" class="mt-5"></v-pagination>
        </div>
        <div v-else>
          <v-container>
            <div>
              <v-btn @click="limparArtigo()" class="botao-principal">
                <v-icon class="mr-2">fas fa-solid fa-angle-left</v-icon>Voltar
              </v-btn>
            </div>
            <h3>{{ artigo.titulo }}</h3>
            <h2>{{ artigo.descricao }}</h2>
            <div class="conteudo"></div>
          </v-container>
        </div>

      </v-card>
    </v-container>
  </div>
</template>

<script>
import CardNovidade from "@/components/CardNovidade.vue";
import ModalNovidade from "@/components/Modal/ModalNovidade.vue";
import api from "@/services/Api.js";
import modalMixin from "@/mixins/modalMixin";
import Alerta from "@/components/Alerta.vue";
import ModalArtigo from "@/components/Modal/ModalArtigo.vue";

export default {
  components: {
    CardNovidade,
    Alerta,
    ModalNovidade,
    ModalArtigo,
  },
  mixins: [modalMixin],
  data() {
    return {
      dialogEditar: false,
      modalNovidade: false,
      dialogExcluir: false,
      dialogItem: "",
      data: false,
      pesquisa: "",
      dataPesquisa: "",
      categoriasSelected: "trilhos",
      tab: null,
      editorData: "",
      editarNovidade: {},
      nomeCategoria: "Academia Hepta",
      categorias: [
        /*         {
          nome: "Início",
          icone: require("@/assets/images/icone/icone-inicio.png"),
        }, */
      ],
      novidades: [],
      dataPesquisaFormatada: ["", ""],
      tag: [""],
      page: 1,
      maxPages: 0,
      visualizarArtigo: false,
      artigo: {
        titulo: "",
      },
      admin: false,
      academiaHepta: true,
    };
  },
  methods: {
    limparArtigo() {
      this.visualizarArtigo = false;
    },
    abrirModalEditarNovidade(novidade) {
      this.dialogEditar = true;
      this.editarNovidade = novidade;
      this.academiaHepta = true;
    },
    fecharModalArtigo() {
      this.editarNovidade = {};
      this.dialogEditar = false;
      this.buscarNovidades();
    },

    formatDate(e) {
      if (e.length > 1) {
        this.data = false;
        this.buscarNovidades();
      }
    },

    listarTags() {
      const vm = this;
      vm.isLoadingTags = true;
      api
        .get(`tag/listar/${vm.nomeCategoria}`)
        .then((response) => {
          if (response.status == 200) {
            response.data.map((e) => {
              e.icone =
                "https://nossahepta.com.br/IntranetHepta/" +
                e.icone.replace("..", "");
              this.categorias.push(e);
            });
          }
        })
        .catch((e) => {
          vm.mensagemErro(e.message);
        });
    },
    buscarNovidades(categoria) {
      if (categoria !== undefined) {
        this.categoriasSelected = categoria;
      }
      const vm = this;
      vm.isLoadingTags = true;
      let data = [];
      if (vm.dataPesquisaFormatada[1] !== "") {
        data = vm.dataPesquisaFormatada;
      }
      vm.tag =
        this.categoriasSelected !== undefined ? [this.categoriasSelected] : [];
      api
        .get(
          `artigo/listar/tag/?tag=${vm.tag}&page=${vm.page}&keywords=${vm.pesquisa}&datas=${data}`
        )
        .then((response) => {
          if (response.status == 200) {
            vm.novidades = [];
            vm.novidades = response.data.content;
          }
          this.maxPages = Math.ceil(
            response.data.totalItens / response.data.limit
          );
        })
        .catch((e) => {
          vm.mensagemErro(e.message);
        });
    },
    salvarNovidades() {
      this.fecharDialog();
    },
    excluirNovidade(novidade) {
      const vm = this;
      this.dialogExcluir = false;
      api
        .get(`/artigo/excluir/${novidade.id}`, {
          headers: { Authorization: localStorage.token },
          withCredentials: true,
        })
        .then((response) => {
          if (response.status == 200) {
            vm.mensagemSucesso(response.data);
            vm.buscarNovidades();
          }
        })
        .catch((e) => {
          vm.mensagemErro(e.message);
        });
    },
    abrirModalAcademia() {
      this.dialogEditar = true;
      this.academiaHepta = true;
    }
  },
  created() {
    this.listarTags();
    this.buscarNovidades();
    //this.isAdmin();
  },
  computed: {
    dateRangeText() {
      if (
        this.dataPesquisaFormatada[0] !== "" ||
        this.dataPesquisaFormatada[1] !== ""
      ) {
        return this.dataPesquisaFormatada.join(" a ");
      } else {
        return [];
      }
    },
  },
};
</script>

<style scoped>
/* Tabs */
.card::v-deep .v-tabs-bar {
  height: auto;
}

.tab {
  background-color: #edefee;
  border: 1px solid #edefee;
  border-radius: 12px;
  text-align: center;
  text-transform: none;
  letter-spacing: 0px;
  font: normal normal bold 16px/20px Montserrat;
  color: #787878 !important;
  margin: 0 1rem;
  padding: 1rem;
  width: 8rem;
}

.tab::before {
  border-radius: 12px;
}

.tab:hover {
  background-color: #eff9fa;
  border: 1px solid #278b97;
  color: #278b97 !important;
}

.tab-active {
  background-color: #278b97;
  border: 1px solid #278b97;
  color: #FFF !important;
}

/* Pesquisa */
.row-pesquisa {
  padding: 2rem 0;
}
</style>
