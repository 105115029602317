<template>
  <div>

    <loader :loader-primario="loader"></loader>

    <alerta v-on:fecharModal="fecharModal" :exibir="modal.exibir" :titulo="modal.titulo" :mensagem="modal.mensagem">
    </alerta>

    <v-container>
      <div class="card">
        <div class="inicio-titulo">
          <v-img class="icone-titulo" src="../../assets/images/icone/espaco-colaborador/icone_contra-cheque.svg"></v-img>
          <h1 class="titulo">Meus Contracheques</h1>
        </div>
        <v-row class="my-5">
          <v-col cols="12" xs="12" md="4" xl="4">
            <v-autocomplete color="#278b97" class="input-field" clearable chips outlined dense hide-details="auto"
              height="40px" @input="pagina = [1], buscarContracheques()" v-model="dataPesquisaInicio"
              :items="datasDisponiveis" item-text="text" item-value="value">
              <template slot="label">
                <span class="input-field-text">Data Inicio</span>
              </template>
              <template v-slot:append>
                <v-icon class="input-field-text">fas fa-chevron-down</v-icon>
              </template>
            </v-autocomplete>
          </v-col>
          <v-col cols="12" xs="12" md="4" xl="4">
            <v-autocomplete color="#278b97" class="input-field" clearable chips outlined dense hide-details="auto"
              height="40px" @input="pagina = [1], buscarContracheques()" v-model="dataPesquisaFinal"
              :items="datasDisponiveis" item-text="text" item-value="value">
              <template slot="label">
                <span class="input-field-text">Data Fim</span>
              </template>
              <template v-slot:append>
                <v-icon class="input-field-text">fas fa-chevron-down</v-icon>
              </template>
            </v-autocomplete>
          </v-col>
          <v-col cols="12" xs="12" md="4" xl="4">
            <v-btn @click="limparFiltros()" class="botao-limpar">Limpar Busca</v-btn>
          </v-col>
        </v-row>
        <v-data-table v-model:pagina="pagina" :headers="headers" :items="contracheques" :items-per-page="porPagina"
          :no-data-text="tabelaVazia()" hide-default-footer class="elevation-1" @update:options="options = $event">
          <template v-slot:item.acao="{ item }">
            <div class="acoes-tabela">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon @click="visualizarPDF(item)" v-bind="attrs" v-on="on" class="icone-acao">fas fa-eye</v-icon>
                </template>
                <span>Visualizar em PDF</span>
              </v-tooltip>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon @click="baixarPDF(item)" v-bind="attrs" v-on="on" class="icone-acao">fa fa-download</v-icon>
                </template>
                <span>Baixar em PDF</span>
              </v-tooltip>
            </div>
          </template>
        </v-data-table>
        <div class="text-center">
          <v-container>
            <v-row justify="center">
              <v-col cols="5">
                <v-container class="max-width">
                  <v-pagination prev-icon=" fa-light fa-chevron-left" next-icon="fa-light fa-chevron-right"
                    v-model="pagina[0]" :length="Math.ceil(total / porPagina)" @input="buscarContracheques()" circle dense
                    solo color="#278b97"></v-pagination>
                </v-container>
              </v-col>
            </v-row>
          </v-container>
        </div>
      </div>
    </v-container>
  </div>
</template>
  
<script>
import loader from "@/components/Loader.vue";
import alerta from "@/components/Alerta.vue";
import api from "@/services/Api.js";
import modalMixin from '@/mixins/modalMixin';

export default {
  components: { loader, alerta, },
  mixins: [modalMixin],
  data() {
    return {
      options: {
        pageCount: 1,
      },
      dataPesquisaInicio: '',
      dataPesquisaFinal: '',
      data: '',
      total: 0,
      pagina: [1],
      porPagina: 5,
      dataInicial: null,
      dataFinal: null,
      loader: false,
      contracheques: [],
      datasDisponiveis: [],
      dataInicialFiltro: null,
      dataFinalFiltro: null,
      headers: [
        { text: "Tipo", value: "tipo" },
        {
          text: 'Competência',
          value: 'mes',
          align: 'center',
        },
        {
          text: "Ação",
          value: "acao",
          align: 'center',
          sortable: false,
        },
      ],
    };
  },
  methods: {
    tabelaVazia() {
      return "Nenhum contracheque encontrado!"
    },
    visualizarPDF(item) {
      const vm = this;
      vm.loader = true;
      api
        .get(
          `contracheque/buscarPDF?mes=${item.mes.split('/')[0]}&ano=${item.ano}&seqFolha=${item.sequencialFolha}`,
          {
            responseType: "arraybuffer",
            headers: {
              Accept: "application/pdf",
              Authorization: localStorage.token,
            },
            withCredentials: true,
          }
        )
        .then(function (response) {
          const blob = new Blob([response.data], { type: "application/pdf" });
          const url = window.URL.createObjectURL(blob);
          window.open(url, "_blank");
        })
        .catch(function (e) {
          vm.mensagemErro(e.message);
        })
        .finally(function () {
          vm.loader = false;
        });
    },
    parseDate(date) {
      if (!date) {
        return null
      }
      const [year, month, day] = date.split('-')
      return `${day.padStart(2, '0')}/${month.padStart(2, '0')}/${year}`
    },
    baixarPDF(item) {
      const vm = this;
      vm.loader = true;
      api
        .get(
          `contracheque/buscarPDF?mes=${item.mes.split('/')[0]}&ano=${item.ano}&seqFolha=${item.sequencialFolha}`,
          {
            responseType: "arraybuffer",
            headers: {
              Accept: "application/pdf",
              Authorization: localStorage.token,
            },
          }
        )
        .then((response) => {
          const blob = new Blob([response.data], { type: "application/pdf" });
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(
            `download`,
            `contracheque_${item.mes}-${item.ano}-${item.sequencialFolha}.pdf`
          );
          document.body.appendChild(link);
          link.click();
        })
        .catch((e) => {
          vm.mensagemErro(e.message);
        })
        .finally(() => {
          vm.loader = false;
        });
    },
    buscarContracheques() {
      let vm = this;
      vm.loader = true;
      api.get(`contracheque`, {
        headers: { Authorization: localStorage.token },
        withCredentials: true,
        params: {
          page: vm.pagina[0],
          perPage: vm.porPagina,
          dtinicial: vm.dataPesquisaInicio,
          dtfinal: vm.dataPesquisaFinal,
        },
      })
        .then(async (res) => {
          await (vm.total = res.data.totalItens);
          await (vm.contracheques = res.data.content.map((c) => {
            return {
              ...c,
              loadingBaixar: false,
              loadingVisualizar: false,
            };
          }));
          vm.contracheques.map((c) => {
            c.mes = c.mes + "/" + c.ano
          })
        })
        .catch((e) => {
          this.mensagemErro(e.message);
        }).finally(() => {
          vm.loader = false;
        })
    },

    buscarDatasDisponiveis() {
      const vm = this;
      api.get(`contracheque/datas`, {
        headers: { Authorization: localStorage.token },
        withCredentials: true,
      })
        .then(response => {
          vm.datasDisponiveis = response.data.map(e => {
            return {
              "text": e.mes + "/" + e.ano, "value": e.ano +
                '-' +
                (e.mes < 10 ? '0' + e.mes : e.mes) +
                '-01'
            }
          })
        })
        .catch(e => {
          vm.mensagemErro(e.message);
        })
    },

    limparFiltros() {
      const vm = this;
      this.dataPesquisaInicio = null;
      this.dataPesquisaFinal = null;
      vm.pagina = [1];
      vm.buscarContracheques();
    },

  },
  mounted() {
    const vm = this;
    vm.buscarDatasDisponiveis();
    vm.buscarContracheques();
  },
};
</script>
  
<style scoped>
</style>
