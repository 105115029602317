<template>
  <v-dialog v-model="modal" width="1800" @click:outside="fechaModal()" @keydown.esc="fechaModal()">
    <v-card>
      <v-card-title>
        <h3>Adicionar Certificacões</h3>
        <v-icon @click="fechaModal()">fa-times</v-icon>
      </v-card-title>

      <v-card-text>
        <v-container class="container-principal">
          <v-row>
            <v-col>
              <v-combobox
                v-model="certificacaoSelecionada"
                item-text="nomeCertificacao"
                item-value="codCertificacao"
                :items="certificacoes"
                label="Certificações/Exames:"
                outlined
                dense
                hide-details
              >
                <template #append>
                  <v-icon>fas fa-chevron-down</v-icon>
                </template>
              </v-combobox>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-file-input
                v-model="documento"
                outlined
                dense
                label="Anexar Arquivo"
                placeholder=" Escolha o anexo..."
                accept="application/pdf"
                hide-details="auto"
                :prepend-icon="null"
              >
                <template v-slot:append>
                  <v-icon>fa-paperclip</v-icon>
                </template>
              </v-file-input>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="6">
              <v-menu
                v-model="dataEmissao"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    maxlength="10"
                    v-model="emissaoFormatada"
                    outlined
                    dense
                    label="Emissao:"
                    placeholder="DD/MM/AAAA"
                    v-on="on"
                    hide-details
                    v-bind="attrs"
                  >
                    <template slot="append">
                      <v-icon>fas fa-calendar-days</v-icon>
                    </template>
                  </v-text-field>
                </template>
                <v-date-picker
                  @input="emissaoFormatada = formatDate(emissao)"
                  color="#278b97"
                  v-model="emissao"
                  :rules="[v => !!v || 'O campo Data é obrigatório.']"
                  locale="pt-br"
                  no-title
                  width="300"
                  required
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" md="6">
              <v-menu
                v-model="dataValidade"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    maxlength="10"
                    v-model="validadeFormatada"
                    outlined
                    dense
                    label="Validade:"
                    placeholder="DD/MM/AAAA"
                    v-on="on"
                    hide-details
                    v-bind="attrs"
                  >
                    <template slot="append">
                      <v-icon>fas fa-calendar-days</v-icon>
                    </template>
                  </v-text-field>
                </template>
                <v-date-picker
                  @input="validadeFormatada = formatDate(validade)"
                  color="#278b97"
                  v-model="validade"
                  required
                  :rules="[v => !!v || 'O campo Data é obrigatório.']"
                  locale="pt-br"
                  no-title
                  width="300"
                ></v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
          <v-row class="aviso-contato">
            <v-col>
              Não encontrou sua certificação / exame?
              <v-btn
                @click="
                  fechaModal();
                  redirecionar();
                "
                class="botao-cliqueaqui mx-2"
              >
                Clique aqui
              </v-btn>
              e nos informe!
            </v-col>
          </v-row>

          <v-divider class="mt-6" />
        </v-container>
      </v-card-text>

      <v-card-actions class="acoes">
        <v-row>
          <v-col cols="12" md="4">
            <v-btn @click="fechaModal()" class="botao-cancelar w-100">
              <v-icon small color="000" class="mr-2">fa-solid fa-xmark</v-icon>
              Cancelar
            </v-btn>
          </v-col>
          <v-col cols="12" md="4">
            <v-btn
              v-if="editar == false"
              @click="enviarCertificacao()"
              class="botao-principal w-100"
            >
              <v-icon class="mr-2">fa-solid fa-arrow-up-from-bracket</v-icon>
              Salvar
            </v-btn>
            <v-btn v-else @click="editarCertificacao()" class="botao-principal w-100">
              <v-icon small class="mr-2">fa-solid fa-arrow-up-from-bracket</v-icon>
              Salvar
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import api from '@/services/Api.js';
import { formatDateAutomatic } from '@/utils/Utils';

export default {
  name: 'modal-certificacoes',
  props: {
    certificacao: {},
    value: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    modal: false,
    download: false,
    arquivoConteudo: null,
    arquivo: {},
    documento: '',
    editar: false,
    certificacaoSelecionada: [],
    validate: {
      certificacao: null,
      documento: null,
      dataEmissao: null,
      dataValidade: null,
    },
    cadastroCertificacao: {
      codPessoa: null,
      codCertificacao: null,
      dataEmissao: null,
      dataValidade: null,
      nomeDocumento: null,
      contentTypeDocumento: null,
      nomeCertificacao: '',
    },
    headers: [
      { text: 'Nome do arquivo', value: '' },
      { text: 'Documento', value: '' },
    ],
    ufs: [],
    certificacoes: [],
    dataEmissao: false,
    dataValidade: false,
    emissao: null,
    emissaoFormatada: null,
    validade: null,
    validadeFormatada: null,
    dataAtestado: false,
    filtroDataAtestado: null,
  }),
  watch: {
    value(e) {
      this.modal = e;
    },
    certificacao(e) {
      this.editar = true;
      this.cadastroCertificacao.codPessoaCertificacao = e.codPessoaCertificacao;
      this.certificacaoSelecionada = e.codCertificacao;
      this.validade = e.dataValidade;
      this.emissao = e.dataEmissao;
      this.emissaoFormatada = this.formatDate(e.dataEmissao);
      this.validadeFormatada = this.formatDate(e.dataValidade);
    },
    emissaoFormatada(e) {
      this.emissaoFormatada = this.formatDateAutomatic(e);
    },
    validadeFormatada(e) {
      this.validadeFormatada = this.formatDateAutomatic(e);
    },
  },
  methods: {
    formatDateAutomatic: formatDateAutomatic,
    limparVariaveis() {
      this.certificacaoSelecionada = [];
      this.validade = null;
      this.emissao = null;
      this.emissaoFormatada = null;
      this.validadeFormatada = null;
      this.documento = '';
    },
    buscarCertificacoesEFormacoes() {
      api
        .get(`/certificacao/todos`)
        .then(response => {
          this.certificacoes = response.data;
        })
        .catch(e => {
          this.$emit('erro', e);
        });
    },
    fechaModal() {
      this.$emit('fecha-modal');
      this.limparVariaveis();
    },
    formatDate(e) {
      if (!e) return null;
      const [year, month, day] = e.split('-');
      this.dataValidade = false;
      this.dataEmissao = false;
      return `${day}/${month}/${year}`;
    },
    gestorCliente(e) {
      if (e) {
        return 'Sim';
      } else {
        return 'Não';
      }
    },
    dataAtualizacao(e) {
      if (e) {
        return e;
      } else {
        return 'Sem data de atualização!';
      }
    },
    tabelaVazia() {
      return 'Nenhum documento encontrado!';
    },
    formatarNomeDocumento(names) {
      if (names.length > 30) return names.substring(0, 30) + '...';
      else return names;
    },
    validateForm() {
      const vm = this;
      vm.validate.certificacao = !!vm.cadastroCertificacao.codCertificacao;
      vm.validate.documento = !!vm.cadastroCertificacao.nomeDocumento;

      vm.validate.dataValidade =
        vm.cadastroCertificacao.dataValidade == null ||
        new Date(vm.cadastroCertificacao.dataValidade) >
          new Date(vm.cadastroCertificacao.dataEmissao);
      vm.validate.dataEmissao = new Date(vm.cadastroCertificacao.dataEmissao) <= new Date();

      if (!vm.validate.dataValidade) {
        this.$emit(
          'mensagem',
          'Informação!',
          'Data de validade deve ser maior que a data de emissão!'
        );
      } else if (!vm.validate.dataEmissao) {
        this.$emit('mensagem', 'Informação!', 'Data de emissão incorreta!');
      }

      return !Object.keys(vm.validate).find(k => vm.validate[k] === false);
    },
    prepararDados() {
      const formData = new FormData();
      this.cadastroCertificacao.codCertificacao = this.certificacaoSelecionada.codCertificacao;
      this.cadastroCertificacao.nomeDocumento =
        this.documento.name !== undefined ? this.formatarNomeDocumento(this.documento.name) : 'a';
      this.cadastroCertificacao.contentTypeDocumento = this.documento.type;
      this.cadastroCertificacao.dataValidade = this.validade;
      this.cadastroCertificacao.dataEmissao = this.emissao;
      formData.append('form', JSON.stringify(this.cadastroCertificacao));
      formData.append('anexo', this.documento);
      return formData;
    },
    enviarCertificacao() {
      const vm = this;
      const dados = vm.prepararDados();
      vm.fechaModal();
      vm.$emit('thisLoader', true);
      if (vm.validateForm()) {
        api
          .post('certificacao/colaborador/relacionar', dados)
          .then(() => {
            vm.$emit('sucesso', 'Certificação salva com sucesso!');
          })
          .catch(err => {
            this.$emit('erro', err);
          })
          .finally(() => {
            vm.$emit('thisLoader', false);
            vm.$emit('buscarCertificacao');
            vm.limparVariaveis();
          });
      }
    },
    editarCertificacao() {
      const vm = this;
      const dados = vm.prepararDados();
      vm.fechaModal();
      vm.$emit('thisLoader', true);
      if (vm.validateForm()) {
        api
          .put('certificacao/colaborador/editar', dados)
          .then(() => {
            vm.$emit('sucesso', 'Editado com sucesso!');
          })
          .catch(err => {
            vm.$emit('erro', err);
          })
          .finally(() => {
            vm.$emit('thisLoader', false);
            vm.$emit('buscarCertificacao');
            vm.limparVariaveis();
          });
      }
    },
    buscarUfs() {
      const vm = this;
      api.get('v2/dominio/uf').then(response => {
        response.data.forEach(e => {
          vm.ufs.push(e.descricaoDominio);
        });
      });
    },
    redirecionar() {
      window.open('https://forms.office.com/r/Fdrg1JSYFk', '_blank');
    },
  },
  created() {
    this.buscarCertificacoesEFormacoes();
  },
};
</script>

<style scoped>
.container-principal {
  padding: 1rem 2rem;
}

.aviso-contato {
  color: #5d5d5d;
  font-size: 1rem;
  font-weight: 600;
  align-items: center;
}

.acoes .row {
  display: flex;
  justify-content: center;
}
</style>
