<template>
  <div id="framework">
    <v-row justify="space-around">
      <div id="f7">
        <svg
          id="Camada_1"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          height="570px"
          width="570px"
        >
          <a
            data-toggle="tooltip"
            title="Valores"
            @click="$vuetify.goTo('#valores-estrategicos')"
          >
            <rect x="244.9" y="452.9" class="st0" width="98.5" height="19.3" />
          </a>
          <a>
            <rect
              x="464"
              y="153.9"
              transform="matrix(0.9317 -0.3633 0.3633 0.9317 -35.7053 184.8755)"
              class="st0"
              width="19.3"
              height="66.8"
            />
          </a>
          <a
            data-toggle="tooltip"
            title="Missão"
            @click="$vuetify.goTo('#missao-visao')"
            
          >
            <rect
              x="109"
              y="155.1"
              transform="matrix(0.5736 -0.8192 0.8192 0.5736 -73.874 187.5533)"
              class="st0"
              width="68.4"
              height="19.3"
            />
          </a>
          <path
            class="st1"
            d="M316.9,81.2l-3-0.2l-0.2,2.9l2.9,0.2L316.9,81.2z M331.5,83.1l-2.9-0.5l-0.4,2.9l2.8,0.4L331.5,83.1z
     M346.1,86.1l-2.8-0.7l-0.7,2.8l2.8,0.7L346.1,86.1z M360.3,90.2l-2.8-0.9l-0.8,2.8l2.7,0.9L360.3,90.2z M374.1,95.4l-2.7-1.1
    l-1.1,2.7l2.7,1.1L374.1,95.4z M387.5,101.6l-2.6-1.4l-1.3,2.6l2.6,1.3L387.5,101.6z M400.3,108.9l-2.5-1.5l-1.5,2.5l2.5,1.5
    L400.3,108.9z M412.6,117.1l-2.4-1.7l-1.7,2.4l2.4,1.7L412.6,117.1z M424.1,126.2l-2.3-2l-1.8,2.3l2.2,1.9L424.1,126.2z M435,136.2
    l-2.1-2.1l-2,2.1l2.1,2L435,136.2z M445.1,147.1l-1.9-2.2l-2.2,2c0.7,0.7,1.3,1.4,1.9,2.2L445.1,147.1z M489.1,239.2
    c-0.1-1-0.3-1.9-0.5-2.9l-2.8,0.5c0.2,0.9,0.4,1.9,0.5,2.8L489.1,239.2z M491.1,253.8c0-1,0-2-0.3-3l-2.9,0.4c0,0.9,0.2,1.9,0.3,2.9
    L491.1,253.8z M492,268.4c0-1,0-2,0-3h-3c0,0.9,0,1.9,0,2.9L492,268.4z M492,283v-3h-2.9v2.9L492,283z M490.7,297.6c0-1,0.3-2,0.4-3
    l-2.9-0.3c0,0.9-0.2,1.9-0.4,2.9L490.7,297.6z M488.3,312.2c0.2-0.9,0.4-1.9,0.6-2.9l-2.9-0.5l-0.5,2.8L488.3,312.2z M484.6,326.5
    c0.3-0.9,0.5-1.9,0.8-2.8l-2.8-0.8c-0.3,0.9-0.5,1.9-0.8,2.8L484.6,326.5z M480,340.5c0.4-0.9,0.7-1.8,1-2.8l-2.7-0.9
    c-0.3,0.9-0.7,1.8-1,2.7L480,340.5z M474.1,354.1c0.4-0.9,0.8-1.8,1.2-2.7l-2.7-1.2l-1.2,2.7L474.1,354.1z M467.4,367.2l1.4-2.6
    l-2.6-1.4c-0.4,0.9-0.9,1.7-1.4,2.6L467.4,367.2z M459.7,379.8l1.6-2.5l-2.5-1.6c-0.5,0.8-1,1.6-1.6,2.4L459.7,379.8z M451,391.7
    l1.8-2.3l-2.3-1.8l-1.8,2.3L451,391.7z M441.5,403l2-2.2l-2.2-1.9c-0.6,0.7-1.3,1.5-1.9,2.2L441.5,403z M431.1,413.5l2.2-2l-2-2.1
    l-2.1,2L431.1,413.5z M420,423.4l2.3-1.9l-1.9-2.3l-2.3,1.9L420,423.4z M408.2,432.2l2.4-1.7l-1.7-2.4l-2.4,1.6L408.2,432.2z
     M395.7,440.1l2.5-1.5l-1.5-2.5l-2.5,1.5L395.7,440.1z M382.7,447l2.7-1.3l-1.3-2.6l-2.6,1.3L382.7,447z M369.3,452.6l2.7-1.1
    l-1.1-2.7l-2.7,1.1L369.3,452.6z M355.4,457.4c0.9-0.3,1.9-0.6,2.8-0.9l-0.9-2.8l-2.8,0.8L355.4,457.4z M225.3,450.9l2.7,1.1
    l1.1-2.7l-2.7-1.1L225.3,450.9z M212,444.7c0.9,0.5,1.8,0.9,2.6,1.3l1.3-2.6l-2.6-1.3L212,444.7z M199.1,437.4l2.5,1.5l1.5-2.6
    l-2.5-1.5L199.1,437.4z M186.8,429.2l2.4,1.7l1.7-2.4l-2.6-1.5L186.8,429.2z M175.2,420.2l2.3,1.9l1.9-2.3l-2.3-1.9L175.2,420.2z
     M164.3,410.2l2.1,2l2-2.1l-2.1-2L164.3,410.2z M154,399.8c0.7,0.7,1.3,1.5,2,2.2l2.2-2l-1.9-2.2L154,399.8z M144.8,388.3
    c0.6,0.8,1.2,1.6,1.8,2.3l2.3-1.8l-1.8-2.3L144.8,388.3z M136.4,376.2l1.6,2.5l2.4-1.6c-0.5-0.8-1.1-1.6-1.6-2.4L136.4,376.2z
     M129.1,363.4l1.4,2.6l2.6-1.4l-1.4-2.6L129.1,363.4z M122.6,350.1c0.4,0.9,0.8,1.8,1.2,2.7l2.6-1.2c-0.4-0.9-0.8-1.8-1.2-2.7
    L122.6,350.1z M117.2,336.4l1,2.8l2.8-1l-1-2.7L117.2,336.4z M112.8,322.3c0.3,1,0.5,1.9,0.8,2.9l2.8-0.8l-0.8-2.8L112.8,322.3z
     M109.7,307.9c0.2,0.9,0.3,1.9,0.5,2.9l2.8-0.5c-0.2-0.8-0.4-1.9-0.5-2.8L109.7,307.9z M107.6,293.3c0,1,0,1.9,0.3,2.9l2.9-0.4
    l-0.3-2.9L107.6,293.3z M106.6,278.7v2.9h2.9v-2.9H106.6z M106.6,264.1v3h2.9v-2.9L106.6,264.1z M107.8,249.5l-0.2,2.4l2.9,0.3
    l0.3-2.9L107.8,249.5z M110.2,234.9l-0.4,2.4l2.9,0.5l0.5-2.9L110.2,234.9z M113.9,220.6l-0.8,2.8l2.8,0.8l0.8-2.8L113.9,220.6z
     M189.8,114.7l-2.4,1.7l1.7,2.4c0.8-0.6,1.6-1.1,2.4-1.7L189.8,114.7z M202.2,106.8l-2.6,1.5l1.5,2.5l2.5-1.5L202.2,106.8z
     M215.2,99.9l-2.6,1.3l1.3,2.6l2.6-1.3L215.2,99.9z M228.7,93.9L226,95l1.1,2.7c0.9-0.4,1.8-0.8,2.7-1.1L228.7,93.9z M242.6,89.1
    l-2.8,0.9l0.9,2.8l2.8-0.9L242.6,89.1z M256.9,85.4l-2.9,0.7l0.7,2.8l2.8-0.7L256.9,85.4z M271.5,82.8l-2.9,0.4l0.5,2.9l2.9-0.4
    L271.5,82.8z M286.1,81.2c-1,0-2,0.1-3,0.2l0.3,2.9h2.9L286.1,81.2z"
          />
          <polygon
            class="st2"
            points="297.8,59.9 270.9,34.2 281.4,34.2 297.8,49.5 314.3,34.2 324.9,34.2 "
          />
          <a
            data-toggle="tooltip"
            title="Código de Ética"
            href="https://www.hepta.com.br/files/hepta-codigo-de-etica.pdf"
            id="codigo"
          >
            <path
              class="st3"
              d="M131.3,226.4c-2.7,9.9-4.5,20.1-5.4,30.3l19.4-9.6l16.6,8.2c0.7-6.7,1.9-13.3,3.6-19.8
      c19.9-74.4,96.4-118.5,170.8-98.6c53.6,14.4,93.5,59.1,101.8,113.9l19.9,9.8l16.3-8c-10.9-96.1-97.7-165.2-193.8-154.2
      C209.3,106.5,150.2,157.2,131.3,226.4"
            />
            <path
              class="st0"
              d="M370.3,133.1c0.4-2.8,0.7-4.3,0.7-4.7s0-0.6,0-0.8c-0.5,0.6-1.8,1.9-3.6,4.1L370.3,133.1z M369.3,138.4
      l0.5-3.1l-4.1-2l-2.1,2.3l-2.6-1.2l9.3-9.2l2.9,1.4l-1.5,13.1L369.3,138.4z M361.3,123.2c-0.8-0.4-1.8-0.4-2.6,0
      c-0.9,0.6-1.6,1.5-1.9,2.5c-1.1,2.5-0.7,4.2,1.2,5c1,0.4,2.1,0.6,3.2,0.7l-0.9,2c-1.2,0-2.3-0.3-3.4-0.7c-1.5-0.5-2.6-1.7-3.1-3.1
      c-0.4-1.6-0.2-3.4,0.5-4.9c0.4-1.1,1.1-2,2-2.8c0.8-0.7,1.7-1.1,2.7-1.2c1.1-0.1,2.2,0,3.1,0.5c1.1,0.5,2.1,1.3,3,2.2l-1.6,1.6
      c-0.3-0.4-0.7-0.8-1.1-1.1c-0.3-0.3-0.7-0.5-1.1-0.6L361.3,123.2z M347,128.8l3.6-11.7l2.5,0.8l-3.6,11.7L347,128.8z M341.9,127.4
      l-2.5-0.7l2.6-9.9l-3.2-0.8l0.5-2.1l9,2.4l-0.5,2.1l-3.2-0.9L341.9,127.4z M332.8,111.4v-0.2c0.9-0.6,1.7-1.3,2.5-2.1l2.8,0.6l0,0
      c-0.6,0.3-1.2,0.7-1.8,0.9c-0.8,0.4-1.4,0.7-1.9,0.9L332.8,111.4z M335,125.5l-7-1.3l2.6-12.1l7,1.5l-0.4,2.1l-4.3-1.1l-0.5,2.7
      l4.1,0.9l-0.5,2.1l-4.1-0.9l-0.7,3.1l4.4,0.9L335,125.5z M321.1,122.7l-7.1-0.8l1.4-12.3l7.1,0.8l-0.3,2.2l-4.5-0.5l-0.3,2.7
      l4.2,0.5l-0.3,2.1l-4.2-0.4l-0.4,3.2l4.5,0.5L321.1,122.7z M308.8,115.4c0-2.7-1.1-4-3.4-4.1H304v8h1.1c2.2-0.1,3.6-1.4,3.6-4.1
      L308.8,115.4z M311.5,115.4c0.1,1.7-0.6,3.4-1.8,4.6c-1.4,1.2-3.2,1.7-5.1,1.6H301v-12.3h3.9c1.7-0.1,3.4,0.5,4.7,1.7
      c1.2,1.1,1.9,2.6,1.9,4.3L311.5,115.4z M284.7,116.1c0,1.1,0.4,2.1,1,3c0.6,0.7,1.5,1,2.4,0.8c2.1,0,3-1.6,2.8-4.4
      c-0.2-2.8-1.4-4.1-3.6-3.9c-0.9,0-1.8,0.5-2.3,1.3c-0.4,0.9-0.6,2-0.4,3L284.7,116.1z M293.7,115.4c0.3,1.7-0.1,3.4-1.1,4.8
      c-1,1.2-2.6,2-4.2,2c-1.6,0.3-3.3-0.2-4.5-1.3c-1.3-1.2-2.1-2.9-2.1-4.7c-0.3-1.7,0.1-3.4,1.1-4.9c1.1-1.2,2.6-1.9,4.2-2
      c1.6-0.3,3.2,0.2,4.5,1.2c1.2,1.2,2,2.9,2,4.6L293.7,115.4z M273.9,116.9l4.8-0.9l1.2,6.3c-0.7,0.4-1.4,0.7-2.1,0.9
      c-0.7,0.2-1.4,0.4-2.1,0.5c-1.5,0.4-3.2,0.1-4.5-0.8c-1.3-1.1-2.1-2.7-2.3-4.4c-0.4-1.7-0.1-3.4,0.8-4.9c1.1-1.4,2.6-2.3,4.3-2.6
      c1.2-0.2,2.4-0.2,3.6,0l-0.4,2.1c-1-0.2-2-0.2-2.9,0c-1,0.2-1.9,0.8-2.5,1.6c-0.5,1-0.7,2.1-0.4,3.2c0.1,1.1,0.6,2.1,1.4,2.8
      c0.7,0.6,1.7,0.8,2.6,0.6c0.5-0.1,1.1-0.3,1.6-0.5l-0.5-2.6l-2.3,0.4L273.9,116.9z M265,125.8l-2.9-12l2.6-0.6l2.9,12L265,125.8z
       M258.1,121.1c-0.8-2.6-2.3-3.6-4.5-2.8l-1.3,0.4l2.3,7.7l1.1-0.3c2-0.5,3.1-2.5,2.7-4.4C258.2,121.4,258.1,121.2,258.1,121.1z
       M260.7,120.2c0.6,1.6,0.4,3.4-0.4,5c-1,1.5-2.6,2.6-4.3,3l-3.4,1l-3.6-11.9l3.6-1.1c1.6-0.6,3.4-0.5,5,0.2
      C259.1,117.2,260.2,118.6,260.7,120.2z M238.5,120.2V120c0.3-1,0.6-2.1,0.9-3.1l2.7-1.1l0,0c0,0.4-0.5,1-0.9,1.8l-1,1.8
      L238.5,120.2z M239.5,127.5c0.4,1,1,1.9,1.9,2.6c0.8,0.4,1.8,0.4,2.6,0c1.9-0.8,2.4-2.5,1.3-5c-1-2.6-2.5-3.5-4.5-2.7
      c-0.9,0.3-1.5,1-1.8,1.9c-0.3,1.2-0.1,2.5,0.4,3.6V127.5z M247.9,124.2c0.8,1.5,0.9,3.3,0.4,5c-0.6,1.5-1.9,2.7-3.4,3.2
      c-1.5,0.7-3.2,0.7-4.7,0c-1.5-0.8-2.6-2.1-3.2-3.6c-0.8-1.5-0.9-3.3-0.4-5c1.5-2.9,5-4.3,8.1-3.3
      C246.2,121.2,247.4,122.6,247.9,124.2z M230,127.4c-0.8,0.4-1.4,1.2-1.5,2.1c-0.1,1.1,0.2,2.2,0.7,3.1c1.3,2.4,2.8,3.2,4.7,2.2
      c0.9-0.5,1.8-1.2,2.5-2l1.1,1.9c-0.8,0.9-1.7,1.6-2.7,2.2c-1.3,0.8-2.9,1-4.4,0.5c-1.6-0.7-2.8-2-3.5-3.6c-0.6-1-0.9-2.1-1-3.3
      c0-1,0.2-2,0.7-2.9c0.6-0.9,1.4-1.6,2.4-2.1c1.1-0.6,2.4-0.9,3.6-0.9l0.3,2.3c-0.5,0-1,0-1.5,0C230.9,126.9,230.4,127.2,230,127.4
      L230,127.4z"
            />
          </a>
          <a
            data-toggle="tooltip"
            title="Políticas"
            data-placement="bottom"
            @click="abrirLinkMandala(3)"
          >
            <path
              class="st4"
              d="M296.6,411c-77.1-2.2-137.8-66.4-135.7-143.5c0-0.2,0-0.4,0-0.6l-15.6-7.6l-20.1,9.9
      c-1.4,96,74.8,175.2,170.7,177.6l-8.5-17.2L296.6,411z"
            />
            <path
              class="st0"
              d="M203.7,399c-0.6,0.9-1.6,1.4-2.6,1.4c-1.2,0-2.4-0.5-3.3-1.3c-1-0.7-1.8-1.7-2.3-2.7l1.5-2
      c0.4,0.7,0.8,1.3,1.2,1.9c0.3,0.4,0.7,0.8,1.1,1.2c0.4,0.3,0.8,0.5,1.3,0.5c0.4,0,0.7-0.2,0.9-0.5c0.2-0.2,0.3-0.4,0.3-0.7
      c0-0.3,0-0.5,0-0.8c0-0.3-0.3-0.8-0.8-1.6c-0.3-0.6-0.6-1.2-0.8-1.9c-0.3-1,0-2.1,0.6-2.9c0.6-0.8,1.5-1.3,2.5-1.4
      c1.1,0,2.2,0.4,3.1,1.1c0.5,0.4,1,0.8,1.4,1.3c0.4,0.5,0.7,1.1,1.1,1.6l-1.9,1.1c-0.4-0.6-0.7-1.1-0.9-1.5
      c-0.3-0.3-0.6-0.6-0.9-0.9c-0.3-0.3-0.7-0.4-1.1-0.4c-0.3,0-0.7,0.2-0.9,0.5c-0.1,0.2-0.2,0.4-0.2,0.6c-0.1,0.2-0.1,0.5,0,0.7
      c0.2,0.6,0.5,1.2,0.8,1.7c0.5,0.8,0.8,1.8,0.9,2.8C204.4,397.7,204.2,398.5,203.7,399 M194.8,389.6l2-4.3c0.1-0.2,0.3-0.5,0.4-0.8
      c-0.6,0.4-2.2,1.4-4.9,2.9L194.8,389.6z M192.4,394.5l1.4-2.8l-3.4-3.1l-2.7,1.6l-2.1-1.9l11.7-6.2l2.4,2.2l-5.3,12.2L192.4,394.5z
       M190.1,377.8c-0.6-0.7-1.5-1-2.4-0.9c-1.1,0.2-2.1,0.8-2.8,1.6c-2,2-2.3,3.6-0.8,5.2c0.8,0.8,1.7,1.4,2.7,1.8l-1.6,1.5
      c-1.1-0.5-2-1.2-2.8-2c-1.2-1-1.8-2.6-1.8-4.1c0.3-1.7,1.1-3.2,2.4-4.4c0.8-0.8,1.9-1.5,3-1.9c1-0.3,2-0.3,3,0c1,0.3,2,0.9,2.7,1.7
      c0.9,0.9,1.5,2,1.9,3.2l-2.1,0.9c-0.2-0.5-0.4-0.9-0.6-1.4C190.7,378.6,190.4,378.1,190.1,377.8L190.1,377.8z M175.2,376.8l9.3-8.2
      l1.8,2l-9.4,8.2L175.2,376.8z M171.8,372.7l-1.6-2.1l8-6.4l-2.2-2.7l1.7-1.4l5.9,7.3l-1.7,1.4l-2.1-2.7L171.8,372.7z M176.2,356.2
      h0.2c1.1,0.1,2.2,0.1,3.3,0l1.7,2.4l0,0l-2-0.4l-2.2-0.4L176.2,356.2z M165.2,364.3l10.1-7.3l1.6,2.1l-10.1,7.3L165.2,364.3z
       M159.9,355.9l10.5-6.8l1.4,2.2l-8.7,5.6l2.8,4.4l-1.8,1.2L159.9,355.9z M159.9,342.9c-1,0.4-1.9,1.2-2.4,2.2
      c-0.4,0.8-0.4,1.8,0,2.6c1,1.9,2.7,2.2,5.2,0.9c2.5-1.3,3.2-2.8,2.3-4.7c-0.4-0.8-1.1-1.5-2-1.6c-1,0-2,0.2-2.8,0.7L159.9,342.9z
       M164.1,351c-1.5,0.9-3.3,1.2-5,0.8c-3.1-1.2-4.9-4.6-4.1-7.8c0.7-1.7,2-3,3.6-3.6c1.5-0.9,3.3-1.2,5-0.8c1.6,0.5,2.8,1.7,3.5,3.2
      c0.9,1.4,1.1,3.1,0.6,4.7c-0.7,1.6-1.9,2.9-3.5,3.6L164.1,351z M160.2,338c-1,0.5-2.3,0.5-3.3,0c-1.1-0.6-2-1.6-2.4-2.8l-0.4-0.9
      l-4.1,1.8l-1.1-2.4l11.3-5.1l1.6,3.6c0.6,1.1,0.8,2.4,0.5,3.6C162.1,336.9,161.2,337.7,160.2,338z M156,333.4l0.3,0.8
      c0.2,0.7,0.7,1.3,1.4,1.6c1,0.4,2.1-0.1,2.6-1.1c0.1-0.6,0-1.1-0.3-1.6l-0.5-1.1L156,333.4z"
            />
          </a>
          <path
            class="st0"
            d="M301.5,244.1c9.1,0,12.4-4.1,13.1-8.1c1-5.6-2.1-9.3,6.6-11.6c1.8-0.5,3.7-0.6,5.5-0.4
    c4.2-5,11.6-14.3,11.2-19.6c0-1.5-1.9-2.5-3.6-3.6c-4.5-2.7-9.3-5.7-3.6-15.6c6.2-10.7,11.4-7.7,16.1-5c1.8,1.1,3.6,2,4.7,1.5
    c6.8-2.9,13.5-23,13.5-23l3.3,1.1c0,0-7.3,21.6-15.4,25.1c-2.7,0.8-5.7,0.2-7.8-1.7c-3.2-1.9-6.9-3.9-11.3,3.6
    c-4.1,7.1-0.8,9.1,2.3,10.9c3.1,1.9,5,3.1,5.3,6.3c0.5,5.9-6.2,15.1-10.9,20.7c12.2,4.2,21.1,21.9,10.3,35
    c6.2,2.8,20.4-11.6,21.2,10.5c6,0.6,13,0.8,16-1.5c1.2-0.9,1.2-2.9,1.2-5c0-5.2,0-10.9,11.6-10.9c12.4,0,12.5,5.8,12.6,11.2
    c0,2.1,0,4,1.2,4.8c6,4.3,26.6-0.3,26.7-0.3l0.7,3.4c0,0-22.1,5-29.4-0.2c-2.5-1.8-2.6-4.6-2.6-7.6c0-3.6,0-7.9-9.1-7.8
    c-8.2,0-8.2,4-8.2,7.6c0,3,0,5.9-2.7,7.8c-3.6,2.7-11.4,2.7-18,2.1c-0.7,22.7-17.4,5.3-22.4,10.9l0,0c0.4,0.3,0.8,0.6,1.1,1
    c7.4,8.9,6.2,22-2.7,29.4c-2,1.7-4.4,3-6.9,3.8c2.4,6.2,6.8,16.2,11.4,18.2c1.4,0.6,3.1-0.4,5-1.5c4.5-2.6,9.4-5.4,15.4,4.5l0,0
    c6.3,10.6,1.2,13.7-3.4,16.5c-1.8,1.1-3.6,2.1-3.6,3.4c-0.4,7.5,13.8,23.1,13.8,23.1l-2.6,2.3c0,0-15.4-16.7-14.6-25.5
    c0.3-3.1,2.7-4.5,5.2-6.1c3.2-1.9,6.8-4.1,2.2-11.7l0,0c-4.2-7-7.6-5.1-10.7-3.3c-2.2,1.9-5.3,2.5-8.1,1.6
    c-5.8-2.6-10.9-14.2-13.5-20.8c-1.7,0.1-3.5-0.1-5.2-0.5c-10.7-2.8-6.5-7-8-12.3c-0.4-1.3-1.1-2.5-2.2-3.4
    c-3.2-2.2-7.1-3.1-10.9-2.6c-10.5,0.4-11.4,5.5-10.7,9.8c1.1,6.6-6.5,9.9-14.2,9c-3.9,4.6-12.3,15-11.8,20.7c0,1.5,1.9,2.5,3.6,3.6
    c4.5,2.7,9.3,5.5,3.6,15.6c-6.1,10.7-11.3,7.8-16,5.1c-1.8-1-3.6-2-4.7-1.5c-6.8,3-13.3,23.1-13.3,23.2l-3.3-1.1
    c0,0,7-21.6,15.2-25.2c2.8-1.2,5.2,0,7.8,1.6c3.3,1.9,6.9,3.9,11.3-3.9c4.1-7.1,0.7-9.1-2.3-10.9c-3.1-1.9-5-3-5.4-6.3
    c-0.5-6.2,6.9-16.3,11.4-21.9c-2.8-0.8-5.3-2.2-7.3-4.2c-6.7-6.9-7.5-17.6-2-25.5c1.5-2.5,4-5.7-1.8-5.3
    c-5.7,0.4-17.2,7.7-17.3-10.3c-6.6-1.1-16.5-2.1-20.3,0.6c-1.2,0.8-1.2,2.8-1.3,5c0,5.2-0.2,10.9-11.8,10.9
    c-12.3,0-12.4-6-12.4-11.4c0-2.1,0-4.1-1.1-4.8c-5.9-4.4-26.6,0-26.7,0l-0.7-3.7c0,0,22.3-4.6,29.4,0.7c2.4,1.8,2.4,4.6,2.4,7.6
    c0,3.6,0,7.9,8.9,7.9c8.2,0,8.3-3.6,8.4-7.3c-0.4-2.9,0.6-5.8,2.8-7.7c4.7-3.2,15.3-2.3,22.4-1.2c1.1-18.5,10.1-10.9,16.3-10.1
    c5.5,0.8,3.9-1.3,2.6-4.5c-5.4-10.8-1.4-23.9,9-29.9c-2.2-6.1-6.7-17-11.4-19.3c-1.4-0.6-3.1,0.3-5,1.4c-4.6,2.5-9.5,5.2-15.3-4.7
    c-6.2-10.7-1-13.7,3.6-16.5c1.8-1,3.5-2,3.6-3.3c0.8-7.3-13.2-23.1-13.3-23.2l2.6-2.3c0,0,15.1,16.9,14.2,25.8
    c-0.3,3.1-2.8,4.5-5.3,5.9c-3.2,1.9-6.9,4-2.4,11.7c4.1,7.1,7.3,5.2,10.6,3.5c2.3-1.8,5.3-2.4,8.1-1.5c5.8,2.7,10.9,14.6,13.1,21.2
    c2.2-0.6,4.5-0.7,6.7-0.3c19.4,3.6-2.9,20.4,19.9,20"
          />
          <a
            data-toggle="tooltip"
            title="ASCOM"
            @click="abrirLinkMandala(7)"
            id="ascom"
          >
            <path
              class="st5"
              d="M426.9,272.9c-0.5,43.6-23.4,84-60.6,106.7c-4.2-5.1-10.9-14.2-10.5-19.3c0-1.4,1.8-2.4,3.6-3.4
    c4.6-2.8,9.7-5.9,3.4-16.5l0,0c-5.9-9.9-10.9-7.1-15.4-4.5c-1.8,1.1-3.6,2-5,1.5c-4.6-2-9-12-11.4-18.2c11-3.6,17-15.3,13.4-26.3
    c-0.8-2.5-2.1-4.9-3.8-6.9c-0.3-0.4-0.7-0.7-1.1-1l0,0c5-5.7,21.7,11.7,22.4-10.9c6.6,0.6,14.3,0.6,18-2.1c2.6-1.9,2.6-4.8,2.7-7.8
    c0-3.6,0-7.3,8.2-7.6c8.9,0,9,4.1,9.1,7.8c0,3,0,5.8,2.6,7.6c5.1,3.6,17.4,2.3,24.3,1.2"
            />
          </a>
          <a
            data-toggle="tooltip"
            title="Gestão de Pessoas"
            @click="abrirLinkMandala(5)"
          >
            <path
              class="st6"
              d="M239.2,160.7c9-4.9,18.5-8.8,28.5-11.5c32.5-8.7,67.1-4.1,96.2,12.7c-2.2,5.9-7.3,17.5-12.3,19.6
      c-1.2,0.5-2.9-0.4-4.7-1.5c-4.7-2.7-9.9-5.7-16.1,5c-5.8,10-1,12.9,3.6,15.6c1.8,1.1,3.6,2.2,3.6,3.6c0.4,5.3-7,14.6-11.2,19.6
      c-1.8-0.2-3.7,0-5.5,0.4c-8.7,2.3-5.6,6-6.6,11.6c-0.7,4-3.9,7.9-13.1,8.1c-22.8,0.4-0.5-16.4-20-20c-2.2-0.4-4.4-0.3-6.6,0.3
      c-2.4-6.6-7.3-18.5-13.1-21.2c-2.8-0.9-5.8-0.4-8.1,1.5c-3.1,1.8-6.5,3.6-10.6-3.5c-4.5-7.7-0.9-9.8,2.4-11.7
      c2.6-1.5,5-2.9,5.3-5.9c0.7-6.6-7.3-17.6-11.7-22.9"
            />
            <path
              class="st1"
              d="M308.4,175.3c-0.8,0-1.7,0.4-2.2,1.1c-0.6,0.9-0.8,1.9-0.8,2.9c0,2.6,1,3.9,2.9,3.9c1,0,2-0.2,3-0.5v2.1
      c-1,0.4-2.1,0.6-3.2,0.6c-1.5,0.1-2.9-0.5-3.9-1.5c-1-1.2-1.5-2.8-1.4-4.4c0-1.1,0.2-2.2,0.7-3.2c0.4-0.9,1.1-1.6,1.9-2.1
      c0.9-0.5,1.9-0.7,2.9-0.7c1.2,0,2.3,0.3,3.4,0.8l-0.8,2l-1.3-0.5c-0.4-0.1-0.8-0.2-1.3-0.2 M301.2,177.1c0.1,1.1-0.4,2.1-1.2,2.8
      c-1,0.7-2.1,1.1-3.3,1h-1v4.1h-2.5v-11.6h3.6c1.1-0.1,2.2,0.2,3.2,0.9C300.9,175.1,301.3,176.1,301.2,177.1L301.2,177.1z
       M295.7,179h0.8c0.6,0,1.2-0.1,1.7-0.4c0.7-0.7,0.7-1.9,0-2.6c-0.4-0.3-1-0.5-1.5-0.4h-1.1L295.7,179z M285.9,178.6h4.6v6
      c-0.7,0.2-1.4,0.4-2.1,0.5c-0.7,0.1-1.3,0.1-2,0c-1.5,0.1-2.9-0.5-4-1.5c-1-1.2-1.5-2.8-1.4-4.4c-0.1-1.6,0.5-3.2,1.6-4.4
      c1.2-1.1,2.8-1.7,4.4-1.6c1.2,0,2.3,0.3,3.4,0.7l-0.8,1.9c-0.8-0.4-1.7-0.6-2.6-0.6c-1,0-1.9,0.4-2.6,1.1c-0.7,0.8-1,1.8-0.9,2.9
      c-0.1,1,0.2,2,0.8,2.9c0.6,0.7,1.4,1,2.3,1c0.5,0.1,1,0.1,1.5,0v-2.4h-2.2L285.9,178.6z"
            />
          </a>
          <a
            data-toggle="tooltip"
            title="Jurídico"
            @click="abrirLinkMandala(6)"
          >
            <path
              class="st7"
              d="M422.6,238.8c2.7,10,4.1,20.3,4.3,30.6c-6.4,1.1-18.2,2.6-22.3-0.5c-1.1-0.8-1.1-2.7-1.2-4.8
    c0-5.4-0.2-11.3-12.6-11.2c-11.5,0-11.6,5.8-11.6,10.9c0,2.1,0,4.1-1.2,5c-3.1,2.2-10,2.1-16,1.5c-0.8-22.1-15-7.8-21.2-10.5
    c10.7-13.3,1.9-30.8-10.3-35c4.6-5.5,11.3-14.8,10.9-20.7c-0.3-3.2-2.7-4.7-5.3-6.3c-2.6-1.6-6.4-3.9-2.3-10.9
    c4.5-7.7,8.1-5.6,11.3-3.6c2.2,1.8,5.1,2.5,7.8,1.7c5.9-2.6,11.3-14.6,13.9-21C394.4,180.8,414.3,207.6,422.6,238.8"
            />
          </a>
          <a
            data-toggle="tooltip"
            title="GEFIN"
            @click="abrirLinkMandala(8)"
          >
            <path
              class="st8"
              d="M363.3,381.3c-38.4,21.9-85.4,22.3-124.1,0.9c2.3-6.1,7-16.9,11.8-19c1.2-0.5,2.9,0.4,4.7,1.5
    c4.7,2.7,9.9,5.6,16-5.1c5.7-10,0.9-12.9-3.6-15.6c-1.8-1.1-3.6-2.1-3.6-3.6c-0.5-5.6,7.9-16.1,11.8-20.7c7.7,0.8,15.4-2.4,14.2-9
    c-0.7-4.3,0-9.3,10.7-9.8c3.8-0.6,7.7,0.4,10.9,2.6c1.1,0.9,1.8,2.1,2.2,3.4c1.5,5.3-2.7,9.5,8,12.3c1.7,0.4,3.4,0.6,5.2,0.5
    c2.5,6.6,7.7,18.2,13.5,20.8c2.8,0.9,5.8,0.3,8.1-1.6c3.1-1.8,6.5-3.6,10.7,3.3l0,0c4.6,7.7,1,9.8-2.2,11.7c-2.6,1.5-4.9,3-5.2,6.1
    C351.9,366,358.9,375.8,363.3,381.3"
            />
          </a>
          <a data-toggle="tooltip" title="GECOM" @click="abrirLinkMandala(10)">
            <path
              class="st9"
              d="M173.8,269.6c0.7-44.1,24.3-84.7,62.3-107.1c3.9,4.7,11.9,15.2,11.3,20.8c0,1.3-1.8,2.3-3.6,3.3
    c-4.7,2.7-9.9,5.7-3.6,16.5c5.8,10,10.7,7.3,15.3,4.7c1.8-1,3.6-2,5-1.4c4.7,2.2,9.2,13.2,11.4,19.3c-10.4,6-14.3,19.1-8.9,29.8
    c1.4,3.2,3,5.3-2.6,4.5c-6.2-0.8-15-8.3-16.3,10.1c-7.1-1.1-17.7-2.1-22.4,1.2c-2.2,1.9-3.2,4.9-2.8,7.7c0,3.6-0.2,7.3-8.4,7.3
    c-9,0.2-9-4-9-7.7c0-3,0-5.8-2.4-7.6c-5.3-3.9-18.5-2.5-25.3-1.5"
            />
          </a>
          <a
            data-toggle="tooltip"
            title="Governança"
            @click="abrirLinkMandala(9)"
          >
            <path
              class="st10"
              d="M236.1,380.5C198,358,174.4,317.3,173.8,273c6.2-1,18.8-2.5,23.2,0.8c1.1,0.8,1.1,2.7,1.1,4.8
      c0,5.4,0,11.3,12.4,11.4c11.5,0,11.7-5.6,11.8-10.9c0-2.1,0-4.1,1.3-5c3.9-2.7,13.7-1.7,20.3-0.6c0,18,10.9,10.7,17.3,10.3
      c6.3-0.4,3.3,2.8,1.8,5.3c-5.5,7.9-4.6,18.6,2,25.5c2,2,4.5,3.4,7.3,4.2c-4.5,5.4-12,15.5-11.4,21.9c0.3,3.2,2.8,4.7,5.4,6.3
      c2.6,1.6,6.4,3.6,2.3,10.9c-4.4,7.8-8,5.7-11.3,3.9c-2.6-1.5-5-2.8-7.8-1.6c-5.7,2.5-10.9,13.9-13.4,20.4"
            />
            <path
              class="st1"
              d="M235.7,314.7h2.5l-3.9,11.6h-2.7l-3.9-11.6h2.5l2.2,6.9c0,0.4,0.3,0.9,0.4,1.4c0.1,0.4,0.2,0.7,0.2,1.1
      c0.1-0.9,0.4-1.7,0.6-2.6L235.7,314.7z M218.4,320.5c-0.1,1,0.2,2,0.7,2.9c0.5,0.7,1.4,1,2.2,1c1.9,0,2.9-1.3,2.9-3.9
      s-0.9-3.9-2.9-3.9c-0.8-0.1-1.7,0.3-2.2,1C218.6,318.5,218.4,319.5,218.4,320.5z M226.9,320.5c0.1,1.6-0.4,3.2-1.4,4.4
      c-2.3,2.1-5.9,2.1-8.2,0c-1.9-2.6-1.9-6.2,0-8.8c2.3-2,5.8-2,8.2,0c1.1,1.2,1.6,2.8,1.5,4.5H226.9z M209.1,319.8h4.6v6
      c-0.7,0.2-1.4,0.4-2.1,0.5c-0.7,0-1.3,0-2,0c-1.5,0.1-3-0.5-4-1.6c-1-1.2-1.5-2.8-1.4-4.4c-0.1-1.6,0.5-3.2,1.6-4.3
      c1.2-1.1,2.8-1.7,4.5-1.6c1.2,0,2.3,0.2,3.4,0.7l-0.8,2c-0.8-0.4-1.7-0.6-2.7-0.6c-1-0.1-1.9,0.3-2.5,1.1c-0.7,0.8-1,1.8-1,2.9
      c-0.1,1,0.2,2,0.8,2.8c0.5,0.7,1.4,1.1,2.2,1c0.5,0.1,1,0.1,1.6,0v-2.4h-2.2L209.1,319.8z"
            />
          </a>
          <a>
            <path
              class="st11"
              d="M340.7,214.6c13.7,6.9,22.4,27.1,9.9,42.7c7.6,3.4,25-13.8,26.6,11.7c0.1,2.4,0.1,4.9,0,7.3
      c-1.6,26.3-21.9,5.3-28.1,12.3l0,0c0.5,0.4,1,0.8,1.4,1.2c11.9,13.4,5.4,33.4-8,40.1c-4.6,2.3-10,2.9-15,1.6
      c-13.5-3.6-8.2-8.8-10.1-15.5c-0.5-1.6-1.5-2.9-2.8-4c-2.5-2.1-6.8-3.6-13.9-3.2c-13.2,0.6-14.3,6.9-13.4,12.3
      c1.2,7.6-6.5,11.6-15.1,11.5c-3.5-0.1-6.9-0.8-10.1-2c-2.5-1-4.9-2.5-6.8-4.5c-8.4-8.7-9.5-22.2-2.6-32.1c2-3.1,5-7.3-2.2-6.7
      c-7.2,0.6-20.1,8.7-21.6-9.3c-0.5-4.2-0.4-8.5,0.4-12.7c2.6-17,12.8-9,20-7.8c7.2,1.1,4.9-1.6,3.2-5.6c-5.8-11.9-2.8-26.3,7.3-34.9
      c4.5-3.9,10.5-5.6,16.5-4.7c24.4,4.5-3.6,25.5,25.1,25.2c11.5,0,15.6-5.1,16.5-10.1c1.2-7-2.6-11.7,8.3-14.6
      C331,211.7,336.2,212.3,340.7,214.6z"
            />
          </a>
          <a
            data-toggle="tooltip"
            title="Operação"
            @click="abrirLinkMandala(4)"
            id="operacao"
          >
            <path
              class="st12"
              d="M340.7,214.6c13.7,6.9,22.4,27.1,9.9,42.7c7.6,3.4,25-13.8,26.6,11.7c0.1,2.4,0.1,4.9,0,7.3
      c-1.6,26.3-21.9,5.3-28.1,12.3l0,0c0.5,0.4,1,0.8,1.4,1.2c11.9,13.4,5.4,33.4-8,40.1c-4.6,2.3-10,2.9-15,1.6
      c-13.5-3.6-8.2-8.8-10.1-15.5c-0.5-1.6-1.5-2.9-2.8-4c-2.5-2.1-6.8-3.6-13.9-3.2c-13.2,0.6-14.3,6.9-13.4,12.3
      c1.2,7.6-6.5,11.6-15.1,11.5c-3.5-0.1-6.9-0.8-10.1-2c-2.5-1-4.9-2.5-6.8-4.5c-8.4-8.7-9.5-22.2-2.6-32.1c2-3.1,5-7.3-2.2-6.7
      c-7.2,0.6-20.1,8.7-21.6-9.3c-0.5-4.2-0.4-8.5,0.4-12.7c2.6-17,12.8-9,20-7.8c7.2,1.1,4.9-1.6,3.2-5.6c-5.8-11.9-2.8-26.3,7.3-34.9
      c4.5-3.9,10.5-5.6,16.5-4.7c24.4,4.5-3.6,25.5,25.1,25.2c11.5,0,15.6-5.1,16.5-10.1c1.2-7-2.6-11.7,8.3-14.6
      C331,211.7,336.2,212.3,340.7,214.6z"
            />
            <path
              class="st0"
              d="M338.3,271.8c-0.1,1.3,0.1,2.7,0.6,3.9c0.3,0.8,1.1,1.3,1.9,1.3c1.7,0,2.5-1.7,2.5-5.2c0-3.5-0.8-5.3-2.5-5.3
      c-0.9,0-1.6,0.5-1.9,1.4C338.4,269.1,338.2,270.4,338.3,271.8 M346.2,271.7c0.1,2-0.3,4-1.4,5.7c-0.9,1.3-2.4,2.1-3.9,2
      c-1.6,0.1-3.1-0.7-4-2c-1.9-3.6-1.9-7.8,0-11.4c0.9-1.3,2.4-2,4-2c1.6-0.1,3.1,0.7,4,2C345.8,267.8,346.3,269.8,346.2,271.7
      L346.2,271.7z M327.9,262c-0.2,0-0.4,0.1-0.5,0.3c-0.2,0.3-0.3,0.5-0.3,0.8h-1.6c0-0.8,0.2-1.6,0.7-2.3c0.4-0.5,0.9-0.8,1.5-0.8
      c0.3,0,0.6,0.1,0.8,0.2c0.2,0.1,0.4,0.2,0.6,0.4l0.6,0.4c0.2,0.1,0.4,0.2,0.6,0.2c0.2,0,0.4-0.1,0.5-0.3c0.1-0.3,0.2-0.5,0.3-0.8
      h1.7c0,0.8-0.3,1.6-0.7,2.3c-0.4,0.5-0.9,0.8-1.5,0.8c-0.3,0.1-0.5,0.1-0.8,0c-0.2-0.1-0.4-0.2-0.6-0.4l-0.6-0.4
      C328.4,262.2,328.1,262.1,327.9,262L327.9,262z M330.5,273l-0.8-3.4c-0.2-0.5-0.4-1-0.5-1.6c0-0.8-0.3-1.4-0.3-1.7
      c0,0.6-0.2,1.2-0.4,1.9c-0.1,0.7-0.5,2.3-1.1,4.8L330.5,273z M331.9,279.2l-0.8-3.6h-4l-0.8,3.6h-2.8l3.6-15h3.6l3.6,15H331.9z
       M321.5,281.9c0,0.6-0.3,1.3-0.7,1.7c-0.7,0.5-1.5,0.7-2.3,0.6c-0.4,0-0.9-0.1-1.3-0.2v-1.6c0.4,0.1,0.8,0.1,1.2,0
      c0.6,0,0.9-0.2,0.9-0.7c0-0.6-0.5-0.9-1.6-1.1l0.8-1.6h1.8l-0.4,0.8c0.4,0.1,0.9,0.3,1.2,0.7C321.4,280.8,321.5,281.4,321.5,281.9
      L321.5,281.9z M320.1,266.5c-0.9,0-1.6,0.6-1.9,1.4c-0.5,1.2-0.7,2.6-0.7,3.9c0,3.4,0.9,5.2,2.7,5.2c0.9,0,1.7-0.3,2.5-0.8v2.5
      c-0.8,0.5-1.8,0.8-2.8,0.7c-1.5,0.1-3-0.7-3.9-2c-1-1.7-1.5-3.7-1.4-5.7c-0.1-2,0.4-4,1.4-5.7c0.8-1.3,2.3-2.1,3.9-2
      c0.5,0,1.1,0.1,1.6,0.2c0.6,0.2,1.1,0.4,1.6,0.8l-0.9,2.3c-0.3-0.2-0.6-0.4-0.9-0.6C320.8,266.6,320.4,266.5,320.1,266.5z
       M309.6,273l-0.8-3.4c0-0.2,0-0.8-0.3-1.6s-0.3-1.4-0.3-1.7c0,0.6,0,1.2-0.3,1.9c-0.3,0.7-0.5,2.3-1.1,4.8L309.6,273z M310.9,279.2
      l-0.9-3.6h-3.8l-0.9,3.6h-2.8l3.6-15h3.6l3.6,15H310.9z M296.4,271.1h0.7c0.5,0.1,1.1-0.2,1.4-0.6c0.3-0.5,0.5-1.1,0.4-1.6
      c0.1-0.6-0.1-1.1-0.4-1.6c-0.4-0.4-0.9-0.6-1.4-0.5h-0.7V271.1z M296.4,273.4v5.8h-2.8v-14.9h3.6c2.1-0.3,4.1,1.1,4.5,3.2
      c0.1,0.4,0.1,0.8,0,1.2c0.2,1.6-0.6,3.1-1.9,3.9l2.8,6.5h-3l-2.4-5.8H296.4z M291.4,279.2h-6.8v-14.9h6.7v2.4h-3.9v3.6h3.6v2.4
      h-3.6v4.2h3.9L291.4,279.2z M277.5,271.4h0.6c0.6,0,1.1-0.2,1.5-0.5c0.4-0.5,0.6-1.2,0.5-1.9c0-0.6-0.1-1.2-0.5-1.7
      c-0.3-0.4-0.9-0.6-1.4-0.6h-0.7L277.5,271.4z M282.8,268.9c0.1,1.3-0.3,2.6-1.2,3.6c-0.9,0.9-2.2,1.3-3.5,1.2h-0.7v5.4h-2.8v-14.9
      h3.6C281.3,264.3,282.7,265.8,282.8,268.9L282.8,268.9z M264.6,271.8c-0.1,1.3,0.2,2.7,0.7,3.9c0.3,0.8,1.1,1.3,1.9,1.3
      c1.6,0,2.5-1.7,2.5-5.2c0-3.5-0.8-5.3-2.4-5.3c-0.9,0-1.6,0.5-1.9,1.4C264.7,269.1,264.5,270.4,264.6,271.8z M272.5,271.8
      c0.1,2-0.4,4-1.4,5.7c-1.6,2.2-4.7,2.6-6.9,1c-0.4-0.3-0.7-0.6-1-1c-1.9-3.6-1.9-7.8,0-11.4c0.9-1.3,2.4-2.1,4-2
      c1.6-0.1,3.1,0.7,3.9,2C272.1,267.8,272.6,269.8,272.5,271.8z"
            />
          </a>
          <a
            data-toggle="tooltip"
            title="Gestão de Riscos"
            data-placement="bottom"
            @click="abrirLinkMandala(2)"
          >
            <path
              class="st13"
              d="M440,263.8c4,76.7-54.7,142.2-131.4,146.7l-9.2,18.6l8.4,17.2c96.5-3.8,171.7-85,168.1-181.5l-17.2,8.4
      L440,263.8z"
            />
            <path
              class="st14"
              d="M456,317.8c1,0.3,1.8,1,2.2,2c0.4,1.2,0.4,2.5,0,3.6c-0.4,1.1-1,2.2-1.8,3.1l-2.3-0.8c0.5-0.5,1-1.1,1.4-1.8
      c0.3-0.4,0.5-0.9,0.7-1.4c0.1-0.4,0.1-0.9,0-1.4c-0.1-0.4-0.4-0.7-0.8-0.8c-0.2-0.1-0.5-0.1-0.7,0c-0.3,0.1-0.5,0.2-0.7,0.4
      c-0.5,0.4-0.9,0.8-1.3,1.2c-0.4,0.5-0.9,1-1.5,1.3c-0.4,0.2-0.9,0.4-1.4,0.5c-0.5,0.1-1.1,0.1-1.6,0c-1-0.3-1.7-0.9-2.1-1.9
      c-0.4-1-0.4-2.2,0-3.2c0.2-0.6,0.4-1.2,0.8-1.7c0.4-0.5,0.8-1.1,1.2-1.5l1.6,1.5c-0.4,0.4-0.7,0.9-1,1.4c-0.2,0.4-0.4,0.8-0.5,1.2
      c-0.2,0.4-0.2,0.8,0,1.2c0.1,0.3,0.4,0.6,0.7,0.7c0.2,0,0.5,0,0.7-0.1c0.2-0.1,0.4-0.2,0.6-0.3c0.5-0.4,0.9-0.8,1.4-1.3
      c0.7-0.7,1.4-1.3,2.3-1.8C454.6,317.6,455.3,317.6,456,317.8 M447,334c1,0.5,2.1,0.7,3.2,0.5c0.9-0.2,1.6-0.9,1.9-1.8
      c0.8-1.9,0-3.4-2.6-4.5s-4.3-0.7-5.1,1.2c-0.4,0.8-0.4,1.8,0,2.6C445.1,332.9,446,333.6,447,334L447,334z M450.7,325.8
      c1.6,0.6,2.9,1.8,3.6,3.3c0.6,1.5,0.5,3.2-0.3,4.7c-0.6,1.5-1.7,2.7-3.2,3.3c-1.6,0.4-3.4,0.2-4.9-0.6c-1.6-0.6-2.9-1.8-3.6-3.3
      c-1-3.2,0.6-6.6,3.6-8C447.5,324.8,449.2,325,450.7,325.8L450.7,325.8z M440.1,338.9c-0.4,0.8-0.4,1.8,0,2.6
      c0.6,0.9,1.4,1.7,2.4,2.1c2.4,1.2,4.2,0.9,5.1-0.9c0.4-1,0.7-2,0.8-3.1l2,1c-0.1,1.2-0.4,2.3-0.9,3.3c-0.6,1.5-1.9,2.6-3.5,3
      c-1.7,0.3-3.4,0-4.9-0.8c-1-0.5-2-1.2-2.7-2.1c-0.6-0.8-1-1.8-1.1-2.8c-0.1-1.1,0.2-2.1,0.7-3.1c0.6-1.1,1.4-2.1,2.4-2.8l1.5,1.7
      c-0.4,0.3-0.8,0.6-1.1,1C440.5,338.1,440.2,338.5,440.1,338.9L440.1,338.9z M443,348.4c0.9,0.4,1.6,1.3,1.7,2.3
      c0.1,1.2-0.2,2.4-0.8,3.4c-0.6,1.1-1.4,2-2.4,2.7l-2.1-1.2c0.6-0.4,1.2-0.9,1.7-1.5c0.4-0.4,0.7-0.8,1-1.3c0.3-0.4,0.4-0.8,0.4-1.3
      c-0.1-0.4-0.3-0.7-0.6-0.9c-0.2-0.1-0.4-0.1-0.7,0c-0.3,0-0.5,0.1-0.8,0.3c-0.5,0.3-1,0.6-1.5,1c-0.5,0.4-1.1,0.7-1.7,1
      c-0.5,0.1-1,0.2-1.5,0.2c-0.5,0-1-0.2-1.5-0.5c-0.9-0.4-1.5-1.3-1.7-2.2c-0.2-1.1,0.1-2.2,0.7-3.1c0.3-0.5,0.7-1,1.1-1.5
      c0.5-0.5,0.9-0.9,1.5-1.3l1.4,1.8c-0.5,0.3-0.9,0.7-1.3,1.1c-0.3,0.3-0.6,0.7-0.8,1.1c-0.2,0.3-0.3,0.7-0.3,1.1
      c0.1,0.3,0.3,0.6,0.6,0.8c0.2,0.1,0.4,0.1,0.6,0c0.2,0,0.4,0,0.7,0c0.5-0.3,1.1-0.6,1.6-1c0.8-0.6,1.7-1,2.6-1.2
      C441.6,347.9,442.3,348,443,348.4L443,348.4z M439.4,361.2l-10.5-6.5l1.4-2.2l10.5,6.5L439.4,361.2z M430.4,365.8l3.9,2.7l-1.5,2.2
      l-10.2-7l2.1-3c0.7-1.1,1.7-2,2.9-2.6c1-0.3,2.1-0.1,3,0.6c0.6,0.4,1,1,1.3,1.6c0.2,0.7,0.2,1.4,0,2.2h6.5l-1.7,2.4l-5.5-0.3
      L430.4,365.8z M428.7,364.5l0.5-0.7c0.4-0.5,0.7-1.1,0.7-1.7c0-0.5-0.3-1-0.7-1.2c-0.4-0.3-0.9-0.4-1.4-0.2c-0.6,0.3-1,0.8-1.4,1.3
      l-0.4,0.7L428.7,364.5z M428,376l-4.6,5.4l-9.4-8l4.6-5.4l1.6,1.4l-2.9,3.4l2.1,1.8l2.7-3.2l1.6,1.4L421,376l2.4,2l2.9-3.4L428,376
      z M415.5,380.8c-1.9-1.9-3.6-2-5.3-0.4l-1,0.9l5.7,5.8l0.8-0.8c1.5-1.4,1.6-3.7,0.2-5.2C415.7,381,415.6,380.9,415.5,380.8
      L415.5,380.8z M417.4,378.8c1.3,1.2,2,2.8,2,4.6c-0.2,1.8-1.1,3.5-2.4,4.7l-2.5,2.4l-8.6-8.7l2.8-2.7c1.2-1.3,2.7-2,4.5-2.2
      C414.7,376.9,416.3,377.6,417.4,378.8L417.4,378.8z M398.5,396.2c0.6,0.9,1.5,1.6,2.6,1.9c0.9,0.2,1.8,0,2.5-0.7
      c1.6-1.3,1.6-3.1,0-5.2c-1.6-2.2-3.4-2.6-5-1.3c-0.7,0.5-1.2,1.4-1.2,2.3C397.4,394.3,397.8,395.3,398.5,396.2z M405.4,390.6
      c1.1,1.3,1.8,2.9,1.8,4.6c-0.2,1.6-1,3.1-2.4,4c-1.2,1.1-2.8,1.6-4.4,1.5c-1.7-0.3-3.2-1.3-4.2-2.7c-1.2-1.3-1.8-2.9-1.8-4.6
      c0.2-1.6,1-3.1,2.4-4c1.2-1.1,2.8-1.6,4.4-1.5C402.9,388.2,404.4,389.2,405.4,390.6z M385.8,395.6c-0.1,0.1-0.2,0.3-0.3,0.5
      c-0.1,0.2-0.1,0.5,0,0.7l-1.1,0.7c-0.4-0.6-0.5-1.3-0.5-2c0.1-0.6,0.4-1.1,0.9-1.5c0.2-0.1,0.4-0.2,0.6-0.3h0.7h0.7
      c0.2,0,0.4-0.1,0.6-0.2c0.2-0.1,0.3-0.3,0.3-0.5c0.1-0.2,0.1-0.5,0-0.8l1.1-0.7c0.4,0.6,0.5,1.3,0.5,2c-0.1,0.6-0.4,1.1-0.9,1.5
      c-0.2,0.1-0.4,0.2-0.6,0.3H387h-0.7C386.1,395.4,385.9,395.4,385.8,395.6L385.8,395.6z M393.3,401.5l-3.6-3l-0.7-0.6
      c0.3,0.7,0.8,2.5,1.6,5.3L393.3,401.5z M397.4,404.9l-2.4-2l-3.6,2.5l0.9,2.9l-2.3,1.6l-3.2-12.7l2.7-1.8l10.5,7.9L397.4,404.9z
       M387.6,411.1l-2.3,1.4l-5-8.8l-2.9,1.7l-1.1-1.9l8-4.8l1.1,1.9l-2.9,1.7L387.6,411.1z M380,411.8c0.5,0.9,0.5,2,0,2.9
      c-0.5,1.1-1.4,1.9-2.5,2.4c-1,0.6-2.2,0.9-3.4,1l-1.1-2.2c0.7-0.1,1.5-0.2,2.2-0.3c0.5-0.1,1-0.3,1.5-0.5c0.4-0.2,0.7-0.5,1-0.9
      c0.2-0.3,0.2-0.8,0-1.1c-0.1-0.2-0.3-0.4-0.5-0.5c-0.2-0.1-0.4-0.1-0.5-0.2h-1.8c-0.7,0.1-1.3,0.1-2,0c-0.5-0.1-0.9-0.3-1.3-0.6
      c-1.2-0.9-1.7-2.5-1.2-4c0.4-1,1.2-1.8,2.3-2.3c0.5-0.3,1.1-0.5,1.7-0.7l1.9-0.3l0.2,2.2c-0.6,0-1.1,0.1-1.7,0.3
      c-0.4,0.1-0.8,0.2-1.2,0.4c-0.4,0.2-0.7,0.5-0.8,0.9c-0.2,0.3-0.2,0.7,0,1c0.1,0.2,0.2,0.3,0.4,0.4c0.2,0.1,0.5,0.2,0.7,0.2h1.9
      c1-0.1,2,0,2.9,0.4C379.2,410.7,379.7,411.2,380,411.8L380,411.8z M372.7,419.4l-6.5,3l-5.3-11.2l6.4-3l0.9,1.9l-4.1,1.9l1.1,2.4
      l3.6-1.8l0.9,1.9l-3.6,1.8l1.4,2.9l4.1-1.9L372.7,419.4z M356.7,418.9l4.6-1.6l2.2,6.1c-0.6,0.5-1.3,0.9-1.9,1.2
      c-0.6,0.3-1.3,0.6-2,0.9c-1.5,0.7-3.1,0.7-4.6,0c-1.4-0.9-2.5-2.3-3-4c-0.7-1.6-0.7-3.4,0-5c0.8-1.5,2.3-2.7,3.9-3.2
      c1.2-0.4,2.4-0.6,3.6-0.5v2.3c-1-0.1-2,0-2.9,0.3c-1,0.3-1.8,1-2.2,2c-0.4,1-0.4,2.2,0,3.2c0.3,1,0.9,2,1.8,2.6
      c0.8,0.4,1.8,0.4,2.6,0c0.5-0.2,1-0.4,1.5-0.7l-0.8-2.4l-2.2,0.8L356.7,418.9z"
            />
          </a>
          <a>
            <path
              class="st15"
              d="M161.3,138.8c0.8,1,2,1.7,3.2,2c1.1,0.2,2.1-0.2,2.8-1c1.8-1.7,1.5-3.6-0.7-6.1c-2.2-2.4-4.3-2.7-6.1-1
      c-0.8,0.7-1.3,1.7-1.2,2.8C159.8,136.8,160.4,137.9,161.3,138.8z M169,131.5c1.5,1.4,2.4,3.2,2.5,5.3c0,1.9-0.9,3.7-2.4,4.9
      c-1.3,1.4-3.2,2.2-5.1,2.1c-2-0.2-3.8-1.2-5.1-2.8c-1.5-1.4-2.4-3.2-2.5-5.3c0-1.9,0.9-3.7,2.4-4.9c1.3-1.4,3.1-2.2,5-2.2
      C165.9,128.9,167.8,129.9,169,131.5L169,131.5z M145.3,142.7c-0.1,0.2-0.1,0.4,0,0.6c0,0.3,0.2,0.6,0.4,0.8l-1,1.1
      c-0.6-0.5-1-1.3-1.2-2.1c-0.1-0.7,0.1-1.4,0.6-2c0.2-0.2,0.4-0.4,0.7-0.5l0.8-0.4l0.8-0.3c0.2-0.1,0.4-0.3,0.5-0.5
      c0.1-0.2,0.1-0.5,0-0.7c0-0.3-0.2-0.6-0.4-0.8l1-1.1c0.6,0.6,1,1.3,1.2,2.1c0.1,0.7-0.1,1.4-0.6,1.9c-0.2,0.2-0.4,0.4-0.6,0.5
      l-0.8,0.3l-0.7,0.4C145.6,142.2,145.4,142.4,145.3,142.7z M155.7,146.9l-5.1-2.2c-0.3-0.1-0.6-0.3-0.9-0.4c0.5,0.7,1.7,2.6,3.6,5.5
      L155.7,146.9z M161.4,149.5l-3.3-1.5l-3.4,4l1.9,3l-2.2,2.5l-7.7-13.4l2.4-2.9l14.4,5.6L161.4,149.5z M150.9,155.9
      c1,0.7,1.6,1.8,1.6,3c-0.1,1.3-0.6,2.6-1.5,3.6c-0.9,1.1-2,2-3.2,2.7l-2.3-1.8c0.8-0.4,1.5-0.9,2.2-1.4c0.5-0.4,1-0.8,1.4-1.3
      c0.4-0.4,0.6-0.9,0.7-1.5c0-0.5-0.2-0.9-0.6-1.2c-0.2-0.2-0.5-0.2-0.7-0.3c-0.3,0-0.6,0-0.9,0l-1.9,0.9c-0.7,0.4-1.4,0.6-2.2,0.8
      c-0.6,0.1-1.1,0.1-1.7,0c-0.6-0.1-1.2-0.4-1.7-0.8c-1-0.7-1.5-1.7-1.6-2.9c0-1.3,0.4-2.6,1.3-3.6c0.5-0.6,1-1.1,1.5-1.5
      c0.6-0.5,1.3-0.9,1.9-1.2l1.3,2.2c-0.6,0.5-1.2,1-1.7,1.6c-0.4,0.3-0.7,0.7-1.1,1.1c-0.3,0.4-0.5,0.8-0.5,1.3c0,0.4,0.2,0.8,0.5,1
      c0.2,0.2,0.4,0.3,0.7,0.3l0.7,0c0.3,0,1-0.4,2-0.9c1-0.6,2.1-0.9,3.2-1C149.3,155,150.2,155.3,150.9,155.9z M144,165
      c1,0.6,1.7,1.7,1.7,3c0,1.4-0.4,2.8-1.3,3.9c-0.8,1.2-1.8,2.2-3.1,2.9l-2.3-1.6c0.8-0.4,1.5-1,2.2-1.5c0.5-0.4,0.9-0.9,1.3-1.4
      c0.3-0.4,0.5-1,0.5-1.5c0-0.5-0.2-0.9-0.6-1.1c-0.2-0.1-0.5-0.2-0.8-0.2c-0.3,0-0.6,0.1-0.9,0.2c-0.7,0.3-1.3,0.6-1.9,0.9
      c-0.7,0.4-1.4,0.8-2.1,1c-0.6,0.1-1.1,0.1-1.7,0c-0.6-0.1-1.2-0.3-1.7-0.7c-1-0.6-1.7-1.6-1.8-2.8c-0.1-1.3,0.3-2.6,1.2-3.6
      c0.4-0.6,0.9-1.1,1.5-1.6c0.6-0.5,1.2-0.9,1.8-1.4l1.4,2.2c-0.6,0.3-1.1,0.7-1.6,1.2c-0.4,0.3-0.7,0.7-1,1.1
      c-0.3,0.4-0.4,0.9-0.4,1.4c0,0.4,0.3,0.8,0.6,1c0.2,0.2,0.4,0.2,0.7,0.3c0.3,0,0.5,0,0.8,0c0.3,0,1-0.5,2-1c1-0.6,2-1,3.2-1.1
      C142.5,164.3,143.3,164.5,144,165z M139,180.1l-12.3-7.7l1.8-2.6l12.3,7.7L139,180.1z M132.9,191.7l-11.7-1.8l0,0
      c2.2,0.9,3.6,1.6,4.2,1.9l6.1,3l-1.3,2.4l-13-6.5l1.9-3.6l11.5,1.9l0,0l-8.3-8.4l1.9-3.6l13,6.4l-1.3,2.5l-6.1-3.1l-1.2-0.5l-3-1.6
      l0,0l8.5,8.4L132.9,191.7z"
            />
          </a>
          <a>
            <path
              class="st15"
              d="M335.2,465.2c0.3,1.2,0,2.4-0.8,3.3c-1,1-2.3,1.6-3.6,1.8c-1.4,0.4-2.8,0.4-4.2,0l-0.5-2.8
      c0.9,0.2,1.7,0.4,2.6,0.5c0.6,0.1,1.2,0.1,1.9,0c0.5-0.1,1-0.3,1.5-0.7c0.3-0.3,0.5-0.8,0.3-1.2c0-0.3-0.2-0.5-0.4-0.7
      c-0.2-0.2-0.5-0.4-0.8-0.5c-0.7-0.3-1.3-0.5-2-0.7c-0.8-0.2-1.5-0.5-2.2-0.8c-0.5-0.3-0.9-0.7-1.3-1.1c-0.4-0.5-0.6-1.1-0.7-1.7
      c-0.3-1.1,0-2.3,0.8-3.2c0.9-1,2.1-1.6,3.4-1.7c0.7-0.1,1.4-0.1,2.2,0c0.8,0.1,1.5,0.2,2.3,0.4l-0.5,2.5c-0.7-0.1-1.3-0.2-2-0.3
      c-0.5-0.1-1-0.1-1.5,0c-0.5,0-0.9,0.3-1.2,0.6c-0.3,0.3-0.4,0.7-0.3,1.1c0,0.2,0.1,0.5,0.3,0.7c0.2,0.2,0.4,0.4,0.7,0.5
      c0.7,0.3,1.4,0.5,2.1,0.7c1.1,0.2,2.2,0.7,3.1,1.5c0.6,0.6,1.1,1.4,1.2,2.2 M324.4,470.8l-8.3,1.1l-1.9-14.4l8.3-1.1l0.4,2.5
      l-5.3,0.7l0.4,3.2l4.9-0.7l0.3,2.5l-5,0.7l0.5,3.6l5.2-0.7L324.4,470.8z M305.7,466.7v5.6h-3.1l-0.3-14.6h4.2
      c1.5-0.1,3.1,0.2,4.4,1c1,0.7,1.6,2,1.5,3.2c0,0.8-0.2,1.6-0.6,2.3c-0.5,0.7-1.2,1.2-2,1.6l4.4,6.2h-3.4l-3.6-5.5L305.7,466.7z
       M305.7,464.2h1c0.7,0.1,1.5-0.1,2.1-0.5c0.5-0.3,0.7-0.9,0.7-1.5c0-0.6-0.2-1.1-0.7-1.5c-0.7-0.3-1.5-0.4-2.2-0.4h-0.9
      L305.7,464.2z M289.4,464.9c-0.1,1.3,0.2,2.6,0.8,3.6c0.6,0.9,1.6,1.3,2.7,1.3c2.5,0,3.6-1.5,3.8-4.8c0.2-3.2-1.1-5-3.6-5
      c-1.1-0.1-2.1,0.3-2.8,1.1C289.6,462.3,289.3,463.6,289.4,464.9L289.4,464.9z M300,465.2c0.1,2-0.6,4-1.9,5.5
      c-3,2.5-7.4,2.4-10.3-0.3c-1.2-1.6-1.8-3.6-1.6-5.6c-0.1-2,0.6-4,1.9-5.5c1.4-1.3,3.3-1.9,5.2-1.8c1.9-0.1,3.8,0.7,5.1,2.1
      C299.6,461.2,300.2,463.2,300,465.2L300,465.2z M275.6,471.2l1-14.6l3.1,0.2l-0.8,12l5.9,0.4v2.5L275.6,471.2z M269.8,464.2
      c-0.4-3.2-0.6-5.1-0.7-5.5c-0.1-0.4,0-0.8,0-1c-0.4,0.8-1.4,2.8-3,5.9L269.8,464.2z M270.5,470.4l-0.4-3.6l-5.2-0.9l-1.6,3.2
      l-3.3-0.6l7.6-13.5l3.6,0.7l2.5,15.3L270.5,470.4z M261,453.9l3,0.7l-8.1,13.1l-3.3-0.8l-1.5-15.3l3,0.7l0.7,9c0,0.5,0,1.1,0,1.8
      c0,0.5,0,1,0,1.5c0.4-1,0.9-2,1.4-3L261,453.9z"
            />
          </a>
          <polygon
            class="st2"
            points="297.8,516.7 270.9,491.1 281.4,491.1 297.8,506.3 314.3,491.1 324.9,491.1 "
          />
          <a
            data-toggle="tooltip"
            title="Visão"
            @click="$vuetify.goTo('#missao-visao')"            
          >
            <path
              class="st15"
              d="M481.4,205.2c-1.3,0.3-2.4,1-3.2,2c-0.6,0.9-0.7,2-0.3,3c0.7,2.4,2.7,3,5.8,2c3.1-1,4.3-2.7,3.6-5
      c-0.3-1-1-1.9-2-2.3c-1.2-0.3-2.5-0.2-3.6,0.2 M484.6,215.4c-1.9,0.7-4,0.7-5.8,0c-1.7-0.9-2.9-2.5-3.4-4.3
      c-0.7-1.8-0.6-3.8,0.3-5.5c1.1-1.7,2.8-2.9,4.8-3.4c1.9-0.8,3.9-0.8,5.8,0c3.2,2,4.5,6,3.1,9.5
      C488.3,213.6,486.6,214.9,484.6,215.4z M485.8,189.9c-0.1-0.2-0.3-0.4-0.5-0.5c-0.3-0.1-0.6-0.1-0.9,0L484,188
      c0.8-0.3,1.6-0.3,2.4,0c0.7,0.2,1.2,0.8,1.4,1.5c0.1,0.3,0.1,0.5,0,0.8c0,0.3,0,0.6,0,0.9c0,0.3,0,0.5,0,0.8c0,0.3,0,0.5,0,0.8
      c0.1,0.2,0.2,0.4,0.4,0.4c0.3,0.1,0.6,0.1,0.9,0l0.5,1.4c-0.8,0.3-1.6,0.3-2.4,0c-0.7-0.3-1.2-0.8-1.4-1.5c0-0.3,0-0.5,0-0.8
      c0-0.3,0-0.6,0-0.8c0-0.3,0-0.5,0-0.8c0.1-0.3,0.1-0.6,0-0.9V189.9z M477.1,196.9l4.4-3.4l0.8-0.6c-0.9,0-3.1,0.1-6.6,0.4
      L477.1,196.9z M472.1,200.6l2.8-2.2l-1.9-4.9l-3.6,0.2l-1.2-3.1l15.4-0.3l1.3,3.6l-11.9,9.9L472.1,200.6z M471.5,188.1
      c-1.1,0.6-2.4,0.6-3.4,0c-1.2-0.7-2.2-1.7-2.7-3c-0.7-1.2-1.1-2.6-1.1-4l2.6-1.2c0,0.9,0.1,1.8,0.3,2.6c0.1,0.6,0.3,1.2,0.5,1.8
      c0.2,0.5,0.6,0.9,1.1,1.2c0.4,0.2,0.9,0.2,1.3,0c0.2-0.1,0.4-0.3,0.5-0.5c0.2-0.3,0.3-0.6,0.3-0.9c0-0.7,0-1.4,0-2.1
      c0-0.8,0.1-1.6,0.2-2.3c0.1-0.6,0.4-1.1,0.7-1.5c0.4-0.5,0.9-0.9,1.4-1.1c1-0.6,2.2-0.6,3.3-0.2c1.2,0.6,2.1,1.6,2.6,2.8
      c0.3,0.6,0.6,1.3,0.7,2c0.1,0.7,0.2,1.5,0.3,2.3h-2.6c0-0.7-0.1-1.4-0.3-2c-0.1-0.5-0.3-1-0.5-1.4c-0.2-0.4-0.5-0.8-0.9-1
      c-0.4-0.2-0.8-0.2-1.2,0c-0.2,0.1-0.4,0.3-0.5,0.5c-0.2,0.2-0.3,0.5-0.3,0.8c0,0.3,0,1.1,0,2.2c0.1,1.1-0.1,2.3-0.5,3.4
      C472.9,186.9,472.3,187.6,471.5,188.1z M460.9,176.2l12.7-7l1.5,2.7l-12.8,7.1L460.9,176.2z M470.7,164.3l1.6,2.6l-14.9,3.6
      l-1.8-2.8l9.7-11.9l1.6,2.7l-5.9,6.9l-1.3,1.4l-1.1,1c1-0.4,2.1-0.8,3.1-1.1L470.7,164.3z"
            />
          </a>
          <text
            transform="matrix(1 0 0 1 183.4531 553.2803)"
            class="st12 st16 st17"
          >
            SOLUÇÕES PARA OS CLIENTES
          </text>
          <text
            transform="matrix(1 0 0 1 186.7002 18.8818)"
            class="st12 st16 st17"
          >
            NECESSIDADES DO MERCADO
          </text>
          <g>
            <path
              class="st1"
              d="M367.4,224.4l-0.8-2.6h-3.9l-0.8,2.6h-2.5l3.8-10.8h2.8l3.8,10.8H367.4z M366,219.9c-0.7-2.3-1.1-3.6-1.2-4
      c-0.1-0.3-0.2-0.5-0.2-0.7c-0.2,0.6-0.6,2.2-1.4,4.7H366z"
            />
            <path
              class="st1"
              d="M377.6,221.4c0,1-0.3,1.7-1.1,2.3c-0.7,0.6-1.7,0.8-2.9,0.8c-1.2,0-2.2-0.2-3.1-0.7v-2.1
      c0.7,0.3,1.3,0.6,1.8,0.7c0.5,0.1,1,0.2,1.4,0.2c0.5,0,0.9-0.1,1.2-0.3c0.3-0.2,0.4-0.5,0.4-0.9c0-0.2-0.1-0.4-0.2-0.6
      c-0.1-0.2-0.3-0.3-0.5-0.5c-0.2-0.2-0.7-0.4-1.4-0.7c-0.7-0.3-1.2-0.6-1.5-0.9c-0.3-0.3-0.6-0.6-0.8-1c-0.2-0.4-0.3-0.8-0.3-1.3
      c0-1,0.3-1.7,1-2.3c0.6-0.5,1.5-0.8,2.7-0.8c0.6,0,1.1,0.1,1.6,0.2c0.5,0.1,1,0.3,1.6,0.6l-0.7,1.8c-0.6-0.2-1.1-0.4-1.4-0.5
      c-0.4-0.1-0.7-0.1-1.1-0.1c-0.4,0-0.8,0.1-1,0.3c-0.2,0.2-0.3,0.5-0.3,0.8c0,0.2,0,0.4,0.1,0.5c0.1,0.1,0.2,0.3,0.4,0.4
      c0.2,0.1,0.7,0.4,1.5,0.8c1,0.5,1.7,1,2.1,1.5C377.4,220.1,377.6,220.7,377.6,221.4z"
            />
            <path
              class="st1"
              d="M378.4,227.6c-0.5,0-1-0.1-1.4-0.2v-1.9c0.4,0.1,0.8,0.1,1.1,0.1c0.5,0,0.9-0.2,1.1-0.5
      c0.2-0.3,0.3-0.8,0.3-1.5v-10.1h2.3v10.1c0,1.3-0.3,2.2-0.9,2.9C380.4,227.3,379.5,227.6,378.4,227.6z"
            />
            <path
              class="st1"
              d="M393.3,213.6v7c0,0.8-0.2,1.5-0.5,2.1c-0.4,0.6-0.9,1.1-1.5,1.4c-0.7,0.3-1.5,0.5-2.4,0.5
      c-1.4,0-2.5-0.4-3.2-1.1c-0.8-0.7-1.2-1.7-1.2-2.9v-7h2.3v6.6c0,0.8,0.2,1.4,0.5,1.8c0.3,0.4,0.9,0.6,1.7,0.6
      c0.7,0,1.3-0.2,1.6-0.6c0.3-0.4,0.5-1,0.5-1.8v-6.6H393.3z"
            />
            <path
              class="st1"
              d="M398.2,220.3v4.1h-2.3v-10.8h3.1c1.5,0,2.6,0.3,3.3,0.8c0.7,0.5,1.1,1.3,1.1,2.4c0,0.6-0.2,1.2-0.5,1.7
      c-0.3,0.5-0.8,0.9-1.5,1.2c1.6,2.4,2.7,4,3.2,4.7H402l-2.6-4.1H398.2z M398.2,218.4h0.7c0.7,0,1.3-0.1,1.6-0.4
      c0.3-0.2,0.5-0.6,0.5-1.1c0-0.5-0.2-0.9-0.5-1.1c-0.4-0.2-0.9-0.3-1.6-0.3h-0.7V218.4z"
            />
          </g>
          <g>
            <path
              class="st1"
              d="M285.6,367.7h4.3v5.6c-0.7,0.2-1.3,0.4-2,0.5c-0.6,0.1-1.2,0.1-1.9,0.1c-1.6,0-2.9-0.5-3.7-1.4
      c-0.9-1-1.3-2.3-1.3-4.1c0-1.7,0.5-3.1,1.5-4.1c1-1,2.4-1.5,4.1-1.5c1.1,0,2.2,0.2,3.2,0.7l-0.8,1.8c-0.8-0.4-1.6-0.6-2.5-0.6
      c-1,0-1.8,0.3-2.4,1c-0.6,0.7-0.9,1.6-0.9,2.7c0,1.2,0.2,2.1,0.7,2.7c0.5,0.6,1.2,0.9,2.1,0.9c0.5,0,1,0,1.5-0.1v-2.3h-2V367.7z"
            />
            <path
              class="st1"
              d="M298.7,373.8h-6.2V363h6.2v1.9h-3.9v2.4h3.7v1.9h-3.7v2.8h3.9V373.8z"
            />
            <path
              class="st1"
              d="M303.2,373.8h-2.3V363h6.2v1.9h-3.9v2.8h3.7v1.9h-3.7V373.8z"
            />
            <path class="st1" d="M309.2,373.8V363h2.3v10.8H309.2z" />
            <path
              class="st1"
              d="M323.8,373.8h-2.9l-4.7-8.2h-0.1c0.1,1.4,0.1,2.5,0.1,3.1v5.1h-2V363h2.9l4.7,8.1h0c-0.1-1.4-0.1-2.4-0.1-3
      V363h2.1V373.8z"
            />
          </g>
          <g>
            <path
              class="st1"
              d="M191.1,233.1h4.3v5.6c-0.7,0.2-1.3,0.4-2,0.5c-0.6,0.1-1.2,0.1-1.9,0.1c-1.6,0-2.9-0.5-3.7-1.4
      c-0.9-1-1.3-2.3-1.3-4.1c0-1.7,0.5-3.1,1.5-4.1c1-1,2.4-1.5,4.1-1.5c1.1,0,2.2,0.2,3.2,0.7l-0.8,1.8c-0.8-0.4-1.6-0.6-2.5-0.6
      c-1,0-1.8,0.3-2.4,1c-0.6,0.7-0.9,1.6-0.9,2.7c0,1.2,0.2,2.1,0.7,2.7c0.5,0.6,1.2,0.9,2.1,0.9c0.5,0,1,0,1.5-0.1V235h-2V233.1z"
            />
            <path
              class="st1"
              d="M204.1,239.1h-6.2v-10.8h6.2v1.9h-3.9v2.4h3.7v1.9h-3.7v2.8h3.9V239.1z"
            />
            <path
              class="st1"
              d="M211,230.1c-0.9,0-1.5,0.3-2,1c-0.5,0.6-0.7,1.5-0.7,2.7c0,2.4,0.9,3.6,2.7,3.6c0.8,0,1.7-0.2,2.8-0.6v1.9
      c-0.9,0.4-1.9,0.6-3,0.6c-1.6,0-2.8-0.5-3.6-1.4c-0.8-1-1.2-2.3-1.2-4.1c0-1.1,0.2-2.1,0.6-2.9c0.4-0.8,1-1.5,1.8-1.9
      c0.8-0.5,1.7-0.7,2.7-0.7c1,0,2.1,0.3,3.2,0.8l-0.7,1.9c-0.4-0.2-0.8-0.4-1.2-0.5C211.8,230.2,211.4,230.1,211,230.1z"
            />
            <path
              class="st1"
              d="M225.8,233.7c0,1.8-0.4,3.2-1.3,4.1c-0.9,1-2.2,1.4-3.8,1.4s-2.9-0.5-3.8-1.4c-0.9-1-1.3-2.3-1.3-4.1
      s0.4-3.2,1.3-4.1c0.9-0.9,2.2-1.4,3.8-1.4c1.7,0,2.9,0.5,3.8,1.4C225.4,230.6,225.8,231.9,225.8,233.7z M217.9,233.7
      c0,1.2,0.2,2.1,0.7,2.7c0.5,0.6,1.1,0.9,2.1,0.9c1.8,0,2.7-1.2,2.7-3.6c0-2.4-0.9-3.6-2.7-3.6c-0.9,0-1.6,0.3-2.1,0.9
      C218.2,231.6,217.9,232.5,217.9,233.7z"
            />
            <path
              class="st1"
              d="M232.6,239.1l-2.6-8.5H230c0.1,1.7,0.1,2.9,0.1,3.4v5h-2v-10.8h3.1l2.6,8.3h0l2.7-8.3h3.1v10.8h-2.1V234
      c0-0.2,0-0.5,0-0.8c0-0.3,0-1.2,0.1-2.5h-0.1l-2.8,8.5H232.6z"
            />
          </g>
          <g>
            <path
              d="M389.5,337.7l-2.4-1.2l-2.7,2.9l1.3,2.3l-1.7,1.8l-5.3-10.2l1.9-2l10.5,4.6L389.5,337.7z M385.3,335.6
      c-2.2-1.1-3.4-1.7-3.7-1.8c-0.3-0.1-0.5-0.3-0.7-0.3c0.3,0.5,1.2,2,2.4,4.2L385.3,335.6z"
            />
            <path
              d="M394,327.4c0.8,0.6,1.2,1.3,1.2,2.2c0,0.9-0.4,1.8-1.1,2.8c-0.7,0.9-1.5,1.6-2.4,2l-1.7-1.3c0.7-0.4,1.3-0.7,1.7-1
      c0.4-0.3,0.8-0.6,1-1c0.3-0.4,0.5-0.8,0.5-1.1c0-0.3-0.1-0.6-0.4-0.8c-0.2-0.1-0.3-0.2-0.6-0.2c-0.2,0-0.4,0-0.7,0.1
      c-0.3,0.1-0.7,0.3-1.4,0.6c-0.7,0.3-1.2,0.5-1.6,0.6c-0.4,0.1-0.9,0.1-1.3,0c-0.4-0.1-0.8-0.3-1.2-0.6c-0.8-0.6-1.1-1.3-1.2-2.2
      c0-0.8,0.3-1.7,1-2.6c0.3-0.4,0.7-0.8,1.1-1.1c0.4-0.3,0.9-0.6,1.4-0.9l0.9,1.7c-0.5,0.3-1,0.6-1.3,0.8c-0.3,0.2-0.6,0.5-0.8,0.8
      c-0.3,0.3-0.4,0.7-0.4,1c0,0.3,0.1,0.6,0.4,0.8c0.2,0.1,0.3,0.2,0.5,0.2c0.2,0,0.4,0,0.6-0.1c0.2-0.1,0.7-0.3,1.5-0.7
      c1-0.5,1.8-0.7,2.4-0.7C392.8,326.7,393.4,327,394,327.4z"
            />
            <path
              d="M392.8,318.3c-0.5,0.7-0.6,1.5-0.3,2.2c0.3,0.7,0.9,1.4,1.9,2.1c2,1.3,3.5,1.2,4.5-0.3c0.4-0.6,0.7-1.5,1-2.6l1.6,1
      c-0.2,0.9-0.5,1.9-1.1,2.8c-0.8,1.3-1.9,2.1-3.1,2.3c-1.3,0.2-2.6-0.2-4.1-1.2c-0.9-0.6-1.7-1.3-2.1-2.1c-0.5-0.8-0.7-1.6-0.7-2.5
      c0-0.9,0.3-1.8,0.9-2.6c0.6-0.9,1.4-1.6,2.3-2.2l1.2,1.6c-0.4,0.2-0.7,0.5-1.1,0.8C393.3,317.7,393,318,392.8,318.3z"
            />
            <path
              d="M402.8,306.9c1.6,0.8,2.7,1.7,3.2,3c0.5,1.2,0.4,2.6-0.3,4.1c-0.7,1.5-1.7,2.4-2.9,2.8c-1.2,0.4-2.7,0.2-4.3-0.6
      c-1.6-0.8-2.7-1.8-3.1-3c-0.5-1.2-0.4-2.6,0.3-4.1c0.7-1.5,1.7-2.4,2.9-2.8C399.8,305.9,401.2,306.1,402.8,306.9z M399.5,314
      c1.1,0.5,2,0.7,2.8,0.5c0.7-0.2,1.3-0.6,1.7-1.5c0.8-1.6,0.1-3-2.1-4c-2.2-1-3.7-0.7-4.5,0.9c-0.4,0.8-0.4,1.6,0,2.3
      C397.7,312.9,398.4,313.5,399.5,314z"
            />
            <path
              d="M410.2,301.4l-8.8,0.3l0,0.1c1.7,0.4,2.8,0.6,3.4,0.7l4.8,1.3l-0.5,2l-10.4-2.8l0.8-3l8.6-0.4l0,0l-7.3-4.7l0.8-3l10.4,2.8
      l-0.5,2.1l-4.9-1.3c-0.2-0.1-0.5-0.1-0.8-0.2c-0.3-0.1-1.1-0.3-2.4-0.7l0,0.1l7.5,4.9L410.2,301.4z"
            />
          </g>
        </svg>
      </div>
    </v-row>
  </div>
</template>

<script>
import biblioteca from "../../views/BibliotecaView.vue";
export default {
  props: {},
  data() {
    return {
      dialog: false,
    };
  },
  methods: {
    redirecionar(pagina) {
      let vm = this;
      if (pagina == "GPC") {
        vm.$router.push({
          name: "home",
          // path: "home"
        });
      }
    },
    async abrirLinkMandala(idMandala) {
      this.$router.push({
        path: "/biblioteca",
        name: "Biblioteca",
        component: biblioteca,
        params: { id: idMandala },
      });
    },
  },
  watch: {},
};
</script>
<style scoped>
#mapasite {
  background-color: #32acb9;
  font-size: 14px;
}

#mapasite a {
  color: white;
}

#mapasite a:hover {
  background: none !important;
  color: #fdc600;
  text-decoration: none;
}

ul {
  list-style: none;
  display: block;
  padding: 0px;
}

.icon-final {
  padding: 5px;
}

.sublink {
  font-size: 12px;
  padding-left: 15%;
}

#conteudo {
  margin-top: 50px;
}

#framework {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-bottom: 60px;
}

#framework img {
  margin-bottom: 50px;
}

#img-tooltip {
  position: absolute;
}

#img-tooltip + .tooltip {
  pointer-events: none;
}

#introducao {
  font-family: "Fira Sans", sans-serif !important;
  font-size: 18px;
  line-height: 18px;
  float: right;

  color: #737172;
}

#introducao p {
  margin-top: 50px;
  line-height: 18px;
  line-height: 22px;
}

#modalvideofrw {
  display: none;
}

.portlet-journal-content .journal-content-article img {
  max-width: 100%;
}

.modal-dialog {
  max-width: 595px;
  height: 315px;
}

iframe {
  width: 100%;
  height: 315px;
}

@media screen and (max-width: 724px) {
  iframe {
    height: 195px;
  }

  svg {
    margin-top: 30px;
  }
}

@media screen and (max-width: 540px) {
  svg {
    height: 570px;
  }
}

.modal-container {
  width: 790px;
  height: 410px;
  margin: 0px auto;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  font-family: Helvetica, Arial, sans-serif;
}

.modal-mask {
  position: fixed;
  z-index: 9998 !important;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

img:active {
  border: none;
}

img.map,
map area {
  outline: none;
}

.modal.fade .modal-dialog {
  -webkit-transition: -webkit-transform 0.3s ease-out;
  -moz-transition: -moz-transform 0.3s ease-out;
  -o-transition: -o-transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
}

.st0 {
  fill: #ffffff;
}

.st1 {
  fill: #211915;
}

.st2 {
  fill-rule: evenodd;
  clip-rule: evenodd;
  fill: #073d4e;
}

.st3 {
  fill: #9a999e;
}

.st4 {
  fill: #c64b21;
}

.st5 {
  fill-rule: evenodd;
  clip-rule: evenodd;
  fill: #9cb5af;
}

.st6 {
  fill-rule: evenodd;
  clip-rule: evenodd;
  fill: #b6d5ce;
}

.st7 {
  fill-rule: evenodd;
  clip-rule: evenodd;
  fill: #aac6bf;
}

.st8 {
  fill-rule: evenodd;
  clip-rule: evenodd;
  fill: #e6f1ed;
}

.st9 {
  fill-rule: evenodd;
  clip-rule: evenodd;
  fill: #cce2dc;
}

.st10 {
  fill-rule: evenodd;
  clip-rule: evenodd;
  fill: #dbeae6;
}

.st11 {
  fill: none;
  stroke: #ffffff;
  stroke-width: 1.92;
  stroke-miterlimit: 22.93;
}

.st12 {
  fill: #4c8391;
}

.st13 {
  fill: #e9bc00;
}

.st14 {
  fill: #073d4e;
}

.st15 {
  fill: #5b5b5f;
}

.st16 {
  font-family: "OpenSans-Bold";
}

.st17 {
  font-size: 1.1em;
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  letter-spacing: -0.03em;
}
</style>
