<template>
  <v-card class="card-biblioteca" elevation="0">
    <v-chip v-if="arquivo.rascunho && admin" color="#f8ba00" class="rascunho"
      >Rascunho</v-chip
    >
    <v-card-text>
      <v-row class="linha-card">
        <div :class="tipoArquivo(arquivo.tipoAnexo)">
          {{ arquivo.tipoAnexo }}
        </div>
        <v-col cols="12" sm="2" class="icone">
          <v-img
            src="@/assets/images/icone/icone-tipo-pdf.png"
            max-width="57px"
            class="ma-auto"
          ></v-img>
        </v-col>

        <v-divider
          inset
          vertical
          color="#278b97"
          justify="center"
          class="mb-2"
          :class="$vuetify.breakpoint.xs ? 'd-none' : ''"
        ></v-divider>

        <v-col cols="12" sm="7">
          <div class="ml-4">
            <h3 class="titulo-arquivo">{{ arquivo.titulo }}</h3>
            <p class="mensagem-arquivo">{{ arquivo.descricao }}</p>
            <v-chip
              v-for="(tags, index) in arquivo.tags"
              x-small
              color="#278B97"
              text-color="white"
              class="botao-categoria"
              :key="index"
            >
              {{ tags.nome }}
            </v-chip>
          </div>
        </v-col>
        <v-col cols="12" sm="3" class="coluna-download">
          <div class="icones-download">
            <v-icon v-if="isPdf(arquivo.tipoAnexo)"
              @click="visualizarArquivo(arquivo.id)"
              color="#278b97ad"
              class="mr-5"
              >fas fa-eye</v-icon
            >
            <v-icon @click="baixarAnexo(arquivo.id)" color="#278b97ad"
              >fas fa-download</v-icon
            >
          </div>
          <p class="data">{{ formatarData(arquivo.dataInclusao) }}</p>
          <div v-if="!pesquisa && admin" class="mt-2 mr-3">
            <v-icon
              @click="$emit('capturarIdEdicao', arquivo)"
              small
              color="#14AB51"
              class="mr-4"
            >
              fas fa-pen
            </v-icon>
            <v-icon @click="$emit('excluir', arquivo.id)" small color="#D91727"
              >fas fa-trash</v-icon
            >
          </div>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import Utils from "@/utils/Utils.js";
import api from "../services/Api.js";
export default {
  props: {
    arquivo: [],
    pesquisa: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dadosArquivo: [],
      admin: false,
    };
  },
  methods: {
    tipoArquivo(tipoArquivo) {
      if (tipoArquivo == "PDF") {
        return "formato-biblioteca alert-pdf ";
      } else if (tipoArquivo == "DOC" || tipoArquivo == "DOCX") {
        return "formato-biblioteca primary";
      } else if (tipoArquivo == "XLS") {
        return "formato-biblioteca green";
      } else if (tipoArquivo == "PSD") {
        return "formato-biblioteca blue";
      } else if (tipoArquivo == "MP3") {
        return "formato-biblioteca purple";
      } else if (tipoArquivo == "MP4") {
        return "formato-biblioteca alert";
      } else if (tipoArquivo == "PPT" || tipoArquivo == "PPTX") {
        return "formato-biblioteca ppt";
      } else if (tipoArquivo == "MOV") {
        return "formato-biblioteca red";
      } else if (tipoArquivo == "JPG" || tipoArquivo == "PNG") {
        return "formato-biblioteca jpg";
      } else if (tipoArquivo == "ZIP") {
        return "formato-biblioteca alert-zip";
      } else {
        return "formato-biblioteca alert";
      }
    }, 
    isPdf(tipoArquivo){
      return tipoArquivo === "PDF";
    },
    visualizarArquivo(id) {
      window.open(process.env.VUE_APP_URL + "anexo/visualizarPDF/" + id);
    },
    baixarAnexo(id) {
      const vm = this;
      api
        .get("anexo/baixar/" + id)
        .then(function (response) {
          const anexo = response.data;
          const ArrayBugger = vm.base64ToArrayBuffer(anexo.conteudo);
          var blob = new Blob([ArrayBugger]);
          let url = window.URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", anexo.nome + anexo.extensao);
          document.body.appendChild(link);
          link.click();
        })
        .catch((e) => {
          this.$emit("capturarMensagemErro", e);
        });
    },
    isAdmin() {
      this.admin = Utils.isAdmin();
    },
    base64ToArrayBuffer(base64) {
      var binary_string = window.atob(base64);
      var len = binary_string.length;
      var bytes = new Uint8Array(len);
      for (var i = 0; i < len; i++) {
        bytes[i] = binary_string.charCodeAt(i);
      }
      return bytes.buffer;
    },
    formatarData(data) {
      if (data) {
        const dataObj = new Date(data);
        const dia = dataObj.getDate();
        const mes = [
          "Janeiro",
          "Fevereiro",
          "Março",
          "Abril",
          "Maio",
          "Junho",
          "Julho",
          "Agosto",
          "Setembro",
          "Outubro",
          "Novembro",
          "Dezembro",
        ][dataObj.getMonth()];
        const ano = dataObj.getFullYear();
        return `${dia} de ${mes} de ${ano}`;
      }
    },
  },
  created() {
    this.isAdmin();
  },
};
</script>

<style scoped>
.jpg {
  background-color: #d6860e;
}

.ppt {
  background-color: #df5d46;
}

.alert-pdf {
  background-color: #c2280d;
}

.alert-zip {
  background-color: #e77a67;
}

.alert {
  background-color: #ff2600;
}

.card-biblioteca {
  background-color: #eff9fa;
  border: 1px solid #278b97;
  border-radius: 20px;
  margin-bottom: 16px;
  width: 100%;
}

.icone {
  /* border-right: 1px solid rgb(39 139 151 / 51%); */
  display: flex;
  justify-content: center;
  align-items: center;
}

.linha-card {
  align-items: center;
  margin-left: 0;
}

.linha-divider {
  color: #278b97;
}

p {
  font-size: 15px;
  margin-bottom: 0;
}

.formato-biblioteca {
  text-transform: uppercase;
  position: absolute;
  left: -30px;
  font: normal normal 800 18px/22px Montserrat;
  color: #ffffff;
  width: 4rem;
  height: 2rem;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.coluna-download {
  padding: 0;
  height: 100%;
  text-align: right;
}

.categoria {
  color: #ffffff;
  width: auto;
  text-align: right;
}

.botao-categoria {
  margin: 2px;
}

.titulo-arquivo {
  color: #32acb9;
}

.mensagem-arquivo {
  color: #616161;
}

.v-chip {
  top: 10px;
  height: 25px;
  width: auto;
  margin-right: 5px;
}

.data {
  margin: 1rem 0.5rem 0 0;
  color: #2b2b2b;
}

.rascunho {
  position: absolute;
  top: -12px;
  left: 0;
  color: #fff;
  border-radius: 10px;
}

.icones-download {
  margin: 0 0.75rem 0 0;
}

.icones-download .v-icon {
  font-size: 2rem;
}

@media screen and (max-width: 600px) {
  .formato-biblioteca {
    left: calc(50% - 30px);
    top: -15px;
  }

  .data {
    margin-top: 0;
    text-align: start;
  }

  .coluna-download {
    display: flex;
    justify-content: space-between;
  }
}

@media (min-width: 601px) and (max-width: 960px) {
  .formato-biblioteca {
    left: calc(50% - 30px);
    top: -15px;
  }
}

@media (min-width: 1265px) {
  .card-biblioteca {
    margin-left: 30px;
    margin-right: 30px;
  }

  .formato-biblioteca {
    left: -36px;
  }
}
</style>
