<template>
  <v-card
    @click="clicavel && abrirModalNovidade()"
    max-height="344"
    class="card-noticias"
  >
    <v-chip v-if="rascunho && admin" class="rascunho">Rascunho</v-chip>
    <v-card-text>
      <v-container>
        <v-row>
          <v-col cols="12" md="2" class="icone">
            <v-img :src="getSrc(icone)" max-width="60px"></v-img>
          </v-col>

          <v-divider
            inset
            vertical
            class="divider"
            :class="$vuetify.breakpoint.sm ? 'd-none' : ''"
          ></v-divider>

          <v-col cols="12" md="9">
            <div>
              <h4>{{ titulo }}</h4>
              <p>{{ descricao }}</p>
              <v-chip
                x-small
                color="#278B97"
                text-color="white"
                class="botao-categoria"
                v-for="(tag, index) in tags"
                :key="index"
              >
                {{ tag.nome }}
              </v-chip>
            </div>
            <div v-if="!pesquisa && admin" class="botoes-admin">
              <v-icon @click.stop="abrirModalEditar()" color="#14AB51"
                >fas fa-pen</v-icon
              >
              <v-icon @click.stop="excluirNovidade()" color="#D91727"
                >fas fa-trash</v-icon
              >
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
import Utils from "@/utils/Utils";

export default {
  props: {
    icone: String,
    titulo: String,
    artigo: Boolean,
    descricao: String,
    categoria: String,
    idArtigo: Number,
    tags: Array,
    pesquisa: Boolean,
    id: Number,
    rascunho: Boolean,
    keyword: String,
    academiaHepta: Boolean,
    clicavel: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      admin: false,
      src: "",
    };
  },
  methods: {
    abrirModalEditar() {
      this.$emit("abrirModalEditar");
    },
    getSrc(path) {
      if (path && (path.includes("/image/") || path.includes("/img/"))) {
        const nomeImagem = path.split("/icone/")[1];
        if (nomeImagem) {
          return require("../assets/images/icone/" + nomeImagem);
        }
      }
      return require("../assets/images/icone/icone-tipo-pdf.png");
    },
    excluirNovidade() {
      this.$emit("excluirNovidade");
    },
    isAdmin() {
      if (this.academiaHepta) {
        this.admin = Utils.isGpc() || Utils.isAdmin();
      } else {
        this.admin = Utils.isAdmin();
      }
    },
    async abrirModalNovidade() {
      if (this.academiaHepta) {
        await this.$router.push({
          name: "Detalhe Academia",
          query: {
            id: this.id,
            pesquisa: this.pesquisa,
            keyword: this.keyword,
            artigo: this.artigo,
          },
        });
        window.location.reload();
      } else {
        await this.$router.push({
          name: "Detalhe Noticia",
          query: { id: this.id },
          params: { pesquisa: this.pesquisa, keyword: this.keyword },
        });
        window.location.reload();
      }
    },
  },
  created() {
    this.isAdmin();
  },
  computed: {
    imagem() {
      return {
        icone: this.icone && this.getSrc(this.icone),
      };
    },
  },
};
</script>

<style scoped>
.rascunho {
  position: absolute;
  top: calc(0% - 1rem);
  left: calc(0% + 2rem);
  background-color: #f8ba00 !important;
  color: #fff;
  border-radius: 1rem;
  padding: 0 0.5rem;
  font-size: 0.75rem;
}

.icone {
  display: flex;
  justify-content: center;
  align-items: center;
}

.card-noticias {
  background-color: #ffffff;
  border-radius: 32px 32px 0 0;
  margin-bottom: 1rem;
  border: 1px solid #278b97;
  display: flex;
  height: 100%;
  align-items: center;
  width: 100%;
  position: relative;
  box-shadow: none !important;
}

.card-noticias:focus {
  overflow: hidden;
}

.card-noticias h4 {
  color: #32acb9;
}

.divider {
  justify-content: center;
  border-color: #5bb0ba;
  margin: 0 0.5rem;
}

.botoes-admin {
  height: 1rem;
  text-align: right;
}

.botoes-admin .v-icon {
  font-size: 1.25rem;
  margin: 1rem 0.5rem;
}
</style>
