<template>
  <div>
    <loader :loader-primario="loader"></loader>

    <alerta
      v-on:fecharModal="fecharModal"
      :exibir="modal.exibir"
      :titulo="modal.titulo"
      :mensagem="modal.mensagem"
      @exclusao="removerDependente()"
      @confirmar="solicitarMudanca()"
    >
    </alerta>

    <modalAnexarDocumentos
      v-model="mostrarModalDocumentos"
      :pedido="pedido"
      @fechar="abrirModalAnexos()"
    >
    </modalAnexarDocumentos>

    <cardSolicitacaoValesVue
      :exibir="mostrarCardVales"
      :titulo="tituloCardVales"
      @fechar="fecharCardVales()"
      @trocarAlimentacao="alterarVale()"
      @cancelar="(jus) => criarPedidoCancelamento(jus)"
      @trocarTransporte="trocarTransporte()"
    ></cardSolicitacaoValesVue>

    <formCancelarPlano
      :exibir="mostrarFormCancelarPlano"
      :pedido="pedidoCancelamento"
      :tipo="tipoPlano"
      @fechar="fecharFormPlano()"
    ></formCancelarPlano>

    <v-container>
      <div class="card">
        <div class="inicio-titulo">
          <v-img
            class="icone-titulo"
            src="../../assets/images/icone/espaco-colaborador/icone_beneficios.svg"
          ></v-img>
          <h1 class="titulo">Benefícios</h1>
        </div>

        <v-tabs v-model="tab" grow hide-slider show-arrows>
          <v-tabs-slider></v-tabs-slider>
          <v-tab
            href="#tab-1"
            :class="tab == 'tab-1' ? 'tab tab-active' : 'tab'"
            >Plano de Saúde</v-tab
          >
          <v-tab
            href="#tab-2"
            :class="tab == 'tab-2' ? 'tab tab-active' : 'tab'"
            >Plano Dental</v-tab
          >
          <v-tab
            href="#tab-3"
            :class="tab == 'tab-3' ? 'tab tab-active' : 'tab'"
            >Vale Alimentação / Refeição</v-tab
          >
          <v-tab
            href="#tab-4"
            :class="tab == 'tab-4' ? 'tab tab-active' : 'tab'"
            >Vale Transporte</v-tab
          >
        </v-tabs>
        <!-- Conteúdo Tabs -->
        <v-tabs-items v-model="tab" touchless>
          <v-tab-item value="tab-1">
            <TabPlanoSaude
              :headerTitular="headerTitular"
              :headerDependente="headerDependentesAtivos"
              :dependente="dependentes.saude"
              :beneficio="beneficios.saude"
            />
          </v-tab-item>

          <v-tab-item value="tab-2">
            <TabPlanoDental
              :headerTitular="headerTitular"
              :headerDependente="headerDependentesAtivos"
              :dependente="dependentes.dental"
              :beneficio="beneficios.dental"
            />
          </v-tab-item>

          <v-tab-item value="tab-3">
            <TabAlimentacaoRefeicao
              :header="headersAlimentacao"
              :beneficio="beneficios.alimentacao"
            />
          </v-tab-item>

          <v-tab-item value="tab-4">
            <TabTransporte
              :header="headersVale"
              :beneficio="beneficios.transporte"
            />
          </v-tab-item>
        </v-tabs-items>
      </div>
    </v-container>
  </div>
</template>

<script>
import loader from "@/components/Loader.vue";
import alerta from "@/components/Alerta.vue";
import api from "@/services/Api.js";
import modalMixin from "@/mixins/modalMixin";
import { colocarMascaraCPF } from "@/utils/CPFUtils.js";
import { TipoBeneficioEnum } from "@/enums/beneficio/TipoBeneficioEnum";
import modalAnexarDocumentos from "@/components/EspacoDoColaborador/Beneficios/modais/ModalAnexarDocumentos.vue";
import cardSolicitacaoValesVue from "../../components/EspacoDoColaborador/Beneficios/modais/ModalTrocarVale.vue";
import formCancelarPlano from "@/components/EspacoDoColaborador/Beneficios/modais/ModalCancelarBeneficio.vue";
import TabPlanoSaude from "@/components/EspacoDoColaborador/Beneficios/tabs/TabPlanoSaude.vue";
import TabPlanoDental from "@/components/EspacoDoColaborador/Beneficios/tabs/TabPlanoDental.vue";
import TabAlimentacaoRefeicao from "@/components/EspacoDoColaborador/Beneficios/tabs/TabAlimentacaoRefeicao.vue";
import TabTransporte from "@/components/EspacoDoColaborador/Beneficios/tabs/TabTransporte.vue";

export default {
  components: {
    loader,
    alerta,
    modalAnexarDocumentos,
    cardSolicitacaoValesVue,
    formCancelarPlano,
    TabPlanoSaude,
    TabPlanoDental,
    TabAlimentacaoRefeicao,
    TabTransporte,
  },
  mixins: [modalMixin],
  name: "beneficios",
  data() {
    return {
      loader: false,
      baseUrl: "",
      tab: null,
      listaFiltrada: [],
      headerTitular: [
        {
          text: "Nome",
          value: "nomePessoa",
          sortable: false,
          formatter: (x) => (x ? x.toUpperCase() : "-"),
        },
        {
          text: "CPF",
          value: "cpf",
          sortable: false,
          formatter: (x) => (x ? colocarMascaraCPF(x) : "-"),
        },
        {
          text: "Benefícios",
          value: "descricao",
          sortable: false,
          formatter: (x) => (x ? x.toUpperCase() : "-"),
        },
        {
          text: "Requerimento",
          value: "requerimento",
          align: "center",
          sortable: false,
        },
      ],
      headerDependentesAtivos: [
        {
          text: "Nome",
          value: "nome",
          formatter: (x) => (x ? x.toUpperCase() : "-"),
        },
        {
          text: "CPF",
          value: "cpf",
          formatter: (x) => (x ? colocarMascaraCPF(x) : "-"),
        },
        {
          text: "Benefícios",
          value: "beneficios",
          sortable: false,
          formatter: (x) => (x ? x.toUpperCase() : "-"),
        },
        {
          text: "Requerimento",
          value: "requerimento",
          align: "center",
          sortable: false,
        },
      ],
      headersVale: [
        {
          text: "Nome",
          value: "nome",
          formatter: (x) => (x ? x.toUpperCase() : "-"),
        },
        {
          text: "Tipo",
          value: "tipo.nome",
          formatter: (x) => (x ? x.toUpperCase() : "-"),
        },
        {
          text: "QTD. Diária",
          value: "qtdDiaria",
          align: "center",
        },
        {
          text: "Obs",
          value: "obs",
          formatter: (x) => (x ? x.toUpperCase() : "-"),
        },
        {
          text: "Requerimento",
          value: "requerimento",
          align: "center",
          sortable: false,
        },
      ],
      headersAlimentacao: [
        {
          text: "Nome",
          value: "nome",
          formatter: (x) => (x ? x.toUpperCase() : "-"),
        },
        {
          text: "Tipo",
          value: "tipo.nome",
          formatter: (x) => (x ? x.toUpperCase() : "-"),
        },
        {
          text: "QTD. Diária",
          value: "qtdDiaria",
          align: "center",
        },
        {
          text: "Obs",
          value: "obs",
          formatter: (x) => (x ? x.toUpperCase() : "-"),
        },
      ],
      periodoAlimentacao: null,
      periodoTransporte: null,
      dependenteExclusao: {},
      tituloCardVales: "",
      mostrarFormCancelarPlano: false,
      mostrarCardVales: false,
      apoliceSaude: null,
      apoliceDental: null,
      pedido: null,
      pessoa: null,
      modalTitle: "",
      beneficios: {
        alimentacao: [],
        transporte: [],
        dental: [],
        saude: [],
      },
      dependentes: {
        dental: [],
        saude: [],
        geral: [],
        selected: [],
        novoDep: [],
      },
      modalMinhasSolicitacoes: false,
      modalAdicionarDependentes: false,
      pedidoCancelamento: {},
      mostrarModalDocumentos: false,
      adicionarDependente: false,
      expanded: [],
      showAdicionar: false,
      trocaVale: {},
      dependenteSelecionados: [],
      singleExpand: true,
      mostrarFormTransporte: false,
      tipo: 0,
      pedidoValeTransporte: null,
      tipoPlano: "",
      justificativa: "",
      options: {
        sexo: [
          { value: 1, text: "Feminino" },
          { value: 2, text: "Masculino" },
          { value: 0, text: "Prefiro não dizer" },
        ],
        grauParentesco: [
          {
            value: 1,
            text: "Conjuge",
          },
          { value: 2, text: "Filho até 21 anos" },
          {
            value: 3,
            text: "Filho universitário (maior de 21 anos até 24 anos)",
          },
          { value: 4, text: "Filho com incapacidade" },
          {
            value: 5,
            text: "Irmão(a), neto(a) ou bisneto(a) até 21 anos com guarda",
          },
          {
            value: 6,
            text: "Irmão(a), neto(a) ou bisneto(a) até 24 anos universitário com guarda",
          },
          {
            value: 7,
            text: "Irmão(a), neto(a) ou bisneto(a) incapacitado com guarda",
          },
          { value: 8, text: "Pais, avós e bisavós" },
          { value: 9, text: "Menor pobre até 21 anos com guarda" },
          { value: 10, text: "Incapaz da qual seja tutor ou curador" },
        ],
      },
    };
  },
  watch: {},
  methods: {
    colocarMascaraCPF,
    showButtonAdicionarAoPedido(Dependente) {
      const vm = this;
      return !vm.dependentes.selected.includes(Dependente);
    },
    cancelarAdicaoDependente() {
      const vm = this;
      vm.adicionarDependente = false;
      vm.dependente = {
        id: undefined,
        cpf: "",
        nome: "",
        nomeMae: "",
        grauParentesco: {},
        dtNascimento: null,
        sexo: null,
      };
    },
    alterarValeTransporte(item) {
      const vm = this;
      vm.pedidoValeTransporte = item.item;
    },
    adicionarAoPedido(dep) {
      const vm = this;
      vm.dependentes.selected.push(dep);
    },
    trocarTransporte() {
      const vm = this;
      vm.fecharCardVales();
      vm.mostrarFormTransporte = true;
    },
    fechartrocarTransporte() {
      const vm = this;
      vm.mostrarFormTransporte = false;
    },
    removerDoPedido(id) {
      const vm = this;
      vm.dependentes.selected = vm.dependentes.selected.filter(
        (d) => d.id !== id
      );
      vm.dependentes.novoDep = vm.dependentes.novoDep.filter(
        (d) => d.id !== id
      );
    },
    modalSolicitacao() {
      const vm = this;
      vm.confirmarSolicitacao(`Deseja confirmar a solicitação?`);
    },
    modalConfirmacaoExclusao(dep) {
      const vm = this;
      vm.dependenteExclusao = dep;
      vm.confirmarExclusao(
        `Deseja confirmar a exclusão do dependente ${dep.nome}?`,
        dep.id
      );
    },
    fecharCardVales() {
      this.mostrarCardVales = false;
    },
    removerDependente() {
      const vm = this;

      vm.loader = true;
      vm.fecharModal();
      api
        .delete(`beneficios/dependente/${vm.dependenteExclusao.id}`)
        .then((res) => {
          vm.dependentes.geral = vm.dependentes.geral.filter(
            (d) => d.id !== vm.dependenteExclusao.id
          );
          // vm.dependentes.selected = vm.dependentes.selected.filter(
          //   (d) => d.id !== dep.id
          // );
          // vm.dependentes.novoDep = vm.dependentes.novoDep.filter(
          //   (d) => d.id !== dep.id
          // );
          vm.mensagemSucesso("Dependente removido com sucesso");
        })
        .catch((error) => {
          if (error.response.status === 409)
            vm.mensagem(
              "Não foi possivel remover dependente.",
              " Dependente ligada a um pedido não é possivel remover."
            );
          else
            vm.mensagem(
              "Não foi possivel remover dependente.",
              " Erro interno do servidor."
            );
        })
        .finally(() => {
          vm.loader = false;
        });
    },
    cancelarPlano(itemId, tipoMudanca, idDep) {
      const vm = this;
      let pedido = {
        beneficio: {
          id: itemId,
        },
        dependentes: [],
        justificativa: "",
        status: 0,
        tipoMudanca: {
          id: tipoMudanca,
        },
      };
      if (itemId == vm.beneficios.saude[0].id) {
        if (idDep) {
          let dep = vm.dependentes.saude.find((d) => d.id == idDep);
          pedido.dependentes.push(dep);
        } else {
          pedido.dependentes = vm.dependentes.saude;
        }
        vm.tipoPlano = "saude";
      } else {
        vm.tipoPlano = "dental";
        if (idDep) {
          let dep = vm.dependentes.dental.find((d) => d.id == idDep);
          pedido.dependentes.push(dep);
        } else {
          pedido.dependentes = vm.dependentes.dental;
        }
      }
      vm.pedidoCancelamento = pedido;
      vm.tipo;
      this.mostrarFormCancelarPlano = !this.mostrarFormCancelarPlano;
    },
    fecharFormPlano() {
      this.mostrarFormCancelarPlano = false;
    },
    modalAnexos(item) {
      const vm = this;
      vm.pedido = item;
      vm.abrirModalAnexos();
    },
    abrirModalAnexos() {
      const vm = this;
      vm.mostrarModalDocumentos = !vm.mostrarModalDocumentos;
    },
    validarCampos() {
      const vm = this;
      if (vm.dependentes.selected.length === 0) {
        vm.mensagem(
          "Selecionar dependente!",
          "Obrigatório selecionar dependente!"
        );
        return false;
      }
      return true;
    },
    solicitarMudanca() {
      if (this.validarCampos() == true) {
        this.criarPedido();
        this.modalAdicionarDependentes = false;
      }
    },
    montarPedido() {
      const vm = this;
      let beneficio;
      if (vm.tipo == 1) {
        beneficio = vm.pessoa.beneficios.find(
          (b) => b.tipo.nome == "Plano de Saúde"
        );
      } else {
        beneficio = vm.pessoa.beneficios.find(
          (b) => b.tipo.nome == "Plano Odontológico"
        );
      }
      let pedido;
      if (vm.tipo == 4) {
        pedido = {
          beneficio: {
            id: vm.trocaVale.id,
          },
          justificativa: vm.justificativa,
          status: 0,
          tipoMudanca: {
            id: 4,
          },
        };
      } else {
        pedido = {
          beneficio: {
            id: beneficio.id,
          },
          beneficioAnterior: beneficio,
          status: 0,
          tipoMudanca: {
            id: 2,
          },
          dependentes: vm.dependentes.selected,
        };
      }

      return pedido;
    },
    tipoDependente(tipo) {
      const vm = this;
      vm.modalAdicionarDependentes = true;
      vm.tipo = tipo;
      if (tipo == 1) {
        vm.modalTitle = "DEPENDENTES DO PLANO DE SAÚDE";
      } else {
        vm.modalTitle = "DEPENDENTES DO PLANO ODONTOLÓGICO";
      }
    },
    criarPedido() {
      const vm = this;
      var link;

      vm.loader = true;
      const pedido = vm.montarPedido();
      link = `beneficios/pedido/plano`;
      // if (
      //   pedido.beneficioAnterior != undefined &&
      //   vm.tipoBeneficio == 1 &&
      //   vm.tipoMudanca == 1
      // )
      //   link = `beneficios/pedido/alteracao/saude`;
      // if (
      //   pedido.beneficioAnterior != undefined &&
      //   vm.tipoBeneficio == 2 &&
      //   vm.tipoMudanca == 1
      // )
      //   link = `beneficios/pedido/alteracao/dental`;
      api
        .post(link, pedido)
        .then((res) => {
          vm.dependentes.selected = [];
          vm.mensagemSucesso(
            "Pedido criado. Necessário anexar os documentos para prosseguir com o atendimento"
          );
          vm.modalAnexos(res.data);
        })
        .catch((error) => {
          vm.mensagemErro(error.response.data);
        })
        .finally(() => {
          vm.loader = false;
        });
    },
    alterarVale() {
      const vm = this;
      var tipoBeneficio;
      var obj;
      if (vm.trocaVale.tipo.id == 3) {
        tipoBeneficio = 4;
      } else {
        tipoBeneficio = 3;
      }
      vm.fecharCardVales();
      vm.loader = true;
      obj = vm.preencherObj(vm.trocaVale.id);
      api
        .post(`beneficios/pedido/vale?tipoBeneficio=${tipoBeneficio}`, obj)
        .then((response) => {
          if (response.status == 200) {
            vm.mensagemSucesso(
              "Pedido realizado com sucesso. Aguarde o atendimento da solicitação."
            );
          }
        })
        .catch((err) => {
          vm.isLoading = false;
          vm.mensagemErro(`Erro ao fazer pedido.`);
          console.error(err.response.data);
        })
        .finally(() => {
          vm.trocaVale = {};
          vm.loader = false;
        });
    },
    preencherObj(idBeneficioAnterior) {
      const obj = {
        beneficioAnterior: {
          id: idBeneficioAnterior,
        },
        tipoMudanca: {
          id: 1,
        },
      };
      return obj;
    },
    criarPedidoCancelamento(justificativa) {
      const vm = this;
      vm.justificativa = justificativa;
      if (vm.documento == null && vm.trocaVale.tipo.id != 5) {
        vm.mensagemErro("Obrigatório anexar formulário de exclusão.");
        return;
      }
      vm.isLoading = true;
      vm.tipo = 4;
      var pedido = vm.montarPedido(vm.trocaVale);
      let link =
        vm.trocaVale.tipo.id != 5
          ? `${vm.baseUrl}/beneficios/pedido/plano`
          : `${vm.baseUrl}/beneficios/pedido/vale`;
      api
        .post(link, pedido)
        .then(async (response) => {
          if (vm.trocaVale.tipo.id != 5) {
            await vm.uploadDocumento(response.data.id).catch((error) => {
              vm.mensagemSucesso(
                "Error ao salvar documento - " + error.response.data
              );
            });
          }
          vm.mensagemSucesso(
            "Pedido realizado com sucesso. Aguarde o atendimento da solicitação."
          );
        })
        .catch((error) => {
          vm.mensagemErro("Erro ao fazer pedido." + error.response.data);
          vm.isLoading = false;
        })
        .finally(() => {
          vm.isLoading = false;
          vm.fecharCardVales();
        });
    },
    // abrirModal(sistema) {},
    formatarCpf(cpf) {
      cpf = cpf.replace(/\D/g, "");
      cpf = cpf.replace(/(\d{3})(\d)/, "$1.$2");
      cpf = cpf.replace(/(\d{3})(\d)/, "$1.$2");
      cpf = cpf.replace(/(\d{3})(\d{1,2})$/, "$1-$2");
      return cpf;
    },
    async buscarBeneficiosPessoa() {
      const vm = this;
      await api
        .get(`beneficios/pessoa`)
        .then(async (res) => {
          var obj;
          vm.pessoa = res.data;
          await res.data.beneficios.forEach((e) => {
            if (
              e.tipo.id == TipoBeneficioEnum.VALE_ALIMENTACAO ||
              e.tipo.id == TipoBeneficioEnum.VALE_REFEICAO
            )
              vm.beneficios.alimentacao.push(e);
            if (e.tipo.id == TipoBeneficioEnum.VALE_TRANSPORTE)
              vm.beneficios.transporte.push(e);
            if (e.tipo.id == TipoBeneficioEnum.PLANO_DENTAL) {
              obj = {
                id: res.data.id,
                nomePessoa: res.data.nomPessoa,
                cpf: res.data.numCPF,
                ...e,
              };
              vm.beneficios.dental.push(obj);
            }
            if (e.tipo.id == TipoBeneficioEnum.PLANO_DE_SAUDE) {
              obj = {
                id: res.data.id,
                nomePessoa: res.data.nomPessoa,
                cpf: res.data.numCPF,
                ...e,
              };
              vm.beneficios.saude.push(obj);
            }
          });
        })
        .catch((e) => {
          vm.mensagemErro(e.message);
        })
        .finally(function () {
          setTimeout(function () {
            vm.loader = false;
          }, 500);
        });
    },
    abrirCardVales(titulo, item) {
      const vm = this;
      vm.trocaVale = item;
      vm.tituloCardVales = titulo;
      vm.mostrarCardVales = !vm.mostrarCardVales;
    },
    async buscaDadosDependente() {
      const vm = this;
      await api
        .get(`beneficios/dependente`)
        .then(async (res) => {
          await res.data.forEach(async (d) => {
            if (d.indPlanoSaude == true) {
              var beneficioSaude = vm.beneficios.saude.find((b) => {
                return b.tipo.id === TipoBeneficioEnum.PLANO_DE_SAUDE;
              });
              var obj = {
                ...d,
                beneficios: beneficioSaude.descricao,
              };
              await vm.dependentes.saude.push(obj);
            }
            if (d.indPlanoOdonto == true) {
              var beneficioDental = vm.beneficios.dental.find((b) => {
                return b.tipo.id === TipoBeneficioEnum.PLANO_DENTAL;
              });
              var obj = {
                ...d,
                beneficios: beneficioDental.descricao,
              };
              await vm.dependentes.dental.push(obj);
            }
          });
          // console.log(res.data);
          // if (res.data.length > 0) {
          //   vm.dependentes.geral = res.data.filter(
          //     (d) => d.indPlanoOdonto == false && d.indPlanoSaude == false
          //   );
          //   vm.dependentes.dental = res.data.filter(
          //     (d) => d.indPlanoOdonto == true
          //   );
          //   vm.dependentes.saude = res.data.filter(
          //     (d) => d.indPlanoSaude == true
          //   );
          //   console.log("DEPENDENTES", vm.dependentes);
          // }
        })
        .catch(function (e) {
          vm.mensagemErro(e.message);
        })
        .finally(function () {
          setTimeout(function () {
            vm.loader = false;
          }, 500);
        });
    },
    buscarPeriodoTransporte() {
      const vm = this;
      api.get(`beneficios/periodo-transporte`).then((response) => {
        vm.periodoTransporte = response.data;
      });
    },
    buscarAniversarioApoliceSaude() {
      const vm = this;
      api.get(`beneficios/apolice/saude`).then((response) => {
        vm.apoliceSaude = response.data;
      });
    },
    buscarAniversarioApoliceDental() {
      const vm = this;
      api.get(`beneficios/apolice/dental`).then((response) => {
        vm.apoliceDental = response.data;
      });
    },
    formatarData(data) {
      if (!data) return null;
      if (data == "Sem data") {
        return data;
      }
      const [ano, mes, dia] = data.includes("-")
        ? data.split("-")
        : data.split("/");
      return `${dia}/${mes}/${ano}`;
    },
    criarDependente() {
      const vm = this;
      const t = true;
      if (t) {
        vm.isLoading = true;
        const dataString = vm.dependente.dtNascimento;
        const partes = dataString.split("/"); // divide a string em um array ['01', '04', '2003']
        vm.dependente.dtNascimento = `${partes[2]}-${partes[1]}-${partes[0]}`;
        api
          .post(`beneficios/dependente`, vm.dependente)
          .then((res) => {
            vm.mensagemSucesso("Dependente cadastrado com sucesso.").then(
              () => {
                vm.resetForm();
                this.$emit("create", { dependente: res.data });
                this.$emit("hide");
              }
            );
          })
          .catch((error) => {
            console.error(error.response.data);
            vm.mensagemErro("Erro ao criar dependente");
          })
          .finally(() => {
            vm.buscaDadosDependente();
            vm.isLoading = false;
          });
      }
    },
  },
  async mounted() {
    const vm = this;
    await (vm.loader = true);
    await Promise.all([vm.buscarBeneficiosPessoa()])
      .then(() => vm.buscaDadosDependente())
      .finally(() => (vm.loader = false));
    // await vm.buscarPeriodoAlimentacao();
    // await vm.buscarPeriodoTransporte();
    // await vm.buscarAniversarioApoliceSaude();
    // await vm.buscarAniversarioApoliceDental();
    // await vm.buscaDadosDependente();
  },
};
</script>

<style>
/* Tabs */
.card .v-tabs-bar {
  height: auto;
}

.tab {
  background-color: #edefee;
  border: 1px solid #edefee;
  border-radius: 12px;
  text-align: center;
  text-transform: none;
  letter-spacing: 0px;
  font: normal normal bold 16px/20px Montserrat;
  color: #787878 !important;
  margin: 0 1rem;
  padding: 1rem;
  width: 8rem;
}

.tab::before {
  border-radius: 12px;
}

.tab:hover {
  background-color: #eff9fa;
  border: 1px solid #278b97;
  color: #278b97 !important;
}

.tab-active {
  background-color: #278b97;
  border: 1px solid #278b97;
  color: #fff !important;
}

/* Alerta */
.alerta {
  display: flex;
  align-items: center;
  margin: 1rem 0;
  padding: 1rem;
  border-radius: 20px;
  gap: 2rem;
  background-color: #edefee;
}

.alerta-icone {
  display: grid;
  align-items: center;
  justify-content: center;
  margin: 2rem 0;
  border-right: 1px solid #278b97;
}

.alerta-icone .v-image {
  width: fit-content;
}

.alerta-texto {
  display: flex;
  align-items: center;
  padding: 1rem 2rem;
}

.alerta-texto a {
  text-decoration: none;
}

@media screen and (max-width: 960px) {
  .alerta-icone {
    margin-bottom: 0;
    border-right: none;
  }
}

/* -------------------------------------------- */
/* TÍtulos - Tabs */
.titulo-e-divider {
  display: flex;
  align-items: center;
}

.titulo-e-divider h1 {
  margin: 0 1rem 0 0 !important;
}

hr {
  border-color: #278b97 !important;
}

/* -------------------------------------------- */
/* Tabela - Tabs  */
.botoes-tabela {
  justify-content: right;
}

.icone-solicitacoes {
  max-width: 1rem;
  margin-right: 0.5rem;
}

.botao-vermelho {
  color: #fff !important;
  font-weight: 600;
  border-radius: 8px;
  background-color: #db1826 !important;
}

.botao-amarelo {
  color: #fff !important;
  font-weight: 600;
  border-radius: 8px;
  background-color: #e7b814 !important;
}
</style>
