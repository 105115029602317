<template>
  <v-dialog v-model="alterar" width="1800" @click:outside="fechaModal()">

    <v-card>
      <v-card-title>
        <h5> {{ item.titulo }} </h5>
        <v-icon small @click="fechaModal()"> fa-times </v-icon>
      </v-card-title>

      <v-card-text>
        <div class="espacamento">
          <v-container>
            <v-form ref="formContrato" v-model="valid">
              <div class="borda">{{ subtitulo() }}</div>
              <v-col cols="12" class="espacamento-descricao">
                <v-textarea color="#278b97" :rules="rules.justificativa" v-model="consentimento.justificativa"
                  :placeholder="descricao()" outlined dense class="estilo">
                </v-textarea>
              </v-col>
            </v-form>
          </v-container>
        </div>

        <p v-if="this.item.tipo == 'ALTERAR'">Ao prosseguir a solicitação irá para análise da equipe técnica responsável.
        </p>
      </v-card-text>

      <v-col cols="12" class="espacamento-v-col">
        <v-divider insert class="divider-tela"></v-divider>
      </v-col>

      <v-card-actions class="justify-center pb-10">
        <v-btn class="botao-cancelar mr-2 largura-btn" @click="fechaModal()">Sair</v-btn>
        <v-btn class="botao-salvar mr-2 largura-btn" @click="confirmar()"> Confirmar </v-btn>
      </v-card-actions>

    </v-card>

  </v-dialog>
</template>

<script>
export default {
  name: "modal-alterar-consentimento",
  props: {
    item: {},
    value: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    alterar: false,
    consentimento: {
      idConsentimentoColaborador: "",
      justificativa: "",
    },
    rules: {
      justificativa: [
        (v) => v.length >= 15 || 'Mínimo 15 caracteres!',
        (v) => !!v || 'Campo obrigatório',
      ],
    },
  }),
  watch: {
    value(e) {
      this.alterar = e;
    },
  },
  methods: {
    fechaModal: function () {
      this.$emit("fecha-modal");
      this.limparCampos();
    },
    limparCampos() {
      this.consentimento = {
        idConsentimentoColaborador: "",
        justificativa: "",
      }
    },
    confirmar() {
      if (this.$refs.formContrato.validate()) {
        this.consentimento.idConsentimentoColaborador = this.item.idConsentimento;
        if (this.item.tipo == 'REPROVAR') {
          //informando para ProtecaoDeDados.vue -- método update() -- que deve reprovar, passando o item necessário
          this.$emit("update", 'REPROVADO', this.consentimento);
        } else {
          //informando para ProtecaoDeDados.vue -- método update() -- que deve solicitarRevogacao, passando o item necessário
          this.$emit("update", 'SOLICITADO_REVOGACAO', this.consentimento);
        }
        this.limparCampos();
      }
    },
    subtitulo() {
      if (this.item.tipo == 'REPROVAR') {
        return "Deseja realmente solicitar alteração?"
      } else {
        return "Deseja realmente reprovar esse consentimento?"
      }
    },
    descricao() {
      if (this.item.tipo == 'REPROVAR') {
        return "Diga o motivo pelo qual deseja reprovar esse consentimento."
      } else {
        return "Diga o motivo pelo qual deseja solicitar a alteração deste consentimento."
      }
    }
  },
};
</script>
<style scoped>
.divider-tela {
  margin: 30px;
  opacity: 1.0;
  justify-items: center;
}

.borda {
  font-size: 16px;
  border-left: 3px solid;
  border-radius: 3px;
  padding-left: 10px;
  color: #008693;
  text-align: left;
}

.v-dialog>.v-card>.v-card__text {
  background-color: #e9e9e9;
  color: #2d2d2d;
  justify-content: center;
  padding: 20px 0px 0px 0px !important;
}

.espacamento {
  padding: 10px 30px 0px 30px;
  background-color: #e9e9e9;
}

.espacamento-descricao {
  padding-top: 20px;
  padding-left: 0px !important;
}

.espacamento-v-col {
  padding: 5px;
  background-color: #e9e9e9;
}

.v-card>.v-card__title>.v-icon {
  position: absolute;
  right: 24px;
  color: #ffffff;
}
</style>


