<template>
  <div>
    <v-container>
      <div class="breadcrumb" no-gutters>
        <router-link v-for="(breadcrumb, idx) in breadcrumbList" :to="breadcrumb.link" :key="idx" class="arrow">
          <p>{{ breadcrumb.name }}</p>
        </router-link>
      </div>
      <div class="card-indicacao">
        <v-row>
          <v-col cols="12" align-self="center">
            <iframe width="100%" height="1000px"
              src="https://forms.office.com/Pages/ResponsePage.aspx?id=8kveREH2aU6_zT_IQS-tZ1G04t6fLMxHsslOBxbr_uJUMzVMM05aN0NCSlJaOTkxSDRCNUNFSENCTi4u&embed=true"
              frameborder="0" style="border: none; max-width:100%; max-height:100vh;" allowfullscreen
              webkitallowfullscreen mozallowfullscreen msallowfullscreen>
            </iframe>
          </v-col>
        </v-row>
      </div>
    </v-container>
  </div>
</template>
<script>
export default {
  components: {  },
  mixins: [],
  name: "indicacao-vaga",
  data: () => ({
    breadcrumbList: [
      { name: "Home", link: "/" },
      { name: "Vagas Hepta", link: "/vagas" },
      { name: "Detalhes Vaga", link: `/vaga/` },
      { name: "Indicar", link: "" },
    ],
  }),
  methods: {
    configuraPath() {
      const vm = this;
      vm.breadcrumbList[2].link += this.$route.params.id;
    }
  },
  mounted() {
    this.configuraPath();
  }
};
</script>
<style scoped>
.card-indicacao {
  border-radius: 32px !important;
  box-shadow: 1px 0px 8px 7px #80808045 !important;
  padding: 0.5rem 1rem 3rem 3rem !important;
  margin: 2rem 3rem 2rem 3rem !important;
}

/* Breadcrumb */
.breadcrumb {
  display: flex;
  margin-bottom: 50px;
}

.arrow {
  min-width: 125px;
  text-align: center;
  height: 30px;
  background: #edefee;
  position: relative;
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #414141;
  cursor: pointer;
  text-decoration: none;
}

.arrow p {
  font-size: 0.7rem;
}

.arrow::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 90%;
  transform: translateY(-50%) rotate(45deg);
  z-index: 1;
  width: 22px;
  background-color: #edefee;
  height: 22px;
  border-top: 2px solid #ffffff;
  border-right: 2px solid #ffffff;
}

.arrow:last-child {
  padding: 0 10px 0 20px;
  background: #278b97;
}

.arrow:last-child p {
  color: #ffffff;
}

.arrow:last-child:before {
  background: #278b97;
  border: none;
}

</style>