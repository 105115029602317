const grauParentescoEnum = Object.freeze({
  CONJUGUE: { id: 1, descricao: "Conjuge" },
  FILHO_ATE_21_ANOS: { id: 2, descricao: "Filho até 21 anos" },
  FILHO_UNIVERSITARIO: {
    id: 3,
    descricao: "Filho universitário (maior de 21 anos até 24 anos)",
  },
  FILHO_COM_INCAPACIDADE: { id: 4, descricao: "Filho com incapacidade" },
  IRMAO_NETO_BISNETO_ATE_21_ANOS: {
    id: 5,
    descricao: "Irmão(a), neto(a) ou bisneto(a) até 21 anos com guarda",
  },
  IRMAO_NETO_BISNETO_ATE_24_ANOS: {
    id: 6,
    descricao:
      "Irmão(a), neto(a) ou bisneto(a) até 24 anos universitário com guarda",
  },
  IRMAO_NETO_BISNETO_COM_INCAPACIDADE: {
    id: 7,
    descricao: "Irmão(a), neto(a) ou bisneto(a) incapacitado com guarda",
  },
  PAIS_AVOS_BISAVOS: { id: 8, descricao: "Pais, avós e bisavós" },
  MENOR_POBRE_ATE_21_ANOS: {
    id: 9,
    descricao: "Menor pobre até 21 anos com guarda",
  },
  INCAPAZ: { id: 10, descricao: "Incapaz da qual seja tutor ou curador" },
  AGREGADO_OUTROS: { id: 11, descricao: "Agregado/outros" },
});

function montarListaGrauParentesco() {
  const vm = this;
  var lista = Object.entries(grauParentescoEnum).map(([key]) => ({
    id: grauParentescoEnum[key].id,
    descricao: grauParentescoEnum[key].descricao,
  }));
  return lista;
}

export { grauParentescoEnum, montarListaGrauParentesco };
export default { grauParentescoEnum, montarListaGrauParentesco };
