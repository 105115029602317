<template>
  <v-dialog v-model="modal" width="1800" @click:outside="fechaModal()">
    <v-card>
      <v-card-title>
        <h3 v-if="escolaridade.id === null">Adicionar Escolaridade</h3>
        <h3 v-else>Editar Escolaridade</h3>
        <v-icon @click="fechaModal()">fa-times</v-icon>
      </v-card-title>
      <v-card-text>
        <div>
          <v-container class="container-principal">
            <v-row>
              <v-select
                color="#278b97"
                label="Escolaridade"
                v-model="escolaridade.escolaridade"
                outlined
                dense
                :items="listEscolaridade"
                item-text="nome"
                item-value="id"
              ></v-select>
            </v-row>
            <v-row class="espacamento-v-col">
              <v-text-field
                color="#278b97"
                type="text"
                v-model="escolaridade.curso"
                label="Nome do curso"
                outlined
                dense
              ></v-text-field>
            </v-row>
            <v-row class="espacamento-v-col" style="display: grid">
              <v-autocomplete
                color="#278b97"
                v-model="instituicaoSelecionada"
                item-text="nomeInstituicao"
                clearable
                chips
                outlined
                dense
                :items="listaInstituicoes"
                label="Instituição"
                return-object
                :search-input.sync="search"
              >
                <template v-slot:append-item>
                  <div v-intersect="endIntersect"></div>
                </template>
              </v-autocomplete>
              <a class="instituicao" v-bind:href="'mailto:' + 'gpc@hepta.com.br'">
                <v-icon color="#737373" class="mr-2">fa-regular fa-envelope</v-icon>
                <p>Não encontrei minha instituição</p>
              </a>
            </v-row>
            <v-row>
              <v-col cols="6" class="pl-0 pb-0">
                <v-text-field
                  color="#278b97"
                  type="number"
                  v-model="escolaridade.cargaHoraria"
                  label="Carga Horaria"
                  outlined
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="6" class="pr-0 pb-0">
                <v-menu
                  v-model="dataValidade"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="300"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="escolaridade.conclusaoFormatada"
                      color="#278b97"
                      outlined
                      dense
                      label="Data de conclusão/previsão de termino"
                      placeholder="DD/MM/AAAA"
                      v-on="on"
                      hide-details="auto"
                      v-bind="attrs"
                    >
                      <template slot="append">
                        <v-icon class="icone-azul">fas fa-calendar-days</v-icon>
                      </template>
                    </v-text-field>
                  </template>
                  <v-date-picker
                    @input="escolaridade.conclusaoFormatada = formatDate(validade)"
                    color="#278b97"
                    v-model="validade"
                    required
                    :rules="[v => !!v || 'O campo Data é obrigatório.']"
                    locale="pt-br"
                    no-title
                    width="300"
                  ></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
            <v-row>
              <v-file-input
                @change="anexarArquivo(arquivo)"
                color="#278b97"
                outlined
                dense
                v-model="arquivo"
                label="Anexar comprovante de escolaridade"
                placeholder=" Escolha o anexo..."
                accept="application/pdf"
                hide-details="auto"
                :prepend-icon="null"
              >
                <template v-slot:prepend-inner>
                  <v-icon class="mr-2">fa-paperclip</v-icon>
                </template>
              </v-file-input>
            </v-row>
          </v-container>

          <v-divider color="#278b97" class="mx-10 my-4"></v-divider>

          <v-card-actions class="acoes">
            <v-btn @click="fechaModal()" class="botao-cancelar mr-3" :class="$vuetify.breakpoint.xs ? 'w-40' : 'w-25'">
              <v-icon small color="000" class="mr-3">fa-solid fa-xmark</v-icon>
              Cancelar
            </v-btn>
            <v-btn @click="salvar()" class="botao-salvar mr-3" :class="$vuetify.breakpoint.xs ? 'w-40' : 'w-25'">
              <v-icon small color="#ffffff" class="mr-3">fa-solid fa-arrow-up-from-bracket</v-icon>
              Salvar
            </v-btn>
          </v-card-actions>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import api from '@/services/Api.js';
export default {
  name: 'modal-escolaridade',
  props: {
    escolaridade: {},
  },
  data: () => ({
    modal: true,
    download: false,
    arquivoConteudo: null,
    arquivo: {},
    headers: [
      { text: 'Nome do arquivo', value: '' },
      { text: 'Documento', value: '' },
    ],
    certificacoes: [],
    dataEmissao: false,
    dataValidade: false,
    search: null,
    page: 1,
    loading: false,
    validadeFormatada: null,
    emissao: null,
    validade: null,
    listaCompletaInstituicoes: [],
    listEscolaridade: [],
    nomeInstituicao: '',
    instituicaoSuperior: false,
    listaInstituicoes: [],
    instituicaoSelecionada: {},
  }),
  methods: {
    fechaModal() {
      this.$emit('fecha-modal');
      this.limparVariaveis();
    },
    async carregarMaisInstituicoes() {
      this.page = this.page + 1;
      let total = 50 * this.page;
      return this.listaCompletaInstituicoes.slice(0, total);
    },
    async endIntersect(entries, observer, isIntersecting) {
      if (isIntersecting) {
        let maisInstituicoes = await this.carregarMaisInstituicoes();
        this.listaInstituicoes = maisInstituicoes;
      }
    },
    formatDate(e) {
      if (!e) return null;
      const [year, month, day] = e.split('-');
      this.dataValidade = false;
      return `${day}/${month}/${year}`;
    },
    anexarArquivo(listaArquivos) {
      if (listaArquivos) {
        this.escolaridade.nomeDocumento = listaArquivos.name;
        this.escolaridade.documento = listaArquivos;
      }
    },
    limparVariaveis() {
      this.arquivo = {};
      this.instituicaoSelecionada = {};
    },
    listarEscolaridade() {
      const vm = this;
      api.get('v2/dominio/escolaridades').then(res => {
        vm.listEscolaridade = res.data;
      });
    },
    async listarInstituicoes() {
      const vm = this;
      api.get('colaborador-formacao/todasInstituicoes').then(res => {
        vm.listaCompletaInstituicoes = res.data;
        vm.listaInstituicoes = res.data.slice(0, 50);
      });
    },
    async filtrarIntituicoesPorNome(nomeInstituicao) {
      const vm = this;
      api.get(`colaborador-formacao/filtrarInstituicaoPorNome/${nomeInstituicao}`).then(res => {
        vm.listaCompletaInstituicoes = res.data;
        vm.listaInstituicoes = res.data.slice(0, 50);
      });
    },
    newDate(date) {
      if (!date) {
        return null;
      }
      const [day, month, year] = date.split('/');

      return new Date(year, month - 1, day, 0);
    },
    salvar() {
      const vm = this;
      const dados = vm.dadosParaSalvar();
      if (vm.escolaridade.id) {
        this.$emit('editar', this.escolaridade.id, dados);
        this.fechaModal();
      } else {
        this.$emit('salvar', dados);
        this.fechaModal();
      }
    },
    dadosParaSalvar() {
      const vm = this;
      let obj = vm.organizarFormulario();

      const form = JSON.parse(JSON.stringify(obj));
      if (vm.escolaridade.documento) {
        let arrayNameDocumento = vm.escolaridade.nomeDocumento.split('.');
        let name = arrayNameDocumento[0];
        form.nomeDocumento = name;
      }

      let date = vm.newDate(vm.escolaridade.conclusaoFormatada);
      form.conclusao = date.getTime();

      const formData = new FormData();
      formData.append('form', JSON.stringify(form));
      formData.append('anexo', vm.escolaridade.documento);
      return formData;
    },
    organizarFormulario() {
      const vm = this;
      let form = {
        escolaridade: vm.escolaridade.escolaridade,
        curso: vm.escolaridade.curso,
        idInstituicao: vm.instituicaoSelecionada.idInstituicao,
        nomeInstituicao: vm.instituicaoSelecionada.nomeInstituicao,
        cargaHoraria: vm.escolaridade.cargaHoraria,
        conclusao: vm.revertFormatDate(vm.escolaridade.conclusaoFormatada),
        previsaoConclusao: null,
        nomeDocumento: vm.escolaridade.nomeDocumento,
      };
      return form;
    },
    revertFormatDate(date) {
      if (!date) {
        return null;
      }
      const [day, month, year] = date.split('/');
      return `${year}-${month}-${day}`;
    },
  },
  mounted() {
    this.listarInstituicoes();
    this.listarEscolaridade();
  },
  watch: {
    async search(val) {
      if (val !== '' && val !== null) {
        await this.filtrarIntituicoesPorNome(val);
      } else {
        await this.listarInstituicoes();
      }
    },
  },
};
</script>

<style scoped>
.container-principal {
  padding: 1.25rem 3rem;
}

.icone-azul {
  font-size: 1rem;
  color: #278b97;
  margin-top: 0.25rem;
}

.acoes {
  padding: 0 0 1rem;
  display: flex;
  justify-content: center;
}

.instituicao {
  display: flex;
  margin: 0 0.5rem 1rem;
  text-decoration: none;
}

.instituicao p {
  color: #5d5d5d9c !important;
  font-size: 0.875rem;
  font-weight: 700;
}
</style>
