<template>
  <div>
    <loader :loader-primario="loader"></loader>

    <alerta v-on:fecharModal="fecharModal" :exibir="modal.exibir" :titulo="modal.titulo" :mensagem="modal.mensagem">
    </alerta>

    <modalVisualizar v-model="visualizar" :item="visualizarItem" v-on:fecha-modal="visualizar = false" @update="update">
    </modalVisualizar>

    <modalAlterar v-model="alterar" :item="modalAlterar" v-on:fecha-modal="alterar = false" @update="update">
    </modalAlterar>

    <v-container>
      <div class="card">
        <div class="inicio-titulo">
          <v-img class="icone-titulo" src="@/assets/images/icone/espaco-colaborador/icone_consentimento.svg"></v-img>
          <h1 class="titulo">Proteção de dados pessoais</h1>
        </div>

        <div class="card-texto-termo">
          <p>
            De acordo com a
            <a class="link" href="http://www.planalto.gov.br/ccivil_03/_ato2015-2018/2018/lei/l13709.htm">
              Lei Geral de Proteção de Dados Pessoais (LGPD)</a>,
            sempre que formos utilizar qualquer dado pessoal que não esteja previsto pela legislação iremos solicitar sua
            autorização. Nessa página você poderá controlar e acompanhar todas as autorizações de uso de dados, assim como
            solicitar alterações se desejado.
          </p>
        </div>

        <!-- Busca Status -->
        <v-row>
          <v-col cols="12" sm="9">
            <v-autocomplete clearable chips outlined dense hide-details="auto" height="40px" v-model="estadoSelecionado"
              :items="estados" item-text="nome" item-value="valor" multiple class="input-field">
              <template slot="label">
                <span class="input-field-text">Status:</span>
              </template>
              <template slot="append">
                <v-icon class="input-field-text">fas fa-chevron-down</v-icon>
              </template>
            </v-autocomplete>
          </v-col>
          <v-col cols="12" sm="3">
            <v-btn @click="limpar()" class="botao-limpar">Limpar Buscas</v-btn>
          </v-col>
        </v-row>

        <!-- Tabela -->
        <v-data-table v-model:pagina="pagina" :headers="headers" :items="listaFiltrada" :no-data-text="tabelaVazia()"
          hide-default-footer :items-per-page="porPagina" class="mt-6">
          <template v-slot:item.item-descricao="{ item }">
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <div class="truncate" v-on="on">{{ item.descricao }}</div>
              </template>
              <span>{{ item.descricao }}</span>
            </v-tooltip>
          </template>
          <template v-slot:item.item-estado="{ item }">
            <div :class="status(item.statusConsentimento)">
              <div v-if="item.statusConsentimento == 'SOLICITADO_REVOGACAO'">SOLICITADO REVOGAÇÃO</div>
              <div v-else> {{ item.statusConsentimento }} </div>
            </div>
          </template>
          <template v-slot:item.acao="{ item }">
            <div class="acoes-tabela">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon @click="abrirModalVisualizar(item)" v-bind="attrs" v-on="on" class="icone-acao">
                    fas fa-eye</v-icon>
                </template>
                <span>Visualizar</span>
              </v-tooltip>
              <v-tooltip top v-if="item.statusConsentimento === 'PENDENTE'
                || item.statusConsentimento === 'REPROVADO'">
                <template v-slot:activator="{ on }">
                  <v-img @click="aprovarConsentimento(item.idConsentimentoColaborador)" v-on="on" class="icone-acao"
                    src="@/assets/images/icone/espaco-colaborador/icone_permitir.svg"></v-img>
                </template>
                <span>Aprovar</span>
              </v-tooltip>
              <v-tooltip top v-else-if="item.statusConsentimento === 'PENDENTE'">
                <template v-slot:activator="{ on }">
                  <v-img @click="update('REPROVAR', item.idConsentimentoColaborador)" v-on="on" class="icone-acao"
                    src="@/assets/images/icone/espaco-colaborador/icone_reprovar.svg"></v-img>
                </template>
                <span>Reprovar</span>
              </v-tooltip>
              <v-tooltip top v-else-if="item.statusConsentimento === 'APROVADO'">
                <template v-slot:activator="{ on }">
                  <v-img @click="update('ALTERAR', item.idConsentimentoColaborador)" v-on="on" class="icone-acao"
                    src="@/assets/images/icone/espaco-colaborador/icone_revogar.svg"></v-img>
                </template>
                <span>Solicitar Alteração</span>
              </v-tooltip>
            </div>
          </template>
        </v-data-table>

        <div class="text-center">
          <v-container>
            <v-row justify="center">
              <v-col cols="5">
                <v-container class="max-width">
                  <v-pagination prev-icon=" fa-light fa-chevron-left" next-icon="fa-light fa-chevron-right"
                    v-model="pagina[0]" :length="Math.ceil(total / porPagina)" @input="mudarPagina()" circle dense solo
                    color="#278b97"></v-pagination>
                </v-container>
              </v-col>
            </v-row>
          </v-container>
        </div>
      </div>
    </v-container>
  </div>
</template>
  
<script>
import loader from "@/components/Loader.vue";
import alerta from "@/components/Alerta.vue";
import api from "@/services/Api.js";
import modalMixin from '@/mixins/modalMixin';
import modalVisualizar from '@/components/EspacoDoColaborador/ProtecaoDeDados/ModalVisualizarConsentimento.vue';
import modalAlterar from '@/components/EspacoDoColaborador/ProtecaoDeDados/ModalAlterarConsentimento.vue';

export default {
  components: { loader, alerta, modalVisualizar, modalAlterar },
  mixins: [modalMixin],
  name: "consentimento",
  data() {
    return {
      headers: [
        { text: 'Assunto', value: 'titulo' },
        {
          text: 'Descrição',
          value: 'item-descricao',
          sortable: false,
          width: '20rem',
        },
        {
          text: 'Data de Validade',
          value: 'validade',
          align: 'center',
        },
        {
          text: 'Status',
          value: 'item-estado',
          align: 'center',
          sortable: false,
        },
        {
          text: 'Ação',
          value: 'acao',
          align: 'center',
          sortable: false,
        },
      ],
      consentimentos: [],
      listaFiltrada: [],
      estados: [
        { nome: "Pendente", valor: "PENDENTE" },
        { nome: "Aprovado", valor: "APROVADO" },
        { nome: "Reprovado", valor: "REPROVADO" },
        { nome: "Solicitado Revogação", valor: "SOLICITADO_REVOGACAO" },
        { nome: "Revogado", valor: "REVOGADO" },
      ],
      estadoSelecionado: [],
      user: {},
      loader: false,
      visualizar: false,
      alterar: false,
      visualizarItem: {},
      options: {
        pageCount: 1,
      },
      pagina: [1],
      porPagina: 5,
      modalAlterar: {
        idConsentimento: null,
        titulo: '',
        tipo: '',
      },
      total: 0,
      listaFiltradaSemAlteracao: null,
    }
  },
  methods: {
    mudarPagina() {
      if (this.pagina[0] > 1 && this.estadoSelecionado.length > 0) {
        this.listaFiltrada = this.listaFiltradaSemAlteracao.slice((this.pagina[0] - 1) * this.porPagina)
      }
      else if (this.pagina[0] > 1) {
        this.listaFiltrada = this.consentimentos.slice((this.pagina[0] - 1) * this.porPagina)
      } else if (this.estadoSelecionado.length > 0) {
        this.listaFiltrada = this.listaFiltradaSemAlteracao;
        this.total = this.listaFiltrada.length;
      } else {
        this.listaFiltrada = this.consentimentos;
      }
    },
    update(e, item) {
      const vm = this;
      vm.visualizar = false;
      vm.alterar = false;
      if (e == 'APROVAR') {
        //aprovando
        vm.aprovarConsentimento(item);

      } else if (e == 'REPROVAR') {
        //passando pra modal pro colaborador informar o motivo e confirmar
        vm.modalAlterar.titulo = 'Reprovar consentimento';
        vm.modalAlterar.tipo = e;
        vm.modalAlterar.idConsentimento = item;
        vm.alterar = true;

      } else if (e == 'ALTERAR') {
        //passando pra modal pro colaborador informar o motivo e confirmar
        vm.modalAlterar.titulo = 'Solicitar alteração';
        vm.modalAlterar.tipo = e;
        vm.modalAlterar.idConsentimento = item;
        vm.alterar = true;
      } else if (e == 'REPROVADO') {
        //Colaborador confirmou a reprovacao na modal
        vm.reprovarConsentimento(item);

      } else if (e == 'SOLICITADO_REVOGACAO') {
        //Colaborador confirmou a revogacao na modal
        vm.revogarConsentimento(item);
      }
    },
    abrirModalVisualizar(item) {
      this.visualizarItem = item;
      this.visualizar = true;
    },
    fecharModal() {
      this.modal.exibir = false;
    },
    tabelaVazia() {
      if (!this.consentimentos.length) {
        return "Você não possui consentimentos!!";
      } else if (this.consentimentos.length && !this.listaFiltrada.length) {
        return "Você não possui consentimento no estado " + this.estadoSelecionado.join().toUpperCase() + "!";
      }
    },
    limpar() {
      this.estadoSelecionado = [];
      this.buscarConsentimentos();
    },
    status(e) {
      switch (e) {
        case 'APROVADO':
          return "status-tabela status-aprovado";
        case 'PENDENTE':
          return "status-tabela status-pendente";
        case 'REPROVADO':
          return "status-tabela status-reprovado";
        case 'SOLICITADO_REVOGACAO':
          return "status-tabela status-revogacao";
        case 'REVOGADO':
          return "status-tabela status-revogado";
        default:
          return '';
      }
    },
    async getCredenciais() {
      const vm = this;
      return await api.get("auth/credenciais/")
        .then(response => {
          vm.user = response.data;
        }).catch(e => {
          this.mensagemErro(e.message);
        })
    },
    buscarConsentimentos() {
      const vm = this;
      vm.loader = true;
      api
        .get("colaborador/consentimentos/" + vm.user.codPessoa, {
          headers: { Authorization: localStorage.token },
          withCredentials: true,
        })
        .then((response) => {
          vm.consentimentos = response.data;
          vm.listaFiltrada = vm.consentimentos;
          vm.total = vm.consentimentos.length;
        }).catch(e => {
          vm.mensagemErro(e.message);
        })
        .finally(() => {
          vm.loader = false;
        });
    },
    aprovarConsentimento(idConsentimentoColaborador) {
      const vm = this;
      vm.loader = true;
      api
        .get("colaborador/aprovar/" + idConsentimentoColaborador, {
          headers: { Authorization: localStorage.token },
          withCredentials: true,
        })
        .then((response) => {
          vm.mensagemSucesso(response.data);
          vm.limpar();
        }).catch(e => {
          vm.mensagemErro(e.message);
        })
        .finally(() => {
          vm.loader = false;
        });
    },
    reprovarConsentimento(consentimentoReprovado) {
      const vm = this;
      vm.loader = true;
      api
        .post("colaborador/reprovar/", consentimentoReprovado, {
          headers: { Authorization: localStorage.token },
          withCredentials: true,
        })
        .then((response) => {
          vm.mensagemSucesso(response.data);
          vm.buscarConsentimentos();
        }).catch(e => {
          vm.mensagemErro(e.message);
        })
        .finally(() => {
          vm.loader = false;
        });
    },
    revogarConsentimento(consentimentoRevogado) {
      const vm = this;
      vm.loader = true;
      api
        .post("colaborador/solicitarRevogacao/", consentimentoRevogado, {
          headers: { Authorization: localStorage.token },
          withCredentials: true,
        })
        .then((response) => {
          vm.mensagemSucesso(response.data);
          vm.buscarConsentimentos();
        }).catch(e => {
          vm.mensagemErro(e.message);
        })
        .finally(() => {
          vm.loader = false;
        });

    }
  },
  async mounted() {
    const vm = this;
    await vm.getCredenciais();
    await vm.buscarConsentimentos();
  },
  watch: {
    estadoSelecionado(e) {
      if (e) {
        this.pagina = [1];
        this.listaFiltrada = this.consentimentos.filter(
          (x) => e.includes(x.statusConsentimento)
        );
        this.listaFiltradaSemAlteracao = this.listaFiltrada;
        this.total = this.listaFiltrada.length;
      }
    },
  },
};
</script>
  
<style scoped>
.card-texto-termo {
  text-align: center;
  background-color: #edefeea6;
  border-radius: 20px;
  margin-top: 1rem;
}

.card-texto-termo p {
  padding: 1rem;
  font-weight: 600;
  color: #616161;
}

.link {
  color: #278b97 !important;
  text-decoration: underline;
}

.truncate {
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 20rem;
}

.status-aprovado {
  background-color: #32ACB9;
}

.status-pendente {
  background-color: #F8BA00;
}

.status-reprovado {
  background-color: #DB1826;
}

.status-revogado {
  background-color: #ff4500;
}

.status-revogacao {
  background-color: #2196f3;
}

.icone-acao {
  max-width: 1.5rem;
  cursor: pointer;
}

.icone-acao:hover {
  filter: brightness(85%);
}
</style>
  