import Api from "@/services/Api";

const  ServiceVisitaTecnica = {
    path: {
        credito: "saldo-visita",
        visita: "visita-tecnica",
        conta: "conta-alternativa",
    },
    listarVisitas(config) {
        return Api.get(this.path.visita, config);
    },
    creditoResumo() {
        return Api.get(`${this.path.credito}/resumo`);
    },
    listarContratosAtivos() {
        return Api.get(`${this.path.visita}/contratos-ativos`);
    },
    criarVisitaPlanejada(visita) {
        return Api.post(`${this.path.visita}/planejamento-visita`, visita);
    },
    tiposDespesas() {
        return Api.get(`${this.path.visita}/tipo-despesa`);
    },
    listarEstadosVisita() {
        return Api.get(`${this.path.visita}/estados`);
    },
    getVisita(id) {
        return Api.get(`${this.path.visita}/${id}`);
    },
    listarDespesasVisita(visitaId) {
        return Api.get(`${this.path.visita}/despesa/${visitaId}`);
    },
    postNotaFiscal(formData) {
        return Api.post(`${this.path.visita}/documento/nota-fiscal`, formData);
    },
    atualizarDespesaSemNotaFiscal(form) {
        return Api.post(`${this.path.visita}/despesa/sem-nota-fiscal`, form);
    },
    postDespesaExtra(formData) {
        return Api.post(`${this.path.visita}/despesa`, formData);
    },
    enviarParaValidacao(idVisita) {
        return Api.post(`${this.path.visita}/enviar-para-validacao/${idVisita}`);

    },
    anexarComprovanteReembolso(idVisita, form) {
        return Api.post(`${this.path.visita}/documento/comprovante-reembolso/${idVisita}`, form);
    },
    documento(idDocumento) {
        return Api.get(`${this.path.visita}/documento/download/${idDocumento}`, {
            headers: {
                "Content-Type": "application/octet-stream"
            }
        });
    },
    deslocamentos(params) {
        return Api.get(`${this.path.credito}/deslocamento`, {
            params
        });
    },
    tiposModais() {
        return Api.get(`${this.path.credito}/modais`);
    },
    postDeslocamento(body) {
        return Api.post(`${this.path.credito}/deslocamento`, body);
    },
    despesasDeslocamento(idDeslocamento) {
        return Api.get(`${this.path.credito}/deslocamento/${idDeslocamento}`);
    },
    deletarTransporte(idTranporte) {
        return Api.delete(`${this.path.credito}/transporte/${idTranporte}`);
    },
    atualizarDeslocamento(deslocamento) {
        return Api.put(`${this.path.credito}/deslocamento`, deslocamento);
    },
    instituicoes() {
        return Api.get(`${this.path.conta}/instituicoes-financeiras`);
    },
    tiposConta() {
        return Api.get(`${this.path.conta}/tipo-conta`);
    },
    pegarConta() {
        return Api.get(`${this.path.conta}`);
    },
    criarConta(body) {
        return Api.post(`${this.path.conta}`, body);
    },
    atualizarConta(body) {
        return Api.put(`${this.path.conta}`, body);
    },
}

 export default ServiceVisitaTecnica;