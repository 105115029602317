<template>
  <v-container>
    <v-dialog @click:outside="show = false" v-model="show" min-width="500">
      <v-card>
        <v-card-title>
          <h3>{{ titulo }}</h3>
          <v-icon @click="show = false">fa-times</v-icon>
        </v-card-title>

        <v-card-text>
          <v-row>
            <v-col cols="12">
              <h3>Importante!</h3>
              <v-divider class="my-3"></v-divider>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <p class="text-justify">
                Solicitações de alteração de modalidade de alimentação para refeição, ou refeição para alimentação
                feitos até dia 15 do mês, serão processados na próxima compra mensal. Após dia 16 do mês serão
                processadas somente no mês subsequente o que ocorrerá em média 45 dias após a solicitação.
              </p>
            </v-col>
            <v-col cols="12">
              <p class="text-justify">
                Ao solicitar a alteração da modalidade, haverá um custo de emissão do novo cartão no valor de R$ 5,50
                descontados em seu próximo contracheque. A operadora tem um prazo de até 15 dias para enviar o novo
                cartão.
              </p>
            </v-col>
            <v-col>
              <ul v-for="(item, index) in listaAlimentacao" :key="index" class="text-left">
                <li>{{ item }}</li>
              </ul>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions>
          <v-row>
            <v-col cols="12" md="4" offset-md="2">
              <v-btn @click="show = false" class="botao-secundario-par w-100">Fechar</v-btn>
            </v-col>
            <v-col cols="12" md="4">
              <v-btn @click="solicitar()" class="botao-principal w-100">Solicitar</v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <alerta v-on:fecharModal="fecharModal" :exibir="modal.exibir" :titulo="modal.titulo" :mensagem="modal.mensagem" />

    <loader :loader-primario="loader" />
  </v-container>
</template>

<script>
import alerta from '@/components/Alerta.vue';
import modalMixin from '@/mixins/modalMixin';
import loader from '@/components/Loader.vue';
import { TipoMudancaEnum } from '@/enums/beneficio/TipoMudancaEnum.js';
import { TipoBeneficioEnum } from '@/enums/beneficio/TipoBeneficioEnum.js';
import api from '@/services/Api.js';

export default {
  name: 'cardSolicitacaoVales',
  components: {
    alerta,
    loader,
  },
  mixins: [modalMixin],
  props: {
    value: Boolean,
    beneficio: Object,
    titulo: {
      type: String,
      default: 'Alterar Vale',
    },
  },
  data() {
    return {
      loader: false,
      paths: {
        vale: 'beneficios/pedido/vale',
      },
      listaAlimentacao: [
        'Caso você seja residente em Brasília ou entorno do DF, tão logo o Departamento Pessoal o receba, você será notificado para retirar o cartão na sede da Hepta.',
        'Caso você seja residente em outro Estado, tão logo o Departamento Pessoal o receba, será enviado ao seu endereço pelos correios.',
      ],
      listaTransporte: [
        'Colaboradores de Brasília recebem o vale transporte exclusivamente pelo cartão DFTrans que é de responsabilidade do colaborador.',
        'Caso o colaborador não possua o cartão, faremos a solicitação e em até 7 dias úteis o cartão estará disponível, neste caso o empregado deverá ir até um posto do BRB Mobilidade retirá-lo',
        'Colaboradores de outros Estados, recebem em dinheiro, sendo o valor depositado na mesma conta em que recebe o salário.',
        'Colaboradores que residem no entorno do DF, que necessitam utilizar o transporte público do DF (Ex: circular), podem receber as duas opções (dinheiro e crédito no cartão DFTrans).',
        'Após a solicitação de vale transporte ou solicitações de alteração de reajuste da tarifa, os pagamentos dos valores devidos serão efetuados em até 15 dias.',
        'Colaboradores em teletrabalho não tem direito ao VT, caso volte ao presencial, deverá seguir as regras e o procedimento de solicitação.',
      ],
      justificativa: '',
    };
  },
  methods: {
    solicitar() {
      const vm = this;
      vm.loader = true;
      api
        .post(vm.paths.vale, vm.montarDTO(), {
          params: {
            tipoBeneficio:
              vm.beneficio.tipo.id === TipoBeneficioEnum.VALE_ALIMENTACAO
                ? TipoBeneficioEnum.VALE_REFEICAO
                : TipoBeneficioEnum.VALE_ALIMENTACAO,
          },
        })
        .then(async () => {
          await vm.$emit('sucesso');
          vm.show = false;
        })
        .catch(e => vm.mensagemErro(e.message))
        .finally(() => (vm.loader = false));
    },
    montarDTO() {
      const vm = this;
      var dto = {
        beneficioAnterior: {
          id: vm.beneficio.id,
        },
        tipoMudanca: {
          id: TipoMudancaEnum.SOLICITACAO_MUDANCA,
        },
      };
      return dto;
    },
    // cancelar() {
    //   const vm = this;
    //   if (vm.justificativa == "") {
    //     vm.mensagem("Campo Necessário", "Por favor insira uma justificativa!");
    //   } else {
    //     vm.$emit("cancelar", vm.justificativa);
    //   }
    // },
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        const vm = this;
        this.$emit('input', value);
      },
    },
  },
};
</script>

<style scoped>
.v-dialog > .v-card > .v-card__text {
  padding: 1rem 2rem !important;
}
</style>
