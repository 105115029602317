<template>
  <div>
    <Loader :loader-primario="loader" />

    <Alerta
      v-on:fecharModal="fecharModal"
      :exibir="modal.exibir"
      :titulo="modal.titulo"
      :mensagem="modal.mensagem"
    />

    <v-container>
      <v-row>
        <v-col cols="12" md="8" lg="10">
          Veja abaixo os vouchers da sua visita. Após a visita a ser realizada
          anexe os comprovantes de cada despesa.
        </v-col>
        <v-col cols="12" md="4" lg="2">
          <v-btn @click="$emit('close')" class="botao-principal w-100">
            <v-icon class="mr-2">fas fa-arrow-left </v-icon>
            Voltar
          </v-btn>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <v-data-table :items="despesas" :headers="headersDespesas">
            <template #[`item.idVoucher`]="{ item }">
              <v-btn
                @click="downloadDocumento(item.idVoucher)"
                :disabled="!item.idVoucher"
                icon
                class="icone-acao"
              >
                <v-icon>fas fa-download</v-icon>
              </v-btn>
            </template>

            <template #[`item.acao`]="{ item }">
              <div>
                <v-btn
                  @click="modalComprovante(item)"
                  :disabled="!podeAnexarComprovante(item)"
                  class="botao-principal"
                >
                  <v-icon class="mr-2">fas fa-file-arrow-up</v-icon>
                  Anexar Comprovante
                </v-btn>
              </div>
            </template>
          </v-data-table>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <v-expansion-panels>
            <v-expansion-panel key="1">
              <v-expansion-panel-header @click="toggleDespesaExtra">
                <span v-if="!adicionarDespesaExtra"
                  >Adicionar Despesa Extra</span
                >
                <span v-else>Adicionar Despesa Extra</span>

                <template #actions>
                  <v-icon>$expand</v-icon>
                </template>
              </v-expansion-panel-header>

              <v-expansion-panel-content>
                <v-container>
                  <v-row>
                    <v-col>
                      <p>
                        Anexe abaixo as despesas extras e os comprovantes
                        durante a visita técnica.
                      </p>
                    </v-col>
                  </v-row>

                  <v-form ref="form-despesa">
                    <v-container>
                      <v-row>
                        <v-col cols="12" md="6">
                          <v-text-field
                            v-model="despesaExtraForm.descricao"
                            dense
                            outlined
                            label="Nome da Despesa"
                            required
                            :rules="[i => !!i || 'Digite um descrição']"
                            hide-details="auto"
                            clearable
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6">
                          <v-select
                            v-model="despesaExtraForm.idTipoDespesa"
                            dense
                            outlined
                            required
                            :rules="[i => !!i || 'Selecione o tipo de despesa']"
                            label="Tipo da Despesa"
                            :items="tiposDespesas"
                            item-text="descricao"
                            item-value="idTipoDespesa"
                            hide-details="auto"
                          />
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12" md="6">
                          <v-text-field
                            v-model="despesaExtraForm.valorGasto"
                            dense
                            outlined
                            required
                            type="number"
                            label="Valor da Despesa"
                            :rules="[i => !!i || 'Digite um valor']"
                            hide-details="auto"
                          />
                        </v-col>
                        <v-col
                          cols="12"
                          md="6"
                          v-if="
                            despesaExtraForm.idTipoDespesa >=
                              tiposDespesaEnum.HOSPEDAGEM &&
                            despesaExtraForm.idTipoDespesa <=
                              tiposDespesaEnum.COMPLEMENTO
                          "
                        >
                          <v-file-input
                            v-model="despesaExtraFormComprovante"
                            dense
                            outlined
                            :required="
                              despesaExtraForm.idTipoDespesa !==
                              tiposDespesaEnum.COMPLEMENTO
                            "
                            label="Comprovante"
                            :rules="[
                              i =>
                                i ||
                                despesaExtraForm.idTipoDespesa ===
                                  tiposDespesaEnum.COMPLEMENTO ||
                                'Anexe um comprovante',
                            ]"
                            hide-details="auto"
                            prepend-icon=""
                            append-icon="fas fa-paperclip"
                          />
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-form>
                </v-container>

                <v-row class="justify-center">
                  <v-col cols="12" md="6" xl="3">
                    <v-btn @click="despesaExtra" class="botao-principal w-100"
                      >Adicionar Gasto Complementar</v-btn
                    >
                  </v-col>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>

        <v-col cols="12">
          <v-divider></v-divider>
        </v-col>
        <v-col cols="12">
          <v-btn
            :disabled="!comprovantesAnexados()"
            @click="enviarValidacao"
            class="botao-principal w-100"
          >
            Enviar para Validação
          </v-btn>
        </v-col>
      </v-row>

      <AnexoComprovante
        :despesa="despesa"
        :dialog="showDialog"
        @erro="mensagemErro"
        @update="updateDespesa"
        @close="() => (showDialog = false)"
      />
    </v-container>
  </div>
</template>

<script>
import AnexoComprovante from '@/components/visita/AnexoComprovante.vue';
import ServiceVisitaTecnica from '@/services/ServiceVisitaTecnica';
import {
  VisitaTecnicaEnum,
  EstadosDespesaEnum,
  TiposDespesaEnum,
} from '@/enums/VisitaTecnincaEnums';
import ModalMixin from '@/mixins/modalMixin';
import Loader from '@/components/Loader.vue';
import { blob } from '@/utils/Utils';
import Alerta from '@/components/Alerta.vue';

export default {
  name: 'DetalhesVisita',
  mixins: [ModalMixin],
  components: { Alerta, Loader, AnexoComprovante },
  props: {
    visita: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      loader: false,
      showDialog: false,

      visitaTecnicaEnum: VisitaTecnicaEnum,
      tiposDespesaEnum: TiposDespesaEnum,

      despesa: {},
      despesas: [],
      tiposDespesas: [],
      despesaExtraForm: {},
      despesaExtraFormComprovante: null,
      adicionarDespesaExtra: false,
      headersDespesas: [
        { text: 'Tipo', value: 'nomeTipoDespesa' },
        { text: 'Descricao', value: 'descricao' },
        // { text: 'Voucher', value: 'idVoucher', align: 'center' }, Não é utilizado voucher no banco?
        { text: 'Status', value: 'descEstadoDespesa' },
        { text: 'Ação', value: 'acao', width: '10%' },
      ],
    };
  },
  methods: {
    enviarValidacao() {
      let vm = this;
      vm.loader = true;
      return ServiceVisitaTecnica.enviarParaValidacao(vm.visita.idVisita)
        .then(() => vm.$emit('validacao'))
        .catch(err => vm.$emit('erro', err))
        .finally(() => (vm.loader = false));
    },
    limparFormularioDespesaExtra() {
      this.despesaExtraForm = {};
      this.despesaExtraFormComprovante = null;
      if (this.$refs['form-despesa']) this.$refs['form-despesa'].reset();
    },
    toggleDespesaExtra() {
      let vm = this;
      vm.adicionarDespesaExtra = !vm.adicionarDespesaExtra;
      vm.limparFormularioDespesaExtra();
    },
    despesaExtra() {
      const vm = this;
      if (!vm.$refs['form-despesa'].validate()) {
        return vm.mensagemErro('Preencha todos os campos');
      }
      const despesaForm = {
        idVisita: vm.visita.idVisita,
        ...vm.despesaExtraForm,
      };
      let formData = new FormData();

      if (vm.despesaExtraFormComprovante !== null)
        formData.append('file', vm.despesaExtraFormComprovante);

      formData.append('form-visita', JSON.stringify(despesaForm));

      vm.loader = true;
      ServiceVisitaTecnica.postDespesaExtra(formData)
        .then(res => this.despesas.push(res.data))
        .catch(err => vm.$emit('erro', err))
        .finally(() => {
          vm.limparFormularioDespesaExtra();
          vm.loader = false;
        });
    },
    updateDespesa(despesaAtualizada) {
      let vm = this;
      const despesa = vm.despesas.find(
        d => (d.idDespesa = despesaAtualizada.idDespesa)
      );
      Object.entries(despesaAtualizada).forEach(
        ([key]) => (despesa[key] = despesaAtualizada[key])
      );
      this.showDialog = false;
      vm.despesaVisita();
    },
    despesaVisita() {
      let vm = this;
      return ServiceVisitaTecnica.listarDespesasVisita(vm.visita.idVisita)
        .then(res => (vm.despesas = res.data))
        .catch(vm.mensagemErro);
    },
    modalComprovante(despesa) {
      let vm = this;
      vm.despesa = despesa;
      vm.showDialog = true;
    },
    downloadDocumento(idDocumento) {
      let vm = this;
      vm.loader = true;
      ServiceVisitaTecnica.documento(idDocumento)
        .then(blob)
        .catch(vm.mensagemErro)
        .finally(() => (vm.loader = false));
    },
    podeAnexarComprovante(despesa) {
      /*
       * Pode anexar em:
       * - Estados: "Pago", "Comprovante Rejeitado"
       * - Tipos: "Hospedagem", "Transporte", "Complemento"
       */
      const estadoPodeAnexar =
        despesa.idEstadoDepesa >= EstadosDespesaEnum.PAGO &&
        despesa.idEstadoDepesa <= EstadosDespesaEnum.COMPROVANTE_REJEITADO;
      const tipoPodeAnexar =
        despesa.idTipoDespesa >= TiposDespesaEnum.HOSPEDAGEM &&
        despesa.idTipoDespesa <= TiposDespesaEnum.COMPLEMENTO;

      return estadoPodeAnexar && tipoPodeAnexar;
    },
    buscarTiposDespesas() {
      let vm = this;
      return ServiceVisitaTecnica.tiposDespesas()
        .then(res => (vm.tiposDespesas = res.data))
        .catch(vm.mensagemErro);
    },
    comprovantesAnexados() {
      /**
       * Todos tipos TRANSPORTE e HOSPEDAGEM devem ter comprovante anexado antes de enviar para validação
       */
      const precisamComprovante = this.despesas.filter(
        d =>
          d.idTipoDespesa === TiposDespesaEnum.TRANSPORTE ||
          d.idTipoDespesa === TiposDespesaEnum.HOSPEDAGEM
      );
      return precisamComprovante.every(
        d => d.idEstadoDepesa === EstadosDespesaEnum.COMPROVANTE_ANEXADO
      );
    },
  },
  mounted() {
    this.buscarTiposDespesas();
    this.despesaVisita();
  },
};
</script>

<style scoped>
.v-expansion-panel-header {
  background-color: #278b97 !important;
  border-color: #278b97 !important;
  color: #fff !important;
}

.v-expansion-panel-header span {
  text-align: center;
}

.v-expansion-panel-header__icon .v-icon {
  color: #fff !important;
}
</style>
