<template>
  <div>
    <Loader :loader-primario="loader" />

    <Alerta v-on:fecharModal="fecharModal" :exibir="modal.exibir" :titulo="modal.titulo" :mensagem="modal.mensagem" />

    <v-row>
      <v-col>
        <b>
          * Por favor, insira uma conta que seja sua, ou seja, vinculada ao seu CPF, e que não seja conta salário(Conta
          corrente/Conta Poupança) <br />
          **Preferencialmente contas do Banco do Brasil
        </b>
      </v-col>
    </v-row>

    <v-form ref="form" class="my-4">
      <v-container>
        <v-row>
          <v-col cols="12" md="6">
            <v-select
              v-model="instituicaoFinanceira"
              dense
              outlined
              required
              label="Banco"
              :items="bancos"
              item-value="ispb"
              item-text="nome"
              hide-details="auto"
            />
          </v-col>
          <v-col cols="12" md="6">
            <v-select
              v-model="tipoContaBancaria"
              dense
              outlined
              required
              label="Tipo conta"
              :items="tipoConta"
              item-text="descricao"
              item-value="id"
              class="esilo"
              hide-details="auto"
            />
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" md="6">
            <v-text-field
              dense
              outlined
              v-model="agencia"
              required
              label="Agencia"
              :rules="[i => !!i || 'digite a agencia']"
              hide-details="auto"
            />
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field
              v-model="conta"
              dense
              outlined
              required
              label="Conta"
              :rules="[i => !!i || 'digite a conta']"
              hide-details="auto"
            />
          </v-col>
        </v-row>
      </v-container>
    </v-form>

    <v-row class="justify-center">
      <v-col cols="12" md="6" xl="3">
        <v-btn v-if="!hasConta" @click="cadastrar" class="botao-principal w-100">Cadastrar</v-btn>
        <v-btn v-else @click="atualizar" class="botao-cancelar w-100">Salvar</v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Alerta from '@/components/Alerta.vue';
import Loader from '@/components/Loader.vue';
import modalMixin from '@/mixins/modalMixin';
import ServiceVisitaTecnica from '@/services/ServiceVisitaTecnica';

export default {
  name: 'ContaAlternativa',
  components: { Loader, Alerta },
  mixins: [modalMixin],
  data() {
    return {
      loader: false,
      bancos: [],
      tipoConta: [],
      idContaAlternativa: false,
      agencia: null,
      instituicaoFinanceira: null,
      conta: null,
      tipoContaBancaria: null,
    };
  },
  methods: {
    atualizar() {
      let vm = this;
      vm.loader = true;
      ServiceVisitaTecnica.atualizarConta({
        id: vm.idContaAlternativa,
        agencia: vm.agencia,
        conta: vm.conta,
        idTipoConta: vm.tipoContaBancaria,
        ispbBanco: vm.instituicaoFinanceira,
      })
        .then(() => vm.mensagemSucesso(`Atualizado com sucesso!`))
        .catch(vm.mensagemErro)
        .finally(() => (vm.loader = false));
    },
    cadastrar() {
      let vm = this;
      vm.loader = true;
      ServiceVisitaTecnica.criarConta({
        agencia: vm.agencia,
        conta: vm.conta,
        idTipoConta: vm.tipoContaBancaria,
        ispbBanco: vm.instituicaoFinanceira,
      })
        .then(res => {
          vm.idContaAlternativa = res.data.id;
          vm.mensagemSucesso(`Cadastrado com sucesso!`);
        })
        .catch(vm.mensagemErro)
        .finally(() => (vm.loader = false));
    },
    buscarInstituicoes() {
      let vm = this;
      ServiceVisitaTecnica.instituicoes().then(res => (vm.bancos = res.data));
    },
    buscarTiposConta() {
      let vm = this;
      ServiceVisitaTecnica.tiposConta().then(res => (vm.tipoConta = res.data));
    },
    pegarConta() {
      let vm = this;
      ServiceVisitaTecnica.pegarConta().then(res => {
        vm.idContaAlternativa = res.data.id;
        vm.agencia = res.data.agencia;
        vm.instituicaoFinanceira = res.data.instituicaoFinanceira?.ispb;
        vm.conta = res.data.conta;
        vm.tipoContaBancaria = res.data.tipoContaBancaria?.id;
      });
    },
  },
  computed: {
    hasConta() {
      return !!this.idContaAlternativa;
    },
  },
  mounted() {
    let vm = this;
    vm.buscarInstituicoes();
    vm.buscarTiposConta();
    vm.pegarConta();
  },
};
</script>
