export const idsImagemHome = Object.freeze({
  banner1: 102,
  banner2: 127,
  banner3: 128,
  linkedin: 103,
  facebook: 104,
  instagram: 105,
  youtube: 106,
  divulgacaoGov: 107,
});

export const idsImagemJeitoHepta = Object.freeze({
  banner: 101,
  mapaEstrategico: 108,
  bannerJeitoHepta: 116,
  bannerEstruturaCorporativa: 117,
  f7: 118,
});

export const idsPdfJeitoHepta = Object.freeze({
  visao: 101,
});

export const valoresEstrategicos = Object.freeze({
  capital_humano: 109,
  coerencia: 110,
  colaboracao: 111,
  equilibrio: 112,
  felicidade: 113,
  respeito: 114,
  transformacao: 115,
});

export const idsImagemNoticias = Object.freeze({
  banner: 119,
});

export const idsImagemFaq = Object.freeze({
  banner: 120,
});

export const idsBiblioteca = Object.freeze({
  banner: 121,
  imagem1: 122,
  imagem2: 123,
});

export const idsPesquisa = Object.freeze({
  banner: 124,
});

export const idsVaga = Object.freeze({
  banner: 125,
});

