<template>
  <div>
    <Loader :loader-primario="loader"></Loader>

    <Alerta
      v-on:fecharModal="fecharModal"
      :exibir="modal.exibir"
      :titulo="modal.titulo"
      :mensagem="modal.mensagem"
      v-on:exclusao="excluir()"
    ></Alerta>

    <ModalBanner
      :modalAlterarBanner="modalAlterarBanner"
      :imagem="imagemModal"
      :pagina="pagina"
      :comLink="false"
      @atualizaImagem="atualizaImagem"
      v-on:fecha-modal="modalAlterarBanner = false"
      :customMethod="getImagem"
      @mensagem="mensagem"
      @loader="isLoading"
    ></ModalBanner>

    <ModalArtigo
      v-if="dialogEditar"
      :novidade="editarNovidade"
      @mensagemSucesso="mensagemSucesso"
      @capturarMensagemErro="mensagemErro"
      v-on:fecha-modal="fecharModalArtigo"
    ></ModalArtigo>

    <v-container class="banner">
      <v-btn
        v-if="admin"
        @click="
          modalAlterarBanner = true;
          imagemModal = imagem.banner;
          pagina = 'banner';
        "
        class="banner-btn botao-edicao-adm"
      >
        <v-icon>fa fa-pencil</v-icon>
      </v-btn>
      <v-img
        :src="
          'data:' +
          imagem.banner.tipoArquivo +
          ';base64,' +
          imagem.banner.arquivo
        "
      ></v-img>
    </v-container>

    <v-container>
      <v-card class="card card-noticia">
        <v-row>
          <v-col cols="12" md="3" xl="2">
            <router-link :to="pesquisa ? '/pesquisa/' + keyword : '/novidades'">
              <v-btn class="botao-principal w-100">
                <v-icon class="mr-2">fas fa-solid fa-arrow-left</v-icon>Voltar
              </v-btn>
            </router-link>
          </v-col>
        </v-row>

        <!-- Título e Descrição -->
        <v-row>
          <v-col cols="12">
            <v-img :src="icone" class="icone"></v-img>
          </v-col>
          <v-col cols="12" class="titulo">
            <h1>{{ artigo.titulo }}</h1>
            <h2>{{ artigo.descricao }}</h2>
          </v-col>
        </v-row>

        <!-- Data de Atualização -->
        <v-row>
          <v-col class="data-atualizacao">
            <span>
              {{ artigo.dataInclusao }}
              <span v-if="artigo.dataModificacao"
                >- Atualizado em {{ artigo.dataModificacao }}</span
              >
            </span>
          </v-col>
        </v-row>

        <!-- Conteúdo -->
        <v-row>
          <v-col cols="12">
            <div class="conteudo" v-html="artigo.conteudo"></div>
          </v-col>
          <v-col cols="12" class="text-right">
            <p v-if="this.visualizacao > 1">
              {{ this.visualizacao }} visualizações
            </p>
            <p v-else>{{ this.visualizacao }} visualização</p>
          </v-col>
        </v-row>

        <v-divider v-if="artigosRelacionados.length > 0"></v-divider>

        <!-- Conteúdos Relacionados -->
        <v-row v-if="artigosRelacionados.length > 0">
          <v-col cols="12" class="outras-novidades">
            <h1>Conteúdos Relacionados</h1>
          </v-col>
          <v-col cols="12">
            <v-slide-group
              v-model="slide"
              center-active
              active-class="success"
              show-arrows
              next-icon="fas fa-chevron-right"
              prev-icon="fas fa-chevron-left"
            >
              <template v-slot:next>
                <v-icon color="#32ACB9">fas fa-chevron-right</v-icon>
              </template>
              <template v-slot:prev>
                <v-icon color="#32ACB9">fas fa-chevron-left</v-icon>
              </template>
              <v-slide-item
                v-for="novidade in artigosRelacionados"
                :key="novidade.id"
              >
                <div class="col-items">
                  <v-col>
                    <CardNovidade
                      :id="novidade.id"
                      :titulo="novidade.titulo"
                      :icone="novidade.icone"
                      :descricao="novidade.descricao"
                      :rascunho="novidade.rascunho"
                      v-on:excluirNovidade="excluirNovidade(novidade)"
                      v-on:abrirModalEditar="abrirModalEditarNovidade(novidade)"
                    >
                    </CardNovidade>
                  </v-col>
                </div>
              </v-slide-item>
            </v-slide-group>
          </v-col>
        </v-row>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import CardNovidade from "@/components/CardNovidade.vue";
import api from "@/services/Api.js";
import Loader from "@/components/Loader.vue";
import modalMixin from "@/mixins/modalMixin";
import Alerta from "@/components/Alerta.vue";
import ModalBanner from "@/components/Modal/ModalBanner.vue";
import { idsImagemNoticias } from "@/utils/ImagemEnums.js";
import ModalArtigo from "@/components/Modal/ModalArtigo.vue";
import Utils from "@/utils/Utils";

export default {
  components: { CardNovidade, Loader, ModalBanner, Alerta, ModalArtigo },
  mixins: [modalMixin],
  data() {
    return {
      artigo: {
        titulo: "",
      },
      loader: false,
      pesquisa: false,
      keyword: "",
      visualizacao: 0,
      admin: false,
      pagina: "",
      imagemModal: {},
      imagem: {
        banner: {
          tipoArquivo: "",
          arquivo: "",
        },
      },
      icone: "",
      modalAlterarBanner: false,
      novidades: [],
      dialogEditar: false,
      editarNovidade: {},
      idExclusao: "",
      artigosRelacionados: [],
      slide: null,
    };
  },
  methods: {
    buscarVisualizacaoArtigo(idArtigo) {
      const vm = this;
      api
        .get("/artigo/visualizacoesArtigo/" + idArtigo)
        .then((response) => {
          if (response.status == 200) {
            vm.visualizacao = response.data.visualizacoes;
          }
        })
        .catch((e) => {
          vm.mensagemErro(e.message);
        });
    },
    getSrc(path) {
      if (path && (path.includes("/image/") || path.includes("/img/"))) {
        const nomeImagem = path.split("/icone/")[1];
        if (nomeImagem) {
          return require("../assets/images/icone/" + nomeImagem);
        }
      }
      return require("../assets/images/icone/icone-tipo-pdf.png");
    },
    abrirModalEditarNovidade(novidade) {
      this.dialogEditar = true;
      this.editarNovidade = novidade;
    },
    fecharModalArtigo() {
      this.editarNovidade = {};
      this.dialogEditar = false;
      this.getArtigosRelacionados();
    },
    buscarArtigoPorId(idArtigo) {
      const vm = this;
      vm.loader = true;
      api
        .get("artigo/" + idArtigo)
        .then((response) => {
          if (response.status == 200) {
            vm.artigo = response.data;
            vm.icone = vm.getSrc(vm.artigo.tags[0].icone);
            var conteudoArtigo = response.data.conteudo;
            var divConteudo = document.getElementsByClassName("conteudo")[0];
            divConteudo.innerHTML = conteudoArtigo;
            vm.buscarVisualizacaoArtigo(idArtigo);
          }
        })
        .catch((e) => {
          vm.mensagemErro(e.message);
        })
        .finally(() => {
          vm.loader = false;
        });
    },
    isLoading(e) {
      this.loader = e;
    },
    getImagem(id, pagina) {
      const vm = this;
      vm.loader = true;
      api
        .get("imagem/" + id)
        .then((response) => {
          //nome da variavel é o pagina. ex: vm.banner
          vm.imagem[pagina] = response.data;
        })
        .catch((e) => {
          vm.mensagem("Erro!", "Não foi possivel baixar imagem.");
        })
        .finally(() => {
          vm.loader = false;
        });
    },
    atualizaImagem(pagina, imagem, tipoArquivo) {
      const vm = this;
      vm.imagem[pagina].arquivo = imagem;
      vm.imagem[pagina].tipoArquivo = tipoArquivo;
      vm.modalAlterarBanner = false;
    },
    isAdmin() {
      this.admin = Utils.isAdmin();
    },
    // buscarNovidades() {
    //   const vm = this;
    //   vm.loader = true;
    //   vm.isLoadingTags = true;

    //   api.get(`artigo/novidades`)
    //     .then(response => {
    //       if (response.status == 200) {
    //         vm.novidades = response.data
    //       }
    //       this.maxPages = Math.ceil(response.data.totalItens / response.data.limit)
    //     })
    //     .catch(e => {
    //       vm.mensagemErro(e.message);
    //     }).finally(() => {
    //       this.loader = false;
    //     })
    // },
    excluirNovidade(novidade) {
      const vm = this;
      //solicitou exclusao mas ainda não houve a confirmação
      if (!vm.exclusaoConfirmada) {
        vm.idExclusao = novidade.id;
        vm.confirmarExclusao("Deseja confirmar a exclusão?", vm.idExclusao);
      }
      //Alerta confirmou exclusão, pegar id de exclusao salvo no mixin
      //exclusaoConfirmada é o id de exclusao salvo no mixin
      else {
        vm.fecharModal();
        vm.loader = true;
        api
          .get(`/artigo/excluir/${vm.idExclusao}`, {
            headers: { Authorization: localStorage.token },
            withCredentials: true,
          })
          .then((response) => {
            if (response.status == 200) {
              vm.mensagemSucesso(response.data);
              vm.getArtigosRelacionados();
            }
          })
          .catch((e) => {});
        vm.idExclusao = "";
      }
    },
    getArtigosRelacionados() {
      const vm = this;
      api
        .get(`/artigo/relacionados/${this.$route.query.id}`)
        .then((res) => {
          vm.artigosRelacionados = res.data;
        })
        .catch((e) => {
          vm.mensagemErro(e.message);
        });
    },
  },
  mounted() {
    this.buscarArtigoPorId(this.$route.query.id);
    this.pesquisa = this.$route.params.pesquisa;
    this.keyword = this.$route.params.keyword;

    this.isAdmin();
    this.getArtigosRelacionados();
    for (let pagina in idsImagemNoticias) {
      //ex: this.getImagem(103, 'banner');
      this.getImagem(idsImagemNoticias[pagina], pagina);
    }
  },
};
</script>

<style scoped>
.card-noticia {
  margin-top: -5rem !important;
}

.icone {
  max-width: 5rem;
  margin: 0 auto;
}

.titulo {
  text-transform: none;
}

.data-atualizacao {
  text-align: right;
  color: #616161;
  font-weight: 600;
}

.data-atualizacao span {
  border-bottom: 2px solid #32acb9;
}

.conteudo {
  margin: 2rem;
}

conteudo ::v-deep img {
  display: block !important;
  max-width: 100% !important;
  height: auto !important;
  margin-left: auto !important;
  margin-right: auto !important;
}

.conteudo ::v-deep figure,
.conteudo ::v-deep div.image {
  text-align: center !important;
  margin-left: auto !important;
  margin-right: auto !important;
}

.conteudo ::v-deep figure.image.image-style-block-align-left {
  text-align: left !important;
  margin-left: 0 !important;
  margin-right: auto !important;
}

.conteudo ::v-deep figure.image.image-style-block-align-right {
  text-align: right !important;
  margin-left: auto !important;
  margin-right: 0 !important;
}

.conteudo ::v-deep p > img {
  display: inline-block !important;
  vertical-align: middle !important;
}

.outras-novidades {
  text-align: center;
  margin-top: 2rem;
}

.banner {
  max-width: 100%;
  padding: 0;
}

.banner-btn {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
}

.col-items {
  width: 30rem;
  height: 100%;
}
</style>
