<template>
  <div>
    <Loader :loader-primario="loader" />

    <Alerta v-on:fecharModal="fecharModal" :exibir="modal.exibir" :titulo="modal.titulo" :mensagem="modal.mensagem" />

    <v-container>
      <v-row>
        <v-col cols="12">
          <template>
            <v-form ref="form">
              <!-- Tabela Resultado -->
              <v-row>
                <v-col cols="12" class="header">
                  <v-icon>fas fa-chart-line</v-icon>
                  <h2>Resultado</h2>
                </v-col>
                <v-col cols="12" class="pa-0">
                  <v-simple-table class="tb-resultado">
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th></th>
                          <th>% Alcançada</th>
                          <th>Classificação</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td class="tituloCelula">Avaliação</td>
                          <td>{{ calculoResultado() }}%</td>
                          <td>{{ getClassificacaoNova(media) }}</td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-col>
                <v-col cols="12" class="px-0">
                  <v-divider class="my-5" />
                </v-col>
              </v-row>

              <!-- Tabela Formulário -->
              <v-row>
                <v-col cols="12" class="header">
                  <v-icon>fas fa-list</v-icon>
                  <h2>Avaliação</h2>
                </v-col>
                <v-col cols="12" class="pa-0">
                  <v-simple-table class="tb-formulario">
                    <!-- Cabeçalho -->
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th>Aplico / Pratico</th>
                          <th v-for="item in headers" :key="item.id" class="text-center">{{ item.header }}</th>
                        </tr>
                      </thead>

                      <!-- Linhas -->
                      <tbody>
                        <template v-for="tipo in tiposQuestao">
                          <tr class="tiposQuestao">
                            <td :colspan="headers.length + 1">
                              <v-row>
                                <v-col>{{ tipo }}</v-col>
                                <v-col class="d-flex justify-end">
                                  <span v-show="isCollapsed(tipo)" @click="expand(tipo)">
                                    <v-icon>fas fa-chevron-down</v-icon>
                                  </span>
                                  <span v-show="!isCollapsed(tipo)" @click="expand(tipo)">
                                    <v-icon>fas fa-chevron-up</v-icon>
                                  </span>
                                </v-col>
                              </v-row>
                            </td>
                          </tr>
                          <tr v-if="!isCollapsed(tipo)" v-for="item in listaQuestoes.filter(q => q.tipo === tipo)">
                            <td>{{ item.questao }}</td>
                            <td v-for="header in headers">
                              <v-radio-group disabled v-model="item.resposta">
                                <v-radio :value="header.id" class="radio-input"></v-radio>
                              </v-radio-group>
                            </td>
                          </tr>
                        </template>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-col>
              </v-row>
            </v-form>
          </template>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import Loader from '@/components/Loader.vue';
import Alerta from '@/components/Alerta.vue';
import modalMixin from '@/mixins/modalMixin';
import api from '@/services/Api.js';

import JS from '../methods.js';

export default {
  name: 'ViewAutoAvaliacao',
  components: { Loader, Alerta, api },
  mixins: [modalMixin],
  props: {
    cod_autoavaliacao: { type: Number, default: null },
  },
  data() {
    return {
      media: null,
      dtFinalizado: null,
      dadosAutoAvaliado: null,
      statusAutoavaliacao: null,

      loader: false,
      animated: false,
      questoesSemResposta: false,

      headers: [],
      tiposQuestao: [],
      listaQuestoes: [],
      tiposQuestaoCollapsed: [],
    };
  },
  methods: {
    ...JS,
    async getDadosByIDColaborador(codCicloColaborador) {
      this.loader = true;
      return api
        .get(`AutoAvaliados/getDadosByCodCicloColaborador`, {
          params: { cod_ciclo_colaborador: codCicloColaborador },
        })
        .then(res => {
          this.dadosAutoAvaliado = res.data;
          this.buscarHeaders();
        })
        .catch(e => {
          this.mensagemErro(e.message);
        })
        .finally(() => {
          this.loader = false;
        });
    },
    buscarHeaders() {
      this.loader = true;
      return api
        .get(`headersAutoavaliacao/listarHeaders`)
        .then(res => {
          res.data.forEach(item => {
            this.headers.push({ id: item.id, header: item.header });
          });
        })
        .catch(e => {
          this.mensagemErro(e.message);
        })
        .finally(() => {
          this.loader = false;
        });
    },
    buscarQuestoes() {
      this.loader = true;
      return api
        .get(`questoesAutoavaliacao/listarQuestoes`, {
          params: { cod_ciclo_colaborador: this.cod_autoavaliacao },
        })
        .then(res => {
          this.listaQuestoes = res.data;
          this.criarTiposQuestao();
        })
        .catch(e => {
          this.mensagemErro(e.message);
        })
        .finally(() => {
          this.loader = false;
        });
    },
    criarTiposQuestao() {
      this.tiposQuestao = [...new Set(this.listaQuestoes.map(item => item.tipo))];
    },
    calculoResultado() {
      const somaRespostas = this.listaQuestoes.reduce((acc, questao) => acc + questao.resposta, 0);
      const totalQuestoes = this.listaQuestoes.length;
      const respostaMaxima = this.headers.slice(-1)[0].id;

      let avg = ((somaRespostas / (totalQuestoes * respostaMaxima)) * 100).toFixed(2);
      this.media = avg;

      return avg;
    },
    getClassificacaoNova(numero) {
      const porcentagem = Number(numero);
      if (porcentagem < 70) {
        return 'Abaixo do esperado';
      } else if (porcentagem >= 70 && porcentagem <= 89.9) {
        return 'Atende';
      } else if (porcentagem >= 90) {
        return 'Supera as expectativas';
      } else return 'Erro';
    },
  },
  async mounted() {
    await this.getDadosByIDColaborador(this.cod_autoavaliacao);
    await this.buscarQuestoes();
  },
};
</script>

<style scoped>
.tb-resultado > .v-data-table__wrapper > table > thead > tr > th::after {
  content: '';
  position: absolute;
  border: 1px solid;
  height: 75%;
  right: 0;
  top: 6px;
  color: #fffa;
}

.tb-resultado.v-data-table > .v-data-table__wrapper > table > thead > tr > th,
.tb-formulario.v-data-table > .v-data-table__wrapper > table > thead > tr > th {
  border-bottom: none !important;
}
</style>
