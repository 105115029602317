<template>
  <div>
    <Loader :loader-primario="loader" />

    <Alerta
      v-on:fecharModal="fecharModal"
      :exibir="modal.exibir"
      :titulo="modal.titulo"
      :mensagem="modal.mensagem"
      v-on:exclusao="excluir()"
    />

    <RedesSociais />

    <ModalBanner
      :modalAlterarBanner="modalAlterarBanner"
      :imagem="imagemModal"
      :pagina="pagina"
      @atualizaImagem="atualizaImagem"
      v-on:fecha-modal="modalAlterarBanner = false"
      :customMethod="getImagem"
      @mensagem="mensagem"
      @loader="isLoading"
      :desativavel="bannerDesativavel"
    />

    <ModalArtigo
      v-if="dialogEditar"
      :novidade="editarNovidade"
      v-on:fecha-modal="fecharModalArtigo"
      @capturarMensagemErro="mensagemErro"
    />

    <ModalAnexo
      v-model="modalEditarAnexo"
      @mensagemSucesso="mensagemSucesso"
      :item="anexoEdicao"
      v-on:fecha-modal="modalEditarAnexo = false"
      v-on:salvar="salvarAnexo()"
      @capturarMensagemErro="mensagemErro"
      @capturarMensagemSucesso="mensagemSucesso"
    />

    <!-- Banner + Filtros de Notícias -->
    <v-container class="carrossel-banners">
      <v-carousel show-arrows-on-hover cycle delimiter-icon="fas fa-window-minimize" height="auto">
        <v-carousel-item
          v-for="banner in [imagem.banner1, imagem.banner2, imagem.banner3]"
          :key="banner.id"
          v-if="banner.indAtivo"
          eager
        >
          <v-img
            @click="abrirLink(banner.linkEncaminhar)"
            :src="'data:' + banner.tipoArquivo + ';base64,' + banner.arquivo"
            class="banner"
          ></v-img>
        </v-carousel-item>
      </v-carousel>
      <v-btn
        v-if="admin"
        @click="abrirModalBanner('banner1', imagem.banner1, true)"
        class="banner-btn botao-edicao-adm"
      >
        <v-icon>fa fa-pencil</v-icon>
      </v-btn>
      <v-btn
        v-if="admin"
        @click="abrirModalBanner('banner2', imagem.banner2, true)"
        class="banner-btn botao-edicao-adm"
      >
        <v-icon>fa fa-pencil</v-icon>
      </v-btn>
      <v-btn
        v-if="admin"
        @click="abrirModalBanner('banner3', imagem.banner3, true)"
        class="banner-btn botao-edicao-adm"
      >
        <v-icon>fa fa-pencil</v-icon>
      </v-btn>
    </v-container>

    <!-- Cards Novidades -->
    <v-container>
      <v-row>
        <v-col cols="12" sm="6" lg="3" v-for="novidade in novidadesCustom">
          <CardNovidadeCustom
            :novidade="novidade"
            :admin="admin"
            class="h-100"
            @sucesso-edicao="getDestaquesHome()"
          />
        </v-col>
      </v-row>
    </v-container>

    <!-- Últimas Notícias + Aniversariantes -->
    <v-container class="mt-8">
      <!-- Últimas Notícias -->
      <v-row>
        <v-col cols="12" md="6" class="col-noticias-aniveriantes">
          <v-row>
            <v-col class="titulo-tamanho-fixo">
              <h1>Últimas Notícias</h1>
              <p>Fique por dentro das últimas notícias!</p>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" v-for="novidade in novidades" :key="novidade.id" class="pb-0">
              <CardNovidade
                :id="novidade.id"
                :titulo="novidade.titulo"
                :icone="novidade.icone"
                :descricao="novidade.descricao"
                :rascunho="novidade.rascunho"
                v-on:excluirNovidade="excluirNovidade(novidade)"
                v-on:abrirModalEditar="abrirModalEditarNovidade(novidade)"
              />
            </v-col>
          </v-row>
          <v-row class="row-btn-ver-mais">
            <v-col cols="12" md="6" xl="4">
              <router-link to="/novidades">
                <v-btn class="botao-principal w-100">
                  <v-icon small class="mr-2">fas fa-circle-plus</v-icon>
                  Ver mais
                </v-btn>
              </router-link>
            </v-col>
          </v-row>
        </v-col>

        <v-divider vertical :class="$vuetify.breakpoint.mdAndDown ? 'd-none' : ''" />

        <!-- Aniversariantes -->
        <v-col v-if="aniversariantes.length > 0" cols="12" md="6" class="col-noticias-aniveriantes">
          <!-- Tem aniversariantes -->
          <v-row>
            <v-col class="titulo-tamanho-fixo">
              <h1>Hoje tem bolo!</h1>
              <p>O #TimeHepta parabeniza os aniversariantes.</p>
              <p>{{ this.fraseDoDia() }}</p>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="12"
              v-for="(aniversariante, index) in aniversariantes"
              :key="aniversariante.id"
              v-if="index < 3 || mostrarTodosAniversariantes"
              class="pb-0"
            >
              <CardAniversariante :aniversariante="aniversariante" />
            </v-col>
          </v-row>
          <v-row class="row-btn-ver-mais">
            <v-col cols="12" md="6" xl="4">
              <v-btn
                v-if="!mostrarTodosAniversariantes && aniversariantes.length > 3"
                @click="mostrarTodosAniversariantes = true"
                class="botao-principal w-100"
              >
                <v-icon small class="mr-2">fas fa-circle-plus</v-icon>
                Ver mais
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
        <!-- Nenhum aniversariante -->
        <v-col v-else>
          <v-row>
            <v-col class="titulo-tamanho-fixo">
              <h1>Hoje não vai ter bolo!</h1>
              <p>O #TimeHepta não tem aniversariantes hoje!</p>
            </v-col>
          </v-row>
          <div class="sem-aniversariantes">
            <v-img src="@/assets/images/icone/icone_sem_aniversariante.svg"></v-img>
          </div>
        </v-col>
      </v-row>
    </v-container>

    <!-- Vagas Hepta -->
    <div class="vagas-hepta">
      <v-container class="h-100">
        <div class="alinhamento-vagas">
          <v-row class="align-end">
            <v-col offset-md="4" cols="12" md="3">
              <v-select
                @input="buscarVagas()"
                v-model="vagasTipo"
                clearable
                color="#278b97"
                :items="vagasTipoItems"
                item-text="name"
                item-value="value"
                label="Tipo de vaga"
                solo
                hide-details="auto"
                class="rounded-lg"
              >
                <template slot="append">
                  <v-icon small color="#008B98">fas fa-chevron-down</v-icon>
                </template>
              </v-select>
            </v-col>
            <v-col cols="12" md="2">
              <v-select
                @input="buscarVagas()"
                v-model="vagasLocalizacao"
                clearable
                color="#278b97"
                :items="vagasLocalizacaoItems"
                item-text="des_dominio"
                item-value="cod_dominio"
                label="Localização"
                solo
                hide-details="auto"
                class="rounded-lg"
              >
                <template slot="append">
                  <v-icon small color="#008B98">fas fa-chevron-down</v-icon>
                </template>
              </v-select>
            </v-col>
            <v-col cols="12" md="3">
              <v-text-field
                @input="buscarVagas()"
                v-model="vagasPesquisa"
                clearable
                color="#278b97"
                label="Pesquisar"
                solo
                hide-details="auto"
                class="rounded-lg"
              >
                <template slot="append">
                  <v-icon small color="#008B98">fas fa-search</v-icon>
                </template>
              </v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col offset-md="4" md="8">
              <v-slide-group
                v-model="slide"
                class="pa-4"
                active-class="success"
                show-arrows
                next-icon="fas fa-chevron-right"
                prev-icon="fas fa-chevron-left"
              >
                <template v-slot:next>
                  <v-icon color="white">fas fa-chevron-right</v-icon>
                </template>
                <template v-slot:prev>
                  <v-icon color="white">fas fa-chevron-left</v-icon>
                </template>
                <v-slide-item v-for="vaga in vagas" :key="vaga.id">
                  <CardVagas
                    :titulo="vaga.nom_funcao_sugerida"
                    :tipo="
                      vaga.cod_tipo_solicitacao == 304 || vaga.cod_tipo_solicitacao == 303 // verifica se a vaga é mista ou externa e se for classifica como mista
                        ? 'Vaga Mista'
                        : 'Vaga Interna'
                    "
                    :local="vaga.nom_cidade"
                    :id="vaga.cod_solicitacao"
                    :data="vaga.dta_aprovacao"
                    :isInterna="vaga.cod_tipo_solicitacao === 302"
                  >
                  </CardVagas>
                </v-slide-item>
              </v-slide-group>
              <v-row class="justify-center pt-5">
                <v-btn class="botao-outlined rounded-lg" href="#/vagas">
                  <v-icon small color="#ffffff" class="mr-3">fas fa-circle-plus</v-icon>
                  Ver todas as vagas
                </v-btn>
              </v-row>
            </v-col>
          </v-row>
        </div>
      </v-container>
    </div>

    <!-- Comunicação + Últimos Arquivos -->
    <div>
      <v-container>
        <v-row>
          <!-- Banner Comunicação -->
          <v-col cols="12" lg="6" class="comunicacao">
            <v-btn
              v-if="admin"
              @click="abrirModalBanner('divulgacaoGov', imagem.divulgacaoGov)"
              class="banner-btn botao-edicao-adm"
            >
              <v-icon>fa fa-pencil</v-icon>
            </v-btn>
            <v-img
              :src="
                'data:' +
                imagem.divulgacaoGov.tipoArquivo +
                ';base64,' +
                imagem.divulgacaoGov.arquivo
              "
              class="banner banner-comunicacao"
              @click="abrirLink(imagem.divulgacaoGov.linkEncaminhar)"
            ></v-img>
          </v-col>

          <v-divider
            inset
            vertical
            color="#5bb0ba"
            justify="center"
            class="mb-2"
            :class="$vuetify.breakpoint.mobile ? 'd-none' : ''"
          ></v-divider>

          <!-- Últimos Arquivos -->
          <v-col cols="12" lg="6">
            <div class="titulo">
              <h1>Últimos arquivos</h1>
            </div>
            <div class="d-flex align-center flex-column">
              <div v-for="item in arquivo" :key="item.id" class="mb-5 w-100">
                <FormatoArquivo
                  :arquivo="item"
                  @capturarIdEdicao="modalEditar"
                  @excluir="excluir"
                  @capturarMensagemErro="mensagemErro"
                  @capturarMensagemSucesso="mensagemSucesso"
                ></FormatoArquivo>
              </div>
            </div>
            <v-row class="justify-center">
              <v-btn class="botao-principal w-50" href="#/biblioteca">
                <v-icon small class="mr-2">fas fa-circle-plus</v-icon>
                Ver todos os arquivos
              </v-btn>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </div>

    <v-divider class="ma-5"></v-divider>

    <!-- Redes Sociais -->
    <v-container class="redes-sociais">
      <div class="titulo">
        <h1>Redes Sociais</h1>
        <div class="redes-sociais-icones">
          <v-icon>fas fa-chevron-down</v-icon>
          <v-icon class="icone-chevron">fas fa-chevron-down</v-icon>
        </div>
      </div>
      <v-row>
        <v-col
          cols="12"
          md="3"
          v-for="(tipoImagem, index) in imagem"
          v-if="
            index != 'banner1' &&
            index != 'banner2' &&
            index != 'banner3' &&
            index != 'divulgacaoGov'
          "
        >
          <v-btn
            v-if="admin"
            @click="abrirModalBanner(index, tipoImagem)"
            class="banner-rede-social-botao botao-edicao-adm"
          >
            <v-icon>fa fa-pencil</v-icon>
          </v-btn>
          <v-img
            @click="abrirLink(tipoImagem.linkEncaminhar)"
            :src="'data:' + tipoImagem.tipoArquivo + ';base64,' + tipoImagem.arquivo"
            class="banner banner-rede-social"
          ></v-img>
        </v-col>
      </v-row>
      <v-row class="texto-redes-sociais">
        <p>
          Escolha a rede social da sua preferência e nos siga para ficar por dentro das novidades!
          <br />
          Lembre-se de curtir, comentar e compartilhar!
        </p>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import FormatoArquivo from '../components/FormatoArquivo.vue';
import Alerta from '@/components/Alerta.vue';
import ModalArtigo from '../components/Modal/ModalArtigo.vue';
import RedesSociais from '@/components/RedesSociais.vue';
import ModalAnexo from '@/components/Modal/ModalBiblioteca.vue';
import Loader from '@/components/Loader.vue';
import api from '@/services/Api.js';
import modalMixin from '@/mixins/modalMixin';
import ModalBanner from '@/components/Modal/ModalBanner.vue';
import { idsImagemHome } from '@/utils/ImagemEnums.js';

import CardAniversariante from '@/components/CardAniversariante.vue';
import CardNovidadeCustom from '@/components/CardNovidadeCustom.vue';
import CardNovidade from '@/components/CardNovidade.vue';
import CardVagas from '@/components/CardVagas.vue';
import Utils from '@/utils/Utils';

export default {
  components: {
    FormatoArquivo,
    CardNovidade,
    CardVagas,
    RedesSociais,
    Loader,
    Alerta,
    ModalArtigo,
    ModalAnexo,
    ModalBanner,
    CardAniversariante,
    CardNovidadeCustom,
  },
  mixins: [modalMixin],
  data() {
    return {
      dialogEditar: false,
      novidades: [
        {
          icone: '@/assets/images/icone-tipo-pdf.png',
          titulo: 'Gartner lista os 10 erros mais comuns na definição da estratégia de nuvem',
          descricao:
            'É preciso avaliar e definir o papel da tecnologia em todos os pontos da organização',
          favorito: false,
        },
        {
          icone: '@/assets/images/icone-tipo-pdf.png',
          titulo: 'Gartner lista os 10 erros mais comuns na definição da estratégia de nuvem',
          descricao:
            'É preciso avaliar e definir o papel da tecnologia em todos os pontos da organização',
          favorito: false,
        },
        {
          icone: '@/assets/images/icone-tipo-pdf.png',
          titulo: 'Gartner lista os 10 erros mais comuns na definição da estratégia de nuvem',
          descricao:
            'É preciso avaliar e definir o papel da tecnologia em todos os pontos da organização',
          favorito: false,
        },
      ],
      novidadesCustom: [],
      imagemModal: {},
      imageUrl: null,
      pagina: '',
      imagem: {
        banner1: {
          tipoArquivo: '',
          arquivo: '',
        },
        banner2: {
          tipoArquivo: '',
          arquivo: '',
        },
        banner3: {
          tipoArquivo: '',
          arquivo: '',
        },
        linkedin: {
          tipoArquivo: '',
          arquivo: '',
        },
        facebook: {
          tipoArquivo: '',
          arquivo: '',
        },
        instagram: {
          tipoArquivo: '',
          arquivo: '',
        },
        youtube: {
          tipoArquivo: '',
          arquivo: '',
        },
        divulgacaoGov: {
          tipoArquivo: '',
          arquivo: '',
        },
      },
      aniversariantes: [],
      vagasTipo: [],
      vagasTipoItems: [
        { name: 'Todas as vagas', value: '' },
        { name: 'Vagas Internas', value: 302 },
        { name: 'Vagas Mistas', value: 304 },
      ],
      vagasLocalizacao: [],
      idExclusao: '',
      vagasLocalizacaoItems: [],
      vagasPesquisa: '',
      slide: null,
      vagas: [],
      arquivo: [],
      loader: false,
      anexoEdicao: {},
      modalEditarAnexo: false,
      modalAlterarBanner: false,
      admin: false,
      mostrarTodosAniversariantes: false,
      bannerDesativavel: false,
    };
  },
  methods: {
    buscarAniversariantesDoDia() {
      this.loader = true;
      api
        .get('/home/aniversariantes', {
          headers: { Authorization: localStorage.token },
          withCredentials: true,
        })
        .then(response => {
          this.aniversariantes = response.data;
        })
        .catch(e => {
          this.mensagemErro(e.message);
        });
    },
    fecharModalArtigo() {
      this.editarNovidade = {};
      this.dialogEditar = false;
      this.buscarUltimasNoticias();
    },
    buscarUltimasNoticias() {
      api
        .get(`/artigo/ultimasNovidades`)
        .then(response => {
          this.novidades = response.data;
        })
        .catch(e => {
          this.mensagemErro(e.message);
        });
    },
    getImagem(id, pagina) {
      const vm = this;
      vm.loader = true;

      api
        .get('imagem/' + id)
        .then(response => {
          //nome da variavel é o pagina. ex: vm.banner
          vm.imagem[pagina] = response.data;
        })
        .catch(e => {
          vm.mensagemErro(e.message);
        })
        .finally(() => {
          vm.loader = false;
        });
    },
    atualizaImagem(pagina, imagem, tipoArquivo) {
      const vm = this;
      vm.imagem[pagina].arquivo = imagem;
      vm.imagem[pagina].tipoArquivo = tipoArquivo;
      vm.modalAlterarBanner = false;
    },
    excluirNovidade(novidade) {
      const vm = this;
      //solicitou exclusao mas ainda não houve a confirmação
      if (!vm.exclusaoConfirmada) {
        vm.idExclusao = novidade.id;
        vm.confirmarExclusao('Deseja confirmar a exclusão?', vm.idExclusao);
      }
      //Alerta confirmou exclusão, pegar id de exclusao salvo no mixin
      //exclusaoConfirmada é o id de exclusao salvo no mixin
    },
    buscarUltimosAnexos() {
      const vm = this;
      api
        .get(`anexo/listarUltimosAnexos`)
        .then(response => {
          this.arquivo = response.data;
        })
        .catch(e => {
          vm.mensagemErro(e.message);
        });
    },
    abrirModalEditarNovidade(novidade) {
      this.dialogEditar = true;
      this.editarNovidade = novidade;
    },
    buscarVagas() {
      const vm = this;
      this.vagasTipo = this.vagasTipo == null ? '' : this.vagasTipo;
      this.vagasLocalizacao = this.vagasLocalizacao == null ? '' : this.vagasLocalizacao;
      this.vagasPesquisa = this.vagasPesquisa == null ? '' : this.vagasPesquisa;
      api
        .get(
          '/recrutamento-selecao/vagascomfiltro/?tipoVaga=' +
            this.vagasTipo +
            '&localizacao=' +
            this.vagasLocalizacao +
            '&nomeVaga=' +
            this.vagasPesquisa,
          {
            headers: { Authorization: localStorage.token },
            withCredentials: true,
          }
        )
        .then(response => {
          this.vagas = response.data;
          this.vagas.map(v => {
            var dataAprovacao = new Date(v.dta_aprovacao);
            v.dta_aprovacao = dataAprovacao.toLocaleDateString();
          });
        })
        .catch(e => {
          this.mensagemErro(e.message);
        });
    },
    modalEditar(anexo) {
      const vm = this;
      vm.anexoEdicao = anexo;
      vm.modalEditarAnexo = true;
    },
    buscarUfVagas() {
      const vm = this;
      api
        .get('/recrutamento-selecao/uf', {
          headers: { Authorization: localStorage.token },
          withCredentials: true,
        })
        .then(response => {
          this.vagasLocalizacaoItems.push({
            des_dominio: 'Todas as localidades',
            nom_dominio: [],
          });
          response.data.map(r => {
            this.vagasLocalizacaoItems.push(r);
          });
        })
        .catch(e => {
          this.mensagemErro(e.message);
        })
        .finally(() => {
          this.loader = false;
        });
    },
    salvarAnexo() {
      this.modalEditarAnexo = false;
      this.buscarUltimosAnexos();
    },
    abrirLink(link) {
      window.open(link, '_self');
    },
    isLoading(e) {
      this.loader = e;
    },
    excluir(idExclusao) {
      const vm = this;
      //solicitou exclusao mas ainda não houve a confirmação
      if (!vm.exclusaoConfirmada) {
        vm.confirmarExclusao('Deseja confirmar a exclusão?', idExclusao);
      }
      if (vm.idExclusao !== '') {
        vm.fecharModal();
        vm.loader = true;
        api
          .get(`/artigo/excluir/${vm.idExclusao}`, {
            headers: { Authorization: localStorage.token },
            withCredentials: true,
          })
          .then(response => {
            if (response.status == 200) {
              vm.mensagemSucesso(response.data);
              vm.buscarUltimasNoticias();
            }
          })
          .catch(e => {
            vm.mensagemErro(e.message);
          });
        vm.loader = false;
        vm.idExclusao = '';
      }
      //Alerta confirmou exclusão, pegar id de exclusao salvo no mixin
      //exclusaoConfirmada é o id de exclusao salvo no mixin
      if (vm.exclusaoConfirmada) {
        idExclusao = vm.exclusaoConfirmada;
        vm.fecharModal();
        vm.loader = true;

        api
          .get(`anexo/excluir/${idExclusao}`)
          .then(response => {
            vm.mensagemSucesso(response.data);
          })
          .catch(e => {
            vm.mensagemErro(e.message);
          })
          .finally(() => {
            this.buscarUltimosAnexos();
          });
      }
    },
    isAdmin() {
      this.admin = Utils.isAdmin();
    },
    fraseDoDia() {
      let frases = [
        'O dia está tão gostoso que poderíamos chamá-lo de torta 🎂! Feliz aniversário!!!',
        'Alegria, alegria, que dia mais feliz!!!',
        'Chega n@ crush e fala: - No seu aniversário, eu não vou te dar um presente... vou te dar o futuro.',
        'Por onde andam aqueles pedidos que a gente fez quando apagamos a velinha no aniversário? 🕯❓😐',
        'A simplicidade de uma palavra pode ser inspiradora: Parabéns. ❤❤',
        'A vida deve ser como o vinho: ficar melhor com o passar dos anos! 😉',
        'Rabo de tatu, pena de galinha, quem está ficando mais velho da uma risadinha.',
      ];
      let dataAtual = new Date();
      return frases[dataAtual.getDay()];
    },
    abrirModalBanner(pagina, imagemModal, desativavel = false) {
      this.pagina = pagina;
      this.imagemModal = imagemModal;
      this.bannerDesativavel = desativavel;
      this.modalAlterarBanner = true;
    },
    getDestaquesHome() {
      this.loader = true;

      api
        .get(`destaque-home`)
        .then(res => {
          res.data.forEach(novidade => {
            novidade.icone = novidade.icone ? `data:image/png;base64,${novidade.icone}` : null;
          });
          this.novidadesCustom = res.data;
        })
        .catch(e => {
          this.mensagemErro(e.message);
        })
        .finally(() => {
          this.loader = false;
        });
    },
  },
  created() {
    this.isAdmin();
    this.getDestaquesHome();
    this.buscarAniversariantesDoDia();
    this.buscarUltimasNoticias();
    this.buscarUltimosAnexos();
    this.buscarVagas();
    this.buscarUfVagas();

    for (let pagina in idsImagemHome) {
      //ex: this.getImagem(103, 'banner');
      this.getImagem(idsImagemHome[pagina], pagina);
    }
  },
};
</script>

<style scoped>
.carrossel-banners {
  max-width: 100%;
  padding: 0;
  margin-bottom: 1rem;
}

.carrossel-banners::v-deep .v-ripple__container {
  opacity: 0 !important;
}

.carrossel-banners::v-deep .v-carousel__controls {
  position: relative;
  height: auto;
  background: none;
}

.carrossel-banners::v-deep .v-carousel__controls__item {
  margin-top: -2rem;
  width: 2.5rem !important;
  height: 2.5rem !important;
  color: #d9d9d9;
}

.carrossel-banners::v-deep .v-carousel__controls__item .v-icon {
  width: 2.5rem !important;
  height: 2.5rem !important;
  font-size: 2.5rem !important;
  opacity: 1;
}

.carrossel-banners::v-deep .v-carousel__controls__item::before {
  opacity: 0 !important;
}

.carrossel-banners::v-deep .v-carousel__controls__item.v-item--active {
  color: #32acb9;
}

.carrossel-banners .banner-btn:nth-last-child(2) {
  top: 3rem;
}

.carrossel-banners .banner-btn:last-child {
  top: 6rem;
}

.col-noticias-aniveriantes {
  position: relative;
  padding: 0.75rem 2rem 5rem;
}

.row-btn-ver-mais {
  position: absolute;
  bottom: 0;
  display: flex;
  justify-content: center;
  width: 100%;
}

.titulo {
  text-align: center;
  margin: 1rem 0 2rem !important;
}

.redes-sociais {
  background-image: url('../assets/images/redes/fundo-redes-sociais.png');
  background-size: 100%;
  background-position: 0%;
  max-width: 100%;
  padding: 1rem 8%;
}

.titulo-tamanho-fixo {
  text-align: center;
  height: 6rem;
  margin: 2rem 0;
}

.redes-sociais-icones {
  display: flex;
  flex-direction: column;
  margin: 1rem 0;
}

.redes-sociais-icones .v-icon {
  color: #278b97;
  font-size: 1.25rem;
}

.icone-chevron {
  line-height: 0.3;
}

.texto-redes-sociais {
  justify-content: center;
  margin: 3rem 0;
}

.texto-redes-sociais p {
  text-align: center;
}

/*VAGAS */
.alinhamento-vagas {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
}

.vagas-hepta {
  background-image: url('../assets/images/home/baner_vagas.png');
  height: 30rem;
  margin: 3rem 0;
}

.vaga {
  border-radius: 23px;
  margin: 10px;
}

.vaga h4 {
  font-size: 15px;
  text-transform: initial;
  color: #278b97;
}

.vaga p {
  color: #231f2099;
}

.vaga-linha {
  background-color: #278b978c;
  margin: 8px 0;
}

.vaga i {
  margin-right: 5px;
  color: #278b97;
}

.vaga span {
  color: #278b97;
  font-weight: 600;
  font-size: 0.8rem;
}

.botao-outlined {
  font-size: 1rem;
  font-weight: 600;
}

/*ARTIGOS */
.comunicacao {
  position: relative;
  height: 478px;
  display: flex;
  align-items: center;
}

.banner {
  cursor: pointer;
}

.banner-rede-social {
  box-shadow: 0px 0px 20px #00000060;
}

.banner-rede-social-botao {
  position: absolute;
  z-index: 1;
}

.banner-btn {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
}

.banner-comunicacao {
  margin: 0 0 0 -3rem;
  border-radius: 2rem;
}

.sem-aniversariantes {
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 3rem;
  max-width: 8rem;
}

.botao-ver-aniversariantes {
  justify-content: center;
}

.margin-top-2 {
  margin-top: 2rem;
}

@media screen and (min-width: 1905px) {
  .vagas-hepta {
    height: 589px;
    background-size: cover;
  }
}

@media (min-width: 960px) and (max-width: 1904px) {
  .vagas-hepta {
    background-image: url('../assets/images/home/baner_vagas.png');
    margin: 64px 0;
    background-position: 15%;
    background-size: cover;
  }

  .botao-outlined {
    width: 50%;
  }
}

@media screen and (max-width: 959px) {
  .destaque {
    height: 100%;
    width: 80%;
    top: 0;
  }

  .destaque .linha {
    border-top: 1px solid rgb(39 139 151 / 51%);
    border-right: none;
  }

  .destaque .linha:first-child {
    border-top: none;
  }

  .vagas-hepta {
    background-image: url('../assets/images/banner/banner_vagas_mobile.png');
    background-size: cover;
    height: 100%;
  }

  .alinhamento-vagas {
    padding-top: 110%;
  }

  .icone {
    flex-direction: column;
  }
}

@media screen and (max-width: 600px) {
  .botao-principal {
    width: 90%;
  }

  .botao-outlined {
    width: 90%;
  }
}
</style>
