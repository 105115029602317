<template>
  <v-dialog v-model="show" @click:outside="fecharModal()" width="1800">
    <Alerta v-on:fecharModal="fecharModal" v-on:exclusao="apagarItemFaq()" :exibir="modal.exibir" :titulo="modal.titulo"
      :mensagem="modal.mensagem" />

    <v-card>
      <v-card-title>
        <h3>Editar Destaque</h3>
        <v-icon @click="fecharModal()">fa-times</v-icon>
      </v-card-title>

      <!-- Conteúdo do Card -->
      <v-card-text>
        <div class="card-principal">
          <!-- Pre Visualização -->
          <v-row>
            <v-col cols="12">
              <h5 class="text-start">Pré visualização</h5>
            </v-col>
            <v-col cols="12" md="6" offset-md="3">
              <v-card class="card-novidade" :style="{ background: destaque.codigoCor }">
                <v-row>
                  <v-col cols="12" v-if="icone">
                    <v-img :src="icone" />
                  </v-col>
                  <v-col cols="12" v-if="icone">
                    <v-divider />
                  </v-col>
                  <v-col cols="12">
                    <span class="titulo-custom">
                      {{ destaque.titulo }}
                    </span>
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
          </v-row>

          <!-- Form -->
          <v-form ref="formArtigo">
            <v-row>
              <v-col cols="12">
                <v-file-input v-model="destaque.icone" @change="atualizarIcone()" prepend-icon
                  prepend-inner-icon="fas fa-paperclip" outlined dense />

                <v-text-field :rules="rules.obrigatorio" label="Titulo:" v-model="destaque.titulo" maxLength="120"
                  outlined dense />

                <v-text-field :rules="rules.obrigatorio" label="Link:" v-model="destaque.link" outlined dense />

                <v-select v-model="destaque.idCor" :items="cores" item-value="id" item-text="nome" :rules="rules.tags"
                  label="Cores" outlined dense @change="atualizarCor()">
                  <template v-slot:item="{ item }">
                    {{ item.nome }} - {{ item.codigo }}
                    <v-icon class="ml-2" :color="item.codigo" size="16px">fa-solid fa-square</v-icon>
                  </template>
                </v-select>
              </v-col>
            </v-row>
          </v-form>

          <v-divider />
        </div>
      </v-card-text>

      <!-- Botões -->
      <v-card-actions class="justify-center pb-5">
        <v-btn @click="fecharModal()" class="botao-cancelar w-25 mr-5">Cancelar</v-btn>
        <v-btn @click="postNovidade()" class="w-25 botao-principal">Editar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
   
<script>
import api from "@/services/Api.js";
import Alerta from "@/components/Alerta.vue";
import modalMixin from "@/mixins/modalMixin";
import CardNovidadeCustom from "@/components/CardNovidadeCustom.vue";

export default {
  name: "ModalEdicaoDestaque",
  components: { Alerta, CardNovidadeCustom },
  mixins: [modalMixin],
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    idDestaque: Number,
  },
  data() {
    return {
      show: false,
      destaque: {
        codigoCor: '',
      },
      corSelecionada: null,
      cores: [],
      rules: {
        tags: [
          (v) => !!v || 'Campo obrigatório',
        ],
        campoObrigatorio: [(v) => !!v || "Campo obrigatório"],
        obrigatorio: [
          (value) => !!value || "Campo obrigatório",
        ],
      },
      icone: null,
    }
  },
  methods: {
    fecharModal() {
      this.$emit('fecha-modal');
    },
    buscarCores() {
      const vm = this;
      api.get("destaque-home/cores")
        .then(res => { vm.cores = res.data })
        .catch(e => { vm.mensagemErro(e.message) });
    },
    buscarDetalhesDestaque() {
      const vm = this;
      api.get(`destaque-home/${vm.idDestaque}`)
        .then(res => {
          vm.destaque = res.data;
          vm.icone = vm.destaque.icone ? `data:image/png;base64,${vm.destaque.icone}` : null;
        })
        .catch(e => { vm.mensagemErro(e.message) });
    },
    postNovidade() {
      api.post(`/destaque-home/atualizar`, {
        id: this.destaque.id,
        link: this.destaque.link,
        idCor: this.destaque.idCor,
        titulo: this.destaque.titulo,
        icone: this.icone.replace(/^data:.+;base64,/, ""),
      })
        .then(res => {
          this.mensagem('Sucesso', res.data);
          this.$emit("sucesso-edicao")
        })
        .catch(e => { this.mensagemErro(e.message) });
    },
    atualizarIcone() {
      const file = this.destaque.icone;
      const reader = new FileReader();

      reader.readAsDataURL(file);
      reader.onloadend = () => {
        this.icone = reader.result;
      };
    },
    atualizarCor() {
      const corSelecionada = this.cores.find(c => c.id === this.destaque.idCor).codigo;
      this.destaque.codigoCor = corSelecionada;
    },
  },
  watch: {
    value(e) {
      this.show = e;
    },
  },
  mounted() {
    this.buscarCores();
    this.buscarDetalhesDestaque();
  },
}
</script>
  
<style scoped>
.card-principal {
  padding: 1.5rem 3rem 0;
}

.card-novidade {
  height: 100%;
  padding: 10% 15%;
  border-radius: 1rem !important;
  box-shadow: none !important;
  color: #FFF !important;
  font-size: 1rem;
}

.card-novidade .v-divider {
  border-color: #FFF !important;
}

.card-novidade .v-image {
  margin: 0 auto;
  width: 30%;
  filter: brightness(0) invert(1);
}

.titulo-custom {
  font-weight: bold;
}
</style>
