<template>
  <v-app-bar color="#278B97" hide-on-scroll>
    <router-link to="/home">
      <img src="@/assets/images/espaco-colaborador/logo_nossa_hepta.png" />
    </router-link>

    <v-spacer></v-spacer>

    <v-list-item v-for="item in menu" :key="item.id">
      <v-col>
        <a @click=abrir(item.link) class="link">{{ item.nome }}</a>
      </v-col>
    </v-list-item>
    <v-list-item light>
      <v-icon @click="pesquisar = true" v-if="!pesquisar" color="white">fas fa-magnifying-glass</v-icon>
      <v-text-field v-else dense solo label="Pesquisar" v-model="keyword" @keyup.enter="abrir('/pesquisa/' + keyword)"
        color="#278b97" hide-details="auto" clearable :class="$vuetify.breakpoint.lg ? 'search-field' : ''">
        <template slot="append">
          <v-icon color="#008B98" @click="pesquisar = false">fas fa-magnifying-glass</v-icon>
        </template>
      </v-text-field>
    </v-list-item>
  </v-app-bar>
</template>

<script>
export default {
  data() {
    return {
      keyword: "",
      menu: [
        { nome: "Início", link: "/home" },
        { nome: "Novidades", link: "/novidades" },
        { nome: "#Jeito Hepta", link: "/jeitohepta" },
        { nome: "Biblioteca", link: "/biblioteca" },
        { nome: "Perguntas Frequentes", link: "/perguntas-frequentes" },
      ],
      pesquisar: false,
    };
  },
  methods: {
    abrir(path) {
      this.$router.push({
        path: path,
      });
    }
  }
};
</script>

<style scoped>
.v-app-bar {
  padding: 0 5%;
}

::v-deep .v-toolbar__content {
  overflow-x: auto;
  scrollbar-color: #278B97 #F5F5F5;
}

.v-list-item {
  flex: none;
}

.link {
  color: #FFF !important;
  font-weight: 600;
  text-decoration: none;
}

.search-field {
  position: absolute;
  right: 0;
  min-width: 9rem;
  z-index: 1;
}

@media screen and (min-width: 601px) and (max-width: 1919px) {
  .v-app-bar {
    padding: 0;
    margin-bottom: 1rem;
  }

  ::v-deep .v-toolbar__content {
    height: 73px !important;
  }
}

@media screen and (max-width: 600px) {
  .v-app-bar {
    padding: 0;
  }
}
</style>
