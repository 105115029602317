<template>
  <v-app>
    <MenuLateral></MenuLateral>

    <v-main>
      <Header></Header>
      <transition name="scroll-x-transition" mode="out-in">
        <router-view />
      </transition>
    </v-main>
  </v-app>
</template>

<script>
import MenuLateral from "@/components/EspacoDoColaborador/MenuLateral.vue";
import Header from "@/components/EspacoDoColaborador/Header.vue";

export default {
  data() {
    return {};
  },
  components: {
    Header,
    MenuLateral,
  },
};
</script>

<style scoped>
</style>
