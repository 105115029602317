const formatter = new Intl.NumberFormat("pt-BR", {
  style: "currency",
  currency: "BRL",
  minimumFractionDigits: 2,
});
function formatCurrency(val = 0.0) {
  return formatter.format(val + 0.0);
}

function removerMascara(valor) {
  return valor.replace("R$ ", "").replaceAll(".", "").replace(",", ".");
}

export { removerMascara, formatCurrency };
export default { removerMascara, formatCurrency };
