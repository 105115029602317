const JS = {
  formatarData(date) {
    if (date == null)
      return "";
    let d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [day, month, year].join('/');
  },
  calcularTempoDeCasa(dateAdm) {
    const dataHoje = new Date();
    const dataAdm = new Date(dateAdm);
    const diferenca = dataHoje - dataAdm;

    const diferencaEmDias = (diferenca / 1000) / 60 / 60 / 24;
    const horasRestantes = (diferenca / 1000) / 60 / 60 % 24;

    return (diferencaEmDias + (horasRestantes / 24)) / 365;
  },
  isCollapsed(tipo) {
    return this.tiposQuestaoCollapsed.includes(tipo);
  },
  expand(tipoQuestao) {
    if (this.isCollapsed(tipoQuestao))
      this.tiposQuestaoCollapsed = this.tiposQuestaoCollapsed.filter(item => item !== tipoQuestao);
    else
      this.tiposQuestaoCollapsed.push(tipoQuestao);
  },
};

export default JS;
