<template>
  <div>
    <loader :loader-primario="loader"></loader>

    <Alerta v-on:fecharModal="fecharModal" :exibir="modal.exibir" :titulo="modal.titulo" :mensagem="modal.mensagem" />

    <v-container>
      <div class="card">
        <div class="inicio-titulo">
          <v-img class="icone-titulo" src="../../assets/images/icone/espaco-colaborador/icone_visita.svg"></v-img>
          <h1 class="titulo">Visita Técnica</h1>
        </div>

        <div v-if="visitaTecnica || creditoDeslocamento">
          <v-tabs v-model="tab" grow hide-slider show-arrows>
            <v-tabs-slider></v-tabs-slider>
            <v-tab v-if="visitaTecnica" href="#tab-1" :class="tab == 'tab-1' ? 'tab tab-active' : 'tab'">
              Viagem Planejada
            </v-tab>
            <v-tab v-if="creditoDeslocamento" href="#tab-2" :class="tab == 'tab-2' ? 'tab tab-active' : 'tab'">
              Antecipação de Crédito
            </v-tab>
            <v-tab href="#tab-3" :class="tab == 'tab-3' ? 'tab tab-active' : 'tab'">Conta Bancária Alternativa </v-tab>
          </v-tabs>

          <v-tabs-items v-model="tab" class="mt-5">
            <v-tab-item v-if="visitaTecnica" value="tab-1">
              <VisitaTecninca />
            </v-tab-item>
            <v-tab-item v-if="creditoDeslocamento" value="tab-2">
              <CreditoDeslocamento />
            </v-tab-item>
            <v-tab-item value="tab-3">
              <ContaAlternativa />
            </v-tab-item>
          </v-tabs-items>
        </div>
        <div v-else>
          <h1>Não existem visitas e não há credito de deslocamento.</h1>
        </div>
      </div>
    </v-container>
  </div>
</template>

<script>
import loader from '@/components/Loader.vue';
import Alerta from '@/components/Alerta.vue';
import ModalMixin from '@/mixins/modalMixin';
import VisitaTecninca from '@/components/visita/VisitaTecninca.vue';
import CreditoDeslocamento from '@/components/visita/CreditoDeslocamento.vue';
import ContaAlternativa from '@/components/visita/ContaAlternativa.vue';
import ServiceVisitaTecnica from '@/services/ServiceVisitaTecnica';

export default {
  components: { ContaAlternativa, CreditoDeslocamento, VisitaTecninca, loader, Alerta },
  mixins: [ModalMixin],
  name: 'DashVisitaTecnica',
  data() {
    return {
      tab: null,
      loader: false,
      visitaTecnica: false,
      creditoDeslocamento: false,
    };
  },
  methods: {
    pegarResumo() {
      let vm = this;
      return ServiceVisitaTecnica.creditoResumo()
        .then(res => (vm.creditoDeslocamento = res.status == 200))
        .catch(err => {
          vm.mensagemErro(err);
          return (vm.creditoDeslocamento = false);
        });
    },
    buscarVistas() {
      let vm = this;
      return ServiceVisitaTecnica.listarVisitas()
        .then(res => (vm.visitaTecnica = res.data.visitas.length > 0))
        .catch(err => {
          vm.mensagemErro(err);
          return (vm.visitaTecnica = false);
        });
    },
    verificarDash() {
      let vm = this;
      vm.loader = true;
      Promise.all([vm.buscarVistas(), vm.pegarResumo()]).finally(() => {
        vm.loader = false;
      });
    },
  },
  mounted() {
    const vm = this;
    vm.verificarDash();
  },
};
</script>

<style scoped>
.card .v-tabs-bar {
  height: auto;
}

.tab {
  background-color: #edefee;
  border: 1px solid #edefee;
  border-radius: 12px;
  text-align: center;
  text-transform: none;
  letter-spacing: 0px;
  font: normal normal bold 16px/20px Montserrat;
  color: #787878 !important;
  margin: 0 1rem;
  padding: 1rem;
  width: 8rem;
}

.tab::before {
  border-radius: 12px;
}

.tab:hover {
  background-color: #eff9fa;
  border: 1px solid #278b97;
  color: #278b97 !important;
}

.tab-active {
  background-color: #278b97;
  border: 1px solid #278b97;
  color: #fff !important;
}
</style>
