<template>
  <v-dialog v-model="modalExperiencia" width="1800" @click:outside="fechaModal" @keydown.esc="fechaModal">
    <v-card>
      <v-card-title>
        <h3>Adicionar Experiencia {{ niveis[nivel - 1].descricao }}</h3>
        <v-icon @click="fechaModal()">fa-times</v-icon>
      </v-card-title>

      <v-card-text>
        <v-container v-if="!cadastrarSugestao" class="container-principal">
          <v-row class="nao-achou-experiencia">
            <p>
              Não achou sua experiência?
              <v-btn @click="cadastrarSugestao = true" class="botao-cliqueaqui mx-2">Clique aqui</v-btn>
              e fale conosco!
            </p>
          </v-row>
          <v-row>
            <v-col>
              <v-autocomplete
                @input="adicionarHabilidadeLista()"
                v-model="experienciaSelecionada"
                item-value="id"
                item-text="descricao"
                :items="experiencias"
                return-object
                label="Pesquise suas experiências"
                outlined
                dense
                hide-details
                class="rounded-xl"
              >
                <template slot="append">
                  <v-icon class="input-field-text">fas fa-chevron-down</v-icon>
                </template>
              </v-autocomplete>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <b class="d-flex justify-start">Encontradas:</b>
              <v-container class="container-experiencia">
                <p v-if="habilidades.length === 0">Pesquise para listar novas experiências</p>
                <p v-for="habilidade in habilidades" class="experiencias">
                  {{ habilidade.descricao }}
                  <v-icon @click="deletarExperiencia(habilidade)">fas fa-times</v-icon>
                </p>
              </v-container>
            </v-col>
          </v-row>

          <!-- Botões -->
          <v-card-actions>
            <v-row class="justify-center">
              <v-col cols="12" md="4">
                <v-btn @click="fechaModal()" class="botao-cancelar w-100">
                  <v-icon class="mr-2">fa-solid fa-xmark</v-icon>
                  Cancelar
                </v-btn>
              </v-col>
              <v-col cols="12" md="4">
                <v-btn @click="salvarExperiencia()" class="botao-principal w-100">
                  <v-icon class="mr-2">fa-solid fa-arrow-up-from-bracket</v-icon>
                  Salvar
                </v-btn>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-container>
        <v-container v-else class="container-principal">
          <v-row>
            <v-col cols="12">
              <p class="d-flex justify-start">Escreva a experiência que vc gostaria de adicionar em nosso sistema.</p>
            </v-col>
            <v-col cols="12" md="10">
              <v-text-field
                v-model="novaSugestao"
                @keydown.enter="adicionarSugestao"
                outlined
                dense
                hide-details
                class="rounded-xl"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="2">
              <v-btn @click="adicionarSugestao" class="botao-principal w-100">
                <v-icon class="mr-2">fas fa-plus</v-icon>
                Adicionar
              </v-btn>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-container class="container-experiencia">
                <p v-if="listaSugestoes.length === 0">Adicione novas sugestões de experiências</p>
                <p v-for="sugestao in listaSugestoes" class="experiencias">
                  {{ sugestao }}
                  <v-icon @click="removerSugestao(sugestao)">fas fa-times</v-icon>
                </p>
              </v-container>
            </v-col>
          </v-row>

          <!-- Botões -->
          <v-card-actions>
            <v-row class="justify-center">
              <v-col cols="12" md="4">
                <v-btn @click="cadastrarSugestao = false" class="botao-cancelar w-100">
                  <v-icon class="mr-2">fas fa-rotate-left</v-icon>
                  Voltar
                </v-btn>
              </v-col>
              <v-col cols="12" md="4">
                <v-btn @click="salvarSugestoes()" class="botao-principal w-100">
                  <v-icon class="mr-2">fas fa-arrow-up-from-bracket</v-icon>
                  Enviar
                </v-btn>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-container>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import api from '@/services/Api.js';
import modalMixin from '@/mixins/modalMixin';

export default {
  name: 'modal-certificacoes',
  mixins: [modalMixin],
  props: {
    nivel: {},
    value: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    modalExperiencia: false,
    habilidades: [],
    headers: [
      { text: 'Nome do arquivo', value: '' },
      { text: 'Documento', value: '' },
    ],
    experienciaSelecionada: null,
    experiencias: [],
    niveis: null,
    cadastrarSugestao: false,
    novaSugestao: '',
    listaSugestoes: [],
  }),
  watch: {
    value(e) {
      this.modalExperiencia = e;
      this.limparVariaveis();
    },
  },
  methods: {
    fechaModal() {
      this.cadastrarSugestao = false;
      this.$emit('fecha-modal');
    },
    limparVariaveis() {
      this.habilidades = [];
    },
    gestorCliente(e) {
      if (e) {
        return 'Sim';
      } else {
        return 'Não';
      }
    },
    dataAtualizacao(e) {
      if (e) {
        return e;
      } else {
        return 'Sem data de atualização!';
      }
    },
    tabelaVazia() {
      return 'Nenhum documento encontrado!';
    },
    buscarNiveis() {
      api
        .get(`experiencia/nivel`)
        .then(res => {
          this.niveis = res.data;
        })
        .catch(err => {
          this.$emit('erro', err);
        });
    },
    buscarExperiencias() {
      api
        .get(`experiencia`)
        .then(res => {
          this.experiencias = res.data;
        })
        .catch(err => {
          this.$emit('erro', err);
        });
    },
    adicionarHabilidadeLista() {
      let habilidade = {
        descricao: this.experienciaSelecionada.descricao,
        idNivel: this.nivel,
        idExperiencia: this.experienciaSelecionada.id,
      };
      this.habilidades = this.habilidades.filter(item => item.idExperiencia != habilidade.idExperiencia);
      this.habilidades.push(habilidade);
      this.experienciaSelecionada = [];
    },
    salvarExperiencia() {
      this.fechaModal();
      this.$emit('thisLoader', true);
      this.habilidades.forEach(habilidade => {
        api
          .post(`experiencia/pessoa/`, {
            idNivel: habilidade.idNivel,
            idExperiencia: habilidade.idExperiencia,
          })
          .then(() => {
            this.$emit('sucesso', 'Experiência cadastrada!');
          })
          .catch(err => {
            this.$emit('erro', err);
          })
          .finally(() => {
            this.$emit('thisLoader', false);
            this.$emit('buscarExperiencia');
          });
      });
    },
    deletarExperiencia(experiencia) {
      this.habilidades = this.habilidades.filter(item => item.idExperiencia != experiencia.idExperiencia);
    },
    adicionarSugestao() {
      const sugestao = this.novaSugestao.trim();
      if (sugestao === '') return;

      this.listaSugestoes.push(sugestao);
      this.novaSugestao = '';
    },
    removerSugestao(i) {
      this.listaSugestoes.splice(i, 1);
    },
    salvarSugestoes() {
      const tagsErro = [];
      const tagsSucesso = [];
      let total = this.listaSugestoes.length;

      this.listaSugestoes.forEach(experiencia => {
        api
          .post(`experiencia`, {
            experiencia: experiencia,
            idNivel: this.nivel,
          })
          .then(() => {
            tagsSucesso.push(experiencia);
          })
          .catch(e => {
            tagsErro.push(experiencia);
            console.error(e);
          })
          .finally(() => {
            this.listaSugestoes = [];
            total--;

            if (total === 0) {
              if (tagsSucesso.length > 0)
                this.$emit('sucesso', `Nova(s) experiência(s) (${tagsSucesso.join(',')}) cadastradas com sucesso!`);
              if (tagsErro.length > 0)
                this.$emit('erro', `Não foi possível cadastar a(s) tag(s): ${tagsErro.join(',')}.`);
            }
          });
      });
    },
  },
  mounted() {
    this.buscarNiveis();
    this.buscarExperiencias();
  },
};
</script>

<style scoped>
.container-principal {
  padding: 1.25rem 3rem 0;
}

.nao-achou-experiencia {
  padding: 0 1rem 1rem;
  justify-content: end;
  color: #5d5d5d;
  font-size: 1rem;
  font-weight: 600;
}

.container-experiencia {
  margin: 1rem 0 1.5rem;
  border: 1px solid #000;
  border-radius: 16px;
}

.experiencias {
  display: inline-flex;
  align-items: center;
  border-radius: 2rem;
  color: #fff !important;
  background-color: #278b97;
  border-color: #6c757d;
  padding: 0.375rem 0.75rem;
  margin: 0.5rem;
}

.experiencias .v-icon {
  color: #fff;
  font-size: 1rem;
  margin-left: 0.5rem;
}
</style>
