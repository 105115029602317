import { render, staticRenderFns } from "./ModalEdicaoDestaqueHome.vue?vue&type=template&id=57ae4d27&scoped=true"
import script from "./ModalEdicaoDestaqueHome.vue?vue&type=script&lang=js"
export * from "./ModalEdicaoDestaqueHome.vue?vue&type=script&lang=js"
import style0 from "./ModalEdicaoDestaqueHome.vue?vue&type=style&index=0&id=57ae4d27&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "57ae4d27",
  null
  
)

export default component.exports