<template>
  <v-container>
    <v-dialog v-model="show" min-width="500" @click:outside="fecharModal()">
      <v-card v-if="beneficio">
        <v-card-title>
          <h3>{{ tituloModal }}</h3>
          <v-icon @click="fecharModal()">fa-times</v-icon>
        </v-card-title>

        <v-card-text>
          <v-row v-show="beneficio.tipo.id !== tipoBeneficio.VALE_TRANSPORTE">
            <v-col>
              <h3>Atenção</h3>

              <v-divider class="my-3"></v-divider>

              <p class="text-justify mx-2">
                É importante ter conhecimento que, caso solicite a sua exclusão ou de algum dependente do Plano de
                Saúde/odontológico e depois queira retornar, haverá carência. Baixe o formulário, assine e depois anexe
                (arquivo com tamanho máximo de 5MB).
                <span style="color: #007bff">
                  <a href="https://helpx.adobe.com/br/acrobat/using/signing-pdfs.html" target="_blank"
                    >Segue link do tutorial de como preencher e assinar seu documento digitalmente.</a
                  >
                </span>
              </p>
            </v-col>
          </v-row>

          <v-form ref="form" lazy-validation>
            <v-col cols="12" v-show="beneficio.tipo.id !== tipoBeneficio.VALE_TRANSPORTE">
              <p class="text-left mb-0">Formulário de exclusão: <span style="color: red">*</span></p>
            </v-col>
            <v-col cols="12" v-show="beneficio.tipo.id !== tipoBeneficio.VALE_TRANSPORTE">
              <p class="text-left">
                <a @click="baixarFormularioExclusao()">Clique aqui para baixar o formulário de exclusão</a>
              </p>
            </v-col>
            <v-col cols="12" class="py-0" v-show="beneficio.tipo.id !== tipoBeneficio.VALE_TRANSPORTE">
              <v-file-input
                v-model="vModel.arquivo"
                label="Escolha um arquivo"
                prepend-icon
                prepend-inner-icon="fas fa-paperclip"
                outlined
                :rules="[
                  v =>
                    beneficio.tipo.id == tipoBeneficio.VALE_TRANSPORTE ||
                    !!v ||
                    'Obrigatório anexar o formulário de exclusão assinado',
                ]"
              ></v-file-input>
            </v-col>
            <v-col cols="12" class="py-0">
              <p class="text-left">Justificativa: <span style="color: red">*</span></p>
            </v-col>
            <v-col>
              <v-textarea
                v-model="vModel.justificativa"
                label="Informe a justificativa"
                clearable
                outlined
                :rules="[v => !!v || 'Obrigatório informar a justificativa']"
              />
            </v-col>
          </v-form>
        </v-card-text>

        <!-- Ações Modal -->
        <v-card-actions>
          <v-row>
            <v-col cols="12" md="3" offset-md="3">
              <v-btn @click="fecharModal()" class="botao-secundario-par w-100">Fechar</v-btn>
            </v-col>
            <v-col cols="12" md="3">
              <v-btn @click="cancelar()" class="botao-principal w-100">Solicitar</v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <alerta v-on:fecharModal="fecharModal" :exibir="modal.exibir" :titulo="modal.titulo" :mensagem="modal.mensagem" />

    <loader :loader-primario="loader" />
  </v-container>
</template>

<script>
import loader from '@/components/Loader.vue';
import alerta from '@/components/Alerta.vue';
import api from '@/services/Api.js';
import modalMixin from '@/mixins/modalMixin';
import { TipoBeneficioEnum } from '@/enums/beneficio/TipoBeneficioEnum.js';

export default {
  components: {
    alerta,
    loader,
    TipoBeneficioEnum,
  },
  mixins: [modalMixin],
  props: {
    value: Boolean,
    beneficio: {
      type: Object,
    },
    dependentes: {
      type: Array,
    },
    tipoMudanca: {
      type: Number,
    },
    tituloModal: {
      type: String,
      default: 'modal cancelar benefício',
    },
  },
  data() {
    return {
      tipoBeneficio: { ...TipoBeneficioEnum },
      vModel: {
        justificativa: null,
        arquivo: null,
      },
      loader: false,
    };
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },
  methods: {
    async fecharModal() {
      const vm = this;
      await vm.$refs['form'].reset();
      await Object.assign(this.$data, this.$options.data());
      vm.show = false;
    },
    baixarFormularioExclusao() {
      const vm = this;
      vm.loader = true;
      let url =
        vm.beneficio.tipo.id === TipoBeneficioEnum.PLANO_DE_SAUDE
          ? `documentos/emitir/exclusao-plano-saude`
          : `documentos/emitir/exclusao-plano-dental`;
      api
        .get(url, {
          responseType: 'blob',
        })
        .then(res => {
          const file = new Blob([res.data], {
            type: res.headers['content-type'],
          });

          const link = document.createElement('a');
          link.href = URL.createObjectURL(file);
          link.download = res.headers['content-disposition'];
          link.click();
          link.remove();
        })
        .catch(e => vm.mensagemErro(e.message))
        .finally(() => (vm.loader = false));
    },
    salvarDocumento(documento, idPedido) {
      const vm = this;
      let url = `beneficios/documento-pedido/` + idPedido;
      return api.post(url, documento).catch(e => {
        vm.mensagemErro(e.message);
      });
    },
    async cancelar() {
      const vm = this;
      if (!vm.$refs['form'].validate()) return;
      vm.loader = true;
      let url =
        (await vm.beneficio.tipo.id) === TipoBeneficioEnum.VALE_TRANSPORTE
          ? `beneficios/pedido/vale`
          : 'beneficios/pedido/plano';
      api
        .post(url, vm.montarPedido())
        .then(async response => {
          if (vm.beneficio.tipo.id !== TipoBeneficioEnum.VALE_TRANSPORTE) {
            let idPedido = response.data.id;
            let documento = this.montarFormData(vm.vModel.arquivo, 921);
            await vm.salvarDocumento(documento, idPedido);
          }
          vm.$emit('sucesso');
          vm.fecharModal();
        })
        .catch(() => vm.mensagemErro('Erro ao fazer pedido.'))
        .finally(async () => (vm.loader = false));
    },
    montarPedido() {
      const vm = this;
      var obj = {
        beneficio: {
          id: vm.beneficio.id,
        },
        tipoMudanca: {
          id: vm.tipoMudanca,
        },
        justificativa: vm.vModel.justificativa,
        dependentes: vm.dependentes,
      };
      return obj;
    },
    montarFormData(vModel, codDominio) {
      let anexoFormData = new FormData();
      anexoFormData.append('arquivo', vModel);
      anexoFormData.append('nomeArquivo', vModel.name);
      anexoFormData.append('formatoArquivo', vModel.type);
      anexoFormData.append('codDominio', codDominio);
      return anexoFormData;
    },
  },
};
</script>

<style scoped>
.v-dialog > .v-card > .v-card__text {
  padding: 1rem 2rem !important;
}

.input-field::v-deep .v-input__slot {
  background-color: #ddd !important;
}
</style>
