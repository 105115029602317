<template>
  <div>
    <Loader :loader-primario="loader" />

    <Alerta v-on:fecharModal="fecharModal" :exibir="modal.exibir" :titulo="modal.titulo" :mensagem="modal.mensagem" />

    <CadastroDeslocamento
      ref="dlct"
      :idDeslocamento="idDeslocamento"
      @close="idDeslocamento = null"
      @erro="mensagemErro"
      @loader="event => (this.loader = event)"
      @sucesso="novoDeslocamento"
    />

    <v-container>
      <v-row class="justify-end">
        <v-col cols="12" md="6" xl="2">
          <v-btn @click="$refs['dlct'].dialog = true" class="botao-principal w-100">
            <v-icon class="mr-2">fas fa-circle-plus</v-icon>
            Cadastrar Nova Viagem
          </v-btn>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <v-data-table
            :headers="headersDeslocamentos"
            :items="paginator.lista"
            :items-per-page="filtro.perPage"
            no-data-text="Não existem dados"
            hide-default-footer
            @update:options="options = $event"
          >
            <template #[`item.dataCriacao`]="{ item }">
              {{ formatDate(item.dthVisita.split('T')[0]) }}
            </template>

            <template #[`item.acao`]="{ item }">
              <v-icon @click="editarDeslocamento(item.id)" class="icone-acao">fas fa-pen</v-icon>
            </template>
          </v-data-table>
        </v-col>

        <v-col cols="12">
          <v-pagination
            v-model="paginator.page"
            @input="getPage"
            prev-icon=" fa-light fa-chevron-left"
            next-icon="fa-light fa-chevron-right"
            :length="Math.ceil(paginator.itens / filtro.perPage)"
            circle
          ></v-pagination>
        </v-col>
      </v-row>

      <v-row class="justify-end">
        <v-col cols="auto">
          <h4>Saldo:</h4>
          R$: {{ resumo.saldoAtual }} reais.
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import ServiceVisitaTecnica from '@/services/ServiceVisitaTecnica';
import ModalMixin from '@/mixins/modalMixin';
import Loader from '@/components/Loader.vue';
import Alerta from '@/components/Alerta.vue';
import CadastroDeslocamento from '@/components/visita/CadastroDeslocamento.vue';
import { formatDate } from '@/utils/Utils';

export default {
  name: 'CreditoDeslocamento',
  components: { CadastroDeslocamento, Loader, Alerta },
  mixins: [ModalMixin],
  data() {
    return {
      idDeslocamento: null,
      resumo: {},
      headersDeslocamentos: [
        { text: 'Nº chamado', value: 'ticket' },
        { text: 'Origem', value: 'enderecoOrigem' },
        { text: 'Destino', value: 'enderecoDestino' },
        { text: 'Data', value: 'dataCriacao' },
        { text: 'Despesas', value: 'totalGasto' },
        { text: 'Edição', value: 'acao', align: 'center' },
      ],
      paginator: {
        lista: [],
      },
      filtro: {
        perPage: 5,
      },
      loader: false,
    };
  },
  methods: {
    formatDate,
    editarDeslocamento(idDeslocamento) {
      let vm = this;
      vm.idDeslocamento = idDeslocamento;
      vm.$refs['dlct'].dialog = true;
    },
    novoDeslocamento(deslocamento) {
      let vm = this;
      vm.mensagemSucesso(`Deslocamento de ${deslocamento.origem} a ${deslocamento.destino} registrado com sucesso.`);
      vm.buscarDeslocamentos();
      vm.buscarResumo();
    },
    buscarResumo() {
      let vm = this;

      vm.loader = true;
      ServiceVisitaTecnica.creditoResumo()
        .then(res => (vm.resumo = res.data))
        .catch(vm.mensagemErro)
        .finally(() => (vm.loader = false));
    },
    buscarDeslocamentos() {
      let vm = this;
      vm.loader = true;
      ServiceVisitaTecnica.deslocamentos({
        page: vm.filtro.page,
        itens: vm.filtro.perPage,
      })
        .then(res => (vm.paginator = res.data))
        .catch(vm.mensagemErro)
        .finally(() => {
          vm.loader = false;
        });
    },
    getPage(page) {
      let vm = this;
      vm.filtro.page = page;
      vm.buscarDeslocamentos();
    },
  },
  mounted() {
    let vm = this;
    vm.buscarResumo();
    vm.buscarDeslocamentos();
  },
};
</script>
