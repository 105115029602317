<template>
  <v-menu
    v-model="menu"
    transition="scale-transition"
    offset-y
    min-width="auto"
    :close-on-content-click="false"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        v-model="formatted"
        :label="label"
        :loading="loading"
        :rules="[v => validarData || `Informe ${label}`]"
        :disabled="disabled"
        v-bind="attrs"
        v-on="on"
        color="#278b97"
        append-icon="fa-calendar"
        readonly
        outlined
        dense
        hide-details="auto"
        :clearable="clearable"
        @click:clear="date = null"
      ></v-text-field>
    </template>
    <v-date-picker
      ref="picker"
      v-model="date"
      :active-picker.sync="p"
      :allowed-dates="allowedDates"
      :min="min"
      :max="max"
      :events="events"
      :show-current="min ? min : true"
      event-color="#278b97"
      no-title
      scrollable
      landscape
      color="#278b97"
      :type="tipo"
      :class="{ 'remover-borda-current': min }"
    >
      <v-spacer></v-spacer>
      <v-btn text color="#278b97" @click="date = null" v-if="botaoLimpar">
        Limpar
      </v-btn>
    </v-date-picker>
  </v-menu>
</template>
<script>
function _format(d) {
  return (!d ? "" : d).split("-").reverse().join("/");
}
export default {
  props: {
    value: String,
    loading: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      required: true,
      default: "Data",
    },
    min: {
      type: String,
      default: null,
    },
    max: {
      type: String,
      default: null,
    },
    allowedDates: {
      type: Function,
      default: null,
    },
    events: {
      type: [Array, Function, Object],
      default: () => null,
    },
    format: {
      type: Function,
    },
    picker: {
      type: String,
    },
    tipo: {
      type: String,
      default: "date",
    },
    validacao: {
      type: Boolean,
      default: false,
    },
    botaoLimpar: {
      type: Boolean,
      default: false,
    },
    clearable: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      date: "",
      formatted: "",
      menu: false,
      p: "DAY",
    };
  },
  computed: {
    validarData() {
      const vm = this;
      let valid = true;
      if (vm.validacao) {
        if (!vm.date) valid = false;
      }
      return valid;
    },
  },
  watch: {
    date(novo) {
      this.$emit("input", novo);
      this.formatted =
        !this.format || !novo ? _format(novo) : this.format(novo);
    },
    menu(val) {
      val && !!this.picker && setTimeout(() => (this.p = this.picker || "DAY"));
    },
    value(valor) {
      this.date = valor;
    },
  },
  methods: {
    resetTableDate() {
      if (this.$refs.picker) {
        this.$refs.picker.tableDate = this.min
          ? this.min
          : new Date().toISOString().split("T")[0];
      }
    },
  },
};
</script>

<style>
.remover-borda-current
  .v-date-picker-table
  .v-btn.v-date-picker-table__current.v-btn--rounded.v-btn--outlined {
  color: #000 !important;
  border: none !important;
}
</style>
