<template>
  <div id="login">
    <Loader :loader-primario="loader"></Loader>
    <Alerta
      v-on:fecharModal="fecharModal"
      :exibir="modal.exibir"
      :titulo="modal.titulo"
      :mensagem="modal.mensagem"
    >
    </Alerta>
    <v-app>
      <v-main>
        <v-container fluid class="altura">
          <v-row class="altura">
            <v-col class="background-foto" cols="12" sm="6"></v-col>
            <v-col class="background-login px-0" cols="12" sm="6">
              <div class="logo">
                <v-img
                  v-if="$vuetify.breakpoint.xsOnly"
                  src="../assets/logo.png"
                  width="100%"
                  max-width="325px"
                  class="mx-auto"
                >
                </v-img>
                <v-img
                  v-else
                  src="../assets/logo.png"
                  width="100%"
                  max-width="325px"
                  class="mx-auto"
                >
                </v-img>
              </div>
              <div class="login">
                <v-text-field
                  v-model="usuario.login"
                  label="Seu login"
                  placeholder="Digite seu login"
                  outlined
                  :dark="$vuetify.breakpoint.xsOnly"
                  hide-details="auto"
                  class="mb-3"
                  color="#278b97"
                ></v-text-field>
                <v-text-field
                  v-model="usuario.senha"
                  :type="mostrarSenha ? 'text' : 'password'"
                  label="Sua senha"
                  placeholder="Digite sua senha"
                  outlined
                  :dark="$vuetify.breakpoint.xsOnly"
                  hide-details="auto"
                  class="mb-3"
                  @keyup.enter="autenticar"
                  color="#278b97"
                >
                  <v-icon
                    slot="append"
                    color="gray"
                    small
                    @click="mostrarSenha = !mostrarSenha"
                  >
                    {{ mostrarSenha ? "fas fa-eye" : "fas fa-eye-slash" }}
                  </v-icon>
                </v-text-field>
                <v-btn
                  @click="autenticar"
                  block
                  large
                  color="#278b97"
                  outlined
                  id="btn-login"
                >
                  Logar
                </v-btn>
                <div class="checkbox">
                  <v-checkbox
                    small
                    v-model="lembrar"
                    class="lembrarSenha"
                    label="Lembrar senha"
                    color="#278b97"
                  ></v-checkbox>
                </div>
              </div>

              <div class="texto">
                <div class="letreiro">
                  <h1>
                    Bem
                    <span> vindo</span>
                  </h1>
                </div>
                <h4 class="bemvindo">Bem-vindo(a)</h4>
                <div class="segundo-letreiro">
                  <h1>
                    <span> Bem </span>
                    vindo
                  </h1>
                </div>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </v-main>
    </v-app>
  </div>
</template>
<script>
import Loader from "../components/Loader.vue";
import api from "../services/Api.js";
import modalMixin from "../mixins/modalMixin";
import Alerta from "../components/Alerta.vue";
import authService from "@/services/AuthService";
export default {
  components: { Loader, Alerta },
  mixins: [modalMixin],
  name: "Login",
  data: () => ({
    loader: false,
    mostrarSenha: false,
    lembrar: false,
    usuario: {
      login: "",
      senha: "",
    },
  }),
  methods: {
    async autenticar() {
      let vm = this;
      var loginUsuario = vm.usuario;
      if (loginUsuario.login.includes("@hepta.com.br")) {
        loginUsuario.login = loginUsuario.login.split("@")[0];
      }
      if (vm.lembrar) {
        let usuario = JSON.stringify(loginUsuario);
        let lembrar = JSON.stringify(vm.lembrar);
        usuario = btoa(usuario);
        localStorage.setItem("lembrar", lembrar);
        localStorage.setItem("login", usuario);
      } else {
        if (!localStorage.login) localStorage.removeItem("login");
      }

      try {
        await authService.login(this.usuario);
        this.$router.push({ name: "Home" });
      } catch (e) {
        this.mensagemErro(e);
      }
    },
  },
  created() {},
  mounted() {
    const vm = this;
    if (localStorage.login) {
      let usuario = atob(localStorage.login);
      vm.usuario = JSON.parse(usuario);
      vm.lembrar = JSON.parse(localStorage.lembrar);
    }
  },
};
</script>

<style scoped>
.background-foto {
  background-image: url("../assets/images/login/background_login.png");
  background-size: cover;
}

.altura {
  min-height: 100vh;
  overflow: hidden;
}

.background-login {
  background-color: #e9e9e9;
  display: flex;
  flex-direction: column;
}

.espacamento {
  display: flex;
  flex-direction: column;
  justify-content: end;
  align-items: center;
  padding-bottom: 25%;
  height: calc(100% - 248px);
}

.login {
  min-width: 65%;
  margin: 8% auto;
}

.link {
  text-decoration: none;
}

.logo {
  margin-top: 11%;
  width: 100%;
}

.texto {
  margin-top: auto;
}

.botao-logar,
.botao-logar * {
  cursor: pointer;
}

.letreiro {
  color: #008693 !important;
  font-family: "Montserrat";
  font-weight: 900;
  text-transform: uppercase;
  opacity: 0.1;
  text-align: center;
}

.letreiro h1,
.segundo-letreiro h1 {
  font-size: 9rem;
  color: #008693 !important;
  line-height: 0.7;
}

.letreiro span {
  text-shadow: -1px -1px 0px #008693, -1px 1px 0px #008693, 1px -1px 0px #008693,
    1px 0px 0px #008693;
  color: #e9e9e9;
  font-size: 9rem;
}

.segundo-letreiro {
  color: #008693;
  font-family: "Montserrat";
  font-weight: 900;
  text-transform: uppercase;
  font-size: 2rem;
  opacity: 0.1;
  text-align: center;
}

.segundo-letreiro span {
  text-shadow: -1px -1px 0px #008693, -1px 1px 0px #008693, 1px -1px 0px #008693,
    1px 0px 0px #008693;
  color: #e9e9e9;
  font-size: 9rem;
}

.bemvindo {
  color: #008693;
  font-family: "Montserrat";
  font-weight: 500;
  text-transform: uppercase;
  font-size: 15px;
  letter-spacing: 27px;
  text-align: center;
}

.theme--dark.v-icon {
  color: #000000 !important;
}

.lembrarSenha .v-input__slot {
  background-color: transparent !important;
  border-color: transparent !important;
}

.checkbox {
  display: flex;
  justify-content: right;
}

.checkbox .theme--light.v-label {
  position: initial !important;
}

/*Responsivo*/
@media only screen and (max-width: 600px) {
  .background-foto {
    display: none;
  }

  .background-login {
    background-size: cover !important;
    height: 100vh;
    background: linear-gradient(to bottom, hsl(0deg 0% 8% / 50%), #141414),
      url("../assets/images/login/background_login.png");
    background-position: center;
  }

  .login {
    min-width: 80%;
    margin: auto;
  }

  .bemvindo {
    letter-spacing: 10px;
  }

  .letreiro h1,
  .segundo-letreiro h1 {
    font-size: 3rem;
    color: #ffffff !important;
  }

  .letreiro span,
  .segundo-letreiro span {
    text-shadow: -1px -1px 0px #ffffff, -1px 1px 0px #ffffff,
      1px -1px 0px #ffffff, 1px 0px 0px #ffffff;
    font-size: 3rem;
    color: #000000;
  }

  .bemvindo {
    color: #ffffff;
  }
}

@media (min-width: 601px) and (max-width: 960px) {
  .bemvindo {
    letter-spacing: 10px;
  }

  .letreiro {
    font-size: 2rem;
  }

  .segundo-letreiro {
    font-size: 2rem;
  }

  .logo {
    margin-top: 50%;
  }

  .login {
    min-width: 90%;
    margin: 20% auto;
  }

  .letreiro h1,
  .segundo-letreiro h1,
  .letreiro span,
  .segundo-letreiro span {
    font-size: 3rem;
  }
}

/*--------------------------------------- */
@media (min-width: 601px) and (max-width: 778px) {
  .letreiro {
    font-size: 1.5rem;
  }

  .segundo-letreiro {
    font-size: 1.5rem;
  }
}

@media (min-width: 961px) and (max-width: 1264px) {
  .letreiro h1,
  .segundo-letreiro h1,
  .letreiro span,
  .segundo-letreiro span {
    font-size: 4rem;
  }

  .bemvindo {
    letter-spacing: 16px;
  }
}

@media (min-width: 1265px) and (max-width: 1700px) {
  .bemvindo {
    letter-spacing: 16px;
  }

  .letreiro h1,
  .segundo-letreiro h1,
  .letreiro span,
  .segundo-letreiro span {
    font-size: 6rem;
  }
}

@media (min-width: 1700px) {
  .bemvindo {
    letter-spacing: 16px;
  }

  .letreiro h1,
  .segundo-letreiro h1,
  .letreiro span,
  .segundo-letreiro span {
    font-size: 8rem;
  }
}
</style>
