<template>
    <v-dialog v-model="modal" width="1800" @click:outside="fechaModal()">

        <v-card>
            <v-card-title>
                <h5> {{ artigo.titulo }} </h5>
                <v-icon small @click="fechaModal()"> fa-times </v-icon>
            </v-card-title>

            <v-card-text>
               <h2>{{ artigo.descricao }}</h2>
               <div class="conteudo">
                {{ artigo.conteudo }}
               </div>
        </v-card-text>
        </v-card>

    </v-dialog>
</template>

<script>
import api from "@/services/Api.js";
export default {
    name: "modal-certificacoes",
    props: {
        artigo: {},
        value: {
            type: Boolean,
            default: false,
        },
    },
    data: () => ({
        modal: false,
        download: false,
        arquivoConteudo: null,
        arquivo: {},
        headers: [
            { text: "Nome do arquivo", value: "" },
            { text: "Documento", value: "" },
        ],
        ufs: [],
        certificacoes:[],
        dataEmissao:false,
        dataValidade:false,
        emissao:null,
        validade:null,
        dataAtestado: false,
        filtroDataAtestado: null,
    }),
    methods: {
        fechaModal() {
            this.$emit("fecha-modal");
        },
        limparVariaveis() {
        },
        gestorCliente(e) {
            if (e) {
                return "Sim";
            }
            else {
                return "Não";
            }
        },
        dataAtualizacao(e) {
            if (e) {
                return e;
            }
            else {
                return "Sem data de atualização!";
            }
        },
        tabelaVazia() {
            return "Nenhum documento encontrado!";
        },
        buscarUfs() {
            const vm = this;
            api.get("v2/dominio/uf").then(response => {
                response.data.forEach(e => {
                    vm.ufs.push(e.descricaoDominio);
                });
            });
        }
    },
    watch:{
        artigo(novo) {

            // var htmlObject = temp.firstChild;
        },
        value(e) {
            this.modal = e;
        },
    }
}
</script>
<style scoped>
.divider-tela {
    margin: 30px;
    opacity: 1.0;
    justify-items: center;
}

.estilo {
    border-radius: 10px;
    opacity: 1;
}

.espacamento {
    padding: 20px 20px 0px 20px;
    background-color: #e9e9e9;
}

.textAreaCursos{
    border: 1px solid;
    padding: 11px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.espacamento-v-col {
    padding: 5px;
}

.instituicao{
    display: flex;
    margin: -15px 0px 0px 5px;
}
.instituicao p{
    color: #5d5d5d9c !important;
    text-align: left !important;
    font-size: 12px;
}
.display {
    display: flex;
    justify-content: space-between;
}

.espacamento-v-col2 {
    padding: 12px !important;
}

.baixar {
    display: inline;
    margin-left: 5px;
    cursor: pointer;
}


.espacamento-esquerda {
    padding-left: 10px;
}

.espacamento-direita {
    padding-right: 10px;
}

.espacamento-top {
    padding-top: 20px;
}

.btn-baixar {
    cursor: pointer;
}

.btn-baixar>.baixar:hover {
    text-shadow: 0 0 0.05em #757575b3;
    color: #278b97 !important;
}

.v-list-item>.v-application {
    color: #278b97 !important;
    caret-color: #278b97 !important;
}

.divider-tela {
    margin: 30px;
    opacity: 1.0;
    justify-items: center;
}

.icone-alerta {
    width: 49px;
    height: 43px;
    margin-left: 50px;
}

.alerta {
    width: 880px;
    margin: 10px 0px 40px 0px;
    height: 107px;
    background: #d7dad9 0% 0% no-repeat padding-box;
    border-radius: 38px;
    gap: 40px;
    align-items: center;
    display: inherit;
    opacity: 0.85;
}

.borda {
    font-size: 16px;
    border-left: 3px solid;
    border-radius: 3px;
    padding-left: 10px;
    color: #008693;
    text-align: left;
}

.subtitulo-borda {
    color: #008693 !important;
    font: Montserrat, Bold;
}

.titulo-borda {
    color: #5D5D5D !important;
}

.descricao {
    padding: 0px 40px 0px 40px !important;
    text-align: justify !important;
}

.icone {
    width: 15px;
    height: 15px;
    clip-path: polygon(100% 50%, 0 0, 0 100%);
    background: #E7B814 0% 0% no-repeat padding-box;
    opacity: 1;
}

.mensagem-alerta {
    color: #5d5d5d9c !important;
    text-align: left !important;
    font-size: 12px;
    padding-left: 36px;
}

.texto-icone {
    text-align: left;
    font: normal normal bold 15px/19px Montserrat;
    letter-spacing: 0px;
    color: #E7B814;
    text-transform: uppercase;
    opacity: 1;
}

.paragrafo {
    display: flex;
    gap: 10px;
    padding-left: 40px;
}

.espacamento-row {
    padding-top: 15px;
}

.v-dialog>.v-card>.v-card__text {
    background-color: #e9e9e9;
    padding: 20px 0px 0px 0px !important;
}

.v-dialog>.v-card>.v-card__actions {
    padding: 15px !important;
}

.theme--light .v-list {
    background-color: #f7f7f7 !important;
}

.espacamento {
    padding: 20px;
    background-color: #e9e9e9;
}

.espacamento-v-col {
    padding: 5px;
}


.espacamento-v-col2 {
    padding: 12px !important;
}

</style>


