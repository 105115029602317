export default {
  data() {
    return {
      modal: { exibir: false, titulo: "", mensagem: "" },
      exclusaoConfirmada: null,
      okCallback: () => {},
      EnumTipoMsg: Object.freeze({
        Erro: "Erro!",
        Sucesso: "Sucesso!",
        Confirmacao: "Confirmação!",
        ConfirmaSolicitacao: "Confirmar Solicitação!",
      }),
    };
  },
  methods: {
    mostrarModal(titulo, mensagem) {
      // const timeout = 2500;
      this.modal.exibir = true;
      this.modal.titulo = titulo;
      this.modal.mensagem = mensagem;
      // setTimeout(() => {
      //   this.modal.exibir = false;
      // }, timeout);
    },
    fecharModal() {
      this.modal.exibir = false;
      this.exclusaoConfirmada = null;
    },
    mensagemErro(error) {
      const mensagemModal = this.parseMensagemError(error);
      return this.mostrarModal(this.EnumTipoMsg.Erro, mensagemModal);
    },
    mensagemSucesso(mensagem) {
      return this.mostrarModal(this.EnumTipoMsg.Sucesso, mensagem);
    },
    mensagem(tipo, mensagem) {
      return this.mostrarModal(tipo, mensagem);
    },
    parseMensagemError(error) {
      if (error.message == "Network Error") return error?.response?.data;

      if (error?.response?.status === 404) return error.response.data;

      if (error?.response?.headers["content-type"] != "application/json")
        return "Erro Interno do Servidor!";

      return error.response.data.mensagem || error.response.data;
    },

    //modal sem timer para mensagem de confirmação
    mostrarModalExclusao(titulo, mensagem) {
      this.modal.exibir = true;
      this.modal.titulo = titulo;
      this.modal.mensagem = mensagem;
    },
    confirmarExclusao(mensagem, idExclusao) {
      this.exclusaoConfirmada = idExclusao;
      this.mostrarModalExclusao(this.EnumTipoMsg.Confirmacao, mensagem);
    },
    confirmarSolicitacao(mensagem) {
      this.mostrarModalExclusao(this.EnumTipoMsg.ConfirmaSolicitacao, mensagem);
    },
  },
};
