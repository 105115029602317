<template>
    <div>
        <v-dialog v-model="value" width="1800" @click:outside="fechaModal()">
            <v-card>
                <v-card-title>
                  <h3>Editar destaque da biblioteca</h3>
                  <v-icon @click="fechaModal()">fa-times</v-icon>
                </v-card-title>
        
                <v-card-text>
                  <v-container class="px-8">
                    <v-row>
                      <v-col cols="12">
                        <v-text-field v-model="destaque.titulo" label="Título" outlined dense></v-text-field>
                      </v-col>
                      <v-col cols="12">
                        <v-textarea v-model="destaque.descricao" label="Descrição" outlined dense></v-textarea>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
                <v-card-actions class="justify-center">
                  <v-btn class="botao-cancelar" min-width="25%" @click="fechaModal()">Cancelar</v-btn>
                  <v-btn class="botao-principal" min-width="25%" @click="editar()">Editar</v-btn>
                </v-card-actions>
              </v-card>
        </v-dialog>
    </div>

</template>

<script>
import api from "@/services/Api.js";
export default {
  name: "modal-edicao-destaque",
  props: {
    destaque: {},
    value: Boolean
  },
  data() {
    return {
  }
},
  methods: {
    fechaModal() {
      this.$emit("fecha-modal");
      this.destaque = {}
    },
    editar(){
        const vm = this;
        api.post("destaque-biblioteca/atualizar", vm.destaque)
        .then(() => {
            vm.$emit("sucessoEdicao");
        }).catch((e) =>{
            vm.$emit("erro",e);
        }).finally(()=>{
            vm.fechaModal();
        })
    }
  },
  
};
</script>

<style scoped>

</style>