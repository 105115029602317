<template>
  <div>
    <v-card class="card-aniversariantes">
      <v-card-text>
        <v-row>
          <v-col cols="auto" class="py-0">
            <v-avatar v-if="aniversariante.imagemBase64 == null" size="60">
              <v-img src="@/assets/images/icone/icone_aniversariante_padrao.png"></v-img>
            </v-avatar>
            <v-avatar v-else size="60">
              <img id="base64image" :src="'data:image/png;base64, ' + aniversariante.imagemBase64" />
            </v-avatar>
          </v-col>
          <v-col cols="auto" class="text-center">
            <h1 class="dia-aniversario">{{ diaData(aniversariante.aniversario) }}</h1>
            <span class="mes-aniversario">{{ mesData(aniversariante.aniversario) }}</span>
          </v-col>
          <v-col cols="12" md="8" xl="9" class="pl-0">
            <div class="informacoes-aniversariante">
              <h1>{{ aniversariante.nome }}</h1>
              <h6>{{ aniversariante.cargo }} - {{ aniversariante.lotacao }}</h6>
            </div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

  </div>
</template>

<script>
export default {
  props: {
    aniversariante: Object
  },
  data() {
    return {
      admin: false,
      src: '',
    };
  },
  methods: {
    mesData(data) {
      const mes = data.split("-")[1];
      switch (mes) {
        case "01":
          return "JAN";
        case "02":
          return "FEV";
        case "03":
          return "MAR";
        case "04":
          return "ABR";
        case "05":
          return "MAI";
        case "06":
          return "JUN";
        case "07":
          return "JUL";
        case "08":
          return "AGO";
        case "09":
          return "SET";
        case "10":
          return "OUT";
        case "11":
          return "NOV";
        case "12":
          return "DEZ";
        default:
          return "Erro";
      };
    },
    diaData(data) {
      const dia = data.split("-")[2];
      return dia;
    },
    fraseDoDia() {
      let frases = ["O dia está tão gostoso que poderíamos chamá-lo de torta 🎂! Feliz aniversário!!!",
        "Alegria, alegria, que dia mais feliz!!!",
        "Chega n@ crush e fala: - No seu aniversário, eu não vou te dar um presente... vou te dar o futuro.",
        "Por onde andam aqueles pedidos que a gente fez quando apagamos a velinha no aniversário? 🕯❓😐",
        "A simplicidade de uma palavra pode ser inspiradora: Parabéns. ❤❤",
        "A vida deve ser como o vinho: ficar melhor com o passar dos anos! 😉",
        "Rabo de tatu, pena de galinha, quem está ficando mais velho da uma risadinha."]
      let dataAtual = new Date();
      return frases[dataAtual.getDay()]
    },
  },
  created() {

  },

};
</script>

<style scoped>
.card-aniversariantes {
  border-radius: 5rem 1rem 1rem 5rem;
  margin-bottom: 1rem;
  box-shadow: 0px 0px 28px 0px #00000026 !important;
  white-space-collapse: break-spaces;
}

.card-aniversariantes .row {
  align-items: center;
}

.dia-aniversario {
  font-size: 1.5rem !important;
}

.mes-aniversario {
  font-weight: 900;
  font-size: 1rem;
}

.titulo {
  text-align: center;
  margin-bottom: 2.5rem;
}

.informacoes-aniversariante {
  border-left: 1px solid #278b97;
  padding-left: 1rem;
}

.informacoes-aniversariante h1 {
  font-size: 1rem;
}

.informacoes-aniversariante h6 {
  font-size: .75rem;
}

@media screen and (max-width: 1279px) {
  .card-aniversariantes {
    border-radius: 1rem;
    padding: 1rem .5rem;
  }

  .card-aniversariantes .row {
    display: flex;
    justify-content: center;
  }

  .informacoes-aniversariante {
    border-left: none;
    text-align: center;
  }
}
</style>