<template>
    <div>
        <!-- Icones Linkedin, Facebook, Intagram e Youtube -->
        <v-footer padless>
            <v-card width="100%" color="#278B97" class="pa-3 text-center">
                <v-icon class="pa-5" size="18" color="white"
                    @click="redirecionar('https://www.linkedin.com/company/hepta-tecnologia/mycompany/')">fa-brands
                    fa-linkedin-in </v-icon>
                <v-icon class="pa-5" size="18" color="white"
                    @click="redirecionar('https://www.facebook.com/heptatecnologia')">fa-brands fa-facebook </v-icon>
                <v-icon class="pa-5" size="18" color="white"
                    @click="redirecionar('https://www.instagram.com/heptatecnologia/')">fa-brands fa-instagram</v-icon>
                <v-icon class="pa-5" size="18" color="white"
                    @click="redirecionar('https://www.youtube.com/heptatecnologia')">fa-brands fa-youtube</v-icon>
            </v-card>

        </v-footer>
    </div>
</template>
<script>
export default {
    data: () => ({
    }),
    methods: {
        redirecionar(URL) {
            window.open(URL, '_blank')
        }
    }
}
</script>
<style scoped></style>
