<template>
  <div>
    <v-dialog v-model="modalAlterarPdf" width="1800" @click:outside="fechaModal()">
      <v-card>
        <v-card-title>
          <h5>{{ pdf.tituloLocal }}</h5>
          <v-icon small @click="fechaModal()"> fa-times </v-icon>
        </v-card-title>

        <v-card-text>
          <v-container class="px-8">
            <v-row>

              <v-col cols="12">
                <p class="pre-visu" v-if="pdfUrl">Pré-visualização</p>
                <v-card>
                  <iframe v-if="pdfUrl" :src="pdfUrl" width="100%" height="500"></iframe>
                </v-card>
              </v-col>

              <v-col cols="12">
                <v-file-input v-model="arquivo" accept="pdf/*" @change="anexarArquivo(arquivo)" label="PDF" outlined dense
                  class="estilo" hide-details="auto"></v-file-input>
              </v-col>
              <v-col cols="12" v-if="comLink">
                <v-text-field v-model="pdf.linkEncaminhar" label="Link" outlined dense class="estilo"
                  hide-details="auto"></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions class="justify-center">
          <v-btn class="botao-cancelar" min-width="25%" @click="fechaModal()">Cancelar</v-btn>
          <v-btn class="botao-principal" min-width="25%" @click="editar()">Salvar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import api from "@/services/Api.js";
export default {
  name: "modal-pdf",
  props: {
    modalAlterarPdf: Boolean,
    comLink: {
      type: Boolean,
      default: true,
    },
    pagina: String,
    pdf: Object,
    customMethod: {
      type: Function,
      required: true,
    },
  },
  data: () => ({
    arquivoPdf: null,
    arquivo: {},
    arquivob64: null,
    tipoArquivo: null,
    loader: false,
    file: null,
    pdfUrl: null,
  }),
  watch: {
    modalAlterarPdf() {
      /*    if (this.pdf.arquivo != null) {
           this.visualizarPdf(this.pdf.id);
   
           this.arquivo = new File([this.pdf.arquivo], this.pdf.nomeArquivo);
         } */
    },
    arquivo(e) {
      if (e == null) {
        this.pdfUrl = null;
      } else {
        this.onFileChange(e);
      }
    }
  },
  methods: {
    preparaDadosEnvio(dadosEnvio, arquivo) {
      let pdfFormData = new FormData();

      this.pdf.dataUltimaModificacao = null;
      this.pdf.idPessoalModificadora = null;

      if (arquivo) {
        pdfFormData.append("filePdf", arquivo);
      }
      dadosEnvio.arquivo = "";
      pdfFormData.append("pdf", JSON.stringify(dadosEnvio));
      return pdfFormData;
    },
    editar() {
      const vm = this;
      vm.loader = true;
      const dadosEditados = vm.preparaDadosEnvio(vm.pdf, vm.arquivoPdf);
      api
        .post("pdf/editar", dadosEditados, {
          headers: { Authorization: localStorage.token },
          withCredentials: true
        }).then(() => {
          vm.$emit("atualizar", vm.pdf.id, vm.pagina);
          vm.fechaModal();
          vm.$emit('mensagem', 'Sucesso!', "PDF editado com sucesso");
        })
        .catch((e) => {
          vm.$emit('mensagem', 'Erro!', e);
        }).finally(() => {
          vm.loader = false;
        })
    },
    anexarArquivo(arquivo) {
      const vm = this;
      if (arquivo?.size) {
        vm.arquivoPdf = arquivo;
        vm.pdf.nomeArquivo = vm.arquivoPdf.name;
        vm.pdf.tipoArquivo = vm.arquivoPdf.type;
        vm.tipoArquivo = vm.arquivoPdf.type;
        vm.getBase64(arquivo);
      }
    },
    getBase64(file) {
      const vm = this;
      var reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = function () {
        vm.arquivob64 = reader.result.replace(/^data:.+;base64,/, "");
      };
    },
    fechaModal() {
      this.arquivo = {};
      this.arquivoPdf = null;
      this.pdfUrl = null;
      this.$emit("fecha-modal");
    },
    visualizarPdf(id) {
      const vm = this;
      vm.loader = true;
      api
        .get("pdf/visualizar/" + id, {
          responseType: "arraybuffer",
          headers: {
            Accept: "application/pdf",
            Authorization: localStorage.token,
          },
        })
        .then((response) => {
          const blob = new Blob([response.data], { type: "application/pdf" });
          const url = window.URL.createObjectURL(blob);
          vm.pdfUrl = url;
        }).finally(
          vm.loader = false
        );
    },
    async onFileChange(file) {
      if (file.type === 'application/pdf') {
        const pdfData = await this.readAsArrayBuffer(file);
        const pdfUrl = URL.createObjectURL(new Blob([pdfData], { type: 'application/pdf' }));
        this.pdfUrl = pdfUrl;
      } else {
        this.pdfUrl = null;
      }
    },
    readAsArrayBuffer(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => {
          resolve(reader.result);
        };
        reader.onerror = reject;
        reader.readAsArrayBuffer(file);
      });
    },
  },
};
</script>
<style scoped>
.estilo {
  border-radius: 10px;
  opacity: 1;
}

.pre-visu {
  text-align: left !important;
  margin-bottom: 12px !important;
  color: #00000099 !important;
}

.tamanho-pdf {
  color: #00000099 !important;
}


.dimensoes {
  max-width: 400px;
  margin: auto;
}
</style>
