<template>
    <div>
        <!-- Icones Linkedin, Facebook, Intagram e Youtube -->
        <v-card class="pa-3 text-center estilo">
            <div @click="redirecionar('https://www.linkedin.com/company/hepta-tecnologia/mycompany/')">
                <v-icon small class="icon linkedin" color="#9C9C9C">fa-brands fa-linkedin-in </v-icon>
            </div>
            <div @click="redirecionar('https://www.facebook.com/heptatecnologia')">
                <v-icon small class="icon facebook" color="#9C9C9C">fa-brands fa-facebook </v-icon>
            </div>
            <div @click="redirecionar('https://www.instagram.com/heptatecnologia/')">
                <v-icon small class="icon instagram" color="#9C9C9C">fa-brands fa-instagram</v-icon>
            </div>
            <div @click="redirecionar('https://www.youtube.com/heptatecnologia')">
                <v-icon small class="icon youtube" color="#9C9C9C">fa-brands fa-youtube</v-icon>
            </div>

        </v-card>
    </div>
</template>
<script>
export default {
    data: () => ({
    }),
    methods: {
        redirecionar(URL) {
            window.open(URL, '_blank')
        }
    }
}
</script>
<style scoped>
.estilo {
    position: fixed;
    left: 0;
    right: 0;
    top: 55%;
    background-color: #F5F5F5;
    width: 48px;
    border-radius: 0px 8px 8px 0px;
    z-index: 2;
}

.icon {
    cursor: pointer;
    padding: 9px 0px 9px 0px !important;
}

.facebook:hover {
    color: #4968AA !important;
}

.instagram:hover {
    color: #DE4688 !important;
}

.youtube:hover {
    color: #D62422 !important;
}

.linkedin:hover {
    color: #037BB4 !important;
}

@media (max-width:1200px) {
    .estilo {
        display: none;
    }

}
</style>
