export const VisitaTecnicaEnum = Object.freeze({
  PENDENTE: { id: 1, desc: 'Pendente' },
  ANALISE_VIABILIDADE: { id: 2, desc: 'Análise de viabilidade' },
  ADIANTAMENT_PENDENTE: { id: 3, desc: 'Adiantamento pendente' },
  ADIANTAMENTO_PAGO: { id: 4, desc: 'Adiantamento pago' },
  COMPLEMENTO_ADICIONADO: { id: 5, desc: 'Complemento adicionado' },
  COMPLEMENTO_PAGO: { id: 6, desc: 'Complemento pago' },
  VALIDACAO: { id: 7, desc: 'Validação' },
  PRESTACAO_CONTA: { id: 8, desc: 'Prestação de contas' },
  PENDENTE_REEMBOLSO_EMPREGADO: { id: 9, desc: 'Pendente de reembolso do empregado' },
  PRENDENTE_REEMBOLSO_EMPRESA: { id: 10, desc: 'Pendente de reembolso da empresa' },
  ANALISE_FINANCEIRO: { id: 11, desc: 'Análise do financeiro' },
  CONCLUIDA: { id: 12, desc: 'Concluída' },
  CANCELADA: { id: 13, desc: 'Cancelada' },
});

export const EstadosDespesaEnum = Object.freeze({
  PREVISAO_DE_GATOS: 1,
  PAGO: 2,
  COMPROVANTE_REJEITADO: 3,
  COMPROVANTE_ANEXADO: 4,
  APROVADO_SUPERVISOR: 5,
  CONCLUIDO: 6,
});

export const TiposDespesaEnum = Object.freeze({
  DIARIA: 1,
  HOSPEDAGEM: 2,
  TRANSPORTE: 3,
  COMPLEMENTO: 4,
  AUTORIZACAO_VIAGEM: 5,
});
