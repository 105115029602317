<template>
  <div>
    <alerta v-on:fecharModal="fecharModal" :exibir="modal.exibir" :titulo="modal.titulo" :mensagem="modal.mensagem" />

    <loader :loader-primario="loader" />

    <v-divider></v-divider>

    <v-container v-if="!visitaDetalhes">
      <v-row>
        <v-col cols="12" md="8" xl="10">
          <p>Veja abaixo suas visitas técnicas. Após realizar a visita anexe os comprovantes de despesas.</p>
        </v-col>
        <v-col cols="12" md="4" xl="2">
          <v-btn @click="planejarVisita()" class="botao-principal w-100">
            <v-icon class="mr-2">fas fa-circle-plus</v-icon>
            Planejar Visita
          </v-btn>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" md="4" xl="5">
          <v-text-field
            outlined
            dense
            v-model="filtro.ticket"
            @keydown.stop="buscarVistas"
            label="Ticket:"
            hide-details="auto"
          >
            <template slot="append"> </template>
          </v-text-field>
        </v-col>
        <v-col cols="12" md="4" xl="5">
          <div>
            <v-autocomplete
              clearable
              chips
              label="Status:"
              outlined
              dense
              hide-details="auto"
              @input="pagina = [1]"
              @change="buscarVistas"
              v-model="filtro.statusList"
              :items="estadoVisita"
              item-text="descricao"
              item-value="idEstadoVisita"
            >
            </v-autocomplete>
          </div>
        </v-col>
        <v-col cols="12" md="4" xl="2">
          <v-btn
            class="botao-limpar"
            @click="
              () => {
                this.limparBusca();
                this.buscarVistas();
              }
            "
          >
            Limpar Buscas
          </v-btn>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <v-data-table
            v-model="paginator.page"
            :headers="headersVisita"
            :items="paginator.visitas"
            :items-per-page="paginator.perpage"
            :no-data-text="tabelaVazia()"
            hide-default-footer
            @update:options="options = $event"
          >
            <template #[`item.inicioFim`]="{ item }">
              {{ formatarExibicaoData(item.dthInicio) }} - {{ formatarExibicaoData(item.dthFim) }}
            </template>

            <template #[`item.dataPrevisao`]="{ item }">
              {{ formatarExibicaoData(item.prazoPagamento) }}
            </template>

            <template #[`item.status`]="{ item }">
              {{ item.descEstadoVista }}
            </template>

            <template #[`item.acao`]="{ item }">
              <div class="acoes-tabela">
                <v-tooltip
                  v-if="
                    item.idEstadoVisita === enumVisitaTecnica.ADIANTAMENTO_PAGO.id ||
                    item.idEstadoVisita === enumVisitaTecnica.COMPLEMENTO_PAGO.id ||
                    item.idEstadoVisita === enumVisitaTecnica.VALIDACAO.id ||
                    item.idEstadoVisita === enumVisitaTecnica.PRESTACAO_CONTA.id
                  "
                  top
                >
                  <template #activator="{ on, attrs }">
                    <v-icon v-bind="attrs" v-on="on" @click="abrirModalDetalhes(item)" class="icone-acao">
                      fas fa-eye
                    </v-icon>
                  </template>
                  <span>Ver detalhes</span>
                </v-tooltip>

                <v-tooltip v-else-if="item.idEstadoVisita === enumVisitaTecnica.PENDENTE_REEMBOLSO_EMPREGADO.id" top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon v-bind="attrs" v-on="on" @click="reembolsoVisita(item.idVisita)" class="icone-acao">
                      fa-file-arrow-up
                    </v-icon>
                  </template>
                  <span>Valor a ser devolvido</span>
                </v-tooltip>

                <span v-else>Sem Ação</span>
              </div>
            </template>
          </v-data-table>
        </v-col>

        <!-- Paginação -->
        <v-col cols="12">
          <v-container>
            <v-row justify="center">
              <v-col cols="12" md="5">
                <v-pagination
                  v-model="paginator.page"
                  @input="getPage"
                  prev-icon=" fa-light fa-chevron-left"
                  next-icon="fa-light fa-chevron-right"
                  :length="Math.ceil(paginator.itens / paginator.perpage)"
                  circle
                  dense
                  solo
                ></v-pagination>
              </v-col>
            </v-row>
          </v-container>
        </v-col>
      </v-row>
    </v-container>

    <!-- Modal Reembolso -->
    <v-dialog v-model="modalReembolso" @click:outside="anexoReembolso = null">
      <v-card>
        <v-card-title>
          <h3>Valor a ser devolvido</h3>
          <v-icon @click="fecharModalReembolso">fas fa-xmark</v-icon>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-row>
              <v-col>
                <p style="color: red">
                  Você precisa devolver o valor de
                  {{ reembolsoEmpregado.valorReembolso }} excedentes da visita. Anexe o comprovante de depósito.
                </p>
                <p style="color: red">Anexe o comprovante de depósito.</p>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <h4>Dados Bancários:</h4>
                <p>Favorecido: Hepta Tecnologia e Informática LTDA</p>
                <p>Banco do Brasil - 001</p>
                <p>Agência: 3382-0</p>
                <p>Conta corrente: 431.004-7</p>
                <p>CNPJ: 37.057.387/0001-22 (PIX)</p>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="px-10">
                <v-file-input v-model="anexoReembolso" outlined dense hide-details label="Comprovante depósito" />
              </v-col>
            </v-row>
            <v-row>
              <v-col class="px-10">
                <v-divider />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-container>
            <v-row class="justify-center">
              <v-col cols="12" md="3">
                <v-btn @click="fecharModalReembolso" class="botao-cancelar w-100">Cancelar</v-btn>
              </v-col>
              <v-col cols="12" md="3">
                <v-btn @click="anexarComprovanteReembolso" class="botao-principal w-100">Anexar Comprovante</v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <PlanejamentoVisita ref="planejamento-visita" @created="buscarVistas" @loader="event => (this.loader = event)" />

    <DetalhesVisita
      v-if="modalDetalhes"
      @erro="mensagemErro"
      @validacao="buscarVistas"
      @close="fecharModalDetalhes"
      :visita="visitaDetalhes"
    />
  </div>
</template>

<script>
import loader from '@/components/Loader.vue';
import alerta from '@/components/Alerta.vue';
import modalMixin from '@/mixins/modalMixin';
import ServiceVisitaTecnica from '@/services/ServiceVisitaTecnica';
import PlanejamentoVisita from '@/components/visita/PlanejamentoVisita.vue';
import { VisitaTecnicaEnum } from '@/enums/VisitaTecnincaEnums';
import { formatMoney } from '@/utils/Utils';
import DetalhesVisita from '@/components/visita/DetalhesVisita.vue';

export default {
  components: { DetalhesVisita, PlanejamentoVisita, loader, alerta },
  mixins: [modalMixin],
  name: 'VisitaTecninca',
  data() {
    return {
      visitaDetalhes: null,
      reembolsoEmpregado: {},
      anexoReembolso: null,
      modalDetalhes: false,
      modalReembolso: false,
      enumVisitaTecnica: VisitaTecnicaEnum,
      headersVisita: [
        { text: 'Nº do chamado', value: 'ticket' },
        { text: 'Origem', value: 'origem' },
        { text: 'Destino', value: 'destino' },
        { text: 'Inicio - Fim', value: 'inicioFim' },
        { text: 'Valor previsto', value: 'valorPrevisto' },
        { text: 'Data previsão adiantamento', value: 'dataPrevisao' },
        { text: 'Status', value: 'status' },
        { text: 'Responsável pelo status', value: 'responsavelStatus' },
        { text: 'Ação', value: 'acao' },
      ],
      estadoVisita: [],
      filtro: {},
      estados: [],
      paginator: {
        perpage: 10,
        page: 1,
        visitas: [],
      },
      loader: false,
      pagina: [1],
      listaDeVisitas: [],
    };
  },
  methods: {
    fecharModalReembolso() {
      let vm = this;
      vm.modalReembolso = false;
      vm.anexoReembolso = null;
    },
    fecharModalDetalhes() {
      this.visitaDetalhes = null;
      this.modalDetalhes = false;
    },
    abrirModalDetalhes(item) {
      this.visitaDetalhes = item;
      this.modalDetalhes = true;
    },
    anexarComprovanteReembolso() {
      let vm = this;
      vm.loader = true;
      let formData = new FormData();
      formData.append('file', vm.anexoReembolso);
      return ServiceVisitaTecnica.anexarComprovanteReembolso(vm.reembolsoEmpregado.idVisita, formData)
        .then(() => {
          vm.buscarVistas();
          vm.fecharModalReembolso();
          vm.mensagemSucesso('Documento anexado!');
        })
        .catch(err => {
          vm.fecharModalReembolso();
          vm.mensagemErro(err);
        })
        .finally(() => (vm.loader = false));
    },
    reembolsoVisita(idVisita) {
      let vm = this;
      vm.loader = true;
      return ServiceVisitaTecnica.listarDespesasVisita(idVisita)
        .then(res => {
          vm.reembolsoEmpregado = {
            idVisita,
            valorReembolso: formatMoney(
              res.data.map(d => d.valorPrevisto - d.valorGasto).reduce((t1, t2) => t1 + t2, 0)
            ),
          };
          vm.modalReembolso = true;
          return res;
        })
        .catch(err => vm.mensagemErro(err))
        .finally(() => (vm.loader = false));
    },
    limparBusca() {
      this.filtro = {
        status: -1,
        statusList: [],
      };
    },
    planejarVisita() {
      let vm = this;
      vm.$refs['planejamento-visita'].dialog = true;
      vm.$refs['planejamento-visita'].visita = {};
    },
    tabelaVazia() {
      return 'Não foram encontradas visitas!';
    },
    getPage(page) {
      this.paginator.page = page;
      this.buscarVistas();
    },
    buscarVistas() {
      const vm = this;
      vm.listaDeVisitas = [];
      vm.loader = true;
      const filtro = {
        itens: 10,
        page: vm.paginator.page,
        perpage: vm.paginator.perpage,
      };
      if (this.filtro.ticket) filtro['ticket'] = this.filtro.ticket;

      filtro['statusList'] = this.filtro.statusList;
      return ServiceVisitaTecnica.listarVisitas({ params: filtro })
        .then(res => {
          const paginator = res.data;
          paginator.page = filtro.page;
          paginator.perpage = filtro.perpage;
          paginator.visitas.forEach(v => {
            v.valorPrevisto = formatMoney(v.valorPrevisto);
            v.dthCriacao = new Date(v.dthCriacao);
            v.dtInicio = new Date(v.dthInicio);
            v.dtFim = new Date(v.dthFim);
          });
          vm.paginator = paginator;
          vm.listaDeVisitas = paginator.visitas;
        })
        .catch(err => vm.mensagemErro(err))
        .finally(() => (vm.loader = false));
    },
    formatarExibicaoData(data) {
      if (!data) return 'N/A';

      const ano = data.slice(0, 4);
      const mes = data.slice(5, 7);
      const dia = data.slice(8, 10);

      return dia + '/' + mes + '/' + ano;
    },
    buscarEstadosVisita() {
      let vm = this;
      ServiceVisitaTecnica.listarEstadosVisita()
        .then(res => {
          res.data.push({ idEstadoVisita: null, descricao: 'Todas' });
          vm.estadoVisita = res.data;
        })
        .catch(vm.mensagemErro);
    },
  },
  mounted() {
    let vm = this;
    vm.limparBusca();
    vm.buscarVistas();
    vm.buscarEstadosVisita();
  },
};
</script>

<style scoped></style>
