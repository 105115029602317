<template>
  <v-dialog v-model="modal" width="1800" @click:outside="fechaModal()">
    <v-card>
      <v-card-title>
        <h3 v-if="curso.codCurso === null">Adicionar Curso</h3>
        <h3 v-else>Editar Curso</h3>
        <v-icon @click="fechaModal()">fa-times</v-icon>
      </v-card-title>
      <v-card-text>
        <div>
          <v-container class="container-principal">
            <v-row>
              <v-text-field
                color="#278b97"
                type="text"
                label="Nome do curso"
                v-model="curso.nameCurso"
                outlined
                dense
              ></v-text-field>
            </v-row>
            <v-row>
              <v-text-field
                color="#278b97"
                label="Instituição"
                v-model="curso.instituicao"
                outlined
                dense
              ></v-text-field>
            </v-row>
            <v-row class="mt-0">
              <v-col cols="6" class="pl-0 pb-0">
                <v-text-field
                  color="#278b97"
                  type="number"
                  label="Carga Horária"
                  v-model="curso.cargaHoraria"
                  outlined
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="6" class="pr-0 pb-0">
                <v-menu
                  v-model="dataValidade"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="300"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="curso.dataFormatada"
                      outlined
                      dense
                      label="Data de conclusão / Previsão de término"
                      maxlength="10"
                      placeholder="DD/MM/AAAA"
                      v-on="on"
                      hide-details="auto"
                      v-bind="attrs"
                    >
                      <template slot="append">
                        <v-icon>fas fa-calendar-days</v-icon>
                      </template>
                    </v-text-field>
                  </template>
                  <v-date-picker
                    @input="curso.dataFormatada = formatDate(validade)"
                    color="#278b97"
                    v-model="validade"
                    required
                    :rules="[v => !!v || 'O campo Data é obrigatório.']"
                    locale="pt-br"
                    no-title
                    width="300"
                  ></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
            <v-row class="mt-0">
              <v-col cols="12" class="px-0">
                <v-file-input
                  @change="anexarArquivo(arquivo)"
                  color="#278b97"
                  v-model="arquivo"
                  outlined
                  dense
                  label="Anexar certificado"
                  placeholder="Escolha o anexo..."
                  accept="application/pdf"
                  hide-details="auto"
                  :prepend-icon="null"
                >
                  <template v-slot:prepend-inner>
                    <v-icon class="mr-2">fa-paperclip</v-icon>
                  </template>
                </v-file-input>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" class="pb-0 px-0">
                <v-textarea
                  color="#278b97"
                  v-model="curso.descricao"
                  label="Descrição do Curso:"
                  outlined
                  dense
                ></v-textarea>
              </v-col>
            </v-row>
          </v-container>

          <v-divider color="#278b97" class="mx-10 mb-8"></v-divider>

          <v-card-actions class="acoes">
            <v-btn @click="fechaModal()" class="botao-cancelar mr-3" :class="$vuetify.breakpoint.xs ? 'w-40' : 'w-25'">
              <v-icon small color="000" class="mr-3">fa-solid fa-xmark</v-icon>
              Cancelar
            </v-btn>
            <v-btn @click="salvar()" class="botao-salvar mr-3" :class="$vuetify.breakpoint.xs ? 'w-40' : 'w-25'">
              <v-icon small color="#ffffff" class="mr-3">fa-solid fa-arrow-up-from-bracket</v-icon>
              Salvar
            </v-btn>
          </v-card-actions>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'modal-curso',
  props: {
    curso: {},
    value: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    modal: false,
    arquivo: {},
    dataValidade: false,
    validade: null,
    validadeFormatada: null,
  }),
  watch: {
    value(e) {
      this.modal = e;
    },
  },
  methods: {
    fechaModal() {
      this.$emit('fecha-modal');
      this.limparVariaveis();
    },
    limparVariaveis() {
      this.arquivo = {};
    },
    formatDate(e) {
      if (!e) return null;
      const [year, month, day] = e.split('-');
      this.dataValidade = false;
      return `${day}/${month}/${year}`;
    },
    anexarArquivo(listaArquivos) {
      if (listaArquivos) {
        this.curso.nameDocumento = listaArquivos.name;
        this.curso.documento = listaArquivos;
      }
    },
    newDate(date) {
      if (!date) {
        return null;
      }
      const [day, month, year] = date.split('/');

      return new Date(year, month - 1, day, 0);
    },
    salvar() {
      const vm = this;
      const dados = vm.dadosParaSalvar();
      if (vm.curso.codCurso) {
        this.$emit('editar', this.curso.codCurso, dados);
        this.fechaModal();
      } else {
        this.$emit('salvar', dados);
        this.fechaModal();
      }
    },
    dadosParaSalvar() {
      const vm = this;
      let obj = vm.organizarFormulario();

      const form = JSON.parse(JSON.stringify(obj));
      if (vm.curso.documento) {
        let arrayNameDocumento = vm.curso.nameDocumento.split('.');
        let name = arrayNameDocumento[0];
        form.nameDocumento = name;
      }

      let date = vm.newDate(vm.curso.dataFormatada);
      form.data = date.getTime();

      const formData = new FormData();
      formData.append('form', JSON.stringify(form));
      formData.append('anexo', vm.curso.documento);
      return formData;
    },
    organizarFormulario() {
      const vm = this;
      let form = {
        nameCurso: vm.curso.nameCurso,
        instituicao: vm.curso.instituicao,
        cargaHoraria: vm.curso.cargaHoraria,
        data: vm.revertFormatDate(vm.curso.dataFormatada),
        descricao: vm.curso.descricao,
        nameDocumento: vm.curso.nameDocumento,
      };
      return form;
    },
    revertFormatDate(date) {
      if (!date) {
        return null;
      }
      const [day, month, year] = date.split('/');
      return `${year}-${month}-${day}`;
    },
  },
  mounted() {},
};
</script>

<style scoped>
.container-principal {
  padding: 1.25rem 3rem;
}

.icone-azul {
  font-size: 1rem;
  color: #278b97;
  margin-top: 0.25rem;
}

.acoes {
  padding: 0 0 1rem;
  display: flex;
  justify-content: center;
}
</style>
