<template>
  <div>
    <Loader :loader-primario="loader" />

    <Alerta v-on:fecharModal="fecharModal" :exibir="modal.exibir" :titulo="modal.titulo" :mensagem="modal.mensagem" />

    <v-container>
      <!-- Título -->
      <v-row>
        <v-col>
          <h1 class="titulo-avaliacao">
            Autoavaliação de Desempenho
            <span v-if="statusAutoAvaliacao == 'Iniciado'">{{ dadosAutoAvaliado.nomeCicloAutoavaliacao }}</span>
          </h1>
        </v-col>
      </v-row>

      <Aviso message="Acesse o histórico para visualizar os resultados de avaliações já concluídas." />

      <!-- Autoavaliação -->
      <v-row v-if="statusAutoAvaliacao == 'Iniciado'" class="mt-10">
        <!-- Dados do Colaborador -->
        <div>
          <v-col cols="12" class="header">
            <v-icon>fas fa-address-card</v-icon>
            <h2>Dados do Colaborador</h2>
          </v-col>
          <v-col cols="12" class="dados-colaborador-itens">
            <p>
              <b>Nome:</b>
              {{ dadosAutoAvaliado.nomeAvaliado }}
              <i v-if="dadosAutoAvaliado.nomeAvaliado == null">Sem informação</i>
            </p>
            <p>
              <b>Matrícula:</b>
              {{ dadosAutoAvaliado.matricula }}
            </p>
            <p>
              <b>Data Admissão:</b>
              {{ formatarData(dadosAutoAvaliado.dataAdmissao) }}
            </p>
            <p>
              <b>Tempo de casa:</b>
              {{ calcularTempoDeCasa(dadosAutoAvaliado.dataAdmissao).toFixed(2) }} Anos
            </p>
            <p>
              <b>Lotação:</b>
              {{ dadosAutoAvaliado.lotacao }}
            </p>
            <p>
              <b>Cargo:</b>
              {{ dadosAutoAvaliado.cargo }}
              <i v-if="dadosAutoAvaliado.cargo == null">Sem informação</i>
            </p>
            <p>
              <b>Descrição Cargo:</b>
              {{ dadosAutoAvaliado.descricao }}
              <i v-if="dadosAutoAvaliado.descricao == null">Sem informação</i>
            </p>

            <v-divider class="my-5" />
          </v-col>
        </div>

        <!-- Formulário -->
        <v-col cols="12">
          <template>
            <v-form ref="form">
              <!-- Tabela Resultado -->
              <v-row v-if="dadosAutoAvaliado.statusAutoavaliacao == 'Finalizado'">
                <v-col cols="12" class="header">
                  <v-icon>fas fa-chart-line</v-icon>
                  <h2>Resultado</h2>
                </v-col>
                <v-col cols="12" class="pa-0">
                  <v-simple-table class="tb-resultado">
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th></th>
                          <th>% Alcançada</th>
                          <th>Classificação</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td class="tituloCelula">Avaliação</td>
                          <td>{{ calculoResultado() }}%</td>
                          <td>{{ getClassificacaoNova(media) }}</td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-col>
                <v-col cols="12" class="px-0">
                  <v-divider class="my-5" />
                </v-col>
              </v-row>

              <!-- Tabela Formulário -->
              <v-row>
                <v-col cols="12" class="header">
                  <v-icon>fas fa-list</v-icon>
                  <h2>Autoavaliação</h2>
                </v-col>
                <v-col cols="12" class="pa-0">
                  <v-simple-table class="tb-formulario">
                    <!-- Cabeçalho -->
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th>Aplico / Pratico</th>
                          <th v-for="item in headers" :key="item.id" class="text-center">{{ item.header }}</th>
                        </tr>
                      </thead>
                      <!-- Linhas -->
                      <tbody>
                        <template v-for="tipo in tiposQuestao">
                          <!-- Tipos de Questão -->
                          <tr class="tiposQuestao">
                            <td :colspan="headers.length + 1">
                              <v-row>
                                <v-col>{{ tipo }}</v-col>
                                <v-col class="d-flex justify-end">
                                  <span v-show="isCollapsed(tipo)" @click="expand(tipo)">
                                    <v-icon>fas fa-chevron-down</v-icon>
                                  </span>
                                  <span v-show="!isCollapsed(tipo)" @click="expand(tipo)">
                                    <v-icon>fas fa-chevron-up</v-icon>
                                  </span>
                                </v-col>
                              </v-row>
                            </td>
                          </tr>
                          <tr
                            v-if="!isCollapsed(tipo)"
                            v-for="item in listaQuestoes.filter(q => q.tipo === tipo)"
                            :class="{ 'sem-resposta': item.resposta === null && questoesSemResposta }"
                          >
                            <!-- Título Linha -->
                            <td>{{ item.questao }}</td>
                            <!-- Radio buttons -->
                            <td v-for="header in headers" :key="header.id">
                              <v-radio-group
                                v-model="item.resposta"
                                :disabled="dadosAutoAvaliado.statusAutoavaliacao == 'Finalizado'"
                              >
                                <v-radio :value="header.id" class="radio-input"></v-radio>
                              </v-radio-group>
                            </td>
                          </tr>
                        </template>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-col>
              </v-row>
            </v-form>
          </template>
        </v-col>
      </v-row>

      <!-- Botões -->
      <v-row
        v-if="
          dadosAutoAvaliado.statusAutoavaliacao == 'Pendente' || dadosAutoAvaliado.statusAutoavaliacao == 'Em Andamento'
        "
        class="pt-8"
      >
        <v-col cols="12" md="4" xl="2">
          <v-btn @click="abrirModalSalvar()" class="botao-principal finalizar w-100">
            <v-icon class="mr-2">fas fa-check</v-icon>
            Finalizar Autoavaliação
          </v-btn>
        </v-col>
        <v-col cols="12" md="4" xl="2">
          <v-btn @click="salvarRascunho()" class="botao-principal rascunho w-100">
            <v-icon class="mr-2">fas fa-save</v-icon>
            Salvar Rascunho
          </v-btn>
        </v-col>
        <v-col cols="12" md="4" xl="2" offset-xl="6">
          <v-btn @click="limparMarcacoes()" class="botao-principal limpar w-100">
            <v-icon class="mr-2">fas fa-minus-circle</v-icon>
            Limpar Marcações
          </v-btn>
        </v-col>
      </v-row>
    </v-container>

    <!-- Modal Finalizar -->
    <v-dialog v-model="modalSalvar" max-width="500">
      <v-card>
        <v-card-title>
          <h3>Finalizar Autoavaliação</h3>
          <v-icon @click="fecharModalSalvar">fas fa-times</v-icon>
        </v-card-title>
        <v-card-text class="pb-0">
          <v-col>
            <p>Deseja finalizar o preenchimento do Formulário da Autoavaliação?</p>
            <v-divider class="mt-4" />
          </v-col>
        </v-card-text>
        <v-card-actions class="pb-4">
          <v-col cols="12" md="6">
            <v-btn @click="fecharModalSalvar()" class="botao-cancelar w-100">Cancelar</v-btn>
          </v-col>
          <v-col cols="12" md="6">
            <v-btn @click="salvar()" class="botao-principal w-100">Finalizar</v-btn>
          </v-col>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Loader from '@/components/Loader.vue';
import Alerta from '@/components/Alerta.vue';
import modalMixin from '@/mixins/modalMixin';
import api from '@/services/Api.js';
import Aviso from '@/components/Aviso.vue';

import JS from './methods.js';

export default {
  name: 'AutoAvaliacao',
  components: { Loader, Alerta, api, Aviso },
  mixins: [modalMixin],
  data() {
    return {
      loader: false,
      questoesSemResposta: false,
      modalSalvar: false,

      media: null,
      codCicloColaborador: null,

      dadosAutoAvaliado: {
        statusAutoAvaliacao: '',
      },

      statusAutoAvaliacao: '',

      headers: [],
      listaQuestoes: [],
      tiposQuestao: [],
      tiposQuestaoCollapsed: [],
    };
  },
  methods: {
    ...JS,
    async verificarAutoavaliacaoPessoaLogada() {
      this.loader = true;
      api
        .get('AutoAvaliados/existeAvaliacaoParaPessoaLogada')
        .then(res => {
          if (res.status === 204) return;

          this.codCicloColaborador = res.data;
          this.getDadosByIDColaborador(this.codCicloColaborador);
          this.buscarQuestoes();
        })
        .catch(e => {
          this.mensagemErro(e.message);
        })
        .finally(() => {
          this.loader = false;
        });
    },
    async getDadosByIDColaborador(codCicloColaborador) {
      this.loader = true;
      return api
        .get('AutoAvaliados/getDadosByCodCicloColaborador', {
          params: { cod_ciclo_colaborador: codCicloColaborador },
        })
        .then(res => {
          this.dadosAutoAvaliado = res.data;
          this.buscarHeaders();
        })
        .catch(e => {
          this.mensagemErro(e.message);
        })
        .finally(() => {
          this.loader = false;
        });
    },
    buscarHeaders() {
      this.loader = true;
      return api
        .get('headersAutoavaliacao/listarHeaders')
        .then(res => {
          res.data.forEach(item => {
            this.headers.push({ id: item.id, header: item.header });
          });
        })
        .catch(e => {
          this.mensagemErro(e.message);
        })
        .finally(() => {
          this.loader = false;
        });
    },
    buscarQuestoes() {
      this.loader = true;
      return api
        .get('questoesAutoavaliacao/listarQuestoes', {
          params: { cod_ciclo_colaborador: this.codCicloColaborador },
        })
        .then(res => {
          this.listaQuestoes = res.data;
          this.criarTiposQuestao();
        })
        .catch(e => {
          this.mensagemErro(e.message);
        })
        .finally(() => {
          this.loader = false;
        });
    },
    criarTiposQuestao() {
      this.tiposQuestao = [...new Set(this.listaQuestoes.map(item => item.tipo))];
      this.tiposQuestao.sort((a, b) => {
        // GPC pediu para que Atitudes apareçam primeiro
        return a === 'Atitudes' ? -1 : b === 'Atitudes' ? 1 : 0;
      });
    },
    getStatusCicloAutoavaliacao() {
      this.loader = true;
      api
        .get('AutoAvaliados/statusCicloAutoavaliacao')
        .then(res => {
          this.statusAutoAvaliacao = res.data;
        })
        .catch(e => {
          this.mensagemErro(e.message);
        })
        .finally(() => {
          this.loader = false;
        });
    },
    limparMarcacoes() {
      this.listaQuestoes.forEach(questao => {
        questao.resposta = null;
      });
    },
    calculoResultado() {
      const somaRespostas = this.listaQuestoes.reduce((acc, questao) => acc + questao.resposta, 0);
      const totalQuestoes = this.listaQuestoes.length;
      const respostaMaxima = this.headers.slice(-1)[0].id;
      let avg = ((somaRespostas / (totalQuestoes * respostaMaxima)) * 100).toFixed(2);

      this.media = avg;

      return avg;
    },
    getClassificacaoNova(numero) {
      const porcentagem = Number(numero);
      if (porcentagem < 70) {
        return 'Abaixo do esperado';
      } else if (porcentagem >= 70 && porcentagem <= 89.9) {
        return 'Atende';
      } else if (porcentagem >= 90) {
        return 'Supera as expectativas';
      } else return 'Erro';
    },
    abrirModalSalvar() {
      const vm = this;
      const todasQuestoesPreenchidas = vm.listaQuestoes.every(item => item.resposta != null);

      if (todasQuestoesPreenchidas) {
        vm.modalSalvar = true;
      } else {
        vm.clickHandler();
        vm.modalSalvar = false;
        vm.mensagem(
          'Campo(s) não Preenchido(s)!',
          'Para finalizar é necessário preencher todos os campos do formulário!'
        );
        vm.questoesSemResposta = true;
      }
    },
    fecharModalSalvar() {
      this.modalSalvar = false;
    },
    salvar() {
      const vm = this;
      vm.loader = true;
      const objetoDados = {
        questoesRespondidas: vm.listaQuestoes,
        idPessoa: vm.dadosAutoAvaliado.codPessoa,
        idCiclo: vm.dadosAutoAvaliado.codCicloAutoAvaliacao,
        codColaboradorCiclo: vm.dadosAutoAvaliado.codColaboradorCiclo,
      };

      api
        .put(`AvaliacoesRealizadas/salvar`, objetoDados)
        .then(() => {
          window.location.reload();
        })
        .catch(e => {
          vm.mensagemErro(e.message);
        })
        .finally(() => {
          vm.loader = false;
        });
    },
    salvarRascunho() {
      const vm = this;
      vm.loader = true;

      const objetoDados = {
        questoesRespondidas: vm.listaQuestoes,
        idPessoa: vm.dadosAutoAvaliado.codPessoa,
        idCiclo: vm.dadosAutoAvaliado.codCicloAutoAvaliacao,
        codColaboradorCiclo: vm.dadosAutoAvaliado.codColaboradorCiclo,
      };

      api
        .put(`AvaliacoesRealizadas/salvarRascunho`, objetoDados)
        .then(res => {
          if (res.status === 200) {
            vm.exibirMensagem = true;
            setTimeout(() => {
              vm.exibirMensagem = false;
            }, 3000);
          }
        })
        .catch(e => {
          vm.mensagemErro(e.message);
        })
        .finally(() => {
          vm.loader = false;
          window.location.reload();
        });
    },
  },
  async created() {
    await this.verificarAutoavaliacaoPessoaLogada();
    this.getStatusCicloAutoavaliacao();
    this.limparMarcacoes();
  },
};
</script>

<style scoped>
.tb-formulario.v-data-table > .v-data-table__wrapper > table > tbody > .sem-resposta td {
  border-top: 2px solid #f66 !important;
  border-bottom: 2px solid #f66 !important;
}

.sem-resposta td:first-child,
.sem-resposta td:first-child:hover {
  border-left: 2px solid #f66;
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px !important;
}

.sem-resposta td:last-child,
.sem-resposta td:last-child:hover {
  border-right: 2px solid #f66;
  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px !important;
}

.finalizar {
  background-color: #4caf50 !important;
}

.rascunho {
  background-color: #fdc600 !important;
}

.limpar {
  background-color: #238692 !important;
}

.tb-resultado > .v-data-table__wrapper > table > thead > tr > th::after {
  content: '';
  position: absolute;
  border: 1px solid;
  height: 75%;
  right: 0;
  top: 6px;
  color: #fffa;
}

.tb-resultado.v-data-table > .v-data-table__wrapper > table > tbody > tr > td::after {
  content: '';
  position: absolute;
  border: 1px solid;
  height: 60%;
  right: 0;
  top: 12px;
  color: #5da660aa;
}

.tb-resultado.v-data-table > .v-data-table__wrapper > table > thead > tr > th,
.tb-formulario.v-data-table > .v-data-table__wrapper > table > thead > tr > th {
  border-bottom: none !important;
}
</style>
