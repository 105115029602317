function colocarMascaraCPF(cpf) {
  if (cpf.length === 11) {
    cpf = cpf.replace(/\D/g, "");
    cpf = cpf.replace(/(\d{3})(\d)/, "$1.$2");
    cpf = cpf.replace(/(\d{3})(\d)/, "$1.$2");
    cpf = cpf.replace(/(\d{3})(\d{1,2})$/, "$1-$2");
  }
  return cpf;
}

function removerMascaraCPF(cpf) {
  cpf = cpf.replace(/[^0-9]+/g, "");
  return cpf;
}

export { colocarMascaraCPF, removerMascaraCPF };
export default { colocarMascaraCPF, removerMascaraCPF };
