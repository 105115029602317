<template>
  <div class="modal-overlay" v-if="modal" @click:outside="fecharModal()">
  <div class="modal">
    <v-card style="background-color: #e9e9e9;">
      <v-card-title class="headerModal">
        <h5>Adicionar FAQ</h5>
        <v-icon small @click="fecharModal()"> fa-times </v-icon>
      </v-card-title>
      <v-form ref="formFaq">
      <v-col cols="12" class="espacamento-v-col">
        <v-text-field color="#278b97" label="Pergunta:" :rules="rules.pergunta" v-model="objetoItem.pergunta" outlined dense
          class="estilo"></v-text-field>
        <v-autocomplete color="#278b97" :rules="rules.categoria" clearable chips label="Categorias:" outlined dense class="estilo"
          v-model="objetoItem.categoria" :items="categorias" item-text="nome" item-value="id" multiple >
          
        </v-autocomplete>
  
        <label class="custom-control-label" for="rascunho">Resposta:</label>
      </v-col>
    </v-form>
      <div class="ckEditor">
        <div id="editor" ref="elementorCkeditor"></div>
      </div>
      <v-divider></v-divider>

      <v-card-actions class="justify-center pb-10">
        <v-btn class="botao-cancelar w-25" @click="fecharModal()">Cancelar</v-btn>
        <v-btn v-if="itemFaq !== undefined" class="w-25 botao-principal" @click="atualizarItemFaq()">Salvar
          alterações</v-btn>
        <v-btn v-else class="w-25 botao-principal" @click="salvarItemFaq()">Salvar alterações</v-btn>
      </v-card-actions>
    </v-card>
  </div>
</div>
</template>
     
<script>
import api from "@/services/Api.js";
export default {
  name: "modal-artigo",
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    itemFaq: Object,
  },
  data() {
    return {
      modal: true,
      objetoItem: {
        id: null,
        pergunta: '',
        resposta: null,
        ativo: true,
        categoria: '',
        visualizacao: null,
      },
      rules: {
            pergunta: [
                (v) => !!v || 'Campo obrigatório',
            ],
            categoria: [
                (v) => v.length >= 0 || 'Selecione uma categoria!',
            ],
        },
      categorias: [],
     

    }
  },
  methods: {
    fecharModal() {
      this.$emit('fecha-modal');
    },
    configurarCKeditor(itemFaq) {
      CKEDITOR.ClassicEditor.create(document.getElementById("editor"), {
        language: 'pt',
        toolbar: {
          items: [
            'exportPDF', 'exportWord', '|',
            'findAndReplace', 'selectAll', '|',
            'heading', '|',
            'bold', 'italic', 'strikethrough', 'underline', 'code', 'subscript', 'superscript', 'removeFormat', '|',
            'bulletedList', 'numberedList', 'todoList', '|',
            'outdent', 'indent', '|',
            'undo', 'redo',
            '-',
            'fontSize', 'fontFamily', 'fontColor', 'fontBackgroundColor', 'highlight', '|',
            'alignment', '|',
            'link', 'insertImage', 'blockQuote', 'mediaEmbed', 'codeBlock', 'htmlEmbed', '|',
            'specialCharacters', 'horizontalLine', 'pageBreak', '|',
            'insertTable', '|',
            'sourceEditing'
          ],
          shouldNotGroupWhenFull: true
        },
        list: {
          properties: {
            styles: true,
            startIndex: true,
            reversed: true
          }
        },
        heading: {
          options: [
            { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
            { model: 'heading1', view: 'h1', title: 'Heading 1', class: 'ck-heading_heading1' },
            { model: 'heading2', view: 'h2', title: 'Heading 2', class: 'ck-heading_heading2' },
            { model: 'heading3', view: 'h3', title: 'Heading 3', class: 'ck-heading_heading3' },
            { model: 'heading4', view: 'h4', title: 'Heading 4', class: 'ck-heading_heading4' },
            { model: 'heading5', view: 'h5', title: 'Heading 5', class: 'ck-heading_heading5' },
            { model: 'heading6', view: 'h6', title: 'Heading 6', class: 'ck-heading_heading6' }
          ]
        },
        placeholder: 'Digite sua resposta:',
        fontFamily: {
          options: [
            'default',
            'Arial, Helvetica, sans-serif',
            'Courier New, Courier, monospace',
            'Georgia, serif',
            'Lucida Sans Unicode, Lucida Grande, sans-serif',
            'Tahoma, Geneva, sans-serif',
            'Times New Roman, Times, serif',
            'Trebuchet MS, Helvetica, sans-serif',
            'Verdana, Geneva, sans-serif'
          ],
          supportAllValues: true
        },
        fontSize: {
          options: ['default', 10, 12, 14, 16, 18, 20, 22, 24, 26, 28, 30, 32, 36],
          supportAllValues: true
        },
        htmlSupport: {
          allow: [
            {
              name: /.*/,
              attributes: true,
              classes: true,
              styles: true,
            },
          ]
        },
        htmlEmbed: {
          showPreviews: true
        },
        mediaEmbed: {
          previewsInData: true
        },
        link: {
          decorators: {
            addTargetToExternalLinks: true,
            defaultProtocol: 'https://',
            toggleDownloadable: {
              mode: 'manual',
              label: 'Downloadable',
              attributes: {
                download: 'file'
              }
            }
          }
        },
        mention: {
          feeds: [
            {
              marker: '@',
              feed: [
                '@apple', '@bears', '@brownie', '@cake', '@cake', '@candy', '@canes', '@chocolate', '@cookie', '@cotton', '@cream',
                '@cupcake', '@danish', '@donut', '@dragée', '@fruitcake', '@gingerbread', '@gummi', '@ice', '@jelly-o',
                '@liquorice', '@macaroon', '@marzipan', '@oat', '@pie', '@plum', '@pudding', '@sesame', '@snaps', '@soufflé',
                '@sugar', '@sweet', '@topping', '@wafer'
              ],
              minimumCharacters: 1
            }
          ]
        },
        removePlugins: [
          'CKBox',
          'CKFinder',
          'EasyImage',
          'RealTimeCollaborativeComments',
          'RealTimeCollaborativeTrackChanges',
          'RealTimeCollaborativeRevisionHistory',
          'PresenceList',
          'Comments',
          'TrackChanges',
          'TrackChangesData',
          'RevisionHistory',
          'Pagination',
          'WProofreader',
          'MathType'
        ]
      }).then(editor => {
        editor.model.document.on('change:data', () => {
          // editorData = editor.getData();
          this.editorData = editor;
          // this.verificarSalvamentoAutomatico()
        });
        if(itemFaq !== undefined){
          if (this.editorData == undefined && itemFaq !== null) {
          editor.setData(itemFaq.resposta)
        }
        }
       

      })

      /*eslint-enable*/
    },
    atualizarItemFaq() {
      if (this.$refs.formFaq.validate()) {
      this.objetoItem.resposta = this.editorData.getData();
      api.put("/itemfaq/atualizarItem", this.objetoItem, {
        headers: { Authorization: localStorage.token },
        withCredentials: true,
      })
        .then((response) => {
          this.$emit("sucesso", "Pergunta atualizada com sucesso!");
          this.$emit("fecha-modal")
          setTimeout(() => {
                this.$router.go(-1)
              }, 2500);
        })
        .catch(erro => {
          console.log(erro);
        })
      }
    },
    buscarPergunta(id) {
      api.get("/itemfaq/getid?id=" + id, {
        headers: { Authorization: localStorage.token },
        withCredentials: true,
      })
        .then((response) => {
          if (response.status == 200) {
            vm.objetoItem = response.data;
            vm.objetoItem.ativo = true;
          }
        })
        .catch((e) => {

        });
    },

    buscarTagsCategoria() {
      api.get(`categoria-faq/listarCategorias`, {
        headers: { Authorization: localStorage.token },
        withCredentials: true,
      })
        .then((response) => {
          this.categorias = response.data.map(r => {
            r.nome = r.nome.replace("&", " E ")
            return r
          })


        })
        .catch((e) => {

        });
    },
    salvarItemFaq() {
      if (this.$refs.formFaq.validate()) {
      this.objetoItem.resposta = this.editorData.getData();

      api.post("/itemfaq/cadastrarItem", this.objetoItem, {
        headers: { Authorization: localStorage.token },
        withCredentials: true,
      })
        .then((response) => {
          this.$emit("sucesso", "Pergunta cadastrada com sucesso!");
          this.$emit("fecha-modal")
          
        })
        .catch((e) => {
          console.log(e)
        });
    }
  }
  },
  mounted: function () {
    this.$nextTick(function () {
      if(this.itemFaq !== undefined){
      this.objetoItem = this.itemFaq
    }
      this.configurarCKeditor(this.itemFaq);
      this.buscarTagsCategoria();
    })
  }
}
</script>
<style scoped>


.modal {
  position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 70%;
    max-height: 95%;
    box-shadow: 0 0 10px rgba(0,0,0,0.3);
    overflow-y: auto;
    border-radius: 2%;
}


.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.5);
  z-index: 999;
  
}

.headerModal{
  background-color: rgb(39, 139, 151);
    justify-content: center;
    color: white;
}

.ckEditor {
  padding: 0px 15px 20px 15px;
}

.switch {
  display: flex;
  gap: 30px;
  padding: 5px 0px 25px 15px;
}
</style>