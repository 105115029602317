<template>
    <v-app>
      <v-main >
        <transition name="scroll-x-transition" mode="out-in">
          <router-view />
        </transition>
      </v-main>
    </v-app>
  </template>
  <script>
  export default {
    data() {
      return {};
    },
  };
  </script>
  