<template>
  <div @click:outside="fecharModal()" class="modal-overlay">
    <Alerta v-on:fecharModal="fecharModal" v-on:exclusao="apagarItemFaq()" :exibir="modal.exibir" :titulo="modal.titulo"
      :mensagem="modal.mensagem">
    </Alerta>
    <div class="modal">

      <v-card style="background-color: #e9e9e9;">
        <v-card-title class="header-modal">
          <h3>Adicionar Novidades</h3>
          <v-icon @click="fecharModal()">fa-times</v-icon>
        </v-card-title>

        <!-- Conteúdo do Card -->
        <v-card-text class="card-principal">
          <v-form ref="formArtigo" class="pb-5">
            <v-col>
              <v-text-field color="#278b97" :rules="rules.obrigatorio" label="Titulo:" v-model="artigo.titulo" outlined
                dense class="rounded-12"></v-text-field>
              <v-text-field color="#278b97" :rules="rules.obrigatorio" label="Sumário:" v-model="artigo.descricao"
                outlined dense class="rounded-12"></v-text-field>
              <v-select color="#278b97" v-model="tagSelecionada" :rules="rules.tags" item-text="nome" return-object
                item-value="id" label="Marcador" outlined dense :items="tags" class="rounded-12"></v-select>

              <v-row>
                <div class="switch">
                  <v-switch v-model="artigo.destaqueHome" color="primary" label="Últimas Notícias"
                    hide-details></v-switch>
                  <v-switch v-model="artigo.rascunho" color="primary" label="Rascunho" hide-details></v-switch>
                </div>
              </v-row>

              <label class="custom-control-label" for="rascunho">Conteúdo:</label>
              <div id="editor" ref="elementorCkeditor"></div>
            </v-col>
          </v-form>

          <v-divider></v-divider>
          <!-- Escolha de artigos relacionados -->
          <v-row class="mt-2">
            <v-col>
              <span class="titulo-azul">
                Conteúdos Relacionados
              </span>
            </v-col>
          </v-row>
          <v-row class="my-2">
            <v-col>
              <v-sheet color="white" :height="'auto'" width="100%" rounded class="px-2">
                <v-row>
                  <v-col cols="12">
                    <v-text-field @input="buscarPorTexto()" @click:clear="buscarArtigos()" class="pesquisa w-100"
                      hide-details="auto" dense flat solo v-model="keyword" clearable>
                      <template slot="label">
                        <span class="pesquisa-label">Pesquisar</span>
                      </template>
                      <template v-slot:append>
                        <v-icon @click="buscarPorTexto()" color="#32ACB9">fa-solid fa-magnifying-glass</v-icon>
                      </template>
                    </v-text-field>
                  </v-col>
                </v-row>
                <v-row class="row-textos">
                  <v-col>
                    Todos artigos
                  </v-col>
                  <v-col>
                    Artigos relacionados:
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="6">
                    <v-virtual-scroll class="custom-scrollbar" height="150" item-height="50" :items="todosArtigos">
                      <template v-slot:default="{ item }">
                        <v-list-item :key="item.id">
                          <v-list-item-content>
                            <v-list-item-title>
                              {{ item.titulo }}
                            </v-list-item-title>

                          </v-list-item-content>
                          <v-list-item-action>
                            <v-icon color="#32ACB9" small @click="adicionarArtigoParaRelacionarEmMemoria(item)">
                              fa-solid fa-circle-chevron-right
                            </v-icon>
                          </v-list-item-action>
                        </v-list-item>
                        <v-divider></v-divider>
                      </template>
                    </v-virtual-scroll>
                  </v-col>

                  <v-col cols="6">
                    <v-virtual-scroll class="custom-scrollbar" height="150" item-height="50"
                      :items="todosArtigosRelacionados">

                      <template v-slot:default="{ item }">
                        <v-list-item :key="item.id">

                          <v-list-item-content>
                            <v-list-item-title>
                              {{ item.titulo }}
                            </v-list-item-title>

                          </v-list-item-content>
                          <v-list-item-action>
                            <v-icon color="#32ACB9" small @click="retirarArtigoParaRelacionarEmMemoria(item)">
                              fa-solid fa-circle-chevron-left
                            </v-icon>
                          </v-list-item-action>
                        </v-list-item>

                        <v-divider></v-divider>
                      </template>
                    </v-virtual-scroll>
                  </v-col>
                </v-row>
              </v-sheet>
            </v-col>
          </v-row>



        </v-card-text>
        <!-- Botões -->
        <v-card-actions class="justify-center pb-5">
          <v-btn @click="fecharModal()" class="botao-cancelar w-25 mr-5">Cancelar</v-btn>
          <v-btn v-if="this.novidade.id !== undefined" @click="atualizarNovidades()" class="w-25 botao-principal">
            Salvar alterações
          </v-btn>
          <v-btn v-else @click="salvarNovidades()" class="w-25 botao-principal">
            Salvar alterações
          </v-btn>
        </v-card-actions>
      </v-card>
    </div>
  </div>
</template>

<script>
import api from "@/services/Api.js";
import Alerta from "@/components/Alerta.vue";
import modalMixin from "@/mixins/modalMixin";

export default {
  components: { Alerta },
  mixins: [modalMixin],
  name: "modal-artigo",
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    novidade: Object,
    academiaHepta: Boolean,
    artigosSaude: Boolean
  },
  data() {
    return {
      tags: [],
      rules: {
        tags: [
          (v) => !!v || 'Campo obrigatório',
        ],
        campoObrigatorio: [(v) => !!v || "Campo obrigatório"],
        obrigatorio: [
          (value) => !!value || "Campo obrigatório",
        ],
      },
      tagSelecionada: [],
      visualizacao: 0,
      artigo: {
        titulo: null,
        descricao: null,
        conteudo: null,
        tags: [],
        destaqueHome: false,
        rascunho: true,
      },
      keyword: "",
      todosArtigos: [],
      artigosRelacionados: [],
      artigosParaRelacionarEmMemoria: [],
      todosArtigosRelacionados: [],
      artigosRelacionadosParaApagar: []
    }
  },
  methods: {
    configurarCKeditor() {
      CKEDITOR.ClassicEditor.create(document.getElementById("editor"), {
        language: 'pt',
        allowedContent: true,
        extraAllowedContent: 'style;*[id,rel](*){*}',
        inlineStyles: true,
        toolbar: {
          items: [
            'exportPDF', 'exportWord', '|',
            'findAndReplace', 'selectAll', '|',
            'heading', '|',
            'bold', 'italic', 'strikethrough', 'underline', 'code', 'subscript', 'superscript', 'removeFormat', '|',
            'bulletedList', 'numberedList', 'todoList', '|',
            'outdent', 'indent', '|',
            'undo', 'redo',
            '-',
            'fontSize', 'fontFamily', 'fontColor', 'fontBackgroundColor', 'highlight', '|',
            'alignment', '|',
            'link', 'insertImage', 'blockQuote', 'mediaEmbed', 'codeBlock', 'htmlEmbed', '|',
            'specialCharacters', 'horizontalLine', 'pageBreak', '|',
            'insertTable', '|',
            'sourceEditing'
          ],
          shouldNotGroupWhenFull: true
        },
        list: {
          properties: {
            styles: true,
            startIndex: true,
            reversed: true
          }
        },
        heading: {
          options: [
            { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
            { model: 'heading1', view: 'h1', title: 'Heading 1', class: 'ck-heading_heading1' },
            { model: 'heading2', view: 'h2', title: 'Heading 2', class: 'ck-heading_heading2' },
            { model: 'heading3', view: 'h3', title: 'Heading 3', class: 'ck-heading_heading3' },
            { model: 'heading4', view: 'h4', title: 'Heading 4', class: 'ck-heading_heading4' },
            { model: 'heading5', view: 'h5', title: 'Heading 5', class: 'ck-heading_heading5' },
            { model: 'heading6', view: 'h6', title: 'Heading 6', class: 'ck-heading_heading6' }
          ]
        },
        placeholder: 'Digite sua resposta:',
        fontFamily: {
          options: [
            'default',
            'Arial, Helvetica, sans-serif',
            'Courier New, Courier, monospace',
            'Georgia, serif',
            'Lucida Sans Unicode, Lucida Grande, sans-serif',
            'Tahoma, Geneva, sans-serif',
            'Times New Roman, Times, serif',
            'Trebuchet MS, Helvetica, sans-serif',
            'Verdana, Geneva, sans-serif'
          ],
          supportAllValues: true
        },
        fontSize: {
          options: ['default', 10, 12, 14, 16, 18, 20, 22, 24, 26, 28, 30, 32, 36],
          supportAllValues: true
        },
        htmlSupport: {
          allow: [
            {
              name: /.*/,
              attributes: true,
              classes: true,
              styles: true,
            },
          ]
        },
        htmlEmbed: {
          showPreviews: true
        },
        mediaEmbed: {
          previewsInData: true
        },
        link: {
          decorators: {
            addTargetToExternalLinks: true,
            defaultProtocol: 'https://',
            toggleDownloadable: {
              mode: 'manual',
              label: 'Downloadable',
              attributes: {
                download: 'file'
              }
            }
          }
        },
        mention: {
          feeds: [
            {
              marker: '@',
              feed: [
                '@apple', '@bears', '@brownie', '@cake', '@cake', '@candy', '@canes', '@chocolate', '@cookie', '@cotton', '@cream',
                '@cupcake', '@danish', '@donut', '@dragée', '@fruitcake', '@gingerbread', '@gummi', '@ice', '@jelly-o',
                '@liquorice', '@macaroon', '@marzipan', '@oat', '@pie', '@plum', '@pudding', '@sesame', '@snaps', '@soufflé',
                '@sugar', '@sweet', '@topping', '@wafer'
              ],
              minimumCharacters: 1
            }
          ]
        },
        removePlugins: [
          'CKBox',
          'CKFinder',
          'EasyImage',
          'RealTimeCollaborativeComments',
          'RealTimeCollaborativeTrackChanges',
          'RealTimeCollaborativeRevisionHistory',
          'PresenceList',
          'Comments',
          'TrackChanges',
          'TrackChangesData',
          'RevisionHistory',
          'Pagination',
          'WProofreader',
          'MathType'
        ]
      }).then(editor => {

        editor.model.document.on('change:data', () => {
          this.editorData = editor;
        });
        if (this.editorData == undefined && this.artigo.conteudo !== null) {
          editor.setData(this.artigo.conteudo)
        }
      })
    },
    fecharModal() {
      this.$emit('fecha-modal');
    },
    urlSeoFriendly(url) {
      return url.toString()               // Convert to string
        .normalize('NFD')               // Change diacritics
        .replace(/[\u0300-\u036f]/g, '') // Remove illegal characters
        .replace(/_+/g, '-')            // Change underscores to dashes
        .replace(/\s+/g, '-')            // Change whitespace to dashes
        .toLowerCase()                  // Change to lowercase
        .replace(/&/g, '-e-')          // Replace ampersand
        .replace(/[^a-z0-9-]/g, '')     // Remove anything that is not a letter, number or dash
        .replace(/-+/g, '-')             // Remove duplicate dashes
        .replace(/^-*/, '')              // Remove starting dashes
        .replace(/-*$/, '');             // Remove trailing dashes
    },
    salvarNovidades() {
      if (this.$refs.formArtigo.validate()) {
        this.artigo.conteudo = this.editorData.getData();
        this.artigo.tags.push(this.tagSelecionada)
        this.artigo.tituloUrl = this.urlSeoFriendly(this.artigo.titulo)
        api.post("/artigo/criar", this.artigo, {
          headers: { Authorization: localStorage.token },
          withCredentials: true,
        })
          .then(() => {
            this.salvarArtigosRelacionados();
            this.apagarArtigosRelacionados();
            this.fecharModal();
            this.$emit('mensagemSucesso', "Artigo salvo com sucesso!");
          })
          .catch((e) => {
            this.$emit('capturarMensagemErro', e);
          })
          .finally(() => {
            this.fecharModal();
          })
      }
    },
    buscarTags() {
      api.get("/tag/listar/categoria/2")
        .then(response => {
          this.tags = response.data
        })
        .catch(e => {
          console.log(e)
        })
    },
    buscarTagsAcademia() {
      api.get("/tag/listar/categoria/15")
        .then(response => {
          this.tags = response.data
        })
        .catch(e => {
          console.log(e)
        })
    },
    atualizarNovidades() {
      if (!this.$refs.formArtigo.validate()) return;

      this.artigo.conteudo = this.editorData.getData();
      this.artigo.tags = [];
      let objetoTag = {
        'categorias': null,
        'cor': null, 'icone': null,
        'id': this.tagSelecionada.id,
        'nome': this.tagSelecionada.nome
      };
      this.artigo.tags.push(objetoTag);
      this.artigo.tituloUrl = this.urlSeoFriendly(this.artigo.titulo);

      api.post("/artigo/atualizar", this.artigo, {
        headers: { Authorization: localStorage.token },
        withCredentials: true,
      })
        .then(() => {
          this.salvarArtigosRelacionados();
          this.apagarArtigosRelacionados();
          this.fecharModal();
          this.$emit('mensagemSucesso', "Artigo atualizado com sucesso!");
        })
        .catch((e) => { this.$emit('capturarMensagemErro', e) })
        .finally(() => { this.fecharModal() });
    },
    buscarTagsArtigos() {
      const vm = this;
      vm.isLoadingTags = true;
      api
        .get(`tag/listar/categoria/1`)
        .then((response) => {
          response.data.pop()
          if (response.status == 200) {
            response.data.map((e) => {
              this.tags.push(e);
            });
          }
        })
        .catch((e) => {
          vm.mensagemErro(e.message);
        });
    },

    buscarArtigoPorId(idArtigo) {
      api.get("artigo/" + idArtigo)
        .then((response) => {
          if (response.status == 200) {
            this.artigo = response.data;
            if (this.artigosSaude) {
              this.tagSelecionada = response.data.tags[0]
            } else {
              this.tagSelecionada = response.data.tags[0]
            }
            this.configurarCKeditor();
          }
        })
        .catch((e) => {
          console.log(e)
        });
    },
    buscarPorTexto() {
      const vm = this;
      api.get(`artigo/buscar?keywords=${vm.keyword}`).then((res) => {
        vm.todosArtigos = res.data;
      }).catch((e) => {
        vm.mensagemErro(e.message);
      });
    },
    buscarArtigos() {
      const vm = this;
      api.get("/artigo/todos").then((res) => {
        vm.todosArtigos = res.data;
      }).catch((e) => {
        vm.mensagemErro(e.message);
      });
    },
    getArtigosRelacionados() {
      const vm = this;

      if (this.novidade.id === undefined) return;

      api.get(`/artigo/relacionados/${this.novidade.id}`).then((res) => {
        vm.todosArtigosRelacionados = res.data;
      }).catch((e) => {
        vm.mensagemErro(e.message);
      });
    },
    adicionarArtigoParaRelacionarEmMemoria(obj) {
      this.artigosParaRelacionarEmMemoria.unshift(obj);
      this.todosArtigosRelacionados.unshift(obj);
      this.todosArtigos = this.todosArtigos.filter(a => a.id !== obj.id);
      this.artigosRelacionadosParaApagar = this.artigosRelacionadosParaApagar.filter(a => a.id !== obj.id);
    },
    retirarArtigoParaRelacionarEmMemoria(obj) {
      this.artigosParaRelacionarEmMemoria = this.artigosParaRelacionarEmMemoria.filter(a => a.id !== obj.id);
      this.todosArtigosRelacionados = this.todosArtigosRelacionados.filter(a => a.id !== obj.id);
      this.artigosRelacionadosParaApagar.push(obj);
      this.todosArtigos.unshift(obj);
    },
    salvarArtigosRelacionados() {
      const vm = this;
      if (vm.artigosParaRelacionarEmMemoria.length === 0) return;
      let listaId = [];
      vm.artigosParaRelacionarEmMemoria.forEach(a => {
        listaId.push(a.id);
      });
      const body = {
        idArtigoOriginal: vm.novidade.id,
        idsArtigosRelacionados: listaId
      }
      api.post("artigo/relacionados/salvar", body).then(() => {
        return;
      }).catch((e) => {
        vm.mensagemErro(e.message);
      });
    },
    apagarArtigosRelacionados() {
      const vm = this;
      if (vm.artigosRelacionadosParaApagar.length === 0) return;
      let listaId = [];
      vm.artigosRelacionadosParaApagar.forEach(a => {
        listaId.push(a.id);
      });
      const body = {
        idArtigoOriginal: vm.novidade.id,
        idsArtigosRelacionados: listaId
      }
      api.post("artigo/relacionados/excluir", body).then(() => {
        return;
      }).catch((e) => {
        vm.mensagemErro(e.message);
      });
    }
  },
  mounted: function () {
    this.buscarArtigos();
    this.getArtigosRelacionados();
    this.$nextTick(function () {
      if (this.novidade.id !== undefined) {
        this.buscarArtigoPorId(this.novidade.id)
      } else {
        this.configurarCKeditor();
      }
      if (this.academiaHepta) {
        this.buscarTagsAcademia();
      }
      else if (this.artigosSaude) {
        this.buscarTagsArtigos();
      }
      else {
        this.buscarTags();
      }
    })
  }
}
</script>

<style scoped>
.modal {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 70%;
  max-height: 95%;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  overflow-y: auto;
  border-radius: 2%;
}


.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
}

.switch {
  display: flex;
  gap: 30px;
  padding: 5px 0px 25px 15px;
}

.header-modal {
  background-color: #278b97;
  justify-content: center;
}

.card-principal {
  padding: 1.5rem 3rem 1rem;
}

.pesquisa {
  margin: 0;
  border-radius: 12px;
  background-color: rgba(0, 0, 0, 0.6);
}

.pesquisa-label {
  color: #278b97;
  margin: 0 1rem;
}

.pesquisa-btn {
  margin-left: 1rem;
  min-width: 36px !important;
  width: 3rem;
  background-color: #32ACB9 !important;
  color: #FFF;
  border: 1px solid #FFF;
  border-radius: 8px;
}

.pesquisa-btn .v-icon {
  font-size: 1.25rem;
}

.custom-scrollbar {
  overflow-y: auto;
  scrollbar-color: #278B97 #F5F5F5;
  z-index: 99;
}

.row-textos .col {
  font-weight: bolder;
  color: #278B97;
  padding-left: 2%;
}
</style>