<template>
  <v-main class="main">
    <Loader :loader-primario="loader" />

    <Alerta
      v-on:fecharModal="fecharModal"
      :exibir="modal.exibir"
      :titulo="modal.titulo"
      :mensagem="modal.mensagem"
      v-on:exclusao="excluir()"
    />

    <ModalBanner
      :modalAlterarBanner="modalAlterarBanner"
      :imagem="imagemModal"
      :pagina="pagina"
      :comLink="false"
      @atualizaImagem="atualizaImagem"
      v-on:fecha-modal="modalAlterarBanner = false"
      :customMethod="getImagem"
      @mensagem="mensagem"
      @loader="isLoading"
    />

    <!-- Banner -->
    <v-container fluid class="pa-0">
      <v-btn
        v-if="admin"
        @click="
          modalAlterarBanner = true;
          imagemModal = imagem.banner;
          pagina = 'banner';
        "
        class="banner-btn botao-edicao-adm"
      >
        <v-icon>fa fa-pencil</v-icon>
      </v-btn>
      <v-img
        :src="
          'data:' +
          imagem.banner.tipoArquivo +
          ';base64,' +
          imagem.banner.arquivo
        "
        class="banner"
      ></v-img>
    </v-container>

    <!-- Aviso Sem Artigos -->
    <v-container v-if="!temLinks && !temAnexos && !temArtigos">
      <v-row>
        <v-col cols="12" lg="8" xl="6" class="mx-auto">
          <Aviso
            message="Não foi possível achar resultados para essa pesquisa."
          />
        </v-col>
      </v-row>
    </v-container>

    <!-- Artigos -->
    <v-container>
      <v-row v-if="artigos.length">
        <v-col cols="12">
          <h1 class="pt-4">Artigos relacionados</h1>
        </v-col>
        <v-col cols="12" md="6" v-for="(artigo, index) in artigos" :key="index">
          <CardNovidade
            :titulo="artigo.titulo"
            :descricao="artigo.descricao"
            :index="index"
            :tags="artigo.tags"
            :pesquisa="true"
            :id="artigo.id"
            :keyword="keyword"
            :icone="artigo.icone"
            :artigo="artigo.artigo"
            :academiaHepta="artigo.academiaHepta"
          />
        </v-col>
      </v-row>
      <v-row v-if="anexos.length">
        <v-col cols="12">
          <h1 class="pt-4">Arquivos relacionados</h1>
        </v-col>
        <v-col cols="12" md="6" v-for="(anexo, index) in anexos" :key="index">
          <FormatoArquivo :arquivo="anexo" :pesquisa="true" />
        </v-col>
      </v-row>
      <v-row v-if="links.length">
        <v-col cols="12">
          <h1 class="pt-4">Sistemas e Serviços relacionados</h1>
        </v-col>
        <v-col cols="12" md="6" v-for="(link, index) in links" :key="index">
          <a target="blank" :href="link.url" class="text-decoration-none">
            <CardNovidade
              :titulo="link.titulo"
              :descricao="link.descricao"
              :index="index"
              :tags="link.tags"
              :pesquisa="true"
              :clicavel="false"
            />
          </a>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
import api from "@/services/Api";
import CardNovidade from "../components/CardNovidade.vue";
import FormatoArquivo from "@/components/FormatoArquivo.vue";
import DetalheNoticia from "../views/DetalheNoticia.vue";
import Loader from "@/components/Loader.vue";
import Alerta from "@/components/Alerta.vue";
import modalMixin from "../mixins/modalMixin";
import ModalBanner from "@/components/Modal/ModalBanner.vue";
import { idsPesquisa } from "@/utils/ImagemEnums.js";
import Aviso from "@/components/Aviso.vue";
import Utils from "@/utils/Utils";

export default {
  components: {
    CardNovidade,
    FormatoArquivo,
    Loader,
    ModalBanner,
    Alerta,
    Aviso,
  },
  mixins: [modalMixin],
  name: "PesquisaView",
  data() {
    return {
      keyword: this.$route.params.string,
      anexos: [],
      artigos: [],
      links: [],
      temAnexos: true,
      temArtigos: true,
      temLinks: true,
      loader: false,
      admin: false,
      pagina: "",
      imagemModal: {},
      imagem: {
        banner: {
          tipoArquivo: "",
          arquivo: "",
        },
      },
      modalAlterarBanner: false,
    };
  },
  watch: {
    $route: function () {
      this.keyword = this.$route.params.string;
    },
  },
  methods: {
    async pesquisa(keyword) {
      this.loader = true;
      await Promise.all([
        this.buscarArtigos(keyword),
        this.buscarAnexos(keyword),
        this.buscarLinks(keyword),
      ]);
      this.loader = false;
    },
    isLoading(e) {
      this.loader = e;
    },
    getImagem(id, pagina) {
      const vm = this;

      api
        .get("imagem/" + id)
        .then((response) => {
          //nome da variavel é o pagina. ex: vm.banner
          vm.imagem[pagina] = response.data;
        })
        .catch((e) => {
          vm.mensagemErro(e.message);
        });
    },
    atualizaImagem(pagina, imagem, tipoArquivo) {
      const vm = this;
      vm.imagem[pagina].arquivo = imagem;
      vm.imagem[pagina].tipoArquivo = tipoArquivo;
      vm.modalAlterarBanner = false;
    },
    isAdmin() {
      this.admin = Utils.isAdmin();
    },
    async buscarArtigos(termosChave) {
      try {
        const response = await api.get(
          "artigo/buscar?keywords=" + termosChave,
          {
            headers: { Authorization: localStorage.token },
            withCredentials: true,
          }
        );

        if (response.status !== 200) return;

        const artigos = response.data;
        this.artigos = artigos.map((a) => {
          const tag = a.tags[0].id;
          const informacoesUteis = tag > 11 && tag < 16 ? true : false;
          const academiaHepta = tag == 24 || tag == 27 ? true : false;

          a.academiaHepta = informacoesUteis || academiaHepta;
          a.artigo = informacoesUteis;

          return a;
        });

        this.temArtigos = this.artigos.length > 0;
      } catch (e) {
        this.mensagemErro(e.message);
      }
    },
    async buscarAnexos(termosChave) {
      try {
        const response = await api.get("anexo/buscar?keywords=" + termosChave, {
          headers: { Authorization: localStorage.token },
          withCredentials: true,
        });

        if (response.status === 200) {
          this.anexos = response.data;
          this.temAnexos = this.anexos.length > 0;
        }
      } catch (e) {
        this.mensagemErro(e.message);
      }
    },
    async buscarLinks(termosChave) {
      try {
        const response = await api.get("link/buscar?keywords=" + termosChave, {
          headers: { Authorization: localStorage.token },
          withCredentials: true,
        });

        if (response.status === 200) {
          this.links = response.data;
          this.temLinks = this.links.length > 0;
        }
      } catch (e) {
        this.mensagemErro(e.message);
      }
    },
    baixarAnexo(id) {
      const vm = this;

      api
        .get("anexo/baixar/" + id, {
          headers: { Authorization: localStorage.token },
          withCredentials: true,
        })
        .then(function (response) {
          const anexo = response.data;
          const ArrayBugger = vm.base64ToArrayBuffer(anexo.conteudo);
          var blob = new Blob([ArrayBugger]);
          let url = window.URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", anexo.nome + anexo.extensao);
          document.body.appendChild(link);
          link.click();
        })
        .catch((e) => {
          vm.mensagemErro(e.message);
        });
    },
    base64ToArrayBuffer(base64) {
      var binary_string = window.atob(base64);
      var len = binary_string.length;
      var bytes = new Uint8Array(len);
      for (var i = 0; i < len; i++) {
        bytes[i] = binary_string.charCodeAt(i);
      }
      return bytes.buffer;
    },
    visualizarPDFAnexo(id) {
      const vm = this;
      vm.loader = true;
      api
        .get("anexo/visualizarPDF/" + id, {
          responseType: "arraybuffer",
          headers: {
            Accept: "application/pdf",
            Authorization: localStorage.token,
          },
          withCredentials: true,
        })
        .then(function (response) {
          const blob = new Blob([response.data], { type: "application/pdf" });
          const url = window.URL.createObjectURL(blob);
          window.open(url, "_blank");
        })
        .catch(function (e) {
          vm.mensagemErro(e.message);
        })
        .finally(function () {
          vm.loader = false;
        });
    },
    getTipoAnexo(nomAnexo) {
      if (nomAnexo) {
        const splitAnexo = nomAnexo.split(".");
        let tipoAnexo = splitAnexo[splitAnexo.length - 1];
        return tipoAnexo;
      }
    },
  },
  mounted() {
    this.isAdmin();
    this.pesquisa(this.keyword);

    for (let pagina in idsPesquisa) {
      //ex: this.getImagem(103, 'banner');
      this.getImagem(idsPesquisa[pagina], pagina);
    }
  },
};
</script>

<style scoped>
.banner {
  position: relative;
}

.banner-btn {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
}
</style>
