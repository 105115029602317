<template>
  <div>
    <alerta
      v-on:fecharModal="fecharModal"
      :exibir="modal.exibir"
      :titulo="modal.titulo"
      :mensagem="modal.mensagem"
    />

    <div>
      <!-- Barra Principal -->
      <v-app-bar color="#278B97" height="84" class="hidden-sm-and-down">
        <v-container class="container-appbar">
          <v-row class="row-principal-appbar">
            <!-- Botões + Bem-vindo -->
            <v-col cols="3" md="4" lg="3">
              <v-toolbar-title class="toolbar">
                <!-- Notificações -->
                <v-menu
                  offset-y
                  max-width="352"
                  allow-overflow
                  max-height="300"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn class="botao-header" dark v-bind="attrs" v-on="on">
                      <v-icon>fa-solid fa-bell</v-icon>
                      <span v-if="totalPendencias" class="notificacao">{{
                        totalPendencias
                      }}</span>
                    </v-btn>
                  </template>

                  <v-list
                    v-if="
                      pendenciasColaborador.termosAditivosPendentes >= 1 ||
                      pendenciasColaborador.treinamentosPendentes >= 1 ||
                      pendenciasColaborador.consentimentosPendentes >= 1 ||
                      pendenciasColaborador.visitasTecnicasPendentes >= 1 ||
                      pendenciasColaborador.reciboFeriasPendentes >= 1
                    "
                    three-line
                  >
                    <v-subheader>Pendências</v-subheader>

                    <v-list-item-group>
                      <v-list-item
                        v-if="
                          pendenciasColaborador.termosAditivosPendentes >= 1
                        "
                      >
                        <v-list-item-content @click="redirecionarTo('Termos')">
                          <v-list-item-title
                            >Termos e Condições</v-list-item-title
                          >
                          <v-list-item-subtitle>
                            Você possui
                            {{ pendenciasColaborador.termosAditivosPendentes }}
                            termos e condições para ciência à serem analisados
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>

                      <v-list-item
                        v-if="pendenciasColaborador.treinamentosPendentes >= 1"
                      >
                        <v-list-item-content
                          @click="redirecionarTo('Formacao')"
                        >
                          <v-list-item-title>Treinamentos</v-list-item-title>
                          <v-list-item-subtitle>
                            Você possui
                            {{ pendenciasColaborador.treinamentosPendentes }}
                            treinamentos pendentes
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>

                      <v-list-item
                        v-if="
                          pendenciasColaborador.consentimentosPendentes >= 1
                        "
                      >
                        <v-list-item-content
                          @click="redirecionarTo('Protecao de Dados')"
                        >
                          <v-list-item-title>Consentimentos</v-list-item-title>
                          <v-list-item-subtitle>
                            Você possui
                            {{ pendenciasColaborador.consentimentosPendentes }}
                            consentimentos à serem analisados
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>

                      <v-list-item
                        v-if="
                          pendenciasColaborador.visitasTecnicasPendentes >= 1
                        "
                      >
                        <v-list-item-content
                          @click="redirecionarTo('Visita Tecnica')"
                        >
                          <v-list-item-title>Visita Técnica</v-list-item-title>
                          <v-list-item-subtitle>
                            Você possui
                            {{ pendenciasColaborador.visitasTecnicasPendentes }}
                            visitas que necessitam de atenção
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>

                      <v-list-item
                        v-if="pendenciasColaborador.reciboFeriasPendentes >= 1"
                      >
                        <v-list-item-content @click="redirecionarTo('Ferias')">
                          <v-list-item-title
                            >Recibo de Férias</v-list-item-title
                          >
                          <v-list-item-subtitle>
                            Você possui
                            {{ pendenciasColaborador.reciboFeriasPendentes }}
                            {{
                              pendenciasColaborador.reciboFeriasPendentes == 1
                                ? "recibo"
                                : "recibos"
                            }}
                            de férias para assinar
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list-item-group>
                  </v-list>
                </v-menu>

                <!-- Home -->
                <router-link to="/">
                  <v-btn class="botao-header" dark>
                    <v-icon>fa-solid fa-house</v-icon>
                  </v-btn>
                </router-link>

                <!-- Bem-vindo -->
                <div class="containerBemVindo">
                  <p class="bemVindo">BEM-VINDO</p>
                  <p class="nomeUsuario">{{ nomeSobrenome() }}</p>
                  <p class="sair" @click="logout()">Sair</p>
                </div>
              </v-toolbar-title>
            </v-col>

            <!-- Pesquisar -->
            <v-col cols="6" md="4" lg="6">
              <v-row>
                <v-text-field
                  @keyup.enter="buscar()"
                  class="pesquisa"
                  hide-details="auto"
                  dense
                  flat
                  solo
                  v-model="keyword"
                  clearable
                >
                  <template slot="label">
                    <span class="pesquisa-label">Pesquisar</span>
                  </template>
                </v-text-field>
                <v-btn @click="buscar()" class="pesquisa-btn">
                  <v-icon>fa-solid fa-magnifying-glass</v-icon>
                </v-btn>
              </v-row>
            </v-col>

            <!-- Espaço do Colaborador -->
            <v-col cols="3" md="4" lg="3" class="text-right">
              <router-link to="/espaco-do-colaborador">
                <v-btn class="botao-espaco-colaborador" color="#32ACB9"
                  >Espaço do Colaborador</v-btn
                >
              </router-link>
            </v-col>
          </v-row>
        </v-container>
      </v-app-bar>

      <!-- Barra Mobile -->
      <div class="topbar-mobile hidden-md-and-up">
        <v-container>
          <v-row class="toolbar-mobile">
            <!-- Botões Principais -->
            <v-col cols="2">
              <v-app-bar-nav-icon
                @click.stop="sidebar = !sidebar"
                variant="text"
                color="white"
              />
            </v-col>
            <v-col cols="6">
              <img
                src="../assets/images/espaco-colaborador/logo_nossa_hepta.png"
              />
            </v-col>
            <v-col cols="2">
              <v-menu offset-y max-width="352" allow-overflow max-height="300">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn dark v-bind="attrs" v-on="on">
                    <v-icon>fa-solid fa-bell</v-icon>
                    <span v-if="totalPendencias" class="notificacao">{{
                      totalPendencias
                    }}</span>
                  </v-btn>
                </template>

                <v-list
                  v-if="
                    pendenciasColaborador.termosAditivosPendentes >= 1 ||
                    pendenciasColaborador.treinamentosPendentes >= 1 ||
                    pendenciasColaborador.consentimentosPendentes >= 1 ||
                    pendenciasColaborador.visitasTecnicasPendentes >= 1 ||
                    pendenciasColaborador.reciboFeriasPendentes >= 1
                  "
                  three-line
                >
                  <v-subheader>Pendências</v-subheader>

                  <v-list-item-group>
                    <v-list-item
                      v-if="pendenciasColaborador.termosAditivosPendentes >= 1"
                    >
                      <v-list-item-content @click="redirecionarTo('Termos')">
                        <v-list-item-title
                          >Termos e Condições</v-list-item-title
                        >
                        <v-list-item-subtitle>
                          Você possui
                          {{ pendenciasColaborador.termosAditivosPendentes }}
                          termos e condições para ciência à serem analisados
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>

                    <v-list-item
                      v-if="pendenciasColaborador.treinamentosPendentes >= 1"
                    >
                      <v-list-item-content @click="redirecionarTo('Formacao')">
                        <v-list-item-title>Treinamentos</v-list-item-title>
                        <v-list-item-subtitle>
                          Você possui
                          {{ pendenciasColaborador.treinamentosPendentes }}
                          treinamentos pendentes
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>

                    <v-list-item
                      v-if="pendenciasColaborador.consentimentosPendentes >= 1"
                    >
                      <v-list-item-content
                        @click="redirecionarTo('Protecao de Dados')"
                      >
                        <v-list-item-title>Consentimentos</v-list-item-title>
                        <v-list-item-subtitle>
                          Você possui
                          {{ pendenciasColaborador.consentimentosPendentes }}
                          consentimentos à serem analisados
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>

                    <v-list-item
                      v-if="pendenciasColaborador.visitasTecnicasPendentes >= 1"
                    >
                      <v-list-item-content
                        @click="redirecionarTo('Visita Tecnica')"
                      >
                        <v-list-item-title>Visita Técnica</v-list-item-title>
                        <v-list-item-subtitle>
                          Você possui
                          {{ pendenciasColaborador.visitasTecnicasPendentes }}
                          visitas que necessitam de atenção
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>

                    <v-list-item
                      v-if="pendenciasColaborador.reciboFeriasPendentes >= 1"
                    >
                      <v-list-item-content @click="redirecionarTo('Ferias')">
                        <v-list-item-title>Recibo de Férias</v-list-item-title>
                        <v-list-item-subtitle>
                          Você possui
                          {{ pendenciasColaborador.reciboFeriasPendentes }}
                          {{
                            pendenciasColaborador.reciboFeriasPendentes == 1
                              ? "recibo"
                              : "recibos"
                          }}
                          de férias para assinar
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list-item-group>
                </v-list>
              </v-menu>
            </v-col>
            <v-col cols="2">
              <v-btn @click="pesquisa = !pesquisa">
                <v-icon>fa-solid fa-magnifying-glass</v-icon>
              </v-btn>
            </v-col>

            <!-- Pesquisar -->
            <v-col cols="12" v-if="pesquisa">
              <v-text-field
                @keyup.enter="buscar()"
                v-model="keyword"
                dense
                solo
                hide-details="auto"
              >
                <template slot="label">
                  <span class="input-field-text">Pesquisar</span>
                </template>
                <template slot="append">
                  <v-icon @click="buscar()" class="input-field-text"
                    >fa-solid fa-magnifying-glass</v-icon
                  >
                </template>
              </v-text-field>
            </v-col>

            <!-- Botão Espaço Colaborador -->
            <v-col cols="12" class="d-flex justify-center">
              <router-link to="/espaco-do-colaborador" class="w-100">
                <v-btn class="botao-espaco-colaborador"
                  >Espaço do Colaborador</v-btn
                >
              </router-link>
            </v-col>
          </v-row>
        </v-container>
      </div>

      <v-container class="py-0 hidden-sm-and-down">
        <div class="lista-links">
          <router-link to="/">
            <img
              src="../assets/images/icone/icone_hepta_com_nome.png"
              width="132px"
            />
          </router-link>
          <v-list height="84px" nav>
            <v-list-item
              v-for="item in menuItems"
              :key="item.id"
              :to="item.link"
              class="menu"
              active-class="menu_ativo"
            >
              {{ item.titulo }}
            </v-list-item>
          </v-list>
        </div>
      </v-container>
      <!-- Menu Mobile -->
      <v-navigation-drawer v-model="sidebar" absolute class="nav-drawer-mobile">
        <v-list>
          <v-list-item
            v-for="item in menuItems"
            :key="item.id"
            :to="item.link"
            >{{ item.titulo }}</v-list-item
          >
        </v-list>
      </v-navigation-drawer>
    </div>
  </div>
</template>

<script>
import modalMixin from "@/mixins/modalMixin";
import alerta from "@/components/Alerta.vue";
import api from "@/services/Api.js";

export default {
  components: { alerta },
  mixins: [modalMixin],
  data() {
    return {
      usuario: {},
      sidebar: false,
      subMenu: "Novidades",
      keyword: "",
      menuItems: [
        { titulo: "Início", link: "/", mobile: "true" },
        { titulo: "Novidades", link: "/novidades" },
        { titulo: "Recrutamento Hepta", link: "/vagas", mobile: "true" },
        { titulo: "#JeitoHepta", link: "/jeitohepta" },
        { titulo: "Biblioteca", link: "/biblioteca" },
        { titulo: "Perguntas Frequentes", link: "/perguntas-frequentes" },
      ],
      pesquisa: false,
      pendenciasColaborador: [],
      totalPendencias: 0,
    };
  },
  methods: {
    getCredenciais() {
      let vm = this;
      api
        .get("auth/credenciais")
        .then((response) => {
          vm.usuario = response.data;
        })
        .catch((e) => {
          this.mensagemErro(e.message);
        });
    },
    buscar() {
      const currentPath = this.$route.path;
      const currentKeyword = currentPath.split("/pesquisa/")[1];

      // Ignorar caso em que a pesquisa é a mesma palavra ou não tenha nada escrito
      if (currentKeyword === this.keyword || !this.keyword) return;

      this.$router.push({ path: "/pesquisa/" + this.keyword });

      // Recarregar página se já estiver na pesquisa, para refazer as buscas
      if (currentPath.startsWith("/pesquisa/")) window.location.reload();
    },
    redirecionarTo(name) {
      this.$router.push({ name: name });
    },
    nomeSobrenome() {
      if (this.usuario.nome) {
        let firstName = this.usuario.nome.split(" ")[0];
        let lastName = this.usuario.nome.split(" ").pop();
        return firstName + " " + lastName;
      }
    },
    logout() {
      const vm = this;
      api
        .get("auth/logout", {
          headers: { Authorization: localStorage.token },
          withCredentials: true,
        })
        .then((response) => {
          if (response.status == 200) {
            localStorage.removeItem("isAdmin");
            localStorage.removeItem("isGpc");
            localStorage.removeItem("token");
            vm.$router.push({
              name: "Login",
            });
          }
        });
    },
    listarPendenciasColaborador() {
      const vm = this;
      api
        .get("headerDropDown")
        .then((res) => {
          vm.pendenciasColaborador = res.data;
          vm.totalPendencias =
            vm.pendenciasColaborador.termosAditivosPendentes +
            vm.pendenciasColaborador.consentimentosPendentes +
            vm.pendenciasColaborador.treinamentosPendentes +
            vm.pendenciasColaborador.visitasTecnicasPendentes +
            vm.pendenciasColaborador.reciboFeriasPendentes;
        })
        .catch((err) => {
          this.mensagemErro(err);
        });
    },
  },
  created() {
    this.getCredenciais();
    this.listarPendenciasColaborador();
  },
};
</script>

<style scoped>
.lista-links {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.lista-links .v-list {
  display: flex;
  padding: 0;
}

.lista-links .v-list-item {
  display: flex;
  justify-content: center;
  margin-bottom: 0 !important;
}

.lista-links .v-list-item:first-child {
  display: none;
}

.lista-links .v-list-item:hover {
  color: #278b97 !important;
}

.menu_ativo {
  border-bottom: 4px solid #278b97;
  border-radius: 0;
}

.menu_ativo::before {
  opacity: 0;
}

.topbar-mobile {
  background-color: #278b97;
}

.toolbar-mobile {
  display: flex;
  align-items: center;
}

.toolbar-mobile .v-btn {
  min-width: 32px !important;
  max-width: 100%;
  background-color: #278b97 !important;
  border: 1px solid #fff;
  border-radius: 8px;
}

.toolbar-mobile .v-icon {
  color: #fff;
  font-size: 1.125rem;
}

.notificacao-mobile {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: -20px;
  right: -20px;
  width: 20px;
  height: 20px;
  font-size: 0.75rem;
  color: #fff;
  background: #db1826;
  border-radius: 50%;
}

.botao-espaco-colaborador {
  margin: auto !important;
  border: 1px solid #fff !important;
  font-weight: 600;
  width: 100%;
}

.botao-espaco-colaborador:hover {
  color: #32acb9 !important;
  background-color: #fff !important;
}

.nav-drawer-mobile {
  z-index: 99;
}

.container-appbar {
  display: contents;
}

.row-principal-appbar {
  align-items: center;
  margin: 0 5%;
}

.pesquisa {
  border-radius: 12px;
}

.pesquisa-label {
  color: #278b97;
  margin: 0 1rem;
}

.pesquisa-btn {
  margin-left: 1rem;
  min-width: 36px !important;
  width: 3rem;
  background-color: #32acb9 !important;
  color: #fff;
  border: 1px solid #fff;
  border-radius: 8px;
}

.pesquisa-btn .v-icon {
  font-size: 1.25rem;
}

.header {
  border-radius: 0px;
  padding: 15px 0px 10px 100px;
  display: flex;
  gap: 25px;
}

.bemVindo {
  color: white;
  font-weight: bold;
}

.containerBemVindo {
  line-height: 20px;
}

.nomeUsuario {
  color: white;
  font-weight: normal;
}

.sair {
  color: white;
  font-weight: normal;
  font-size: 12px;
  cursor: pointer;
}

.botao-header {
  min-width: 12px !important;
  width: 48px !important;
  height: 48px !important;
  background-color: #278b97 !important;
  border: 1px solid;
  border-color: #fff;
  border-radius: 8px;
  margin-right: 1rem;
}

.botao-header .v-icon {
  color: #fff;
  font-size: 1.125rem;
}

.notificacao {
  position: absolute;
  top: -25px;
  right: -26px;
  width: 23px;
  height: 23px;
  background: #db1826;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}

.pesquisar {
  width: 45% !important;
  margin-top: 2px;
  height: 50px;
  margin-left: 65px;
}

div.pesquisar.v-input__control {
  height: 40px !important;
}

.menu_header {
  border-radius: 0px;
  display: flex;
  gap: 25px;
}

.logoHepta {
  margin-left: 100px;
  height: 65px;
  margin-top: 5px;
}

.navegacao ul {
  list-style: none;
  padding: 0px 10px 9px 10px;
  background-color: rgb(255, 255, 255);
}

.navegacao ul li {
  display: inline;
}

.navegacao ul li a {
  color: black;
  text-decoration: none;
  display: inline-block;
  padding: 27px 25px 20px;
}

.activate {
  border-bottom: 1px solid;
  border-width: thick;
  border-color: #278b97;
  background-color: rgb(39 139 151 / 7%);
}

.subMenu {
  display: flex;
  margin-bottom: -20px;
}

.toolbar {
  display: flex;
  align-items: center;
  height: 84px;
}
</style>
