import axios from 'axios';
import router from '../router/index';

const baseUrl = process.env.VUE_APP_URL;

const config = {
  baseURL: baseUrl,
  withCredentials: true,
  maxRedirects: 0,
};

const api = axios.create(config);

api.interceptors.response.use(
  response => {
    localStorage.isAdmin = response.headers['isadmin'];
    localStorage.isGpc = response.headers['isgpc'];
    return response;
  },
  error => {
    if (!error.response) {
      console.error('Erro de rede ou CORS', error.message);
      return Promise.reject(error);
    }

    if ([401, 403, 302].includes(error.response?.status)) {
      localStorage.removeItem('isAdmin');
      localStorage.removeItem('isGpc');
      localStorage.removeItem('token');
      localStorage.removeItem('isPJ');

      if (!window.location.hash.includes('/auth/')) {
        router.push({
          name: 'Login',
          query: { 'next-hash': window.location.hash },
        });
      }
    }
    return Promise.reject(error);
  }
);

api.interceptors.request.use(
  config => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers.Authorization = token;
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

api.metricas = (name, caminho) => {
  return api.post(`metricasRegistro`, {
    pagina: name.toString(),
    path: caminho.toString(),
  });
};

api.rotas = {};
api.rotas.base = baseUrl;
api.rotas.login = 'auth/login';

api.rotas.pessoa = {};
api.rotas.pessoa.avatar = baseUrl + 'pessoa/avatar';

export default api;
