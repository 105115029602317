<template>
  <div>
    <Loader :loader-primario="loader" />

    <Alerta
      v-on:fecharModal="fecharModal"
      :exibir="modal.exibir"
      :titulo="modal.titulo"
      :mensagem="modal.mensagem"
      v-on:exclusao="excluir()"
    />

    <RedesSociais />

    <Modal
      v-model="modalAnexo"
      :item="anexoEdicao"
      v-on:fecha-modal="(modalAnexo = false), limparCampos()"
      v-on:salvar="
        (modalAnexo = false), buscarAnexos(0, 1, '', '', 0, ''), limparCampos()
      "
      @capturarMensagemErro="mensagemErro"
      @capturarMensagemSucesso="mensagemSucesso"
    />

    <ModalBanner
      :modalAlterarBanner="modalAlterarBanner"
      :imagem="imagemModal"
      :pagina="pagina"
      @atualizaImagem="atualizaImagem"
      v-on:fecha-modal="modalAlterarBanner = false"
      :customMethod="getImagem"
      @mensagem="mensagem"
      @loader="isLoading"
      :comLink="false"
    />

    <EditarDestaque
      v-model="modalEditarDestaque"
      :destaque="destaqueEditar"
      @sucessoEdicao="sucessoEdicao()"
      @erro="mensagemErro"
      @fecha-modal="fecharModalEdicao()"
    />

    <div>
      <v-btn
        v-if="admin"
        @click="
          modalAlterarBanner = true;
          imagemModal = imagem.banner;
          pagina = 'banner';
        "
        class="banner-btn botao-edicao-adm"
      >
        <v-icon>fa fa-pencil</v-icon>
      </v-btn>
      <v-img
        :src="
          'data:' +
          imagem.banner.tipoArquivo +
          ';base64,' +
          imagem.banner.arquivo
        "
      ></v-img>
    </div>

    <!-- Filtro -->
    <v-row class="destaque-inicio">
      <v-col>
        <a href="#novidades">
          <v-img
            src="@/assets/images/icone/icone-novidades-biblioteca.png"
            max-width="57px"
            class="ma-auto"
          ></v-img>
          <p>Novidades</p>
        </a>
      </v-col>
      <v-divider vertical class="divider-filtro" />
      <v-col>
        <a href="#arquivos">
          <v-img
            src="@/assets/images/icone/icone-tipo-pdf.png"
            max-width="57px"
            class="ma-auto"
          ></v-img>
          <p>Arquivos</p>
        </a>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <p class="text-center">
          Tenha acesso a todos os tipos de arquivos que são<br />
          considerados úteis para o dia a dia na Hepta.
        </p>
      </v-col>
    </v-row>

    <v-divider class="divider-conteudo" />

    <!-- Destaque 1 -->
    <v-row id="novidades">
      <v-col cols="12" md="6" class="conteudo-editavel-esquerda">
        <v-btn
          v-if="admin"
          @click="
            modalAlterarBanner = true;
            imagemModal = imagem.imagem1;
            pagina = 'imagem1';
          "
          class="botao-edicao-adm"
        >
          <v-icon>fa fa-pencil</v-icon>
        </v-btn>
        <v-img
          :src="
            'data:' +
            imagem.imagem1.tipoArquivo +
            ';base64,' +
            imagem.imagem1.arquivo
          "
        ></v-img>
      </v-col>
      <v-col
        cols="12"
        md="6"
        class="conteudo-editavel-direita alinhamento-texto"
      >
        <v-btn
          v-if="admin"
          @click="abrirModalEditarDestaque(destaque1)"
          class="botao-edicao-adm"
        >
          <v-icon>fa fa-pencil</v-icon>
        </v-btn>
        <h1>{{ destaque1.titulo }}</h1>
        <p>{{ destaque1.descricao }}</p>
      </v-col>
    </v-row>

    <v-divider class="divider-conteudo" />

    <!-- Destaque 2 -->
    <v-row>
      <v-col
        cols="12"
        md="6"
        class="conteudo-editavel-esquerda alinhamento-texto"
      >
        <v-btn
          v-if="admin"
          @click="abrirModalEditarDestaque(destaque2)"
          class="botao-edicao-adm"
        >
          <v-icon>fa fa-pencil</v-icon>
        </v-btn>
        <h1>{{ destaque2.titulo }}</h1>
        <p>{{ destaque2.descricao }}</p>
      </v-col>
      <v-col cols="12" md="6" class="conteudo-editavel-direita">
        <v-btn
          v-if="admin"
          @click="
            modalAlterarBanner = true;
            imagemModal = imagem.imagem2;
            pagina = 'imagem2';
          "
          class="botao-edicao-adm"
        >
          <v-icon>fa fa-pencil</v-icon>
        </v-btn>
        <v-img
          :src="
            'data:' +
            imagem.imagem2.tipoArquivo +
            ';base64,' +
            imagem.imagem2.arquivo
          "
        ></v-img>
      </v-col>
    </v-row>

    <!-- Arquivos -->
    <div class="arquivos-texto">
      <v-row v-if="admin" class="justify-end">
        <v-col cols="12" md="3" xl="2">
          <v-btn @click="modalAnexo = true" class="botao-principal w-100 mb-4">
            <v-icon small class="mr-2">fas fa-circle-plus</v-icon>
            Novo Arquivo
          </v-btn>
        </v-col>
      </v-row>

      <h1 class="mb-5">Arquivos</h1>
      <p>
        Os arquivos estão organizados por políticas, processos, procedimentos
        que são a base das nossas entregas, além de modelos de apresentação,
        timbrado e outros para serem usados de acordo com a nossa identidade
        visual. Você ainda encontra as referências externas, arquivos
        selecionados que complementam a nossa biblioteca.
      </p>
    </div>

    <v-row id="arquivos">
      <v-col class="text-center">
        <p>Selecione um tipo de documento</p>
      </v-col>
    </v-row>
    <!-- Cards Arquivos -->
    <v-row id="novidades" class="row-card-arquivos">
      <v-col cols="6" md="2" v-for="filtro in filtros" :key="filtro.id">
        <v-card
          @click="
            buscarAnexos(
              filtro.id,
              (page = 1),
              filtro.titulo,
              filtroArquivo,
              filtroTag,
              termo
            )
          "
          :class="
            filtroPorCategoria == filtro.titulo
              ? 'card-arquivo card-arquivo-active'
              : 'card-arquivo'
          "
          tabindex="none"
        >
          <div class="imagem-card-arquivo">
            <v-img :src="filtro.img" max-width="57px"></v-img>
          </div>
          <p class="titulo-card-arquivo">{{ filtro.titulo }}</p>
        </v-card>
      </v-col>
    </v-row>

    <!-- Pesquisa + Arquivos -->
    <v-container>
      <!-- Pesquisa -->
      <v-row ref="pesquisa">
        <v-col cols="12" md="4">
          <v-text-field
            v-model="termo"
            dense
            solo
            hide-details="auto"
            clearable
            class="input-field"
          >
            <template slot="label">
              <span class="input-field-text">Pesquisar</span>
            </template>
            <template slot="append">
              <v-icon class="input-field-text"
                >fa-solid fa-magnifying-glass</v-icon
              >
            </template>
          </v-text-field>
        </v-col>
        <v-col cols="12" md="4">
          <v-select
            v-model="filtroArquivo"
            :items="extensao"
            dense
            solo
            item-value="id"
            item-text="tipoAnexo"
            clearable
            hide-details="auto"
            class="input-field"
          >
            <template slot="label">
              <span class="input-field-text">Arquivos</span>
            </template>
            <template slot="append">
              <v-icon class="input-field-text">fa-light fa-chevron-down</v-icon>
            </template>
          </v-select>
        </v-col>
        <v-col cols="12" md="4">
          <v-select
            v-model="filtroTag"
            :items="tags"
            item-value="id"
            item-text="nome"
            dense
            solo
            clearable
            hide-details="auto"
            class="input-field"
          >
            <template slot="label">
              <span class="input-field-text">Tags</span>
            </template>
            <template slot="append">
              <v-icon class="input-field-text">fa-light fa-chevron-down</v-icon>
            </template>
          </v-select>
        </v-col>
      </v-row>
      <!-- Arquivos -->
      <v-row>
        <v-col
          cols="12"
          lg="6"
          v-for="arquivo in anexos.result"
          :key="arquivo.id"
          class="col-arquivo"
        >
          <FormatoArquivo
            :arquivo="arquivo"
            @capturarIdEdicao="modalEditar"
            @excluir="excluir"
            @capturarMensagemErro="mensagemErro"
            @capturarMensagemSucesso="mensagemSucesso"
          >
          </FormatoArquivo>
        </v-col>
      </v-row>

      <v-row class="paginacao">
        <v-col cols="5">
          <v-pagination
            v-model="page"
            :length="anexos.maxPages"
            @input="
              buscarAnexos(
                idFiltroSelecionado,
                page,
                filtroPorCategoria,
                filtroArquivo,
                filtroTag,
                termo
              )
            "
            circle
          ></v-pagination>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import Loader from "@/components/Loader.vue";
import FormatoArquivo from "@/components/FormatoArquivo.vue";
import RedesSociais from "@/components/RedesSociais.vue";
import api from "@/services/Api.js";
import Modal from "@/components/Modal/ModalBiblioteca.vue";
import Alerta from "@/components/Alerta.vue";
import modalMixin from "@/mixins/modalMixin";
import ModalBanner from "@/components/Modal/ModalBanner.vue";
import EditarDestaque from "@/components/Biblioteca/ModalEdicaoDestaque.vue";
import { idsBiblioteca } from "@/utils/ImagemEnums.js";
import Utils from "@/utils/Utils";

export default {
  components: {
    FormatoArquivo,
    RedesSociais,
    Loader,
    Modal,
    Alerta,
    ModalBanner,
    EditarDestaque,
  },
  mixins: [modalMixin],
  name: "biblioteca",
  data: () => ({
    filtros: [
      {
        id: 4,
        titulo: "Políticas",
        img: require("@/assets/images/icone/icone-politicas.png"),
      },
      {
        id: 5,
        titulo: "Processos",
        img: require("@/assets/images/icone/icone-processos.png"),
      },
      {
        id: 6,
        titulo: "Procedimentos",
        img: require("@/assets/images/icone/icone-procedimentos.png"),
      },
      {
        id: 7,
        titulo: "Modelos",
        img: require("@/assets/images/icone/icone-modelos.png"),
      },
      {
        id: 8,
        titulo: "Outros documentos",
        img: require("@/assets/images/icone/icone-referencia.png"),
      },
    ],
    admin: false,
    mostrarModalAnexo: false,
    primeiroAcesso: true,
    idExclusao: null,
    tagURL: null,
    categorias: [],
    msg: [],
    filtro: {},
    anexos: {
      currentPage: 1,
      maxPages: 0,
      result: [],
    },
    termo: "",
    idFiltroSelecionado: 0,
    filtroPorCategoria: "",
    page: 1,
    anexoSelecionado: {},
    isLoading: true,
    grupo: "tipoAnexo",
    loader: false,
    extensao: [],
    tags: [],
    filtroArquivo: "",
    filtroTag: 0,
    modalAnexo: false,
    anexoEdicao: {
      id: null,
      titulo: null,
      descricao: null,
      nomAnexo: "",
      nomImagem: null,
      imagem: null,
      conteudo: null,
      rascunho: false,
      categoria: {},
      tags: [],
    },
    modalAlterarBanner: false,
    imagemModal: {},
    imageUrl: null,
    pagina: "",
    imagem: {
      banner: {
        tipoArquivo: "",
        arquivo: "",
      },
      imagem1: {
        tipoArquivo: "",
        arquivo: "",
      },
      imagem2: {
        tipoArquivo: "",
        arquivo: "",
      },
    },
    destaque1: {},
    destaque2: {},
    destaqueEditar: {},
    modalEditarDestaque: false,
  }),
  watch: {
    termo() {
      this.buscarAnexos(
        this.idFiltroSelecionado,
        this.page,
        this.filtroPorCategoria,
        this.filtroArquivo,
        this.filtroTag,
        this.termo
      );
    },
    filtroArquivo(e) {
      this.buscarAnexos(
        this.idFiltroSelecionado,
        this.page,
        this.filtroPorCategoria,
        e,
        this.filtroTag,
        this.termo
      );
    },
    filtroTag(e) {
      this.buscarAnexos(
        this.idFiltroSelecionado,
        this.page,
        this.filtroPorCategoria,
        this.filtroArquivo,
        e,
        this.termo
      );
    },
  },
  methods: {
    recarregar() {
      this.buscarAnexos(
        this.idFiltroSelecionado,
        this.page,
        vm.filtroPorCategoria,
        this.filtroArquivo,
        this.filtroTag,
        this.termo
      );
    },
    limparCampos() {
      this.anexoEdicao = {
        id: null,
        titulo: null,
        descricao: null,
        nomAnexo: "",
        nomImagem: null,
        imagem: null,
        conteudo: null,
        rascunho: null,
        categoria: {},
        tags: [],
      };
    },
    modalEditar(anexo) {
      const vm = this;
      vm.anexoEdicao = anexo;
      vm.modalAnexo = true;
    },
    buscarAnexos(categoria, pagina, nomeCategoria, extensaoAnexos, tag, termo) {
      const vm = this;
      vm.filtroPorCategoria = nomeCategoria;
      vm.idFiltroSelecionado = categoria;

      extensaoAnexos = extensaoAnexos == null ? "" : extensaoAnexos;
      vm.termo = vm.termo == null ? "" : vm.termo;
      tag = tag == null ? 0 : tag;

      if (termo == "") {
        vm.loader = true;
      }
      api
        .get(
          `anexo/listar/?categoria=${categoria}&pagina=${pagina}&extensao=${extensaoAnexos}&tag=${tag}&textoPesquisa=${vm.termo}`
        )
        .then((response) => {
          vm.anexos = response.data;
        })
        .catch((e) => {
          this.mensagemErro(e.message);
        })
        .finally(() => {
          vm.loader = false;
        });
    },
    buscarExtensaoAnexos() {
      const vm = this;
      api
        .get(`anexo/listar/extensao/`)
        .then((response) => {
          response.data.forEach((element) => {
            if (!vm.extensao.includes(element.tipoAnexo)) {
              vm.extensao.push(element.tipoAnexo);
            }
          });
        })
        .catch((e) => {
          this.mensagemErro(e.message);
        });
    },
    buscarTags() {
      const vm = this;
      api
        .get(`tag/listar/`)
        .then((response) => {
          response.data.forEach((element) => {
            if (!vm.tags.includes(element)) {
              vm.tags.push(element);
            }
          });
        })
        .catch((e) => {
          this.mensagemErro(e.message);
        });
    },
    abriModal() {
      this.modalAnexo = true;
    },
    excluir(idExclusao) {
      const vm = this;
      //solicitou exclusao mas ainda não houve a confirmação
      if (!vm.exclusaoConfirmada) {
        vm.confirmarExclusao("Deseja confirmar a exclusão?", idExclusao);
      }
      //Alerta confirmou exclusão, pegar id de exclusao salvo no mixin
      //exclusaoConfirmada é o id de exclusao salvo no mixin
      else {
        idExclusao = vm.exclusaoConfirmada;
        vm.fecharModal();
        vm.loader = true;

        api
          .get(`anexo/excluir/${idExclusao}`, {
            headers: { Authorization: localStorage.token },
            withCredentials: true,
          })
          .then((response) => {
            vm.loader = false;
            vm.mensagemSucesso(response.data);
          })
          .catch((e) => {
            vm.mensagemErro(e.message);
          })
          .finally(() => {
            vm.loader = false;
            this.buscarAnexos(
              this.idFiltroSelecionado,
              this.page,
              vm.filtroPorCategoria,
              this.filtroArquivo,
              this.filtroTag,
              this.termo
            );
          });
      }
    },
    buscarDestaques() {
      const vm = this;
      vm.loader = true;
      api
        .get("destaque-biblioteca")
        .then((res) => {
          vm.destaque1 = res.data[0];
          vm.destaque2 = res.data[1];
        })
        .catch((e) => {
          vm.mensagemErro(e.message);
        })
        .finally(() => {
          vm.loader = false;
        });
    },
    abrirModalEditarDestaque(obj) {
      const vm = this;
      vm.modalEditarDestaque = true;
      vm.destaqueEditar = obj;
    },
    sucessoEdicao() {
      const vm = this;
      vm.mensagemSucesso("Destaque atualizado com sucesso");
      vm.buscarDestaques();
    },
    fecharModalEdicao() {
      const vm = this;
      vm.modalEditarDestaque = false;
      vm.buscarDestaques();
    },
    isAdmin() {
      this.admin = Utils.isAdmin();
    },
    getImagem(id, pagina) {
      const vm = this;
      vm.loader = true;

      api
        .get("imagem/" + id)
        .then((response) => {
          //nome da variavel é o pagina. ex: vm.banner
          vm.imagem[pagina] = response.data;
        })
        .catch((e) => {
          vm.mensagemErro(e.message);
        })
        .finally(() => {
          vm.loader = false;
        });
    },
    atualizaImagem(pagina, imagem, tipoArquivo) {
      const vm = this;
      vm.imagem[pagina].arquivo = imagem;
      vm.imagem[pagina].tipoArquivo = tipoArquivo;
      vm.modalAlterarBanner = false;
    },
  },
  created() {
    const vm = this;
    vm.isAdmin();
  },
  async mounted() {
    const vm = this;
    vm.isAdmin();
    vm.buscarExtensaoAnexos();
    vm.buscarTags();
    vm.buscarDestaques();

    for (let pagina in idsBiblioteca) {
      //ex: this.getImagem(103, 'banner');
      vm.getImagem(idsBiblioteca[pagina], pagina);
    }

    if (this.$route.params.id) {
      vm.filtroTag = this.$route.params.id;

      setTimeout(() => {
        // Encontra o elemento desejado usando uma referência
        const elementoDesejado = this.$refs.pesquisa;

        // Rola para o elemento desejado
        elementoDesejado.scrollIntoView({ behavior: "smooth", block: "start" });
      }, 1000);
    } else {
      vm.buscarAnexos(
        this.idFiltroSelecionado,
        this.page,
        vm.filtroPorCategoria,
        this.filtroArquivo,
        this.filtroTag,
        this.termo
      );
    }
  },
};
</script>

<style scoped>
/* Filtros Novidades / Arquivos */
.destaque-inicio {
  position: relative;
  top: -3rem;
  margin: auto;
  overflow: hidden;
  max-width: 20rem;
  background-color: #fff;
  border-radius: 20px 20px 0 0;
  box-shadow: 0px 6px 8px 2px #00000026;
}

.destaque-inicio a {
  text-decoration: none;
}

.destaque-inicio p {
  margin-top: 1rem;
  color: #278b97;
  text-decoration: none;
  text-transform: uppercase;
  text-align: center;
  font-weight: 700;
}

.destaque-inicio .col:hover {
  background-color: #ecfdff;
  cursor: pointer;
}

.divider-filtro {
  margin: 1rem 0;
  border-color: #32acb9 !important;
  z-index: 1;
}

/* ----------------------------------- */
/* Conteúdo */
.divider-conteudo {
  margin: 3rem;
}

.conteudo-editavel-esquerda {
  position: relative;
}

.conteudo-editavel-esquerda .v-btn {
  position: absolute;
  top: 0.75rem;
  right: 0.75rem;
  z-index: 1;
}

.conteudo-editavel-direita {
  position: relative;
}

.conteudo-editavel-direita .v-btn {
  position: absolute;
  top: 0.75rem;
  left: 0.75rem;
  z-index: 1;
}

.alinhamento-texto {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem 4.5rem;
}

/* ----------------------------------- */
/* Arquivos */
.arquivos-texto {
  position: relative;
  margin: 3rem 0 5rem;
  padding: 2rem;
  background-color: #f5f5f5;
  text-align: center;
}

.arquivos-texto:after {
  content: "";
  position: absolute;
  right: calc(50% - 50px);
  top: 100%;
  border-left: 50px solid transparent;
  border-right: 50px solid transparent;
  border-top: 50px solid #f5f5f5;
}

.arquivos-texto p {
  max-width: 60%;
  margin: auto;
}

/* Cards Arquivo */
.row-card-arquivos {
  justify-content: center;
  margin: 0 1rem;
}

.card-arquivo {
  padding: 1rem;
  height: 100%;
  border-radius: 20px;
  border: 1px solid #fff;
  box-shadow: 0px 0px 20px #00000024 !important;
}

.card-arquivo:hover {
  border: 1px solid #278b97;
  background-color: #ecfdff !important;
  box-shadow: none !important;
}

.card-arquivo:hover p {
  color: #278b97;
}

.card-arquivo:hover .imagem-card-arquivo {
  filter: none;
}

.imagem-card-arquivo {
  display: flex;
  justify-content: center;
  margin: 1rem 0;
}

.titulo-card-arquivo {
  padding: 0 0.125rem;
  color: #278b97;
  text-align: center;
  text-transform: uppercase;
}

.card-arquivo-active {
  border: 1px solid #278b97;
  background-color: #278b97 !important;
  box-shadow: none !important;
}

.card-arquivo-active p {
  color: #fff;
}

.card-arquivo-active .imagem-card-arquivo {
  filter: brightness(0) invert(1);
}

.col-arquivo {
  display: flex;
  justify-content: center;
  padding: 1rem;
}

.col-arquivo:nth-child(odd) {
  border-right: 1px solid #32acb9;
}

/* Paginacao */
.paginacao {
  justify-content: center;
  margin: 1rem 0 2rem;
}

/* ----------------------------------- */
/* Responsividade */
@media screen and (max-width: 1264px) {
  .arquivos-texto p {
    max-width: 80%;
  }

  .col-arquivo:nth-child(odd) {
    border: none;
  }
}
</style>
