<template>
  <div>
    <Alerta
      v-on:fecharModal="fecharModal"
      :exibir="modal.exibir"
      :titulo="modal.titulo"
      :mensagem="modal.mensagem"
    />

    <loader :loader-primario="loader" />

    <RedesSociais />

    <ModalFaq
      v-if="modalFaq"
      v-on:fecha-modal="fecharModalFaq()"
      @erro="mensagemErro"
      @sucesso="mensagemSucesso"
    />

    <ModalBanner
      :modalAlterarBanner="modalAlterarBanner"
      :imagem="imagemModal"
      :pagina="pagina"
      :comLink="false"
      @atualizaImagem="atualizaImagem"
      v-on:fecha-modal="modalAlterarBanner = false"
      :customMethod="getImagem"
      @mensagem="mensagem"
      @loader="isLoading"
    />

    <v-container fluid class="pa-0">
      <v-btn
        v-if="admin"
        @click="
          modalAlterarBanner = true;
          imagemModal = imagem.banner;
          pagina = 'banner';
        "
        class="banner-btn botao-edicao-adm"
      >
        <v-icon>fa fa-pencil</v-icon>
      </v-btn>
      <v-img
        :src="
          'data:' +
          imagem.banner.tipoArquivo +
          ';base64,' +
          imagem.banner.arquivo
        "
        class="banner"
      ></v-img>
    </v-container>

    <div class="destaque">
      <v-container>
        <h1>
          Bem-vindo ao perguntas frequentes
          <span>FAQ</span>
        </h1>
        <p>
          Selecionamos aqui as dúvidas mais frequentes dos nossos colaboradores
          e suas respostas.<br />
          Busque pela sua dúvida, caso não ache sua resposta mande um e-mail
          para comunicação@hepta.com.br.<br />
          Contamos com sua participação para garantirmos a atualização dos dados
          e a melhoria constante da ferramenta.
        </p>
      </v-container>
    </div>

    <v-container>
      <v-row v-if="admin" class="justify-end">
        <v-col cols="12" md="4" xl="2">
          <v-btn @click="abrirModalFaq()" class="botao-principal w-100"
            >Adicionar Pergunta</v-btn
          >
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <h1 class="text-center">Como podemos te ajudar?</h1>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="6">
          <v-text-field
            dense
            solo
            hide-details="auto"
            v-model="buscar"
            @input="getDuvidas(), (pages = 1)"
            class="input-field"
          >
            <template slot="label">
              <span class="input-field-text">Pesquisar</span>
            </template>
            <template slot="append">
              <v-icon class="input-field-text">fas fa-magnifying-glass</v-icon>
            </template>
          </v-text-field>
        </v-col>
        <v-col cols="12" md="4">
          <v-select
            dense
            solo
            hide-details="auto"
            :items="listaCategorias"
            v-model="categoria"
            @input="getDuvidas(), (pages = 1)"
            class="input-field"
          >
            <template slot="label">
              <span class="input-field-text">Categorias</span>
            </template>
            <template slot="append">
              <v-icon class="input-field-text">fas fa-chevron-down</v-icon>
            </template>
          </v-select>
        </v-col>
        <v-col cols="12" md="2">
          <v-btn class="botao-limpar" @click="limparBusca()"
            >Limpar Busca</v-btn
          >
        </v-col>
      </v-row>

      <div v-if="buscar == '' && categoria == -1" class="duvidas-comuns">
        <div class="titulo">
          <p>Dúvidas mais comuns</p>
        </div>
        <v-row>
          <v-col
            v-for="duvida in duvidasComuns"
            :key="duvida.id"
            cols="12"
            md="6"
          >
            <router-link
              :to="'/pergunta/' + duvida.idItem"
              class="text-decoration-none"
            >
              <v-card class="card-duvida">
                <p>{{ duvida.pergunta }}</p>
                <v-chip
                  v-for="(categoria, index) in duvida.categorias"
                  :key="index"
                  class="chip-categoria"
                >
                  #{{ categoria }}
                </v-chip>
              </v-card>
            </router-link>
          </v-col>
        </v-row>
      </div>

      <v-row class="row-titulo-duvidas">
        <v-divider class="mr-8" />
        <h1>Dúvidas</h1>
        <v-divider class="ml-8" />
      </v-row>

      <v-row>
        <v-col v-for="duvida in duvidas" :key="duvida.id" cols="12">
          <router-link
            :to="'/pergunta/' + duvida.id"
            class="text-decoration-none"
          >
            <v-card class="card-duvida">
              <p>{{ duvida.pergunta }}</p>
              <v-chip class="chip-categoria">{{ duvida.categoriaFaq }}</v-chip>
            </v-card>
          </router-link>
        </v-col>
      </v-row>
    </v-container>

    <div>
      <v-container>
        <v-row justify="center">
          <v-col cols="5">
            <v-container class="max-width">
              <v-pagination
                v-model="pages"
                :length="totalPaginas"
                :total-visible="6"
                @input="getDuvidas()"
                circle
              ></v-pagination>
            </v-container>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>
import Loader from "@/components/Loader.vue";
import Alerta from "@/components/Alerta.vue";
import RedesSociais from "@/components/RedesSociais.vue";
import api from "@/services/Api";
import modalMixin from "@/mixins/modalMixin";
import ModalFaq from "../components/Modal/ModalFAQ.vue";
import ModalBanner from "@/components/Modal/ModalBanner.vue";
import { idsImagemFaq } from "@/utils/ImagemEnums.js";
import Utils from "@/utils/Utils";

export default {
  components: { RedesSociais, ModalFaq, Alerta, Loader, ModalBanner },
  name: "biblioteca",
  mixins: [modalMixin],
  data: () => ({
    duvidasComuns: [],
    duvidas: [],
    items: 7,
    pages: 1,
    buscar: "",
    categoria: -1,
    listaCategorias: [
      {
        value: 0,
        text: "TODAS AS CATEGORIAS",
      },
    ],
    idCategoria: [],
    itensCategoria: [],
    totalPaginas: 0,
    modalFaq: false,
    loader: false,
    pagina: "",
    imagemModal: {},
    imagem: {
      banner: {
        tipoArquivo: "",
        arquivo: "",
      },
    },
    admin: false,
    modalAlterarBanner: false,
  }),
  mounted() {
    this.getDuvidasComuns();
    this.getDuvidas();
    this.buscarTodasAsCategoria();
    this.isAdmin();

    for (let pagina in idsImagemFaq) {
      //ex: this.getImagem(103, 'banner');
      this.getImagem(idsImagemFaq[pagina], pagina);
    }
  },
  methods: {
    fecharModalFaq() {
      this.getDuvidasComuns();
      this.modalFaq = false;
    },
    getImagem(id, pagina) {
      const vm = this;
      vm.loader = true;
      api
        .get("imagem/" + id)
        .then((response) => {
          //nome da variavel é o pagina. ex: vm.banner
          vm.imagem[pagina] = response.data;
        })
        .catch((e) => {
          vm.mensagemErro(e.message);
        })
        .finally(() => {
          vm.loader = false;
        });
    },
    atualizaImagem(pagina, imagem, tipoArquivo) {
      const vm = this;
      vm.imagem[pagina].arquivo = imagem;
      vm.imagem[pagina].tipoArquivo = tipoArquivo;
      vm.modalAlterarBanner = false;
    },
    isAdmin() {
      this.admin = Utils.isAdmin();
    },
    isLoading(e) {
      this.loader = e;
    },
    getDuvidasComuns() {
      this.loader = true;
      api
        .get("visualizacao/listar", {
          headers: { Authorization: localStorage.token },
          withCredentials: true,
        })
        .then((response) => {
          if (response.status == 200) {
            this.duvidasComuns = response.data;
            this.duvidasComuns.forEach((d) => {
              d.categorias = d.categorias.split("#");
              d.categorias.shift();
            });
          }
        })
        .catch((e) => {
          this.mensagemErro(e.message);
        })
        .finally(() => {
          this.loader = false;
        });
    },
    limparBusca() {
      this.categoria = -1;
      this.buscar = "";
      this.getDuvidas();
    },
    abrirModalFaq() {
      this.modalFaq = true;
    },
    buscarTodasAsCategoria() {
      this.loader = true;
      api
        .get(`categoria-faq/listarCategorias`, {
          headers: { Authorization: localStorage.token },
          withCredentials: true,
        })
        .then((response) => {
          if (response.status == 200) {
            response.data.forEach((r) =>
              this.listaCategorias.push({
                value: r.id,
                text: r.nome.replace("&", " E "),
              })
            );
          }
        })
        .catch((e) => {
          this.mensagemErro(e.message);
        })
        .finally(() => {
          this.loader = false;
        });
    },
    getDuvidas() {
      this.loader = true;
      var paramIdCategoria = "";

      if (this.categoria > 0)
        paramIdCategoria = "&idCategoria=" + this.categoria;

      api
        .get(
          `itemfaq/listarTodos/?buscar=${this.buscar}${paramIdCategoria}&pages=${this.pages}&items=${this.items}`,
          {
            headers: { Authorization: localStorage.token },
            withCredentials: true,
          }
        )
        .then((response) => {
          if (response.status == 200) {
            this.duvidas = response.data.listObjects;
            this.totalPaginas = Math.ceil(
              response.data.totalItens / this.items
            );
          }
        })
        .catch((e) => {
          this.mensagemErro(e.message);
        })
        .finally(() => {
          this.loader = false;
        });
    },
  },
};
</script>

<style scoped>
.banner {
  position: relative;
}

.banner-btn {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
}

.destaque {
  position: relative;
  display: flex;
  text-align: center;
  align-items: center;
  background-color: #f5f5f5;
  height: 12rem;
}

.destaque:after {
  content: "";
  position: absolute;
  top: 100%;
  right: calc(50% - 3rem);
  border-top: 3rem solid #f5f5f5;
  border-right: 3rem solid transparent;
  border-left: 3rem solid transparent;
}

.destaque h1 {
  color: #f8ba00;
  margin-bottom: 1rem;
}

.destaque h1 span {
  color: #ff0c23;
  font-size: 1.25rem;
}

.titulo {
  position: absolute;
  top: -1.25rem;
  left: 50%;
  transform: translateX(-50%);
  width: fit-content;
  padding: 0.5rem;
  border-radius: 12px;
  background-color: #278b97;
  color: #fff;
}

.titulo p {
  color: #ffffff;
  margin-bottom: 0;
}

.duvidas-comuns {
  position: relative;
  border-radius: 20px;
  padding: 4rem;
  margin-top: 4rem;
  background-color: #f5f5f5;
}

.chip-categoria {
  margin: 0.5rem 0.5rem 0;
  border-color: #278b97;
  background-color: #278b97 !important;
  color: #fff;
  font-weight: 600;
}

.card-duvida {
  cursor: pointer;
  padding: 1.5rem 3rem;
  border-radius: 20px;
  box-shadow: 0px 0px 12px #00000012 !important;
  height: 100%;
}

.row-titulo-duvidas {
  margin: 3rem 0 1rem;
  align-items: center;
}

@media screen and (max-width: 768px) {
  .destaque {
    height: 18rem;
    margin-bottom: 3rem;
  }

  .duvidas-comuns {
    padding: 2.5rem 0.5rem 1rem;
  }

  .titulo {
    top: -2rem;
  }
}
</style>
