<template>
  <!--   <div class="grid grid2">
    <div class="msgm">
      <div>
        <h1 id="ops">OPS!</h1>
        <h1 id="textoerro">Página não encontrada!</h1>
        <v-btn id="botaoerro">
          <v-icon class="iconebtn">fas fa-arrow-left</v-icon>Voltar</v-btn
        >
      </div>
    </div>
    <div>
      <v-container id="img">
        <v-img src="../assets/images/erro/erro_img.png"></v-img>
      </v-container>
    </div>
  </div> -->
  <v-container>
    <v-row class="linha-erro">
      <v-col cols="12" sm="12" md="12" class="alinhamento">
        <div class="texto">
          <h1 id="ops">OPS!</h1>
          <h1 id="textoerro">Página não encontrada!</h1>
          <v-btn id="botaoerro" @click="home()">
            <v-icon class="iconebtn">fas fa-arrow-left</v-icon>Voltar
          </v-btn>
        </div>
      </v-col>
      <v-col cols="12" sm="12" md="6">
        <v-img src="../assets/images/erro/erro_img.png" width="100%"></v-img>
      </v-col>
    </v-row>
  </v-container>
</template>


<script>

export default {
  data() {
    return {
    }
  },
  methods: {
    home() {
      this.$router.push({
        path: "/home",
      });
    }
  }
};
</script>

<style scoped>
.alinhamento {
  display: flex;
  justify-content: center;
  align-items: center;
}

#ops {
  font-size: 130px;
  color: #32acb9;
}

#img {
  padding: 0px;
  margin: 0px 0px;
}

.msgm {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 72px;
}

.grid2 {
  display: flex;
  justify-content: center;
}

.grid {
  display: grid;
  grid-template-columns: 500px 1000px;
  gap: 20px;
}

#textoerro {
  font-size: 28px;
  margin-top: -20px;
}

#botaoerro {
  margin-top: 20px;
  padding: 20px 40px;
  background: transparent;
  box-shadow: none;
  color: #278b97;
  border-radius: 10px;
  border: 2px solid;
  font-family: Montserrat;
  font-weight: 600;
}

.iconebtn {
  margin-right: 10px;
  font-size: 20px !important;
}

@media screen and (max-width: 960px) {
  .linha-erro {
    flex-direction: column-reverse;
  }

  .texto {
    text-align: center;
  }
}

@media (min-width: 961px) and (max-width: 1264px) {
  #ops {
    font-size: 100px;
  }

  #textoerro {
    font-size: 18px;
    margin-top: 0px;
  }

  #botaoerro {
    padding: 20px 30px;
  }
}

@media screen and (max-width: 700px) {
  #ops {
    font-size: 90px;
  }

  #textoerro {
    font-size: 15px;
    margin-top: 0px;
  }

  #botaoerro {
    padding: 20px 30px;
  }
}
</style>
