<template>
  <div>
    <Loader :loader-primario="loader" />

    <Alerta v-on:fecharModal="fecharModal" :exibir="modal.exibir" :titulo="modal.titulo" :mensagem="modal.mensagem" />

    <v-container>
      <div v-if="visaoTabela">
        <!-- Título -->
        <v-row>
          <v-col>
            <h1 class="titulo-avaliacao">
              Histórico
              <span>Veja o histórico do Programa de Gestão de Desempenho</span>
            </h1>
          </v-col>
        </v-row>

        <!-- Tabela Histórico -->
        <v-row>
          <v-col>
            <v-data-table :headers="headers" :items="listaCiclos" show-empty>
              <template v-slot:item.status="{ item }">
                <div :class="atribuirClasseStatus(item.status)">{{ atribuirTextoStatus(item.status) }}</div>
              </template>

              <template v-slot:item.feedbackColaborador="{ item }">
                <v-tooltip top target="tooltip-target" class="custom-tooltip">

                  <template v-slot:activator="{ on, attrs }">
                    <div v-if="item.feedbackColaborador || item.feedbackColaborador === false"
                      :class="atribuirClasseFeedback(item.feedbackColaborador)" id="tooltip-target" v-bind="attrs"
                      v-on="on">
                      <v-icon :class="atribuirIconeFeedback(item.feedbackColaborador)">
                        {{ atribuirIconeFeedback(item.feedbackColaborador) }}
                      </v-icon>
                    </div>
                  </template>

                  <span>{{ atribuirTooltipFeedback(item.feedbackColaborador) }}</span>
                </v-tooltip>
              </template>

              <template v-slot:item.acao="{ item }">
                <v-icon v-on:click="abrirCiclo(item)" class="icone-acao">fas fa-eye</v-icon>
              </template>

              <template v-slot:no-data>
                <span class="aviso-vazio">
                  <v-icon>fas fa-exclamation-circle</v-icon>
                  Você ainda não está em nenhum ciclo de avaliação de desempenho.
                </span>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </div>

      <div v-else>
        <!-- Título -->
        <v-row>
          <v-col>
            <h1 class="titulo-avaliacao">{{ objCiclo.nomeCiclo }}</h1>
          </v-col>
        </v-row>

        <!-- Breadcrumb -->
        <div class="breadcrumb">
          <div @click="visaoTabela = true" class="arrow">
            <p>Histórico</p>
          </div>
          <div class="arrow">
            <p>Detalhes</p>
          </div>
        </div>

        <MainHistorico ref="mainHistorico" :ciclo="objCiclo" />
      </div>
    </v-container>
  </div>
</template>
  
<script>
import Loader from '@/components/Loader.vue';
import Alerta from '@/components/Alerta.vue';
import modalMixin from '@/mixins/modalMixin';
import api from "@/services/Api.js";

import MainHistorico from './HistoricoTabs/MainHistorico.vue';

export default {
  name: 'Historico',
  components: { Loader, Alerta, api, MainHistorico },
  mixins: [modalMixin],
  data() {
    return {
      loader: false,
      visaoTabela: true,

      page: 1,
      perPage: 10,
      totalCiclos: 0,

      objCiclo: null,

      listaCiclos: [],
      headers: [ /* Campos de cabeçalho da tabela */
        { value: 'nomeCiclo', text: 'Ciclo', align: 'center' },
        { value: 'nomeAvaliador', text: 'Avaliador', align: 'center' },
        { value: 'status', text: 'Status', align: 'center' },
        { value: 'feedbackColaborador', text: 'Feedback', align: 'center' },
        { value: 'acao', text: 'Ação', align: 'center' },
      ],
    };
  },
  methods: {
    listarCiclos() {
      this.loader = true;
      api.get(`avaliacaoDesempenho/ciclosColaborador`, {
        headers: { Authorization: localStorage.token }, withCredentials: true,
        params: {
          page: this.page,
          perPage: this.perPage,
        },
      })
        .then(response => {
          if (response.status === 200) {
            this.page = response.data.currentPage;
            this.totalCiclos = response.data.maxPages
            this.listaCiclos = response.data.result;
          };
        })
        .catch(e => { this.mensagemErro(e.message) })
        .finally(() => { this.loader = false });
    },
    atribuirClasseStatus(status) {
      switch (status) {
        case 'Encerrado':
          return 'status status-concluida';
        case 'Iniciado':
          return 'status status-andamento'
      }
    },
    atribuirTextoStatus(status) {
      switch (status) {
        case 'Encerrado':
          return 'Concluída';
        case 'Iniciado':
          return 'Em Andamento'
      }
    },
    atribuirClasseFeedback(feedback) {
      if (feedback) return 'caixa caixa-verde';
      else if (feedback === false) return 'caixa caixa-vermelha';
      else return 'caixa caixa-cinza';
    },
    atribuirIconeFeedback(feedback) {
      if (feedback) return 'fas fa-check';
      else if (feedback === false) return 'fas fa-times';
      else return 'fas fa-question';
    },
    atribuirTooltipFeedback(feedback) {
      if (feedback) return 'Você confirmou ter recebido o feedback com o avaliador.';
      else if (feedback === false) return 'Você confirmou NÃO ter recebido o feedback com o avaliador.';
      else return `O botão de feedback será liberado dentro do histórico ao final do ciclo de avaliação e você 
                    poderá confirmar se recebeu ou não o feedback do seu gestor.`;
    },
    abrirCiclo(item) {
      this.objCiclo = item;
      this.visaoTabela = false;
    },
  },
  created() {
    this.listarCiclos();
  },
};
</script>
  
<style scoped>
.aviso-vazio {
  font-size: 1rem;
}

/* ------------------------- */
/* Status */
.status {
  display: flex;
  justify-content: center;
  padding: 0.25rem;
  font-size: 1rem;
  font-weight: 600;
  border-radius: 8px;
}

.status-concluida {
  color: #32B92D;
  border: 2px solid #32B92D;
}

.status-andamento {
  color: #FDC600;
  border: 2px solid #FDC600;
}

/* ------------------------- */
/* Caixa Feedback */
.caixa {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin: 0 auto;
  width: 2rem;
  height: 2rem;
  border-radius: .25rem;
}

.caixa:hover {
  filter: brightness(0.85);
}

.caixa .v-icon {
  color: #FFF;
}

.caixa-verde {
  background-color: #4CAF50;
}

.caixa-vermelha {
  background-color: #D60F19;
}

.caixa-cinza {
  background-color: #A9A9A9;
}

/* ------------------------- */
/* Breadcrumb */
.breadcrumb {
  display: flex;
  margin: 1rem 0 2rem;
}

.arrow {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 8rem;
  height: 2.125rem;
  background: #edefee;
  cursor: pointer;
}

.arrow::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 90%;
  transform: translateY(-50%) rotate(45deg);
  z-index: 1;
  width: 1.5rem;
  height: 1.5rem;
  background-color: #edefee;
}

.arrow p {
  font-size: 0.875rem;
  margin-bottom: 0 !important;
}

.arrow:last-child {
  padding: 0 10px 0 20px;
  background: #278b97;
}

.arrow:last-child p {
  color: #ffffff;
}

.arrow:last-child:before {
  background: #278b97;
  border: none;
}
</style>
