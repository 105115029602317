<template>
  <v-container>
    <v-dialog @click:outside="fecharModal()" v-model="show" :retain-focus="false">
      <v-card>
        <v-card-title>
          <h3>{{ titulo }}</h3>
          <v-icon @click="fecharModal()">fa-times</v-icon>
        </v-card-title>
        <v-card-text class="card-text">
          <!-- Alerta -->
          <div class="alerta">
            <v-row>
              <v-col cols="12" md="2" class="alerta-icone">
                <v-img src="@/assets/images/icone/espaco-colaborador/icone_sinal_de_aviso.svg"></v-img>
              </v-col>
              <v-col cols="12" md="10" class="alerta-texto">
                <span v-if="tipo === 1">
                  Para saber os valores da mensalidade, percentuais de participação da Hepta e outras informações sobre
                  coparticipação, reembolso e carência, acesse
                  <a href="https://nossahepta.com.br/IntranetHepta/#/detalhe-academia?id=1207&pesquisa=false&artigo=true"
                    target="_blank">Nossa Hepta > Espaço do Colaborador > Benefícios > Plano de Saúde</a>
                </span>
                <span v-else>
                  Para saber valores das mensalidade, acesse
                  <a href="https://nossahepta.com.br/IntranetHepta/#/detalhe-academia?id=302&pesquisa=false&artigo=true"
                    target="_blank">Nossa Hepta > Espaço do Colaborador> Benefícios > Plano odontológico</a>
                </span>
              </v-col>
            </v-row>
          </div>

          <!-- Carência -->
          <v-row>
            <v-col cols="12">
              <v-alert show class="carencia">
                <h4 class="text-left">Carência: </h4>
                <v-divider class="my-2" />
                <p class="text-justify">
                  Adesões fora do prazo de 30 dias após o ingresso/contratação tem carência, inclusive para inclusão de
                  dependentes. Os prazos estão indicados no texto completo sobre Plano de Saúde.<br />
                  Apenas duas situações de inclusão de dependente não tem carência:<br />
                <ul>
                  <li>Casamento (até 30 dias após o casamento)</li>
                  <li>Filho recém nascido (primeiros 30 dias de vida)</li>
                </ul>
                <br />
                Novos colaboradores: adesão sem carência nos primeiros 30 dias
                </p>
              </v-alert>
            </v-col>
          </v-row>

          <!-- Select Plano -->
          <v-row>
            <v-col>
              <v-select label="Plano Desejado" v-model="beneficios.selected" :items="beneficios.options"
                :item-text="beneficios.textField" :item-value="beneficios.valueField" outlined class="input-field">
                <template slot="label">
                  <span class="input-field-text">Plano Desejado</span>
                </template>
                <template slot="append">
                  <v-icon class="input-field-text">fas fa-chevron-down</v-icon>
                </template>
              </v-select>
            </v-col>
          </v-row>
          <div v-if="beneficios.selected">
            <v-row class="adicionar-dependente">
              <v-col cols="12" md="8">
                <h4>
                  <v-icon small class="mr-2">fas fa-caret-right</v-icon> Quais dependentes você deseja adicionar ao plano?
                </h4>
              </v-col>
              <v-col cols="12" md="4">
                <v-btn @click="abrirFormAdicionarDependente = true" class="botao-principal w-100"
                  :disabled="abrirFormAdicionarDependente">
                  <v-icon class="mr-2">fas fa-circle-plus</v-icon> Adicionar Novo Dependente
                </v-btn>
              </v-col>
              <v-col cols="12">
                <v-form ref="form" lazy-validation>
                  <div v-if="abrirFormAdicionarDependente">
                    <v-row>
                      <v-col cols="12" md="4">
                        <v-text-field v-model="vModel.nome" outlined dense hide-details="auto" class="input-field"
                          :rules="[(v) => !!v || 'Obrigatório informar o nome']">
                          <template slot="label">
                            <span class="input-field-text">Nome do dependente</span>
                          </template>
                        </v-text-field>
                      </v-col>
                      <v-col cols="12" md="4">
                        <v-text-field v-model="vModel.nomeMae" outlined dense hide-details="auto" class="input-field"
                          :rules="[(v) => !!v || 'Obrigatório informar o nome da mãe']">
                          <template slot="label">
                            <span class="input-field-text">Nome da mãe do dependente</span>
                          </template>
                        </v-text-field>
                      </v-col>
                      <v-col cols="12" md="4">
                        <v-text-field v-model="vModel.cpf" v-mask="'###.###.###-##'" outlined dense hide-details="auto"
                          class="input-field" :rules="[(v) => !!v || 'Obrigatório informar o CPF']">
                          <template slot="label">
                            <span class="input-field-text">CPF</span>
                          </template>
                        </v-text-field>
                      </v-col>
                      <v-col cols="12" md="4">
                        <v-text-field v-model="vModel.dtNascimento" type="date" outlined dense hide-details="auto"
                          class="input-field" :rules="[(v) => !!v || 'Obrigatório informar data de nascimento']">
                          <template slot="label">
                            <span class="input-field-text">Data de Nascimento</span>
                          </template>
                        </v-text-field>
                      </v-col>
                      <v-col cols="12" md="4">
                        <v-select v-model="vModel.sexo" :items="item.sexo" item-text="descricao" item-value="id"
                          :menu-props="{ bottom: true, offsetY: true }" outlined dense hide-details="auto"
                          class="input-field" :rules="[(v) => v !== null || 'Obrigatório informar o sexo']">
                          <template slot="label">
                            <span class="input-field-text">Sexo</span>
                          </template>
                        </v-select>
                      </v-col>
                      <v-col cols="12" md="4">
                        <v-select v-model="vModel.grauParentesco" item-text="descricao" item-value="id"
                          :menu-props="{ bottom: true, offsetY: true }" :items="item.grauParentesco" outlined dense
                          hide-details="auto" class="input-field"
                          :rules="[(v) => !!v || 'Obrigatório informar o grau de parentesco']">
                          <template slot="label">
                            <span class="input-field-text">Grau de Parentesco</span>
                          </template>
                        </v-select>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" md="6">
                        <v-btn @click="fecharFormNovoDependente()" class="botao-cancelar w-100">Cancelar</v-btn>
                      </v-col>
                      <v-col cols="12" md="6">
                        <v-btn @click="salvarDependente()" class="botao-principal w-100">Adicionar dependente</v-btn>
                      </v-col>
                    </v-row>
                  </div>
                </v-form>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-data-table :headers="headerDependente" :items="item.dependentes" no-data-text="Nenhum dependente"
                  hide-default-footer class="mb-7">
                  <template v-for="header in headerDependente.filter((header) => header.hasOwnProperty('formatter'))"
                    v-slot:[`item.${header.value}`]="{ value }">
                    {{ header.formatter(value) }}
                  </template>

                  <template v-slot:[`item.action`]="{ item }">
                    <v-row justify="center">
                      <v-checkbox v-model="item.selected" @change="selecionarDependente(item)"></v-checkbox>
                    </v-row>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </div>

          <v-divider class="my-3"></v-divider>
        </v-card-text>

        <!-- Ações Modal -->
        <v-card-actions>
          <v-btn @click="fecharModal()" class="botao-secundario-par mr-2 largura-btn">Fechar</v-btn>
          <v-btn @click="solicitar()" class="botao-principal mr-2 largura-btn">{{ !alteracaoPlano ? 'Solicitar' : 'Alterar' }} </v-btn>
        </v-card-actions>
      </v-card>

      <alerta v-on:fecharModal="fecharModal" :exibir="modal.exibir" :titulo="modal.titulo" :mensagem="modal.mensagem" />

      <loader :loader-primario="loader" />
    </v-dialog>
  </v-container>
</template>
  
<script>
import api from "@/services/Api.js";
import alerta from "@/components/Alerta.vue";
import modalMixin from "@/mixins/modalMixin";
import loader from "@/components/Loader.vue";
import { montarListaSexo } from "@/enums/sexoEnum.js";
import { montarListaGrauParentesco } from "@/enums/grauParentescoEnum.js";
import { TipoMudancaEnum } from "@/enums/beneficio/TipoMudancaEnum.js";
import { colocarMascaraCPF } from "@/utils/CPFUtils.js";
import VueMask from "v-mask";
export default {
  name: "formSolicitarPlano",
  components: {
    alerta,
    loader
  },
  mixins: [modalMixin],
  props: {
    value: Boolean,
    titulo: {
      type: String,
      default: "solicitar plano",
    },
    tipo: Number, // 1 - plano de saude // 2 - plano dental
    alteracaoPlano: { // true - a modal se comporta para alterar um plano anterior // false - a modal se comporta para solicitar um plano
      type: Boolean,
      default: false
    },
    beneficioAnterior: {
      type: Object,
      default: () => ({})
    },
  },
  data() {
    return {
      paths: {
        dependente: "beneficios/dependente",
        pedido: "beneficios/pedido/plano",
        lista: "/beneficios/lista"
      },
      loader: false,
      beneficios: {
        options: [],
        selected: null,
        valueField: "id",
        textField: "nome",
      },
      abrirFormAdicionarDependente: false,
      item: {
        sexo: [],
        grauParentesco: [],
        dependentes: [],
      },
      vModel: {
        cpf: null,
        nome: null,
        nomeMae: null,
        grauParentesco: null,
        dtNascimento: null,
        sexo: null,
        dependentes: [],
      },
      dependentes: [],
      headerDependente: [
        {
          text: "Nome",
          value: "nome",
          width: "30%",
          formatter: (x) => (x ? x.toUpperCase() : "-"),
        },
        {
          text: "CPF",
          value: "cpf",
          width: "25%",
          formatter: (x) => (x ? colocarMascaraCPF(x) : "-"),
        },
        {
          text: "Parentesco",
          value: "grauParentesco.nome",
          width: "25%",
          formatter: (x) => (x ? x.toUpperCase() : "-"),
        },
        {
          text: "Selecionar",
          value: "action",
          width: "20%",
          align: "center",
        },
      ],
    };
  },
  methods: {
    fecharModal() {
      const vm = this;
      vm.beneficios.selected = null;
      vm.show = false;
    },
    buscarBeneficios() {
      const vm = this;
      vm.loader = true;
      api
        .get(`${vm.paths.lista}/${vm.tipo}`)
        .then((res) => {
          vm.beneficios.options = res.data;
        })
        .finally(() => (vm.loader = false));
    },
    async fecharFormNovoDependente() {
      const vm = this;
      await vm.$refs["form"].reset();
      vm.abrirFormAdicionarDependente = false;
    },
    async buscarDependentes() {
      const vm = this;
      vm.loader = true;
      await api
        .get(vm.paths.dependente)
        .then(async (res) => {
          await (vm.item.dependentes = res.data
            .filter((d) => !vm.dependentes.some((s) => s.id === d.id))
            .map((x) => {
              return {
                ...x,
                selected: vm.vModel.dependentes.some((d) => d.id === x.id)
                  ? true
                  : false,
              };
            }));
        })
        .catch((e) => {
          vm.mensagemErro(e.message)
          console.log('aqui');
        })
        .finally(() => (vm.loader = false));
    },
    selecionarDependente(item) {
      const vm = this;
      vm.vModel.dependentes.some((d) => d.id === item.id)
        ? vm.vModel.dependentes.splice(
          vm.vModel.dependentes.findIndex((i) => i.id === item.id),
          1
        )
        : vm.vModel.dependentes.push(item);
    },
    async salvarDependente() {
      const vm = this;
      if (!vm.$refs["form"].validate()) return;
      vm.loader = true;
      var dto;
      dto = vm.montarDTODependente();
      api
        .post(vm.paths.dependente, dto)
        .then(async (res) => {
          await vm.buscarDependentes();
          await vm.$refs["form"].reset();
          vm.abrirFormAdicionarDependente = false;
        })
        .catch((e) => vm.mensagemErro(e.message))
        .finally(() => (vm.loader = false));
    },
    montarDTODependente() {
      const vm = this;
      var dto = {
        nome: vm.vModel.nome,
        cpf: vm.vModel.cpf,
        grauParentesco: {
          id: vm.vModel.grauParentesco,
        },
        dtNascimento: vm.vModel.dtNascimento,
        sexo: vm.vModel.sexo,
        nomeMae: vm.vModel.nomeMae,
      };
      return dto;
    },
    montarDTOPedido() {
      const vm = this;
      var dto = {
        beneficio: {
          id: vm.beneficios.selected,
        },
        tipoMudanca: {
          id: TipoMudancaEnum.ADESAO,
        },
        dependentes: vm.vModel.dependentes,
      };
      return dto;
    },
    montarDTOPedidoAlteracao() {
      const vm = this;
      var dto = {
        beneficio: {
          id: vm.beneficios.selected,
        },
        beneficioAnterior: vm.beneficioAnterior,
        tipoMudanca: {
          id: TipoMudancaEnum.SOLICITACAO_MUDANCA,
        },
        dependentes: vm.vModel.dependentes,
      };
      return dto;
    },
    solicitar(){
      const vm = this;
      if(vm.alteracaoPlano) vm.solicitarAlteracao();
      else vm.solicitarAdesao();
    },
    montarUrl(){
      const vm = this
      const urlTipo = vm.tipo == 1 ? "/alteracao/saude" : "/alteracao/dental";
      return "beneficios/pedido" + urlTipo;
    },
    solicitarAdesao() {
      const vm = this
      const url = vm.montarUrl();
      vm.loader = true;
      api.post(url, vm.montarDTOPedido())
        .then(res => {
          vm.$emit("create", res.data)
        })
        .catch((e) => vm.mensagemErro(e.message))
        .finally(() => {
          vm.loader = false
          vm.fecharModal()
        });
    },
    solicitarAlteracao(){
      const vm = this
      const url = vm.montarUrl();
      vm.loader = true;
      api.post(url, vm.montarDTOPedidoAlteracao())
        .then(res => {
          vm.$emit("create", res.data)
        })
        .catch((e) => vm.mensagemErro(e.message))
        .finally(() => {
          vm.loader = false
          vm.fecharModal()
        });
  },
  },
  computed: {
    show: {
      get() {
        if (this.value == true) this.buscarDependentes();
        return this.value;
      },
      set(value) {
        const vm = this;
        this.$emit("input", value);
      },
    },
  },
  async mounted() {
    const vm = this;
    vm.buscarBeneficios();
    await (vm.item = {
      sexo: montarListaSexo(),
      grauParentesco: montarListaGrauParentesco(),
    });
  },
};
</script>

<style scoped>
.v-dialog>.v-card>.v-card__text {
  padding: 1rem 2rem !important;
}

.input-field::v-deep .v-input__slot {
  background-color: #DDD !important;
}

.alerta {
  background-color: #DDD;
}

.carencia {
  background: transparent;
}

.adicionar-dependente {
  text-align: left;
  align-items: center;
}
</style>
