<!-- 
  Caixa mostrando uma mensagem de aviso.
  Props:
  - message: String. Mensagem que aparecerá no aviso.
  - buttons: Boolean. Default: false. Se 'true', o componente terá botões que enviam true/false.
  - bg_hex: String. Default: '#EDEFEE'. Hexadecimal da cor para o fundo.
 -->
<template>
  <v-row class="custom-alert" :style="{background: bg_hex}">
    <v-col cols="12" md="auto" class="icon">
      <v-icon>fas fa-exclamation-triangle</v-icon>
    </v-col>

    <v-divider v-if="$vuetify.breakpoint.mdAndUp" vertical />

    <v-col cols="12" :md="buttons ? '12' : 'auto'" class="message">
      <span class="bold">Aviso!</span>&nbsp;
      <br v-if="$vuetify.breakpoint.smAndDown" />
      <span v-html="message"></span>

      <v-row v-if="buttons" class="buttons">
        <v-col cols="12" md="6" lg="4" xl="2">
          <v-btn @click="clickBtn(true)" class="w-100">Sim</v-btn>
        </v-col>
        <v-col cols="12" md="6" lg="4" xl="2">
          <v-btn @click="clickBtn(false)" class="false w-100">Não</v-btn>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'Aviso',
  props: {
    message: { type: String, required: true },
    buttons: { type: Boolean, required: false, default: false },
    bg_hex: { type: String, required: false, default: '#EDEFEE'},
  },
  data() {
    return {
      btnBool: null,
    }
  },
  methods: {
    clickBtn(bool) {
      this.$emit('btn-value', bool);
    },
  },
};
</script>

<style scoped>
.custom-alert {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin: 1rem 0;
  padding: 2rem;
  border-radius: 20px;
}

.icon {
  text-align: center;
}

.icon .v-icon {
  color: #278B97;
  font-size: 3.5rem;
}

hr {
  margin: 0 2rem;
  border-width: 1px;
}

.message {
  flex: 1;
  min-width: 0;
}

.message .bold {
  font-weight: 700;
}

.buttons {
  justify-content: right;
  margin: 1rem 0 -1rem;
}

.buttons .v-btn {
  background-color: #278B97 !important;
  color: #FFF !important;
  border-radius: 12px !important;
  border: 1px solid #EDEFEE;
  font-family: Montserrat;
  font-weight: 600 !important;
  box-shadow: none !important;
}

.buttons .false {
  background-color: #EDEFEE !important;
  color: #278B97 !important;
  border: 1px solid #278B97;
}

.buttons .v-btn:hover {
  filter: brightness(90%);
}

@media screen and (max-width: 960px) {
  .message {
    text-align: center;
  }
}
</style>
