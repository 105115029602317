<template>
  <v-card @click="redirecionar(id)" class="vaga">
    <!-- Por ora pediram para retirar a tag de vaga interna
    <v-chip v-if="isInterna" class="tag interna">Interna</v-chip> -->
    <v-chip v-if="isNova" class="tag nova">Nova</v-chip>
    <v-card-text>
      <h4>{{ titulo }}</h4>
      <div>
        <p>{{ tipo }}</p>
        <v-divider></v-divider>
      </div>
      <span>
        <v-icon small class="mr-2">fas fa-location-dot</v-icon>{{ local }}
        <br />
        <v-icon small class="mr-2">fas fa-calendar-days</v-icon>{{ data }}
      </span>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: {
    titulo: String,
    tipo: String,
    local: String,
    data: String,
    id: Number,
    isInterna: Boolean
  },
  data() {
    return {
      isNova: false
    };
  },
  methods: {
    redirecionar(id) {
      // window.open(
      //   "https://www.hepta.com.br/carreiras/oportunidades/" + id,
      //   "_blank"
      // );
      this.$router.push('/vaga/' + id)
    },
    verificarSeVagaENova() {
      const vm = this;
      const partesData = vm.data.split("/");
      const dia = parseInt(partesData[0], 10);
      const mes = parseInt(partesData[1], 10);
      const ano = parseInt(partesData[2], 10);
      const dataFormatada = new Date(ano, mes, dia);
      const dataAtual = new Date();
      const diferenca = dataAtual - dataFormatada; //milissegundos
      const diferencaEmDias = Math.floor(diferenca / (1000 * 60 * 60 * 24));
      vm.isNova = diferencaEmDias <= 30;
    }
  },
  mounted() {
    this.verificarSeVagaENova();
  }
};
</script>

<style scoped>
.vaga {
  border-radius: 24px;
  margin: 1rem 1rem !important;
  width: 100%;
  height: 14rem;
  background-color: #F9F9F9;
  box-shadow: none !important;
}

.vaga .v-card__text {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 100%;
  padding: 1rem !important;
}

.vaga h4 {
  font-size: 1rem;
  text-transform: initial;
  color: #278b97;
}

.vaga p {
  color: #F8BB00 !important;
  font-weight: 700;
}

.vaga hr {
  border-color: #F8BB00 !important;
  border-width: 1px !important;
}


.vaga span {
  color: #278b97;
  font-weight: 600;
  font-size: 0.75rem;
}

.vaga .v-icon {
  color: #278b97;
}

.tag {
  position: absolute;
  top: -7%;
  right: -2%;
  color: #FFF !important;
  border: 2px solid #FFF;
  border-radius: 8px;
}

.interna {
  background-color: #F8BB00 !important;
  margin-right: 4rem;
}

.nova {
  background-color: #DB1826 !important;
}
</style>
