<template>
    <v-app>
      <Header></Header>
      <v-main>
        <transition name="scroll-x-transition" mode="out-in">
          <router-view />
        </transition>
      </v-main>
      <Footer></Footer>
    </v-app>
  </template>
  <script>
  import Footer from '@/components/Footer.vue';
  import Header from '@/components/Header.vue';
  export default {
    data() {
      return {};
    },
    components: {
      Header,
      Footer,
    },
  };
  </script>
  <style scoped>
  .bg-linhas {
    position: absolute;
    width: 100vw;
  }
  .altura_paginas {
    min-height: calc(100vh - 215px - 76px);
  }
  
  .background {
    background-color: #2e2a25!important;
  }
  </style>
  