<template>
  <div>
    <Alerta
      v-on:fecharModal="fecharModal"
      :exibir="modal.exibir"
      :titulo="modal.titulo"
      :mensagem="modal.mensagem"
      v-on:exclusao="excluir()"
    />

    <Loader :loader-primario="loader" />

    <RedesSociais></RedesSociais>

    <ModalBanner
      :modalAlterarBanner="modalAlterarBanner"
      :imagem="imagemModal"
      :pagina="pagina"
      :comLink="false"
      @atualizaImagem="atualizaImagem"
      v-on:fecha-modal="modalAlterarBanner = false"
      :customMethod="getImagem"
      @mensagem="mensagem"
      @loader="isLoading"
    />

    <v-container class="banner">
      <v-btn
        v-if="admin"
        @click="abrirModalAlterarBanner()"
        class="banner-btn botao-edicao-adm"
      >
        <v-icon>fa fa-pencil</v-icon>
      </v-btn>
      <v-img
        :src="
          'data:' +
          imagem.banner.tipoArquivo +
          ';base64,' +
          imagem.banner.arquivo
        "
        class="banner"
      ></v-img>
    </v-container>

    <v-container>
      <div class="text-center">
        <!-- Utlizar <span> dentro do <h1> para criar um texto amarelo misturado com o azul padrão -->
        <h1 class="titulo">Conquiste Novos Desafios</h1>
        <p>
          Inscreva-se agora em uma nova jornada e seja protagonista da sua
          carreira!
        </p>
      </div>
      <div class="todas_vagas">
        <v-row class="ordem-responsivo">
          <v-col cols="12" md="7" class="mt-10">
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="vagasPesquisa"
                  outlined
                  dense
                  @input="buscarVagas()"
                  hide-details="auto"
                  class="input-field"
                >
                  <template slot="label">
                    <span class="input-field-text">Pesquisar</span>
                  </template>
                  <template slot="append">
                    <v-icon class="input-field-text">fas fa-search</v-icon>
                  </template>
                </v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-select
                  v-model="vagasTipo"
                  :items="vagasTipoItems"
                  item-text="name"
                  item-value="value"
                  outlined
                  dense
                  hide-details="auto"
                  @input="buscarVagas()"
                  class="input-field"
                >
                  <template slot="label">
                    <span class="input-field-text">Tipo de Vaga</span>
                  </template>
                  <template slot="append">
                    <v-icon class="input-field-text"
                      >fas fa-chevron-down</v-icon
                    >
                  </template>
                </v-select>
              </v-col>
              <v-col cols="12" md="6">
                <v-select
                  v-model="vagasLocalizacao"
                  :items="vagasLocalizacaoItems"
                  item-text="des_dominio"
                  item-value="nom_dominio"
                  outlined
                  dense
                  hide-details="auto"
                  class="input-field"
                  @input="buscarVagas()"
                >
                  <template slot="label">
                    <span class="input-field-text">Localização</span>
                  </template>
                  <template slot="append">
                    <v-icon class="input-field-text"
                      >fas fa-chevron-down</v-icon
                    >
                  </template>
                </v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                v-for="vaga in vagas"
                :key="vaga.cod_solicitacao"
                cols="12"
                md="6"
                lg="4"
                class="d-flex justify-center"
              >
                <CardVagas
                  :titulo="vaga.nom_funcao_sugerida"
                  :tipo="
                    vaga.cod_tipo_solicitacao == 304 ||
                    vaga.cod_tipo_solicitacao == 303 // verifica se a vaga é mista ou externa e se for classifica como mista
                      ? 'Vaga Mista'
                      : 'Vaga Interna'
                  "
                  :local="vaga.nom_cidade"
                  :data="vaga.dta_aprovacao"
                  :id="vaga.cod_solicitacao"
                  :isInterna="vaga.cod_tipo_solicitacao === 302"
                >
                </CardVagas>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" md="5">
            <h1 class="titulo indico">Programa <span>#EuIndico</span></h1>
            <iframe
              title="Programa #EuIndico"
              frameborder="0"
              width="1200"
              height="675"
              class="indico"
              src="https://view.genial.ly/636d12a72db3f20012161d81"
              type="text/html"
              allowscriptaccess="always"
              allowfullscreen="true"
              scrolling="yes"
              allownetworking="all"
            ></iframe>
            <div class="recrutamento-interno">
              <h3>Recrutamento interno</h3>
              <p>
                Como parte do processo de recrutamento e seleção existem dois
                tipos de vagas:<br /><br />

                Interno — quando a busca se restringe aos profissionais do
                #TimeHepta <br />
                misto — quando o processo abrange candidatos internos e externos
                <br /><br />
                Ficou interessado em uma vaga e quer participar do processo
                seletivo? Dê uma olhadinha nas regras abaixo antes de se
                candidatar: Possuir o perfil exigido para a vaga e atender todos
                os pré-requisitos (experiência, conhecimentos técnicos,
                certificações, etc).<br /><br />
                Não ter sido reprovado em processo seletivo anterior no prazo de
                6 meses para vaga igual a que pretende se candidatar.
              </p>
              <br />
              <h3>Regras</h3>
              <p>
                Conversar sobre a candidatura com o seu gestor imediato, que
                precisa estar ciente da sua participação no processo seletivo.
                <br /><br />
                Todos os colaboradores que se candidatarem aos processos
                seletivos receberão uma resposta da área de Recrutamento e
                Seleção sobre avaliação do currículo e os que atenderem ao
                perfil da vaga serão chamados para a próxima etapa do processo.
                <br /><br />
                Para entender melhor as diretrizes da Hepta sobre este tema,
                acesse a Política de Recrutamento e Seleção, disponível no F7.

                <br /><br />
                Caso tenha dúvidas ou precise de outras informações, entre em
                contato com a área de Gestão de Pessoas e Competências (GPC)
                pelo e-mail
                <a
                  style="text-decoration: none; color: #278b97"
                  href="mailto:recrutamento@hepta.com.br"
                  >recrutamento@hepta.com.br</a
                >
              </p>
            </div>
          </v-col>
        </v-row>
      </div>
    </v-container>
  </div>
</template>

<script>
import RedesSociais from "@/components/RedesSociais.vue";
import CardVagas from "@/components/CardVagas.vue";
import api from "@/services/Api.js";
import Loader from "@/components/Loader.vue";
import modalMixin from "../mixins/modalMixin";
import Alerta from "@/components/Alerta.vue";
import ModalBanner from "@/components/Modal/ModalBanner.vue";
import { idsVaga } from "@/utils/ImagemEnums.js";
import Utils from "@/utils/Utils";

export default {
  components: { RedesSociais, CardVagas, Loader, Alerta, ModalBanner },
  mixins: [modalMixin],
  name: "vagas",
  data: () => ({
    vagas: [],
    vagasTipo: [],
    vagasTipoItems: [
      { name: "Todas as vagas", value: "" },
      { name: "Vagas Internas", value: 302 },
      { name: "Vagas Mistas", value: 304 },
    ],
    vagasLocalizacao: [],
    vagasLocalizacaoItems: [],
    vagasPesquisa: "",
    loader: false,
    admin: false,
    pagina: "",
    imagemModal: {},
    imagem: {
      banner: {
        tipoArquivo: "",
        arquivo: "",
      },
    },
    modalAlterarBanner: false,
  }),
  methods: {
    buscarVagas() {
      const vm = this;
      vm.loader = true;
      api
        .get(
          "/recrutamento-selecao/vagascomfiltro/?tipoVaga=" +
            this.vagasTipo +
            "&localizacao=" +
            this.vagasLocalizacao +
            "&nomeVaga=" +
            this.vagasPesquisa,
          {
            headers: { Authorization: localStorage.token },
            withCredentials: true,
          }
        )
        .then((response) => {
          this.vagas = response.data;
          this.vagas.map((v) => {
            var dataAprovacao = new Date(v.dta_aprovacao);
            v.dta_aprovacao = dataAprovacao.toLocaleDateString();
          });
        })
        .catch((e) => {
          this.mensagemErro(e.message);
        })
        .finally(() => {
          vm.loader = false;
        });
    },
    buscarUfVagas() {
      api
        .get("/recrutamento-selecao/uf", {
          headers: { Authorization: localStorage.token },
          withCredentials: true,
        })
        .then((response) => {
          this.vagasLocalizacaoItems.push({
            des_dominio: "Todas as localidades",
            nom_dominio: [],
          });
          response.data.map((r) => {
            this.vagasLocalizacaoItems.push(r);
          });
        })
        .catch((e) => {
          this.mensagemErro(e.message);
        });
    },
    isLoading(e) {
      this.loader = e;
    },
    getImagem(id, pagina) {
      const vm = this;
      vm.loader = true;
      api
        .get("imagem/" + id)
        .then((response) => {
          //nome da variavel é o pagina. ex: vm.banner
          vm.imagem[pagina] = response.data;
          console.log(vm.imagem[pagina]);
        })
        .catch(() => {
          vm.mensagem("Erro!", "Não foi possivel baixar imagem.");
        })
        .finally(() => {
          vm.loader = false;
        });
    },
    atualizaImagem(pagina, imagem, tipoArquivo) {
      const vm = this;
      vm.imagem[pagina].arquivo = imagem;
      vm.imagem[pagina].tipoArquivo = tipoArquivo;
      vm.modalAlterarBanner = false;
    },
    isAdmin() {
      this.admin = Utils.isAdmin();
    },
    abrirModalAlterarBanner() {
      this.modalAlterarBanner = true;
      this.imagemModal = this.imagem.banner;
      this.pagina = "banner";
    },
  },
  mounted() {
    this.buscarVagas();
    this.buscarUfVagas();
    this.isAdmin();

    for (let pagina in idsVaga) {
      //ex: this.getImagem(103, 'banner');
      this.getImagem(idsVaga[pagina], pagina);
    }
  },
};
</script>

<style>
.banner {
  max-width: 100%;
  padding: 0;
}

.banner-btn {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
}

.todas_vagas {
  margin-top: 30px;
}

.titulo {
  margin-bottom: 11px;
}

.titulo span {
  font-size: inherit;
  color: #f8bb00;
}

.titulo {
  font-size: 2rem;
  text-align: center;
}

.estilo {
  border-radius: 10px;
  opacity: 1;
}

.indico {
  max-width: 100%;
}

.recrutamento-interno {
  background-color: #edefee;
  border-radius: 15px;
  padding: 42px;
  margin-top: 20px;
}

.recrutamento-interno h3 {
  color: #278b97;
}

@media screen and (max-width: 960px) {
  .ordem-responsivo {
    flex-direction: column-reverse;
  }
}
</style>
