const sexoEnum = Object.freeze({
  MASCULINO: { id: 2, descricao: "Masculino" },
  FEMININO: { id: 1, descricao: "Feminino" },
  NAO_DEFINIDO: { id: 0, descricao: "Prefiro não dizer" },
});

function montarListaSexo() {
  const vm = this;
  var lista = Object.entries(sexoEnum).map(([key]) => ({
    id: sexoEnum[key].id,
    descricao: sexoEnum[key].descricao,
  }));
  return lista;
}

export { sexoEnum, montarListaSexo };
export default { sexoEnum, montarListaSexo };
