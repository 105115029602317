function formatarDataPadraoAmericano(data) {
  return data.toString().split("/").reverse().join("-");
}

function formatarDataPadraoBrasileiro(data) {
  return data.toString().split("-").reverse().join("/");
}

/**
 * Converte data e hora ex: 2022-11-22T18:04:37.567 em somente data
 */
function formatarDataHoraPadraoBrasileiro(data) {
  var novaData = new Date(data);
  var dataConvertida = novaData.toLocaleDateString("pt-BR", {
    timeZone: "UTC",
  });
  return dataConvertida;
}

/**
 * Cria um Date apartir de uma String no padrão brasileiro dd/MM/yyyy
 *
 * @param data
 * @return Date
 */
function stringBRToDate(data) {
  return new Date(`${formatarDataPadraoAmericano(data)}T00:00`);
}

/**
 *  Verifica se a data esta entre o incio e o fim passados
 *  por parametro, retornando true ou false
 *
 * @param start
 * @param end
 * @return boolean
 */
Date.prototype.between = function (start, end) {
  return this.getTime() >= start.getTime() && this.getTime() <= end.getTime();
};

Date.prototype.addDays = function (h) {
  this.setDate(this.getDate() + h);
  return this;
};

export default {
  formatarDataPadraoAmericano,
  formatarDataPadraoBrasileiro,
  formatarDataHoraPadraoBrasileiro,
  stringBRToDate,
};

export {
  formatarDataPadraoAmericano,
  formatarDataPadraoBrasileiro,
  formatarDataHoraPadraoBrasileiro,
  stringBRToDate,
};
