<template>
  <v-dialog v-model="show" max-width="1200" persistent>
    <alerta v-on:fecharModal="fecharModal" :exibir="modal.exibir" :titulo="modal.titulo" :mensagem="modal.mensagem" />

    <v-card>
      <v-card-title>
        <h3>{{ isRemarcacao ? 'Solicitação de remarcação de férias' : 'Solicitação de férias' }}</h3>
        <v-icon @click="fecharModalSolicitacao()">fa-times</v-icon>
      </v-card-title>

      <v-card-text>
        <v-container class="px-8">
          <v-row class="row-periodo">
            <v-col cols="12" md="auto">
              <h3>
                Período disponível para férias: entre
                <v-chip>{{ periodoInfo.inicioPeriodoConcessivo }}</v-chip>
                e
                <v-chip>{{ periodoInfo.fimPeriodoConcessivo }}</v-chip>
              </h3>
            </v-col>
            <v-col cols="12" md="auto">
              <h3>
                Dias de férias disponíveis
                <v-chip>{{ periodoInfo.qtDias }} dias</v-chip>
              </h3>
            </v-col>
            <v-col cols="12">
              <v-divider class="mb-4" />
            </v-col>
          </v-row>

          <v-form ref="form" class="form">
            <!-- Remarcação -->
            <v-row v-if="isRemarcacao">
              <v-col cols="12">
                <h4>Justifique o motivo da sua solicitação de remarcação</h4>
              </v-col>
              <v-col cols="12">
                <v-textarea
                  label="Justificativa de remarcação"
                  v-model="justificativaRemarcacao"
                  :rules="[v => (!!v && v.length >= 15) || 'Informe uma justificativa válida']"
                  rows="2"
                  outlined
                  dense
                  hide-details
                ></v-textarea>
              </v-col>
            </v-row>

            <!-- Datepicker Começo Férias -->
            <v-row>
              <v-col cols="12">
                <h4>Escolha o dia em que você deseja começar suas férias</h4>
              </v-col>
              <v-col cols="12" md="6">
                <date-picker
                  ref="datePicker"
                  label="Data de Início"
                  v-model="form.dataInicio"
                  :format="d => new Date(`${d}T00:00`).toLocaleDateString('pt-BR', { dateStyle: 'full' })"
                  :min="dataMinimaCalendario"
                  :max="dataMaximaCalendario"
                  :allowed-dates="dataJaUtilizada"
                  :events="isFimDeSemana"
                  :validacao="true"
                />
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  label="Data Fim"
                  v-model="dataFinalFormatada"
                  disabled
                  outlined
                  dense
                  readonly
                  append-icon="fa-calendar"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row v-if="dataEspecial.show">
              <v-col cols="12">
                <v-alert type="warning" class="text-start" dense>
                  {{
                    dataEspecial.isFeriado
                      ? `Data selecionada no feriado (${dataEspecial.nomeFeriado}) ou em até 2 dias antes é necessário
                  informar uma justificativa`
                      : 'Data selecionada no final de semana ou até 2 dias antes é necessário informar uma justificativa'
                  }}
                </v-alert>
                <v-textarea
                  label="Justificativa"
                  v-model="form.justificativa"
                  :rules="[v => (!!v && v.length >= 15) || 'Informe uma justificativa válida']"
                  rows="2"
                  outlined
                  dense
                ></v-textarea>
              </v-col>
            </v-row>

            <!-- Qtd. Dias Férias -->
            <v-row>
              <v-col cols="12">
                <h4>Escolha quantos dias deseja tirar férias</h4>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  :disabled="!form.dataInicio"
                  v-model="quantidadeDiasSolicitados"
                  :rules="rulesQuantidadeSolicitada"
                  label="Coloque quantos dias deseja tirar de férias"
                  type="number"
                  min="0"
                  outlined
                  dense
                  hide-details
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <h4 class="texto-amarelo">
                  Dias de férias restantes
                  <v-chip class="ml-2">{{ quantidadeDiasRestantes }} dias</v-chip>
                </h4>
              </v-col>
              <v-col cols="12">
                <v-divider />
              </v-col>
            </v-row>

            <!-- Vender Férias / Adiantar 13º -->
            <v-row class="row-vender-adiantar">
              <v-col cols="12" md="6">
                <h4>Vender férias</h4>
                <p v-if="periodoInfo.abonoJaSolicitado">Você já solicitou abono para o período atual.</p>
                <p v-else>
                  Lembrando que é possível abonar 10 dias de suas férias anuais. Os dias abonados serão pagos como dias
                  de trabalho. Para abonar as férias é necessário ter um período de 14 dias de gozo e outro de pelo
                  menos de 5.
                </p>
              </v-col>
              <v-col cols="12" md="6" class="d-flex flex-column h-100">
                <h4>Adiantar o 13º</h4>
                <p v-if="periodoInfo.decimoTerceiroJaSolicitado">
                  Você já solicitou o adiantamento para o período atual.
                </p>
                <p v-else-if="isDezembroSelecionado">
                  Solicitações de adiantamento não podem ser realizadas para o mês de dezembro.
                </p>
                <p v-else>É possível solicitar o adiantamento do 13º</p>
              </v-col>

              <v-col cols="12" md="6">
                <v-select
                  label="Deseja vender as férias?"
                  v-model="form.abono"
                  :items="opcoes"
                  :rules="[v => v != null || periodoInfo.abonoJaSolicitado || 'Informe se deseja vender as férias']"
                  :disabled="periodoInfo.abonoJaSolicitado"
                  dense
                  outlined
                  @change="$refs.form.validate()"
                  hide-details
                ></v-select>
              </v-col>
              <v-col cols="12" md="6">
                <v-select
                  label="Adiantamento 13º"
                  v-model="form.adiantamentoDecimoTerceiro"
                  :items="opcoes"
                  :disabled="periodoInfo.decimoTerceiroJaSolicitado || isDezembroSelecionado"
                  :rules="[
                    v =>
                      v != null ||
                      periodoInfo.decimoTerceiroJaSolicitado ||
                      isDezembroSelecionado ||
                      'Informe se deseja o adiantamento do 13°',
                  ]"
                  dense
                  outlined
                  hide-details
                  class="align-end"
                ></v-select>
              </v-col>

              <v-col cols="12" md="6">
                <Aviso
                  v-if="periodoInfo.abonoJaSolicitado == false && (form.abono || form.abono == false)"
                  message="Para se requisitar o abono é preciso ter ao menos 10 dias restantes após a seleção do inicio e fim."
                  bg_hex="#DDD"
                  class="h-100"
                />
                <Aviso
                  v-else-if="periodoInfo.abonoJaSolicitado == true"
                  message="Você já solicitou abono para o
                  período atual."
                  bg_hex="#DDD"
                  class="h-100"
                />
              </v-col>
              <v-col cols="12" md="6">
                <Aviso
                  v-if="form.adiantamentoDecimoTerceiro"
                  :message="
                    `O recibo do adiantamento do 13º salário é disponibilizado no Nossa Hepta quando 
                    disponibilizado o recibo mensal. O mesmo é feito separado do recibo de férias.\n` +
                    `O pagamento é realizado no mesmo dia do pagamento das férias e é metade do salário contratual.`
                  "
                  bg_hex="#DDD"
                  class="h-100"
                />
              </v-col>

              <v-col cols="12">
                <v-divider class="mt-2" />
              </v-col>
            </v-row>
          </v-form>
        </v-container>

        <v-card-actions>
          <v-container class="py-0">
            <v-row class="justify-center">
              <v-col cols="12" md="3">
                <v-btn @click="fecharModalSolicitacao()" class="botao-cancelar w-100">Cancelar</v-btn>
              </v-col>
              <v-col cols="12" md="3">
                <v-btn
                  @click="solicitarFerias()"
                  :class="qtdValida ? 'botao-principal w-100' : 'botao-excluir w-100'"
                  :readonly="!qtdValida"
                  :loading="loadingButton"
                >
                  Solicitar Férias
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card-actions>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import Vue from 'vue';
import api from '@/services/Api';
import DatePicker from '@/components/DatePicker.vue';
import DateUtils, { formatarDataPadraoBrasileiro, stringBRToDate } from '@/utils/DateUtils';
import modalMixin from '@/mixins/modalMixin';
import alerta from '@/components/Alerta.vue';
import Aviso from '@/components/Aviso.vue';

Vue.use(DateUtils);

export default {
  props: {
    value: Boolean,
    isRemarcacao: {
      type: Boolean,
      default: false,
    },
    periodoInfo: {
      type: Object,
      default: () => {},
    },
    solicitacoes: {
      type: Array,
      default: () => [],
    },
    feriasRemarcacao: {
      type: Object,
      default: () => {},
    },
  },
  components: { DatePicker, alerta, Aviso },
  mixins: [modalMixin],
  data() {
    return {
      paths: {
        feriados: 'meusdados/ferias/calendario/feriado',
        solicitacao: 'meusdados/ferias/solicitar',
        remarcacao: 'meusdados/ferias/solicitar/remarcacao',
      },
      form: {
        abono: null,
        adiantamentoDecimoTerceiro: null,
        codPeriodoAquisitivo: null,
        codPessoa: null,
        dataFim: null,
        dataInicio: null,
        justificado: true,
        justificativa: null,
      },
      justificativaRemarcacao: null,
      quantidadeDiasSolicitados: null,
      dataFinalFormatada: null,
      loadingButton: false,
      opcoes: [
        { text: 'Sim', value: true },
        { text: 'Não', value: false },
      ],
      dataEspecial: {
        show: false,
        isFeriado: false,
        nomeFeriado: '',
      },
      qtdValida: false,
    };
  },
  watch: {
    // define o valor de dataFim e dataFinalFormatada
    qtdSolicitadaEDataInicio(values) {
      this.form.dataFim = null;
      this.dataFinalFormatada = null;
      let qtdDias = values[0];

      if (qtdDias && qtdDias >= 5 && qtdDias <= this.periodoInfo.qtDias && this.form.dataInicio) {
        let dtFim = new Date(`${this.form.dataInicio}T00:00`);
        dtFim.setDate(dtFim.getDate() + Number(qtdDias) - 1);
        this.form.dataFim = dtFim.toLocaleDateString();
        this.dataFinalFormatada = dtFim.toLocaleDateString('pt-BR', {
          dateStyle: 'full',
        });
      }
    },
    async 'form.dataInicio'(value) {
      if (!value) return;

      if (this.isFimDeSemana(value)) {
        this.dataEspecial.show = true;
        this.dataEspecial.isFeriado = false;
        return;
      }

      let inicio = value;
      let fim = new Date(`${value}T00:00`).addDays(2).toISOString().split('T')[0];

      let feriados = await this.buscarFeriadosDoPeriodo(inicio, fim);

      if (feriados.length != 0) {
        this.dataEspecial.show = true;
        this.dataEspecial.isFeriado = true;
        this.dataEspecial.nomeFeriado = feriados[0].nomeFeriado;
      } else {
        this.dataEspecial = this.$options.data().dataEspecial;
      }
    },
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
    quantidadeDiasRestantes() {
      let diasRestantes = this.quantidadeDiasSolicitados
        ? this.periodoInfo.qtDias - this.quantidadeDiasSolicitados
        : this.periodoInfo.qtDias;

      if (this.form.abono) diasRestantes -= 10;

      return diasRestantes;
    },
    dataMinimaCalendario() {
      if (!this.periodoInfo.inicioPeriodoConcessivo) return null;

      const dataInicioPeriodoConcessivo = new Date(
        this.periodoInfo.inicioPeriodoConcessivo.split('/').reverse().join('-')
      );
      const dataMinima45Dias = new Date();
      dataMinima45Dias.setDate(dataMinima45Dias.getDate() + 45);

      const dataMinimaFinal = new Date(Math.max(dataInicioPeriodoConcessivo, dataMinima45Dias));

      return dataMinimaFinal.toISOString().split('T')[0];
    },
    dataMaximaCalendario() {
      if (!this.periodoInfo.fimPeriodoConcessivo || !this.periodoInfo.qtDias) return null;

      const [dia, mes, ano] = this.periodoInfo.fimPeriodoConcessivo.split('/');
      const dataFimPeriodoConcessivo = `${ano}-${mes}-${dia}`;
      const data = new Date(dataFimPeriodoConcessivo);
      data.setDate(data.getDate() - (this.periodoInfo.qtDias - 2));

      return data.toISOString().split('T')[0];
    },
    qtdSolicitadaEDataInicio() {
      return [this.quantidadeDiasSolicitados, this.form.dataInicio];
    },
    mensagemDecimoTerceiro() {
      return this.periodoInfo.decimoTerceiroJaSolicitado
        ? 'Você já solicitou o adiantamento para o período atual.'
        : '';
    },
    isDezembroSelecionado() {
      if (this.form.dataInicio) return new Date(`${this.form.dataInicio}T00:00`).getMonth() == 11;
      else return false;
    },
    rulesQuantidadeSolicitada() {
      return [
        v => !!v || 'Informe a quantidade de dias de férias',
        v => this.isQuantidadeValida(v) || 'Quantidade inválida, verifique as regras de divisão de férias',
      ];
    },
  },
  methods: {
    solicitarFerias() {
      if (!this.$refs.form.validate()) return;

      this.loadingButton = true;

      let solicitacao = {
        ...this.form,
        codPeriodoAquisitivo: this.periodoInfo.idPeriodoAquisitivo,
        codPessoa: this.periodoInfo.idPessoa,
        dataInicio: formatarDataPadraoBrasileiro(this.form.dataInicio),
      };

      if (this.isRemarcacao) {
        solicitacao = {
          ...solicitacao,
          codFerias: this.feriasRemarcacao.codFerias,
          justificativaRemarcacao: this.justificativaRemarcacao,
          justificativaData: solicitacao.justificativa,
        };
        Vue.delete(solicitacao, 'justificativa');
      }

      let url = this.isRemarcacao ? this.paths.remarcacao : this.paths.solicitacao;

      api
        .post(url, solicitacao)
        .then(res => {
          let retorno = res.data;
          if (retorno && retorno.valido) {
            this.$emit('atualizarInformacoes');
            this.mensagemSucesso('Solicitação criada com sucesso');
            this.fecharModalSolicitacao();
          } else {
            this.mensagem('Erro!', retorno.erros.join(' - '));
          }
        })
        .catch(error => this.mensagemErro(error))
        .finally(() => (this.loadingButton = false));
    },
    async buscarFeriadosDoPeriodo(inicio, fim) {
      let res = await api.get(this.paths.feriados, {
        params: {
          inicio,
          fim,
        },
      });

      return res.data;
    },
    dataJaUtilizada(value) {
      let jaUtilizado;

      let date = new Date(`${value}T00:00`);

      if (this.isRemarcacao) {
        jaUtilizado =
          this.solicitacoes
            .filter(
              s => (s.status == 'APROVADA' || s.status == 'PENDENTE') && s.id != this.feriasRemarcacao.codSolicitacao
            )
            .map(s => {
              return {
                inicio: stringBRToDate(s.dataInicio),
                fim: stringBRToDate(s.dataFim),
              };
            })
            .filter(f => date.between(f.inicio, f.fim)).length > 0;
      } else {
        jaUtilizado =
          this.solicitacoes
            .filter(s => s.status == 'APROVADA' || s.status == 'PENDENTE')
            .map(s => {
              return {
                inicio: stringBRToDate(s.dataInicio),
                fim: stringBRToDate(s.dataFim),
              };
            })
            .filter(f => date.between(f.inicio, f.fim)).length > 0;
      }

      return !jaUtilizado;
    },
    isFimDeSemana(value) {
      let diaDaSemana = new Date(`${value}T00:00`).getDay();

      return diaDaSemana == 0 || diaDaSemana >= 4;
    },
    fecharModalSolicitacao() {
      this.$refs.form.resetValidation();
      this.$refs.datePicker.resetTableDate();
      this.form = this.$options.data().form;
      this.dataEspecial = this.$options.data().dataEspecial;
      this.quantidadeDiasSolicitados = null;
      this.show = false;
    },
    isQuantidadeValida(qtd) {
      const isValid =
        !!qtd && qtd >= 5 && this.quantidadeDiasRestantes >= 0 && !(qtd === 10 && this.quantidadeDiasRestantes === 10);

      this.qtdValida = isValid;
      return isValid;
    },
  },
};
</script>

<style scoped>
.row-periodo {
  justify-content: space-between;
}

.row-periodo h3 {
  color: #278b97;
}

.row-periodo .v-chip {
  border-radius: 8px;
  background: #278b97;
  color: #fff;
}

.form h4 {
  display: flex;
  align-items: center;
  border-left: 0.325rem solid #278b97;
  border-radius: 0.325rem;
  padding-left: 0.5rem;
  color: #278b97;
}

.texto-amarelo {
  color: #e7b814 !important;
}

.texto-amarelo .v-chip {
  border-radius: 8px;
  background: #e7b814;
  color: #fff;
}

.row-vender-adiantar p {
  text-align: left !important;
  margin: 0.75rem 0 !important;
}
</style>
