<template>
  <div>
    <v-container fluid class="pa-0">
      <v-btn
        v-if="admin"
        @click="
          modalAlterarBanner = true;
          imagemModal = imagem.banner;
          pagina = 'banner';
        "
        class="banner-btn botao-edicao-adm"
      >
        <v-icon>fa fa-pencil</v-icon>
      </v-btn>
      <v-img
        :src="
          'data:' +
          imagem.banner.tipoArquivo +
          ';base64,' +
          imagem.banner.arquivo
        "
        class="banner"
      ></v-img>
      <ModalBanner
        :modalAlterarBanner="modalAlterarBanner"
        :imagem="imagemModal"
        :pagina="pagina"
        :comLink="false"
        @atualizaImagem="atualizaImagem"
        v-on:fecha-modal="modalAlterarBanner = false"
        :customMethod="getImagem"
        @mensagem="mensagem"
        @loader="isLoading"
      ></ModalBanner>
    </v-container>
    <Loader :loader-primario="loader"></Loader>
    <RedesSociais></RedesSociais>
    <Alerta
      v-on:fecharModal="fecharModal"
      :exibir="modal.exibir"
      :titulo="modal.titulo"
      :mensagem="modal.mensagem"
      v-on:exclusao="apagarItemFaq()"
    >
    </Alerta>
    <ModalFaq
      v-if="modalFaq"
      :itemFaq="duvida"
      v-on:fecha-modal="fecharModalFaq()"
      @erro="mensagemErro"
      @sucesso="mensagemSucesso"
    ></ModalFaq>

    <v-container>
      <router-link to="/perguntas-frequentes">
        <v-btn class="botao-outlined-azul mb-7" color="#278b97"
          ><v-icon small color="#278b97" class="mr-3"
            >fas fa-solid fa-arrow-left </v-icon
          >Voltar
        </v-btn>
      </router-link>
      <div class="card">
        <div class="espacamento">
          <h1 class="titulo">{{ duvida.pergunta }}</h1>
          <v-row class="justify-end" no-gutters v-if="admin">
            <v-icon small color="#14AB51" class="mr-4" @click="abrirModal()"
              >fas fa-pen</v-icon
            >
            <v-icon small color="#D91727" @click="apagarItemFaq(duvida.id)"
              >fas fa-trash</v-icon
            >
          </v-row>
          <v-chip
            small
            color="#278B97"
            text-color="white"
            class="mr-2 mt-2 mb-2"
            v-for="(categoria, index) in categorias"
            :key="index"
          >
            #{{ categoria }}
          </v-chip>
          <div class="mt-7" v-html="duvida.resposta"></div>
        </div>
        <v-row class="justify-end" no-gutters>
          <p v-if="duvida.visualizacao > 1">
            {{ duvida.visualizacao }} visualizações
          </p>
          <p v-else>{{ duvida.visualizacao }} visualização</p>
        </v-row>
      </div>
    </v-container>
  </div>
</template>
<script>
import RedesSociais from "@/components/RedesSociais.vue";
import Loader from "@/components/Loader.vue";
import Alerta from "@/components/Alerta.vue";
import modalMixin from "@/mixins/modalMixin";
import PerguntasFrequentes from "./PerguntasFrequentes.vue";
import ModalFaq from "../components/Modal/ModalFAQ.vue";
import api from "@/services/Api";
import ModalBanner from "@/components/Modal/ModalBanner.vue";
import { idsImagemFaq } from "@/utils/ImagemEnums.js";
import Utils from "@/utils/Utils";
export default {
  components: { RedesSociais, ModalFaq, Alerta, Loader, ModalBanner },
  name: "Pergunta",
  mixins: [modalMixin],
  data: () => ({
    duvida: {},
    categorias: [],
    modalFaq: false,
    loader: false,
    pagina: "",
    imagemModal: {},
    imagem: {
      banner: {
        tipoArquivo: "",
        arquivo: "",
      },
    },
    admin: false,
    modalAlterarBanner: false,
    admin: false,
    loader: false,
  }),
  mounted() {
    this.buscarPerguntas();
    this.isAdmin();

    for (let pagina in idsImagemFaq) {
      //ex: this.getImagem(103, 'banner');
      this.getImagem(idsImagemFaq[pagina], pagina);
    }
  },
  methods: {
    getImagem(id, pagina) {
      const vm = this;
      vm.loader = true;
      api
        .get("imagem/" + id)
        .then((response) => {
          //nome da variavel é o pagina. ex: vm.banner
          vm.imagem[pagina] = response.data;
        })
        .catch((e) => {
          vm.mensagem("Erro!", "Não foi possivel baixar imagem.");
        })
        .finally(() => {
          vm.loader = false;
        });
    },
    atualizaImagem(pagina, imagem, tipoArquivo) {
      const vm = this;
      vm.imagem[pagina].arquivo = imagem;
      vm.imagem[pagina].tipoArquivo = tipoArquivo;
      vm.modalAlterarBanner = false;
    },
    isAdmin() {
      this.admin = Utils.isAdmin();
    },
    isLoading(e) {
      this.loader = e;
    },
    async buscarPerguntas() {
      const vm = this;
      this.loader = true;
      await api
        .get("../rs/itemfaq/getid/?id=" + this.$route.params.id, {
          headers: { Authorization: localStorage.token },
          withCredentials: true,
        })
        .then((response) => {
          if (response.status == 200) {
            this.duvida = response.data;
            this.buscarCategoria();
          }
        })
        .catch((e) => {
          if (e.response.status == 403 || e.response.status == 401) {
            localStorage.removeItem("token");
            location.href = "/IntranetHepta/";
          } else if (e.response.status === 404 || e.response.status === 405) {
            this.msg.push("Não foi possivel realizar a busca.");
          } else {
            this.msg.push(e.response.data);
          }
        })
        .finally(() => {
          this.loader = false;
        });
    },
    isLoading(e) {
      this.loader = e;
    },
    fecharModalFaq() {
      this.modalFaq = false;
      this.categorias = [];
      this.buscarPerguntas();
    },
    abrirModal() {
      this.categorias = [];
      this.modalFaq = true;
    },
    async apagarItemFaq(idExclusao) {
      const vm = this;
      //solicitou exclusao mas ainda não houve a confirmação
      if (!this.exclusaoConfirmada) {
        this.confirmarExclusao("Deseja confirmar a exclusão?", idExclusao);
      }
      //Alerta confirmou exclusão, pegar id de exclusao salvo no mixin
      //exclusaoConfirmada é o id de exclusao salvo no mixin
      else {
        idExclusao = this.exclusaoConfirmada;
        this.fecharModal();
        this.loader = true;
        api
          .put("/itemfaq/deletarItem?id=" + idExclusao, {
            headers: { Authorization: localStorage.token },
            withCredentials: true,
          })
          .then((response) => {
            this.loader = false;
            this.mensagemSucesso("Pergunta excluida com sucesso!");
            setTimeout(() => {
              this.$router.go(-1);
            }, 2500);
          })
          .catch((e) => {
            this.mensagemErro(e.message);
          })
          .finally(() => {
            this.loader = false;
          });
      }
    },
    buscarCategoria() {
      const vm = this;
      this.categorias = [];
      this.duvida.categoria.forEach((c) => {
        api
          .get("../rs/categoria-faq/listar/?id=" + c, {
            headers: { Authorization: localStorage.token },
            withCredentials: true,
          })
          .then((response) => {
            if (response.status == 200) {
              let cat = response.data;
              this.categorias.push(cat.nome);
            }
          })
          .catch((e) => {
            if (e.response.status == 403 || e.response.status == 401) {
              localStorage.removeItem("token");
              location.href = "/IntranetHepta/";
            } else if (e.response.status === 404 || e.response.status === 405) {
              this.msg.push("Não foi possivel realizar a busca.");
            } else {
              this.msg.push(e.response.data);
            }
          });
      });
    },
  },
};
</script>

<style scoped>
.banner {
  position: relative;
}

.banner-btn {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
}

.espacamento {
  padding: 20px 40px;
}

.titulo {
  font: normal normal bold 18px/22px Montserrat;
  letter-spacing: 0px;
  color: #278b97;
  text-transform: uppercase;
  opacity: 1;
}

.destaque {
  background-color: #f5f5f5;
  height: 250px;
  text-align: center;
  display: flex;
  align-items: center;
  position: relative;
}

.destaque:after {
  content: "";
  position: absolute;
  right: calc(50% - 50px);
  top: 100%;
  border-left: 50px solid transparent;
  border-right: 50px solid transparent;
  border-top: 50px solid #f5f5f5;
}

.destaque h1 {
  color: rgba(39, 139, 151, 1);
}

.destaque h1 span {
  color: #ff0c23;
  font-size: 1.25rem;
}

.resposta {
  margin-top: 10vh;
}

.categorias {
  display: flex;
}
</style>
