<template>
  <v-container>
    <v-dialog persistent v-model="show" min-width="500" v-if="pedido">
      <alerta
        v-on:fecharModal="fecharModal"
        :titulo="modal.titulo"
        :exibir="modal.exibir"
        :mensagem="modal.mensagem"
      >
      </alerta>
      <template class="main">
        <v-card>
          <v-card-title>
            <h5>Anexo de documentos do Pedido Nº {{ pedido.id }}</h5>
            <v-icon small @click="fecharModal()"> fa-times </v-icon>
          </v-card-title>
          <v-card-text>
            <v-row class="justify-center mx-5">
              <v-col cols="12">
                <div class="alerta">
                  <v-img
                    class="icone-alerta"
                    src="@/assets/images/icone/espaco-colaborador/icone_sinal_de_aviso.svg"
                  ></v-img>
                  <v-divider
                    inset
                    vertical
                    color="#278b97"
                    justify="center"
                    class="mb-2"
                    :class="$vuetify.breakpoint.xs ? 'd-none' : ''"
                  ></v-divider>
                  <p class="text-alerta">
                    Baixe o formulário de
                    {{ !alteracao ? "adesão" : "alteração" }} e depois anexe-o
                    no campo abaixo com as informações preenchidas.
                  </p>
                  <v-btn
                    class="botao-cliqueaqui cor-destaque mr-2 largura-btn-cliqueaqui"
                    @click="gerarAdesao()"
                    >Baixar formulário</v-btn
                  >
                </div>
              </v-col>
            </v-row>
            <v-row class="mx-5">
              <v-col cols="12">
                <p class="text-left my-4">Declaração do Plano *</p>
                <v-file-input
                  v-model="adesao.model"
                  outlined
                  dense
                  @input="anexoAdesao()"
                  :label="
                    !alteracao
                      ? 'Anexar formulário de adesão ao plano'
                      : 'Anexar formulário de alteração do plano'
                  "
                  placeholder=" Escolha o anexo..."
                  accept="application/pdf"
                  hide-details="auto"
                  prepend-icon
                  prepend-inner-icon="fas fa-paperclip"
                  class="estilo"
                  >Anexar</v-file-input
                >
              </v-col>
              <v-col cols="12">
                <div
                  class="mt-2 mb-2"
                  v-for="dependente in pedido.dependentes"
                  :key="dependente.id"
                >
                  <p class="text-left my-4">
                    Dados do dependente: {{ dependente.nome }}
                  </p>
                  <v-row>
                    <v-col cols="12" md="6">
                      <v-file-input
                        outlined
                        dense
                        v-model="documento.model.id[dependente.id]"
                        label="Anexe um documento de identificação*"
                        placeholder=" Escolha o anexo..."
                        accept="application/pdf"
                        hide-details="auto"
                        prepend-icon
                        prepend-inner-icon="fas fa-paperclip"
                        class="estilo"
                        >Anexar</v-file-input
                      >
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-file-input
                        outlined
                        dense
                        v-model="documento.model.outroDoc[dependente.id]"
                        label="Anexe outro documento "
                        placeholder=" Escolha o anexo..."
                        accept="application/pdf"
                        hide-details="auto"
                        prepend-icon
                        prepend-inner-icon="fas fa-paperclip"
                        class="estilo"
                        >Anexar</v-file-input
                      >
                    </v-col>
                  </v-row>
                </div>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-row justify="center" class="my-5">
              <v-btn
                class="botao-sair mr-2"
                variant="info"
                @click="show = false"
                >Fechar
              </v-btn>
              <v-btn
                class="botao-principal mr-2 largura-btn"
                variant="info"
                @click="finalizarAnexo()"
                >Finalizar
              </v-btn>
            </v-row>
          </v-card-actions>
        </v-card>
      </template>
    </v-dialog>
  </v-container>
</template>

<script>
import api from "@/services/Api.js";
import alerta from "@/components/Alerta.vue";
import modalMixin from "@/mixins/modalMixin";
export default {
  components: { alerta },
  mixins: [modalMixin],
  name: "modalAnexarDocumentos",
  props: {
    value: Boolean,
    pedido: {
      type: Object,
    },
    alteracao: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loader: false,
      idDependente: null,
      anexos: false,
      documento: {
        anexado: {
          id: {},
          outroDoc: {},
        },
        model: {
          id: [],
          outroDoc: [],
        },
      },
      adesao: {
        anexado: null,
        model: null,
      },
      formAdesao: {},
      dominio: {
        declaracaoPlanoSaude: 903 /** Declaração do plano de saúde **/,
        declaracaoPlanoDental: 904 /** Declaração do plano odontológico **/,
        documentoIdentificacao: 919 /** Documento de identificação (Certidão de nascimento ou RG)**/,
        outroDocumento: 896 /** Outro tipo de documento **/,
      },
    };
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
    urlDocumento() {
      const vm = this;
      let url = `beneficios/documento-pedido/` + vm.pedido.id;
      return vm.idDependente == null
        ? url
        : (url += "?idDependente=" + vm.idDependente);
    },
  },
  methods: {
    montarFormData(vModel, codDominio) {
      let anexoFormData = new FormData();
      anexoFormData.append("arquivo", vModel);
      anexoFormData.append("nomeArquivo", vModel.name);
      anexoFormData.append("formatoArquivo", vModel.type);
      anexoFormData.append("codDominio", codDominio);
      return anexoFormData;
    },

    salvarDocumento(documento, idDependente) {
      const vm = this;
      let url = `beneficios/documento-pedido/` + vm.pedido.id;
      if (idDependente != null) {
        url += "?idDependente=" + idDependente;
      }
      return api.post(url, documento).catch((e) => {
        vm.isLoading = false;
        vm.mensagemErro(e.message);
      });
    },
    resetarAnexos() {
      const vm = this;
      vm.adesao.model = null;
      vm.documento.model.id = [];
      vm.documento.model.outroDoc = [];
    },
    fecharModal() {
      const vm = this;
      vm.resetarAnexos();
      vm.show = false;
      vm.$emit("fechar");
    },
    anexoAdesao() {
      const vm = this;
      var codDominio;
      vm.idDependente = null;
      if (vm.adesao.model == null) return;
      vm.pedido.beneficio.tipo.id == 1
        ? (codDominio = vm.dominio.declaracaoPlanoSaude)
        : (codDominio = vm.dominio.declaracaoPlanoDental);
      var documento = vm.montarFormData(vm.adesao.model, codDominio);
      return vm.salvarDocumento(documento);
    },
    async anexoOutroDoc() {
      const vm = this;
      await Promise.all(
        vm.documento.model.outroDoc.map((file, index) => {
          var documento = vm.montarFormData(file, vm.dominio.outroDocumento);
          return vm.salvarDocumento(documento, index);
        })
      );
    },
    async anexoId() {
      const vm = this;
      await Promise.all(
        vm.documento.model.id.map((file, id) => {
          var documento = vm.montarFormData(
            file,
            vm.dominio.documentoIdentificacao
          );
          return vm.salvarDocumento(documento, id);
        })
      );
    },
    validarCampos() {
      const vm = this;
      if (!vm.adesao.model) {
        return false;
      }
      let count = 0;
      vm.documento.model.id.forEach(() => count++);
      if (count < vm.pedido.dependentes.length) {
        return false;
      }
      console.log(count);
      console.log(vm.pedido.dependentes.length);
      return true;
    },
    montarPedido() {
      const vm = this;
      const dataAgora = new Date(Date.now());
      const dataFormatada = dataAgora.toISOString();
      const pedido = {
        beneficio: vm.pedido.beneficio,
        beneficioAnterior: vm.pedido.beneficioAnterior,
        pedidoValeTransporte: vm.pedido.pedidoValeTransporte,
        dataRegistro: dataFormatada,
        dependentes: vm.pedido.dependentes,
        id: vm.pedido.id,
        tipoMudanca: vm.pedido.tipoMudanca,
        pessoa: vm.pedido.pessoa,
      };
      return pedido;
    },
    async finalizarAnexo() {
      const vm = this;
      if (vm.validarCampos()) {
        await Promise.all([vm.anexoAdesao(), vm.anexoOutroDoc(), vm.anexoId()]);
        vm.mensagemSucesso("Documentos Adicionados com sucesso");
        const pedido = vm.montarPedido();
        api
          .put("beneficios/finalizar-anexo", pedido)
          .then((res) => console.log(res))
          .catch((e) => {
            vm.mensagemErro(e.message);
          });
      } else {
        vm.mensagem(
          "Campos obrigatórios em branco!",
          "Preencha todos os campos obrigatórios"
        );
      }
      vm.fecharModal();
    },
    isSolicitacaoMudancaDental() {
      const solicitacaoDental =
        this.pedido.beneficio.tipo.id === 2 && this.pedido.tipoMudanca.id === 1;
      console.log(solicitacaoDental);

      return solicitacaoDental;
    },
    gerarAdesaoSolicitacaoDental() {
      const vm = this;
      vm.loader = true;
      const adesao = {};
      var link;
      adesao.nomeCompleto = vm.pedido.pessoa.nomPessoa;
      adesao.cpf = vm.pedido.pessoa.numCPF;
      adesao.tipoPlano = vm.pedido.beneficio.nome;
      adesao.dependentes = [];
      vm.pedido.dependentes.forEach((dep) => {
        let obj = {
          nome: dep.nome,
          cpf: dep.cpf,
          dtNascimento: dep.dtNascimento,
          grauParentesco: dep.grauParentesco.nome,
        };
        adesao.dependentes.push(obj);
      });
      vm.postFormularioAdesao(
        "/documentos/emitir/alteracao-plano-dental",
        adesao,
        "pdf"
      );
    },
    gerarAdesao() {
      const vm = this;
      if (vm.isSolicitacaoMudancaDental()) {
        vm.gerarAdesaoSolicitacaoDental();
        return;
      }
      vm.loader = true;
      const adesao = {};
      var link;
      adesao.nomeCompleto = vm.pedido.pessoa.nomPessoa;
      adesao.cpf = vm.pedido.pessoa.numCPF;
      adesao.tipoPlano = vm.pedido.beneficio.nome;
      adesao.dependentes = [];
      vm.pedido.dependentes.forEach((dep) => {
        let obj = {
          nome: dep.nome,
          cpf: dep.cpf,
          dtNascimento: dep.dtNascimento,
          grauParentesco: dep.grauParentesco.nome,
        };
        adesao.dependentes.push(obj);
      });
      vm.pedido.beneficio.tipo.id === 1
        ? (link = `/documentos/emitir/adesao-plano-saude`)
        : (link = `/documentos/emitir/adesao-plano-dental`);
      const tipoArquivo = vm.pedido.beneficio.tipo.id === 1 ? "docx" : "pdf";
      vm.postFormularioAdesao(link, adesao, tipoArquivo);
    },
    postFormularioAdesao(link, dadosAdesao, tipoArquivo) {
      const vm = this;
      api
        .post(link, dadosAdesao)
        .then((res) => {
          vm.abrirFormularioAdesao(res.data, tipoArquivo);
        })
        .catch((e) => {
          vm.mensagemErro(e.message);
        })
        .finally(() => (vm.loader = false));
    },
    abrirFormularioAdesao(bytePdf, tipoArquivo) {
      console.log(tipoArquivo);
      const vm = this;
      var file64 = vm.base64ToArrayBuffer(bytePdf);

      /* var file = tipoArquivo == "docx" ? 
      new Blob([file64], { type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document" }) : */
      var file = new Blob([file64], { type: "application/pdf" });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(file);
      link.download = "Formulário de Adesão";
      link.click();
    },
    base64ToArrayBuffer(base64) {
      var binaryString = window.atob(base64);
      var binaryLen = binaryString.length;
      var bytes = new Uint8Array(binaryLen);
      for (var i = 0; i < binaryLen; i++) {
        var ascii = binaryString.charCodeAt(i);
        bytes[i] = ascii;
      }
      return bytes;
    },
  },
};
</script>
<style scoped>
.estilo {
  border-radius: 10px;
  opacity: 1;
}

.borda-card {
  border-left: 3px solid #2d2d2d;
  padding-left: 10px;
  text-align: left !important;
  border-radius: 3px 0px 0px 3px;
  margin-bottom: 12px !important;
}

.icone-alerta {
  width: 49px;
  height: 43px;
  margin-left: 50px;
}

.alerta {
  width: 880px;
  margin: 10px 0px 40px 0px;
  min-height: 107px;
  background: #d7dad9 0% 0% no-repeat padding-box;
  border-radius: 38px;
  gap: 40px;
  align-items: center;
  display: inherit;
  opacity: 0.85;
}

.adicionar-dependente {
  border: 2px solid #278b97;
  padding: 30px 20px 40px 20px;
  margin-top: 15px;
}

.divider-tela-inicial {
  margin: 10px 5px 30px 5px;
  opacity: 1;
  justify-items: center;
}

.divider-tela {
  margin: 15px 5px 20px 5px;
  opacity: 1;
  justify-items: center;
}

.theme--light .v-list {
  background-color: #f7f7f7 !important;
}

.titulo {
  font: normal normal bold 18px/22px Montserrat;
  letter-spacing: 0px;
  color: #278b97;
  text-transform: uppercase;
  opacity: 1;
}

.espacamento-subtitulo {
  padding: 0px 15px;
}

.icone-titulo {
  width: 38px;
  height: 38px;
}

.alerta {
  width: auto;
  margin: 10px 0px 40px 0px;
  height: 107px;
  background: #edefee 0% 0% no-repeat padding-box;
  border-radius: 20px;
  gap: 40px;
  align-items: center;
  display: flex;
}

.icone-alerta {
  width: 49px;
  height: 43px;
  margin-left: 50px;
}

.icone-solicitacoes {
  width: 15px;
  height: 19px;
  margin-right: 5px;
}

.text-alerta {
  padding-top: 15px;
  text-align: center;
  max-width: 75%;
}

.inicio-titulo {
  display: inline-flex;
  gap: 10px;
  align-items: end;
}

.espacamento-titulo {
  padding-bottom: 20px;
}

.espacamento-btn-adicionar {
  padding-right: 15px;
}

.espacamento-inicial {
  padding: 10px;
}

.novo-btn {
  text-align: left;
  font: normal normal 600 12px/15px Montserrat;
  letter-spacing: 0.77px;
  color: #ffffff;
  opacity: 1;
}

.v-card > .v-card__title > .v-icon {
  position: absolute;
  right: 24px;
  color: #ffffff;
}

.espacamento {
  padding: 20px 20px 0px 20px;
}

.botao-sair {
  background-color: transparent !important;
  color: #278b97 !important;
  border-radius: 15px !important;
  text-transform: initial !important;
  height: 40px !important;
  font-family: Montserrat;
  font-weight: 500 !important;
  box-shadow: none !important;
  width: 200px;
  border: 1px solid #278b97;
}

@media screen and (max-width: 960px) {
  .alerta {
    flex-direction: column;
    padding: 20px 0;
  }
  .icone-alerta {
    margin-left: 0;
  }
  .text-alerta {
    text-align: center;
  }
}
</style>
