import Home from '@/views/Home.vue';
import Vue from 'vue';
import VueRouter from 'vue-router';
import authService from '@/services/AuthService';
import PaginaCabecalhoFooter from '../container/PaginaCabecalhoFooter.vue';
import Login from '../views/LoginView.vue';
import JeitoHepta from '../views/JeitoHeptaView.vue';
import biblioteca from '../views/BibliotecaView.vue';
import Novidades from '../views/NovidadesView.vue';
import PaginaSimples from '../container/PaginaSimples.vue';
import PaginaEspacoDoColaborador from '../container/PaginaEspacoDoColaborador.vue';
import MeusDados from '../views/EspacoDoColaborador/MeusDados.vue';
import Formacao from '../views/EspacoDoColaborador/Formacao.vue';
import Atestados from '../views/EspacoDoColaborador/Atestados.vue';
import Termos from '../views/EspacoDoColaborador/Termos.vue';
import Vagas from '../views/Vagas.vue';
import DetalheNoticia from '../views/DetalheNoticia.vue';
import PerguntasFrequentes from '../views/PerguntasFrequentes.vue';
import ContraCheque from '../views/EspacoDoColaborador/ContraCheque.vue';
import FolhaDePonto from '../views/EspacoDoColaborador/FolhaDePonto.vue';
import ProtecaoDeDados from '../views/EspacoDoColaborador/ProtecaoDeDados.vue';
import SistemasEServicos from '../views/EspacoDoColaborador/SistemasEServicos.vue';
import Ferias from '../views/EspacoDoColaborador/Ferias.vue';
import Beneficios from '../views/EspacoDoColaborador/Beneficios.vue';
import VisitaTecnica from '../views/EspacoDoColaborador/DashVisitaTecnica.vue';
import ProgramaGestaoDesempenho from '../views/EspacoDoColaborador/ProgramaGestaoDesempenho.vue';
import PesquisaView from '../views/PesquisaView.vue';
import ErroView from '../views/ErroView.vue';
import PerguntaView from '../views/PerguntaView.vue';
import Metricas from '../views/EspacoDoColaborador/Metricas.vue';
import api from '../services/Api.js';
import AcademiaHepta from '../views/EspacoDoColaborador/AcademiaHepta.vue';
import DetalheAcademiaHepta from '../views/EspacoDoColaborador/DetalheAcademiaHepta.vue';
import InformacoesUteis from '../components/EspacoDoColaborador/InformacoesUteis/InformacoesUteis.vue';
import descricaoVaga from '../views/DescricaoVaga.vue';
import cadastroVaga from '../views/FormCadastroVaga.vue';
import IndicacaoVaga from '@/views/IndicacaoVaga.vue';

Vue.use(VueRouter);
const routes = [
  {
    path: '',
    name: '',
    component: PaginaCabecalhoFooter,
    children: [
      {
        path: '',
        alias: 'home',
        name: 'Home',
        component: Home,
      },
      {
        path: 'novidades',
        name: 'Novidades',
        component: Novidades,
      },
      {
        path: 'jeitoHepta',
        name: 'Jeito Hepta',
        component: JeitoHepta,
      },
      {
        path: 'biblioteca',
        name: 'Biblioteca',
        component: biblioteca,
      },
      {
        path: 'perguntas-frequentes',
        name: 'Perguntas Frequentes',
        component: PerguntasFrequentes,
      },
      {
        path: 'vagas',
        name: 'Vagas',
        component: Vagas,
      },
      {
        path: '/detalhe-noticia',
        name: 'Detalhe Noticia',
        component: DetalheNoticia,
      },
      {
        path: 'pesquisa/:string',
        name: 'Pesquisa',
        component: PesquisaView,
      },
      {
        path: 'pergunta/:id',
        name: 'Pergunta',
        component: PerguntaView,
      },
      {
        path: 'vaga/:id',
        name: 'descricaoVaga',
        component: descricaoVaga,
      },
      {
        path: 'vaga/cadastro-vaga/:id',
        name: 'cadastroVaga',
        component: cadastroVaga,
      },
      {
        path: 'vaga/indicacao-vaga/:id',
        name: 'indicacaoVaga',
        component: IndicacaoVaga,
      },
    ],
  },
  {
    path: '/auth',
    component: PaginaSimples,
    children: [
      {
        path: '',
        name: 'Login',
        component: Login,
      },
    ],
  },
  {
    path: '/espaco-do-colaborador',
    name: 'EspacoDoColaborador',
    component: PaginaEspacoDoColaborador,
    children: [
      {
        path: 'meus-dados',
        alias: '',
        name: 'Meus Dados',
        component: MeusDados,
      },
      {
        path: 'formacao',
        name: 'Formacao',
        component: Formacao,
      },
      {
        path: 'atestados',
        name: 'Atestados',
        component: Atestados,
      },
      {
        path: 'termos',
        name: 'Termos',
        component: Termos,
      },
      {
        path: 'contra-cheque',
        name: 'ContraCheque',
        component: ContraCheque,
      },
      {
        path: 'folhadeponto',
        name: 'Folha de Ponto',
        component: FolhaDePonto,
      },
      {
        path: 'consentimento',
        name: 'Protecao de Dados',
        component: ProtecaoDeDados,
      },
      {
        path: 'ferias',
        name: 'Ferias',
        component: Ferias,
      },
      {
        path: 'beneficios',
        name: 'Beneficios',
        component: Beneficios,
      },
      {
        path: 'sistemas',
        name: 'Sistemas e Servicos',
        component: SistemasEServicos,
      },
      {
        path: 'visita-tecnica',
        name: 'Visita Tecnica',
        component: VisitaTecnica,
      },
      {
        path: 'programa-gestao-desempenho',
        name: 'Programa de Gestão de Desempenho',
        component: ProgramaGestaoDesempenho,
      },

      {
        path: 'metricas',
        name: 'Metricas',
        component: Metricas,
      },
      {
        path: 'academia-hepta',
        name: 'Academia Hepta',
        component: AcademiaHepta,
      },
      {
        path: '/detalhe-academia',
        name: 'Detalhe Academia',
        component: DetalheAcademiaHepta,
      },
      {
        path: 'informacoes-uteis',
        name: 'Informacoes Uteis',
        component: InformacoesUteis,
      },
    ],
  },
  // TODO: Pagina de erro genérica
  {
    path: '*',
    name: 'Erro',
    component: ErroView,
  },
];

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes,
});

const isTokenExpired = token => {
  if (!token) return true;
  try {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const payload = JSON.parse(window.atob(base64));
    return Date.now() >= payload.exp * 1000;
  } catch (e) {
    return true;
  }
};

router.beforeEach((to, from, next) => {
  const token = localStorage.getItem('token');
  const isAuthRoute = to.matched.some(r => r.path === '/auth');

  if (!isAuthRoute && isTokenExpired(token)) {
    localStorage.clear();
    return next('/auth');
  }

  if (isAuthRoute && token && !isTokenExpired(token)) return next('/');
  if (!isAuthRoute && !token) return next('/auth');

  next();
});

router.afterEach((to, from, failure) => {
  if (!failure && to.name != 'Login') {
    api.metricas(to.name, to.path);
  }
});
export default router;
