<template>
  <div>
    <Alerta
      v-on:fecharModal="fecharModal"
      :exibir="modal.exibir"
      :titulo="modal.titulo"
      :mensagem="modal.mensagem"
      v-on:exclusao="excluir()"
    >
    </Alerta>
    <Loader :loader-primario="loader"></Loader>
    <v-container fluid class="px-0 pt-0">
      <v-btn
        v-if="admin"
        @click="
          modalAlterarBanner = true;
          imagemModal = imagem.banner;
          pagina = 'banner';
        "
        class="banner-btn botao-edicao-adm"
      >
        <v-icon>fa fa-pencil</v-icon>
      </v-btn>
      <v-img
        :src="
          'data:' +
          imagem.banner.tipoArquivo +
          ';base64,' +
          imagem.banner.arquivo
        "
        class="banner"
      ></v-img>
      <ModalBanner
        :modalAlterarBanner="modalAlterarBanner"
        :imagem="imagemModal"
        :pagina="pagina"
        :comLink="false"
        @atualizaImagem="atualizaImagem"
        v-on:fecha-modal="modalAlterarBanner = false"
        :customMethod="getImagem"
        @mensagem="mensagem"
        @loader="isLoading"
      ></ModalBanner>
    </v-container>
    <v-container>
      <div class="breadcrumb" no-gutters>
        <router-link
          v-for="(breadcrumb, idx) in breadcrumbList"
          :to="breadcrumb.link"
          :key="idx"
          class="arrow"
        >
          <p>{{ breadcrumb.name }}</p>
        </router-link>
      </div>
      <div class="card">
        <v-form>
          <v-container>
            <v-row class="espacamento flex-column align-center">
              <h1>{{ vaga.nom_selecao }}</h1>
            </v-row>
            <v-row>
              <v-divider class="divider"></v-divider>
            </v-row>

            <v-row class="dados-vaga">
              <div class="d-flex align-center">
                <p>
                  <v-icon small color="#278b97" class="mr-2"
                    >fas fa-location-dot</v-icon
                  >{{ vaga.nom_cidade }} / {{ vaga.nom_uf }}
                </p>
                <p class="ml-16">
                  <v-icon small color="#278b97" class="mr-2"
                    >fas fa-calendar-days</v-icon
                  >{{ vaga.dta_aprovacao }}
                </p>
              </div>
            </v-row>
            <v-row>
              <v-col cols="6">
                <h3 class="mt-4">Meus Dados:</h3>
                <v-text-field
                  required
                  :rules="[(v) => !!v || 'Campo obrigatório']"
                  v-model="dadosPessoa.nom_pessoa"
                  label="Nome Completo *"
                  outlined
                ></v-text-field>
                <v-text-field
                  required
                  :rules="[(v) => !!v || 'Campo obrigatório']"
                  outlined
                  v-model="dadosPessoa.num_telefone"
                  label="Telefone *"
                ></v-text-field>
                <v-select
                  required
                  :rules="[(v) => !!v || 'Campo obrigatório']"
                  label="Escolaridade *"
                  :items="optionsEscolaridade"
                  item-text="nome"
                  item-value="valor"
                  outlined
                  v-model="dadosPessoa.escolaridade"
                ></v-select>
                <v-file-input
                  required
                  :rules="[(v) => !!v || 'Campo obrigatório']"
                  prepend-icon
                  prepend-inner-icon="fas fa-paperclip"
                  outlined
                  label="Currículo *"
                  v-model="dadosPessoa.nm_documento_cv"
                ></v-file-input>
              </v-col>
              <v-col class="mt-11" cols="6">
                <v-text-field
                  required
                  :rules="[(v) => !!v || 'Campo obrigatório']"
                  v-model="dadosPessoa.num_cpf"
                  label="CPF *"
                  outlined
                ></v-text-field>
                <v-text-field
                  outlined
                  required
                  :rules="[(v) => !!v || 'Campo obrigatório']"
                  v-model="dadosPessoa.des_email_pessoal"
                  label="Email *"
                ></v-text-field>
                <div class="d-flex">
                  <v-select
                    required
                    :rules="[(v) => !!v || 'Campo obrigatório']"
                    label="UF *"
                    class="mr-4"
                    :items="ufs"
                    item-text="nom_dominio"
                    item-value="cod_dominio"
                    outlined
                    v-model="dadosPessoa.uf"
                    @change="getMunicipios()"
                  ></v-select>
                  <v-select
                    required
                    :rules="[(v) => !!v || 'Campo obrigatório']"
                    label="Cidade *"
                    :items="cidades"
                    item-text="nom_municipio"
                    item-value="cod_municipio"
                    outlined
                    v-model="dadosPessoa.municipio"
                  ></v-select>
                </div>
              </v-col>
            </v-row>
            <v-row class="align-center">
              <v-col cols="6">
                <p class="d-flex align-center">PCD</p>
                <p class="d-flex align-center">
                  <v-radio-group v-model="dadosPessoa.is_pcd" mandatory>
                    <v-radio label="Sim" :value="true"></v-radio>
                    <v-radio label="Não" :value="false"></v-radio>
                  </v-radio-group></p
              ></v-col>
              <v-col cols="6"
                ><v-file-input
                  v-if="dadosPessoa.is_pcd"
                  required
                  :rules="[(v) => !!v || 'Campo obrigatório']"
                  prepend-icon
                  prepend-inner-icon="fas fa-paperclip"
                  outlined
                  label="Laudo *"
                  v-model="dadosPessoa.nm_documento_pcd"
                ></v-file-input
              ></v-col>
            </v-row>
            <v-row>
              <v-col cols="6">
                <h3 class="mt-4">Meus Conhecimentos:</h3>
                <ul
                  class="py-3"
                  v-for="(item, index) in vaga.competencias"
                  :key="index"
                >
                  <li class="item-lista">
                    <v-checkbox v-model="item.check" /> {{ item.nome }}
                  </li>
                  <v-radio-group v-model="item.nivel" v-if="item.check">
                    <v-radio label="Básico" value="'1'"></v-radio>
                    <v-radio label="Intermediário" value="'2'"></v-radio>
                    <v-radio label="Avançado" value="'3'"></v-radio>
                  </v-radio-group>
                </ul>
              </v-col>
              <v-col
                cols="6"
                v-if="vaga.certificacoes && vaga.certificacoes.length > 0"
              >
                <h3 class="mt-4">Minhas Certificações:</h3>
                <ul
                  class="py-3"
                  v-for="(item, index) in vaga.certificacoes"
                  :key="index"
                >
                  <li class="item-lista">
                    <v-checkbox v-model="item.check" />
                    {{ item.nome
                    }}<span
                      class="status status-obrigatorio"
                      v-if="item.obrigatorio"
                      >Obrigatório</span
                    >
                  </li>
                </ul>
              </v-col>
            </v-row>
            <v-row class="flex-column">
              <h3 class="mt-4">Remuneração</h3>
              <div class="d-flex">
                <v-text-field
                  required
                  :rules="[(v) => !!v || 'Campo obrigatório']"
                  class="mr-2"
                  outlined
                  type="number"
                  label="Pretenção Salarial *"
                  v-model="dadosPessoa.vlr_pretensao_salarial"
                ></v-text-field>
                <v-text-field
                  required
                  :rules="[(v) => !!v || 'Campo obrigatório']"
                  outlined
                  class="ml-2"
                  type="number"
                  label="Último Salário *"
                  v-model="dadosPessoa.vlr_ultimo_salario"
                ></v-text-field>
              </div>
            </v-row>
            <v-row>
              <h3>Últimos Benefícios Recebidos:</h3>
            </v-row>
            <v-row>
              <ul v-for="(item, index) in listaCategorias" :key="index">
                <li class="item-lista">
                  <v-checkbox v-model="item.selected" /> {{ item.nome }}
                </li>
                <v-radio-group
                  v-model="item.subSelected"
                  v-if="item.selected"
                  v-for="(sub, id) in item.subcategorias"
                  :key="id"
                >
                  <v-radio :label="sub.nome" :value="sub.valor"></v-radio>
                </v-radio-group>
              </ul>
            </v-row>
            <v-row class="flex-column">
              <h3 class="mt-4">Mais Informações</h3>
              <div>
                <v-select
                  :items="optionsComoSoubeVaga"
                  item-text="label"
                  item-value="value"
                  required
                  :rules="[(v) => !!v || 'Campo obrigatório']"
                  outlined
                  label="Como você soube da vaga?*"
                  v-model="dadosPessoa.comoSoube"
                >
                </v-select>
                <div v-if="dadosPessoa.comoSoube === 10" class="form-row">
                  <div class="form-group col-md-3">
                    <v-text-field
                      outlined
                      label="Qual outro? "
                      v-model="dadosPessoa.desComoSoubeHepta"
                    ></v-text-field>
                  </div>
                </div>
                <v-text-field
                  outlined
                  label="Mais sobre você "
                  v-model="dadosPessoa.maisSobreVoce"
                ></v-text-field>
                <v-text-field
                  outlined
                  v-model="dadosPessoa.nome_ultima_empresa"
                  label="Ultima Empresa"
                ></v-text-field>
              </div>
            </v-row>
            <v-row>
              <v-divider class="divider my-10"></v-divider>
            </v-row>
            <v-row class="justify-center mt-4">
              <v-btn class="botao-cancelar w-15 mr-3" to="/vagas">Voltar</v-btn>
              <v-btn class="botao-principal w-15" @click="candidatar()"
                >Candidatar-se</v-btn
              >
            </v-row>
          </v-container>
        </v-form>
      </div>
    </v-container>
  </div>
</template>
<script>
import api from "@/services/Api.js";
import Loader from "@/components/Loader.vue";
import modalMixin from "../mixins/modalMixin";
import Alerta from "@/components/Alerta.vue";
import ModalBanner from "@/components/Modal/ModalBanner.vue";
import { idsVaga } from "@/utils/ImagemEnums.js";
import router from "@/router";
import Utils from "@/utils/Utils";
export default {
  components: { Loader, Alerta, ModalBanner },
  mixins: [modalMixin],
  name: "cadastroVaga",
  data: () => ({
    vaga: {},
    loader: false,
    config: {
      header: {
        Authorization: localStorage.token,
        withCredentials: true,
      },
    },
    admin: false,
    pagina: "",
    imagemModal: {},
    imagem: {
      banner: {
        tipoArquivo: "",
        arquivo: "",
      },
    },
    modalAlterarBanner: false,
    dadosPessoa: {},
    checkCertificacoes: [],
    ufs: [],
    cidades: [],
    certificacoesSelecionadas: [],
    categoriasSelecionadas: [],
    breadcrumbList: [
      { name: "Home", link: "/" },
      { name: "Vagas Hepta", link: "/vagas" },
      { name: "Detalhes Vaga", link: `/vaga/` },
      { name: "Candidate-se", link: "" },
    ],
    optionsEscolaridade: [
      { nome: "Selecione", valor: null },
      { nome: "Fundamental Incompleto", valor: 45 },
      { nome: "Fundamental Completo", valor: 46 },
      { nome: "Médio Incompleto", valor: 47 },
      { nome: "Médio Completo", valor: 48 },
      { nome: "Curso Técnico", valor: 292 },
      { nome: "Superior Incompleto", valor: 49 },
      { nome: "Superior Cursando", valor: 293 },
      { nome: "Superior Completo", valor: 50 },
      { nome: "Pós-graduação (Lato senso) Incompleto", valor: 51 },
      { nome: "Pós-graduação (Lato senso) Completo", valor: 52 },
      {
        nome: "Pós-graduação (Stricto sensu, nível mestrado) Incompleto",
        valor: 53,
      },
      {
        nome: "Pós-graduação (Stricto sensu, nível mestrado) Completo",
        valor: 54,
      },
      {
        nome: "Pós-graduação (Stricto sensu, nível doutor) Incompleto",
        valor: 55,
      },
      {
        nome: "Pós-graduação (Stricto sensu, nível doutor) Completo",
        valor: 56,
      },
    ],
    listaCategorias: [
      {
        nome: "Plano de Saúde",
        valor: 1,
        selected: false,
        subSelected: null,
        subcategorias: [
          { nome: "Coparticipação", valor: 1 },
          { nome: "Pago integralmente pela empresa", valor: 2 },
          { nome: "Pago parcialmente pela empresa", valor: 3 },
        ],
      },
      {
        nome: "Plano de Odontológico",
        valor: 2,
        selected: false,
        subSelected: null,
        subcategorias: [
          { nome: "Pago integralmente pela empresa", valor: 2 },
          { nome: "Pago parcialmente pela empresa", valor: 3 },
          { nome: "Pago integralmente pelo colaborador", valor: 20 },
        ],
      },
      {
        nome: "Auxilio Creche",
        valor: 3,
        selected: false,
        subSelected: null,
        subcategorias: [
          { nome: "Pago integralmente pela empresa", valor: 2 },
          { nome: "Pago parcialmente pela empresa", valor: 3 },
        ],
      },
      {
        nome: "Vale alimentação/refeição",
        valor: 4,
        selected: false,
        subSelected: null,
        subcategorias: [
          { nome: "Somente vale alimentação", valor: 4 },
          { nome: "Somente vale refeição", valor: 5 },
          { nome: "Vale alimentação E refeição", valor: 6 },
        ],
      },
      {
        nome: "Vale Transporte",
        valor: 5,
        selected: false,
        subSelected: null,
        subcategorias: [{ nome: "Vale transporte", valor: 7 }],
      },
      {
        nome: "Auxílio Gasolina",
        valor: 6,
        selected: false,
        subSelected: null,
        subcategorias: [
          { nome: "Valor fixo mensal", valor: 8 },
          { nome: "Reembolso por quilometragem", valor: 9 },
        ],
      },
      {
        nome: "Celular Corporativo",
        valor: 7,
        selected: false,
        subSelected: null,
        subcategorias: [{ nome: "Celular corporativo", valor: 10 }],
      },
      {
        nome: "Seguro de vida",
        valor: 8,
        selected: false,
        subSelected: null,
        subcategorias: [{ nome: "Seguro de vida", valor: 11 }],
      },
      {
        nome: "GymPass / Auxílio academias",
        valor: 9,
        selected: false,
        subSelected: null,
        subcategorias: [
          { nome: "Gympass (ou outro semelhante)", valor: 12 },
          { nome: "Reembolso mensal", valor: 13 },
        ],
      },
      {
        nome: "Assistência psicológica / Auxílio terapia",
        valor: 10,
        selected: false,
        subSelected: null,
        subcategorias: [
          { nome: "Assistência psicológica contratada", valor: 14 },
          { nome: "Reembolso de sessões mensal", valor: 15 },
        ],
      },
      {
        nome: "Assistência jurídica (orientação jurídica)",
        valor: 11,
        selected: false,
        subSelected: null,
        subcategorias: [{ nome: "Assistência jurídica", valor: 16 }],
      },
      {
        nome: "Assistência financeira",
        valor: 12,
        selected: false,
        subSelected: null,
        subcategorias: [
          { nome: "Orientação financeira", valor: 17 },
          { nome: "Empréstimo consignado", valor: 18 },
        ],
      },
      {
        nome: "PLR (Participação nos Lucros e Resultados)",
        valor: 13,
        selected: false,
        subSelected: null,
        subcategorias: [
          { nome: "PLR (Participação nos Lucros e Resultados)", valor: 19 },
        ],
      },
    ],
    optionsComoSoubeVaga: [
      { value: 0, label: "ClickA" },
      { value: 1, label: "Catho" },
      { value: 2, label: "Promodef" },
      { value: 3, label: "CIEE" },
      { value: 4, label: "Empregare" },
      { value: 5, label: "Empregos_TI" },
      { value: 6, label: "Amigos" },
      { value: 7, label: "Linkedin" },
      { value: 8, label: "Facebook" },
      { value: 9, label: "Google Groups" },
      { value: 11, label: "Próprio site da Hepta" },
      { value: 12, label: "Estava cadastrado no banco de talentos da Hepta" },
      { value: 13, label: "Indeed" },
      { value: 14, label: "Já sou colaborador" },
      { value: 10, label: "Outro" },
    ],
  }),
  methods: {
    selecionaCategoria(id) {
      const vm = this;
      vm.listaCategorias.forEach((item) => {
        if (item.valor == id) {
          item.selected = true;
        }
      });
    },
    selecionaSubCategoria(id, idSub) {
      const vm = this;
      vm.listaCategorias.forEach((item) => {
        if (item.valor == id) {
          item.subSelected = idSub;
        }
      });
    },
    getDadosCandidato() {
      const vm = this;
      vm.loader = true;
      api
        .get(`recrutamento-selecao/candidato`, vm.config)
        .then((response) => {
          vm.dadosPessoa = response.data;
          vm.dadosPessoa.vlr_pretensao_salarial = null;
          vm.dadosPessoa.nm_documento_cv = null;
          vm.getMunicipios();
          vm.getUfs();
          vm.dadosPessoa.beneficios.forEach((beneficio) => {
            vm.selecionaCategoria(beneficio.idCategoria);
            vm.selecionaSubCategoria(
              beneficio.idCategoria,
              beneficio.idSubcategoria
            );
          });
        })
        .catch((e) => {
          this.mensagemErro(e.message);
        })
        .finally(() => {
          vm.loader = false;
          vm.getMunicipios();
          vm.getUfs();
        });
    },
    prepararDadosEnvio() {
      const vm = this;
      let competencias = vm.vaga.competencias.map((item) => {
        if (!item.check) {
          item.check = false;
        }
        return item;
      });
      vm.certificacoesSelecionadas = vm.vaga.certificacoes.map((item) => {
        if (!item.check) {
          item.check = false;
        }
        return item;
      });
      return {
        cod_selecao: vm.vaga.cod_selecao,
        cod_solicitacao: vm.vaga.cod_solicitacao,
        pessoa: {
          nom_pessoa: vm.dadosPessoa.nom_pessoa,
          des_email_pessoal: vm.dadosPessoa.des_email_pessoal,
          num_cpf: vm.dadosPessoa.num_cpf,
          num_telefone: vm.dadosPessoa.num_telefone,
          escolaridade: vm.dadosPessoa.escolaridade,
          descr_curso: vm.dadosPessoa.descr_curso,
          uf: vm.dadosPessoa.uf,
          municipio: vm.dadosPessoa.municipio,
          is_pcd: vm.dadosPessoa.is_pcd,
        },
        pretensao_salarial: vm.dadosPessoa.vlr_pretensao_salarial,
        ultimo_salario: vm.dadosPessoa.vlr_ultimo_salario,
        mais_sobre_voce: vm.dadosPessoa.maisSobreVoce,
        num_como_soube_vaga: vm.dadosPessoa.comoSoube,
        descr_como_soube_vaga: vm.dadosPessoa.desComoSoubeHepta,
        nome_ultima_empresa: vm.dadosPessoa.nome_ultima_empresa,
        competencias: competencias,
        certificados: vm.certificacoesSelecionadas,
        beneficios: vm.dadosPessoa.beneficios,
      };
    },
    prepararCandidatura(dadosEnvio, curriculo, laudo) {
      const vm = this;

      let candidaturaFormData = new FormData();
      candidaturaFormData.append("candidatura", JSON.stringify(dadosEnvio));

      if (curriculo != null) {
        candidaturaFormData.append("fileCV", curriculo);
      }

      if (laudo != null && vm.dados.is_pcd) {
        candidaturaFormData.append("filePCD", laudo);
      }

      return candidaturaFormData;
    },
    candidatar() {
      const vm = this;
      const dadosEnvio = vm.prepararDadosEnvio();
      if (
        vm.dadosPessoa.nm_documento_cv &&
        vm.dadosPessoa.nm_documento_cv.size / 1024 / 1024 > 7
      ) {
        vm.mensagemErro("O anexo de currículo deve possuir até 7Mb");
      }
      if (
        vm.dadosPessoa.nm_documento_pcd &&
        vm.dadosPessoa.nm_documento_pcd.size / 1024 / 1024 > 7
      ) {
        mensagemErro("O anexo do laudo deve possuir até 7Mb");
        return;
      }
      let dadosCandidatura = vm.prepararCandidatura(
        dadosEnvio,
        vm.dadosPessoa.nm_documento_cv,
        vm.dadosPessoa.nm_documento_pcd
      );
      vm.salvarCandidato(dadosCandidatura);
    },

    salvarCandidato(dadosCandidatura) {
      const vm = this;
      vm.loader = true;
      api
        .post(`recrutamento-selecao/candidatar/vaga`, dadosCandidatura)
        .then((response) => {
          if (response.status === 200) {
            vm.mensagemSucesso("Candidatura realizada com sucesso");
            setTimeout(() => {
              this.$router.push({
                path: "/vagas",
              });
            }, 2000);
          }
        })
        .catch((e) => {
          this.mensagemErro(e.message);
        })
        .finally(() => {
          vm.loader = false;
        });
    },
    getMunicipios() {
      const vm = this;
      vm.loader = true;
      api
        .get(`recrutamento-selecao/municipio/` + vm.dadosPessoa.uf, vm.config)
        .then((response) => {
          vm.cidades = response.data;
          return vm.cidades;
        })
        .catch((e) => {
          this.mensagemErro(e.message);
        })
        .finally(() => {
          vm.loader = false;
        });
    },
    getUfs() {
      const vm = this;
      vm.loader = true;
      api
        .get(`recrutamento-selecao/uf`, vm.config)
        .then((response) => {
          vm.ufs = response.data;
        })
        .catch((e) => {
          this.mensagemErro(e.message);
        })
        .finally(() => {
          vm.loader = false;
        });
    },
    buscarVagas() {
      const vm = this;
      vm.loader = true;
      api
        .get("recrutamento-selecao/dados-vaga/" + this.$route.params.id)
        .then((response) => {
          this.vaga = response.data;
          vm.breadcrumbList[2].link += this.$route.params.id;
          vm.vaga.dta_aprovacao = vm.formatDateDMY(vm.vaga.dta_aprovacao);
          vm.vaga.certificacoes.forEach((c) => {
            c.check = false;
          });
        })
        .catch((e) => {
          this.mensagemErro(e.message);
        })
        .finally(() => {
          vm.loader = false;
        });
    },
    formatDateDMY(date) {
      if (date == null) return "";
      var d = new Date(date),
        month = "" + (d.getMonth() + 1),
        day = "" + d.getDate(),
        year = d.getFullYear();

      if (month && month.length < 2) month = "0" + month;
      if (day && day.length < 2) day = "0" + day;

      return [day, month, year].join("/");
    },
    isLoading(e) {
      this.loader = e;
    },
    getImagem(id, pagina) {
      const vm = this;
      vm.loader = true;
      api
        .get("imagem/" + id)
        .then((response) => {
          //nome da variavel é o pagina. ex: vm.banner
          vm.imagem[pagina] = response.data;
          console.log(vm.imagem[pagina]);
        })
        .catch((e) => {
          vm.mensagem("Erro!", "Não foi possivel baixar imagem.");
        })
        .finally(() => {
          vm.loader = false;
        });
    },
    atualizaImagem(pagina, imagem, tipoArquivo) {
      const vm = this;
      vm.imagem[pagina].arquivo = imagem;
      vm.imagem[pagina].tipoArquivo = tipoArquivo;
      vm.modalAlterarBanner = false;
    },
    isAdmin() {
      this.admin = Utils.isAdmin();
    },
  },
  created() {
    const vm = this;
    vm.isAdmin();
  },
  mounted() {
    const vm = this;
    vm.buscarVagas();
    vm.getDadosCandidato();

    vm.isAdmin();

    for (let pagina in idsVaga) {
      //ex: this.getImagem(103, 'banner');
      vm.getImagem(idsVaga[pagina], pagina);
    }
  },
};
</script>
<style scoped>
.banner {
  position: relative;
}

.banner-btn {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
}

.espacamento {
  padding: 20px 40px;
}

.w-15 {
  width: 15%;
}

.divider {
  background-color: #278b97;
  margin-bottom: 20px;
  opacity: 55%;
}

.dados-vaga p {
  color: #278b97;
  display: flex;
}

.dados-vaga {
  padding: 12px;
  justify-content: space-between;
}

.subText {
  font-size: 80%;
}

.button {
  background-color: #278b97 !important;
  color: white !important;
  font: normal normal bold 18px/22px Montserrat;
}

.status {
  width: 157px;
  height: 35px;
  border-radius: 10px;
  opacity: 1;
  padding: 8px;
  text-align: center;
  font: normal normal bold 15px/19px Montserrat;
  letter-spacing: 0.3px;
  color: #ffffff;
  font-size: 14px;
}

.status-obrigatorio {
  background: #32acb9 0% 0% no-repeat padding-box;
}

.status-opcional {
  background: #e63b25 0% 0% no-repeat padding-box;
}

/* Breadcrumb */
.breadcrumb {
  display: flex;
  margin-bottom: 50px;
}

.arrow {
  min-width: 125px;
  text-align: center;
  height: 30px;
  background: #edefee;
  position: relative;
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #414141;
  cursor: pointer;
  text-decoration: none;
}

.arrow p {
  font-size: 0.7rem;
}

.arrow::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 90%;
  transform: translateY(-50%) rotate(45deg);
  z-index: 1;
  width: 22px;
  background-color: #edefee;
  height: 22px;
  border-top: 2px solid #ffffff;
  border-right: 2px solid #ffffff;
}

.arrow:last-child {
  padding: 0 10px 0 20px;
  background: #278b97;
}

.arrow:last-child p {
  color: #ffffff;
}

.arrow:last-child:before {
  background: #278b97;
  border: none;
}

.item-lista {
  display: flex;
  align-items: center;
}

/* Breadcrumb */
</style>
