<template>
  <div>
    <Loader :loader-primario="loader" />

    <Alerta v-on:fecharModal="fecharModal" :exibir="modal.exibir" :titulo="modal.titulo" :mensagem="modal.mensagem" />

    <v-container>
      <v-row>
        <v-col cols="12">
          <template>
            <v-form ref="form">
              <!-- Tabela Resultado -->
              <v-row>
                <v-col cols="12" class="header">
                  <v-icon>fas fa-chart-line</v-icon>
                  <h2>Resultado</h2>
                </v-col>
                <v-col cols="12" class="pa-0">
                  <v-simple-table class="tb-resultado">
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th></th>
                          <th>% Alcançada</th>
                          <th>Classificação</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td class="tituloCelula">Atitudes</td>
                          <td>{{ getPorcentagem(notasAtitudes, notaMaximaAtitudes) }}</td>
                          <td>{{ getClassificacao(((notasAtitudes / notaMaximaAtitudes) * 100).toFixed(2)) }}</td>
                        </tr>
                        <tr>
                          <td class="tituloCelula">Atividades</td>
                          <td>{{ getPorcentagem(notasAtividades, notaMaximaAtividades) }}</td>
                          <td>{{ getClassificacao(((notasAtividades / notaMaximaAtividades) * 100).toFixed(2)) }}</td>
                        </tr>
                        <tr>
                          <td class="tituloCelula">Perfeição Técnica</td>
                          <td>{{ getPorcentagem(notasPerfeicaoTecnica, notaMaximaPerfeicaoTecnica) }}</td>
                          <td>
                            {{
                              getClassificacao(((notasPerfeicaoTecnica / notaMaximaPerfeicaoTecnica) * 100).toFixed(2))
                            }}
                          </td>
                        </tr>
                        <!-- Média -->
                        <tr>
                          <td class="tituloCelula"><b>Média Geral</b></td>
                          <td>
                            {{
                              getPorcentagemMedia([
                                [notasAtitudes, notaMaximaAtitudes],
                                [notasAtividades, notaMaximaAtividades],
                                [notasPerfeicaoTecnica, notaMaximaPerfeicaoTecnica],
                              ])
                            }}%
                          </td>
                          <td>
                            {{
                              getConverterNota(
                                getClassificacao(((notasAtitudes / notaMaximaAtividades) * 100).toFixed(2)),
                                getClassificacao(((notasAtividades / notaMaximaAtividades) * 100).toFixed(2)),
                                getClassificacao(
                                  ((notasPerfeicaoTecnica / notaMaximaPerfeicaoTecnica) * 100).toFixed(2)
                                )
                              )
                            }}
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-col>
                <v-col cols="12" class="px-0">
                  <v-divider class="my-5" />
                </v-col>
              </v-row>

              <!-- Tabela Formulário -->
              <v-row>
                <v-col cols="12" class="header">
                  <v-icon>fas fa-list</v-icon>
                  <h2>Avaliação</h2>
                </v-col>
                <v-col cols="12" class="pa-0">
                  <v-simple-table class="tb-formulario">
                    <!-- Cabeçalho -->
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th>Aplico / Pratico</th>
                          <th v-for="item in headers" :key="item.id" class="text-center">{{ item.header }}</th>
                        </tr>
                      </thead>
                      <!-- Linhas -->
                      <tbody>
                        <!-- Atitudes -->
                        <tr class="tiposQuestao">
                          <td :colspan="headers.length + 1">
                            <v-row>
                              <v-col>Atitudes</v-col>
                              <v-col class="d-flex justify-end">
                                <span v-show="isCollapsed('Atitudes')" @click="expand('Atitudes')">
                                  <v-icon>fas fa-chevron-down</v-icon>
                                </span>
                                <span v-show="!isCollapsed('Atitudes')" @click="expand('Atitudes')">
                                  <v-icon>fas fa-chevron-up</v-icon>
                                </span>
                              </v-col>
                            </v-row>
                          </td>
                        </tr>
                        <template v-for="atitude in atitudes">
                          <tr v-if="!isCollapsed('Atitudes')">
                            <!-- Título Linha-->
                            <td>
                              <div>
                                <p>{{ atitude.descricao }}</p>
                              </div>
                            </td>
                            <!-- Radio buttons -->
                            <td v-for="header in headers">
                              <v-radio-group disabled v-model="atitude.notaCalibragem">
                                <v-radio :value="header.id + 1" class="radio-input"></v-radio>
                              </v-radio-group>
                            </td>
                          </tr>
                        </template>

                        <!-- Atividades -->
                        <tr class="tiposQuestao">
                          <td :colspan="headers.length + 1">
                            <v-row>
                              <v-col>Atividades</v-col>
                              <v-col class="d-flex justify-end">
                                <span v-show="isCollapsed('Atividades')" @click="expand('Atividades')">
                                  <v-icon>fas fa-chevron-down</v-icon>
                                </span>
                                <span v-show="!isCollapsed('Atividades')" @click="expand('Atividades')">
                                  <v-icon>fas fa-chevron-up</v-icon>
                                </span>
                              </v-col>
                            </v-row>
                          </td>
                        </tr>
                        <template v-for="atividade in atividades">
                          <tr v-if="!isCollapsed('Atividades')">
                            <!-- Título Linha-->
                            <td>
                              <div>
                                <p>{{ atividade.descricao }}</p>
                              </div>
                            </td>
                            <!-- Radio buttons -->
                            <td v-for="header in headers">
                              <v-radio-group disabled v-model="atividade.notaCalibragem">
                                <v-radio :value="header.id + 1" class="radio-input"></v-radio>
                              </v-radio-group>
                            </td>
                          </tr>
                        </template>

                        <!-- Perfeição Técnica -->
                        <tr class="tiposQuestao">
                          <td :colspan="headers.length + 1">
                            <v-row>
                              <v-col>Perfeição Técnica</v-col>
                              <v-col class="d-flex justify-end">
                                <span v-show="isCollapsed('Perfeição Técnica')" @click="expand('Perfeição Técnica')">
                                  <v-icon>fas fa-chevron-down</v-icon>
                                </span>
                                <span v-show="!isCollapsed('Perfeição Técnica')" @click="expand('Perfeição Técnica')">
                                  <v-icon>fas fa-chevron-up</v-icon>
                                </span>
                              </v-col>
                            </v-row>
                          </td>
                        </tr>
                        <template v-if="!isCollapsed('Perfeição Técnica')">
                          <tr class="row-definicao">
                            <th>Nível de Perfeição Técnica o colaborador demonstra em suas atividades e entregas</th>
                            <th v-for="item in definicaoNotasPerfeicaoTecnica">{{ item.nome }}</th>
                          </tr>
                          <template v-for="item in perfeicoestecnicas">
                            <tr>
                              <!-- Título Linha-->
                              <td>{{ definicaoPerfeicaoTecnica.descricao }}</td>
                              <!-- Radio buttons -->
                              <td v-for="header in headers">
                                <v-radio-group disabled v-model="item.notaCalibragem">
                                  <v-radio :value="header.id + 1" class="radio-input"></v-radio>
                                </v-radio-group>
                              </td>
                            </tr>
                          </template>
                        </template>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-col>
              </v-row>
            </v-form>
          </template>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import Loader from '@/components/Loader.vue';
import Alerta from '@/components/Alerta.vue';
import modalMixin from '@/mixins/modalMixin';
import api from '@/services/Api.js';

import JS from '../methods.js';

export default {
  name: 'ViewAvaliacao',
  components: { Loader, Alerta, api },
  mixins: [modalMixin],
  props: {
    cod_avaliacao: { type: Number, default: null },
  },
  data() {
    return {
      media: null,
      dtFinalizado: null,
      dadosAutoAvaliado: null,
      statusAutoavaliacao: null,
      definicaoPerfeicaoTecnica: null,
      definicaoNotasPerfeicaoTecnica: null,

      loader: false,
      animated: false,
      questoesSemResposta: false,

      listaQuestoes: [],
      headers: [],
      tiposQuestao: [],
      tiposQuestaoCollapsed: [],
      atitudes: [],
      atividades: [],
      perfeicoestecnicas: [],

      NOTA_MAXIMA_POSSIVEL: 5,
      notasAtitudes: 0,
      notaMaximaAtitudes: 0,
      notasAtividades: 0,
      notaMaximaAtividades: 0,
      notasPerfeicaoTecnica: 0,
      notaMaximaPerfeicaoTecnica: 0,
    };
  },
  methods: {
    ...JS,
    buscarHeaders() {
      return api
        .get(`headersAutoavaliacao/listarHeaders`)
        .then(res => {
          res.data.forEach(item => {
            this.headers.push({ id: item.id, header: item.header });
          });
        })
        .catch(e => {
          this.mensagemErro(e.message);
        });
    },
    getAtitudes() {
      api
        .get(`avaliacaoDesempenho/competenciasComportamentais`, {
          headers: { Authorization: localStorage.token },
          withCredentials: true,
          params: { codAvaliacao: this.cod_avaliacao },
        })
        .then(res => {
          this.atitudes = res.data;
          this.somarAtitudes();
        })
        .catch(e => {
          this.mensagemErro(e.message);
        });
    },
    somarAtitudes() {
      this.atitudes.forEach(item => {
        if (item.notaCalibragem !== 1) this.notasAtitudes += item.notaCalibragem;
      });
      this.notaMaximaAtitudes =
        this.atitudes.filter(item => {
          return item.notaCalibragem > 1;
        }).length * this.NOTA_MAXIMA_POSSIVEL;
    },
    getAtividades() {
      api
        .get(`avaliacaoDesempenho/competenciasAtividades`, {
          headers: { Authorization: localStorage.token },
          withCredentials: true,
          params: { codAvaliacao: this.cod_avaliacao },
        })
        .then(res => {
          this.atividades = res.data;
          this.somarAtividades();
        })
        .catch(e => {
          this.mensagemErro(e.message);
        });
    },
    somarAtividades() {
      this.atividades.forEach(item => {
        if (item.notaCalibragem !== 1) this.notasAtividades += item.notaCalibragem;
      });
      this.notaMaximaAtividades =
        this.atividades.filter(item => {
          return item.notaCalibragem > 1;
        }).length * this.NOTA_MAXIMA_POSSIVEL;
    },
    getperfeicaoTecnica() {
      api
        .get(`avaliacaoDesempenho/competenciasPerfeicaoTecnica`, {
          headers: { Authorization: localStorage.token },
          withCredentials: true,
          params: { codAvaliacao: this.cod_avaliacao },
        })
        .then(res => {
          this.perfeicoestecnicas = res.data;
          this.somarPerfeicoesTecnicas();
        })
        .catch(e => {
          this.mensagemErro(e.message);
        });
    },
    somarPerfeicoesTecnicas() {
      this.perfeicoestecnicas.forEach(item => {
        if (item.notaCalibragem !== 1) this.notasPerfeicaoTecnica += item.notaCalibragem;
      });
      this.notaMaximaPerfeicaoTecnica = this.perfeicoestecnicas.length * this.NOTA_MAXIMA_POSSIVEL;
    },
    getDefinicaoPerfeicaoTecnica() {
      api
        .get(`avaliacaoDesempenho/definicaoPerfeicaoTecnica`, {
          headers: { Authorization: localStorage.token },
          withCredentials: true,
        })
        .then(res => {
          this.definicaoPerfeicaoTecnica = res.data;
        })
        .catch(e => {
          this.mensagemErro(e.message);
        });
    },
    getNotasPerfeicaoTecnica() {
      api
        .get(`avaliacaoDesempenho/notasPerfeicaoTecnica`, {
          headers: { Authorization: localStorage.token },
          withCredentials: true,
        })
        .then(res => {
          this.definicaoNotasPerfeicaoTecnica = res.data;
        })
        .catch(e => {
          this.mensagemErro(e.message);
        });
    },
    getConverterNota(mediaAtitudes, mediaAtividade, mediaPerfeicao) {
      let notaFinal = 0;
      let notaMaxima = 0;

      if (mediaAtitudes !== 'Não se aplica') {
        notaMaxima += 3;
        notaFinal += mediaAtitudes === 'Supera as expectativas' ? 3 : mediaAtitudes === 'Atende' ? 2 : 1;
      }
      if (mediaAtividade !== 'Não se aplica') {
        notaMaxima += 3;
        notaFinal += mediaAtividade === 'Supera as expectativas' ? 3 : mediaAtividade === 'Atende' ? 2 : 1;
      }
      if (mediaPerfeicao !== 'Não se aplica') {
        notaMaxima += 3;
        notaFinal += mediaPerfeicao === 'Supera as expectativas' ? 3 : mediaPerfeicao === 'Atende' ? 2 : 1;
      }

      if (notaMaxima === 0) return 'Erro';

      // A partir deste ponto, notaMaxima pode ser: 3, 6, 9. Sendo 3=(caso 1), 6=(caso 2), 9=(caso 3)
      if (notaFinal >= notaMaxima)
        // Todos os Casos: todas as categorias devem ter sido classificadas como 'Supera as expectativas'
        return 'É referência';
      else if (notaFinal >= notaMaxima - 1)
        // Caso 1 e 2: Cai em 'É referência' | Caso 3: notaFinal > 7
        return 'Supera as expectativas';
      else if (notaFinal > notaMaxima / 3)
        // Caso 1: notaFinal > 1 | Caso 2: notaFinal > 2 | Caso 3: notaFinal > 3
        return 'Atende';
      else return 'Abaixo do esperado';
    },
    getPorcentagemMedia(vals, isPerfeicaoTecnica = false) {
      let valoresValidos = [];

      /*  Em perfeição técnica os valores podem ser 0, diferente das outras categorias onde 0 é desconsiderado.
          Perfeição técnica é sempre enviada como último valor. */
      if (isPerfeicaoTecnica)
        valoresValidos = vals
          .slice(0, -1)
          .filter(([_, max]) => max !== 0)
          .concat([vals[vals.length - 1]]);
      else valoresValidos = vals.filter(([_, max]) => max !== 0);

      if (valoresValidos.length === 0) return 'Erro';

      const notas = valoresValidos.reduce((acc, [nota, _]) => (acc += nota), 0);
      const maximoSoma = valoresValidos.reduce((acc, [_, maximo]) => (acc += maximo), 0);

      return ((notas / maximoSoma) * 100).toFixed(2);
    },
    getPorcentagem(notaFinal, notaMaxima) {
      if (isNaN(notaFinal / notaMaxima)) return 'Não se aplica';
      else return ((notaFinal / notaMaxima) * 100).toFixed(2) + '%';
    },
    getClassificacao(numero) {
      const porcentagem = Number(numero);
      if (porcentagem < 70) {
        return 'Abaixo do esperado';
      } else if (porcentagem >= 70 && porcentagem <= 89.9) {
        return 'Atende';
      } else if (porcentagem >= 90 && porcentagem <= 100) {
        return 'Supera as expectativas';
      } else if (porcentagem > 100) {
        return 'É referência';
      } else return 'Não se aplica';
    },
  },
  async mounted() {
    await this.buscarHeaders();
    await this.getAtitudes();
    await this.getAtividades();
    await this.getperfeicaoTecnica();
    await this.getDefinicaoPerfeicaoTecnica();
    await this.getNotasPerfeicaoTecnica();
  },
};
</script>

<style scoped>
.row-definicao th {
  color: #fff;
  font-size: 0.875rem !important;
}

.row-definicao th:not(:first-child) {
  text-align: center !important;
}

.tb-resultado > .v-data-table__wrapper > table > thead > tr > th::after {
  content: '';
  position: absolute;
  border: 1px solid;
  height: 75%;
  right: 0;
  top: 6px;
  color: #fffa;
}

.tb-resultado.v-data-table > .v-data-table__wrapper > table > thead > tr > th,
.tb-formulario.v-data-table > .v-data-table__wrapper > table > thead > tr > th {
  border-bottom: none !important;
}
</style>
