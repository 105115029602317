<template>
    <v-dialog v-model="modalSistema" width="1800" @click:outside="fechaModal()">
        <v-card>
            <v-card-title>
                <h3 v-if="item.id == null">Sistema ou Serviço </h3>
                <h3 v-else> {{ item.titulo }} </h3>
                <v-icon @click="fechaModal()">fa-times</v-icon>
            </v-card-title>

            <v-card-text>
                <div class="espacamento">
                    <v-container>

                        <v-col cols="12" class="espacamento-v-col">
                            <v-text-field color="#278b97" label="Titulo:" v-model="item.titulo" :rules="rules.obrigatorio"
                                outlined dense></v-text-field>
                        </v-col>

                        <v-col cols="12" class="espacamento-v-col">
                            <v-textarea color="#278b97" v-model="item.descricao" label="Descrição:" outlined dense>
                            </v-textarea>
                        </v-col>

                        <v-col cols="12" class="espacamento-v-col">
                            <v-text-field color="#278b97" label="Link:" :rules="rules.obrigatorio" v-model="item.url"
                                outlined dense></v-text-field>
                        </v-col>

                        <v-col cols="12" class="espacamento-v-col">
                            <v-row justify="space-around">
                                <v-col cols="6" class="espacamento-v-col">
                                    <v-autocomplete color="#278b97" :rules="rules.obrigatorio" clearable chips
                                        label="Categoria:" outlined dense class="espacamento-esquerda"
                                        :items="categorias" item-value="id" item-text="titulo"
                                        v-model="item.categoria.id"></v-autocomplete>
                                </v-col>

                                <v-col cols="6" class="espacamento-v-col">
                                    <v-autocomplete color="#278b97" :rules="rules.tag" clearable chips label="Tags:"
                                        outlined dense class="espacamento-direita" v-model="item.tags" :items="tags"
                                        return-object item-text="nome" multiple>
                                    </v-autocomplete>
                                </v-col>
                            </v-row>
                        </v-col>

                        <v-col cols="12" class="espacamento-v-col">
                            <v-row justify="space-around">
                                <v-col class="espacamento-v-col2">
                                    <v-file-input @change="anexarArquivo('imagem', arquivo); novaImagem = true" prepend-icon
                                        prepend-inner-icon="fas fa-paperclip" v-model="arquivo" outlined dense
                                        label="Anexar imagem de capa" placeholder=" Escolha a imagem..." accept="image/*"
                                        hide-details="auto" :rules="rules.imagemObrigatoria"></v-file-input>
                                </v-col>
                            </v-row>
                        </v-col>


                    </v-container>
                    <v-col cols="12" class="espacamento-v-col">
                        <v-col cols="3" class="espacamento-v-col">
                            <v-switch class="espacamento-esquerda" color="#278b97" label="Rascunho"
                                v-model="item.rascunho"></v-switch>
                        </v-col>
                    </v-col>

                    <v-col cols="12" class="espacamento-v-col">
                        <v-divider insert class="divider-tela"></v-divider>
                    </v-col>

                </div>
            </v-card-text>

            <v-card-actions class="justify-center pb-10">
                <v-btn class="botao-cancelar mr-2 largura-btn" @click="fechaModal()"><v-icon small color="black"
                        class="mr-3">fa-solid fa-xmark</v-icon>Cancelar</v-btn>
                <v-btn class="botao-salvar mr-2 largura-btn" @click="salvar()"><v-icon small color="#ffffff"
                        class="mr-3">fas
                        fa-save</v-icon>
                    <div v-if="item.rascunho">Salvar</div>
                    <div v-else>Publicar</div>

                </v-btn>
            </v-card-actions>


        </v-card>

    </v-dialog>
</template>
  
<script>
import api from "@/services/Api.js";

export default {
    name: "modal-biblioteca",
    props: {
        tags: [],
        categorias: [],
        item: {},
        value: {
            type: Boolean,
            default: false,
        },
    },
    data: () => ({
        modalSistema: false,
        download: false,
        arquivo: null,
        novaImagem: false,
        arquivoImagem: null,
        rules: {
            imagemObrigatoria: [(v) => !!v || "A imagem é obrigatória."],
            tag: [
                (value) => value.length > 0 || "Campo obrigatório",
            ],
            obrigatorio: [
                (value) => !!value || "Campo obrigatório",
            ],
        },
    }),
    watch: {
        value(e) {
            this.modalSistema = e;
            if (this.item.id) {
                this.arquivo = new File([this.item.titulo], this.item.nomImagem);
            }
        },
    },
    methods: {
        fechaModal: function () {
            this.$emit("fecha-modal");
            this.limparVariaveis();
        },
        salvar() {
            const vm = this;

            if (!vm.arquivo) return;

            vm.item.descricao = vm.item.descricao ? vm.item.descricao : "Sem descrição...";
            vm.item.dataInclusao = vm.item.dataInclusao ? delete vm.item.dataInclusao : vm.item.dataInclusao;

            let itemFormData = new FormData();
            itemFormData.append('json', JSON.stringify(vm.item));

            if (vm.novaImagem) itemFormData.append('imagem', vm.arquivoImagem);

            if (vm.item.id == null) vm.criar(itemFormData);
            else vm.editar(itemFormData);
        },
        criar(item) {
            api.post("link/criar", item).then((response) => {
                this.$emit('capturarMensagemSucesso', response.data);
            })
                .catch(e => {
                    this.$emit('capturarMensagemErro', e);
                }).finally(() => {
                    this.$emit("salvar");
                    this.limparVariaveis();
                });
        },
        editar(item) {
            api.post("link/atualizar", item).then((response) => {
                this.$emit('capturarMensagemSucesso', response.data);
            })
                .catch(e => {
                    this.$emit('capturarMensagemErro', e);
                }).finally(() => {
                    this.$emit("salvar");
                    this.limparVariaveis();
                });
        },
        base64ToArrayBuffer(base64) {
            var binary_string = window.atob(base64);
            var len = binary_string.length;
            var bytes = new Uint8Array(len);
            for (var i = 0; i < len; i++) {
                bytes[i] = binary_string.charCodeAt(i);
            }
            return bytes.buffer;
        },
        anexarArquivo(categoriaLink, listaLinks) {
            const vm = this;

            if (categoriaLink === 'imagem') {
                vm.arquivoImagem = listaLinks;
                vm.item.nomImagem = listaLinks.name;
            }
        },
        limparVariaveis() {
            this.anexoNovo = false;
            this.arquivoImagem = null;
            this.arquivo = null;
            this.novaImagem = false;
        },
    },
    created: function () {

    },
};
</script>
<style scoped>
.divider-tela {
    margin: 30px;
    opacity: 1.0;
    justify-items: center;
}

.estilo {
    border-radius: 10px;
    opacity: 1;
}

.espacamento {
    padding: 20px;
    background-color: #e9e9e9;
}

.espacamento-v-col {
    padding: 5px;
}


.espacamento-v-col2 {
    padding: 12px !important;
}

.baixar {
    display: inline;
    margin-left: 5px;
    cursor: pointer;
}


.espacamento-esquerda {
    padding-left: 10px;
}

.espacamento-direita {
    padding-right: 10px;
}

.espacamento-top {
    padding-top: 20px;
}

.btn-baixar {
    cursor: pointer;
}

.v-card>.v-card__title>.v-icon {
    position: absolute;
    right: 24px;
    color: #ffffff;
}

.btn-baixar>.baixar:hover {
    text-shadow: 0 0 0.05em #757575b3;
    color: #278b97 !important;
}

.v-list-item>.v-application {
    color: #278b97 !important;
    caret-color: #278b97 !important;
}
</style>
  
  
  