<template>
  <div>
    <Alerta
      v-on:fecharModal="fecharModal"
      :exibir="modal.exibir"
      :titulo="modal.titulo"
      :mensagem="modal.mensagem"
      v-on:exclusao="excluir()"
    >
    </Alerta>
    <Loader :loader-primario="loader"></Loader>
    <v-container fluid class="pa-0">
      <v-btn
        v-if="admin"
        @click="
          modalAlterarBanner = true;
          imagemModal = imagem.banner;
          pagina = 'banner';
        "
        class="banner-btn botao-edicao-adm"
      >
        <v-icon>fa fa-pencil</v-icon>
      </v-btn>
      <v-img
        :src="
          'data:' +
          imagem.banner.tipoArquivo +
          ';base64,' +
          imagem.banner.arquivo
        "
        class="banner"
      ></v-img>
      <ModalBanner
        :modalAlterarBanner="modalAlterarBanner"
        :imagem="imagemModal"
        :pagina="pagina"
        :comLink="false"
        @atualizaImagem="atualizaImagem"
        v-on:fecha-modal="modalAlterarBanner = false"
        :customMethod="getImagem"
        @mensagem="mensagem"
        @loader="isLoading"
      ></ModalBanner>
    </v-container>
    <v-container>
      <div class="breadcrumb" no-gutters>
        <router-link
          v-for="(breadcrumb, idx) in breadcrumbList"
          :to="breadcrumb.link"
          :key="idx"
          class="arrow"
        >
          <p>{{ breadcrumb.name }}</p>
        </router-link>
      </div>
      <div class="card">
        <v-container>
          <v-row class="espacamento flex-column align-center">
            <h1>{{ vaga.nom_selecao }}</h1>
          </v-row>
          <v-row>
            <v-divider class="divider"></v-divider>
          </v-row>

          <v-row class="dados-vaga">
            <v-col cols="12" sm="8">
              <div class="d-flex align-center">
                <p>
                  <v-icon small color="#278b97" class="mr-2"
                    >fas fa-location-dot</v-icon
                  >{{ vaga.nom_cidade }} / {{ vaga.nom_uf }}
                </p>
                <p class="ml-16">
                  <v-icon small color="#278b97" class="mr-2"
                    >fas fa-calendar-days</v-icon
                  >{{ vaga.dta_aprovacao }}
                </p>
              </div>
            </v-col>
            <v-col cols="12" sm="4">
              <div class="d-flex align-end">
                <router-link
                  :to="`cadastro-vaga/${id}`"
                  class="text-decoration-none w-100 text-end"
                >
                  <v-btn class="botao-principal w-75">Candidatar-se</v-btn>
                </router-link>
                <router-link
                  :to="`indicacao-vaga/${id}`"
                  class="text-decoration-none w-100 text-end"
                >
                  <v-btn class="botao-principal w-75">Indicar</v-btn>
                </router-link>
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6">
              <h3>Escolaridade:</h3>
              <div class="px-10 py-3">
                <h4>{{ vaga.grau_escolaridade }}</h4>
                <ul
                  v-for="(item, index) in vaga.nom_curso_formacao"
                  :key="index"
                >
                  <li>{{ item }}</li>
                </ul>
              </div>
              <h3 class="mt-4">Conhecimentos:</h3>
              <ul
                class="px-10 py-3"
                v-for="(item, index) in vaga.competencias"
                :key="index"
              >
                <li>{{ item.nome }}</li>
              </ul>
            </v-col>
            <v-col
              cols="6"
              v-if="vaga.certificacoes && vaga.certificacoes.length > 0"
            >
              <h3>Certificações:</h3>
              <ul
                class="px-10 py-3"
                v-for="(item, index) in vaga.certificacoes"
                :key="index"
              >
                <li>
                  {{ item.nome }}
                  <span
                    class="status status-obrigatorio"
                    v-if="item.obrigatorio"
                    >Obrigatório</span
                  >
                  <span class="status status-opcional" v-else>Opcional</span>
                </li>
              </ul>
            </v-col>
          </v-row>
          <v-row>
            <v-divider class="divider my-10"></v-divider>
          </v-row>
          <v-row class="justify-center mt-4">
            <v-btn class="botao-cancelar w-15 mr-3" to="/vagas">Voltar</v-btn>
            <router-link
              :to="`cadastro-vaga/${id}`"
              class="text-decoration-none w-15"
            >
              <v-btn class="botao-principal w-100">Candidatar-se</v-btn>
            </router-link>
            <router-link
              :to="`indicacao-vaga/${id}`"
              class="text-decoration-none w-15 ml-3"
            >
              <v-btn class="botao-principal w-100">Indicar</v-btn>
            </router-link>
          </v-row>
        </v-container>
      </div>
    </v-container>
    <div class="mx-auto my-4">
      <v-row class="justify-center mt-4">
        <v-col
          v-for="vaga in vagas"
          :key="vaga.cod_solicitacao"
          cols="12"
          lg="2"
          class="d-flex justify-center"
        >
          <CardVagas
            :titulo="vaga.nom_funcao_sugerida"
            :tipo="
              vaga.cod_tipo_solicitacao == 304 ? 'Vaga Mista' : 'Vaga Interna'
            "
            :local="vaga.nom_cidade"
            :data="vaga.dta_aprovacao"
            :id="vaga.cod_solicitacao"
            :isInterna="vaga.cod_tipo_solicitacao === 302"
          >
          </CardVagas>
        </v-col>
      </v-row>
      <v-row class="justify-center mt-4">
        <v-col class="text-center">
          <router-link to="vagas" class="text-decoration-none w-100">
            <v-btn class="botao-azul">
              <v-icon small color="#ffffff" class="mr-3">
                fas fa-circle-plus
              </v-icon>
              Ver todas as vagas
            </v-btn>
          </router-link>
        </v-col>
      </v-row>
    </div>
  </div>
</template>
<script>
import api from "@/services/Api.js";
import Loader from "@/components/Loader.vue";
import modalMixin from "../mixins/modalMixin";
import Alerta from "@/components/Alerta.vue";
import ModalBanner from "@/components/Modal/ModalBanner.vue";
import { idsVaga } from "@/utils/ImagemEnums.js";
import CardVagas from "@/components/CardVagas.vue";
import Utils from "@/utils/Utils";
export default {
  components: { Loader, Alerta, ModalBanner, CardVagas },
  mixins: [modalMixin],
  name: "descricaoVaga",
  data: () => ({
    vaga: {},
    loader: false,
    breadcrumbList: [
      { name: "Home", link: "/" },
      { name: "Vagas Hepta", link: "/vagas" },
      { name: "Detalhes Vaga", link: "/cadastro-vaga" },
    ],
    admin: false,
    pagina: "",
    imagemModal: {},
    imagem: {
      banner: {
        tipoArquivo: "",
        arquivo: "",
      },
    },
    id: null,
    modalAlterarBanner: false,
    vagas: [],
  }),
  methods: {
    buscarVagas() {
      const vm = this;
      vm.loader = true;
      api
        .get("/recrutamento-selecao/vagas", {
          headers: { Authorization: localStorage.token },
          withCredentials: true,
        })
        .then((response) => {
          this.vagas = response.data
            .filter((v) => v.cod_tipo_solicitacao != 303)
            .slice(0, 5);
          this.vagas.map((v) => {
            var dataAprovacao = new Date(v.dta_aprovacao);
            v.dta_aprovacao = dataAprovacao.toLocaleDateString();
          });
        })
        .catch((e) => {
          this.mensagemErro(e.message);
        })
        .finally(() => {
          vm.loader = false;
        });
    },
    buscarDetalhesVaga() {
      const vm = this;
      vm.loader = true;
      api
        .get("/recrutamento-selecao/dados-vaga/" + this.$route.params.id)
        .then((response) => {
          this.vaga = response.data;
          vm.vaga.dta_aprovacao = vm.formatDateDMY(vm.vaga.dta_aprovacao);
        })
        .catch((e) => {
          this.mensagemErro(e.message);
        })
        .finally(() => {
          vm.loader = false;
        });
    },
    formatDateDMY(date) {
      if (date == null) return "";
      var d = new Date(date),
        month = "" + (d.getMonth() + 1),
        day = "" + d.getDate(),
        year = d.getFullYear();

      if (month && month.length < 2) month = "0" + month;
      if (day && day.length < 2) day = "0" + day;

      return [day, month, year].join("/");
    },
    isLoading(e) {
      this.loader = e;
    },
    getImagem(id, pagina) {
      const vm = this;
      vm.loader = true;
      api
        .get("imagem/" + id)
        .then((response) => {
          //nome da variavel é o pagina. ex: vm.banner
          vm.imagem[pagina] = response.data;
        })
        .catch((e) => {
          vm.mensagem("Erro!", "Não foi possivel baixar imagem.");
        })
        .finally(() => {
          vm.loader = false;
        });
    },
    atualizaImagem(pagina, imagem, tipoArquivo) {
      const vm = this;
      vm.imagem[pagina].arquivo = imagem;
      vm.imagem[pagina].tipoArquivo = tipoArquivo;
      vm.modalAlterarBanner = false;
    },
    isAdmin() {
      this.admin = Utils.isAdmin();
    },
    getIdUrl() {
      const vm = this;
      const url = window.location.href;
      const lastParam = url.split("/").slice(-1)[0];
      vm.id = lastParam;
    },
  },
  mounted() {
    this.buscarDetalhesVaga();
    this.buscarVagas();
    this.getIdUrl();
    this.isAdmin();

    for (let pagina in idsVaga) {
      //ex: this.getImagem(103, 'banner');
      this.getImagem(idsVaga[pagina], pagina);
    }
  },
};
</script>
<style scoped>
.banner {
  position: relative;
}

.banner-btn {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
}

.espacamento {
  padding: 20px 40px;
}

.w-15 {
  width: 15%;
}

.divider {
  background-color: #278b97;
  margin-bottom: 20px;
  opacity: 55%;
}

.dados-vaga p {
  color: #278b97;
  display: flex;
}
.subText {
  font-size: 80%;
}

.button {
  background-color: #278b97 !important;
  color: white !important;
  font: normal normal bold 18px/22px Montserrat;
}

.status {
  width: 157px;
  height: 35px;
  border-radius: 10px;
  opacity: 1;
  padding: 8px;
  text-align: center;
  font: normal normal bold 15px/19px Montserrat;
  letter-spacing: 0.3px;
  color: #ffffff;
  font-size: 14px;
}

.status-obrigatorio {
  background: #32acb9 0% 0% no-repeat padding-box;
}

.status-opcional {
  background: #e63b25 0% 0% no-repeat padding-box;
}

/* Breadcrumb */
.breadcrumb {
  display: flex;
  margin-bottom: 50px;
}

.arrow {
  min-width: 125px;
  text-align: center;
  height: 30px;
  background: #edefee;
  position: relative;
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #414141;
  cursor: pointer;
  text-decoration: none;
}

.arrow p {
  font-size: 0.7rem;
}

.arrow::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 90%;
  transform: translateY(-50%) rotate(45deg);
  z-index: 1;
  width: 22px;
  background-color: #edefee;
  height: 22px;
  border-top: 2px solid #ffffff;
  border-right: 2px solid #ffffff;
}

.arrow:last-child {
  padding: 0 10px 0 20px;
  background: #278b97;
}

.arrow:last-child p {
  color: #ffffff;
}

.arrow:last-child:before {
  background: #278b97;
  border: none;
}

/* Breadcrumb */
</style>
