<template>
  <div class="text-center">
    <v-dialog v-model="dialog" width="auto" @click:outside="closeDialog">
      <v-card>
        <v-card-title>
          <h3>Solicitação de visita</h3>
        </v-card-title>

        <v-card-text>
          <v-container class="px-6">
            <v-form ref="form" lazy-validation>
              <v-row>
                <v-col cols="12" sm="4">
                  <v-autocomplete
                    required
                    outlined
                    dense
                    label="Contrato"
                    :items="contratos"
                    item-value="codContrato"
                    v-model="visita.codContato"
                    :rules="[c => !!c || 'Selecione o contrato']"
                    item-text="nomeNum"
                    hide-details="auto"
                  >
                  </v-autocomplete>
                </v-col>
                <v-col cols="12" sm="8">
                  <v-text-field
                    label="Tickets"
                    v-model="visita.ticket"
                    required
                    :rules="[v => !!v || 'Preencha o(s) ticket(s)']"
                    outlined
                    dense
                    hide-details="auto"
                  >
                  </v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="6" md="4">
                  <v-menu
                    v-model="dtInicioMenu"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                    id="editDataVerificacao"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="visita.dataInicio"
                        color="#278b97"
                        hide-details="auto"
                        outlined
                        :rules="[v => !!v || 'O campo Data é obrigatório.']"
                        dense
                        label="Data inicial"
                        placeholder="DD/MM/AAAA"
                        v-on="on"
                        v-bind="attrs"
                        clearable
                      >
                        <template slot="append">
                          <v-icon color="#278b97">fas fa-calendar-days</v-icon>
                        </template>
                      </v-text-field>
                    </template>
                    <v-date-picker
                      color="#278b97"
                      v-model="dtInicio"
                      required
                      @input="visita.dataInicio = formatDate(dtInicio)"
                      :rules="[v => !!v || 'O campo Data é obrigatório.']"
                      locale="pt-br"
                      no-title
                      scrollable
                      hide-details="auto"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="6" md="2">
                  <v-text-field
                    v-model="visita.horaInicio"
                    outlined
                    dense
                    label="Horário Inicio"
                    placeholder="HH:MM"
                    v-mask="'##:##'"
                    required
                    :rules="[
                      v => !!v,
                      v => /^(?:[01][0-9]|2[0-3]):[0-5][0-9]$/.test(v) || 'Digite a hora',
                    ]"
                    hide-details="auto"
                    color="#278b97"
                  ></v-text-field>
                </v-col>

                <v-col cols="6" md="4">
                  <v-menu
                    v-model="dtFimMenu"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                    id="editDataVerificacao"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="visita.dataFim"
                        color="#278b97"
                        outlined
                        dense
                        :rules="[v => !!v || 'O campo Data é obrigatório.']"
                        label="Data Final"
                        placeholder="DD/MM/AAAA"
                        v-on="on"
                        hide-details="auto"
                        v-bind="attrs"
                        clearable
                      >
                        <template slot="append">
                          <v-icon color="#278b97">fas fa-calendar-days </v-icon>
                        </template>
                      </v-text-field>
                    </template>
                    <v-date-picker
                      color="#278b97"
                      v-model="dtFim"
                      required
                      @input="visita.dataFim = formatDate(dtFim)"
                      locale="pt-br"
                      no-title
                      scrollable
                      hide-details="auto"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="6" md="2">
                  <v-text-field
                    v-model="visita.horaFim"
                    outlined
                    dense
                    label="Horário Fim"
                    placeholder="HH:MM"
                    v-mask="'##:##'"
                    required
                    :rules="[
                      v => !!v,
                      v => /^(?:[01][0-9]|2[0-3]):[0-5][0-9]$/.test(v) || 'Digite a hora',
                    ]"
                    hide-details="auto"
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="8" md="4">
                  <v-text-field
                    required
                    :rules="[v => !!v || 'Preenha a origem']"
                    v-model="visita.origem"
                    outlined
                    dense
                    label="Cidade origem"
                    hide-details="auto"
                  ></v-text-field>
                </v-col>
                <v-col cols="4" md="2">
                  <v-select
                    outlined
                    dense
                    label="UF - origem"
                    v-model="visita.ufOrigem"
                    required
                    :rules="[v => !!v || 'Preenha a UF da origem']"
                    :items="ufs"
                    item-text="uf"
                    hide-details="auto"
                  ></v-select>
                </v-col>
                <v-col cols="8" md="4">
                  <v-text-field
                    outlined
                    dense
                    label="Cidade destino"
                    required
                    :rules="[v => !!v || 'Preenha o destino']"
                    v-model="visita.destino"
                    hide-details="auto"
                  ></v-text-field>
                </v-col>
                <v-col cols="4" md="2">
                  <v-select
                    outlined
                    dense
                    label="UF - destino"
                    required
                    :rules="[v => !!v || 'Preenha a UF do destino']"
                    v-model="visita.ufDestino"
                    :items="ufs"
                    item-text="uf"
                    hide-details="auto"
                    color="#278b97"
                  ></v-select>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12">
                  <v-textarea
                    outlined
                    dense
                    label="Justificativa da visita"
                    v-model="visita.justificativa"
                    hide-details="auto"
                    rows="3"
                  />
                </v-col>
              </v-row>
            </v-form>

            <v-row class="align-center my-5">
              <h3 class="destaque-gastos">Gastos</h3>
              <v-divider></v-divider>
            </v-row>

            <v-form ref="form-despesa">
              <v-row>
                <v-col cols="6" md="4">
                  <v-select
                    :items="tiposDespesas"
                    item-text="descricao"
                    outlined
                    dense
                    required
                    item-value="idTipoDespesa"
                    v-model="despesa.idTipoDespesa"
                    label="Tipo de Gasto"
                    :rules="[v => !!v || 'Selecione o tipo de gasto']"
                    hide-details="auto"
                  ></v-select>
                </v-col>
                <v-col cols="6" md="4">
                  <v-text-field
                    label="Valor do gasto"
                    required
                    :rules="[
                      i => !!i || 'Adicione o valor do gasto',
                      i => i > 0 || 'Digite um valor válido',
                    ]"
                    outlined
                    dense
                    v-model="despesa.valorPrevisto"
                    type="number"
                    hide-details="auto"
                  />
                </v-col>
                <v-col cols="12" md="4">
                  <div class="total-estimativa">
                    <p>Total da Estimativa</p>
                    <p>{{ TotalPlanejado }}</p>
                  </div>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" md="8">
                  <v-text-field
                    label="Descrição do gasto"
                    required
                    :rules="[i => !!i || 'Adicione uma descricao do gasto']"
                    outlined
                    dense
                    v-model="despesa.descricao"
                    hide-details="auto"
                  />
                </v-col>
                <v-col cols="12" md="4">
                  <v-btn @click="pushNovaDespesa" class="botao-principal w-100">
                    <v-icon> fa-plus</v-icon>
                    Adicionar
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>

            <div
              v-for="(despesa, index) in despesasPlanejadas"
              :key="index"
              class="total-gasto my-5"
            >
              <v-row class="align-center">
                <v-col cols="6" md="3">
                  <p><span>Tipo de Gasto:</span></p>
                  <p>{{ descricaoDespesa(despesa.idTipoDespesa) }}</p>
                </v-col>
                <v-col cols="6" md="3">
                  <p><span>Valor do Gasto:</span></p>
                  <p>{{ FormatMoney(despesa.valorPrevisto) }}</p>
                </v-col>
                <v-col cols="8" md="4">
                  <p><span>Descrição do Gasto:</span></p>
                  <p>{{ despesa.descricao }}</p>
                </v-col>
                <v-col cols="4" md="2">
                  <v-icon @click="editarGasto(despesa)" color="#278b97" class="mr-5">
                    fa-pen
                  </v-icon>
                  <v-icon @click="despesasPlanejadas.splice(index, 1)" color="#d91727">
                    fa-trash
                  </v-icon>
                </v-col>
              </v-row>
            </div>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-row class="justify-center">
            <v-col cols="12" md="3">
              <v-btn @click="closeDialog" class="botao-cancelar w-100">Cancelar</v-btn>
            </v-col>
            <v-col cols="12" md="3">
              <v-btn @click="criarVisita()" class="botao-principal w-100">Enviar Solicitação</v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import ServiceVisitaTecnica from '@/services/ServiceVisitaTecnica';
import serviceVisitaTecnica from '@/services/ServiceVisitaTecnica';
import ModalMixin from '@/mixins/modalMixin';
import { formatDate, formatMoney, UnidadesFederativas } from '@/utils/Utils';

export default {
  name: 'PlanejamentoVisita',
  mixins: [ModalMixin],
  data() {
    return {
      dialog: false,
      menuInicio: false,
      dtInicio: '',
      dtInicioMenu: null,
      dtFim: '',
      dtFimMenu: null,
      visita: {},
      contratos: [],
      despesa: {},
      despesasPlanejadas: [],
      tiposDespesas: [],
      ufs: UnidadesFederativas,
    };
  },
  methods: {
    formatDate: formatDate,
    editarGasto(despesa) {
      let vm = this;
      vm.despesasPlanejadas.splice(vm.despesasPlanejadas.indexOf(despesa), 1);
      vm.despesa = despesa;
    },
    FormatMoney: formatMoney,
    descricaoDespesa(idTipoDespesa) {
      const despesa = this.tiposDespesas.find(d => d.idTipoDespesa == idTipoDespesa);
      return despesa?.descricao;
    },
    pushNovaDespesa() {
      let vm = this;
      if (vm.$refs['form-despesa'].validate()) {
        vm.despesasPlanejadas.push(vm.despesa);
        vm.despesa = {};
        vm.$refs['form-despesa'].reset();
      }
    },
    buscarContratos() {
      ServiceVisitaTecnica.listarContratosAtivos()
        .then(res => (this.contratos = res.data.map(this.formatContratos).sort()))
        .catch(err => this.mensagemErro(err));
    },
    buscarTipoDespesas() {
      let vm = this;
      serviceVisitaTecnica
        .tiposDespesas()
        .then(res => (vm.tiposDespesas = res.data))
        .catch(vm.mensagemErro);
    },
    formatContratos: c => {
      c.nomeNum = `${c.nomCliente} - ${c.numContrato} `;
      return c;
    },
    montarFormulario() {
      return {
        codContrato: this.visita.codContato,
        ticket: this.visita.ticket
          .split(' ' || ',')
          .join(', ')
          .toString(),
        justificativaVisita: this.visita.justificativa,
        enderecoUfOrigem: this.visita.ufOrigem,
        enderecoCidadeOrigem: this.visita.origem,
        dthIda: this.formatToISO(this.visita.dataInicio, this.visita.horaInicio),
        dthVolta: this.formatToISO(this.visita.dataFim, this.visita.horaFim),
        enderecoUfDestino: this.visita.ufDestino,
        enderecoCidadeDestino: this.visita.destino,
        listaDespesas: this.despesasPlanejadas,
      };
    },
    formatToISO(date, time) {
      const [day, month, year] = date.split('/');
      const [hours, minutes] = time.split(':');
      return `${year}-${month}-${day}T${hours}:${minutes}:00.000Z`;
    },
    criarVisita() {
      let vm = this;
      if (vm.$refs.form.validate() && vm.despesasPlanejadas.length > 0) {
        vm.$emit('loader', true);
        ServiceVisitaTecnica.criarVisitaPlanejada(vm.montarFormulario())
          .then(() => {
            vm.$emit('created');
            vm.closeDialog();
          })
          .catch(err => vm.$emit('erro', err))
          .finally(() => vm.$emit('loader', false));
      } else if (vm.despesasPlanejadas.length > 0) {
        vm.$emit('alert', 'Cadastre as despesas');
      }
    },
    closeDialog() {
      this.dialog = false;
      this.despesasPlanejadas = [];
      this.visita = {};
    },
  },
  computed: {
    TotalPlanejado() {
      let vm = this;
      return formatMoney(
        vm.despesasPlanejadas
          .map(d => d.valorPrevisto)
          .reduce((x, y) => new Number(x) + new Number(y), 0)
      );
    },
  },
  created() {
    let vm = this;
    vm.buscarTipoDespesas();
    vm.buscarContratos();
  },
};
</script>

<style scoped>
.destaque-gastos {
  color: #278b97;
  font-weight: 700;
  margin-right: 1rem;
}

.total-estimativa {
  background-color: #ddd;
  border-radius: 0.75rem;
  padding: 0.25rem;
}

.total-estimativa p {
  font-size: 0.75rem;
  line-height: 1rem;
}

.total-gasto {
  background-color: #ddd;
  border-radius: 0.75rem;
  padding: 1.5rem;
}

.total-gasto p {
  text-align: left !important;
}

.total-gasto p span {
  color: #525252;
  font-size: 0.8rem;
}

.total-gasto .col {
  position: relative;
}

.total-gasto .col::after {
  content: '';
  position: absolute;
  border: 1px solid;
  height: 60%;
  right: 0;
  top: 14px;
  color: #8080804a;
}

.total-gasto .col:last-child::after {
  display: none;
}
</style>
