<template>
  <div>
    <loader :loader-primario="loader"></loader>

    <alerta v-on:fecharModal="fecharModal" :exibir="modal.exibir" :titulo="modal.titulo" :mensagem="modal.mensagem">
      maxlength="10"</alerta>

    <v-container>
      <div class="card">
        <div class="inicio-titulo">
          <v-img class="icone-titulo" src="@/assets/images/icone/espaco-colaborador/icone_metricas.svg"></v-img>
          <h1 class="titulo">Métricas de uso</h1>
        </div>

        <v-tabs v-model="tab" grow class="my-2" hide-slider show-arrows>
          <v-tabs-slider></v-tabs-slider>
          <v-tab href="#tab-0" :class="tab == 'tab-0' ? 'tab tab-active' : 'tab'">Dashboard</v-tab>
          <v-tab href="#tab-1" :class="tab == 'tab-1' ? 'tab tab-active' : 'tab'">Histórico</v-tab>
        </v-tabs>

        <!-- Tabs -->
        <v-tabs-items v-model="tab">
          <!-- Dashboard -->
          <v-tab-item value="tab-0">
            <v-container>
              <!-- Filtros Data -->
              <v-row>
                <v-col cols="12" md="6">
                  <v-menu v-model="filtroData1" :close-on-content-click="false" transition="scale-transition" offset-y
                    min-width="auto" id="editDataVerificacao">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field maxlength="10" v-model="dataInicial" @input="pagina = [1]" class="input-field"
                        outlined dense placeholder="DD/MM/AAAA" v-on="on" hide-details="auto" v-bind="attrs" clearable>
                        <template slot="label">
                          <span class="input-field-text">Data Inicial</span>
                        </template>
                        <template slot="append">
                          <v-icon class="input-field-text">fas fa-calendar-days</v-icon>
                        </template>
                      </v-text-field>
                    </template>
                    <v-date-picker v-model="filtroDataInicial" required
                      @input="dataInicial = formatDate(filtroDataInicial)"
                      :rules="[(v) => !!v || 'O campo Data é obrigatório.']" locale="pt-br" no-title
                      scrollable></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12" md="6">
                  <v-menu v-model="filtroData2" @input="pagina = [1]" :close-on-content-click="false" clearable
                    transition="scale-transition" offset-y min-width="auto" id="editDataVerificacao">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field maxlength="10" v-model="dataFinal" class="input-field" outlined dense
                        placeholder="DD/MM/AAAA" v-on="on" hide-details="auto" v-bind="attrs" clearable>
                        <template slot="label">
                          <span class="input-field-text">Data Final</span>
                        </template>
                        <template slot="append">
                          <v-icon class="input-field-text">fas fa-calendar-days</v-icon>
                        </template>
                      </v-text-field>
                    </template>
                    <v-date-picker color="#278b97" v-model="filtroDataFinal1" required
                      :rules="[(v) => !!v || 'O campo Data é obrigatório.']" locale="pt-br" no-title scrollable
                      @input="dataFinal = formatDate(filtroDataFinal1)"></v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>
              <!-- Métricas: 4 primeiros cards -->
              <v-row class="row-metricas">
                <v-col cols="12" md="6" xl="3">
                  <div :class="$vuetify.breakpoint.xl ? 'card-interno-xl' : 'card-interno-padrao'">
                    <div class="texto-metricas">
                      <h2>{{ totais.total }}</h2>
                      <h4>
                        <span>Acessos</span><br />
                        totais
                      </h4>
                    </div>
                    <div v-if="$vuetify.breakpoint.xl" class="imagem-metricas">
                      <v-img src="@/assets/images/icone/icone_metrica_card.png"></v-img>
                    </div>
                  </div>
                </v-col>

                <v-col cols="12" md="6" xl="3">
                  <div :class="$vuetify.breakpoint.xl ? 'card-interno-xl' : 'card-interno-padrao'">
                    <div class="texto-metricas">
                      <h2>{{ Math.floor(totais.media) }}</h2>
                      <div class="texto-metricas">
                        <h4>
                          <span>Acessos em</span><br />
                          média por Dia
                        </h4>
                      </div>
                    </div>
                    <div v-if="$vuetify.breakpoint.xl" class="imagem-metricas">
                      <v-img src="@/assets/images/icone/icone_metrica_card2.png"></v-img>
                    </div>
                  </div>
                </v-col>

                <v-col cols="12" md="6" xl="3">
                  <div :class="$vuetify.breakpoint.xl ? 'card-interno-xl' : 'card-interno-padrao'
                    ">
                    <div class="texto-metricas">
                      <h4>
                        <span>Grupo que mais acessou</span><br />
                        {{ totais.lotacao }}
                      </h4>
                    </div>
                    <div v-if="$vuetify.breakpoint.xl" class="imagem-metricas">
                      <v-img src="@/assets/images/icone/icone_metrica_card3.png"></v-img>
                    </div>
                  </div>
                </v-col>

                <v-col cols="12" md="6" xl="3">
                  <div :class="$vuetify.breakpoint.xl ? 'card-interno-xl' : 'card-interno-padrao'">
                    <div class="texto-metricas">
                      <h4>
                        <span>Página mais acessada</span><br />
                        {{ totais.pagina }}
                      </h4>
                    </div>
                    <div v-if="$vuetify.breakpoint.xl" class="imagem-metricas">
                      <v-img src="@/assets/images/icone/icone_metrica_card.png"></v-img>
                    </div>
                  </div>
                </v-col>
              </v-row>

              <!-- Charts -->
              <v-row>
                <v-col cols="12" lg="6">
                  <div class="card-interno-grafico">
                    <p>Número de</p>
                    <h4>acessos por mês</h4>
                    <v-divider class="divider-tela"></v-divider>
                    <div>
                      <LineChart v-if="visible" :height="200" :chartData="dataLine" class="line-chart" />
                    </div>
                  </div>
                </v-col>
                <v-col cols="12" lg="6">
                  <div class="card-interno-grafico">
                    <p>Número de</p>
                    <h4>acessos por grupo</h4>
                    <v-divider class="divider-tela"></v-divider>
                    <div>
                      <PieChart v-if="visiblePie" :height="300" :chartData="dataPie" />
                    </div>
                  </div>
                </v-col>
              </v-row>

              <!-- Tabelas -->
              <v-row>
                <v-col>
                  <div class="card-interno-table">
                    <p>Número de</p>
                    <h4>acessos por página</h4>

                    <v-divider insert class="divider-tela"></v-divider>

                    <v-autocomplete class="input-field mb-4" clearable chips outlined dense hide-details="auto"
                      height="40px" v-model="paginasSelecionadasPagina" :items="paginas" item-text="nomePagina"
                      item-value="id" multiple>
                      <template slot="label">
                        <span class="input-field-text">Página:</span>
                      </template>
                      <template slot="append">
                        <v-icon class="input-field-text">fas fa-chevron-down</v-icon>
                      </template>
                    </v-autocomplete>

                    <v-data-table :headers="porPagina" :items="numeroAcessoPagina.listObjects" item-text="nome"
                      hide-default-footer item-value="valor" :items-per-page="itens" :no-data-text="tabelaVazia()">
                    </v-data-table>
                    <v-row class="row-paginacao">
                      <v-col cols="5">
                        <v-pagination prev-icon=" fa-light fa-chevron-left" next-icon="fa-light fa-chevron-right"
                          v-model="paginaList[0]" :length="Math.ceil(totalPagina / itens)"
                          @input="buscarNumerosDeAcessosPorPagina()" circle dense solo></v-pagination>
                      </v-col>
                    </v-row>
                  </div>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <div class="card-interno-table">
                    <p>Páginas mais</p>
                    <h4>acessadas por grupo</h4>

                    <v-divider class="divider-tela"></v-divider>

                    <div class="center-space-between">
                      <v-row>
                        <v-col cols="6">
                          <v-autocomplete class="input-field mb-4" clearable chips outlined dense hide-details="auto"
                            height="40px" v-model="paginasSelecionadasGrupo" :items="paginas" item-text="nomePagina"
                            item-value="id" multiple>
                            <template slot="label">
                              <span class="input-field-text">Página:</span>
                            </template>
                            <template slot="append">
                              <v-icon class="input-field-text">fas fa-chevron-down</v-icon>
                            </template>
                          </v-autocomplete>
                        </v-col>

                        <v-col cols="6">
                          <v-autocomplete class="input-field" clearable chips outlined dense hide-details="auto"
                            height="40px" v-model="lotacoesSelecionadasGrupo" :items="lotacoes" item-text="nomeLotacao"
                            item-value="idLotacao" multiple>
                            <template slot="label">
                              <span class="input-field-text">Grupo:</span>
                            </template>
                            <template slot="append">
                              <v-icon class="input-field-text">fas fa-chevron-down</v-icon>
                            </template>
                          </v-autocomplete>
                        </v-col>
                      </v-row>
                    </div>

                    <v-data-table :headers="porGrupo" :items="numeroAcessoGrupo.listObjects" item-text="nome"
                      hide-default-footer item-value="valor" :items-per-page="itens"
                      :no-data-text="tabelaVazia()"></v-data-table>
                    <v-row class="row-paginacao">
                      <v-col cols="5">
                        <v-pagination prev-icon=" fa-light fa-chevron-left" next-icon="fa-light fa-chevron-right"
                          v-model="paginaGrupo[0]" :length="Math.ceil(totalGrupo / itens)"
                          @input="buscarAcessosPaginasPorgrupo()" circle dense solo></v-pagination>
                      </v-col>
                    </v-row>
                  </div>
                </v-col>
              </v-row>
            </v-container>
          </v-tab-item>

          <!-- Histórico -->
          <v-tab-item value="tab-1">
            <v-container>
              <!-- Filtros Data -->
              <v-row>
                <v-col cols="12" md="6">
                  <v-menu v-model="filtroDataMetricas" :close-on-content-click="false" transition="scale-transition"
                    offset-y min-width="auto" id="editDataVerificacao">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field maxlength="10" v-model="dataHistoricoInicial" @input="pagina = [1]"
                        class="input-field" outlined dense placeholder="DD/MM/AAAA" v-on="on" hide-details="auto"
                        clearable v-bind="attrs">
                        <template slot="label">
                          <span class="input-field-text">Data Inicial</span>
                        </template>
                        <template slot="append">
                          <v-icon class="input-field-text">fas fa-calendar-days</v-icon>
                        </template>
                      </v-text-field>
                    </template>
                    <v-date-picker color="#278b97" v-model="filtroDataMetricasInicial" required @input="
                      dataHistoricoInicial = formatDate(filtroDataMetricasInicial)"
                      :rules="[(v) => !!v || 'O campo Data é obrigatório.']" locale="pt-br" no-title
                      scrollable></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12" md="6">
                  <v-menu v-model="filtroDataMetricas2" @input="pagina = [1]" :close-on-content-click="false"
                    transition="scale-transition" offset-y min-width="auto" id="editDataVerificacao">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field maxlength="10" v-model="dataHistoricoFinal" color="#278b97" class="input-field"
                        outlined dense placeholder="DD/MM/AAAA" v-on="on" hide-details="auto" v-bind="attrs" clearable>
                        <template slot="label">
                          <span class="input-field-text">Data Final</span>
                        </template>
                        <template slot="append">
                          <v-icon class="input-field-text">fas fa-calendar-days</v-icon>
                        </template>
                      </v-text-field>
                    </template>
                    <v-date-picker color="#278b97" v-model="filtroDataMetricasFinal" required
                      :rules="[(v) => !!v || 'O campo Data é obrigatório.']" locale="pt-br" no-title scrollable
                      @input="dataHistoricoFinal = formatDate(filtroDataMetricasFinal)"></v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>
              <!-- Filtros Página / Grupo -->
              <v-row>
                <v-col cols="12" md="6">
                  <v-autocomplete class="input-field" clearable chips outlined dense hide-details="auto" height="40px"
                    v-model="paginasSelecionadas" :items="paginas" item-text="nomePagina" item-value="id" multiple>
                    <template slot="label">
                      <span class="input-field-text">Página: </span>
                    </template>
                    <template slot="append">
                      <v-icon class="input-field-text">fas fa-chevron-down</v-icon>
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col cols="12" md="6">
                  <v-autocomplete class="input-field" clearable chips outlined dense hide-details="auto" height="40px"
                    v-model="lotacoesSelecionadas" :items="lotacoes" item-text="nomeLotacao" item-value="idLotacao"
                    multiple>
                    <template slot="label">
                      <span class="input-field-text">Grupo: </span>
                    </template>
                    <template slot="append">
                      <v-icon class="input-field-text">fas fa-chevron-down</v-icon>
                    </template>
                  </v-autocomplete>
                </v-col>
              </v-row>
              <!-- Filtro Pessoa / Botão Limpar -->
              <v-row>
                <v-col cols="12" md="9">
                  <v-text-field class="input-field" v-model="filtroPessoa" clearable dense outlined hide-details="auto">
                    <template slot="label">
                      <span class="input-field-text">Pessoa </span>
                    </template>
                  </v-text-field>
                </v-col>
                <v-col cols="12" md="3">
                  <v-btn class="botao-limpar" color="#F8BA00" @click="limpar()">Limpar Buscas</v-btn>
                </v-col>
              </v-row>

              <!-- Tabela -->
              <v-row>
                <v-col cols="12">
                  <v-data-table :headers="headerHistorico" :items="historico.listObjects" item-text="nome"
                    hide-default-footer item-value="valor" :items-per-page="itensHistorico"
                    :no-data-text="tabelaVazia()"></v-data-table>
                </v-col>
              </v-row>
              <!-- Paginação -->
              <v-row class="row-paginacao">
                <v-col cols="5">
                  <v-pagination prev-icon=" fa-light fa-chevron-left" next-icon="fa-light fa-chevron-right"
                    v-model="pagina[0]" :length="Math.ceil(total / itensHistorico)" @input="buscarHistorico()" circle
                    dense solo></v-pagination>
                </v-col>
              </v-row>
            </v-container>
          </v-tab-item>
        </v-tabs-items>
      </div>
    </v-container>
  </div>
</template>

<script>
import loader from "@/components/Loader.vue";
import alerta from "@/components/Alerta.vue";
import api from "@/services/Api.js";
import modalMixin from "@/mixins/modalMixin";
import LineChart from "@/components/Graficos/Line.vue";
import PieChart from "@/components/Graficos/Pie.vue";
import { formatDateAutomatic } from "@/utils/Utils";

export default {
  components: { loader, alerta, LineChart, PieChart },
  mixins: [modalMixin],
  name: "metricas",
  data() {
    return {
      tab: "tab-0",
      loader: false,
      visible: false,
      visiblePie: false,
      dataLine: {
        labels: [],
        datasets: [
          {
            label: ["Acessos"],
            data: [],
            backgroundColor: "#278b97",
            fill: false,
            tension: 0.1,
          },
        ],
      },
      dataPie: {
        labels: [],
        datasets: [
          {
            backgroundColor: [
              "#397780",
              "#417F88",
              "#278997",
              "#24B6CA",
              "#12B2C8",
              "#46588D",
              "#4F6195",
              "#3753A6",
              "#365ED3",
              "#2550D1",
              "#D1AB5A",
              "#DDB868",
              "#F7BC3C",
              "#FFBC2A",
              "#FFB413",
              "#D1935A",
              "#DDA068",
              "#F7963C",
              "#FF902A",
              "#FF8413",
            ],
            data: [],
            label: ["Acessos"],
          },
        ],
      },
      porGrupo: [
        { text: "Grupo", value: "lotação", width: "35%" },
        { text: "Pagina", value: "pagina", width: "35%" },
        { text: "Nrº de acesso", value: "totalAcessos", width: "30%" },
      ],
      porPagina: [
        { text: "Pagina", value: "pagina", width: "50%" },
        { text: "Nrº de acesso", value: "numeroAcessos", width: "50%" },
      ],
      headerHistorico: [
        { text: "Pessoa", value: "nomePessoa", width: "35%" },
        { text: "Grupo", value: "lotacao", width: "35%" },
        { text: "Pagina", value: "pagina", width: "15%" },
        { text: "Data Acesso", value: "dtRegistro", width: "15%" },
      ],
      height: 250,
      allMeses: [
        "Janeiro",
        "Fevereiro",
        "Março",
        "Abril",
        "Maio",
        "Junho",
        "Julho",
        "Agosto",
        "Setembro",
        "Outubro",
        "Novembro",
        "Dezembro",
      ],
      plugins: {
        legend: {
          position: "top",
        },
        title: {
          display: true,
          text: "Chart.js Pie Chart",
        },
      },
      pagina: [1],
      paginaGrupo: [1],
      paginaList: [1],
      itens: 5,
      itensHistorico: 8,
      line: null,
      pie: null,
      paginas: [],
      lotacoes: [],
      total: 0,
      totalPagina: 0,
      totalGrupo: 0,
      totais: {},
      paginasSelecionadas: [],
      lotacoesSelecionadas: [],
      paginasSelecionadasGrupo: [],
      lotacoesSelecionadasGrupo: [],
      paginasSelecionadasPagina: [],
      lotacoesSelecionadasPagina: [],
      paginasSelecionadasAcesso: [],
      historico: {},
      filtroPessoa: null,

      dataInicial: null,
      filtroData1: null,
      filtroDataInicial: null,

      dataFinal: null,
      filtroData2: null,
      filtroDataFinal1: null,

      dataHistoricoInicial: null,
      filtroDataMetricas: null,
      filtroDataMetricasInicial: null,

      dataHistoricoFinal: null,
      filtroDataMetricas2: null,
      filtroDataMetricasFinal: null,

      numeroAcessoPagina: {},
      numeroAcessoGrupo: {},
    };
  },
  watch: {
    dataHistoricoInicial(e) {
      if (this.validarDataHistorico(e)) {
        this.buscarHistorico();
      }
      this.dataHistoricoInicial = this.formatDateAutomatic(e);
    },
    dataHistoricoFinal(e) {
      if (this.validarDataHistorico(e)) {
        this.buscarHistorico();
      }
      this.dataHistoricoFinal = this.formatDateAutomatic(e);
    },
    lotacoesSelecionadas() {
      this.buscarHistorico();
    },
    paginasSelecionadas() {
      this.buscarHistorico();
    },
    filtroPessoa() {
      this.buscarHistorico();
    },
    paginasSelecionadasPagina() {
      this.buscarNumerosDeAcessosPorPagina();
    },
    paginasSelecionadasGrupo() {
      this.buscarAcessosPaginasPorgrupo();
    },
    lotacoesSelecionadasGrupo() {
      this.buscarAcessosPaginasPorgrupo();
    },
    dataInicial(e) {
      if (this.validarData(e)) {
        this.buscarTotais();
        this.buscarDadosLine();
        this.buscarDadosPie();
        this.buscarAcessosPaginasPorgrupo();
        this.buscarNumerosDeAcessosPorPagina();
      }
      this.dataInicial = this.formatDateAutomatic(e);
    },
    dataFinal(e) {
      if (this.validarData(e)) {
        this.buscarTotais();
        this.buscarDadosLine();
        this.buscarDadosPie();
        this.buscarAcessosPaginasPorgrupo();
        this.buscarNumerosDeAcessosPorPagina();
      }
      this.dataFinal = this.formatDateAutomatic(e);
    },
  },
  methods: {
    meses(config) {
      var cfg = config || {};
      var count = cfg.count || 12;
      var section = cfg.section;
      var values = [];
      var i, value;

      for (i = 0; i < count; ++i) {
        value = this.allMeses[Math.ceil(i) % 12];
        values.push(value.substring(0, section));
      }

      return values;
    },
    validarDataHistorico(data) {
      const vm = this;

      if (!data) {
        return false;
      }

      if (vm.dataHistoricoInicial && vm.dataHistoricoFinal && vm.dataHistoricoFinal.length == 10) {
        let dataInicial = new Date(
          vm.revertFormatDate(vm.dataHistoricoInicial)
        );
        let dataFinal = new Date(vm.revertFormatDate(vm.dataHistoricoFinal));

        if ((dataInicial > dataFinal)) {
          vm.mensagem(
            "Alerta!",
            "Data inicial tem que ser maior que a data final!"
          );
          vm.dataHistoricoInicial = null;
          vm.dataHistoricoFinal = null;
          return false;
        }
      }

      return vm.verificaData(data);
    },
    validarData(data) {
      const vm = this;

      if (!data) {
        return false;
      }
      //Se nenhuma das datas for nula
      if (vm.dataInicial && vm.dataFinal && vm.dataFinal.length == 10) {
        let dataInicial = new Date(vm.revertFormatDate(vm.dataInicial));
        let dataFinal = new Date(vm.revertFormatDate(vm.dataFinal));

        if ((dataInicial > dataFinal)) {
          vm.mensagem(
            "Alerta!",
            "Data inicial tem que ser maior que a data final!"
          );
          vm.dataInicial = null;
          vm.dataFinal = null;
          return false;
        }
      }

      return vm.verificaData(data);
    },
    verificaData(data) {
      let partes = data.toString().split("/");
      let dia = parseInt(partes[0]);
      let mes = parseInt(partes[1]) - 1;
      let ano = parseInt(partes[2]);

      let datafinal = new Date(ano, mes, dia);

      return (
        datafinal.getFullYear() === ano &&
        datafinal.getMonth() === mes &&
        datafinal.getDate() === dia &&
        ano.toString().length === 4
      );
    },
    buscarLotacoes() {
      const vm = this;
      api
        .get("metricasRegistro/lotacoes")
        .then((response) => {
          vm.lotacoes = response.data;
        })
        .catch((e) => {
          this.mensagemErro(e.message);
        });
    },
    buscarPaginas() {
      const vm = this;
      api
        .get("metricasRegistro/paginas")
        .then((response) => {
          vm.paginas = response.data;
        })
        .catch((e) => {
          this.mensagemErro(e.message);
        });
    },
    buscarHistorico() {
      const vm = this;
      api
        .get("metricasRegistro/historico", {
          params: {
            nomePessoa: vm.filtroPessoa,
            listPaginas: vm.paginasSelecionadas,
            dataInicial: vm.revertFormatDate(vm.dataHistoricoInicial),
            dataFinal: vm.revertFormatDate(vm.dataHistoricoFinal),
            idLotacao: vm.lotacoesSelecionadas,
            page: vm.pagina[0],
            itens: vm.itensHistorico,
          },
          paramsSerializer: { indexes: null },
        })
        .then((response) => {
          vm.historico = response.data;
          vm.total = vm.historico.itens;
        })
        .catch((e) => {
          this.mensagemErro(e.message);
        });
    },
    buscarTotais() {
      const vm = this;
      api
        .get("metricasRegistro/totais", {
          params: {
            dataInicial: vm.revertFormatDate(vm.dataInicial),
            dataFinal: vm.revertFormatDate(vm.dataFinal),
          },
        })
        .then((response) => {
          vm.totais = response.data;
        })
        .catch((e) => {
          this.mensagemErro(e.message);
        });
    },
    buscarDadosLine() {
      const vm = this;
      let array = [];
      let valor = [];

      vm.visible = false;
      api
        .get("metricasRegistro/line", {
          params: {
            dataInicial: vm.revertFormatDate(vm.dataInicial),
            dataFinal: vm.revertFormatDate(vm.dataFinal),
          },
        })
        .then((response) => {
          vm.line = response.data;
          response.data.forEach((element) => {
            array.push(element.mesAno);
          });

          response.data.forEach((element) => {
            valor.push(element.totalAcesso);
          });

          vm.dataLine.datasets[0].data = valor;
          vm.dataLine.labels = array;
          vm.visible = true;
        })
        .catch((e) => {
          this.mensagemErro(e.message);
        });
    },
    buscarDadosPie() {
      const vm = this;
      let array = [];
      let valor = [];

      vm.visiblePie = false;
      api
        .get("metricasRegistro/grupo", {
          params: {
            dataInicial: vm.revertFormatDate(vm.dataInicial),
            dataFinal: vm.revertFormatDate(vm.dataFinal),
          },
        })
        .then((response) => {
          vm.pie = response.data;
          response.data.forEach((element) => {
            array.push(element.lotacao);
          });

          response.data.forEach((element) => {
            valor.push(element.totalAcesso);
          });

          vm.dataPie.datasets[0].data = valor;
          vm.dataPie.labels = array;
          vm.visiblePie = true;
        })
        .catch((e) => {
          this.mensagemErro(e.message);
        });
    },
    buscarAcessosPaginasPorgrupo() {
      const vm = this;
      api
        .get("metricasRegistro/grupo/lista", {
          params: {
            listPaginas: vm.paginasSelecionadasGrupo,
            listLotacao: vm.lotacoesSelecionadasGrupo,
            dataInicial: vm.revertFormatDate(vm.dataInicial),
            dataFinal: vm.revertFormatDate(vm.dataFinal),
            page: vm.paginaGrupo[0],
            itens: vm.itens,
          },
          paramsSerializer: { indexes: null },
        })
        .then((response) => {
          vm.numeroAcessoGrupo = response.data;
          vm.totalGrupo = vm.numeroAcessoGrupo.itens;
        })
        .catch((e) => {
          this.mensagemErro(e.message);
        });
    },
    buscarNumerosDeAcessosPorPagina() {
      const vm = this;
      api
        .get("metricasRegistro/paginas/lista", {
          params: {
            listPaginas: vm.paginasSelecionadasPagina,
            listLotacao: vm.lotacoesSelecionadasPagina,
            dataInicial: vm.revertFormatDate(vm.dataInicial),
            dataFinal: vm.revertFormatDate(vm.dataFinal),
            page: vm.paginaList[0],
            itens: vm.itens,
          },
          paramsSerializer: { indexes: null },
        })
        .then((response) => {
          vm.numeroAcessoPagina = response.data;
          vm.totalPagina = vm.numeroAcessoPagina.itens;
        })
        .catch((e) => {
          this.mensagemErro(e.message);
        });
    },
    limpar() {
      const vm = this;
      vm.filtroPessoa = null;
      vm.paginasSelecionadas = [];
      vm.lotacoesSelecionadas = [];
      vm.pagina = [1];
      vm.dataHistoricoInicial = null;
      vm.dataHistoricoFinal = null;
    },
    tabelaVazia() {
      return "Nenhuma métrica encontrada!";
    },
    formatDate(e) {
      if (!e) return null;
      const [year, month, day] = e.split("-");
      return `${day}/${month}/${year}`;
    },
    revertFormatDate(date) {
      if (!date) {
        return null;
      }
      const [day, month, year] = date.split("/");
      return `${year}-${month}-${day}`;
    },
    formatDateAutomatic: formatDateAutomatic,
  },
  async mounted() {
    const vm = this;

    await vm.buscarDadosLine();
    await vm.buscarDadosPie();
    await vm.buscarTotais();

    await vm.buscarLotacoes();
    await vm.buscarPaginas();
    await vm.buscarAcessosPaginasPorgrupo();
    await vm.buscarNumerosDeAcessosPorPagina();
    await vm.buscarHistorico();
  },
};
</script>

<style scoped>
/* Tabs */
.card::v-deep .v-tabs-bar {
  height: auto;
}

.tab {
  background-color: #edefee;
  border: 1px solid #edefee;
  border-radius: 12px;
  text-align: center;
  text-transform: none;
  letter-spacing: 0px;
  font: normal normal bold 16px/20px Montserrat;
  color: #787878 !important;
  margin: 0 1rem;
  padding: 1rem;
  width: 8rem;
}

.tab::before {
  border-radius: 12px;
}

.tab:hover {
  background-color: #eff9fa;
  border: 1px solid #278b97;
  color: #278b97 !important;
}

.tab-active {
  background-color: #278b97;
  border: 1px solid #278b97;
  color: #FFF !important;
}

/* -------------------------------------------------------------------------- */
/* Métricas */
.row-metricas .col {
  display: flex;
  text-align: center;
  justify-content: space-between;
}

.card-interno-xl {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 1rem;
  box-shadow: 0px 0px 16px 4px #7d7a7a26;
  padding: 1.5rem;
  height: 8rem !important;
}

@media (max-width: 1904px) {
  .card-interno-xl {
    padding: 1rem;
    height: 14rem !important;
  }
}

.card-interno-padrao {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 1rem;
  box-shadow: 0px 0px 16px 4px #7d7a7a26;
  padding: 1rem;
  height: 7rem !important;
}

.texto-metricas {
  display: flex;
  align-items: center;
}

.texto-metricas h2 {
  font-size: 3rem;
  font-weight: 900;
  margin-right: .5rem;
}

.texto-metricas h4 {
  font-size: 1.125rem;
  font-weight: 900;
  line-height: 1.25;
  color: #2d2d2d;
}

.texto-metricas h4 span {
  font-weight: 600;
  color: #787878;
  text-transform: initial;
  font-size: 1rem;
}

.imagem-metricas {
  background: #00838f;
  border-radius: 50%;
  height: 5rem;
  width: 5rem;
  padding: 1rem;
}

/* -------------------------------------------------------------------------- */
/* Charts */
.card-interno-grafico {
  border-radius: 1rem;
  box-shadow: 0px 0px 16px 4px #7d7a7a26;
  padding: 2rem;
  height: 30rem !important;
}

@media screen and (max-width: 600px) {
  .line-chart {
    width: 100%;
    height: auto;
  }
}

/* -------------------------------------------------------------------------- */
/* Tabela */
.card-interno-table {
  border-radius: 1rem;
  box-shadow: 0px 0px 16px 4px #7d7a7a26;
  padding: 2rem;
  height: 44rem !important;
}

@media screen and (max-width: 600px) {
  .card-interno-table {
    height: auto !important;
  }
}

.row-paginacao {
  margin: 1rem 0 0;
  justify-content: center;
}

/* -------------------------------------------------------------------------- */
/* Geral */
.divider-tela {
  margin: 1rem 0;
  border-color: #278b97 !important;
}

.center-space-between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>
