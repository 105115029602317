<template>
  <v-container>
    <!-- Título -->
    <v-row>
      <v-col class="titulo-e-divider">
        <h1>Titular</h1>
        <v-divider></v-divider>
      </v-col>
    </v-row>
    <!-- Botões -->
    <v-row>
      <v-col cols="12" md="6" lg="4" xl="3" offset-lg="8" offset-xl="9">
        <v-btn
          @click="showMinhasSolicitacoes = true"
          class="botao-principal w-100"
        >
          <v-img
            class="icone-solicitacoes"
            src="@/assets/images/icone/espaco-colaborador/icone_document.svg"
          ></v-img>

          Minhas Solicitações
        </v-btn>
      </v-col>
    </v-row>
    <!-- Tabela -->
    <v-row>
      <v-col>
        <v-data-table
          :headers="header"
          :items="beneficio"
          :items-per-page="5"
          :hide-default-footer="true"
          no-data-text="Você não possui vale alimentação/refeição cadastrado!"
        >
          <template
            v-for="header in header.filter((header) =>
              header.hasOwnProperty('formatter')
            )"
            v-slot:[`item.${header.value}`]="{ value }"
          >
            {{ header.formatter(value) }}
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <ModalMinhasSolicitacoes v-model="showMinhasSolicitacoes" />

    <ModalTrocarVale
      v-model="modalTrocarVale.show"
      :titulo="modalTrocarVale.titulo"
      :beneficio="modalTrocarVale.beneficio"
      @sucesso="
        mensagemSucesso(
          'Pedido realizado com sucesso. Aguarde o atendimento da solicitação.'
        )
      "
    />

    <loader :loader-primario="loader" />

    <alerta
      v-on:fecharModal="fecharModal"
      :exibir="modal.exibir"
      :titulo="modal.titulo"
      :mensagem="modal.mensagem"
    />
  </v-container>
</template>

<script>
import ModalMinhasSolicitacoes from "@/components/EspacoDoColaborador/Beneficios/modais/ModalMinhasSolicitacoes.vue";
import ModalTrocarVale from "@/components/EspacoDoColaborador/Beneficios/modais/ModalTrocarVale.vue";
import loader from "@/components/Loader.vue";
import api from "@/services/Api.js";
import alerta from "@/components/Alerta.vue";
import modalMixin from "@/mixins/modalMixin";

export default {
  mixins: [modalMixin],
  components: {
    ModalMinhasSolicitacoes,
    ModalTrocarVale,
    alerta,
    loader,
  },
  props: {
    header: Array,
    beneficio: Array,
  },
  data() {
    return {
      showMinhasSolicitacoes: false,
      periodo: null,
      loader: false,
      paths: {
        periodo: `beneficios/periodo-alimentacao`,
      },
      modalTrocarVale: {
        show: false,
        beneficio: null,
        titulo: "",
      },
    };
  },
  methods: {
    async buscarPeriodoAlimentacao() {
      const vm = this;
      vm.loader = true;
      await api
        .get(vm.paths.periodo)
        .then(async (res) => await (vm.periodo = res.data))
        .catch((e) => vm.mensagemErro(e.message))
        .finally(() => (vm.loader = false));
    },
    async abrirModalTrocarVale(item) {
      const vm = this;
      const diaHoje = await new Date().getDate();
      await vm.buscarPeriodoAlimentacao();
      if (diaHoje >= vm.periodo.diaInicial && diaHoje <= vm.periodo.diaFinal) {
        vm.modalTrocarVale = {
          titulo: `Alterar ${item.item.descricao}`,
          beneficio: item.item,
          show: true,
        };
      } else {
        await vm.mensagem(
          "ATENÇÃO!",
          `Solicitações para vale alimentação/refeição só podem ser realizadas entre os dias ${vm.periodo.diaInicial} e ${vm.periodo.diaFinal}`
        );
      }
    },
  },
};
</script>

<style scoped></style>
