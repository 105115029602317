<template>
  <div>
    <loader :loader-primario="loader"></loader>

    <alerta
      v-on:fecharModal="fecharModal"
      :exibir="modal.exibir"
      :titulo="modal.titulo"
      :mensagem="modal.mensagem"
      v-on:exclusao="excluir()"
    ></alerta>

    <ModalSistemasEServicos
      v-model="modalSistema"
      v-on:fecharModal="fecharModalSistemas"
      :item="sistemaEdicao"
      :tags="tags"
      :categorias="filtros"
      v-on:fecha-modal="(modalSistema = false), limparCampos()"
      v-on:salvar="salvou"
      @capturarMensagemErro="mensagemErro"
      @capturarMensagemSucesso="mensagemSucesso"
    ></ModalSistemasEServicos>

    <v-container>
      <div class="card">
        <div class="inicio-titulo">
          <v-img
            class="icone-titulo"
            src="@/assets/images/icone/espaco-colaborador/icone_sistemas.svg"
          ></v-img>
          <h1 class="titulo">Sistemas e Serviços</h1>
        </div>
        <v-row v-if="admin" class="justify-end my-0">
          <v-btn @click="abrirModal(null)" class="botao-principal">
            <v-icon small class="mr-2">fas fa-circle-plus</v-icon>
            Adicionar
          </v-btn>
        </v-row>

        <!-- Áreas -->
        <v-row class="areas">
          <v-col cols="12">Selecione a área ou pesquise o termo</v-col>
          <v-col cols="6" md="3" v-for="filtro in filtros" :key="filtro.id">
            <v-card
              @click="listarCategorias(filtro.id, filtro.titulo, 1)"
              tabindex="none"
              :class="
                filtroPorCategoria == filtro.titulo
                  ? 'card-arquivo card-arquivo-active'
                  : 'card-arquivo'
              "
            >
              <v-img :src="filtro.img"></v-img>
              <p>{{ filtro.titulo }}</p>
            </v-card>
          </v-col>
        </v-row>

        <!-- Pesquisa -->
        <v-container class="mt-5">
          <v-row>
            <v-col cols="12">
              <v-text-field
                v-model="textoPesquisa"
                clearable
                dense
                outlined
                hide-details="auto"
                class="input-field"
              >
                <template slot="label">
                  <span class="input-field-text">Pesquisar</span>
                </template>
                <template slot="append">
                  <v-icon class="input-field-text"
                    >fa-solid fa-magnifying-glass</v-icon
                  >
                </template>
              </v-text-field>
            </v-col>
          </v-row>
        </v-container>

        <!-- Sistemas -->
        <v-col cols="12" v-for="arq in arquivo" :key="arq.id">
          <FormatoArquivo
            :arquivo="arq"
            @update="update"
            @excluir="excluir"
          ></FormatoArquivo>
        </v-col>
        <v-col cols="12" v-if="arquivo.length === 0">
          <div class="alerta">
            <v-row>
              <v-col cols="12" md="2" xl="1" class="alerta-icone">
                <v-img
                  src="@/assets/images/icone/espaco-colaborador/icone_sinal_de_aviso.svg"
                ></v-img>
              </v-col>
              <v-col cols="12" md="10" xl="11" class="alerta-texto">
                <span
                  >Não há nenhum serviço ou sistema cadastrado para essa
                  área.</span
                >
              </v-col>
            </v-row>
          </div>
        </v-col>

        <!-- Paginação -->
        <div class="text-center">
          <v-container>
            <v-row justify="center">
              <v-container class="max-width">
                <v-pagination
                  prev-icon=" fa-light fa-chevron-left"
                  next-icon="fa-light fa-chevron-right"
                  v-model="paginacao.page"
                  :length="paginacao.maxPage"
                  circle
                  dense
                  @input="
                    listarCategorias(
                      idFiltroSelecionado,
                      filtroPorCategoria,
                      paginacao.page
                    )
                  "
                  solo
                ></v-pagination>
              </v-container>
            </v-row>
          </v-container>
        </div>
      </div>
    </v-container>
  </div>
</template>

<script>
import loader from "@/components/Loader.vue";
import alerta from "@/components/Alerta.vue";
import api from "@/services/Api.js";
import modalMixin from "@/mixins/modalMixin";
import ModalSistemasEServicos from "@/components/EspacoDoColaborador/SistemasEServicos/ModalSistemasEServicos.vue";
import FormatoArquivo from "@/components/EspacoDoColaborador/SistemasEServicos/FormatoArquivo.vue";
import Utils from "@/utils/Utils";

export default {
  components: { loader, alerta, FormatoArquivo, ModalSistemasEServicos },
  mixins: [modalMixin],
  name: "sistemas-servicos",
  data() {
    return {
      loader: false,
      filtros: [
        {
          id: 10,
          titulo: "Gecom",
          idGrupo: 4,
          img: require("@/assets/images/icone/espaco-colaborador/icone_comercial.svg"),
        },
        {
          id: 11,
          titulo: "GOV",
          idGrupo: 4,
          img: require("@/assets/images/icone/espaco-colaborador/icone_governanca.svg"),
        },
        {
          id: 12,
          titulo: "GPC",
          idGrupo: 4,
          img: require("@/assets/images/icone/espaco-colaborador/icone_gpc.svg"),
        },
        {
          id: 13,
          titulo: "Geinfra",
          idGrupo: 4,
          img: require("@/assets/images/icone/espaco-colaborador/icone_geinfra.svg"),
        },
        {
          id: 14,
          titulo: "Gefin",
          idGrupo: 4,
          img: require("@/assets/images/icone/espaco-colaborador/icone_gefin.svg"),
        },
        {
          id: 16,
          titulo: "Gedev",
          idGrupo: 4,
          img: require("@/assets/images/icone/espaco-colaborador/icone_gedev.svg"),
        },
        {
          id: 17,
          titulo: "Ascom",
          idGrupo: 4,
          img: require("@/assets/images/icone/espaco-colaborador/icone_ascom.svg"),
        },
        {
          id: 18,
          titulo: "Asjur",
          idGrupo: 4,
          img: require("@/assets/images/icone/espaco-colaborador/icone_asjur.svg"),
        },
      ],
      filtroPorCategoria: "",
      idFiltroSelecionado: 0,
      arquivo: [],
      sistemaEdicao: {
        id: null,
        titulo: null,
        descricao: null,
        url: null,
        nomImagem: null,
        imagem: null,
        rascunho: false,
        categoria: {},
        tags: [],
      },
      modalSistema: false,
      tags: [],
      textoPesquisa: "",
      paginacao: {
        page: 1,
        maxPage: 0,
      },
      admin: false,
    };
  },
  watch: {
    textoPesquisa() {
      this.listarCategorias(
        this.idFiltroSelecionado,
        this.filtroPorCategoria,
        this.paginacao.page
      );
    },
  },
  methods: {
    update(e) {
      const vm = this;
      vm.abrirModal(e);
    },
    listarCategorias(categoria, nomeCategoria, page) {
      const vm = this;
      vm.filtroPorCategoria = nomeCategoria;
      vm.idFiltroSelecionado = categoria;
      vm.textoPesquisa = vm.textoPesquisa == null ? "" : vm.textoPesquisa;

      if (vm.textoPesquisa == "") vm.loader = true;

      api
        .get(
          `link/listar/categoria/?categoria=${categoria}&pagina=${page}&textoPesquisa=${vm.textoPesquisa}`
        )
        .then((response) => {
          vm.arquivo = response.data.result;
          vm.paginacao = {
            page: response.data.currentPage,
            maxPage: response.data.maxPages,
          };
        })
        .catch((e) => {
          this.mensagemErro(e.message);
        })
        .finally(() => {
          vm.loader = false;
        });
    },
    salvou() {
      const vm = this;
      vm.fecharModalSistemas();
      vm.limparCampos();
    },
    excluir(idExclusao) {
      const vm = this;
      //solicitou exclusao mas ainda não houve a confirmação
      if (!vm.exclusaoConfirmada) {
        vm.confirmarExclusao("Deseja confirmar a exclusão?", idExclusao);
      }
      //Alerta confirmou exclusão, pegar id de exclusao salvo no mixin
      //exclusaoConfirmada é o id de exclusao salvo no mixin
      else {
        idExclusao = vm.exclusaoConfirmada;
        vm.fecharModal();
        vm.loader = true;

        api
          .get(`link/excluir/${idExclusao}`)
          .then((response) => {
            vm.loader = false;
            vm.mensagemSucesso(response.data);
          })
          .catch((e) => {
            vm.mensagemErro(e.message);
          })
          .finally(() => {
            vm.loader = false;
            this.listarCategorias(
              this.idFiltroSelecionado,
              this.filtroPorCategoria,
              this.paginacao.page
            );
          });
      }
    },
    abrirModal(sistema) {
      const vm = this;
      vm.buscarTags();
      if (sistema) {
        vm.sistemaEdicao = sistema;
      }
      vm.modalSistema = true;
    },
    fecharModalSistemas() {
      const vm = this;
      vm.modalSistema = false;
      // this.listarCategorias(this.idFiltroSelecionado, this.filtroPorCategoria, this.paginacao.page);
    },
    formatarData(data) {
      if (!data) return null;
      if (data == "Sem data") {
        return data;
      }
      const [ano, mes, dia] = data.includes("-")
        ? data.split("-")
        : data.split("/");
      return `${dia}/${mes}/${ano}`;
    },
    buscarTags() {
      const vm = this;
      api
        .get(`tag/listar/`)
        .then((response) => {
          response.data.forEach((element) => {
            if (!vm.tags.includes(element)) {
              vm.tags.push(element);
            }
          });
        })
        .catch((e) => {
          this.mensagemErro(e.message);
        });
    },
    isAdmin() {
      this.admin = Utils.isAdmin();
    },
    limparCampos() {
      this.sistemaEdicao = {
        id: null,
        titulo: null,
        descricao: null,
        url: null,
        nomImagem: null,
        imagem: null,
        rascunho: false,
        categoria: {},
        tags: [],
      };
    },
  },
  created() {
    const vm = this;
    vm.isAdmin();
  },
  async mounted() {
    const vm = this;
    vm.listarCategorias(
      vm.idFiltroSelecionado,
      vm.filtroPorCategoria,
      vm.paginacao.page
    );
  },
};
</script>

<style scoped>
.areas .col {
  display: flex;
  justify-content: center;
}

/* Card Arquivo */
.card-arquivo {
  width: 100%;
  height: 100%;
  box-shadow: 0px 0px 20px #00000020 !important;
  border: 1px solid #fff;
  border-radius: 28px !important;
}

.card-arquivo .v-image {
  max-width: 4rem;
  margin: 1.5rem auto 0.5rem;
  overflow: visible;
}

.card-arquivo p {
  margin: 1rem !important;
  text-align: center;
  color: #278b97;
  font-weight: 600;
}

/* Card Arquivo Hover */
.card-arquivo:hover {
  background-color: #eff9fa;
  border: 1px solid #278b97;
}

.card-arquivo:hover p {
  color: #278b97;
}

.card-arquivo:hover .v-image {
  filter: brightness(1) invert(0);
}

/* Card Arquivo Active */
.card-arquivo-active {
  background-color: #278b97;
}

.card-arquivo-active p {
  color: #fff;
}

.card-arquivo-active .v-image {
  filter: brightness(0) invert(1);
}

/* Alerta */
.alerta {
  display: flex;
  align-items: center;
  margin: 1rem 0;
  padding: 1rem;
  border-radius: 20px;
  gap: 2rem;
  background-color: #edefee;
}

.alerta-icone {
  display: grid;
  align-items: center;
  justify-content: center;
  margin: 2rem 0;
  border-right: 1px solid #278b97;
}

.alerta-icone .v-image {
  width: fit-content;
}

.alerta-texto {
  display: flex;
  align-items: center;
  padding: 1rem 2rem;
}

@media screen and (max-width: 960px) {
  .alerta-icone {
    margin-bottom: 0;
    border-right: none;
  }
}
</style>
