<template>
  <div v-cloak>
    <alerta v-on:fecharModal="fecharModal" :exibir="modal.exibir" :titulo="modal.titulo" :mensagem="modal.mensagem">
    </alerta>

    <loader :loader-primario="loader"></loader>

    <v-container>
      <div class="card px-0">
        <!-- Empresa + Representante Legal -->
        <v-col v-if="dadosPessoais.isPJ" cols="12" md="6" class="ms-3">
          <p>
            <v-icon class="icone">fas fa-city</v-icon>
            Empresa - {{ dadosPessoais.nomeFornecedor }}
          </p>
          <p>
            <v-icon class="icone">fas fa-user-tie</v-icon>
            Representante Legal - {{ dadosPessoais.nomeResponsavel }}
          </p>
        </v-col>
        <!-- Nome do Colaborador -->
        <v-row class="perfil">
          <v-avatar color="#278B97" size="56" :class="$vuetify.breakpoint.mobile ? 'ms-4 mb-2' : ''">
            <img v-if="dadosPessoais.imagemBase64 != null" :src="'data:image/png;base64,' + dadosPessoais.imagemBase64"
              alt="Imagem do perfil do usuário" class="img-thumbnail" />
            <v-icon v-else color="#FFF">fas fa-user-circle</v-icon>
          </v-avatar>
          <div class="nome-colaborador">
            <h2 v-if="dadosPessoais.nome" id="nome-colaborador">{{ dadosPessoais.nome }}</h2>
          </div>
        </v-row>
        <!-- Vínculo Empregatício -->
        <div>
          <h3 v-if="dadosPessoais.isPJ" class="isPS">
            <v-icon class="icone-vinculo">fas fa-file-contract</v-icon>
            Prestador de Serviço
          </h3>
          <h3 v-else-if="dadosPessoais.cargo == 'Estagiario'" class="isEstagiario">
            <v-icon class="icone-vinculo">fas fa-user-graduate</v-icon>
            Estagiário
          </h3>
          <h3 v-else-if="dadosPessoais.cargo == 'Menor Aprendiz'" class="isMenorAprendiz">
            <v-icon class="icone-vinculo">fas fa-chalkboard-teacher</v-icon>
            Menor Aprendiz
          </h3>
          <h3 v-else class="isEmpregado">
            <v-icon class="icone-vinculo">fas fa-id-card</v-icon>
            Empregado
          </h3>
        </div>
        <!-- Dados + Dependentes -->
        <v-container>
          <v-row class="px-8">
            <v-col cols="12" md="11">
              <p v-if="dadosPessoais.cargo != ''">
                <v-icon class="icone">fas fa-briefcase</v-icon>{{ dadosPessoais.cargo }}
              </p>
              <p>
                <v-icon class="icone">fas fa-building</v-icon>
                {{ modificaNome(dadosPessoais.lotacao) }}
              </p>
              <p>
                <v-icon class="icone">fas fa-phone</v-icon>{{ dadosPessoais.telefone }}
              </p>
              <p>
                <v-icon class="icone">fas fa-envelope</v-icon>{{ dadosPessoais.email }}
              </p>
              <p>
                <v-icon class="icone">fas fa-home</v-icon>
                {{ endereco.endereco }} Número {{ endereco.numero }} {{ endereco.complemento }} - {{ endereco.bairro }},
                {{ endereco.cidade }} - {{ endereco.uf }} {{ endereco.cep }}
              </p>
            </v-col>
            <p v-if="dadosPessoais.isPJ">
              <i>Os dados aqui apresentados e compartilhados são de responsabilidade da empresa
                {{ dadosPessoais.nomeFornecedor }}, conforme previsto em contrato.</i>
            </p>
          </v-row>
          <!-- Dependentes -->
          <v-row v-if="!dadosPessoais.isPJ" class="dependentes">
            <v-col class="px-0">
              <h3>
                <v-icon class="icone-vinculo">fas fa-user-group</v-icon>Dependentes
              </h3>
              <v-data-table :headers="cabecalhoDependentes" :hide-default-footer="true" :items="dependentes"
                :no-data-text="'Você não possui dependentes cadastrados!'" class="tabela"></v-data-table>
              <!-- <p v-else class="pt-3 px-10">Você não possui dependentes cadastrados!</p> -->
            </v-col>
          </v-row>
        </v-container>
      </div>
    </v-container>
  </div>
</template>

<script>
import loader from "@/components/Loader.vue";
import alerta from "@/components/Alerta.vue";
import api from "@/services/Api.js";
import modalMixin from "@/mixins/modalMixin";

export default {
  components: { loader, alerta },
  mixins: [modalMixin],
  data() {
    return {
      cabecalhoDependentes: [
        {
          text: "CPF",
          align: "start",
          sortable: false,
          value: "cpf",
        },
        {
          text: "Nome",
          align: "start",
          sortable: false,
          value: "nome",
        },
        {
          text: "Data de nascimento",
          align: "start",
          sortable: false,
          value: "data_nascimento",
        },
      ],
      dependentes: [],
      dadosPessoaisAd: {},
      dadosPessoais: {
        nome: "",
        cargo: "",
        lotacao: "",
        telefone: "",
        email: "",
        imagemBase64: "",
        nomeFornecedor: "",
        isPJ: false,
        idFornecedor: "",
        nomeResponsavel: "",
        tipoFornecedor: "",
      },
      usuario: {},
      dadosAtualizaveis: {},
      certificacoesPessoais: [],
      endereco: {
        endereco: "",
        complemento: "",
        bairro: "",
        cidade: "",
        uf: "",
        cep: "",
        numero: "",
      },
      loader: false,
    };
  },
  methods: {
    modificaNome(nome) {
      if (nome != "") {
        return nome[0].toUpperCase() + nome.substring(1).toLowerCase();
      } else {
        return "";
      }
    },
    getCredenciais() {
      let vm = this;
      api
        .get("auth/credenciais")
        .then((response) => {
          vm.usuario = response.data;
        })
        .catch((e) => {
          this.mensagemErro(e.message);
        })
        .finally(() => {
          vm.buscaDadosADd();
        });
    },
    buscaDadosDependentes() {
      const vm = this;
      api.get("meusdados/dependentes")
        .then(function (response) {
          vm.dependentes = response.data;
          response.data.forEach((element) => {
            element.data_nascimento = vm.formatarData(element.data_nascimento);
          });
        })
        .catch(e => { console.error(e) });
    },
    async buscaDadosADd() {
      const vm = this;
      vm.loader = true;
      await api
        .get("meusdados/dadosAD")
        .then((response) => {
          vm.dadosPessoais = response.data;
          vm.dadosPessoais.imagemBase64 = vm.usuario.imagemBase64;
          vm.dadosPessoais.isPJ = vm.usuario.isPJ;
          if (vm.usuario.isPJ) {
            vm.dadosPessoais.idFornecedor = vm.usuario.idFornecedor;
            vm.dadosPessoais.nomeFornecedor = vm.usuario.nomeFornecedor;
            vm.dadosPessoais.nomeResponsavel = vm.usuario.nomeResponsavel;
            vm.dadosPessoais.tipoFornecedor =
              vm.usuario.tipoFornecedor.descricao;
          }
        })
        .catch((e) => {
          this.mensagemErro(e.message);
        })
        .finally(function () {
          vm.loader = false;
        });
    },
    buscarDadosPessoais() {
      api
        .get("pessoa")
        .then((response) => {
          this.dadosAtualizaveis = response.data;
        })
        .catch((err) => {
          this.mensagemErro(e.message);
        });
    },
    buscaDadosEndereco() {
      const vm = this;
      api
        .get("meusdados/endereco", {
          headers: { Authorization: localStorage.token },
          withCredentials: true,
        })
        .then(function (response) {
          vm.endereco = response.data;
        })
        .catch(function (e) {
          this.mensagemErro(e.message);
        });
    },
    formatarData(data) {
      if (!data) return null;
      if (data == "Sem data") {
        return data;
      }
      const [ano, mes, dia] = data.includes("-")
        ? data.split("-")
        : data.split("/");
      return `${dia}/${mes}/${ano}`;
    },
  },
  async created() {
    const vm = this;
    await vm.getCredenciais();
    await vm.buscaDadosEndereco();
    vm.buscaDadosDependentes();
  },
};
</script>

<style scoped>
.perfil {
  margin: -.75rem 1rem 1rem;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  max-width: 100%;
  height: auto;
}

.nome-colaborador {
  display: flex;
  align-items: center;
  margin-left: 1rem;
}

.perfil .v-icon.v-icon {
  font-size: 45px;
}

.dependentes h3 {
  background-color: #e8b90e;
}

.isEstagiario {
  background-color: #008693;
}

.isMenorAprendiz {
  background-color: #da9902;
}

.isEmpregado {
  background-color: #005169;
}

.isPS {
  background-color: #c21a25;
}

.icone {
  color: #278B97;
  width: 1.5rem;
  margin-right: .5rem;
}

.icone-vinculo {
  color: #FFF;
  font-size: 1.25rem;
  margin-right: 1rem;
}

.tabela {
  margin: 2rem 3rem 0;
}
</style>
