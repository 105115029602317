<template>
  <div>
    <Loader :loader-primario="loader" />

    <Alerta v-on:fecharModal="fecharModal" :exibir="modal.exibir" :titulo="modal.titulo" :mensagem="modal.mensagem" />

    <v-container>
      <div class="card">
        <div class="inicio-titulo">
          <v-img class="icone-titulo" src="../../assets/images/icone/espaco-colaborador/icone_avaliacao.svg"></v-img>
          <h1 class="titulo">Programa de Gestão de Desempenho</h1>
        </div>

        <v-tabs v-model="tab" grow hide-slider show-arrows class="py-3">
          <v-tab href="#tab-0" :class="tab == 'tab-0' ? 'tab tab-active' : 'tab'">Autoavaliação</v-tab>
          <v-tab href="#tab-1" :class="tab == 'tab-1' ? 'tab tab-active' : 'tab'">Histórico</v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab" touchless>
          <v-divider class="my-6"></v-divider>
          <v-tab-item value="tab-0">
            <AutoAvaliacao />
          </v-tab-item>
          <v-tab-item value="tab-1">
            <Historico />
          </v-tab-item>
        </v-tabs-items>
      </div>
    </v-container>
  </div>
</template>
  
<script>
import Loader from '@/components/Loader.vue';
import Alerta from '@/components/Alerta.vue';
import modalMixin from '@/mixins/modalMixin';

import AutoAvaliacao from '@/components/EspacoDoColaborador/ProgramaGestaoDesempenho/AutoAvaliacao.vue';
import Historico from '@/components/EspacoDoColaborador/ProgramaGestaoDesempenho/Historico.vue';

export default {
  name: 'programa-gestao-desempenho',
  components: { Loader, Alerta, AutoAvaliacao, Historico },
  mixins: [modalMixin],
  data() {
    return {
      loader: false,
      tab: null,
    };
  },
};
</script>
  
<style>
/* Tabs */
.card::v-deep .v-tabs-bar {
  height: auto;
}

.tab {
  background-color: #edefee;
  border: 1px solid #edefee;
  border-radius: 12px;
  text-align: center;
  text-transform: none;
  letter-spacing: 0px;
  font: normal normal bold 16px/20px Montserrat;
  color: #787878 !important;
  margin: 0 1rem;
  padding: 1rem;
  width: 8rem;
}

.tab::before {
  border-radius: 12px;
}

.tab:hover {
  background-color: #eff9fa;
  border: 1px solid #278b97;
  color: #278b97 !important;
}

.tab-active {
  background-color: #278b97;
  border: 1px solid #278b97;
  color: #FFF !important;
}

/* --------------------------------- */
/* Geral */
.titulo-avaliacao {
  text-transform: none;
}

.titulo-avaliacao span {
  border-left: 2px solid #278B97;
  padding-left: .75rem;
  margin-left: .75rem;
  text-transform: none;
}

/* --------------------------------- */
/* Dados Colaborador */
.header {
  display: flex;
  background-color: #278b97;
  border-radius: 1rem 1rem 0 0;
}

.header .v-icon {
  font-size: 1.5rem;
  color: #FFF;
  margin: 0 1rem;
}

.header h2 {
  color: #FFF;
  font-weight: 600;
}

.dados-colaborador-itens p {
  margin: 0 .125rem .25rem !important;
}

.dados-colaborador-itens b {
  font-weight: 700;
}

/* ------------------------------- */
/* Tabela Resultado */
.tb-resultado th {
  background-color: #5DA660;
  text-align: center !important;
}

.tb-resultado>.v-data-table__wrapper>table>thead>tr>th:first-child,
.tb-resultado>.v-data-table__wrapper>table>thead>tr>th:last-child,
.tb-formulario>.v-data-table__wrapper>table>thead>tr>th:first-child,
.tb-formulario>.v-data-table__wrapper>table>thead>tr>th:last-child {
  border-radius: 0 !important;
}

.tb-resultado>.v-data-table__wrapper>table>thead>tr>th::after {
  content: "";
  position: absolute;
  border: 1px solid;
  height: 75%;
  right: 0;
  top: 6px;
  color: #FFFA;
}

.tb-resultado td {
  font-size: 1rem;
  text-align: center;
}

.tb-resultado.v-data-table>.v-data-table__wrapper>table>tbody>tr>td::after {
  content: "";
  position: absolute;
  border: 1px solid;
  height: 60%;
  right: 0;
  top: 12px;
  color: #5DA660AA;
}

/* ------------------------------- */
/* Tabela Formulário */
.tb-formulario th {
  background-color: #4CAF50;
  padding: .5rem 1rem !important;
  text-transform: none !important;
}

.tb-formulario>.v-data-table__wrapper>table>thead>tr>th::after {
  content: "";
  position: absolute;
  border: 1px solid;
  height: 75%;
  right: 0;
  top: 10px;
  color: #FFFA;
}

.tb-formulario.v-data-table>.v-data-table__wrapper>table>tbody>tr>td {
  padding: .5rem;
  border-top: none !important;
  border-bottom: 1px solid #278B97 !important;
  background-color: transparent !important;
  font-size: .875rem;
  color: #000;
}

.tb-formulario.v-data-table>.v-data-table__wrapper>table>tbody>tr>td::after {
  content: "";
  position: absolute;
  border: 1px solid;
  height: 60%;
  right: 0;
  top: 12px;
  color: #278B97;
}

.tb-formulario.v-data-table>.v-data-table__wrapper tbody tr:first-child:hover td:first-child,
.tb-formulario.v-data-table>.v-data-table__wrapper tbody tr:first-child:hover td:last-child {
  border-radius: 0;
}

.tiposQuestao {
  background-color: #278B97;
  margin: 0;
}

.tiposQuestao .row {
  margin: 0;
}

.tiposQuestao td {
  background-color: transparent !important;
  color: #FFF !important;
  font-size: 1rem !important;
  font-weight: 600;
}

.tiposQuestao .v-icon {
  color: #FFF !important;
  font-size: 1.25rem !important;
  cursor: pointer;
}

.theme--light.v-data-table>.v-data-table__wrapper>table>tbody>.tiposQuestao:hover>td {
  background-color: #278B97 !important;
}

.radio-input {
  justify-content: center;
}
</style>
