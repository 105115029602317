<template>
  <v-navigation-drawer
    v-model="drawer"
    :fixed="$vuetify.breakpoint.xsOnly"
    :app="$vuetify.breakpoint.smAndUp"
    :mini-variant.sync="mini"
    :permanent="$vuetify.breakpoint.smAndUp"
    class="custom-scrollbar"
  >
    <!-- Ícone: Ativar mini -->
    <v-btn @click="mini = !mini" icon :class="mini ? 'btn-mini-ativado' : 'btn-mini-desativado'">
      <v-icon>fas fa-bars</v-icon>
    </v-btn>
    <!-- Foto -->
    <div v-if="$vuetify.breakpoint.smAndUp && !mini">
      <div class="foto-usuario">
        <img
          v-if="usuario.imagemBase64 != null"
          :src="'data:image/png;base64,' + usuario.imagemBase64"
          alt="Imagem do perfil do usuário"
        />
        <v-icon v-else>fas fa-user</v-icon>
      </div>
    </div>
    <v-list dense flat>
      <!-- Bem-vindo -->
      <v-list-item v-if="$vuetify.breakpoint.xsOnly">
        <v-list-item-content>
          <v-list-item-title>
            <h4>Bem-vindo</h4>
          </v-list-item-title>
          <v-list-item-subtitle>{{ firstName }} {{ lastName }}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-list-item v-else-if="!mini">
        <v-list-item-content class="text-center">
          <v-list-item-title>
            <h4>Bem-vindo</h4>
          </v-list-item-title>
          <v-list-item-subtitle>{{ firstName }} {{ lastName }}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <v-divider class="divider"></v-divider>

      <!-- Itens do Menu -->
      <v-list-item-group color="primary">
        <div v-for="item in menuLateral" :key="item.titulo">
          <router-link v-if="!item.hide" :to="item.link" class="text-decoration-none">
            <v-list-item :class="mini ? 'itens-menu-mini' : 'itens-menu'">
              <v-list-item-icon>
                <v-img :src="item.icone"></v-img>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ item.titulo }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </router-link>
        </div>
      </v-list-item-group>
    </v-list>

    <!-- Sair -->
    <template v-slot:append>
      <v-btn @click="logout()" class="botao-sair">
        <v-icon small class="mr-2">fas fa-sign-out-alt</v-icon>
        <span v-if="$vuetify.breakpoint.xsOnly">Sair</span>
        <span v-else-if="!mini">Sair</span>
      </v-btn>
    </template>
  </v-navigation-drawer>
</template>

<script>
import api from '@/services/Api.js';
import ServiceVisitaTecnica from '@/services/ServiceVisitaTecnica';
import Utils from '@/utils/Utils';

export default {
  data() {
    return {
      key: 0,
      usuario: {},
      firstName: '',
      lastName: '',
      mini: false,
      drawer: false,
      menuLateral: [
        {
          titulo: 'Meus Dados',
          link: '/espaco-do-colaborador/meus-dados',
          icone: require('@/assets/images/icone/espaco-colaborador/icone_meus_dados.svg'),
        },
        {
          titulo: 'Formação',
          link: '/espaco-do-colaborador/formacao',
          icone: require('@/assets/images/icone/espaco-colaborador/icone_formacao.svg'),
        },
        {
          titulo: 'Contracheque',
          link: '/espaco-do-colaborador/contra-cheque',
          icone: require('@/assets/images/icone/espaco-colaborador/icone_contra-cheque.svg'),
          hide: Utils.isPj(),
        },
        {
          titulo: 'Benefícios',
          link: '/espaco-do-colaborador/beneficios',
          icone: require('@/assets/images/icone/espaco-colaborador/icone_beneficios.svg'),
          hide: Utils.isPj(),
        },
        {
          titulo: 'Atestados Médicos',
          link: '/espaco-do-colaborador/atestados',
          icone: require('@/assets/images/icone/espaco-colaborador/icone_atestado.svg'),
          hide: Utils.isPj(),
        },
        {
          titulo: 'Folha de Ponto',
          link: '/espaco-do-colaborador/folhadeponto',
          icone: require('@/assets/images/icone/espaco-colaborador/icone_folha_de_ponto.svg'),
          hide: Utils.isPj(),
        },
        {
          titulo: 'Férias',
          link: '/espaco-do-colaborador/ferias',
          icone: require('@/assets/images/icone/espaco-colaborador/icone_ferias.svg'),
          hide: Utils.isPj(),
        },
        {
          titulo: 'Academia Hepta',
          link: '/espaco-do-colaborador/academia-hepta',
          icone: require('@/assets/images/icone/espaco-colaborador/icone_academia_hepta.svg'),
        },
        {
          titulo: 'Proteção de Dados Pessoais',
          link: '/espaco-do-colaborador/consentimento',
          icone: require('@/assets/images/icone/espaco-colaborador/icone_consentimento.svg'),
        },
        {
          titulo: 'Termos e Condições',
          link: '/espaco-do-colaborador/termos',
          icone: require('@/assets/images/icone/espaco-colaborador/icone_termos_e_condicoes.svg'),
        },
        {
          titulo: 'Visita Técnica',
          link: '/espaco-do-colaborador/visita-tecnica',
          icone: require('@/assets/images/icone/espaco-colaborador/icone_visita.svg'),
          hide: true,
        },
        {
          titulo: 'Programa de Gestão de Desempenho',
          link: '/espaco-do-colaborador/programa-gestao-desempenho',
          icone: require('@/assets/images/icone/espaco-colaborador/icone_avaliacao.svg'),
          hide: Utils.isPj(),
        },
        {
          titulo: 'Sistemas e Serviços',
          link: '/espaco-do-colaborador/sistemas',
          icone: require('@/assets/images/icone/espaco-colaborador/icone_sistemas.svg'),
        },
        {
          titulo: 'Informações Úteis',
          link: '/espaco-do-colaborador/informacoes-uteis',
          icone: require('@/assets/images/icone/espaco-colaborador/icone_informacoes_uteis.svg'),
        },
      ],
    };
  },
  methods: {
    /*  Atualizar para nova auto-avaliação
    verificarAutoavaliacaoPessoaLogada() {
      const menuAvaliacao = this.menuLateral.find(r => r.titulo === "Autoavaliação")
      api.get("AutoAvaliados/existeAvaliacaoParaPessoaLogada")
        .then((res) => {
          if (res.data !== false) {
            menuAvaliacao.hide = false;
          }
        });
    }, */
    nomeSobrenome() {
      this.firstName = this.usuario.nome.split(' ')[0];
      this.lastName = this.usuario.nome.split(' ').pop();
    },
    logout() {
      const vm = this;
      api
        .get('auth/logout', {
          headers: { Authorization: localStorage.token },
          withCredentials: true,
        })
        .then(response => {
          if (response.status == 200) {
            //localStorage.clear();
            localStorage.removeItem('isAdmin');
            localStorage.removeItem('isGpc');
            localStorage.removeItem('token');
            vm.$router.push({
              name: 'Login',
            });
          }
        });
    },
    async validarVisitas() {
      let vm = this;
      let showVisita = false;
      const menuVisita = vm.menuLateral.find(i => i.titulo === 'Visita Técnica');

      await ServiceVisitaTecnica.creditoResumo()
        .then(res => {
          if (res.status == 200) showVisita = true;
        })
        .catch(() => (showVisita = false || showVisita));
      await ServiceVisitaTecnica.listarVisitas()
        .then(res => (showVisita = res.data.visitas.length > 0))
        .catch(() => (showVisita = false || showVisita));

      menuVisita.hide = !showVisita;
    },
    getCredenciais() {
      let vm = this;
      api
        .get('auth/credenciais')
        .then(response => {
          vm.usuario = response.data;

          if (vm.usuario.admin) {
            vm.menuLateral.push({
              titulo: 'Métricas de Uso',
              link: '/espaco-do-colaborador/metricas',
              icone: require('@/assets/images/icone/espaco-colaborador/icone_metricas.svg'),
            });
          }
        })
        .catch(e => this.mensagemErro(e.message))
        .finally(() => vm.nomeSobrenome());
    },
  },
  mounted() {
    let vm = this;
    vm.validarVisitas();
  },
  async created() {
    await this.getCredenciais();
    // await this.verificarAutoavaliacaoPessoaLogada();
  },
};
</script>

<style scoped>
@media screen and (max-width: 600px) {
  .tooltip-mobile {
    display: none;
  }
}

.btn-mini-desativado {
  margin: 1rem 0 0 1rem;
}

.btn-mini-ativado {
  display: block;
  margin: 1rem auto 0;
}

.btn-mini-desativado .v-icon,
.btn-mini-ativado .v-icon {
  font-size: 1.25rem;
}

.foto-usuario {
  background-color: #278b97;
  height: 8rem;
  width: 8rem;
  display: flex;
  justify-content: center;
  border-radius: 2rem;
  margin: 0 auto;
}

.foto-usuario .v-icon {
  color: #d4e8ea;
  font-size: 4rem;
}

.divider {
  margin: 1rem 0.5rem;
  border-color: #939393 !important;
}

.itens-menu .v-list-item__icon {
  margin-right: 0.5rem;
  align-items: center;
}

.itens-menu .v-list-item__icon .v-image {
  width: 1.325rem !important;
  height: fit-content;
}

.itens-menu .v-list-item__title {
  font-size: 0.875rem;
  font-weight: 700;
  white-space: normal;
}

.itens-menu-mini {
  display: block;
  padding: 0;
  text-align: center;
}

.itens-menu-mini .v-list-item__icon {
  width: 1.325rem !important;
  height: fit-content;
}

.botao-sair {
  background-color: #278b97 !important;
  padding: 1rem 0 !important;
  height: 3rem !important;
  font-weight: 600;
}

.custom-scrollbar {
  overflow-y: auto;
  scrollbar-color: #278b97 #f5f5f5;
  z-index: 10;
}
</style>
