<template>
  <v-dialog v-model="visualizar" width="1800" @click:outside="fechaModal()">

    <v-card>
      <v-card-title>
        <h5> Detalhes Consentimento </h5>
        <v-icon small @click="fechaModal()"> fa-times </v-icon>
      </v-card-title>

      <v-card-text>
        <div class="espacamento">
          <v-container>
            <v-row justify="space-between">
              <div class="borda">
                <div class="titulo-borda">Título</div>
                <div class="subtitulo-borda">{{ item.titulo }}</div>
              </div>

              <div class="borda">
                <div class="titulo-borda">Data de criação</div>
                <div class="subtitulo-borda">{{ modificarDataCriacao(item) }}</div>
              </div>

              <div class="borda">
                <div class="titulo-borda">Data de Validade</div>
                <div class="subtitulo-borda">{{ item.validade }}</div>
              </div>
            </v-row>
          </v-container>
        </div>

        <v-col cols="12" class="espacamento-v-col">
          <v-divider insert class="divider-tela"></v-divider>
        </v-col>

        <div>
          <p class="descricao">{{ item.descricao }} </p>
        </div>
      </v-card-text>

      <div class="espacamento">
        <v-data-table :headers="headers" :items="dados" class="elevation-1" :hide-default-footer="true">


          <template v-slot:item.item-estado="{ item }">
            <div :class="status(item.statusConsentimento)">
              <div v-if="item.statusConsentimento == 'SOLICITADO_REVOGACAO'">SOLICITADO REVOGAÇÃO</div>
              <div v-else> {{ item.statusConsentimento }} </div>
            </div>
          </template>

          <template v-slot:item.acao="{ item }">
            <div class="div-icone">
              <v-tooltip top v-if="item.statusConsentimento === 'PENDENTE' ||
                item.statusConsentimento === 'REPROVADO'">
                <template v-slot:activator="{ on, attrs }">
                  <v-img v-on="on" class="icone" @click="aprovar(item.idConsentimentoColaborador)"
                    src="@/assets/images/icone/espaco-colaborador/icone_permitir.svg"></v-img>
                </template>
                <span>Aprovar</span>
              </v-tooltip>

              <v-tooltip top v-if="item.statusConsentimento === 'PENDENTE'">
                <template v-slot:activator="{ on, attrs }">
                  <v-img v-on="on" class="icone" @click="reprovar(item.idConsentimentoColaborador)"
                    src="@/assets/images/icone/espaco-colaborador/icone_reprovar.svg"></v-img>
                </template>
                <span>Reprovar</span>
              </v-tooltip>

              <v-tooltip top v-if="item.statusConsentimento === 'APROVADO'">
                <template v-slot:activator="{ on, attrs }">
                  <v-img v-on="on" class="icone" @click="alterar(item.idConsentimentoColaborador)"
                    src="@/assets/images/icone/espaco-colaborador/icone_revogar.svg"></v-img>
                </template>
                <span>Solicitar Alteração</span>
              </v-tooltip>
            </div>

          </template>

        </v-data-table>

      </div>
      <v-col cols="12" class="espacamento-v-col">
        <v-divider insert class="divider-tela"></v-divider>
      </v-col>

      <v-card-actions class="justify-center pb-10">
        <v-btn class="botao-cancelar mr-2 largura-btn" @click="fechaModal()">Sair</v-btn>
      </v-card-actions>


    </v-card>

  </v-dialog>
</template>

<script>
// import api from "@/services/Api.js";
export default {
  name: "modal-visualizar-consentimento",
  props: {
    item: {},
    value: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    visualizar: false,
    headers: [
      { text: 'Criador', value: 'criadoPor', width: '65%' },
      { text: 'Status Consentimento', value: 'item-estado', width: '25%' },
      { text: 'Ação', value: 'acao', width: '10%' },
    ],
    dados: [],
  }),
  watch: {
    value(e) {
      this.visualizar = e;
      this.dados = [this.item];
    },
  },
  methods: {
    aprovar(e) {
      //informando para ProtecaoDeDados.vue -- método update() -- que deve APROVAR 
      this.$emit("update", 'APROVAR', e);
    },
    reprovar(e) {
      //informando para ProtecaoDeDados.vue -- método update() -- que deve REPROVAR 
      this.$emit("update", 'REPROVAR', e);
    },
    alterar(e) {
      //informando para ProtecaoDeDados.vue -- método update() -- que deve ALTERAR 
      this.$emit("update", 'ALTERAR', e);
    },
    fechaModal: function () {
      this.$emit("fecha-modal");
    },
    modificarDataCriacao(item) {
      if (item.dataCriacao) {
        return item.dataCriacao.split(" ")[0]
      }
    },
    status(e) {
      if (e == 'APROVADO') {
        return "status status-aprovado";
      } else if (e == 'PENDENTE') {
        return "status status-pendente";
      } else if (e == 'REPROVADO') {
        return "status status-reprovado";
      } else if (e == 'SOLICITADO_REVOGACAO') {
        return "status status-revogacao";
      } else if (e == 'REVOGADO') {
        return "status status-revogado";
      }
    },
  },
};
</script>
<style scoped>
.divider-tela {
  margin: 30px;
  opacity: 1.0;
  justify-items: center;
}

.borda {
  font-size: 16px;
  border-left: 3px solid;
  border-radius: 3px;
  padding-left: 10px;
  color: #008693;
  text-align: left;
}

.subtitulo-borda {
  color: #008693 !important;
  font: Montserrat, Bold;
}

.titulo-borda {
  color: #5D5D5D !important;
}

.descricao {
  padding: 0px 40px 0px 40px !important;
  text-align: justify !important;
}


.borda-modal {
  border-left: 3px solid;
  border-radius: 3px;
  padding-left: 10px;
}

.espacamento {
  padding: 20px 40px 10px 40px;
  background-color: #e9e9e9;
}

.espacamento-v-col {
  padding: 5px;
  background-color: #e9e9e9;
}

.v-card>.v-card__title>.v-icon {
  position: absolute;
  right: 24px;
  color: #ffffff;
}

.status {
  width: 157px;
  height: 35px;
  border-radius: 10px;
  opacity: 1;
  padding: 8px;
  text-align: center;
  font: normal normal bold 15px/19px Montserrat;
  letter-spacing: 0.3px;
  color: #FFFFFF;
  font-size: 14px;
}

.status-aprovado {
  background: #32ACB9 0% 0% no-repeat padding-box;
}

.status-pendente {
  background: #F8BA00 0% 0% no-repeat padding-box;
}

.status-reprovado {
  background: #DB1826 0% 0% no-repeat padding-box;
}

.status-revogado {
  background: #ff4500 0% 0% no-repeat padding-box;
}

.status-revogacao {
  background: #2196f3 0% 0% no-repeat padding-box;
  padding: 5px !important;
  height: 45px !important;
}

.div-icone {
  display: inline-flex;
  gap: 15px;
}

.icone {
  width: 23px;
  height: 23px;
  cursor: pointer;
  outline: none;
}

.espacamento-v-col2 {
  padding: 12px !important;
}
</style>


