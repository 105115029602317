<template>
  <div>
    <Loader :loader-primario="loader"></Loader>

    <Alerta
      v-on:fecharModal="fecharModal"
      v-on:exclusao="excluirNovidade()"
      :exibir="modal.exibir"
      :titulo="modal.titulo"
      :mensagem="modal.mensagem"
    ></Alerta>

    <RedesSociais></RedesSociais>

    <ModalArtigo
      v-if="dialogEditar"
      :novidade="editarNovidade"
      @mensagemSucesso="mensagemSucesso"
      @capturarMensagemErro="mensagemErro"
      v-on:fecha-modal="fecharModalArtigo"
    ></ModalArtigo>

    <ModalBanner
      :modalAlterarBanner="modalAlterarBanner"
      :imagem="imagemModal"
      :pagina="pagina"
      :comLink="false"
      @atualizaImagem="atualizaImagem"
      v-on:fecha-modal="modalAlterarBanner = false"
      :customMethod="getImagem"
      @mensagem="mensagem"
      @loader="isLoading"
    ></ModalBanner>

    <!-- Banner -->
    <v-container class="banner">
      <v-btn
        v-if="admin"
        @click="
          modalAlterarBanner = true;
          imagemModal = imagem.banner;
          pagina = 'banner';
        "
        class="banner-btn botao-edicao-adm"
      >
        <v-icon>fa fa-pencil</v-icon>
      </v-btn>
      <v-img
        :src="
          'data:' +
          imagem.banner.tipoArquivo +
          ';base64,' +
          imagem.banner.arquivo
        "
      ></v-img>
    </v-container>

    <!-- Card Principal -->
    <v-container>
      <v-card class="card-novidades">
        <v-row>
          <!-- Seleção de Tabs -->
          <v-col
            cols="12"
            md="3"
            xl="2"
            :class="{
              'tabs-md': $vuetify.breakpoint.mdAndUp,
              'tabs-sm': !$vuetify.breakpoint.mdAndUp,
            }"
          >
            <v-tabs
              v-model="tab"
              :vertical="$vuetify.breakpoint.mdAndUp"
              background-color="transparent"
            >
              <v-tabs-slider color="transparent"></v-tabs-slider>
              <v-tab
                v-for="categoria in categorias"
                :key="categoria.id"
                @click="buscarNovidades(categoria.nome), (page = 1)"
                :to="`#/${categoria.nome}`"
                active-class="tab-ativa"
              >
                <v-img :src="getSrc(categoria.icone)" class="icone"></v-img>
                <span>{{ categoria.nome }}</span>
              </v-tab>
            </v-tabs>
          </v-col>

          <!-- Tabs / Ver Artigo -->
          <v-col cols="12" md="9" xl="10">
            <!-- Visão Cards -->
            <div v-if="!visualizarArtigo">
              <v-container>
                <v-tabs-items v-model="tab">
                  <!-- Botão Adicionar -->
                  <v-row>
                    <v-col cols="12">
                      <p>
                        Saiba tudo que anda acontecendo na Hepta e receba
                        informações sobre o mercado de TI no Brasil e no Mundo.
                      </p>
                    </v-col>
                    <v-col cols="12" md="3" xl="2" offset-md="9" offset-xl="10">
                      <v-btn
                        @click="dialogEditar = true"
                        class="botao-principal w-100"
                      >
                        <v-icon class="mr-2">fas fa-circle-plus</v-icon>
                        Adicionar
                      </v-btn>
                    </v-col>
                  </v-row>

                  <!-- Filtros -->
                  <v-row>
                    <v-col cols="12" md="6" lg="8">
                      <v-text-field
                        dense
                        solo
                        v-model="pesquisa"
                        @input="buscarNovidades()"
                        clearable
                        hide-details="auto"
                        class="input-field"
                      >
                        <template slot="label">
                          <span class="input-field-text">Pesquisar</span>
                        </template>
                        <template slot="append">
                          <v-icon class="input-field-text"
                            >fa-solid fa-magnifying-glass</v-icon
                          >
                        </template>
                      </v-text-field>
                    </v-col>
                    <v-col cols="12" md="6" lg="4">
                      <v-menu
                        v-model="data"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                        id="editDataVerificacao"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="dateRangeText"
                            solo
                            dense
                            placeholder="DD/MM/AAAA"
                            clearable
                            v-on="on"
                            hide-details="auto"
                            v-bind="attrs"
                            class="input-field"
                          >
                            <template slot="label">
                              <span class="input-field-text"
                                >Data de Verificação</span
                              >
                            </template>
                            <template slot="append">
                              <v-icon class="input-field-text"
                                >fas fa-calendar-days</v-icon
                              >
                            </template>
                          </v-text-field>
                        </template>
                        <v-date-picker
                          v-model="dataPesquisaFormatada"
                          @input="formatDate"
                          required
                          range
                          :rules="[(v) => !!v || 'O campo Data é obrigatório.']"
                          locale="pt-br"
                          no-title
                          scrollable
                        ></v-date-picker>
                      </v-menu>
                    </v-col>
                  </v-row>

                  <!-- Cards -->
                  <div
                    v-for="categoria in categorias"
                    :key="categoria.id"
                    class="pt-4"
                  >
                    <v-tab-item
                      :key="categoria.id"
                      :value="'/' + categoria.nome"
                      :transition="false"
                    >
                      <v-row v-if="novidades.length > 0">
                        <v-col
                          cols="12"
                          lg="6"
                          v-for="novidade in novidades"
                          :key="novidade.id"
                        >
                          <CardNovidade
                            :id="novidade.id"
                            :titulo="novidade.titulo"
                            :icone="novidade.icone"
                            :descricao="novidade.descricao"
                            :rascunho="novidade.rascunho"
                            v-on:excluirNovidade="excluirNovidade(novidade)"
                            v-on:abrirModalEditar="
                              abrirModalEditarNovidade(novidade)
                            "
                          ></CardNovidade>
                        </v-col>
                      </v-row>
                    </v-tab-item>
                  </div>
                </v-tabs-items>

                <v-pagination
                  prev-icon=" fa-light fa-chevron-left"
                  next-icon="fa-light fa-chevron-right"
                  v-model="page"
                  :length="maxPages"
                  :total-visible="7"
                  @input="buscarNovidades()"
                  circle
                  dense
                  solo
                ></v-pagination>
              </v-container>
            </div>

            <!-- <div v-else>
              <v-container>
                <v-row>
                  <v-btn style="color:white" class="destaque-bt rounded-lg" id="adicionarbt" color="#278b97"
                    @click="visualizarArtigo = false">
                    <v-icon small color="#ffffff" class="mr-3">fas fa-solid fa-angle-left</v-icon>
                    <div class="novo-arquivo" @click="limparArtigo()"> Voltar </div>
                  </v-btn>
                </v-row>
                <h3> {{ artigo.titulo }} </h3>
                <h2> {{ artigo.descricao }}</h2>
                <div class="conteudo"></div>
              </v-container>
            </div> -->
          </v-col>
        </v-row>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import CardNovidade from "@/components/CardNovidade.vue";
import api from "@/services/Api.js";
import modalMixin from "@/mixins/modalMixin";
import Utils from "@/utils/Utils";
import Loader from "@/components/Loader.vue";
import Alerta from "@/components/Alerta.vue";
import RedesSociais from "@/components/RedesSociais.vue";
import ModalArtigo from "../components/Modal/ModalArtigo.vue";
import ModalBanner from "@/components/Modal/ModalBanner.vue";
import { idsImagemNoticias } from "@/utils/ImagemEnums.js";

export default {
  components: {
    CardNovidade,
    Alerta,
    RedesSociais,
    ModalArtigo,
    Loader,
    ModalBanner,
  },
  mixins: [modalMixin],
  data() {
    return {
      dialogEditar: false,
      modalNovidade: false,
      dialogItem: "",
      data: false,
      loader: false,
      pesquisa: "",
      categoriasSelected: "",
      tab: null,
      editorData: "",
      idExclusao: "",
      editarNovidade: {},
      nomeCategoria: "Novidades Hepta",
      categorias: [
        {
          nome: "Inicio",
          icone: "../assets/images/icone/icone-inicio.png",
        },
      ],
      novidades: [],
      dataPesquisaFormatada: ["", ""],
      dataPesquisa: ["", ""],
      tag: [""],
      page: 1,
      maxPages: 0,
      visualizarArtigo: false,
      artigo: {
        titulo: "",
      },
      admin: false,
      pagina: "",
      imagemModal: {},
      imagem: {
        banner: {
          tipoArquivo: "",
          arquivo: "",
        },
      },
      modalAlterarBanner: false,
    };
  },
  methods: {
    limparArtigo() {
      this.visualizarArtigo = false;
    },
    getSrc(path) {
      if (path) {
        const nomeImagem = path.split("/icone/")[1];
        if (nomeImagem) {
          return require(`../assets/images/icone/${nomeImagem}`);
        }
      }
      return require("../assets/images/icone/icone-tipo-pdf.png");
    },
    abrirModalEditarNovidade(novidade) {
      this.dialogEditar = true;
      this.editarNovidade = novidade;
    },
    fecharModalArtigo() {
      this.editarNovidade = {};
      this.dialogEditar = false;
      this.buscarNovidades();
    },
    formatDate(e) {
      if (e.length > 1) {
        this.data = false;
        this.buscarNovidades();
      }
    },
    listarTags() {
      this.loader = true;
      api
        .get(`tag/listar/${this.nomeCategoria}`)
        .then((response) => {
          if (response.status == 200)
            response.data.map((tag) => {
              this.categorias.push(tag);
            });
        })
        .catch((e) => {
          this.mensagemErro(e.message);
        })
        .finally(() => {
          this.loader = false;
        });
    },
    buscarNovidades(categoria) {
      const vm = this;
      vm.loader = true;

      let data = [];
      let url = window.location.href;
      url = url.split("/");
      url = url[url.length - 1];

      vm.categoriasSelected = categoria === undefined ? url : categoria;

      if (vm.dataPesquisaFormatada[0] !== "") {
        data[0] = Utils.desformatDate(vm.dataPesquisaFormatada[0]);
        data[1] = Utils.desformatDate(vm.dataPesquisaFormatada[1]);
      }

      vm.tag =
        vm.categoriasSelected !== undefined &&
        vm.categoriasSelected !== "Inicio"
          ? [vm.categoriasSelected]
          : [];

      api
        .get(
          `artigo/listar/tag/?tag=${vm.tag}&page=${vm.page}&keywords=${vm.pesquisa}&datas=${data}`
        )
        .then((res) => {
          if (res.status == 200) vm.novidades = res.data.content;
          vm.maxPages = Math.ceil(res.data.totalItens / res.data.limit);
        })
        .catch((e) => {
          vm.mensagemErro(e.message);
        })
        .finally(() => {
          vm.loader = false;
        });
    },
    salvarNovidades() {
      this.fecharDialog();
    },
    isLoading(e) {
      this.loader = e;
    },
    getImagem(id, pagina) {
      const vm = this;
      vm.loader = true;
      api
        .get("imagem/" + id)
        .then((response) => {
          //nome da variavel é o pagina. ex: vm.banner
          vm.imagem[pagina] = response.data;
        })
        .catch(() => {
          vm.mensagem("Erro!", "Não foi possivel baixar imagem.");
        })
        .finally(() => {
          vm.loader = false;
        });
    },
    atualizaImagem(pagina, imagem, tipoArquivo) {
      const vm = this;
      vm.imagem[pagina].arquivo = imagem;
      vm.imagem[pagina].tipoArquivo = tipoArquivo;
      vm.modalAlterarBanner = false;
    },
    excluirNovidade(novidade) {
      const vm = this;
      //solicitou exclusao mas ainda não houve a confirmação
      if (!vm.exclusaoConfirmada) {
        vm.idExclusao = novidade.id;
        vm.confirmarExclusao("Deseja confirmar a exclusão?", vm.idExclusao);
      }
      //Alerta confirmou exclusão, pegar id de exclusao salvo no mixin
      //exclusaoConfirmada é o id de exclusao salvo no mixin
      else {
        vm.fecharModal();
        vm.loader = true;
        api
          .get(`/artigo/excluir/${vm.idExclusao}`, {
            headers: { Authorization: localStorage.token },
            withCredentials: true,
          })
          .then((response) => {
            if (response.status == 200) {
              vm.mensagemSucesso(response.data);
              vm.buscarNovidades();
            }
          })
          .catch((e) => {
            console.error(e);
          });
        vm.idExclusao = "";
      }
    },
    isAdmin() {
      this.admin = Utils.isAdmin();
    },
  },
  created() {
    this.listarTags();
    this.buscarNovidades();
    this.isAdmin();

    for (let pagina in idsImagemNoticias) {
      //ex: this.getImagem(103, 'banner');
      this.getImagem(idsImagemNoticias[pagina], pagina);
    }
  },
  computed: {
    dateRangeText() {
      if (
        this.dataPesquisaFormatada[0] !== "" ||
        this.dataPesquisaFormatada[1] !== ""
      ) {
        if (
          this.dataPesquisaFormatada[0] !== undefined &&
          this.dataPesquisaFormatada[1] == undefined
        ) {
          this.dataPesquisaFormatada[0] = Utils.formatDate(
            this.dataPesquisaFormatada[0]
          );
        }
        if (this.dataPesquisaFormatada[1] !== undefined) {
          this.dataPesquisaFormatada[1] = Utils.formatDate(
            this.dataPesquisaFormatada[1]
          );
        }

        return this.dataPesquisaFormatada.join(" a ");
      } else {
        return "";
      }
    },
  },
};
</script>

<style scoped>
.card-novidades {
  border-radius: 24px;
  margin-top: -5rem !important;
  text-align: center;
}

/* ------------------------------------- */
/* Tabs */
.tabs-md {
  padding: 2rem 0 0;
  border-radius: 24px 0 0 0;
  background-color: #278b97;
  overflow: hidden;
}

.tabs-md .v-tabs {
  height: 100%;
}

.tabs-md .v-tab {
  justify-content: left;
  color: #fff !important;
  font-weight: 600;
  letter-spacing: 0;
  padding-left: 2.5rem;
  min-height: 4rem;
}

.tabs-md .tab-ativa::before {
  border-radius: 16px 0 0 16px;
  margin-left: 1.5rem;
}

.tabs-sm {
  background-color: #278b97;
}

.tabs-sm .v-tab {
  color: #fff !important;
  font-weight: 600;
  letter-spacing: 0;
}

.tab-ativa::before {
  opacity: 1 !important;
  background-color: #fff;
  border-radius: 16px;
}

.tab-ativa span {
  color: #278b97 !important;
  z-index: 1;
}

.tab-ativa .icone {
  filter: none;
}

.icone {
  margin-right: 1rem;
  max-width: 2rem;
  filter: brightness(0) invert(1);
}

/* ------------------------------------- */
/* Banner */
.banner {
  max-width: 100%;
  padding: 0;
}

.banner-btn {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
}
</style>
