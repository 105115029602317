<template>
    <div>
        <Loader :loader-primario="loader"></Loader>
        <v-container>
            <div class="card">
                <div class="inicio-titulo">
                    <v-img class="icone-titulo"
                        src="../../assets/images/icone/espaco-colaborador/icone_folha_de_ponto.svg"></v-img>
                    <h1 class="titulo">Folha de Ponto</h1>
                </div>
                <br>
                <iframe :src="url" type="application/pdf" height="800px" width="100%" title="Folha de ponto"></iframe>
            </div>
        </v-container>

    </div>
</template>
    
<script>
import Loader from "@/components/Loader.vue";
import api from "@/services/Api.js";
export default {
    components: { Loader, },
    name: "folhadeponto",
    data() {
        return {
            url: "",
            loader: false,
        }
    },
    methods: {
        buscaFolhaDePonto() {
            const vm = this;
            vm.loader = true
            api
                .get("folhaPontoColaborador/folha", {
                    responseType: "arraybuffer",
                    headers: {
                        Accept: "application/pdf",
                        Authorization: localStorage.token,
                    },
                })
                .then(function (response) {
                    const blob = new Blob([response.data], { type: "application/pdf" });
                    const url = window.URL.createObjectURL(blob);
                    vm.url = url
                })
                .catch((e) => {
                }).finally(vm.loader = false);
        }
    },
    created() {
        this.buscaFolhaDePonto();
    },
};
</script>  

<style scoped>
</style>
    