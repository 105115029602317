<template>
  <v-container>
    <Loader :loader-primario="loader" />

    <!-- Voltar -->
    <v-row>
      <v-col cols="12" md="3" xl="2" :class="$vuetify.breakpoint.mdAndUp ? 'mt-4 ml-10 pl-6' : 'mt-2 mb-5'">
        <router-link :to="redirectArtigo ? 'espaco-do-colaborador/informacoes-uteis'
          : 'espaco-do-colaborador/academia-hepta'">
          <v-btn class="botao-outlined-azul w-100">
            <v-icon class="mr-2">fas fa-solid fa-arrow-left</v-icon>Voltar
          </v-btn>
        </router-link>
      </v-col>
    </v-row>

    <!-- Conteúdo -->
    <v-card class="card pa-8">
      <v-row>
        <v-col cols="12">
          <h1>{{ artigo.titulo }}</h1>
        </v-col>
        <v-col cols="12">
          <h2>{{ artigo.descricao }}</h2>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <div id="conteudo1"></div>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="text-right">
          {{ this.visualizacao }}
          <span v-if="this.visualizacao > 1">visualizações</span>
          <span v-else>visualização</span>
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>

<script>
import api from "@/services/Api.js";
import modalMixin from "@/mixins/modalMixin";
import Loader from "@/components/Loader.vue";

export default {
  components: { Loader },
  mixins: [modalMixin],
  data() {
    return {
      loader: false,
      pesquisa: false,
      redirectArtigo: false,
      artigo: { titulo: "" },
      keyword: "",
      visualizacao: 0,
    };
  },
  methods: {
    buscarVisualizacaoArtigo(idArtigo) {
      api.get("/artigo/visualizacoesArtigo/" + idArtigo)
        .then((response) => {
          if (response.status == 200) {
            this.visualizacao = response.data.visualizacoes
          }
        })
    },
    buscarArtigoPorId(idArtigo) {
      const vm = this;
      vm.loader = true;
      api
        .get("artigo/" + idArtigo)
        .then((response) => {
          if (response.status == 200) {
            vm.artigo = response.data;
            var conteudoArtigo = response.data.conteudo;
            var divConteudo = document.getElementById("conteudo1");
            divConteudo.innerHTML = conteudoArtigo;
            this.buscarVisualizacaoArtigo(idArtigo);
          }
        })
        .catch((e) => {
          vm.mensagemErro(e.message);
        })
        .finally(() => {
          vm.loader = false;
        });
    },
  },
  mounted() {
    this.buscarArtigoPorId(this.$route.query.id);
    this.pesquisa = this.$route.query.pesquisa;
    this.keyword = this.$route.query.keyword;
    this.redirectArtigo = this.$route.query.artigo;
  },
};
</script>
