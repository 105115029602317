<template>
  <div>
    <loader :loader-primario="loader"></loader>

    <alerta v-on:fecharModal="fecharModal" :exibir="modal.exibir" :titulo="modal.titulo" :mensagem="modal.mensagem">
    </alerta>

    <termo v-model="modalTermo" :item="item" v-on:fecha-modal="fechaModalTermo" @aprovar="aprovarTermoAditivo"
      @mensagem="mensagem" @enviarDocumento="enviarDocumento"></termo>

    <v-container>
      <div class="card">
        <div class="inicio-titulo">
          <v-img class="icone-titulo"
            src="../../assets/images/icone/espaco-colaborador/icone_termos_e_condicoes.svg"></v-img>
          <h1 class="titulo">Termos e Condições</h1>
        </div>
        <div class="card-texto-termo">
          <p>
            Esse espaço reúne documentos relacionados aos termos e condições necessários para a conformidade e integridade
            de nossas atividades. Os termos e declarações aqui assinados são parte integrante do contrato de trabalho ou
            prestação de serviço.
          </p>
        </div>

        <!-- Busca Status -->
        <v-row>
          <v-col cols="12" sm="9">
            <div>
              <v-autocomplete clearable chips label="Status:" outlined dense hide-details="auto" height="40px"
                @input="pagina = [1]" v-model="estadoSelecionado" :items="estados" item-text="nome" item-value="valor"
                multiple class="input-field"></v-autocomplete>
            </div>
          </v-col>
          <v-col cols="12" sm="3">
            <v-btn @click="limpar()" class="botao-limpar">Limpar Buscas</v-btn>
          </v-col>
        </v-row>

        <!-- Tabela -->
        <v-data-table v-model:pagina="pagina" :headers="headers" :items="listaTermosFiltrada" :items-per-page="porPagina"
          :no-data-text="tabelaVazia()" hide-default-footer class="mt-6" @update:options="options = $event">
          <template v-slot:item.item-descricao="{ item }">
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <div class="truncate" v-on="on">{{ item.descricao }}</div>
              </template>
              <span>{{ item.descricao }}</span>
            </v-tooltip>
          </template>
          <template v-slot:item.item-status="{ item }">
            <div :class="status(item.statusTermoAditivo)">{{ item.statusTermoAditivo }}</div>
          </template>
          <template v-slot:item.acao="{ item }">
            <div class="acoes-tabela">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    @click="item.assinaturaGov && item.statusTermoAditivo == 'APROVADO' ? visualizarAssinado(item) : abrirModal(item)"
                    v-bind="attrs" v-on="on" class="icone-acao">fas fa-eye</v-icon>
                </template>
                <span>Visualizar</span>
              </v-tooltip>
              <v-tooltip top v-if="item.assinaturaGov && item.statusTermoAditivo == 'APROVADO'">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon @click="baixarTermoAssinado(item)" v-bind="attrs" v-on="on" class="icone-acao">
                    fa-solid fa-download</v-icon>
                </template>
                <span>Baixar termo assinado</span>
              </v-tooltip>
            </div>
          </template>
        </v-data-table>
        <div class="text-center">
          <v-container>
            <v-row justify="center">
              <v-col cols="5">
                <v-container class="max-width">
                  <v-pagination prev-icon=" fa-light fa-chevron-left" next-icon="fa-light fa-chevron-right"
                    v-model="pagina[0]" :length="Math.ceil(totalItens / porPagina)" @input="buscarTermosAditivos()" circle
                    dense solo color="#278b97"></v-pagination>
                </v-container>
              </v-col>
            </v-row>
          </v-container>
        </div>
      </div>
    </v-container>
  </div>
</template>
  
<script>
import loader from "@/components/Loader.vue";
import alerta from "@/components/Alerta.vue";
import api from "@/services/Api.js";
import modalMixin from '@/mixins/modalMixin';
import termo from '@/components/EspacoDoColaborador/Termos/ModalTermos.vue';

export default {
  components: { loader, alerta, termo },
  mixins: [modalMixin],
  name: "termos",
  data() {
    return {
      headers: [
        { text: 'Titulo', value: 'titulo' },
        { text: 'Descrição', value: 'item-descricao', width: '20rem', sortable: false },
        { text: 'Data da Criação', value: 'dataCriacao', align: 'center' },
        { text: 'Status', value: 'item-status', align: 'center', sortable: false },
        { text: 'Ação', value: 'acao', align: 'center', sortable: false },
      ],
      termos: [],
      pagina: [1],
      porPagina: 5,
      listaTermosFiltrada: [],
      estados: [
        { nome: "Pendente", valor: "PENDENTE" },
        { nome: "Aprovado", valor: "APROVADO" },
      ],
      estadoSelecionado: null,
      user: {},
      loader: false,
      modalTermo: false,
      totalItens: 0,
      item: {}
    }
  },
  methods: {
    abrirModal(item) {
      this.item = item;
      this.modalTermo = true;
    },
    fechaModal() {
      this.modal.exibir = false;
      this.modalTermo = false;
    },
    tabelaVazia() {
      if (!this.termos.length) {
        return "Você não possui termos aditivos!";
      } else if (this.termos.length && !this.listaTermosFiltrada.length) {
        return "Você não possui termos aditivos no estado " + this.estadoSelecionado.join().toUpperCase() + "!";
      }
    },
    limpar() {
      this.estadoSelecionado = null;
      this.pagina = [1]
      this.buscarTermosAditivos();
    },
    status(e) {
      if (e == 'APROVADO') {
        return "status-tabela status-aprovado";
      } else {
        return "status-tabela status-pendente";
      }
    },
    async getCredenciais() {
      const vm = this;
      return await api.get("auth/credenciais/")
        .then(response => {
          vm.user = response.data;
        }).catch(e => {
          this.mensagemErro(e.message);
        })
    },
    buscarTermosAditivos() {
      const vm = this;
      vm.loader = true;
      api
        (`termo-aditivo/termos/codPessoa/${vm.user.codPessoa}/page/${vm.pagina[0]}/perPage/${vm.porPagina}`)
        .then((response) => {
          vm.termos = response.data.listObjects;
          vm.totalItens = response.data.totalItens;
          vm.listaTermosFiltrada = vm.termos;
        }).catch(e => {
          this.mensagemErro(e.message);
        }).finally(() => {
          vm.loader = false;
        });
    },
    visualizarAssinado(termo) {
      const vm = this;
      vm.loader = true;
      api
        .get(
          "termo-aditivo/download-documento-assinado-gov?idTermo=" + termo.idTermoColaborador,
          {
            responseType: "arraybuffer",
            headers: {},
          }
        )
        .then(function (response) {
          const blob = new Blob([response.data], { type: "application/pdf" });
          const url = window.URL.createObjectURL(blob);
          window.open(url, "_blank");
        })
        .catch(function (e) {
          vm.mensagemErro(e.message);
        })
        .finally(function () {
          vm.loader = false;
        });
    },
    baixarTermoAssinado(termo) {
      const vm = this;
      api
        .get("termo-aditivo/download-documento-assinado?idTermo=" + termo.idTermoColaborador,
          {
            responseType: "arraybuffer",
            headers: {}
          })
        .then(function (res) {
          let nomeArquivo = res.headers['content-disposition'].split(" = ")[1]
          const blob = new Blob([res.data], { type: "application/pdf" });
          const url = window.URL.createObjectURL(blob);

          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(`download`, `${nomeArquivo}`);
          document.body.appendChild(link);
          link.click();
        })
    },
    enviarDocumento(dados) {
      const vm = this;
      vm.loader = true;
      api
        .post("termo-aditivo/enviar-documento/", dados)
        .then((response) => {
          vm.loader = false;
          vm.buscarTermosAditivos();
          setTimeout(() => {
            vm.mensagemSucesso(response.data);
          }, 1500)
        })
        .catch((e) => {
          vm.loader = false;
          vm.mensagemErro(e.message);
        })
    },
    aprovarTermoAditivo(idTermoColaborador) {
      const vm = this;
      vm.loader = true;
      api
        .get("termo-aditivo/aprovar/" + idTermoColaborador, {
          headers: { Authorization: localStorage.token },
          withCredentials: true,
        })
        .then((response) => {
          vm.loader = false;
          vm.fechaModalTermo();
          setTimeout(() => {
            vm.mensagemSucesso(response.data);
          }, 1000)

        }).catch(e => {
          vm.loader = false;
          vm.mensagemErro(e.message);
        })
    },
    fechaModalTermo() {
      this.modalTermo = false;
    }
  },
  async mounted() {
    const vm = this;
    await vm.getCredenciais();
    await vm.buscarTermosAditivos();
  },
  watch: {
    estadoSelecionado(e) {
      if (e) {
        this.listaTermosFiltrada = this.termos.filter(
          (x) => e.includes(x.statusTermoAditivo)
        );
      }
      if (e.length == 0) {
        this.listaTermosFiltrada = this.termos
      }
    }
  },
};
</script>
  
<style scoped>
.truncate {
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 20rem;
}

.status-aprovado {
  background: #32ACB9 0% 0% no-repeat padding-box;

}

.status-pendente {
  background: #F8BA00 0% 0% no-repeat padding-box;
}

.card-texto-termo {
  text-align: center;
  background-color: #edefeea6;
  border-radius: 20px;
  margin-top: 1rem;
}

.card-texto-termo p {
  padding: 1rem;
  font-weight: 600;
  color: #616161;
}
</style>
  