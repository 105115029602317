<template>
  <div>
    <v-container>
      <!-- Dados do Colaborador -->
      <v-row>
        <v-col cols="12" class="header">
          <v-icon>fas fa-address-card</v-icon>
          <h2>Dados do Colaborador</h2>
        </v-col>
        <v-col cols="12" class="dados-colaborador-itens">
          <p>
            <b>Nome:</b> {{ dadosColaborador.nomeAvaliado }}
            <i v-if="dadosColaborador.nomeAvaliado == null">Sem informação</i>
          </p>
          <p>
            <b>Matrícula:</b> {{ dadosColaborador.matricula }}
          </p>
          <p>
            <b>Data Admissão:</b> {{ formatarData(dadosColaborador.dataAdmissao) }}
          </p>
          <p>
            <b>Tempo de casa:</b> {{ calcularTempoDeCasa(dadosColaborador.dataAdmissao).toFixed(2) }} Anos
          </p>
          <p>
            <b>Lotação:</b> {{ dadosColaborador.lotacao }}
          </p>
          <p>
            <b>Cargo:</b> {{ dadosColaborador.cargo }}
            <i v-if="dadosColaborador.cargo == null">Sem informação</i>
          </p>
          <p>
            <b>Descrição Cargo:</b> {{ dadosColaborador.descricao }}
            <i v-if="dadosColaborador.descricao == null">Sem informação</i>
          </p>

          <v-divider class="my-5" />
        </v-col>
      </v-row>

      <!-- Avaliação / Autoavaliação -->
      <v-row>
        <v-col cols="12">
          <v-tabs v-model="tab" grow hide-slider show-arrows>
            <v-tab href="#tab-0" :class="tab == 'tab-0' ? 'tab tab-active' : 'tab'">Avaliação</v-tab>
            <v-tab href="#tab-1" :class="tab == 'tab-1' ? 'tab tab-active' : 'tab'">Autoavaliação</v-tab>
          </v-tabs>
        </v-col>

        <v-col cols="12">
          <!-- Avaliação -->
          <v-tabs-items v-model="tab" touchless>
            <v-tab-item value="tab-0">
              <div v-if="ciclo.dataEncerramentoFeedback === null">
                <Aviso
                  message="A avaliação continua em progresso, o resultado ainda não está disponível neste momento!" />
              </div>
              <div v-else-if="ciclo.codAvaliacao !== null && ciclo.feedbackColaborador === null
                && ciclo.dataEncerramentoFeedback !== null">
                <Aviso message="Você recebeu o feedback do seu gestor sobre sua avaliação?" :buttons="true"
                  @btn-value="abrirModalFeedback" />
              </div>
              <div v-else-if="ciclo.codAvaliacao === null">
                <Aviso message="Sua avaliação não foi realizada pelo seu gestor direto. Entre em contato com ele!" />
              </div>
              <div v-else>
                <ViewAvaliacao :cod_avaliacao="ciclo.codAvaliacao" />
              </div>
            </v-tab-item>

            <!-- Autoavaliação -->
            <v-tab-item value="tab-1">
              <div v-if="dadosColaborador.statusAutoavaliacao !== 'Finalizado'
                && ciclo.statusAutoavaliacao === 'Encerrado'">
                <Aviso message="O ciclo foi encerrado e você não se autoavaliou dentro do prazo!" />
              </div>
              <div v-else-if="dadosColaborador.statusAutoavaliacao !== 'Finalizado'
                && ciclo.statusAutoavaliacao === 'Não Iniciado'">
                <Aviso message="Autoavaliação ainda não foi iniciada." />
              </div>
              <div v-else-if="dadosColaborador.statusAutoavaliacao !== 'Finalizado'
                && ciclo.statusAutoavaliacao === 'Iniciado'">
                <Aviso message="Finalize a sua autoavaliação." />
              </div>
              <div v-else>
                <ViewAutoAvaliacao :cod_autoavaliacao="ciclo.codAutoAvaliacao" />
              </div>
            </v-tab-item>
          </v-tabs-items>
        </v-col>
      </v-row>

    </v-container>
  </div>
</template>

<script>
import Loader from '@/components/Loader.vue';
import Alerta from '@/components/Alerta.vue';
import modalMixin from '@/mixins/modalMixin';
import api from "@/services/Api.js";
import Aviso from '@/components/Aviso.vue';

import JS from '../methods.js';

import ViewAvaliacao from './ViewAvaliacao.vue';
import ViewAutoAvaliacao from './ViewAutoAvaliacao.vue';

export default {
  name: 'MainHistorico',
  props: {
    ciclo: { type: Object, default: null },
  },
  components: { Loader, Alerta, api, Aviso, ViewAvaliacao, ViewAutoAvaliacao },
  mixins: [modalMixin],
  data() {
    return {
      loader: false,
      modalFeedback: false,

      tab: null,
      feedbackColaborador: null,
      modalEnvioFeedbackSucesso: null,

      dadosColaborador: {},
    };
  },
  methods: {
    ...JS,
    buscarDados(codAvaliacao) {
      this.loader = true;

      api.get(`AutoAvaliados/getDadosByCodCicloColaborador/`, {
        params: {
          cod_ciclo_colaborador: codAvaliacao,
        }
      })
        .then(res => {
          if (res.status == 200) this.dadosColaborador = res.data;
        })
        .catch(e => { this.mensagemErro(e.message) })
        .finally(() => { this.loader = false });
    },
    abrirModalFeedback(feedback) {
    },
    fecharModalFeedback() {
      this.feedbackColaborador = null;
      this.modalFeedback = false;
    },
    atualizarFeedback() {
      this.loader = true;

      const ciclo = { ...this.ciclo };
      ciclo.feedbackColaborador = this.feedbackColaborador;

      const cicloDTO = JSON.stringify(ciclo);

      axios.put(`/IntranetHepta/rs/avaliacaoDesempenho/feedbackColaborador`, cicloDTO, {
        headers: {
          "Content-Type": "application/json",
          "Authorization": localStorage.token,
        },
        withCredentials: true,
      })
        .then(res => {
          if (res.status == 200) this.abrirModalEnvioFeedback(true);
        })
        .catch(e => {
          vm.mensagemErro(e.message);
          this.abrirModalEnvioFeedback(false);
        })
        .finally(() => { this.loader = false });
    },
    reload() {
      location.reload();
    },
  },
  async mounted() {
    this.buscarDados(this.ciclo.codAutoAvaliacao);
  },
}
</script>

<style scoped></style>
