import api from '@/services/Api';

const logout = async () => {
  localStorage.removeItem('isAdmin');
  localStorage.removeItem('isGpc');
  localStorage.removeItem('token');
  localStorage.removeItem('isPJ');

  const token = localStorage.getItem('token');
  if (token) {
    try {
      await api.get('auth/logout');
    } catch (error) {
      console.warn('Failed to logout from server', error);
    }
  }
};

const login = async form => {
  const response = await api.post('auth/login', form);
  if (response.status == 200) {
    const token = response.data.token;
    localStorage.setItem('token', token);
    localStorage.setItem('isPJ', response.data.isPJ);

    await api.post(`metricasRegistro`, { pagina: 'Login', path: '/auth' });
  }
  return response;
};

const authService = Object.freeze({
  logout,
  login,
});

export default authService;
