<template>
    <v-dialog v-model="dialog" transition="dialog-top-transition" max-width="500">
        <template>
            <v-card elevation="2" :class="titulo == 'Erro!' ? 'modal_erro' : 'v-card'">
                <v-card-title>
                    <h3>{{ titulo }}</h3>
                    <v-icon @click="$emit('fecharModal')">fa-times</v-icon>
                </v-card-title>
                <v-card-text>
                    <v-icon v-if="titulo == 'Erro!'" color="#d91727">fas fa-times-circle</v-icon>
                    <v-icon v-else-if="titulo == 'Sucesso!'" color="#008693">fa-solid fa-check</v-icon>
                    <p>{{ mensagem }}</p>
                </v-card-text>

                <v-card-actions v-if="titulo == 'Confirmação!' || titulo == 'Confirmar Solicitação!'" class="botoes">
                    <v-row v-if="titulo == 'Confirmação!'">
                        <v-btn @click="$emit('fecharModal')" class="botao-cancelar w-25">Cancelar</v-btn>
                        <v-btn @click="$emit('exclusao')" class="botao-excluir w-25">Excluir</v-btn>
                    </v-row>
                    <v-row v-else-if="titulo == 'Confirmar Solicitação!'">
                        <v-btn @click="$emit('fecharModal')" class="botao-cancelar w-25">Cancelar</v-btn>
                        <v-btn @click="$emit('confirmar')" class="botao-confirmar w-25">OK</v-btn>
                    </v-row>
                </v-card-actions>
            </v-card>
        </template>
    </v-dialog>
</template>

<script>
export default {
    props: {
        titulo: String,
        mensagem: String,
        exibir: {
            type: Boolean,
            required: false,
        },
    },
    data() {
        return {
            dialog: false,
        }
    },
    methods: {
    },
    watch: {
        exibir(e) {
            this.dialog = e
        }
    },
};
</script>

<style scoped>
.close {
    position: absolute;
    top: 5px;
    right: 20px;
}

.v-card>.v-card__title>.v-icon {
    position: absolute;
    right: 1.5rem;
    color: #FFF;
}

.botoes .row {
    justify-content: center;
}

.botoes .v-btn {
    margin: 1rem 1rem 0;
}
</style>
