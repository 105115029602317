<template>
  <v-dialog v-model="modalAnexo" width="1800" @click:outside="fechaModal()">
    <v-card>
      <v-card-title>
        <h3 v-if="anexo.id == null">Novo arquivo</h3>
        <h3 v-else>{{ anexo.titulo }}</h3>
        <v-icon @click="fechaModal()">fa-times</v-icon>
      </v-card-title>
      <!-- Conteúdo Card -->
      <v-card-text>
        <v-container class="espacamento-x-2 espacamento-y-1">
          <v-col cols="12">
            <v-text-field
              color="#278b97"
              label="Titulo:"
              v-model="anexo.titulo"
              outlined
              dense
              class="borda-redonda"
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-textarea
              color="#278b97"
              v-model="anexo.descricao"
              label="Descrição:"
              outlined
              dense
              class="borda-redonda"
            ></v-textarea>
          </v-col>
          <v-col cols="12">
            <v-row justify="space-around">
              <v-col>
                <v-autocomplete
                  color="#278b97"
                  clearable
                  chips
                  label="Categoria:"
                  outlined
                  dense
                  class="borda-redonda"
                  :items="categorias"
                  item-value="id"
                  item-text="titulo"
                  v-model="anexo.categoria.id"
                ></v-autocomplete>
              </v-col>
              <v-col>
                <v-autocomplete
                  color="#278b97"
                  clearable
                  chips
                  label="Tags:"
                  outlined
                  dense
                  class="borda-redonda"
                  v-model="anexo.tags"
                  :items="tags"
                  return-object
                  item-text="nome"
                  multiple
                ></v-autocomplete>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12">
            <v-row justify="space-around">
              <v-col>
                <v-file-input
                  color="#278b97"
                  class="borda-redonda"
                  v-model="arquivo"
                  outlined
                  dense
                  label="Anexar arquivo"
                  @change="anexarArquivo(arquivo)"
                  placeholder=" Escolha o anexo..."
                  accept="application/pdf"
                  hide-details="auto"
                  :prepend-icon="null"
                >
                  <template v-slot:prepend-inner>
                    <v-icon class="mr-2">fa-paperclip</v-icon>
                  </template>
                </v-file-input>
              </v-col>
              <v-col cols="2" v-if="download" @click="baixarArquivo(arquivo)" class="btn-baixar">
                <v-icon color="#278b97" class="mr-3 mb-1">fa-solid fa-download</v-icon>
                <p>Baixar</p>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="3">
            <v-switch color="#278b97" label="Rascunho" v-model="anexo.rascunho"></v-switch>
          </v-col>
        </v-container>
        <v-divider class="margem-x-2"></v-divider>
      </v-card-text>
      <!-- Botões -->
      <v-card-actions class="justify-center botoes">
        <v-btn @click="fechaModal()" class="botao-cancelar w-25">
          <v-icon color="black" class="mr-2">fa-solid fa-xmark</v-icon>
          Cancelar
        </v-btn>
        <v-btn @click="salvar()" class="botao-salvar w-25">
          <v-icon color="#ffffff" class="mr-2">fas fa-save</v-icon>
          <div v-if="anexo.rascunho">Salvar</div>
          <div v-else>Publicar</div>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import api from '@/services/Api.js';
export default {
  name: 'modal-biblioteca',
  props: {
    item: {},
    value: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    tags: [],
    categorias: [
      {
        id: 4,
        titulo: 'Políticas',
        img: require('@/assets/images/icone/icone-politicas.png'),
      },
      {
        id: 5,
        titulo: 'Processos',
        img: require('@/assets/images/icone/icone-processos.png'),
      },
      {
        id: 6,
        titulo: 'Procedimentos',
        img: require('@/assets/images/icone/icone-procedimentos.png'),
      },
      {
        id: 7,
        titulo: 'Modelos',
        img: require('@/assets/images/icone/icone-modelos.png'),
      },
      {
        id: 8,
        titulo: 'Outros documentos',
        img: require('@/assets/images/icone/icone-referencia.png'),
      },
    ],
    modalAnexo: false,
    download: false,
    arquivoConteudo: null,
    arquivo: {},
    msg: [],
    anexo: {
      id: null,
      titulo: null,
      descricao: null,
      nomAnexo: null,
      nomImagem: null,
      imagem: null,
      conteudo: null,
      rascunho: false,
      categoria: {},
      tags: [],
    },
    anexoNovo: false,
  }),
  watch: {
    value(e) {
      this.modalAnexo = e;
      this.anexo = this.item;

      if (this.anexo.id != null) {
        this.arquivo = new File([this.anexo.titulo], this.anexo.nomAnexo);
        this.download = true;
      }
    },
  },
  methods: {
    fechaModal: function () {
      this.$emit('fecha-modal');
      this.limparVariaveis();
    },
    limparVariaveis() {
      this.anexoNovo = false;
      this.download = false;
      this.arquivoConteudo = null;
      this.arquivo = {};
    },
    anexarArquivo(listaArquivos) {
      if (listaArquivos == null) {
        this.download = false;
      } else {
        this.anexoNovo = true;
        this.download = true;
        this.anexo.nomAnexo = listaArquivos.name;
        this.arquivoConteudo = listaArquivos;
      }
    },
    baixarArquivo(anexo) {
      const vm = this;
      //caso arquivo tenha sido anexado agora e não tenha um salvo
      if (vm.anexo.id == null || vm.anexoNovo) {
        let url = window.URL.createObjectURL(anexo);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', anexo.name);
        document.body.appendChild(link);
        link.click();
      } else {
        api
          .get('anexo/baixar/' + vm.anexo.id)
          .then(function (response) {
            const anexo = response.data;
            const ArrayBugger = vm.base64ToArrayBuffer(anexo.conteudo);
            var blob = new Blob([ArrayBugger]);
            let url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', anexo.nome + anexo.extensao);
            document.body.appendChild(link);
            link.click();
          })
          .catch(e => {
            this.$emit('capturarMensagemErro', e);
          });
      }
    },
    prepararTag() {},
    salvar() {
      const vm = this;
      vm.anexo.descricao = vm.anexo.descricao ? vm.anexo.descricao : 'Sem descrição...';
      vm.anexo.dataInclusao = vm.anexo.dataInclusao
        ? delete vm.anexo.dataInclusao
        : vm.anexo.dataInclusao;
      let anexoFormData = new FormData();
      anexoFormData.append('anexo', JSON.stringify(vm.anexo));

      if (vm.arquivoConteudo) {
        anexoFormData.append('fileConteudo', vm.arquivoConteudo);
      }

      if (vm.anexo.id == null) {
        vm.criar(anexoFormData);
      } else {
        vm.editar(anexoFormData);
      }
    },
    criar(anexo) {
      api
        .post('anexo/criar', anexo, {
          headers: { Authorization: localStorage.token },
          withCredentials: true,
        })
        .then(response => {
          this.$emit('capturarMensagemSucesso', response.data);
        })
        .catch(e => {
          this.$emit('capturarMensagemErro', e);
        })
        .finally(() => {
          this.$emit('salvar');
          this.limparVariaveis();
        });
    },
    editar(anexo) {
      api
        .post('anexo/atualizar', anexo, {
          headers: { Authorization: localStorage.token },
          withCredentials: true,
        })
        .then(response => {
          this.$emit('capturarMensagemSucesso', response.data);
        })
        .catch(e => {
          this.$emit('capturarMensagemErro', e);
        })
        .finally(() => {
          this.$emit('fecha-modal');
          this.limparVariaveis();
        });
    },
    base64ToArrayBuffer(base64) {
      var binary_string = window.atob(base64);
      var len = binary_string.length;
      var bytes = new Uint8Array(len);
      for (var i = 0; i < len; i++) {
        bytes[i] = binary_string.charCodeAt(i);
      }
      return bytes.buffer;
    },
    buscarTags() {
      const vm = this;
      api.get(`tag/listar/`).then(response => {
        if (!Array.isArray(response.data)) return;
        response.data.forEach(element => {
          if (!vm.tags.includes(element)) {
            vm.tags.push(element);
          }
        });
      });
    },
  },
  created: function () {
    this.buscarTags();
  },
};
</script>

<style scoped>
.borda-redonda {
  border-radius: 12px;
  opacity: 1;
}

.margem-x-2 {
  margin-left: 2rem;
  margin-right: 2rem;
}

.espacamento-1 {
  padding: 1rem;
}

.espacamento-x-1 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.espacamento-y-1 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.espacamento-2 {
  padding: 2rem;
}

.espacamento-x-2 {
  padding-left: 2rem;
  padding-right: 2rem;
}

.espacamento-y-2 {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.btn-baixar {
  cursor: pointer;
  display: flex;
  align-items: center;
}

.btn-baixar > .baixar:hover {
  text-shadow: 0 0 0.05em #757575b3;
  color: #278b97 !important;
}

.v-list-item > .v-application {
  color: #278b97 !important;
  caret-color: #278b97 !important;
}

.botoes {
  padding-top: 1rem !important;
}

.botoes .v-btn {
  margin: 0 1rem !important;
}
</style>
