import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import pt from "vuetify/lib/locale/pt";
Vue.use(Vuetify);

export default new Vuetify({
    icons: {
        iconfont: 'mdiSvg', // default - only for display purposes
    },
    lang: {
        locales: { pt },
        current: "pt",
      },
      theme: {
        themes: {
          light: {
            primary: '#278b97',
          },
        },
      },
});

