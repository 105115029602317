<template>
  <v-dialog v-model="show" max-width="1200" persistent>
    <loader :loader-primario="loader"></loader>

    <alerta :fecharModal="fecharModal" :exibir="modal.exibir" :titulo="modal.titulo" :mensagem="modal.mensagem">
    </alerta>

    <v-card>
      <v-card-title>
        <h3>Recibo de férias</h3>
        <v-icon @click="fechar()">fa-times</v-icon>
      </v-card-title>

      <v-card-text>
        <v-container class="conteudo-texto">
          <p>
            Baixe aqui o seu recibo de férias, assine e depois anexe  para completar o
            ciclo das suas férias. Segue abaixo um tutorial de como assinar seu documento digitalmente.
          </p>
          <p class="mt-2">
            <span class="aviso">
             <v-icon size="15" color="#0A5EC0">fa-circle-info</v-icon>
              Arquivo com tamanho máximo de 2MB
              </span>
          </p>
          <div class="como-assinar">
            <h4 class="text-start my-4">
              <v-icon>fas fa-caret-right</v-icon>Como assinar o recibo
            </h4>
            <p>
              O recibo de férias não precisa ser impresso para assinatura, os mesmos podem ser assinados com sua conta do
              gov.br conforme vídeo do link abaixo.
            </p>
            <p>Quer saber como usar a ASSINATURA DIGITAL do GOV.BR? ✍️📲</p>
            <p>
              🤩 Imagine poder assinar, gratuitamente e com validade legal, contratos de venda de imóvel, requerimentos
              para o governo ou quaisquer outros documentos SEM precisar se deslocar, mexer com papel ou caneta, contador,
              cartório… 😉
            </p>
            <p>
              🤜 Chega pra lá, burocracia! AGORA, com a assinatura digital, a gente facilita a vida do cidadão e ele
              escolhe: pelo computador ou pelo aplicativo GOV.BR! ✋ 📲
            </p>
            <p>🤝 Ministério da Economia e Serpro</p>
            <p>📹 Ou assista ao vídeo direto aqui👇</p>
            <p>
              Confira o passo a passo no YouTube:
              <a href="https://www.youtube.com/watch?v=v7VKeVHBE2s&ab_channel=TVCRECI" target="_blank" class="link">
                Como fazer Assinatura Eletrônica gov.br gratuita
              </a>
            </p>
            <p>
              A assinatura digital e gratuita pelo celular já está ao alcance da população no GOV.BR, a plataforma de
              relacionamento do governo federal com o cidadão. O aplicativo GOV.BR, que pode ser utilizado gratuitamente
              tanto em iOS quanto em Android, passou a contar com essa funcionalidade e avançou para se tornar um canal
              cada vez mais completo, com experiência padronizada e integrada em relação ao formato web. Quaisquer
              documentos que envolvam interações com o poder público federal poderão ser assinados pelo aplicativo e terão
              validade legal, amparada pela Lei 14.063 (de 23/09/2020) e pelo Decreto 10.543 (de 13/11/2020). Mas atenção:
              para assinar digitalmente, é preciso ter a conta Prata ou Ouro na plataforma.
            </p>
          </div>
        </v-container>

        <div v-for="(recibo, index) in dadosRecibos">
          <v-divider class="divider"></v-divider>
          <v-container>
          <v-row class="ma-0">
            <v-col style="padding-left: 2rem" class="text-justify" cols="12" md="4">
              <h4 :class="reciboInvalido(recibo) ? 'red--text' : '' ">
                <v-icon :class="reciboInvalido(recibo) ? 'red--text' : '' ">fas fa-caret-right</v-icon>{{ recibo.tipo}}
                <span v-if="reciboInvalido(recibo)">
                 - RECIBO INVALIDO
                </span>

              </h4>
              <p v-if="reciboInvalido(recibo)">
                Motivo de recusa:
                <span class="red--text">{{recibo.comentario }} </span>
              </p>
            </v-col>
            <v-spacer></v-spacer>
            <v-col cols="12" md="3">
              <div v-if="reciboPendente(recibo)" class="botao-salvar">
                <label :for="'inputReciboFerias' + index" class="custom-file-upload">
                  <i class="fas fa-paperclip"></i>
                  {{ recibo.selectedFileName || `Anexar Recibo` }}
                </label>
                <input
                    type="file"
                    :id="'inputReciboFerias' + index"
                    accept="application/pdf"
                    @change="onFilePicked($event, recibo)"
                />
              </div>
            </v-col>

            <v-col cols="12" md="3">
              <v-btn @click="baixarRecibo(recibo)"  class="botao-salvar w-100">
                <v-icon>fas fa-download</v-icon>Baixar Recibo
              </v-btn>
            </v-col>
          </v-row>
          </v-container>
        </div>

        <v-divider class="divider"></v-divider>

        <v-card-actions>
          <v-row class="d-flex justify-end ma-0">
            <v-col cols="12" md="3">
              <v-btn @click="fechar()" class="botao-cancelar w-100">
                Cancelar
              </v-btn>
            </v-col>
            <v-col v-if="recibosPendentes()" cols="12" md="3">
              <v-btn @click="enviarRecibos()" class="botao-azul w-100">
                Enviar Recibos
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import modalMixin from "@/mixins/modalMixin";
import alerta from "@/components/Alerta.vue";
import loader from "@/components/Loader.vue";
import api from "@/services/Api";

export default {
  props: {
    value: Boolean,
    codFerias: Number,
  },
  components: {
    alerta,
    loader,
  },
  mixins: [modalMixin],
  data() {
    return {
      paths: {
        recibo: "meusdados/ferias/recibo",
        baixar: "meusdados/ferias/baixar-recibo",
        baixarAssinado: "meusdados/ferias/baixar-recibo-assinado",
        download: "/meusdados/ferias/download-recibo",
        enviarRecibo: "/meusdados/ferias/recibo/enviar-recibos"
      },
      loader: false,
      dadosRecibo: {},
      dadosRecibos: [],
      envioRecibos: {},
      loadingBaixar: false,
      loadingAnexar: false,
    };
  },
  computed: {
    show: {
      get() {
        if (this.value) this.buscarDadosRecibo();
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
    getStatusCor() {
      if (this.dadosRecibo.status == "APROVADO") return "#278B97";

      if (this.dadosRecibo.status == "INVALIDADO") return "#FF0C23";

      return "#E7B814";
    },
    getStatusTexto() {
      let texto = "";
      switch (this.dadosRecibo.status) {
        case "ENVIADO":
          texto = "Aguardando envio do Recibo";
          break;
        case "ASSINADO":
          texto =
            "Recibo enviado com sucesso, aguarde a validação do financeiro";
          break;
        case "INVALIDADO":
          texto = "Seu recibo foi invalidado";
          break;
        case "APROVADO":
          texto =
            "Seu recibo foi aprovado com sucesso. Disponível abaixo para download:";
          break;
      }

      return texto;
    },
  },
  methods: {
    buscarDadosRecibo() {
      this.loader = true;
      api
        .get(`${this.paths.recibo}/${this.codFerias}`)
        .then(res => (this.dadosRecibos = res.data))
        .catch(error => this.mensagemErro(error))
        .finally(() => (this.loader = false));
    },
    baixarRecibo(obj) {
      if(!obj) return;
      this.loadingBaixar = true;

      let url =
        obj.status == "APROVADO"
          ? `${this.paths.baixarAssinado}/${obj.id}`
          : `${this.paths.download}/${obj.id}`;

      api
        .get(url, {
          responseType: "arraybuffer",
        })
        .then(res => {
          let link = document.createElement("a");
          link.href = window.URL.createObjectURL(new Blob([res.data]));
          link.download = "Recibo.pdf";
          link.click();
          URL.revokeObjectURL(link.href);
        })
        .catch(error => this.mensagemErro(error))
        .finally(() => (this.loadingBaixar = false));
    },
    baixarRecibo2(obj) {
      if(!obj) return;
      try {
        const vm = this;
        const link = document.createElement("a");
        link.href = `${vm.paths.download}/${obj.id}`;
        link.download = `${obj.tipo}.pdf`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } catch(e){
        this.mensagemErro(e)
      } finally {
        this.loadingBaixar = false
      }
    },
    salvarReciboAssinado(event) {
      if (event.target.files.length == 0) return;

      this.loadingAnexar = true;

      let body = new FormData();
      let file = event.target.files[0];
      body.append("arquivoAssinado", file);

      if (this.verificaTamanhoArquivo(file)) {
        api
          .put(`${this.paths.recibo}/${this.dadosRecibo.id}`, body)
          .then(res => {
            this.mensagemSucesso("Recibo de férias cadastrado com sucesso.");
            this.fechar();
          })
          .catch(error => this.mensagemErro(error))
          .finally(() => (this.loadingAnexar = false));
      } else {
        this.mensagem("Aviso!", "Tamanho máximo de 2MB.");
        this.loadingAnexar = false;
      }
    },
    verificaTamanhoArquivo(arquivo) {
      return arquivo.size / 1024 < 2048;
    },
    isAprovado(obj) {
      if(!obj) return;
      return obj.status == "APROVADO";
    },
    onFilePicked(event, obj) {
      const arquivo = event.target.files[0];
      this.$set(obj, 'selectedFileName', arquivo.name);
      const vm = this;
      let tipo = obj.tipo.replace(" ", "_");
      if (!vm.verificaTamanhoArquivo(arquivo)) {
        this.envioRecibos[tipoArq] = null;
        alert("Tamanho máximo de 2MB.");
      } else {
        this.envioRecibos[tipo] = { arquivo: arquivo, idRecibo: obj.id }
      };
    },
    reciboPendente(recibo) {
      return recibo.tipo && recibo.status && recibo.status !== 'ASSINADO' && recibo.status !== 'APROVADO';
    },
    recibosPendentes(){
      return this.dadosRecibos.some(r => r.status !== "APROVADO" && r.status !== "ASSINADO");
    },
    reciboInvalido(recibo){
      return recibo.status && recibo.status === 'INVALIDADO';
    },
    fechar() {
      if (this.$refs.fileInput?.files) this.$refs.fileInput.value = null;

      this.dadosRecibo = {};
      this.show = false;
    },
    enviarRecibos() {
      const vm = this;
      const body = new FormData();
      const countElementosJaEnviados = this.dadosRecibos.filter(f =>
          f.status === "ASSINADO" || f.status === "APROVADO"
      ).length
      let tamanhoDaLista = this.dadosRecibos.length - countElementosJaEnviados;
      if(Object.keys(this.envioRecibos).length != tamanhoDaLista) {
        alert("Anexe todos os recibos para enviar. ");
        vm.isAnexando = false;
        return;
      }

      if (this.envioRecibos.RECIBO_FERIAS) {
        body.append("idRecibo", this.envioRecibos.RECIBO_FERIAS.idRecibo);
        body.append("arquivoAssinado", this.envioRecibos.RECIBO_FERIAS.arquivo);
        body.append("tipo_arquivo", 0);
      }
      if (this.envioRecibos.AVISO_FERIAS) {
        body.append("idRecibo", this.envioRecibos.AVISO_FERIAS.idRecibo);
        body.append("arquivoAssinado", this.envioRecibos.AVISO_FERIAS.arquivo);
        body.append("tipo_arquivo", 1);
      }
      if (this.envioRecibos.SOLICITACAO_ABONO) {
        body.append("idRecibo", this.envioRecibos.SOLICITACAO_ABONO.idRecibo);
        body.append("arquivoAssinado", this.envioRecibos.SOLICITACAO_ABONO.arquivo);
        body.append("tipo_arquivo", 2);
      }
      if (this.envioRecibos.RECIBO_ABONO) {
        body.append("idRecibo", this.envioRecibos.RECIBO_ABONO.idRecibo);
        body.append("arquivoAssinado", this.envioRecibos.RECIBO_ABONO.arquivo);
        body.append("tipo_arquivo", 3);
      }
      api.put(this.paths.enviarRecibo, body)
          .then(() => {
            alert("Recibo de férias cadastrado com sucesso.");
            this.fechar();
          })
          .catch((err) => {
            console.error(err);
            alert("Ocorreu um erro ao tentar cadastrar um recibo de férias. ");
          })
          .finally(() => {

          })
    },
  },
};
</script>

<style scoped>
.conteudo-texto {
  padding: 1rem 3rem;
}

.conteudo-texto p {
  color: #616161 !important;
  text-align: start !important;
}

.status-recibo {
  margin: 2rem 0;
}

.status-recibo span {
  color: #e7b814 !important;
  font-size: 1rem;
  font-weight: 600;
}

.status-recibo span .v-icon {
  color: #e7b814;
  font-size: 1rem;
  margin-right: .5rem;
}

.status-card {
  border-radius: 12px;
  border: solid 2px;
  justify-content: center;
  margin: 0;
}

.status-card .col {
  padding: 1.75rem 1.25rem;
  text-align: center;
}

.status-card p {
  text-align: center !important;
}

h4 {
  color: #278b97;
}

h4 .v-icon {
  color: #278b97;
  font-size: 1rem;
  margin-right: .5rem;
}

.como-assinar p {
  margin: .5rem 0;
}

a.link {
  color: #0080ff !important;
}

.aviso{
  color: #0A5EC0 !important;
  font-weight: 600;
  font-size: 0.9rem;
}

.divider {
  border-color: #278b97;
  margin: .5rem 2.5rem;
}

.v-dialog>.v-card>.v-card__text p, .v-dialog>.v-card>.v-card__text{
 text-align: start;
}

input[type="file"] {
  display: none;
}
label {
  width: 100%;
  color: #FFF;
  text-align: center;
  display: block;
  cursor: pointer;
  border-radius: .25rem;
  padding-top: 10px;
  padding-bottom: 5px;
  font-size: 16px;
}

</style>
