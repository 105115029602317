<template>
    <div>
        <div class="loader" id="loader-primario">
            <span></span>
            <span></span>
            <span></span>
            <span></span>
        </div>
        <div class="loaderFocus" id="loader-primario-focus"></div>
    </div>
</template>
<script>
export default {
    props: {
        loaderPrimario: Boolean
    },
    data() {
        return {
            loaderSemaforo: 0,
        };
    },

    methods: {
        iniciaLoader() {
            var focus = document.getElementById("loader-primario-focus")
            var loader = document.getElementById("loader-primario");

            this.loaderSemaforo++;

            this.checkVisibilityNone(loader, focus);
        },

        encerraLoader(s) {
            var focus = document.getElementById("loader-primario-focus")
            var loader = document.getElementById("loader-primario");


            if (this.loaderSemaforo > 0) {
                this.loaderSemaforo--;
            }

            if (this.loaderSemaforo == 0) {
                this.checkVisibilityInline(loader, focus);
            }
        },

        checkVisibilityNone(loader, focus) {
            loader.style.display = "inline";
            focus.style.display = "inline"
        },
        checkVisibilityInline(loader, focus) {
            loader.style.display = "none";
            focus.style.display = "none"
        },
    },
    created: function () {
    },
    watch: {
    loaderPrimario: {
      immediate: true, // Para chamar o watcher imediatamente na criação do componente
      handler(value) {
        if (value) {
        setTimeout(() =>this.iniciaLoader("loader-primario"), 30)
        } else {
            setTimeout(() =>this.encerraLoader("loader-primario"), 30)
        }
      },
    },
  },
};
</script>
<style scoped>

</style>
