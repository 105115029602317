<template>
  <div>
    <v-dialog v-model="dialog" @click:outside="closeDialog">
      <v-card>
        <v-card-title>
          <h3>Nota fiscal</h3>
        </v-card-title>

        <v-card-text>
          <v-container class="px-10">
            <v-row>
              <v-col cols="12" md="4">
                <v-text-field
                  v-model="despesa.descricao"
                  label="Descrição:"
                  outlined
                  disabled
                  dense
                  hide-details="auto"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field
                  v-model="despesa.valorPrevisto"
                  label="Valor previsto:"
                  disabled
                  outlined
                  dense
                  hide-details="auto"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field
                  v-model="valorDespesa"
                  label="Valor gasto:"
                  type="number"
                  :rules="[v => !!v || 'insira o valor gasto']"
                  outlined
                  dense
                  hide-details="auto"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12">
                <v-file-input
                  v-model="file"
                  :disabled="!!extravioComprovante"
                  label="Comprovante"
                  outlined
                  dense
                  hide-details="auto"
                  prepend-icon=""
                  append-icon="fas fa-paperclip"
                />

                <!-- <v-checkbox
                  v-if="valorDespesa"
                  v-model="extravioComprovante"
                  @change="confirmarExtravio"
                  :disabled="!!file"
                  label="Comprivante Extraviado"
                  hide-details="auto"
                ></v-checkbox> -->
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-row class="justify-center">
            <v-col cols="11" md="4">
              <v-btn @click="closeDialog" class="botao-cancelar w-100">Cancelar</v-btn>
            </v-col>
            <v-col cols="11" md="4">
              <v-btn
                @click="anexarComprovante"
                :disabled="!(!!valorDespesa && (!!extravioComprovante || !!file))"
                class="botao-principal w-100"
              >
                Anexar
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="showConfirmacao" persistent @click:outside="naoConcorda" width="450px">
      <v-card>
        <v-card-title> Sem comprovante </v-card-title>
        <v-container>
          <v-card-text>
            <v-row>
              <v-col>
                Declaro que foi efetuado o pagamento do valor citado a seguir, depositado pela Hepta em minha conta
                bancária para tal finalidade. O comprovante deste pagamento foi extraviado, por isso, será substituído
                por esta declaração para prestação de contas.
              </v-col>
            </v-row>
            <v-row>
              <v-col> Atividade:<b> Visita Técnica para atendimento ao Cliente</b> </v-col>
            </v-row>
            <v-row>
              <v-col>
                Valor Previsto:<b> {{ formatMoney(despesa.valorPrevisto) }}</b>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                Valor Gasto:<b> {{ formatMoney(valorDespesa) }} </b>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                Finalidade:<b> {{ despesa.nomeTipoDespesa }}</b>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                Descrição:<b> {{ despesa.descricao }} </b>
              </v-col>
            </v-row>
            <v-row>
              <v-col> Por ser a expressão da verdade, firmo a presente para efeitos legais. </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="showConfirmacao = false"> Concordar </v-btn>
          </v-card-actions>
        </v-container>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import ServiceVisitaTecnica from '@/services/ServiceVisitaTecnica';
import { formatMoney } from '@/utils/Utils';

export default {
  name: 'AnexoComprovante',
  props: { dialog: { Type: Boolean }, despesa: { Type: Object } },
  data() {
    return {
      showConfirmacao: false,
      valorDespesa: null,
      extravioComprovante: null,
      file: null,
    };
  },
  methods: {
    formatMoney: formatMoney,
    naoConcorda() {
      let vm = this;
      vm.showConfirmacao = !vm.showConfirmacao;
      vm.extravioComprovante = false;
    },
    confirmarExtravio(extravio) {
      this.showConfirmacao = extravio;
    },
    anexarComprovante() {
      let vm = this;
      vm.$emit('loader', true);
      let promisse = null;
      if (vm.extravioComprovante) {
        const formDespesa = {
          idDespesa: vm.despesa.idDespesa,
          valorGasto: vm.valorDespesa,
          semComprovante: vm.extravioComprovante,
        };
        promisse = ServiceVisitaTecnica.atualizarDespesaSemNotaFiscal(formDespesa);
      } else {
        const form = {
          valorGasto: vm.valorDespesa,
          idDespesa: vm.despesa.idDespesa,
        };
        const formData = new FormData();
        formData.append('formulario', JSON.stringify(form));
        formData.append('file', vm.file);
        promisse = ServiceVisitaTecnica.postNotaFiscal(formData);
      }
      return promisse
        .then(res => vm.$emit('update', res.data))
        .catch(err => vm.$emit('erro', err))
        .finally(() => vm.$emit('loader', false));
    },
    closeDialog() {
      let vm = this;
      vm.showConfirmacao = false;
      vm.valorDespesa = null;
      vm.extravioComprovante = null;
      vm.file = null;
      vm.$emit('close');
    },
  },
  mounted() {},
};
</script>

<style scoped></style>
