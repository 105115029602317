import { render, staticRenderFns } from "./ModalNovidade.vue?vue&type=template&id=a3ad8432&scoped=true"
import script from "./ModalNovidade.vue?vue&type=script&lang=js"
export * from "./ModalNovidade.vue?vue&type=script&lang=js"
import style0 from "./ModalNovidade.vue?vue&type=style&index=0&id=a3ad8432&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a3ad8432",
  null
  
)

export default component.exports